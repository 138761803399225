import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationSettingsResponse, AppSettingsState, CustomLogo, EnvironmentInfoResponse, PublicSettingsResponse } from '../types/types';
import { ApplicationStatus } from '../../userSettings/types/types';

const initialAppSettingsState: AppSettingsState = {
  gaCode: '',
  gaAppId: '',
  hotjarId: 0,
  hotjarSnippetVersion: 0,
  applicationVersion: 'unknown',
  activeProfiles: 'unknown',
  customLogoLink: undefined,
  tierName: 'unknown',
  customLogoFilename: undefined,
  environment: undefined,
  keycloakUrl: undefined,
  applicationStatus: undefined,
  productTermsLink: '',
  productTermsCheckEnabled: false,
  productTermsAccepted: false
};

const setPublicSettings = (state: AppSettingsState, action: PayloadAction<PublicSettingsResponse>) => {
  state.gaCode = action.payload.gaCode;
  state.gaAppId = action.payload.gaAppId;
  state.applicationVersion = action.payload.applicationVersion;
  state.activeProfiles = action.payload.activeProfiles;
  state.hotjarId = action.payload.hotjarId;
  state.hotjarSnippetVersion = action.payload.hotjarSnippetVersion;
  state.tierName = action.payload.tierName;
};

const setEnvironmentInfo = (state: AppSettingsState, action: PayloadAction<EnvironmentInfoResponse>) => {
  state.environment = action.payload.environment;
  state.keycloakUrl = action.payload.keycloakUrl;
};

const setCustomLogo = (state: AppSettingsState, action: PayloadAction<CustomLogo>) => {
  state.customLogoFilename = action.payload.filename;
  state.customLogoLink = action.payload.link;
  state.base64image = action.payload.base64image;
};

const setApplicationStatus = (state: AppSettingsState, action: PayloadAction<ApplicationStatus>) => {
  state.applicationStatus = action.payload;
};

const setApplicationSettings = (state: AppSettingsState, action: PayloadAction<ApplicationSettingsResponse>) => {
  setCustomLogo(state, { payload: action.payload.customLogo, type: '' });
  state.productTermsAccepted = action.payload.productTermsAccepted;
  state.productTermsCheckEnabled = action.payload.productTermsCheckEnabled;
  state.productTermsLink = action.payload.productTermsLink;
};

const setProductTermsAccepted = (state: AppSettingsState) => {
  state.productTermsAccepted = true;
};

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: initialAppSettingsState,
  reducers: {
    setPublicSettingsInStore: setPublicSettings,
    setCustomLogoInStore: setCustomLogo,
    setEnvironmentInfoInStore: setEnvironmentInfo,
    setApplicationStatusInStore: setApplicationStatus,
    setApplicationSettingsInStore: setApplicationSettings,
    setProductTermsAcceptedInStore: setProductTermsAccepted
  },
});

export const {
  setPublicSettingsInStore,
  setCustomLogoInStore,
  setEnvironmentInfoInStore,
  setApplicationStatusInStore,
  setApplicationSettingsInStore,
  setProductTermsAcceptedInStore
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
