import React from 'react';
import { AccurityButton, AccurityMenuItem, DetailBottomBar, DetailMenu } from 'ts-components';
import Analytics from '../../../../common/analytics';
import { DetailBag } from '../../../../common/detail/types/types';
import { FormikProps } from 'formik';
import { DATA_SOURCE_TYPE, DataSource, DataSourceType } from '../../types/dataSourceTypes';
import { getTestConnectionEndpoint } from '../../../../common/rest/endpoints';
import { doFetch } from '../../../../common/rest/FetchService';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../../../common/userMessages/actions';
import { coreEntityAsFormData, setRedUnderlinesOnDetail } from '../../../../common/detail/actions/detailActions';
import { getDataSourceTestFailMessage, getDataSourceTestSuccessfulMessage } from '../../../../common/userMessages/commonMessages';

interface DataSourceDetailBottomBarProps {
  detailBag: DetailBag<DataSource>;
  formik: FormikProps<DataSource>;
}

const DataSourceDetailBottomBar = ({ detailBag, formik }: DataSourceDetailBottomBarProps) => {

  const trackAction = Analytics.createActionTrackerWithCallback('Detail: ' + DATA_SOURCE_TYPE);
  const testConnectionHandler = trackAction(() => testConnection(formik), 'Test Connection');

  const testConnection = (formik: FormikProps<DataSource>) => {
    return doFetch(getTestConnectionEndpoint(), 'POST', coreEntityAsFormData(formik.values), { doNotFillContentType: true })
      .then((success: boolean) => {
        if (success) {
          showSnackbarMessage(getDataSourceTestSuccessfulMessage());
        } else {
          showSnackbarMessage(getDataSourceTestFailMessage());
        }
      })
      .catch((error) => {
        showErrorMessageFromResponse(error);
        setRedUnderlinesOnDetail(error, formik.setFieldError);
      }
      )
  };

  return (
    <DetailBottomBar
      menu={(
        <DetailMenu>
          {detailBag.showRemoveAction && !detailBag.isRemoveDisabled && (
            <AccurityMenuItem onClick={trackAction(detailBag.removeAction, 'Remove Object')}>
              Remove
            </AccurityMenuItem>
          )}
          {detailBag.showCopyAction && (
            <AccurityMenuItem onClick={trackAction(detailBag.copyAction, 'Copy Object')}>
              Copy
            </AccurityMenuItem>
          )}
        </DetailMenu>
      )}
    >
      {formik.values.dataSourceType && formik.values.dataSourceType !== DataSourceType.EXCEL && (
        <AccurityButton
          onClick={testConnectionHandler}
          color="secondary"
        >
          Test Connection
        </AccurityButton>
      )}
      {detailBag.showReloadAction && (
        <AccurityButton
          onClick={trackAction(detailBag.reloadAction, 'Reload Object')}
          disabled={detailBag.isReloadDisabled}
          color="secondary"
        >
          Reload
        </AccurityButton>
      )}
      {detailBag.showSaveAction && (
        <AccurityButton
          onClick={trackAction(() => detailBag.saveAction(), 'Save Object')}
          disabled={detailBag.isSaveDisabled}
        >
          Save
        </AccurityButton>
      )}
    </DetailBottomBar>
  );
};

export default DataSourceDetailBottomBar;
