import React from 'react';

import { useSelector } from 'react-redux';
import { FormikProps, useFormikContext } from 'formik';

import { EnumOption, NumericFormat, Tooltip } from 'ts-components';

import AttributeCustomPropertyValueField from './AttributeCustomPropertyValueField';
import { CustomProperty, CustomPropertyPropertyType, DEFAULT_MAX_CUSTOM_PROPERTY_VALUE_ELEMENTS } from '../customProperty/types/customPropertyTypes';
import { AccurityCoreEntity, AccurityFilter } from '../../types/accurityTypes';
import EnumerationFieldWithFormik from '../../detail/formik/EnumerationFieldWithFormik';
import HyperlinkFieldWithFormik from '../../detail/formik/HyperlinkFieldWithFormik';
import MultiLineFieldWithFormik from '../../detail/formik/MultiLineFieldWithFormik';
import FeatureChecker from '../../userSettings/components/FeatureChecker';
import { Feature } from '../../userSettings/features/features';
import SingleLineFieldWithFormik from '../../detail/formik/SinglelineFieldWithFormik';
import { generateTooltipForCP, getDisplayCustomPropertyValueField, targetObjectTypeIcon, targetObjectTypeTypeEndpoint } from '../customPropertyUtils';
import { excludeFieldValueFilter } from '../../referenceField/utils/filters';
import { CustomPropertyTargetObjectType } from '../../../businessGlossary/customProperties/types';
import ReferenceFieldWithFormik from '../../detail/formik/ReferenceFieldWithFormik';
import DateFieldWithFormik from '../../detail/formik/DateFieldWithFormik';
import { UserSettingsState } from '../../userSettings/types/types';
import { useHasFeature } from '../../userSettings/hooks/features';
import { useDataSetTypeEnumOptions } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import CollectionFieldWithFormik from '../../detail/formik/CollectionFieldWithFormik';
import ProcessStepCustomPropertyValueField from './ProcessStepCustomPropertyValueField';
import DataStructureCustomPropertyValueField from './DataStructureCustomPropertyValueField';
import DataFieldCustomPropertyValueField from './DataFieldCustomPropertyValueField';

export type CustomPropertyFieldProps = {
  customProperty: CustomProperty;
  inherited?: boolean;
  expandedByDefault?: boolean;

};

const renderSingleCustomPropertyField = (fieldName: string,
                                         customProperty: CustomProperty,
                                         formik: FormikProps<AccurityCoreEntity>,
                                         multipleDataSetTypesAllowedFeature: boolean,
                                         dataSetTypeEnumOptions: EnumOption[],
                                         collectionValues?: any[],
                                         customPropertyInheritance?: boolean,
                                         tooltip?: Tooltip) => {

  const inherited = customPropertyInheritance || formik.getFieldProps(fieldName + '.inherited').value;
  switch (customProperty.propertyType) {
    case CustomPropertyPropertyType.ENUMERATION:
      return (
        <>
          <EnumerationFieldWithFormik
            name={fieldName + '.value'}
            label={customProperty.name}
            options={customProperty.options}
            filterOutValues={collectionValues}
            canBeEmpty={!customProperty.multiselection}
            tooltip={tooltip}
            inherited={inherited}
            coloringOverwrite={fieldName}
          />
        </>
      );
    case CustomPropertyPropertyType.HYPERLINK:
      return (
        <>
          <HyperlinkFieldWithFormik
            name={fieldName + '.value'}
            label={customProperty.name}
            tooltip={tooltip}
            inherited={inherited}
            coloringOverwrite={fieldName}
          />
        </>
      );
    case CustomPropertyPropertyType.TEXT:
      return (
        <>
          <MultiLineFieldWithFormik
            name={fieldName + '.value'}
            label={customProperty.name}
            tooltip={tooltip}
            inherited={inherited}
            coloringOverwrite={fieldName}
          />
        </>
      );
    case CustomPropertyPropertyType.NUMBER:
      return (
        <FeatureChecker featureId={Feature.ADVANCED_CUSTOM_PROPERTIES}>
          <SingleLineFieldWithFormik
            name={fieldName + '.value'}
            label={customProperty.name}
            format={NumericFormat.DECIMAL}
            tooltip={tooltip}
            inherited={inherited}
            coloringOverwrite={fieldName}
          />
        </FeatureChecker>
      );
    case CustomPropertyPropertyType.RELATION:
      const targetObjectType = customProperty.targetObjectType;

      const targetObjectTypeEndpoint = targetObjectTypeTypeEndpoint(targetObjectType);
      const icon = targetObjectTypeIcon(targetObjectType);
      const displayCustomPropertyValueField = getDisplayCustomPropertyValueField(targetObjectType);

      let additionalFilters: AccurityFilter[] = [];

      // Exclude already used values for custom property values
      collectionValues?.map(v => v?.reference)
        .filter(value => value !== null)
        .forEach(value => {
          if (!!value && !!value.id) {
            additionalFilters.push(
              excludeFieldValueFilter('id', value.id)
            );
          }
        });

      if (customProperty.forObjectType === targetObjectType && formik.values.id) {
        additionalFilters.push(
          excludeFieldValueFilter('id', formik.values.id)
        );
      }

      const referenceFieldProps = formik.getFieldProps(fieldName + '.reference').value;

      // when the reference object is selected, we want to show default tooltip generated by ReferenceField
      const showDefaultTooltip = !!referenceFieldProps;

      return (
        <FeatureChecker featureId={Feature.ADVANCED_CUSTOM_PROPERTIES}>
          {CustomPropertyTargetObjectType.PROCESS_STEP === targetObjectType &&
          <ProcessStepCustomPropertyValueField
            formik={formik}
            fieldName={fieldName}
            customProperty={customProperty}
            additionalFilters={additionalFilters}
            inherited={inherited}
            tooltip={tooltip}
          />}

          {CustomPropertyTargetObjectType.DATA_STRUCTURE === targetObjectType &&
          <DataStructureCustomPropertyValueField
            formik={formik}
            fieldName={fieldName}
            customProperty={customProperty}
            additionalFilters={additionalFilters}
            inherited={inherited}
            tooltip={tooltip}
          />
          }
          {CustomPropertyTargetObjectType.DATA_FIELD === targetObjectType &&
          <DataFieldCustomPropertyValueField
            formik={formik}
            fieldName={fieldName}
            customProperty={customProperty}
            additionalFilters={additionalFilters}
            inherited={inherited}
            tooltip={tooltip}
          />
          }

          {CustomPropertyTargetObjectType.ATTRIBUTE === targetObjectType &&
          <AttributeCustomPropertyValueField
            formik={formik}
            fieldName={fieldName}
            customProperty={customProperty}
            collectionValues={collectionValues}
            inherited={inherited}
          />
          }
          {displayCustomPropertyValueField && <ReferenceFieldWithFormik
            name={fieldName + '.reference'}
            icon={icon}
            label={customProperty.name}
            objectType={targetObjectTypeEndpoint}
            additionalFilters={additionalFilters}
            tooltip={tooltip}
            showDefaultTooltip={showDefaultTooltip}
            inherited={inherited}
            coloringOverwrite={fieldName}
          />}
        </FeatureChecker>
      );
    case CustomPropertyPropertyType.DATE:
      return (
        <FeatureChecker featureId={Feature.ADVANCED_CUSTOM_PROPERTIES}>
          <DateFieldWithFormik
            name={fieldName + '.value'}
            label={customProperty.name}
            tooltip={tooltip}
            inherited={inherited}
            coloringOverwrite={fieldName}
          />
        </FeatureChecker>
      );
    default:
      console.error('No Field found for CustomProperty Type: ', customProperty.propertyType);
      return null;
  }
};

const CustomPropertyValueField = ({ customProperty, inherited, expandedByDefault }: CustomPropertyFieldProps) => {
  const maxCustomPropertyValueElements = useSelector((state: { userSettings: UserSettingsState }) =>
    state.userSettings.maxCustomPropertyValueElements) || DEFAULT_MAX_CUSTOM_PROPERTY_VALUE_ELEMENTS;
  const customPropertyFieldName = `customPropertyValues.${customProperty.id}`;

  const formik = useFormikContext<AccurityCoreEntity>();
  const tooltip = generateTooltipForCP(customProperty);

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  if (customProperty.multiselection) {
    const maxElements = customProperty.propertyType === CustomPropertyPropertyType.ENUMERATION
      ? Math.min(customProperty.options.length, maxCustomPropertyValueElements)
      : maxCustomPropertyValueElements;

    return (
      <CollectionFieldWithFormik
        name={customPropertyFieldName}
        title={customProperty.name}
        maxRows={maxElements}
        expandedByDefault={expandedByDefault}
        inherited={inherited}
        tooltip={tooltip}
        rowValidation={value => value && (value.value || value.reference)}
        renderRow={(elementName, index, collectionValues) =>
          renderSingleCustomPropertyField(elementName, customProperty, formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions, collectionValues, false)
        }
      />
    );
  } else {
    // Single-value CPVs are still stored in array, so append '.0' to always deal with first element
    return renderSingleCustomPropertyField(customPropertyFieldName + '.0', customProperty, formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions, undefined, inherited, tooltip);
  }
};


export default CustomPropertyValueField;
