import React from 'react';
import { getDefaultListSettings } from '../../../list/listUtils';
import AccurityListContainer from '../../../list/redux/AccurityListContainer';
import { CUSTOM_PROPERTY_GROUP_ICON, CUSTOM_PROPERTY_GROUP_PLURAL_LABEL, CUSTOM_PROPERTY_GROUP_TYPE } from '../types/customPropertyGroupTypes';
import { useCustomPropertyGroupListColumns } from './customPropertyGroupListColumns';

const CustomPropertyGroupList = () => {

  const columns = useCustomPropertyGroupListColumns();
  const defaultSettings = getDefaultListSettings(CUSTOM_PROPERTY_GROUP_TYPE, columns);

  return (
    <AccurityListContainer
      objectType={CUSTOM_PROPERTY_GROUP_TYPE}
      title={CUSTOM_PROPERTY_GROUP_PLURAL_LABEL}
      icon={CUSTOM_PROPERTY_GROUP_ICON}
      columns={columns}
      defaultListSettings={defaultSettings}
    />
  )
};


export default CustomPropertyGroupList;
