import React from 'react';
import { VersionBrowserModal, VersionBrowserRevisionSelector } from 'ts-components';
import { FormikContext, FormikProps, useFormikContext, } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useLoadChanges } from '../hooks/loadChangesHook';
import { createFields } from '../utils/versionBrowserUtils';
import { useLoadRevisions } from '../hooks/loadRevisionsHook';
import { useSyncCollapsingGroups } from '../hooks/syncCollapsingGroupsHook';

interface AccurityVersionBrowserModalProps {
  getDetailFields: (formik: FormikProps<any>) => JSX.Element;
  closeModal: () => void;
}

export const AccurityVersionBrowserModal = ({ getDetailFields, closeModal }: AccurityVersionBrowserModalProps) => {

  const entity = useFormikContext<AccurityCoreEntity>().values;

  const { isLoadingRevisions, newerRevision, olderRevision, allRevisions, setRevisions } = useLoadRevisions(entity, closeModal);
  const newerRevisionIsCurrent = allRevisions[allRevisions.length - 1]?.revision === newerRevision?.revision;
  const { formikNewObject, formikOldObject, isLoadingChanges } = useLoadChanges(entity, olderRevision, newerRevision, newerRevisionIsCurrent);
  useSyncCollapsingGroups(formikOldObject, formikNewObject);

  const RevisionSelector = olderRevision && newerRevision ? <VersionBrowserRevisionSelector
    allRevision={allRevisions}
    initialNewRevision={newerRevision}
    initialOldRevision={olderRevision}
    onChange={setRevisions}
  /> : null;

  return (
    <VersionBrowserModal
      isOpen={true}
      isLoadingRevisions={isLoadingRevisions}
      isLoadingChanges={isLoadingChanges}
      onClose={closeModal}
      RevisionSelector={RevisionSelector}
    >
      <>
        {!isLoadingChanges && <div className={'versionBrowserComparison'}>
          {formikOldObject && <FormikContext.Provider value={formikOldObject}>
            {createFields(formikOldObject, 'old', getDetailFields)}
          </FormikContext.Provider>}
          {formikNewObject && <FormikContext.Provider value={formikNewObject}>
            {createFields(formikNewObject, 'new', getDetailFields)}
          </FormikContext.Provider>}
        </div>}
      </>
    </VersionBrowserModal>
  )
};
