import { useCallback } from 'react';
import { doBackgroundFetchByObjectTypeAndId } from '../../redux/commonActions';
import { isEqual } from 'lodash';
import { HasSyncResult } from '../types';
import { useReloadAction } from '../../detail/actions/detailReload';

export const useDetailReloadAfterSync = (objectType: string, id?: string) => {
  const doReload = useReloadAction(objectType, id!)

  const customHandleExternalUpdate = useCallback((defaultHandleExternalUpdate: () => void, entityInDetail: HasSyncResult) => {
    if (id) {
      doBackgroundFetchByObjectTypeAndId(objectType, id)
        .then((entityFromBackend: HasSyncResult) => {

          const updateWasOnlyToSyncResult = isEqual(
            {
              ...entityInDetail,
              changedBy: undefined,
              changedDate: undefined,
              version: undefined,
              childrenCounts: undefined,
              syncResult: undefined,
              isSyncInProgress: undefined,
            },
            {
              ...entityFromBackend,
              changedBy: undefined,
              changedDate: undefined,
              version: undefined,
              childrenCounts: undefined,
              syncResult: undefined,
              isSyncInProgress: undefined,
            }
          );

          if (updateWasOnlyToSyncResult) {
            doReload();
          } else {
            defaultHandleExternalUpdate();
          }
        })
    }
  }, [doReload, id]);

  return { customHandleExternalUpdate };
}
