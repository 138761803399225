import React from 'react';
import SwitchFieldWithFormik from '../../../../common/detail/formik/SwitchFieldWithFormik';
import { useFormikContext } from 'formik';
import { DataSource } from '../../types/dataSourceTypes';
import ConnectionGroup from './ConnectionGroup';
import UsernamePasswordGroup from './UsernamePasswordGroup';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';

const JdbcDataSourceFields = () => {
  const formik = useFormikContext<DataSource>();
  const isVersionBrowser = formik.status.isVersionBrowser;
  const isUseJdbc = formik.values.useJdbcUrl;

  return (
    <>
      <SwitchFieldWithFormik
        name={'useJdbcUrl'}
        label={'Use JDBC URL'}
      />
      {(isUseJdbc || isVersionBrowser) && <JdbcUrlField/>}
      {(!isUseJdbc || isVersionBrowser) && <ConnectionGroup/>}
      <UsernamePasswordGroup/>
    </>
  );
}

const JdbcUrlField = () => (
  <SingleLineFieldWithFormik
    name={'jdbcUrl'}
    label={'JDBC URL'}
  />
);

export default JdbcDataSourceFields;
