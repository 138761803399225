import React, { useState } from 'react';
import welcomeScreenLogo from '../../../businessGlossary/images/accurity-logo-vert.svg';
import { AccurityButton, SwitchField } from 'ts-components';
import { logout } from '../../userSettings/hooks/logout';
import { useAcceptProductTerms, useProductTermsSettings } from '../hooks/productTermsHook';

export const ProductTermsScreen = () => {

  const [productTermsAccepted, setProductTermsAccepted] = useState(false);
  const productTermsSettings = useProductTermsSettings();
  const acceptProductTerms = useAcceptProductTerms();

  const handleConfirm = () => {
    if (productTermsAccepted) {
      acceptProductTerms();
    }
  };

  const checkboxLabel = (
    <span>
      I agree to <a href={productTermsSettings.productTermsLink} target="_blank" rel="noreferrer">Product Terms</a>
    </span>
  );

  return (
    <div className="productTermsScreen">
      <img src={welcomeScreenLogo} className="productTermsScreen__logo" alt="Accurity"/>
      <div className="productTermsScreen__mainContainer">
        <SwitchField
          name={'product-terms'}
          value={productTermsAccepted}
          label={checkboxLabel}
          onChange={e => setProductTermsAccepted(e.currentTarget.checked)}
          displayAsCheckbox={true}
        />
        <div className="productTermsScreen__buttonsContainer">
          <AccurityButton
            onClick={() => logout()}
          >
            Cancel
          </AccurityButton>
          <AccurityButton
            variant="contained"
            onClick={() => handleConfirm()}
            disabled={!productTermsAccepted}
          >
            Confirm
          </AccurityButton>
        </div>
      </div>
    </div>
  )

};