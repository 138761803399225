import React from 'react';
import UsernamePasswordGroup from './UsernamePasswordGroup';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';

const SnowflakeDataSourceFields = () => (
  <>
    <SingleLineFieldWithFormik
      name={'host'}
      label={'Account Identifier'}
    />
    <SingleLineFieldWithFormik
      name={'database'}
      label={'Database'}
    />
    <SingleLineFieldWithFormik
      name={'schema'}
      label={'Schema'}
    />
    <UsernamePasswordGroup/>
  </>
);

export default SnowflakeDataSourceFields;
