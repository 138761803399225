import React, { useState } from 'react';
import { AccurityReference } from '../../types/accurityTypes';
import { CustomEditorControl, EditorInputDialog, EnumerationField, EnumOptionValue, useEditorContext } from 'ts-components';
import AccurityReferenceField from '../../referenceField/components/AccurityReferenceField';
import { useTranslation } from 'react-i18next';

interface AddChipControlProps {
  chipObjectTypes: string[];
}

const AddChipControl = ({ chipObjectTypes }: AddChipControlProps) => {
  const { t } = useTranslation();
  const [componentHelper, contentHelper, , chipsHelper] = useEditorContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [chipSourceReference, setChipSourceReference] = useState<AccurityReference | undefined>(undefined);

  const objectTypeOptions =
    chipObjectTypes.map(objectType => ({ value: objectType, label: t(`common.${objectType}`), }));
  const [selectedObjectType, setSelectedObjectType] = useState<EnumOptionValue>(objectTypeOptions[0].value);

  if (typeof selectedObjectType !== 'string') {
    return null;
  }

  const handleOpenDialog = () => {
    setChipSourceReference(undefined);
    setSelectedObjectType(objectTypeOptions[0].value);
    setShowDialog(true);
  }

  const handleCloseDialog = () => {
    setShowDialog(false);
    setTimeout(() => {
      componentHelper?.editorReference?.current?.focus();
    }, 0);
  }

  const handleConfirmDialog = chipSourceReference
    ? () => {
      chipsHelper.addChip(chipSourceReference);
      handleCloseDialog();
    }
    : undefined;

  return (
    <>
      <EditorInputDialog
        title={'Add Chip'}
        isOpen={showDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
      >
        <EnumerationField
          name={'selectObjectType'}
          label={'Select Object Type'}
          options={objectTypeOptions}
          value={selectedObjectType}
          onChange={(name, newValue) => {
            setChipSourceReference(undefined);
            setSelectedObjectType(newValue);
          }}
        />
        <AccurityReferenceField
          name={'selectChip'}
          label={'Select Chip Source'}
          value={chipSourceReference}
          setValue={setChipSourceReference}
          objectType={selectedObjectType}
          initialFilterText={contentHelper.currentlySelectedText}
        />
      </EditorInputDialog>
      <CustomEditorControl
        tooltip={`Add Reference Chip`}
        icon={'emptyLink'}
        onClick={handleOpenDialog}
      />
    </>
  );

}

export default AddChipControl;
