import { Column } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { PROCESS_DIAGRAM_BASED_ON_TYPE_LABEL, ProcessDiagramBasedOnTypeOptions } from '../types/processDiagramTypes';

export const useProcessDiagramColumns = () => {

  const isOracle = useIsOracleDb();

  const processDiagramColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle }),
    columnFactory.createEnumColumn('processDiagramBasedOnType', PROCESS_DIAGRAM_BASED_ON_TYPE_LABEL, ProcessDiagramBasedOnTypeOptions),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(processDiagramColumns, GlossaryCustomPropertyObjectType.PROCESS_DIAGRAM);

}
