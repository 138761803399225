import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, isEqual } from 'lodash';
import {
  BasicDataTable,
  Column,
  ColumnToggler,
  ColumnTogglerItemType,
  ExportColumnsType,
  ExportDelimiter,
  ExportFormatType,
  ExportRowsType,
  FilterValue,
  List,
  ListActionButton,
  ListExportSettings,
  ListImportSettings,
  ListMenu,
  ListPinFilter,
  ListReferenceFilter,
  ListRememberButton,
  Paginator,
  useAccurityTimezone
} from 'ts-components';

import { useAccurityNavigation } from '../../navigation/hooks';
import * as listUtils from '../listUtils';
import { areFiltersEqual, assembleParametersForSearch, changeFilterData, mergeSettingsAndFiltersOntoColumns } from '../listUtils';
import { AccurityFilter, AccurityPinFilter, AccuritySearch, AccuritySort, AccuritySortType } from '../../types/accurityTypes';
import { ColumnFilterData, ColumnSettings, ListSettings } from '../types/types';
import { usePermissionsForObjectType } from '../../userSettings/hooks/permissions';
import { ExportFormat, ExportSearch } from '../../dataTransfer/types';
import { createExportSearch } from '../../dataTransfer/utils';
import Analytics from '../../analytics';
import AccurityListObjectCounter, { AccurityListObjectCounterProps } from './AccurityListObjectCounter';
import { CrudPermissions } from '../../userSettings/types/types';
import { useBulkOperations } from '../../bulkOperations/hooks/bulkOperationsHook';
import BulkOperationsBar from '../../bulkOperations/components/BulkOperationsBar';
import { BulkOperationsConfig } from '../../bulkOperations/types';

interface AccurityListProps extends AccurityListObjectCounterProps {
  listType: string;
  title: string;
  icon: string;

  rows: any[];
  columns: Column[];

  totalRows: number;

  rememberedListSettings: ListSettings;
  globalPinFilters?: AccurityPinFilter[];
  setGlobalPinFilters: (globalPinFilters: AccurityPinFilter[]) => void;
  navigationPinFilters?: AccurityPinFilter[];
  navigationReferenceFieldFilters?: AccurityFilter[];
  navigationColumnFilters?: ColumnFilterData[];

  isLoading?: boolean;
  doBackendListSearch: (search: AccuritySearch) => void;
  didInvalidateListSearch: boolean;

  hideAddButton?: boolean;
  hideObjectCounter?: boolean;
  handleRememberButton: (settings: ListSettings) => void;
  handleRestoreButton: () => void;
  handleExportButton: (format: ExportFormat, exportSearch: ExportSearch) => void;
  partialImportOnly?: boolean;
  allowImport?: boolean;
  allowExport?: boolean;
  handleImportButton: (file: File, partialImport: boolean) => void;
  permissionsOverwrite?: CrudPermissions;
  handleRowSelectOverwrite?: (row: any) => void;
  bulkOperationsConfig?: BulkOperationsConfig;
}

const COLUMN_FILTER_CHANGE_SEARCH_DELAY = 300;

const AccurityList = ({
                        listType,
                        title,
                        icon,
                        rows,
                        columns,
                        totalRows,
                        existingObjects,
                        maximumObjects,
                        additionalExistingObjects,
                        additionalMaximumObjects,
                        isLoading,
                        allowImport = true,
                        allowExport = true,
                        rememberedListSettings,
                        globalPinFilters = [],
                        setGlobalPinFilters,
                        navigationPinFilters,
                        navigationReferenceFieldFilters = [],
                        doBackendListSearch,
                        didInvalidateListSearch,
                        hideAddButton,
                        hideObjectCounter,
                        handleRememberButton,
                        handleRestoreButton,
                        handleExportButton,
                        handleImportButton,
                        navigationColumnFilters = [],
                        partialImportOnly,
                        permissionsOverwrite,
                        handleRowSelectOverwrite,
                        bulkOperationsConfig,
                      }: AccurityListProps) => {
  const isInitialRender = useRef(true);
  const trackAction = Analytics.createActionTracker('List: ' + listType);

  // navigation params
  const navigationController = useAccurityNavigation();
  const { detailId, detailType } = navigationController.getCurrentNavigation();
  const timezone = useAccurityTimezone();
  let permissions: CrudPermissions = usePermissionsForObjectType(listType);
  if (permissionsOverwrite) {
    permissions = permissionsOverwrite;
  }
  const bulkOperationsManager = useBulkOperations(listType, rows, permissions, bulkOperationsConfig);

  const [maxResults, setMaxResults] = useState<number>(rememberedListSettings.maxResults);
  const [startFrom, setStartFrom] = useState<number>(0);
  const [sort, setSort] = useState<AccuritySort>(rememberedListSettings.sort);

  const [columnSettings, setColumnSettings] = useState<ColumnSettings[]>(rememberedListSettings.columnSettings);
  const [columnFilters, setColumnFilters] = useState<ColumnFilterData[]>(navigationColumnFilters);
  const [columnsWithSettingsAndFilters, setColumnsWithSettingsAndFilters] =
    useState(() => mergeSettingsAndFiltersOntoColumns(columns, columns, columnSettings, columnFilters, sort));

  useEffect(() => {
    setColumnsWithSettingsAndFilters((prevColumns: Column[]) => mergeSettingsAndFiltersOntoColumns(prevColumns, columns, columnSettings, columnFilters, sort));
  }, [columnSettings, columnFilters, sort, columns]);

  const [pinFilters, setPinFilters] = useState<AccurityPinFilter[]>(navigationPinFilters
    ? [...rememberedListSettings.pinFilters, ...navigationPinFilters]
    : rememberedListSettings.pinFilters);

  const [referenceFieldFilters, setReferenceFieldFilters] = useState<AccurityFilter[]>(navigationReferenceFieldFilters);

  const searchObjectRef = useRef<AccuritySearch>();
  useEffect(() => {
    searchObjectRef.current =
      assembleParametersForSearch(listType, columnFilters, referenceFieldFilters, globalPinFilters, pinFilters, startFrom, maxResults, sort);
  }, [listType, columnFilters, referenceFieldFilters, globalPinFilters, pinFilters, startFrom, maxResults, sort]);

  // redirect to the first page when any search-related parameter changes that could affect the number of results
  useEffect(() => {
    setStartFrom(0);
  }, [columnFilters, referenceFieldFilters, globalPinFilters, pinFilters, maxResults]);

  const doSearch = useCallback(() =>
    searchObjectRef.current && doBackendListSearch(searchObjectRef.current),
    [doBackendListSearch]);

  const debouncedDoSearch = useMemo(() => {
    return debounce(doSearch, COLUMN_FILTER_CHANGE_SEARCH_DELAY)
  }, [doSearch]);

  // When any non-column filter search param changes, immediately redo the search
  useEffect(() => {
      doSearch();
    }, [doSearch, referenceFieldFilters, globalPinFilters, pinFilters, startFrom, maxResults, sort]
  );

  // When search is invalidated (eg. websocket arrived), redo the search. Skip first render because search will always fire anyway
  useEffect(() => {
    if (!isInitialRender.current && didInvalidateListSearch) {
      doSearch();
    }
  }, [doSearch, didInvalidateListSearch]);

  // When column filter search param changes, redo the search with debounce so we search after User stops typing
  useEffect(() => {
    if (!isInitialRender.current) {
      debouncedDoSearch();
    }
  }, [debouncedDoSearch, columnFilters]);

  // Keep this as final useEffect so that all other useEffects read it as `true` on initial render
  useEffect(() => {
    isInitialRender.current = false;
  });

  const assembledListSettingsForRemember: ListSettings = {
    type: rememberedListSettings.type,
    pinFilters: pinFilters,
    columnSettings: columnSettings,
    maxResults: maxResults,
    sort: sort,
  };

  const handleListSettingsRemember = () => {
    handleRememberButton(assembledListSettingsForRemember);
    trackAction('Remember List Settings');
  };

  const handleListSettingsRestore = () => {
    handleRestoreButton();
    trackAction('Restore List Settings');
  };

  const handleRowSelect = (objectId: string, row: any) => {
    if (handleRowSelectOverwrite) {
      handleRowSelectOverwrite(row);
    } else {
      navigationController.openDetailWithObject(listType, row.id);
    }
    trackAction('Row Selected');
  };

  const handleColumnFilterChange = (column: Column, filterValue: FilterValue) => {
    const newFilters = changeFilterData(columnFilters, column, filterValue, timezone);
    if (!areFiltersEqual(columnFilters, newFilters)) {
      setColumnFilters(newFilters);
      trackAction('Column Filter Changed', `${column.name}`);
    }
  };

  const handlePageChange = (limit: number, offset: number) => {
    setStartFrom(offset);
    setMaxResults(limit);
    trackAction('Page Changed', `Page size: ${limit}, Offset ${offset}`);
  };

  const handleSortChange = (property: string, value?: string) => {
    const isNewProperty = property !== sort.property;
    const newType = isNewProperty ? AccuritySortType.ASCENDING
      : sort.type === AccuritySortType.ASCENDING ? AccuritySortType.DESCENDING : AccuritySortType.ASCENDING;
    setSort({
      type: newType,
      property: property,
    });
    trackAction('Column Sort Change', `${property} - ${newType}`);
  };

  const handleColumnVisibilityChange = (changedColumns: ColumnTogglerItemType[]) => {

    const newColumnSettings = columnSettings.map(column => {
      const idx = changedColumns.findIndex(q => q.name === column.name);
      return idx !== -1
        ? { ...column, hidden: changedColumns[idx].hidden }
        : column;
    });

    const numberOfVisible = newColumnSettings.reduce((acc, current) => !current.hidden ? acc + 1 : acc, 0);
    if (numberOfVisible === 0) {
      const firstChangedColumn = newColumnSettings.findIndex(q => q.name === changedColumns[0].name);
      newColumnSettings[firstChangedColumn] = { ...newColumnSettings[firstChangedColumn], hidden: false };
    }

    setColumnSettings(newColumnSettings);
    changedColumns.forEach(column => {
      trackAction('Column Visibility Change', `${column.name} - ${column.hidden ? 'show' : 'hide'}`);
    });
  };

  const handleColumnDragAndDrop = (dragIndex: number, dropIndex: number) => {
    const newColumnSettings = columnSettings.map(c => ({ ...c }));

    const draggingColumn = newColumnSettings[dragIndex];
    if (draggingColumn) {
      trackAction('Column DnD', `${draggingColumn.name} from ${dragIndex} to ${dropIndex}`);
    }

    newColumnSettings.splice(dropIndex, 0, newColumnSettings.splice(dragIndex, 1)[0]);
    setColumnSettings(newColumnSettings);
  };

  const handleColumnResize = (resizedWidths: { [name: string]: number }) => {
    const newColumnSettings = columnSettings.map(columnSetting => {
      if (resizedWidths[columnSetting.name]) {
        return {
          ...columnSetting,
          width: resizedWidths[columnSetting.name],
        }
      } else {
        return columnSetting;
      }
    });
    setColumnSettings(newColumnSettings);
  };

  const handlePinFilterRemove = (removeIndex: number) => {
    setPinFilters(pinFilters.filter((pinFilter, index) =>
      index !== removeIndex)
    );
    trackAction('Pin Filter - remove');
  };

  const handlePinFilterDisable = (disableIndex: number) => {
    setPinFilters(pinFilters.map((pinFilter, index) =>
      index === disableIndex ? { ...pinFilter, disabled: !pinFilter.disabled } : pinFilter
    ));
    trackAction('Pin Filter - disable/enable');
  };

  const handlePinFilterMakeGlobal = (pinFilter: AccurityPinFilter, index: number) => {
    setGlobalPinFilters([pinFilter, ...globalPinFilters]);
    handlePinFilterRemove(index);
    trackAction('Pin Filter - make global');
  };

  const handleTemplate = () => {
    const exportSearch = createExportSearch(
      {
        format: ExportFormatType.XLSX,
        delimiter: ExportDelimiter.COMMA,
        columns: ExportColumnsType.REQUIRED_FOR_IMPORT,
        rows: ExportRowsType.HEADERS_ONLY
      },
      {
        objectType: listType,
        filename: title,
        columns: columnsWithSettingsAndFilters,
        offset: startFrom,
        limit: maxResults,
        size: totalRows,
        pinFilters: pinFilters,
        sort: sort,
      },
      timezone);
    handleExportButton(ExportFormatType.XLSX, exportSearch);
    trackAction('Import Template');
  };

  const handleExport = (exportSettings: ListExportSettings) => {
    const exportSearch = createExportSearch(exportSettings,
      {
        objectType: listType,
        filename: title,
        columns: columnsWithSettingsAndFilters,
        offset: startFrom,
        limit: maxResults,
        size: totalRows,
        pinFilters: pinFilters,
        sort: sort,
      },
      timezone);
    handleExportButton(exportSettings.format, exportSearch);
    trackAction('Export Data');
  };

  const handleImport = (importSettings: ListImportSettings) => {
    handleImportButton(importSettings.file, importSettings.partialImport);
    trackAction('Import Data');
  };

  const handleCreateNewObject = () => {
    navigationController.openDetail(listType);
    trackAction('Create New Object');
  };

  const handleReferenceFilterRemove = () => {
    setReferenceFieldFilters([]);
    trackAction('ReferenceField filter remove');
  };

  const listSettingsChanged = !isEqual(assembledListSettingsForRemember, rememberedListSettings);

  if (!columnsWithSettingsAndFilters) {
    return null;
  }

  return (
    <>
      <List
        icon={icon}
        title={title}
        isProgressBarShown={isLoading}
        ActionButton={!hideAddButton && permissions.hasCreatePermission && <ListActionButton onClick={handleCreateNewObject}/>}
        HeaderRightNode={
          <>
            {listSettingsChanged && <ListRememberButton onClick={handleListSettingsRemember}/>}
            {referenceFieldFilters.length > 0 && <ListReferenceFilter onClick={handleReferenceFilterRemove}/>}
            <BulkOperationsBar
              bulkOperationsManager={bulkOperationsManager}
              listType={listType}
            />
            <ColumnToggler
              columns={listUtils.transformColumnsForToggler(columnsWithSettingsAndFilters)}
              onClick={handleColumnVisibilityChange}
            />
            <ListMenu
              listTitle={title}
              onTemplate={handleTemplate}
              onImport={(permissions.hasCreatePermission && allowImport) ? handleImport : undefined}
              onExport={allowExport ? handleExport : undefined}
              onRestore={handleListSettingsRestore}
              partialImportOnly={!!partialImportOnly}
            />
          </>
        }
        PinFilters={
          pinFilters.map((pinFilter, index) =>
            <ListPinFilter
              key={`${pinFilter.label}-${pinFilter.property}`}
              icon={pinFilter.icon}
              text={pinFilter.label || ''}
              disabled={pinFilter.disabled}
              onRemove={() => handlePinFilterRemove(index)}
              onDisable={() => handlePinFilterDisable(index)}
              onMakeGlobal={() => handlePinFilterMakeGlobal(pinFilter, index)}
            />
          )
        }
        Content={
          <>
            <BasicDataTable
              id={listType}
              uniqueColumnName={'id'}
              highlightedRows={detailType === listType && detailId ? [detailId] : []}
              data={rows}
              columns={columnsWithSettingsAndFilters}
              onRowClick={handleRowSelect}
              resizeAction={handleColumnResize}
              columnDropAction={handleColumnDragAndDrop}
              onFilterChange={handleColumnFilterChange}
              sortAction={handleSortChange}
              width={0}
              checkboxSelection={bulkOperationsManager.isEnabled}
              selectedRows={bulkOperationsManager.selectedRowsIds}
              onRowSelectionChanged={bulkOperationsManager.handleRowSelectionChanged}
            />
          </>
        }
        FooterContent={
          <>
            {!hideObjectCounter &&
            <AccurityListObjectCounter
              listType={listType}
              maximumObjects={maximumObjects}
              existingObjects={existingObjects}
              additionalExistingObjects={additionalExistingObjects}
              additionalMaximumObjects={additionalMaximumObjects}
            />}
            <Paginator
              updatePage={handlePageChange}
              size={totalRows}
              offset={startFrom}
              limit={maxResults}
            />
          </>
        }
      />
    </>
  );
};

export default AccurityList;
