import { CoreEntityState } from '../../../redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../types/accurityTypes';
import { EnumOption } from 'ts-components';
import { BUSINESS_TERM_LABEL, BUSINESS_TERM_PLURAL_LABEL } from '../../../../businessGlossary/businessTerm/types/businessTermTypes';
import { DATA_SET_LABEL, DATA_SET_PLURAL_LABEL, DataSetReference } from '../../../../businessGlossary/dataSet/types/dataSetTypes';
import { DATA_STRUCTURE_LABEL, DATA_STRUCTURE_PLURAL_LABEL, DataStructureReference } from '../../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DATA_FIELD_LABEL, DATA_FIELD_PLURAL_LABEL } from '../../../../businessGlossary/dataField/types/dataFieldTypes';
import { CustomPropertyTargetObjectType, GlossaryCustomPropertyObjectType } from '../../../../businessGlossary/customProperties/types';
import { ENTITY_LABEL, ENTITY_PLURAL_LABEL } from '../../../../businessGlossary/entity/types/entityTypes';
import { COMPOSITE_TYPE_LABEL, COMPOSITE_TYPE_PLURAL_LABEL } from '../../../../businessGlossary/compositeType/types/compositeTypeTypes';
import { ATTRIBUTE_DEFINITION_LABEL, ATTRIBUTE_DEFINITION_PLURAL_LABEL } from '../../../../businessGlossary/attributeDefinition/types/attributeDefinitionTypes';
import { ATTRIBUTE_LABEL, ATTRIBUTE_PLURAL_LABEL } from '../../../../businessGlossary/attribute/types/attributeTypes';
import { REQUIREMENT_LABEL, REQUIREMENT_PLURAL_LABEL } from '../../../../businessGlossary/requirement/types/requirementTypes';
import { BUSINESS_MODEL_MAPPING_LABEL, BUSINESS_MODEL_MAPPING_PLURAL_LABEL } from '../../../../businessGlossary/businessModelMapping/types/businessModelMappingTypes';

export const CUSTOM_PROPERTY_TYPE = 'custom-property';
export const CUSTOM_PROPERTY_LABEL = 'Custom Property';
export const CUSTOM_PROPERTY_PLURAL_LABEL = 'Custom Properties';
export const CUSTOM_PROPERTY_ICON = 'customProperty';
export const CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD = 'defaultValue';
export const CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL = 'Default Value';
export const CUSTOM_PROPERTY_DEFAULT_LABEL = 'Default';

export interface CustomPropertyState extends CoreEntityState<CustomProperty> {
}

export enum CustomPropertyPropertyType {
  DATE = 'DATE',
  ENUMERATION = 'ENUMERATION',
  HYPERLINK = 'HYPERLINK',
  NUMBER = 'NUMBER',
  RELATION = 'RELATION',
  TEXT = 'TEXT',
}

export const advancedCustomPropertyTypes = [CustomPropertyPropertyType.NUMBER];

export const DEFAULT_MAX_CUSTOM_PROPERTY_VALUE_ELEMENTS = 10;

// Maintain alphabetical order of enum elements
export const CustomPropertyPropertyTypeOptions: EnumOption[] = [
  { value: CustomPropertyPropertyType.HYPERLINK, label: 'Hyperlink' },
  { value: CustomPropertyPropertyType.ENUMERATION, label: 'Enumeration' },
  { value: CustomPropertyPropertyType.TEXT, label: 'Text' },
];

// Maintain alphabetical order of enum elements
export const AdvancedCustomPropertyPropertyTypeOptions: EnumOption[] = [
  { value: CustomPropertyPropertyType.DATE, label: 'Date' },
  { value: CustomPropertyPropertyType.ENUMERATION, label: 'Enumeration' },
  { value: CustomPropertyPropertyType.HYPERLINK, label: 'Hyperlink' },
  { value: CustomPropertyPropertyType.NUMBER, label: 'Number' },
  { value: CustomPropertyPropertyType.RELATION, label: 'Relation' },
  { value: CustomPropertyPropertyType.TEXT, label: 'Text' },
];

export interface CustomProperty extends AccurityCoreEntity {
  customPropertyGroup: AccurityReference;
  forObjectType: string;
  propertyType: CustomPropertyPropertyType;
  targetObjectType: CustomPropertyTargetObjectType;
  options: CustomPropertyOption[];
  orderOptionsAlphabetically: boolean;
  multiselection: boolean;
  defaultValue?: CustomPropertyValue;
  mandatory: boolean;
  valueOrder: number;
}

export interface CustomPropertyOption {
  id: number;
  value: string;
}

export interface CustomPropertyValues {
  [id: string]: CustomPropertyValue[];
}

export interface CustomPropertyValue {
  value: string;
  reference?: AccurityReference;
  entityReference?: AccurityReference;
  attributeDefinitionReference?: AccurityReference;
  processReference?: AccurityReference;
  dataSetReference?: DataSetReference;
  dataStructureReference?: DataStructureReference;
}

export const getObjectTypeLabel = (objectType?: string, plural?: boolean) => {
  switch (objectType) {
    case GlossaryCustomPropertyObjectType.BUSINESS_TERM:
      return plural ? BUSINESS_TERM_PLURAL_LABEL : BUSINESS_TERM_LABEL;
    case GlossaryCustomPropertyObjectType.ENTITY:
      return plural ? ENTITY_PLURAL_LABEL : ENTITY_LABEL;
    case GlossaryCustomPropertyObjectType.COMPOSITE_TYPE:
      return plural ? COMPOSITE_TYPE_PLURAL_LABEL : COMPOSITE_TYPE_LABEL;
    case GlossaryCustomPropertyObjectType.ATTRIBUTE_DEFINITION:
      return plural ? ATTRIBUTE_DEFINITION_PLURAL_LABEL : ATTRIBUTE_DEFINITION_LABEL;
    case GlossaryCustomPropertyObjectType.ATTRIBUTE:
      return plural ? ATTRIBUTE_PLURAL_LABEL : ATTRIBUTE_LABEL;
    case GlossaryCustomPropertyObjectType.DATA_SET:
      return plural ? DATA_SET_PLURAL_LABEL : DATA_SET_LABEL;
    case GlossaryCustomPropertyObjectType.DATA_STRUCTURE:
      return plural ? DATA_STRUCTURE_PLURAL_LABEL : DATA_STRUCTURE_LABEL;
    case GlossaryCustomPropertyObjectType.DATA_FIELD:
      return plural ? DATA_FIELD_PLURAL_LABEL : DATA_FIELD_LABEL;
    case GlossaryCustomPropertyObjectType.REQUIREMENT:
      return plural ? REQUIREMENT_PLURAL_LABEL : REQUIREMENT_LABEL;
    case GlossaryCustomPropertyObjectType.BUSINESS_MODEL_MAPPING:
      return plural ? BUSINESS_MODEL_MAPPING_PLURAL_LABEL : BUSINESS_MODEL_MAPPING_LABEL;
    default:
      return null;
  }
};

export type CustomPropertyInheritanceObject = {
  [customPropertyId: string]: boolean
}
