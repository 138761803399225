import React from 'react';
import { AccurityButton } from 'ts-components';
import { DATA_FIELD_TYPE, DataField } from '../../../businessGlossary/dataField/types/dataFieldTypes';
import Analytics from '../../analytics';
import { doFetch } from '../../rest/FetchService';
import { getDataFieldDoProfilingEndpoint } from '../../rest/endpoints';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { useDispatch } from 'react-redux';
import { markDataFieldIsBeingProfiledInStore } from '../redux/columnProfilingSlice';
import { useOpenCanvasOnProfilingFinish } from '../hooks/openCanvasOnProfilingFinishHook';
import { useSelectIsProfilingInProgress } from '../redux/selectors';

interface DoColumnProfilingButtonProps {
  dataField?: DataField,
}

const DoColumnProfilingButton = ({ dataField }: DoColumnProfilingButtonProps) => {

  const actionWrapper = Analytics.createActionTrackerWithCallback('Detail: ' + DATA_FIELD_TYPE);
  const dispatch = useDispatch();
  const canvasAutoOpener = useOpenCanvasOnProfilingFinish(dataField?.id);
  const isProfilingInProgress = useSelectIsProfilingInProgress(dataField?.id);

  if (!dataField?.id) {
    return null;
  }

  const doProfiling = () => {
    // note: data field is marked as profiled in columnProfilingCommandHandler.ts when websocket message arrives
    dispatch(markDataFieldIsBeingProfiledInStore({ dataFieldId: dataField.id }));
    canvasAutoOpener.profilingTriggered();
    doFetch(getDataFieldDoProfilingEndpoint(dataField.id), 'POST')
      .catch(showErrorMessageFromResponse);
  };

  return (
    <AccurityButton
      disabled={isProfilingInProgress}
      onClick={actionWrapper(doProfiling, 'Profile Data Set')}
      color="secondary"
    >
      Profile
    </AccurityButton>
  );
};

export default DoColumnProfilingButton;
