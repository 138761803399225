import React from 'react';
import { ATTRIBUTE_ICON } from '../types/attributeTypes';
import { DetailBag } from '../../../common/detail/types/types';
import SettingsDetail from '../../../common/detail/commonFields/SettingsDetail';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';
import KeepDiscardButtonBar, { KeepDiscardAction } from './KeepDiscardButtonBar';

interface AttributeConfirmEntityOrAttDefChangeDetailProps {
  detailBag: DetailBag<AccurityCoreEntity>,
}

//Setting form SF16D

const CONFIRM_TEXT = 'Changing Entity and/or Attribute Definition of this Attribute will leave existing inherited Attributes without a parent. ' +
  'You can either keep these inherited Attributes (turning them into non-inherited), or discard them.';

const AttributeConfirmEntityOrAttDefChangeDetail = ({ detailBag }: AttributeConfirmEntityOrAttDefChangeDetailProps) => {
  const formik = useFormikContext<AccurityCoreEntity>();

  return (
    <SettingsDetail
      objectName={formik.values.name}
      iconName={ATTRIBUTE_ICON}
      confirmDescription={CONFIRM_TEXT}
      bottomBar={
        <KeepDiscardButtonBar
          detailBag={detailBag}
          keepDiscardAction={KeepDiscardAction.SAVE}
        />
      }
    />
  );

};

export default AttributeConfirmEntityOrAttDefChangeDetail;
