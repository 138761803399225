import { DataManagementCommand } from '../../../types';
import { pushDataManagementCenterNotification } from '../../../../dataManagement/actions';

export const handleDataManagementCommand = (dataManagementCommand: DataManagementCommand) => {
  const { dataManagementId, dataManagementMessageType } = dataManagementCommand;
  const importFinishDate = Date.now();
  pushDataManagementCenterNotification({
    id: dataManagementId,
    date: importFinishDate,
    dataManagementMessageType: dataManagementMessageType
  });
};