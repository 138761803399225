import React from 'react';
import { DetailHeader, NumericFormat, StaticField } from 'ts-components';
import NameFieldWithFormik from '../../../detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../detail/formik/DescriptionFieldWithFormik';
import SingleLineFieldWithFormik from '../../../detail/formik/SinglelineFieldWithFormik';
import DefaultDetailBottomBar from '../../../detail/components/DefaultDetailBottomBar';
import AccurityDetailContainer from '../../../detail/redux/AccurityDetailContainer';
import HistoryGroupWithFormik from '../../../detail/formik/HistoryGroupWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_CURRENTLY_LABEL } from '../../../detail/types/types';
import ConfirmRemovalDetail from '../../../detail/commonFields/ConfirmRemovalDetail';
import { COLLAPSED_BY_DEFAULT_LABEL, CUSTOM_PROPERTY_GROUP_ICON, CUSTOM_PROPERTY_GROUP_TYPE, CustomPropertyGroup } from '../types/customPropertyGroupTypes';
import SwitchFieldWithFormik from '../../../detail/formik/SwitchFieldWithFormik';
import ChildrenCountFieldWithFormik from '../../../detail/formik/ChildrenCountFieldWithFormik';
import { CUSTOM_PROPERTY_ICON, CUSTOM_PROPERTY_LABEL, CUSTOM_PROPERTY_PLURAL_LABEL, CUSTOM_PROPERTY_TYPE } from '../../customProperty/types/customPropertyTypes';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../versionBrowserField/components/VersionBrowserButton';
import { ChildrenCountWording } from '../../../childrenCountField/types';
import DetailTopBar from '../../../detail/components/DetailTopBar';

interface CustomPropertyGroupDetailProps {
  id?: string,
}

const CustomPropertyGroupDetail = ({ id }: CustomPropertyGroupDetailProps) => {

  return (
    <AccurityDetailContainer<CustomPropertyGroup>
      objectType={CUSTOM_PROPERTY_GROUP_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar objectType={CUSTOM_PROPERTY_GROUP_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<CustomPropertyGroup>, detailBag: DetailBag<CustomPropertyGroup>) => (
  <ConfirmRemovalDetail
    iconName={CUSTOM_PROPERTY_GROUP_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={IT_CURRENTLY_LABEL}
      inverted={true}
    />
    <ChildrenCountFieldWithFormik
      singularLabel={CUSTOM_PROPERTY_LABEL}
      pluralLabel={CUSTOM_PROPERTY_PLURAL_LABEL}
      value={formik.values.childrenCounts.customPropertiesCount}
      iconName={CUSTOM_PROPERTY_ICON}
      pinIconName={CUSTOM_PROPERTY_GROUP_ICON}
      childFieldType={CUSTOM_PROPERTY_TYPE}
      childrenCountWording={ChildrenCountWording.HAS}
      inverted={true}
    />
  </ConfirmRemovalDetail>

);

const getDetailFields = (formik: FormikProps<CustomPropertyGroup>) => {
  return (
    <>
      <DetailHeader iconName={CUSTOM_PROPERTY_GROUP_ICON}>
        <NameFieldWithFormik label={'Custom Property Group Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={getDetailFields}
        />}
      />
      <SwitchFieldWithFormik
        name={'collapsedByDefault'}
        label={COLLAPSED_BY_DEFAULT_LABEL}
      />
      <SingleLineFieldWithFormik
        name={'groupOrder'}
        label={'Order'}
        type={'text'}
        format={NumericFormat.POSITIVE_NUMERIC}
      />
      <ChildrenCountFieldWithFormik
        singularLabel={CUSTOM_PROPERTY_LABEL}
        pluralLabel={CUSTOM_PROPERTY_PLURAL_LABEL}
        value={formik.values.childrenCounts.customPropertiesCount}
        iconName={CUSTOM_PROPERTY_ICON}
        pinIconName={CUSTOM_PROPERTY_GROUP_ICON}
        childFieldType={CUSTOM_PROPERTY_TYPE}
        childrenCountWording={ChildrenCountWording.HAS}
      />
    </>
  );
};

export default CustomPropertyGroupDetail;
