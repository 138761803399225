import React from 'react';
import { BulkOperationsManager } from '../hooks/bulkOperationsHook';
import { getRemovalDialogMessage } from '../messages';
import { TagsEditDialogContent } from './TagsEditDialogContent';
import { ListBulkOperations } from 'ts-components';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';

interface BulkOperationsBarProps {
  bulkOperationsManager: BulkOperationsManager;
  listType: string;
}

const BulkOperationsBar = ({ bulkOperationsManager, listType }: BulkOperationsBarProps) => {
  const hasFeature = useHasFeature();
  const hasCommentsFeature = hasFeature(Feature.COMMENTS);

  if (!bulkOperationsManager.isBulkActionsVisible) {
    return null;
  }

  return (
    <ListBulkOperations
      inProgress={bulkOperationsManager.isInProgress}
      editTagsDialogContent={<TagsEditDialogContent bulkOperationsManager={bulkOperationsManager}/>}
      removalDialogContent={getRemovalDialogMessage(listType, bulkOperationsManager.selectedRowsIds.length)}
      onRemove={bulkOperationsManager.removeItems}
      onNotificationChange={bulkOperationsManager.toggleNotification}
      onTagsUpdate={bulkOperationsManager.saveTags}
      removeOperationEnabled={bulkOperationsManager.removeOperationEnabled}
      updateTagsOperationEnabled={bulkOperationsManager.updateTagsOperationEnabled}
      toggleEmailNotificationsOperationEnabled={bulkOperationsManager.toggleEmailNotificationsOperationEnabled}
      displayCommentsNotifications={hasCommentsFeature}
    />
  );
};

export default BulkOperationsBar;

