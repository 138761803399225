import { doFetch } from '../../../common/rest/FetchService';
import { getCreateProcessDiagramBasedOnEndpoint } from '../../../common/rest/endpoints';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramBasedOnType } from '../types/processDiagramTypes';
import { showErrorMessageFromResponse } from '../../../common/userMessages/actions';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { AccurityMenuItem } from 'ts-components';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';

interface GenerateProcessDiagramButtonProps {
  id?: string;
  basedOnType: ProcessDiagramBasedOnType;
  objectType: string;
}

const GenerateProcessDiagramButton = ({ id, basedOnType, objectType }: GenerateProcessDiagramButtonProps) => {
  const navigationController = useAccurityNavigation();

  const generateProcessDiagram = !id ? undefined : () => {
    doFetch(getCreateProcessDiagramBasedOnEndpoint(basedOnType, objectType, id), 'POST')
      .then((processDiagramId: string) => {
        navigationController.openDetailAndDiagram(PROCESS_DIAGRAM_TYPE, processDiagramId, processDiagramId);
      })
      .catch(showErrorMessageFromResponse);
  };

  return (
    <AccurityMenuItem
      key={'generateProcessDiagram'}
      onClick={() => generateProcessDiagram?.()}
    >
      Generate Process Diagram
    </AccurityMenuItem>
  );
}

export default GenerateProcessDiagramButton;
