import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { PROCESS_DIAGRAM_ICON, PROCESS_DIAGRAM_PLURAL_LABEL, PROCESS_DIAGRAM_TYPE } from '../types/processDiagramTypes';
import { useProcessDiagramColumns } from './processDiagramColumns';

const ProcessDiagramList = () => {
  const processDiagramColumns = useProcessDiagramColumns();
  const processDiagramListSettings = getDefaultListSettings(PROCESS_DIAGRAM_TYPE, processDiagramColumns);

  return (
    <AccurityListContainer
      objectType={PROCESS_DIAGRAM_TYPE}
      title={PROCESS_DIAGRAM_PLURAL_LABEL}
      icon={PROCESS_DIAGRAM_ICON}
      columns={processDiagramColumns}
      defaultListSettings={processDiagramListSettings}
      allowExport={false}
      allowImport={false}
    />
  );
};

export default ProcessDiagramList;
