import { AccurityMessage, WebsocketMessageType } from './types';
import { parseWebsocketMessage } from './utils';
import { handleLogoutNotification } from './handlers/notification/logoutHandler';
import { handleErrorNotification } from './handlers/notification/errorHandler';
import rootStore from '../redux/rootStore';
import { invalidateAllEntityTypesInStore } from '../redux/commonReducers';
import { handleCrudCommand } from './handlers/crud/crudHandler';
import { Message } from '@stomp/stompjs';
import { handleBulkOperationCommand } from './handlers/bulkOperations/bulkOperationCommandHandler';
import { handleColumnProfilingFinishedCommand, handleColumnProfilingStartedCommand } from './handlers/columnProfiling/columnProfilingCommandHandler';
import { handleDataManagementCommand } from './handlers/data/management/dataManagementCommandHandler';
import { handleExportJobResultCommand, handleImportExceptionCommand, handleImportJobResultCommand } from './handlers/data/transfer/dataTransferCommandHandler';
import { handleDataSyncJobCommand } from './handlers/data/dataCatalogSyncCommandHandler';

export const handleWebsocket = (websocketMessage: Message) => {
  const accurityMessage = parseWebsocketMessage(websocketMessage);

  if (accurityMessage) {
    handleAccurityMessage(accurityMessage);
  }
};

const handleAccurityMessage = (accurityMessage: AccurityMessage) => {
  switch (accurityMessage.command) {
    case WebsocketMessageType.AggregatedNotificationsCommand:
      let shouldRefreshList = false;
      accurityMessage.commands.forEach((command) => {
        const shouldRefresh = handleCrudCommand(command);
        shouldRefreshList = shouldRefreshList || shouldRefresh;
      });
      if (shouldRefreshList) {
        rootStore.dispatch(invalidateAllEntityTypesInStore({ invalidatedAt: Date.now() }));
      }
      break;
    case WebsocketMessageType.ImportJobResultCommand:
      handleImportJobResultCommand(accurityMessage);
      break;
    case WebsocketMessageType.ImportExceptionCommand:
      handleImportExceptionCommand(accurityMessage);
      break;
    case WebsocketMessageType.ExportJobResultCommand:
      handleExportJobResultCommand(accurityMessage);
      break;
    case WebsocketMessageType.ErrorNotification:
      handleErrorNotification(accurityMessage);
      break;
    case WebsocketMessageType.LogoutNotification:
      handleLogoutNotification(accurityMessage);
      break;
    case WebsocketMessageType.BulkOperationLogCommand:
      handleBulkOperationCommand(accurityMessage);
      break;
    case WebsocketMessageType.ColumnProfilingStartedCommand:
      handleColumnProfilingStartedCommand(accurityMessage);
      break;
    case WebsocketMessageType.ColumnProfilingFinishedCommand:
      handleColumnProfilingFinishedCommand(accurityMessage);
      break;
    case WebsocketMessageType.DataManagementCommand:
      handleDataManagementCommand(accurityMessage);
      break;
    case WebsocketMessageType.DataSyncJobCommand:
      handleDataSyncJobCommand(accurityMessage);
      break;
    default:
      console.error('Unknown websocket message type : ', accurityMessage)
  }
};
