import React from 'react';
import { DetailHeader, StaticField } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_ALSO_LABEL, IT_CURRENTLY_LABEL, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import {
  ATTRIBUTE_DEFINITION_DESCRIPTION_LABEL,
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_TYPE,
  ATTRIBUTE_DEFINITION_TYPE_LABEL,
  AttributeDefinition,
  AttributeDefinitionType,
  AttributeDefinitionTypeOptions,
  AttributeDefinitionTypeOptionsForCreate,
  COMPONENT_ATTRIBUTE_DEFINITION_LABEL,
  COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL,
  COMPONENT_OF_LABEL,
  isComponent,
  isComposite,
  isReference,
  isValue
} from '../types/attributeDefinitionTypes';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { BASED_ON_LABEL, BUSINESS_TERM_DESCRIPTION, ENTITY_ICON, ENTITY_TYPE } from '../../entity/types/entityTypes';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_TYPE } from '../../businessTerm/types/businessTermTypes';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import { COMPOSITE_TYPE_ICON, COMPOSITE_TYPE_LABEL, COMPOSITE_TYPE_TYPE, TARGET_ENTITY_LABEL } from '../../compositeType/types/compositeTypeTypes';
import { VALUE_TYPE_ICON, VALUE_TYPE_LABEL, VALUE_TYPE_TYPE } from '../../valueType/types/valueTypeTypes';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL, ATTRIBUTE_PLURAL_LABEL, ATTRIBUTE_TYPE } from '../../attribute/types/attributeTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import RichTextEditorFieldWithFormik from '../../../common/detail/formik/RichTextEditorFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface AttributeDefinitionDetailProps {
  id?: string,
}

const AttributeDefinitionDetail = ({ id }: AttributeDefinitionDetailProps) => {
  return (
    <AccurityDetailContainer<AttributeDefinition>
      objectType={ATTRIBUTE_DEFINITION_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getAttributeDefinitionDetailFields(formik)}
              <DefaultDetailBottomBar objectType={ATTRIBUTE_DEFINITION_TYPE} detailBag={{
                ...detailBag,
                showRemoveAction: formik.status.permissions.hasDeletePermission && !isComponent(formik.values.attributeDefinitionType) && formik.status.isUpdateDetail,
                showCopyAction: formik.status.permissions.hasCreatePermission && !isComponent(formik.values.attributeDefinitionType) && formik.status.isUpdateDetail
              }}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<AttributeDefinition>, detailBag: DetailBag<AttributeDefinition>) => (
  <ConfirmRemovalDetail
    iconName={ATTRIBUTE_DEFINITION_ICON}
    detailBag={detailBag}
  >
    {formik.values.attributeDefinitionType === AttributeDefinitionType.COMPOSITE &&
    <>
      <StaticField
        name={'removalDescription'}
        value={IT_IS_CURRENTLY_LABEL}
        inverted={true}
      />
      <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
        <ChildrenCountFieldWithFormik
          singularLabel={COMPONENT_ATTRIBUTE_DEFINITION_LABEL}
          pluralLabel={COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributeDefinitionsCount}
          iconName={ATTRIBUTE_DEFINITION_ICON}
          pinIconName={ATTRIBUTE_DEFINITION_ICON}
          childFieldType={ATTRIBUTE_DEFINITION_TYPE}
          childrenCountWording={ChildrenCountWording.COMPOSED_OF}
          inverted={true}
        />
      </FeatureChecker>
    </>}
    {formik.values.childrenCounts.attributesCount > 0 &&
    <>
      {formik.values.attributeDefinitionType === AttributeDefinitionType.COMPOSITE ?
        <StaticField
          name={'removalDescription2'}
          value={IT_ALSO_LABEL}
          inverted={true}
        />
        :
        <StaticField
          name={'removalDescription2'}
          value={IT_CURRENTLY_LABEL}
          inverted={true}
        />}
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_LABEL}
          pluralLabel={ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ATTRIBUTE_DEFINITION_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          childrenCountWording={ChildrenCountWording.HAS}
          inverted={true}
        />
      </FeatureChecker>
    </>
    }
    {
      formik.values.attributeDefinitionType === AttributeDefinitionType.COMPOSITE &&
      formik.values.childrenCounts.attributesCount > 0
        ?
        <StaticField
          name={'removalDescription3'}
          inverted={true}
          value={'These Attributes and Attribute Definition Components will be removed together with the Attribute Definition.'}
        />
        :
        formik.values.attributeDefinitionType === AttributeDefinitionType.COMPOSITE
          ?
          <StaticField
            name={'removalDescription3'}
            inverted={true}
            value={'These Attribute Definition Components will be removed together with the Attribute Definition.'}
          />
          :
          formik.values.childrenCounts.attributesCount > 0
            ?
            <StaticField
              name={'removalDescription3'}
              inverted={true}
              value={'These Attributes will be removed together with the Attribute Definition.'}
            />
            :
            undefined
    }
  </ConfirmRemovalDetail>
);

export const getAttributeDefinitionDetailFields = (formik: FormikProps<AttributeDefinition>,
                                                   isEmbedded?: boolean,
                                                   editingInEmbedded?: boolean,
) => {
  return (
    <>
      {(!isEmbedded || editingInEmbedded) &&
      <DetailHeader iconName={ATTRIBUTE_DEFINITION_ICON} disabledScrolling={isEmbedded} >
        <NameFieldWithFormik
          label={'Attribute Definition Name'}
          readOnly={isComponent(formik.values.attributeDefinitionType)}
        />
        {!editingInEmbedded ? <DetailTopBar/> : null}
      </DetailHeader>
      }
      <DescriptionFieldWithFormik label={ATTRIBUTE_DEFINITION_DESCRIPTION_LABEL}/>
      <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
        {!isComponent(formik.values.attributeDefinitionType) &&
        <ReferenceFieldWithFormik
          name={'basedOnBusinessTerm'}
          label={BASED_ON_LABEL}
          objectType={BUSINESS_TERM_TYPE}
          icon={BUSINESS_TERM_ICON}
          showDefaultTooltip={false}
        />}
        {!!formik.values.basedOnBusinessTerm &&
        <RichTextEditorFieldWithFormik
          key={formik.values.basedOnBusinessTerm.id}
          name={'basedOnBusinessTerm.description'}
          label={BUSINESS_TERM_DESCRIPTION}
          allowedChipTypes={[BUSINESS_TERM_TYPE]}
          chipsCollectionName={'basedOnBusinessTerm.chips'}
          readOnly={true}
        />}
      </FeatureChecker>
      {isComponent(formik.values.attributeDefinitionType) &&
      <ReferenceFieldWithFormik
        name={'parent'}
        label={COMPONENT_OF_LABEL}
        objectType={ATTRIBUTE_DEFINITION_TYPE}
        icon={ATTRIBUTE_DEFINITION_ICON}
        showDefaultTooltip={false}
        readOnly={formik.status.isUpdateDetail}
      />}
      {!!formik.values.parent &&
      <RichTextEditorFieldWithFormik
        name={'parent.description'}
        label={'Composite Attribute Definition Description'}
        readOnly={true}
      />}
      <HistoryGroupWithFormik
        VersionBrowserButton={!isEmbedded && <VersionBrowserButton
          getDetailFields={formik => getAttributeDefinitionDetailFields(formik)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={ATTRIBUTE_DEFINITION_TYPE}/>
      <EnumerationFieldWithFormik
        name={'attributeDefinitionType'}
        label={ATTRIBUTE_DEFINITION_TYPE_LABEL}
        options={formik.status.isUpdateDetail ? AttributeDefinitionTypeOptions : AttributeDefinitionTypeOptionsForCreate}
        readOnly={formik.status.isUpdateDetail}
        onChange={() => {
          formik.setFieldValue('compositeType', null);
          formik.setFieldValue('targetEntity', null);
          formik.setFieldValue('valueType', null);
        }}
      />
      {isComposite(formik.values.attributeDefinitionType) && <ReferenceFieldWithFormik
        name={'compositeType'}
        label={COMPOSITE_TYPE_LABEL}
        objectType={COMPOSITE_TYPE_TYPE}
        icon={COMPOSITE_TYPE_ICON}
        showDefaultTooltip={false}
        readOnly={isComponent(formik.values.attributeDefinitionType)}
      />}
      {isReference(formik.values.attributeDefinitionType) && <ReferenceFieldWithFormik
        name={'targetEntity'}
        label={TARGET_ENTITY_LABEL}
        objectType={ENTITY_TYPE}
        icon={ENTITY_ICON}
        showDefaultTooltip={false}
        readOnly={isComponent(formik.values.attributeDefinitionType)}
      />}
      {isValue(formik.values.attributeDefinitionType) && <ReferenceFieldWithFormik
        name={'valueType'}
        label={VALUE_TYPE_LABEL}
        objectType={VALUE_TYPE_TYPE}
        icon={VALUE_TYPE_ICON}
        showDefaultTooltip={false}
        readOnly={isComponent(formik.values.attributeDefinitionType)}
      />}
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.ATTRIBUTE_DEFINITION}/>
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_LABEL}
          pluralLabel={ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ATTRIBUTE_DEFINITION_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          childrenCountWording={ChildrenCountWording.HAS}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
        {formik.values.attributeDefinitionType === AttributeDefinitionType.COMPOSITE &&
        <ChildrenCountFieldWithFormik
          singularLabel={COMPONENT_ATTRIBUTE_DEFINITION_LABEL}
          pluralLabel={COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributeDefinitionsCount}
          iconName={ATTRIBUTE_DEFINITION_ICON}
          pinIconName={ATTRIBUTE_DEFINITION_ICON}
          childFieldType={ATTRIBUTE_DEFINITION_TYPE}
          childrenCountWording={ChildrenCountWording.COMPOSED_OF}
        />}
      </FeatureChecker>
    </>
  );
};

export default AttributeDefinitionDetail;
