import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';

export const VALUE_TYPE_TYPE = 'value-type';
export const VALUE_TYPE_LABEL = 'Value Type';
export const VALUE_TYPE_PLURAL_LABEL = 'Value Types';
export const VALUE_TYPE_ICON = 'valueType';

export interface ValueTypeState extends CoreEntityState<ValueType> {
}

export interface ValueType extends AccurityCoreEntity, WithChildrenCounts {
  customPropertyValues: CustomPropertyValues;
}
