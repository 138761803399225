import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption, Tag } from 'ts-components';
import { DataSetReference } from '../../dataSet/types/dataSetTypes';
import { DataStructureReference } from '../../dataStructure/types/dataStructureTypes';
import { DataCatalogSyncResult, HasSyncResult } from '../../../common/dataCatalogSync/types';
import { RelatedBusinessTerm } from '../../businessTerm/types/businessTermTypes';

export const DATA_FIELD_TYPE = 'data-field';
export const DATA_FIELD_LABEL = 'Data Field';
export const DATA_FIELD_PLURAL_LABEL = 'Data Fields';
export const DATA_FIELD_ICON = 'dataField';

export const DATA_FIELD_COMMENT_FIELD = 'comment';
export const DATA_FIELD_COMMENT_FIELD_LABEL = 'Database Comment';

export interface DataFieldState extends CoreEntityState<DataField> {
}

export enum DataFieldConstraint {
  NA = 'NA',
  YES = 'YES',
  NO = 'NO'
}

export const DataFieldConstraintOptions: EnumOption[] = [
  { value: DataFieldConstraint.NA, label: 'N/A' },
  { value: DataFieldConstraint.YES, label: 'Yes' },
  { value: DataFieldConstraint.NO, label: 'No' },
];

export interface DataField extends AccurityCoreEntity, WithChildrenCounts, HasSyncResult {
  status: AccurityReference;
  dataSet: DataSetReference;
  dataStructure: DataStructureReference;
  physicalName?: string;
  dataType?: string;
  length?: number;
  precision?: number;
  scale?: number;
  primaryKey?: boolean;
  foreignKey?: boolean;
  unique?: boolean;
  notNull?: boolean;
  tags: Tag[];
  relatedBusinessTerms?: RelatedBusinessTerm[];
  customPropertyValues: CustomPropertyValues;
  isProfileable: boolean;
  syncResult?: DataCatalogSyncResult
}

export interface DataFieldReference extends AccurityReference {
  dataStructure: DataStructureReference;
}

export const isDataField = (object: unknown): object is DataField => {
  return (object as DataField).objectType === DATA_FIELD_TYPE;
};