import { AccurityFilter, AccurityReference } from '../../../common/types/accurityTypes';
import { FormikProps, } from 'formik';
import { doFetch } from '../../../common/rest/FetchService';
import { getGetEndpoint } from '../../../common/rest/endpoints';
import { DATA_STRUCTURE_TYPE, DataStructure } from '../../dataStructure/types/dataStructureTypes';
import { DataField } from '../types/dataFieldTypes';
import { createReferenceFieldFilter } from '../../../common/referenceField/utils/filters';
import { DataSetType } from '../../dataSet/types/dataSetTypes';

export const getDataStructureValueHandler = (formik: FormikProps<DataField>) => {

  return (newValue: AccurityReference | null) => {
    formik.setFieldValue('dataStructure', newValue);

    // try to set Data Set based on Data Structure
    if (newValue && !formik.values.dataSet) {
      doFetch(getGetEndpoint(DATA_STRUCTURE_TYPE, newValue.id), 'GET')
        .then((dataStructure: DataStructure) => formik.setFieldValue('dataSet', dataStructure.dataSet))
        .catch(error => console.log('Cannot fetch Data Set', error))
    }
  };
};

// filter Data Set by Data Set Type
export const getDataSetFilters = (formik: FormikProps<DataField>) => {
  const additionalFilters: AccurityFilter[] = [];
  if (formik.values.dataSet) {
    additionalFilters.push(createReferenceFieldFilter('id', formik.values.dataSet.id));
  }
  if (!formik.status.isUpdateDetail) {
    additionalFilters.push(createReferenceFieldFilter('dataSetType', DataSetType.LOGICAL));
  }
  return additionalFilters;
};

// filter Data Structures by Data Set if Data Set is selected
export const getDataStructureFilters = (formik: FormikProps<DataField>) => {
  const additionalFilters: AccurityFilter[] = [];
  if (formik.values.dataSet) {
    additionalFilters.push(createReferenceFieldFilter('dataSet.id', formik.values.dataSet.id));
  }
  if (!formik.status.isUpdateDetail) {
    additionalFilters.push(createReferenceFieldFilter('dataSet.dataSetType', DataSetType.LOGICAL));
  }
  return additionalFilters;
};
