import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_PLURAL_LABEL, BUSINESS_TERM_TYPE } from '../types/businessTermTypes';
import { useBusinessTermListColumns } from './businessTermListColumns';

const BusinessTermList = () => {
  const businessTermColumns = useBusinessTermListColumns();
  const businessTermListSettings = getDefaultListSettings(BUSINESS_TERM_TYPE, businessTermColumns);

  return (
    <AccurityListContainer
      objectType={BUSINESS_TERM_TYPE}
      title={BUSINESS_TERM_PLURAL_LABEL}
      icon={BUSINESS_TERM_ICON}
      columns={businessTermColumns}
      defaultListSettings={businessTermListSettings}
      partialImportOnly={true}
    />
  );
};

export default BusinessTermList;
