import React from 'react';
import { DataManagementNotificationCenterNotification } from './types';
import { NotificationCenterCard, NotificationCenterCardProps, ProgressIndicator } from 'ts-components';
import { useDispatch } from 'react-redux';
import { dismissNotification } from './notificationCenterSlice';
import { useTranslation } from 'react-i18next';
import { DataManagementMessageType } from '../dataTransfer/types';

export interface DataManagementNotificationCardProps {
  notification: DataManagementNotificationCenterNotification;
}

const DataManagementNotificationCard = ({ notification }: DataManagementNotificationCardProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let title;
    let titleIcon;
    let titleIconColor: NotificationCenterCardProps['titleIconColor'];
    if (DataManagementMessageType.REMOVE_ALL_DATA_STARTED === notification.dataManagementMessageType) {
      title = t('dataManagementNotification.removeAllDataStart');
      titleIcon = 'cancel';
      titleIconColor = 'error';
    } else if (DataManagementMessageType.REMOVE_ALL_DATA_FINISHED === notification.dataManagementMessageType) {
      title = t('dataManagementNotification.removeAllDataFinish');
      titleIcon = 'resultPassed2';
      titleIconColor = 'success';
    } else if (DataManagementMessageType.IMPORT_SAMPLE_DATA_STARTED === notification.dataManagementMessageType) {
      title = t('dataManagementNotification.importSampleDataStart');
      titleIcon = 'save';
    } else if (DataManagementMessageType.IMPORT_SAMPLE_DATA_FINISHED === notification.dataManagementMessageType) {
      title = t('dataManagementNotification.importSampleDataFinish');
      titleIcon = 'resultPassed2';
      titleIconColor = 'success';
    } else {
      title = t('dataManagementNotification.importSampleDataFinishWithError');
      titleIcon = 'mui:Error';
      titleIconColor = 'error';
    }

    const date = notification.date;
    const unread = !notification.wasRead;
    const dataTransferOperationStart = DataManagementMessageType.REMOVE_ALL_DATA_STARTED === notification.dataManagementMessageType ||
      DataManagementMessageType.IMPORT_SAMPLE_DATA_STARTED === notification.dataManagementMessageType;

    const onDismiss = notification.cannotDismiss ? undefined : () => dispatch(dismissNotification(notification.id));

    let content;
    if (dataTransferOperationStart) { // In Progress Message
      content = <span style={{ width: '100%' }}><ProgressIndicator/></span>;
    } else if (DataManagementMessageType.REMOVE_ALL_DATA_FINISHED === notification.dataManagementMessageType) {
      content =
        <span>
      {
        t('dataManagementNotification.removeAllDataFinishMessage')
      }
    </span>;
    } else if (DataManagementMessageType.IMPORT_SAMPLE_DATA_FINISHED === notification.dataManagementMessageType) {
      content =
        <span>
      {
        t('dataManagementNotification.importSampleDataFinishMessage')
      }
      </span>;
    }

    return (
      <NotificationCenterCard
        title={title}
        titleIcon={titleIcon}
        titleIconColor={titleIconColor}
        date={date}
        unread={unread}
        onDismiss={onDismiss}
      >
        {content}
      </NotificationCenterCard>
    );
  }
;

export default DataManagementNotificationCard;
