import { getGetEndpoint } from '../rest/endpoints';
import { doFetch } from '../rest/FetchService';
import { AccurityCoreEntity } from '../types/accurityTypes';
import rootStore from './rootStore';
import { addCoreEntityToStore } from './commonReducers';

export const doBackgroundFetchByObjectTypeIdAndAddToStore = (objectType: string, id: string) => {
  const getByIdEndpoint = getGetEndpoint(objectType, id);
  doFetch(getByIdEndpoint, 'GET')
    .then((fetchedCoreEntity: AccurityCoreEntity) => {
      const fetchedAt = Date.now();
      rootStore.dispatch(addCoreEntityToStore({ coreEntity: fetchedCoreEntity, fetchedAt: fetchedAt }));
    })
    .catch((err) => {
      console.error('Error fetching by objectType + id! ', objectType, id, err);
    });
};

export const doBackgroundFetchByObjectTypeAndId = (objectType: string, id: string) => {
  const getByIdEndpoint = getGetEndpoint(objectType, id);
  return doFetch(getByIdEndpoint, 'GET')
    .catch((err) => {
      console.error('Error fetching by objectType + id! ', objectType, id, err);
    });
}
