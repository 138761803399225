import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useSelectCoreEntity } from '../../../common/redux/commonSelectors';
import React, { useCallback, useEffect, useState } from 'react';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { LinkBase, NodeBase, useInteractiveDiagramBag } from 'ts-components';
import { Graph, GraphContent } from '../types/businessModelDiagramTypes';

export const useInteractiveDiagramsLogic = <D extends AccurityCoreEntity, N extends NodeBase, L extends LinkBase>(
  diagramType: string,
  diagramId: string,
  requestLoadGraph: (diagramId: string) => Promise<Graph>,
  requestHideNode: (graphContent: GraphContent, nodeId: string) => Promise<GraphContent>,
  requestSaveGraph: (graph: Graph) => Promise<Graph>,
) => {
  const { getCurrentNavigation, openList, openDetailWithObject } = useAccurityNavigation();
  const { detailType, detailId } = getCurrentNavigation();
  const isDetailOpen = detailType === diagramType && detailId === diagramId;
  const diagramInfo = useSelectCoreEntity<D>(diagramType, diagramId);

  const [selectedTags, setSelectedTags] = useState<AccurityReference[]>([]);
  const [selectedSources, setSelectedSources] = useState<AccurityReference[]>([]);
  const diagramBag = useInteractiveDiagramBag<N, L>();
  const { setState: setDiagramState } = diagramBag;

  const onReload = useCallback(() => {
    requestLoadGraph(diagramId)
      .then((graph: Graph) => {
        setDiagramState({
          ...graph.content ? JSON.parse(graph.content) : undefined,
          transform: graph.zoom ? { k: graph.zoom, x: graph.x, y: graph.y } : undefined,
        }, true);
        setSelectedTags(graph.source.tags);
        setSelectedSources(graph.source.sourceReferences || [])
      });
  }, [diagramId, setDiagramState]);

  useEffect(() => {
    onReload()
  }, [onReload]);

  const handleHideNode = (nodeId: string) =>
    requestHideNode(diagramBag.state, nodeId)
      .then((graphContent: GraphContent) => {
        setDiagramState({
          ...diagramBag.state,
          ...graphContent,
        }, false);
      });

  const onSave = () => {
    const persistGraph: Graph = {
      diagramId: diagramId,
      diagramType: diagramType,
      content: JSON.stringify({
        nodes: diagramBag.state.nodes,
        hiddenNodes: diagramBag.state.hiddenNodes,
        links: diagramBag.state.links,
        secondaryLinks: diagramBag.state.secondaryLinks,
      }),
      zoom: diagramBag.state.transform?.k,
      x: diagramBag.state.transform?.x,
      y: diagramBag.state.transform?.y,
      source: {
        tags: selectedTags,
        sourceReferences: selectedSources,
      },
    };
    requestSaveGraph(persistGraph)
      .then((graph: Graph) => {
        const graphContent: GraphContent = JSON.parse(graph.content);
        setDiagramState({
          ...graphContent,
          transform: graph.zoom ? { k: graph.zoom, x: graph.x, y: graph.y } : undefined,
        }, true);
      });
  };

  const onOpenDetail = isDetailOpen ? undefined : () => {
    openDetailWithObject(diagramType, diagramId);
  };

  const onCloseDiagram = () => {
    openList(diagramType);
  };

  return {
    diagramBag,
    setDiagramState,
    diagramInfo,
    selectedSources,
    setSelectedSources,
    selectedTags,
    setSelectedTags,
    handleHideNode,
    onSave,
    onReload,
    onOpenDetail,
    onCloseDiagram,
  }
}