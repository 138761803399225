import { Column } from 'ts-components';

import { AccurityPinFilter, AccuritySearch, AccuritySort } from '../types/accurityTypes';

export type ExportFormat = 'csv' | 'xls' | 'xlsx'; // TODO duplicate definition in ts-components

export type ListExportData = {
  objectType: string;
  filename: string;
  columns: Column[];
  offset: number;
  limit: number;
  size: number;
  pinFilters: AccurityPinFilter[];
  sort: AccuritySort;
}

export type DataTransferLink = {
  id: string;
}

export type ExportColumnDefinition = {
  label: string;
  type: string;
  property: string;
}

export interface ExportSearch extends AccuritySearch {
  objectType: string;
  filename: string;
  columns: ExportColumnDefinition[];
  delimiter: string;
  exportTemplate?: boolean;
  timeZone?: string;
}

export type ImportResult = {
  partialImport: boolean;
  successfulImport: boolean;
  numberOfAllRecords: number;
  numberOfValidRecords: number;
  numberOfInvalidRecords: number;
  numberOfCreatedRecords: number;
  numberOfUpdatedRecords: number;
}

export type ExportResult = {
  numberOfExportedRows: number;
  exportSuccess: boolean;
}

export enum DataManagementMessageType {
  REMOVE_ALL_DATA_STARTED = 'REMOVE_ALL_DATA_STARTED',
  REMOVE_ALL_DATA_FINISHED = 'REMOVE_ALL_DATA_FINISHED',
  IMPORT_SAMPLE_DATA_STARTED = 'IMPORT_SAMPLE_DATA_STARTED',
  IMPORT_SAMPLE_DATA_FINISHED = 'IMPORT_SAMPLE_DATA_FINISHED',
  IMPORT_SAMPLE_DATA_FINISHED_WITH_ERROR = 'IMPORT_SAMPLE_DATA_FINISHED_WITH_ERROR',
}