import React, { useState } from 'react';
import { NotificationCenterNotification, NotificationCenterNotificationType } from './types';
import { useDispatch, useSelector } from 'react-redux';
import ImportNotificationCard from './ImportNotificationCard';
import { NotificationCenter, NotificationIconButton } from 'ts-components';
import { dismissAllNotifications, markAllNotificationsAsRead } from './notificationCenterSlice';
import Analytics from '../analytics';
import ColumnProfilingNotificationCard from './ColumnProfilingNotificationCard';
import BulkOperationNotificationCard from './BulkOperationNotificationCard';
import ExportNotificationCard from './ExportNotificationCard';
import DataManagementNotificationCard from './DataManagementNotificationCard';
import DataCatalogSyncNotificationCard from './DataCatalogSyncNotificationCard';

const renderNotificationCard = (notification: NotificationCenterNotification, closeAction: () => void) => {
  switch (notification.type) {
    case NotificationCenterNotificationType.IMPORT:
      return <ImportNotificationCard key={notification.id} notification={notification}/>;
    case NotificationCenterNotificationType.EXPORT:
      return <ExportNotificationCard key={notification.id} notification={notification}/>;
    case NotificationCenterNotificationType.BULK_OPERATION:
      return <BulkOperationNotificationCard key={notification.id} notification={notification}/>;
    case NotificationCenterNotificationType.COLUMN_PROFILING:
      return <ColumnProfilingNotificationCard key={notification.id} notification={notification} closeNotificationCenter={closeAction}/>;
    case NotificationCenterNotificationType.DATA_MANAGEMENT:
      return <DataManagementNotificationCard key={notification.id} notification={notification}/>;
    case NotificationCenterNotificationType.DATA_CATALOG_SYNC:
      return <DataCatalogSyncNotificationCard key={notification.id} notification={notification}/>;
    default:
      console.error('Invalid NotificationCardType ', notification);
  }
};

const AccurityNotificationCenter = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const notifications: NotificationCenterNotification[] = useSelector((state: any) => state.notificationCenter.notifications);
  const unreadNotificationsCount = notifications.filter(notification => !notification.wasRead).length;
  const trackAction = Analytics.createActionTracker('Notification Center');

  const handleDismissAll = () => {
    dispatch(dismissAllNotifications());
    trackAction('Dismiss All Notifications');
  };

  const handleOpen = () => {
    setOpen(true);
    trackAction('Open');
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(markAllNotificationsAsRead());
    trackAction('Close');
  };

  const isDismissAllBtnVisible = notifications.some(q => !q.cannotDismiss);

  return (
    <>
      <NotificationIconButton
        name={'notificationCenter'}
        onClick={handleOpen}
        count={unreadNotificationsCount}
      />
      <NotificationCenter isOpen={open} onClose={handleClose} dismissAll={handleDismissAll} isDismissAllVisible={isDismissAllBtnVisible}>
        {notifications.map(notification => renderNotificationCard(notification, handleClose))}
      </NotificationCenter>
    </>
  );
};

export default AccurityNotificationCenter;
