import React from 'react';
import { GlobalSearch, GlobalSearchProps } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useGlobalSearchFetchData } from '../useGlobalSearchFetchData';

interface AccurityGlobalSearchProps {
  disabledBackgroundOverlay?: boolean,
  variant?: GlobalSearchProps['variant'],
}

const AccurityGlobalSearch = ({ disabledBackgroundOverlay, variant = 'center' }: AccurityGlobalSearchProps) => {

  const navigationController = useAccurityNavigation();
  const { rows, existingObjects, setSearchText, isLoading } = useGlobalSearchFetchData();

  return (
    <GlobalSearch
      existingObjects={existingObjects}
      rows={rows}
      onSelect={(item) => {
        if (item.relatedObjectType && item.relatedObjectId) {
          navigationController.openDetailAndList(item.relatedObjectType, item.relatedObjectId + '')
        } else {
          navigationController.openDetailAndList(item.objectType, item.id + '')
        }
      }}
      onSearch={setSearchText}
      onShowMoreResults={searchTerm => navigationController.openSearch(searchTerm)}
      isLoading={isLoading}
      disabledBackgroundOverlay={disabledBackgroundOverlay}
      variant={variant}
    />
  );
};

export default AccurityGlobalSearch;
