import { Feature } from '../types/types';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export const useHasFeature = () => {
  const features: Feature[] = useSelector((state: any) => state.userSettings.features);

  return useCallback((featureType: string) => features.some(feature => feature.type === featureType), [features]);
};

export const useHasSomeOfTheFeatures = () => {
  const features: Feature[] = useSelector((state: any) => state.userSettings.features);
  return useCallback((featureTypes: string[]) => features.some(feature => featureTypes.includes(feature.type)), [features]);
};
