import {setEnvironmentInfoInStore} from '../redux/appSettingsSlice';
import {useDispatch, useSelector} from 'react-redux';
import {requestEnvironmentInfo} from '../rest/environmentInfoApi';
import {AppEnvironment, AppSettingsState} from '../types/types';

export const useLoadEnvironmentInfo = () => {
  const dispatch = useDispatch();
  return () => requestEnvironmentInfo()
    .then(environment => dispatch(setEnvironmentInfoInStore(environment)));
};

export const useIsCloudEnvironment = () =>
  useSelector((state: { appSettings: AppSettingsState }) =>
    state.appSettings.environment === AppEnvironment.CLOUD
  );

export const useIsOracleDb = () =>
  useSelector((state: { appSettings: AppSettingsState }) =>
    state.appSettings.activeProfiles.includes('oracle')
  );

export const useIsSwaggerEnabled = () =>
  useSelector((state: { appSettings: AppSettingsState }) =>
    state.appSettings.activeProfiles.includes('swagger')
  );
