import React from 'react';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import TechnicalDataMappingTargetDataField from './TechnicalDataMappingTargetDataField';
import {
  TECHNICAL_DATA_MAPPING_ICON,
  TECHNICAL_DATA_MAPPING_SELECTION_ICON,
  TECHNICAL_DATA_MAPPING_TARGET_DATA_FIELD_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_DATA_FIELD_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_FIELD_NAME,
  TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_SELECTION_ITEMS_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_SELECTION_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_SELECTION_PLURAL_LABEL,
  TechnicalDataMapping
} from '../../types/technicalDataMappingTypes';
import { AccurityCoreEntity, AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps, useFormikContext } from 'formik';
import { CollapsingGroup, DetailInlineGroup, EnumOption } from 'ts-components';
import { DATA_FIELD_ICON } from '../../../dataField/types/dataFieldTypes';
import { getTechnicalDataMappingCountLabel, resetValuesOnTargetDataSetChange } from '../technicalDataMappingDetailUtils';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_SET_ICON, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';
import TechnicalDataMappingTargetDataStructureDataFieldSelection from './TechnicalDataMappingTargetDataStructureDataFieldSelection';
import TechnicalDataMappingTargetDataStructureDataFieldJoin from './TechnicalDataMappingTargetDataStructureDataFieldJoin';

interface TechnicalDataMappingTargetProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<TechnicalDataMapping>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const TechnicalDataMappingTarget = ({ additionalFilters = [], formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions }: TechnicalDataMappingTargetProps) => {

  const formikContext = useFormikContext<AccurityCoreEntity>();
  const targetDataStructureDataFields = formikContext.getFieldProps(TECHNICAL_DATA_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD).value;
  const selections = formikContext.getFieldProps(TECHNICAL_DATA_MAPPING_TARGET_SELECTION_ITEMS_FIELD).value;

  return (
    <>
      <CollapsingGroup
        name={TECHNICAL_DATA_MAPPING_TARGET_FIELD}
        title={TECHNICAL_DATA_MAPPING_TARGET_LABEL}
        icon={TECHNICAL_DATA_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <DetailInlineGroup
          childGridSizes={[8, 4]}
        >
          <ReferenceFieldWithFormik
            name={TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_FIELD_NAME}
            label={TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_LABEL}
            objectType={DATA_SET_TYPE}
            icon={DATA_SET_ICON}
            setValue={(newValue) => {
              resetValuesOnTargetDataSetChange(formik, false);
              formik.setFieldValue(TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_FIELD_NAME, newValue);
            }}
          />
          {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
            name={'targetDataSet.dataSetType'}
            label={DATA_SET_TYPE_LABEL}
            options={dataSetTypeEnumOptions}
            readOnly={true}
          />}
        </DetailInlineGroup>
        {formik.values.targetDataSet && <CollectionFieldWithFormik
          name={TECHNICAL_DATA_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD}
          title={getTechnicalDataMappingCountLabel(targetDataStructureDataFields,
            TECHNICAL_DATA_MAPPING_TARGET_DATA_FIELD_LABEL,
            TECHNICAL_DATA_MAPPING_TARGET_DATA_FIELD_PLURAL_LABEL)}
          icon={DATA_FIELD_ICON}
          rowValidation={(value) => value && value.dataField && value.dataField.id}
          renderRow={(elementName, index) =>
            <TechnicalDataMappingTargetDataField
              elementName={elementName}
              index={index}
              additionalFilters={additionalFilters}
              formik={formik}
            />
          }
        />}
        {formik.values.targetDataSet && <CollectionFieldWithFormik
          name={TECHNICAL_DATA_MAPPING_TARGET_SELECTION_ITEMS_FIELD}
          title={getTechnicalDataMappingCountLabel(selections,
            TECHNICAL_DATA_MAPPING_TARGET_SELECTION_LABEL,
            TECHNICAL_DATA_MAPPING_TARGET_SELECTION_PLURAL_LABEL)}
          icon={TECHNICAL_DATA_MAPPING_SELECTION_ICON}
          expandedByDefault={false}
          rowValidation={(value) => value &&
            value.dataStructure && value.dataStructure.id &&
            value.dataField && value.dataField.id &&
            value.criteria
          }
          renderRow={(elementName, index) =>
            <TechnicalDataMappingTargetDataStructureDataFieldSelection
              elementName={elementName}
              index={index}
              formik={formik}
            />
          }
        />}
        {formik.values.targetDataSet && <TechnicalDataMappingTargetDataStructureDataFieldJoin
          additionalFilters={additionalFilters}
          formik={formik}
        />}
      </CollapsingGroup>
    </>
  )
};

export default TechnicalDataMappingTarget;
