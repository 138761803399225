import React from 'react';
import { DetailHeader } from 'ts-components';

import { Status, STATUS_ICON, STATUS_TYPE } from '../types/statusTypes';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import SwitchFieldWithFormik from '../../../common/detail/formik/SwitchFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import { CONFIRM_REMOVE_SETTINGS_DETAIL } from '../../../common/detail/types/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusConfirmRemovalDetail from './StatusConfirmRemovalDetail';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface StatusDetailProps {
  id?: string;
}

const StatusDetail = ({ id }: StatusDetailProps) => {
  return (
    <AccurityDetailContainer<Status>
      objectType={STATUS_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return <StatusConfirmRemovalDetail formik={formik} detailBag={detailBag}/>;
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar
                objectType={STATUS_TYPE}
                detailBag={{
                  ...detailBag,
                  showCopyAction: false,
                }}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getDetailFields = (formik: FormikProps<Status>) => {
  return (
    <>
      <DetailHeader iconName={STATUS_ICON}>
        <NameFieldWithFormik label={'Status Value Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={getDetailFields}
        />}
      />
      <SwitchFieldWithFormik label={'Default'} name={'defaultIndicator'}/>
    </>
  );
};

export default StatusDetail;
