import * as React from 'react';
import { Histogram, ProfileCard } from 'ts-components';
import { ResultData } from '../types/types';

export const handleDateHistogramData = ({ dateFrequencyData }: ResultData) => {

  if (!dateFrequencyData) {
    return null;
  }

  const cards: React.ReactNode[] = [];

  // show year statistics only if at least one bucket has value to show
  if (dateFrequencyData.year && dateFrequencyData.year.some(q => q.count > 0)) {
    cards.push(<ProfileCard title={'Value Distribution per Year'} key={'histogram-year'}>
      <Histogram
        values={dateFrequencyData.year.map(q => parseInt(q.count as any))}
        labels={dateFrequencyData.year.map(q => q.value)}
      />
    </ProfileCard>)
  }

  // show month statistics only if at least one bucket has value to show
  if (dateFrequencyData.month && dateFrequencyData.month.some(q => q.count > 0)) {
    cards.push(<ProfileCard title={'Value Distribution per Month'} key={'histogram-month'}>
      <Histogram
        values={dateFrequencyData.month.map(q => parseInt(q.count as any))}
        labels={dateFrequencyData.month.map(q => q.value)}
      />
    </ProfileCard>)
  }

  // show week statistics only if at least one bucket has value to show
  if (dateFrequencyData.dayOfTheWeek && dateFrequencyData.dayOfTheWeek.some(q => q.count > 0)) {
    cards.push(<ProfileCard title={'Value Distribution per Week'} key={'histogram-week'}>
      <Histogram
        values={dateFrequencyData.dayOfTheWeek.map(q => parseInt(q.count as any))}
        labels={dateFrequencyData.dayOfTheWeek.map(q => q.value)}
      />
    </ProfileCard>)
  }

  return cards;
};

