import { EnumOption, DataStructureChanges, UserInfo } from 'ts-components';

import { DataSetReference } from '../../businessGlossary/dataSet/types/dataSetTypes';
import { DataStructureReference } from '../../businessGlossary/dataStructure/types/dataStructureTypes';


export interface DataSetChanges {
  dataSet: DataSetReference;
  dataStructures: DataStructureChanges[];
}

export enum DataCatalogSyncStatus {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  OBSOLETED = 'OBSOLETED',
  OUTDATED = 'OUTDATED',
  MIXED = 'MIXED'
}

export const dataCatalogSyncDataFieldStatusOptions: EnumOption[] = [
  { value: DataCatalogSyncStatus.CREATED, label: 'Created' },
  { value: DataCatalogSyncStatus.UPDATED, label: 'Updated' },
  { value: DataCatalogSyncStatus.OBSOLETED, label: 'Obsoleted' },
  { value: DataCatalogSyncStatus.OUTDATED, label: 'Outdated' },
];

export const dataCatalogSyncStatusOptions: EnumOption[] = [
  ...dataCatalogSyncDataFieldStatusOptions,
  { value: DataCatalogSyncStatus.MIXED, label: 'Mixed' },
];

export interface DataCatalogSyncResult {
  syncRun: DataCatalogSyncRun,
  status: DataCatalogSyncStatus,
  dataStructureCreatedCount: number;
  dataStructureUpdatedCount: number;
  dataStructureObsoletedCount: number;
  dataStructureOutdatedCount: number;
  dataFieldCreatedCount: number;
  dataFieldUpdatedCount: number;
  dataFieldObsoletedCount: number;
  dataFieldOutdatedCount: number;
}

export interface DataCatalogSyncRun {
  triggeredFrom: DataSetReference | DataStructureReference;
  triggeredBy: UserInfo;
  triggeredDate: number;
  runStatus: SyncRunStatus;
}

export enum SyncRunStatus {
  PREPARING = 'PREPARING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

export interface HasSyncResult {
  syncResult?: DataCatalogSyncResult;
}
