import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ProductTermsScreen } from '../productTerms/components/ProductTermsScreen';
import { useProductTermsSettings } from '../productTerms/hooks/productTermsHook';

/*
 * Routes are only accessible post-authentication. If not authenticated, redirects to Login Screen.
 */

const PostAuthenticationRoute = ({ ...routeProps }: RouteProps) => {
  const productTermsSettings = useProductTermsSettings();
  const { keycloak, initialized } = useKeycloak();

  // wait to initialize keycloak
  if (!initialized) {
    return null;
  }

  // redirect to login page if not authenticated
  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  if (productTermsSettings.productTermsCheckEnabled && !productTermsSettings.productTermsAccepted) {
    return <ProductTermsScreen/>;
  }

  return <Route {...routeProps}/>;
};

export default PostAuthenticationRoute;
