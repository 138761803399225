import { getCommentByIdEndpoint, getCommentsCreateEndpoint, getCommentsDeleteEndpoint, getCommentsSearchEndpoint, getCommentsUpdateEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { AccuritySearch, AccuritySortType } from '../../types/accurityTypes';
import { ListSearchResult } from '../../list/types/types';
import { AccurityComment } from 'ts-components';

export const getCommentByIdRequest = (objectType: string, commentId: number): Promise<AccurityComment> => {
  const endpoint = getCommentByIdEndpoint(objectType, commentId);
  const method = 'GET';

  return doFetch(endpoint, method).catch(showErrorMessageFromResponse);
};

export const getCommentsRequest = (objectType: string, objectId: number, startFrom: number = 0): Promise<ListSearchResult<AccurityComment>> => {
  const endpoint = getCommentsSearchEndpoint(objectType, objectId);
  const method = 'POST';
  const search: AccuritySearch = {
    filters: [],
    sort: { property: 'createdDate', type: AccuritySortType.DESCENDING },
    maxResults: 20,
    startFrom: startFrom
  };

  return doFetch(endpoint, method, JSON.stringify(search)).catch(showErrorMessageFromResponse);
};

export type CreateCommentParams = Pick<AccurityComment, 'referencedObjectId' | 'referencedObjectType' | 'text'>;
export const createCommentRequest = (params: CreateCommentParams): Promise<AccurityComment> => {
  const endpoint = getCommentsCreateEndpoint(params.referencedObjectType, params.referencedObjectId);
  const method = 'POST';

  return doFetch(endpoint, method, JSON.stringify(params)).catch(showErrorMessageFromResponse);
};

export type UpdateCommentParams = CreateCommentParams;
export const updateCommentRequest = (params: UpdateCommentParams): Promise<AccurityComment> => {
  const endpoint = getCommentsUpdateEndpoint(params.referencedObjectType, params.referencedObjectId);
  const method = 'PUT';

  return doFetch(endpoint, method, JSON.stringify(params)).catch(showErrorMessageFromResponse);
};

export type DeleteCommentParams = CreateCommentParams & Pick<AccurityComment, 'referencedObjectType' | 'id'>;
export const deleteCommentRequest = (params: DeleteCommentParams): Promise<AccurityComment> => {
  const endpoint = getCommentsDeleteEndpoint(params.referencedObjectType, params.id);
  const method = 'DELETE';

  return doFetch(endpoint, method).catch(showErrorMessageFromResponse);
};
