import React, { useMemo } from 'react';
import { AccurityIcon, AccurityMenu, AccurityMenuItem, UserAvatar, AccurityDivider } from 'ts-components';
import { logout } from '../userSettings/hooks/logout';
import { useAccurityNavigation } from '../navigation/hooks';
import { USER_PROFILE } from './types';
import { ABOUT_PAGE } from '../about/types/types';
import Analytics from '../analytics';
import { APPLICATION_SETTING } from '../appSettings/types/types';
import { useHasAdminPermission } from '../userSettings/hooks/permissions';

interface UserPopupProps {
  userFirstName: string;
  userLastName: string;
}

const SettingsIcon = () => <AccurityIcon iconName="mui:SettingsOutlined" />;
const AboutIcon = () => <AccurityIcon iconName="mui:InfoOutlined" />;
const LogoutIcon = () => <AccurityIcon iconName="mui:Logout" />;

const UserPopup = ({ userFirstName, userLastName }: UserPopupProps) => {
  const navigationController = useAccurityNavigation();
  const trackAction = Analytics.createActionTrackerWithCallback('User Popup');
  const isAdmin = useHasAdminPermission();

  const openUserProfileHandler = trackAction(() => navigationController.openDetail(USER_PROFILE), 'Click on User Profile');
  const openAboutDetailHandler = trackAction(() => navigationController.openDetail(ABOUT_PAGE), 'Click on About Page');
  const openAppSettingsDetailHandler = trackAction(() => navigationController.openDetail(APPLICATION_SETTING), 'Click on Application Settings');
  const logoutHandler = trackAction(() => logout(), 'Logout');

  const UserProfileIcon = useMemo(() => {
    return () => <UserAvatar firstName={userFirstName} lastName={userLastName} />
  }, [userFirstName, userLastName])

  const UserProfileMenuIcon = useMemo(() => {
    return () => <UserAvatar firstName={userFirstName} lastName={userLastName} size="small" />
  }, [userFirstName, userLastName])

  return (
    <div id={'app-bar-username'}>
      <AccurityMenu renderOpenButton={UserProfileIcon}>
        <AccurityMenuItem onClick={openUserProfileHandler} renderIconComponent={UserProfileMenuIcon()}>My Account</AccurityMenuItem>
        <AccurityDivider sx={{ margin: '8px 0px'}} />
        {isAdmin && <AccurityMenuItem onClick={openAppSettingsDetailHandler} renderIconComponent={SettingsIcon()}>Settings</AccurityMenuItem>}
        <AccurityMenuItem onClick={openAboutDetailHandler} renderIconComponent={AboutIcon()}>About</AccurityMenuItem>
        <AccurityMenuItem onClick={logoutHandler} renderIconComponent={LogoutIcon()}>Log Out</AccurityMenuItem>
      </AccurityMenu>
    </div>
  );
};

export default UserPopup;
