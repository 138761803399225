import React from 'react';
import { FormikProps } from 'formik';
import { DetailInlineGroup, EnumOption } from 'ts-components';
import { CUSTOM_PROPERTY_DEFAULT_LABEL, CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, CustomProperty } from '../../types/customPropertyTypes';
import { getSupportiveCustomPropertyDefaultValueFieldLabel, targetObjectTypeIcon, targetObjectTypeTypeEndpoint } from '../../../customPropertyUtils';
import ReferenceFieldWithFormik from '../../../../detail/formik/ReferenceFieldWithFormik';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../../../businessGlossary/dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../detail/formik/EnumerationFieldWithFormik';
import { AccurityFilter } from '../../../../types/accurityTypes';
import { createReferenceFieldFilter } from '../../../../referenceField/utils/filters';
import { DATA_STRUCTURE_LABEL, DataStructureReference } from '../../../../../businessGlossary/dataStructure/types/dataStructureTypes';

interface DataStructureDefaultValueFieldProps {
  formik: FormikProps<CustomProperty>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const DataStructureDefaultValueField = ({ formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions }: DataStructureDefaultValueFieldProps) => {

  const targetObjectType = formik.values.targetObjectType;
  const objectType = targetObjectTypeTypeEndpoint(targetObjectType);
  const icon = targetObjectTypeIcon(targetObjectType);
  const readOnly = targetObjectType === null;

  const dataSetReferenceFieldProps = formik.getFieldProps(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference').value;

  let filters: AccurityFilter[] = [];

  const dataSetId = dataSetReferenceFieldProps?.id;

  filters.push(
    createReferenceFieldFilter('dataSet.id', dataSetId),
  );

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_SET_LABEL)}
          objectType={DATA_SET_TYPE}
          icon={DATA_SET_ICON}
          setValue={(dataSet) => {
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference', dataSet);
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', null);
          }}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
        {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference.dataSetType'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_SET_TYPE_LABEL)}
          options={dataSetTypeEnumOptions}
          readOnly={true}
        />}
      </DetailInlineGroup>
      <ReferenceFieldWithFormik<DataStructureReference>
        name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference'}
        icon={icon}
        label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_STRUCTURE_LABEL)}
        additionalFilters={filters}
        setValue={(dataStructure) => {
          if (!dataSetId) {
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference', dataStructure?.dataSet);
          }
          formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', dataStructure);
        }}
        objectType={objectType}
        coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        readOnly={readOnly}
      />
    </>
  )
};

export default DataStructureDefaultValueField;