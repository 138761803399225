import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { ATTRIBUTE_TYPE, AttributeState } from '../types/attributeTypes';

const initialState: AttributeState = getInitialCoreEntityState(ATTRIBUTE_TYPE);

const attributeSlice = createSlice({
  name: ATTRIBUTE_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default attributeSlice.reducer;
