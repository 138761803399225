import React from 'react';
import { AccurityButton, AccurityMenuItem, DetailBottomBar, DetailMenu } from 'ts-components';
import { DetailBag } from '../types/types';
import Analytics from '../../analytics';
import { AccurityCoreEntity } from '../../types/accurityTypes';

interface DefaultDetailBottomBarProps {
  detailBag: DetailBag<AccurityCoreEntity>;
  objectType: string;
  customMenuItems?: React.ReactNode;
  customActionButtons?: React.ReactNode[];
  showRemoveInDetailMenu?: boolean;
}

const DefaultDetailBottomBar = ({ detailBag, objectType, customMenuItems, customActionButtons, showRemoveInDetailMenu = false }: DefaultDetailBottomBarProps) => {

  const actionWrapper = Analytics.createActionTrackerWithCallback('Detail: ' + objectType);

  return ( 
    <DetailBottomBar
      menu={(
        <DetailMenu>
          {customMenuItems}
          {detailBag.showRemoveAction && !detailBag.isRemoveDisabled && showRemoveInDetailMenu && (
            <AccurityMenuItem onClick={actionWrapper(detailBag.removeAction, 'Remove Object')}>
              Remove
            </AccurityMenuItem>
          )}
          {detailBag.showCopyAction && (
            <AccurityMenuItem onClick={actionWrapper(detailBag.copyAction, 'Copy Object')}>
              Copy
            </AccurityMenuItem>
          )}
        </DetailMenu>
      )}
    >
      {detailBag.showRemoveAction && !showRemoveInDetailMenu && (
        <AccurityButton
          onClick={actionWrapper(() => detailBag.removeAction(), 'Remove Object')}
          disabled={detailBag.isRemoveDisabled}
          color="secondary"
        >
          Remove
        </AccurityButton>
      )}
      {customActionButtons}
      {detailBag.showReloadAction && (
        <AccurityButton
          onClick={actionWrapper(detailBag.reloadAction, 'Reload Object')}
          disabled={detailBag.isReloadDisabled}
          color="secondary"
        >
          Reload
        </AccurityButton>
      )}
      {detailBag.showSaveAction && (
        <AccurityButton
          onClick={actionWrapper(() => detailBag.saveAction(), 'Save Object')}
          disabled={detailBag.isSaveDisabled}
        >
          Save
        </AccurityButton>
      )}
    </DetailBottomBar>
  );
};

export default DefaultDetailBottomBar;
