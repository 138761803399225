import { RowItem } from 'ts-components';
import { AccurityFilter } from '../../types/accurityTypes';
import { doExactReferenceFieldSearch } from './search';
import { AccurityReferenceFieldSearchResult } from '../types';

export type DropFilterParams = {
  objectType: string,
  filterProperty?: string,
  dragDropRowProperty?: string,
  additionalFilters?: AccurityFilter[],
  customEndpoint?: string
}

// Field will accept dragged item if its type matches given objectType
export const defaultTestDraggedItem = (objectType: string) => (rowItem?: RowItem): boolean => {
  if (!rowItem) {
    return false;
  }

  return rowItem?.objectType === objectType;
};

// Field will accept dropped item if:
//    no filters are given, or
//    result of search with given filters includes the dropped item
export const defaultTestDroppedItem = (dropFilterParams: DropFilterParams) => async (rowItem?: RowItem): Promise<RowItem> => {

  const objectType = dropFilterParams.objectType;
  const filterProperty = dropFilterParams.filterProperty ?? 'name';
  const dragDropRowProperty = dropFilterParams.dragDropRowProperty ?? filterProperty;
  const additionalFilters = dropFilterParams.additionalFilters ?? [];
  const customEndpoint = dropFilterParams.customEndpoint;

  if (!rowItem) {
    return Promise.reject();
  }

  const searchText = (rowItem as any)[dragDropRowProperty];
  if (!searchText) {
    console.error('Filter Property not present on dropped Row Item! ', filterProperty, rowItem);
    return Promise.reject();
  }

  return doExactReferenceFieldSearch(objectType, filterProperty, searchText, additionalFilters, undefined, customEndpoint)
    .then((searchResult: AccurityReferenceFieldSearchResult) => {
        const referenceItem = searchResult.rows.find(searchRow => searchRow.id === rowItem.id);
        return referenceItem ? referenceItem : Promise.reject();
      }
    );
};
