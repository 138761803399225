import React from 'react';
import { WelcomeScreen } from 'ts-components';
import Analytics from '../../common/analytics';
import AccurityGlobalSearch from '../../common/globalSearch/components/AccurityGlobalSearch';
import welcomeScreenLogo from '../images/accurity-logo.svg';

const BusinessGlossaryWelcomeScreen = () => {
  Analytics.useScreenAnalytics('Welcome Page');

  return (
    <WelcomeScreen
      logo={welcomeScreenLogo}
      GlobalSearch={<AccurityGlobalSearch disabledBackgroundOverlay={true}/>}
    />
  );
};

export default BusinessGlossaryWelcomeScreen;
