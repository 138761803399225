import React from 'react';
import { DetailInlineGroup, EnumOption } from 'ts-components';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import { useVersionBrowserColoring } from '../../../common/versionBrowserField/hooks/versionBrowserColoringHook';
import { AccurityFilter } from '../../../common/types/accurityTypes';

interface RelatedDataAssetRowProps {
  elementName: string;
  additionalFilters: AccurityFilter[];
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const RelatedDataAssetRow = ({
                               elementName,
                               additionalFilters,
                               multipleDataSetTypesAllowedFeature,
                               dataSetTypeEnumOptions
                             }: RelatedDataAssetRowProps) => {

  const coloring = useVersionBrowserColoring(elementName);

  return (
    <DetailInlineGroup
      childGridSizes={[8, 4]}
      coloring={coloring}
    >
      <ReferenceFieldWithFormik
        name={elementName}
        label={DATA_SET_LABEL}
        objectType={DATA_SET_TYPE}
        icon={DATA_SET_ICON}
        additionalFilters={additionalFilters}
        hideRemoveButton={true}
        showDefaultTooltip={false}
        disableColoring={true}
      />
      {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
        name={elementName + '.dataSetType'}
        label={DATA_SET_TYPE_LABEL}
        options={dataSetTypeEnumOptions}
        readOnly={true}
        disableColoring={true}
      />}
    </DetailInlineGroup>
  );
};

export default RelatedDataAssetRow;
