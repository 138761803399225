import { VersionBrowserRevision } from 'ts-components';
import { getFindObjectChangesEndpoint, getFindRevisionsEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { ListSearchResult } from '../../list/types/types';
import { VersionBrowserChangesRequest, VersionBrowserChangesResponse } from '../types/types';

export const getRevisions = (objectType: string, id: string): Promise<ListSearchResult<VersionBrowserRevision>> => {
  const endpoint = getFindRevisionsEndpoint(objectType, id);
  const method = 'GET';

  return doFetch(endpoint, method).catch(showErrorMessageFromResponse);
};

export const getChanges = (objectType: string, id: string, olderRevisionId: number, newerRevisionId: number, newerRevisionIsCurrent?: boolean): Promise<VersionBrowserChangesResponse> => {
  const endpoint = getFindObjectChangesEndpoint(objectType);
  const method = 'POST';
  const body: VersionBrowserChangesRequest = {
    objectId: id,
    olderObjectRevision: olderRevisionId,
    newerObjectRevision: newerRevisionId,
    newerRevisionIsCurrent: newerRevisionIsCurrent,
  };

  return doFetch(endpoint, method, JSON.stringify(body)).catch(showErrorMessageFromResponse);
};
