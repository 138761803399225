import React from 'react';
import { useFormikContext } from 'formik';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';
import { DetailInlineGroup, NumericFormat } from 'ts-components';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';
import { DataSource, DataSourceType, OracleConnectionTypeOptions } from '../../types/dataSourceTypes';

const ConnectionGroup = () => {
  const formik = useFormikContext<DataSource>();
  const isOracleDatabaseType = formik.values.dataSourceType === DataSourceType.ORACLE;
  const isMongoDbAtlasType = formik.values.dataSourceType === DataSourceType.MONGODB_ATLAS;

  return (
    <>
      {isMongoDbAtlasType ? <HostField/> : <HostPortField/>}
      {isOracleDatabaseType ? <OracleDatabaseField/> : <NonOracleDatabaseField/>}
    </>
  );
};

const HostField = () => (
  <SingleLineFieldWithFormik
    name={'host'}
    label={'Host'}
  />
);

const HostPortField = () => (
  <DetailInlineGroup
    childGridSizes={[9, 3]}>
    <HostField/>
    <SingleLineFieldWithFormik
      name={'port'}
      label={'Port'}
      type={'text'}
      format={NumericFormat.POSITIVE_NUMERIC}
    />
  </DetailInlineGroup>
);


const OracleDatabaseField = () => (
  <DetailInlineGroup
    childGridSizes={[3, 9]}>
    <EnumerationFieldWithFormik
      name={'oracleConnectionType'}
      label={''}
      options={OracleConnectionTypeOptions}
    />
    <SingleLineFieldWithFormik
      name={'database'}
      label={' '}  // setting the label to ' ' to achieve the same field height as EnumerationField before this field
    />
  </DetailInlineGroup>
);

const NonOracleDatabaseField = () => (
  <SingleLineFieldWithFormik
    name={'database'}
    label={'Database'}
  />
);

export default ConnectionGroup;
