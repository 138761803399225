import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DetailBag, TEMPLATE_OBJECT_ID } from '../types/types';
import { AccurityCoreEntity, AccurityLocationState } from '../../types/accurityTypes';
import { useAccurityNavigation } from '../../navigation/hooks';
import { detailSaveWidth } from '../actions/detailActions';
import { getInitialCoreEntityMeta, RootState } from '../../redux/types';
import AccurityDetail from '../components/AccurityDetail';
import { CommentsDrawer } from '../../comments/components/commentsDrawer';
import { UserSettingsState } from '../../userSettings/types/types';
import { CustomSaveSteps, useSaveAction } from '../actions/detailSave';
import { CustomReloadSteps, useReloadAction } from '../actions/detailReload';
import { CustomRemoveSteps, useRemoveAction } from '../actions/detailRemove';

interface AccurityDetailContainerProps<T extends AccurityCoreEntity> {
  objectType: string;
  id?: string;
  customSaveSteps?: CustomSaveSteps<T>,
  customReloadSteps?: CustomReloadSteps<T>,
  customRemoveSteps?: CustomRemoveSteps<T>,
  isEmbedded?: boolean,
  customHandleExternalUpdate?: (defaultHandleExternalUpdate: () => void, detailEntity: T) => void;
  children?: (formik: FormikProps<T>, detailBag: DetailBag<T>) => React.ReactNode;
}

const AccurityDetailContainer = <T extends AccurityCoreEntity>({
                                                                 objectType,
                                                                 id = TEMPLATE_OBJECT_ID,
                                                                 customSaveSteps,
                                                                 customReloadSteps,
                                                                 customRemoveSteps,
                                                                 isEmbedded,
                                                                 customHandleExternalUpdate,
                                                                 children,
                                                               }: AccurityDetailContainerProps<T>) => {

  const location = useLocation<AccurityLocationState>();
  const detailOpenedAt = useSelector((state: RootState) => {
    const objectTypeState = state[objectType];
    return objectTypeState.detailOpenedAt;
  }) || Date.now();

  // GET FROM STORE
  const { coreEntity, coreEntityMeta, shouldFetchEntity, isDetailLoading } = useSelector((state: RootState) => {
    const objectTypeState = state[objectType];
    let coreEntity = objectTypeState.byId[id] ? objectTypeState.byId[id].data : undefined;
    const coreEntityMeta = objectTypeState.byId[id] ? objectTypeState.byId[id].meta : getInitialCoreEntityMeta();

    // We immediately fetch entity if:
    // entity is not in store, OR
    // entity in store was invalidated AFTER entity was fetched BEFORE the detail was opened
    // (handling for AFTER detail is opened is done in AccurityDetail.tsx as there
    // is business logic for this that depends on other info like changedDate, if User started editing detail)
    const shouldFetchEntity =
      coreEntity === undefined ||
      (detailOpenedAt > objectTypeState.invalidatedAt && objectTypeState.invalidatedAt > coreEntityMeta.fetchedAt);

    // merge the object template and the data that should be pre-populated
    if (coreEntity && id === TEMPLATE_OBJECT_ID && !!location.state?.detail?.prePopulateData && !isEmpty(location.state.detail.prePopulateData)) {
      coreEntity = { ...coreEntity, ...location.state.detail.prePopulateData };
    }

    return { coreEntity, coreEntityMeta, shouldFetchEntity, isDetailLoading: objectTypeState.isDetailLoading }
  });

  // DETAIL ACTIONS
  const dispatch = useDispatch();
  const navigationController = useAccurityNavigation();

  const savedWidth = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.detailWidth);
  const doSaveWidth = (width: number) =>
    dispatch(detailSaveWidth(width));

  const doReload = useReloadAction(objectType, id, customReloadSteps)
  const doSave = useSaveAction(customSaveSteps);
  const doRemove = useRemoveAction(customRemoveSteps);

  useEffect(() => {
    if (!isDetailLoading && shouldFetchEntity) {
      doReload();
    }
  }, [isDetailLoading, shouldFetchEntity, doReload]);

  const isCommentsDrawerOpen = coreEntity && coreEntity.id && !!navigationController.getCurrentNavigation().comments;

  return (
    <>
      <AccurityDetail
        key={`${detailOpenedAt}-${coreEntity?.id}`}
        entity={coreEntity}
        entityMeta={coreEntityMeta}
        doSave={doSave}
        doReload={doReload}
        doRemove={doRemove}
        isLoading={isDetailLoading}
        savedWidth={savedWidth}
        doSaveWidth={doSaveWidth}
        isEmbedded={isEmbedded}
        customHandleExternalUpdate={customHandleExternalUpdate}
      >
        {children}
      </AccurityDetail>
      {isCommentsDrawerOpen && <CommentsDrawer
        entity={coreEntity}
        onCloseDrawer={navigationController.closeCommentsDrawer}
      />}
    </>
  );
};

export default AccurityDetailContainer;
