import React from 'react';
import { Feature } from '../../../common/userSettings/features/features';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { DetailHeader, objectTypeToIconName } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { CustomRemoveSteps } from '../../../common/detail/actions/detailRemove';
import {
  PROCESS_DIAGRAM_BASED_ON_TYPE_LABEL,
  PROCESS_DIAGRAM_ICON,
  PROCESS_DIAGRAM_TYPE,
  ProcessDiagram,
  ProcessDiagramBasedOnType,
  ProcessDiagramBasedOnTypeOptions
} from '../types/processDiagramTypes';
import OpenDiagramButton from '../../businessModelDiagram/detail/OpenDiagramButton';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface ProcessDiagramDetailProps {
  id?: string,
}

const ProcessDiagramDetail = ({ id }: ProcessDiagramDetailProps) => {

  const customRemoveSteps: CustomRemoveSteps<ProcessDiagram> = {
    closeDetail: (navigationController => {
      const { diagramType, diagramId } = navigationController.getCurrentNavigation();
      const isCanvasOpen = diagramType === PROCESS_DIAGRAM_TYPE && diagramId === id;
      if (isCanvasOpen) {
        navigationController.openListAndCloseDetail(PROCESS_DIAGRAM_TYPE);
      } else {
        navigationController.closeDetail();
      }
    }),
  }

  return (
    <AccurityDetailContainer<ProcessDiagram>
      objectType={PROCESS_DIAGRAM_TYPE}
      id={id}
      customRemoveSteps={customRemoveSteps}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar objectType={PROCESS_DIAGRAM_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (detailBag: DetailBag<ProcessDiagram>) => (
  <ConfirmRemovalDetail
    iconName={objectTypeToIconName(PROCESS_DIAGRAM_TYPE)}
    detailBag={detailBag}
  />
);

const getDetailFields = (formik: FormikProps<ProcessDiagram>) => {
  return (
    <FeatureChecker featureId={Feature.PROCESS_DIAGRAMS}>
      <DetailHeader iconName={PROCESS_DIAGRAM_ICON}>
        <NameFieldWithFormik label={'Process Diagram Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton getDetailFields={getDetailFields}/>}
      />
      <StatusFieldWithFormik/>
      <EnumerationFieldWithFormik
        name={'processDiagramBasedOnType'}
        label={PROCESS_DIAGRAM_BASED_ON_TYPE_LABEL}
        options={ProcessDiagramBasedOnTypeOptions}
        defaultValue={ProcessDiagramBasedOnType.ATTRIBUTES}
        readOnly={formik.status.isUpdateDetail}
      />
      <CustomPropertyFieldsContainer
        forObjectType={GlossaryCustomPropertyObjectType.PROCESS_DIAGRAM}
      />
      <OpenDiagramButton
        icon={PROCESS_DIAGRAM_ICON}
        diagramType={PROCESS_DIAGRAM_TYPE}
        diagramId={formik.values.id}
      />
    </FeatureChecker>
  );
};

export default ProcessDiagramDetail;

