import React, { useState } from 'react';
import { AccurityIconButton } from 'ts-components';
import { doFetch } from '../../rest/FetchService';
import { getNotificationToggleEndpoint } from '../../rest/endpoints';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../userMessages/actions';
import {
  getEmailNotificationDisabledMessage,
  getEmailNotificationEnabledMessage,
} from '../../userMessages/commonMessages';
import { EmailNotificationActionType, EmailNotificationState } from '../../types/accurityTypes';

interface EmailNotificationToggleProps {
  value: boolean;
  payload: EmailNotificationState;
  onToggleSuccess: (newValue: boolean) => void;
}

const EmailNotificationToggle = ({ value, payload, onToggleSuccess }: EmailNotificationToggleProps) => {

  const [isLoading, setIsLoading] = useState(false);

  const doRequest = () => {
    setIsLoading(true);

    doFetch(getNotificationToggleEndpoint(value), 'PUT', JSON.stringify(payload))
      .then(() => {
        const notificationType = EmailNotificationActionType.OBJECT_UPDATE;
        showSnackbarMessage(value ? getEmailNotificationDisabledMessage(notificationType) : getEmailNotificationEnabledMessage(notificationType));
        onToggleSuccess(!value);
      })
      .catch(error => {
        return showErrorMessageFromResponse(error)
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  let title = value ? 'Toggle update notifications OFF' : 'Toggle update notifications ON';
  if (isLoading) {
    title = 'Action in progress';
  }

  return (
    <AccurityIconButton
      iconName={value ? 'mui:NotificationsOffOutlined' : 'mui:NotificationsOutlined'}
      title={title}
      disabled={isLoading}
      size="small"
      id={'email-notifications-toggle'}
      onClick={doRequest}
    />
  );
};

export default EmailNotificationToggle;
