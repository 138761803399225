import { TaggedEntity } from 'ts-components';
import { doFetch } from '../../../common/rest/FetchService';
import { getEntitiesWithTagEndpoint } from '../../../common/rest/endpoints';
import { showErrorMessageFromResponse } from '../../../common/userMessages/actions';
import { ListSearchResult } from '../../../common/list/types/types';

export type  FetchObjectWithTagResponse = ListSearchResult<TaggedEntity>;

export const fetchEntitiesWithTag = (searchTerm: string, startFrom = 0, maxResults = 20): Promise<FetchObjectWithTagResponse> => {
  const requestBody = { searchTerm, startFrom, maxResults };
  return doFetch(getEntitiesWithTagEndpoint(), 'POST', JSON.stringify(requestBody))
    .catch(showErrorMessageFromResponse);
};
