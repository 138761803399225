import React, { useEffect, useState } from 'react';
import { ENTITY_TYPE } from '../../entity/types/entityTypes';
import AccurityReferenceMultiSelector from '../../../common/referenceField/components/AccurityReferenceMultiSelector';
import { AccurityButton, AccurityFilterType, DiagramControlField } from 'ts-components';
import { AccurityReference } from '../../../common/types/accurityTypes';
import { isEqual } from 'lodash';

interface BusinessModelDiagramTopBarControlsProps {
  tags: AccurityReference[];
  setTags: (newTags: AccurityReference[]) => void;
  initialSelectedItems: AccurityReference[];
  onGenerate: (selectedItems: AccurityReference[]) => void;
  hasUpdatePermission: boolean;
}

const BusinessModelDiagramTopBarControls = ({ tags, setTags, initialSelectedItems = [], onGenerate, hasUpdatePermission }: BusinessModelDiagramTopBarControlsProps) => {
  const [selectedItems, setSelectedItems] = useState<AccurityReference[]>(initialSelectedItems);

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  const dirty = !isEqual(initialSelectedItems, selectedItems);

  const onGenerateClick = () => {
    onGenerate(selectedItems);
  };

  const tagIds = tags.map(tag => tag.id).join(';');
  const filterByTags = tagIds ? [{
    type: AccurityFilterType.SIMPLE_QUERY,
    property: 'tags.id',
    value: `=${tagIds}`,
  }] : undefined;

  return (
    <>
      <DiagramControlField>
        <AccurityReferenceMultiSelector
          name={'tag'}
          label={'Entity Tags'}
          value={tags}
          setValue={newValue => setTags(newValue)}
          objectType={'tag'}
          disabled={!hasUpdatePermission}
          customEndpoint={'global-search/findAllTagsByObjectTypeAndName/entity'}
        />
      </DiagramControlField>
      <DiagramControlField>
        <AccurityReferenceMultiSelector
          name={'entitySelector'}
          label={'Entities'}
          value={selectedItems}
          setValue={setSelectedItems}
          objectType={ENTITY_TYPE}
          additionalFilters={filterByTags}
          disabled={!hasUpdatePermission}
          allowSelectAll={true}
        />
      </DiagramControlField>
      <AccurityButton
        onClick={onGenerateClick}
        disabled={!hasUpdatePermission || selectedItems.length === 0 || !dirty}
      >
        Generate
      </AccurityButton>
    </>
  );
};

export default BusinessModelDiagramTopBarControls;
