import React, { useEffect } from 'react';
import { EnumerationField, EnumOption, EnumOptionValue, Tooltip } from 'ts-components';
import { useField, useFormikContext } from 'formik';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface EnumerationFieldWithFormikProps {
  name: string;
  label: string;
  onChange?: (name: string, newValue: EnumOptionValue) => void;
  options: EnumOption[];
  filterOutValues?: EnumOptionValue[];
  canBeEmpty?: boolean;
  defaultValue?: string;
  readOnly?: boolean;
  tooltip?: Tooltip;
  coloringOverwrite?: string;
  disableColoring?: boolean;
  inherited?: boolean;
}

const EnumerationFieldWithFormik = ({
                                      name,
                                      label,
                                      onChange,
                                      options,
                                      filterOutValues = [],
                                      canBeEmpty,
                                      defaultValue,
                                      readOnly,
                                      tooltip,
                                      coloringOverwrite,
                                      disableColoring,
                                      inherited
                                    }: EnumerationFieldWithFormikProps) => {
  const [field, meta, helper] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(coloringOverwrite ? coloringOverwrite : name);

  const filteredOptions = options.filter(option =>
    !filterOutValues?.includes(option.value) ||
    option.value === field.value
  );

  useEffect(() => {
    if (defaultValue && !field.value) {
      helper.setValue(defaultValue);
    }
  });

  return (
    <EnumerationField
      name={name}
      label={label}
      value={field.value}
      errorMessage={meta.error}
      options={filteredOptions}
      canBeEmpty={canBeEmpty}
      disabled={readOnly || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      onChange={(name, newValue) => {
        if (onChange) {
          onChange(name, newValue);
        }
        helper.setValue(newValue);
      }}
      tooltip={tooltip}
      coloring={disableColoring ? undefined : coloring}
      inherited={inherited}
    />
  );
};

export default EnumerationFieldWithFormik;
