import React from 'react';
import CollectionFieldWithFormik from './CollectionFieldWithFormik';
import ReferenceFieldWithFormik from './ReferenceFieldWithFormik';
import {
  BUSINESS_TERM_ICON,
  BUSINESS_TERM_LABEL,
  BUSINESS_TERM_PLURAL_LABEL,
  BUSINESS_TERM_REFERENCING_ICON,
  BUSINESS_TERM_TYPE,
  BusinessTerm,
  BusinessTermRelatedBusinessTermRelationType,
  RELATED_BUSINESS_TERM_RELATION_TYPE_LABEL,
  RelatedBusinessTerm,
  RelatedBusinessTermRelationType
} from '../../../businessGlossary/businessTerm/types/businessTermTypes';
import { useField, useFormikContext } from 'formik';
import { AccurityFilter } from '../../types/accurityTypes';
import { createUniqueWithinCollectionFilters } from '../../referenceField/utils/filters';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../referenceField/utils/dragAndDrop';
import { DetailInlineGroup, EnumOption } from 'ts-components';
import EnumerationFieldWithFormik from './EnumerationFieldWithFormik';
import { getLabelForValue } from '../../childrenCountField/utils';
import { ChildrenCountWording } from '../../childrenCountField/types';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

const RELATED_BUSINESS_TERMS_FIELD_NAME = 'relatedBusinessTerms';

interface RelatedBusinessTermReferencesFieldWithFormikProps {
  additionalFilters?: AccurityFilter[];
  enableSuggestions?: boolean;
  suggestions?: React.ReactNode[];
  findSuggestions?: () => void;
  relationTypeOptions: EnumOption[];
  relationTypeDefaultValue: RelatedBusinessTermRelationType;
}

const RelatedBusinessTermReferencesFieldWithFormik = ({
                                                        additionalFilters = [],
                                                        enableSuggestions,
                                                        findSuggestions,
                                                        suggestions,
                                                        relationTypeOptions,
                                                        relationTypeDefaultValue,
                                                      }: RelatedBusinessTermReferencesFieldWithFormikProps) => {
  const formik = useFormikContext<BusinessTerm>();
  const [field] = useField<RelatedBusinessTerm[]>(RELATED_BUSINESS_TERMS_FIELD_NAME);
  const relatedBusinessTerms = field.value.map(row => row?.relatedBusinessTerm);

  const collectionTitle = getLabelForValue(BUSINESS_TERM_LABEL, BUSINESS_TERM_PLURAL_LABEL, relatedBusinessTerms.length, ChildrenCountWording.REFERENCES);

  const relatedBusinessTermsRelationsFilters = [
    ...additionalFilters,
    ...createUniqueWithinCollectionFilters('id', relatedBusinessTerms),
  ];

  const businessTermHasChipInDescription = (relatedBusinessTerm?: RelatedBusinessTerm): boolean =>
    !!formik?.values?.chips?.find(chip => chip?.id === relatedBusinessTerm?.relatedBusinessTerm?.id);

  const onValidDrop = (addRow: (newValue: RelatedBusinessTerm) => void, item?: any) => {
    addRow({
      relationType: BusinessTermRelatedBusinessTermRelationType.RELATES_TO,
      relatedBusinessTerm: item
    });
  };

  return (
    <CollectionFieldWithFormik
      name={RELATED_BUSINESS_TERMS_FIELD_NAME}
      title={collectionTitle}
      icon={BUSINESS_TERM_REFERENCING_ICON}
      rowValidation={(value) => value && value.relatedBusinessTerm && value.relatedBusinessTerm.id && value.relationType}
      rowDisableRemove={businessTermHasChipInDescription}
      testDraggedItem={defaultTestDraggedItem(BUSINESS_TERM_TYPE)}
      testDroppedItem={defaultTestDroppedItem({ objectType: BUSINESS_TERM_TYPE, additionalFilters: relatedBusinessTermsRelationsFilters })}
      onValidDrop={onValidDrop}
      renderRow={(elementName, index, collectionValues) =>
        <RelatedBusinessTermRow
          elementName={elementName}
          index={index}
          collectionValues={collectionValues}
          additionalFilters={relatedBusinessTermsRelationsFilters}
          businessTermHasChipInDescription={businessTermHasChipInDescription}
          relationTypeOptions={relationTypeOptions}
          relationTypeDefaultValue={relationTypeDefaultValue}
        />}
      suggestions={suggestions}
      findSuggestions={findSuggestions}
      enableSuggestions={enableSuggestions}
    />
  );
};

type RelatedBusinessTermRowProps = {
  elementName: string;
  index: number;
  collectionValues: any[];
  additionalFilters: AccurityFilter[];
  businessTermHasChipInDescription: (relatedBusinessTerm?: RelatedBusinessTerm) => boolean;
  relationTypeOptions: EnumOption[];
  relationTypeDefaultValue: RelatedBusinessTermRelationType;
}

const RelatedBusinessTermRow = ({
                                  elementName,
                                  index,
                                  collectionValues,
                                  additionalFilters,
                                  businessTermHasChipInDescription,
                                  relationTypeOptions,
                                  relationTypeDefaultValue
                                }: RelatedBusinessTermRowProps) => {
  const coloring = useVersionBrowserColoring(elementName);
  return (
    <DetailInlineGroup
      childGridSizes={[5, 7]}
      coloring={coloring}
    >
      <EnumerationFieldWithFormik
        name={elementName + '.relationType'}
        label={RELATED_BUSINESS_TERM_RELATION_TYPE_LABEL}
        options={relationTypeOptions}
        defaultValue={relationTypeDefaultValue}
        disableColoring={true}
      />
      <ReferenceFieldWithFormik
        name={elementName + '.relatedBusinessTerm'}
        label={BUSINESS_TERM_LABEL}
        icon={BUSINESS_TERM_ICON}
        objectType={BUSINESS_TERM_TYPE}
        additionalFilters={additionalFilters}
        readOnly={businessTermHasChipInDescription(collectionValues[index])}
        disableColoring={true}
      />
    </DetailInlineGroup>
  );
};

export default RelatedBusinessTermReferencesFieldWithFormik;
