import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { COMPOSITE_TYPE_ICON, COMPOSITE_TYPE_PLURAL_LABEL, COMPOSITE_TYPE_TYPE } from '../types/compositeTypeTypes';
import { useCompositeTypeListColumns } from './compositeTypeListColumns';

const CompositeTypeList = () => {
  const compositeTypeColumns = useCompositeTypeListColumns();
  const compositeTypeListSettings = getDefaultListSettings(COMPOSITE_TYPE_TYPE, compositeTypeColumns);

  return (
    <AccurityListContainer
      objectType={COMPOSITE_TYPE_TYPE}
      title={COMPOSITE_TYPE_PLURAL_LABEL}
      icon={COMPOSITE_TYPE_ICON}
      columns={compositeTypeColumns}
      defaultListSettings={compositeTypeListSettings}
    />
  );
};

export default CompositeTypeList;
