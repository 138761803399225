import { AccessRight, CrudPermissions, Role, UserSettingsState } from '../types/types';
import { useSelector } from 'react-redux';
import { USER_TYPE } from '../../../businessGlossary/user/types/userTypes';

export const useHasAdminPermission = () => {
  const roles: Role[] = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.roles || []);
  return hasAdminRole(roles);
};

export const useHasReadPermission = () => {
  const userId = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.id);
  const roles: Role[] = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.roles || []);

  return (objectType: string) => hasAdminRole(roles) || hasPermission(AccessRight.READ, roles, userId, objectType);
};

export const usePermissionsForObjectType = (objectType?: string): CrudPermissions => {
  return usePermissionsForObject(objectType, undefined);
};

export const usePermissionsForObject = (objectType?: string, objectId?: string): CrudPermissions => {
  const userId = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.id);
  const roles: Role[] = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.roles || []);
  const isAdmin = hasAdminRole(roles);

  return {
    hasCreatePermission: isAdmin || hasPermission(AccessRight.CREATE, roles, userId, objectType, objectId),
    hasReadPermission: isAdmin || hasPermission(AccessRight.READ, roles, userId, objectType, objectId),
    hasUpdatePermission: isAdmin || hasPermission(AccessRight.UPDATE, roles, userId, objectType, objectId),
    hasDeletePermission: isAdmin || hasPermission(AccessRight.DELETE, roles, userId, objectType, objectId),
    hasLaunchPermission: isAdmin || hasPermission(AccessRight.LAUNCH, roles, userId, objectType, objectId),
  };
};

const hasPermission = (accessRight: AccessRight, roles: Role[], userId?: string, objectType?: string, objectId?: string): boolean => {
  return userIsAccessingItself(userId, objectType, objectId) ||
    roles.some(role =>
      role.permissions.some(permission =>
        permission.objectType === objectType && permission.accessRight === accessRight
      )
    );
};

const hasAdminRole = (roles: Role[]) => {
  return roles.some(role => role.admin);
};

const userIsAccessingItself = (userId?: string, objectType?: string, objectId?: string) => {
  return objectType === USER_TYPE && userId === objectId;
};
