import { AccurityNavigationParams } from './types';
import { mapValues } from 'lodash';

export const createPathFromNavigationParams = (navParts: AccurityNavigationParams): string => {
  const { listType, listId, diagramType, diagramId, diagramEntityType, detailId, detailType, comments, tagName, searchTerm, dataFieldProfilingId } = encodeNavParams(navParts);
  return createListPath(listType, listId, tagName, searchTerm) +
    createOverlayingComponentPath(diagramType, diagramId, diagramEntityType, dataFieldProfilingId) +
    createDetailPath(detailType, detailId, comments);
};

const createDetailPath = (detailType?: string, detailId?: string, comments?: string) => {
  if (detailType && detailId) {
    return `detail/${detailType}/${detailId}/${!!comments ? 'comments/' : ''}`;
  } else if (detailType) {
    return `detail/${detailType}/`;
  } else {
    return '';
  }
};

const createListPath = (
  listType?: string,
  listId?: string,
  tagName?: string,
  searchTerm?: string,
) => {
  if (listId) {
    return `/referencing-list/${listType}/${listId}/`
  } else if (listType) {
    return `/list/${listType}/`;
  } else if (tagName) {
    return `/tagged-entities/${tagName}/`
  } else if (searchTerm) {
    return `/search/${searchTerm}/`
  } else {
    return '/'
  }
};

const createOverlayingComponentPath = (
  diagramType?: string,
  diagramId?: string,
  diagramEntityType?: string,
  dataFieldProfilingId?: string,
) => {
  if (dataFieldProfilingId) {
    return `data-field-profiling/${dataFieldProfilingId}/`;
  } else if (diagramType && diagramId) {
    return `diagram/${diagramType}/${diagramId}/${diagramEntityType}/`;
  } else {
    return '';
  }
};

export const encodeNavParams = (params: AccurityNavigationParams) =>
  mapValues(params, (param) => typeof param === 'string' ? encodeURIComponent(param) : param);

export const decodeNavParams = (params: AccurityNavigationParams) =>
  mapValues(params, (param) => {
    let decodedParam;
    try {
      decodedParam = typeof param === 'string' ? decodeURIComponent(param) : param;
    } catch {
      decodedParam = param;
    }
    return decodedParam;
  });
