import { AccurityFilter, AccurityReference, AccuritySortType } from '../../types/accurityTypes';
import { AccurityReferenceFieldSearch, AccurityReferenceFieldSearchResult } from '../types';
import { createExactReferenceFieldFilter, createReferenceFieldFilter } from './filters';
import { requestReferenceFieldSearch } from '../rest';

const MAX_SEARCH_RESULTS = 20;

export const createReferenceFieldSearch = (sortProperty: string, filters?: AccurityFilter[], maxResult?: number): AccurityReferenceFieldSearch => {
  return {
    filters: filters || [],
    sort: {
      property: sortProperty,
      type: AccuritySortType.ASCENDING,
    },
    maxResults: maxResult || MAX_SEARCH_RESULTS,
  }
};

export const doReferenceFieldSearch = <T extends AccurityReference>(searchEndpoint: string,
                                                                    filterProperty: string,
                                                                    searchText: string,
                                                                    additionalFilters: AccurityFilter[] = [],
                                                                    maxResult?: number,
                                                                    customEndpoint?: string,
): Promise<AccurityReferenceFieldSearchResult<T>> => {
  let filters = additionalFilters;
  if (searchText) {
    filters = [...filters, createReferenceFieldFilter(filterProperty, searchText)];
  }

  return requestReferenceFieldSearch(searchEndpoint, filterProperty, filters, maxResult, customEndpoint)
};

export const doExactReferenceFieldSearch = <T extends AccurityReference>(searchEndpoint: string,
                                                                         filterProperty: string,
                                                                         searchText: string,
                                                                         additionalFilters: AccurityFilter[] = [],
                                                                         maxResult?: number,
                                                                         customEndpoint?: string,
): Promise<AccurityReferenceFieldSearchResult<T>> => {
  const textFilter = createExactReferenceFieldFilter(filterProperty, searchText);

  return requestReferenceFieldSearch(searchEndpoint, filterProperty, [textFilter, ...additionalFilters], maxResult, customEndpoint)
};
