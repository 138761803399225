import { useSelector } from 'react-redux';
import { ColumnProfilingReduxState } from '../types/types';

type ReduxState = {
  columnProfiling: ColumnProfilingReduxState;
}

export const useSelectLastProfiledDataFieldId = () => {
  return useSelector((state: ReduxState) => state.columnProfiling.lastProfiledDataFieldId);
};

export const useSelectIsProfilingInProgress = (dataFieldId?: string) => {
  return useSelector((state: ReduxState) =>
    dataFieldId ? state.columnProfiling.inProgressProfiling.includes(dataFieldId) : false
  );
};

export const useSelectProfilingData = (dataFieldId?: string) => {
  return useSelector((state: ReduxState) =>
    !!dataFieldId && state.columnProfiling.result?.dataFieldId?.toString() === dataFieldId
      ? state.columnProfiling.result
      : undefined
  );
};
