import React from 'react';
import CustomPropertyDetail from '../../common/customProperties/customProperty/detail/CustomPropertyDetail';
import { useBusinessGlossaryCustomPropertyTargetObjectTypes, useBusinessGlossaryCustomPropertyTypes } from './types';

interface GlossaryCustomPropertyDetailProps {
  id?: string
}

const GlossaryCustomPropertyDetail = ({ id }: GlossaryCustomPropertyDetailProps) => {
  const glossaryCustomPropertyObjectTypes = useBusinessGlossaryCustomPropertyTypes();
  const glossaryCustomPropertyTargetObjectTypes = useBusinessGlossaryCustomPropertyTargetObjectTypes(false);

  return (
    <CustomPropertyDetail
      id={id}
      customPropertyObjectTypeOptions={glossaryCustomPropertyObjectTypes}
      customPropertyTargetObjectTypeOptions={glossaryCustomPropertyTargetObjectTypes}
    />
  );
};

export default GlossaryCustomPropertyDetail;
