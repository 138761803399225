import * as React from 'react';
import { useSelector } from 'react-redux';
import { UserSettingsState } from '../userSettings/types/types';
// @ts-ignore
import ProductFruits from 'react-product-fruits';
import { AppSettingsState } from '../appSettings/types/types';

const PROJECT_CODE_SAAS = 'eqFUvUJTtKRt3B4T';
const PROJECT_CODE_INTERNAL_4 = 'HPNhbvkY6oGfPVrj';
const PROJECT_CODE_LOCALHOST = 'QY8VNDYyHhKwZwLj';

const getProjectCode = () => {
  const origin = window?.location?.origin;
  if (origin.includes('app.accurity.ai')) {
    return PROJECT_CODE_SAAS;
  } else if (origin.includes('localhost:3000')) {
    return PROJECT_CODE_LOCALHOST;
  } else if (origin.includes('accurity-4.internal.cloudity.lan')) {
    return PROJECT_CODE_INTERNAL_4;
  }
  return null;
};

const IS_DISABLED = true;

export const GuidedToursInit = () => {

  const { email, firstName, lastName, roles } = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings || {});
  const tierName = useSelector((state: { appSettings: AppSettingsState }) => state.appSettings.tierName);

  if (IS_DISABLED) {
    return null;
  }

  const projectCode = getProjectCode();

  // we don't have data to init product fruits
  if (!email || !projectCode) {
    return null;
  }

  const userInfo = {
    username: email,
    email: email,
    firstname: firstName,
    lastname: lastName,
    role: roles?.map(q => q.name).join(', '),
    props: { tier: tierName }
  };

  return <ProductFruits projectCode={projectCode} language="en" {...userInfo} />;
}