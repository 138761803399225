import { CUSTOM_PROPERTY_TYPE, CustomPropertyState } from '../types/customPropertyTypes';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../redux/types';
import { commonReducers } from '../../../redux/commonReducers';

const initialState: CustomPropertyState = getInitialCoreEntityState(CUSTOM_PROPERTY_TYPE);

const customPropertySlice = createSlice({
  name: CUSTOM_PROPERTY_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default customPropertySlice.reducer;
