import React, { useState } from 'react';
import { AppSettingsState, CustomLogoRequest } from '../types/types';
import {
  AccurityButton,
  AccurityDetail as AccurityDetailComponent,
  CollapsingGroup,
  DetailBottomBar,
  DetailHeader,
} from 'ts-components';
import { MAX_DETAIL_WIDTH, MIN_DETAIL_WIDTH } from '../../detail/components/AccurityDetail';
import Analytics from '../../analytics';
import ApplicationSettingsConfirmDetail from './ApplicationSettingsConfirmDetail';
import CustomLogoSettingGroup from './CustomLogoSettingGroup';
import { showSnackbarMessage } from '../../userMessages/actions';
import { getNoChangesMadeMessage } from '../../userMessages/commonMessages';
import FeatureChecker from '../../userSettings/components/FeatureChecker';
import { Feature } from '../../userSettings/features/features';
import DetailTopBar from '../../detail/components/DetailTopBar';

interface ApplicationSettingsDetailProps {
  appSettings: AppSettingsState;
  updateCustomLogo: (customLogo: CustomLogoRequest) => void;
  useSampleData: () => void;
  clearAllData: () => void;
  isLoading?: boolean;
}


const CLEAR_DATA_CONFIRMATION_DETAIL = 'clearDataConfirmationDetail';
const USE_SAMPLE_DATA_CONFIRMATION_DETAIL = 'useSampleDataConfirmationDetail';

const ApplicationSettingsDetail = ({ appSettings, updateCustomLogo, useSampleData, clearAllData, isLoading }: ApplicationSettingsDetailProps) => {
  const trackAction = Analytics.createActionTracker('Detail: Application Settings');

  const [confirmationDetail, setConfirmationDetail] = useState<string | null>(null);
  const [customLogoFile, setCustomLogoFile] = useState<File | undefined>();
  const [customLogoLink, setCustomLogoLink] = useState<string | ''>();

  const handleSave = () => {

    const fileName = customLogoFile ? customLogoFile.name : '';

    if (appSettings.customLogoFilename === fileName && appSettings.customLogoLink === customLogoLink) {
      showSnackbarMessage(getNoChangesMadeMessage());
    } else {
      updateCustomLogo({
        customLogoFile,
        customLogoLink,
      });
    }
    trackAction('Save');
  };

  if (confirmationDetail === USE_SAMPLE_DATA_CONFIRMATION_DETAIL) {
    return (
      <ApplicationSettingsConfirmDetail
        title={'Use Sample Data'}
        description={
          'Are you sure you want to use sample data?' +
          '</br></br>' +
          'Doing so will remove all objects you have already created within the application. '
        }
        onConfirm={useSampleData}
        onCancel={() => setConfirmationDetail(null)}
      />
    );
  }

  if (confirmationDetail === CLEAR_DATA_CONFIRMATION_DETAIL) {
    return (
      <ApplicationSettingsConfirmDetail
        title={'Clear All Data'}
        description={'Are you sure you want to remove all objects you have already created within the application?'}
        onConfirm={clearAllData}
        onCancel={() => setConfirmationDetail(null)}
      />
    );
  }

  return (
    <AccurityDetailComponent
      minWidth={MIN_DETAIL_WIDTH}
      maxWidth={MAX_DETAIL_WIDTH}
      showProgressIndicator={isLoading}
    >
      <DetailHeader
        iconName={'mui:SettingsOutlined'}
        title={'Application Settings'}
      >
        <DetailTopBar allowComments={false} allowEmailNotification={false} />
      </DetailHeader>
      <CollapsingGroup
        name={'general'}
        title={'General'}
        icon={'mui:SettingsOutlined'}
        expandedByDefault={true}
        expandable={true}
      >
        <AccurityButton
          className="appSettings__button"
          onClick={() => setConfirmationDetail(USE_SAMPLE_DATA_CONFIRMATION_DETAIL)}
          variant="outlined"
          fullWidth
          aria-label="use-sample-data"
        >
          Use Sample Data
        </AccurityButton>
        <AccurityButton
          className="appSettings__button"
          onClick={() => setConfirmationDetail(CLEAR_DATA_CONFIRMATION_DETAIL)}
          variant="outlined"
          fullWidth
          aria-label="clear-all-data"
        >
          Clear All Data
        </AccurityButton>
      </CollapsingGroup>
      <FeatureChecker featureId={Feature.CUSTOM_LOGO}>
        <CustomLogoSettingGroup
          appSettings={appSettings}
          trackAction={trackAction}
          customLogoFile={customLogoFile}
          setCustomLogoFile={setCustomLogoFile}
          customLogoLink={customLogoLink}
          setCustomLogoLink={setCustomLogoLink}
        />
      </FeatureChecker>
      <DetailBottomBar>
        <AccurityButton
          disabled={isLoading}
          onClick={handleSave}
        >
          Save
        </AccurityButton>
      </DetailBottomBar>
    </AccurityDetailComponent>
  );
};

export default ApplicationSettingsDetail;
