import React from 'react';

import { FormikProps, useField } from 'formik';

import { DetailInlineGroup } from 'ts-components';

import {
  BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD,
  BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL,
  BusinessModelMapping,
  JoinEntityAttributeDefinition
} from '../../types/businessModelMappingTypes';
import { AccurityFilter, AccurityReference } from '../../../../common/types/accurityTypes';
import { createReferenceFieldFilter, excludeFieldValueFilter } from '../../../../common/referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../entity/types/entityTypes';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL } from '../../../attribute/types/attributeTypes';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../../attributeDefinition/types/attributeDefinitionTypes';
import { getCustomSearchEndpoint } from '../../../../common/rest/endpoints';
import { doFetch } from '../../../../common/rest/FetchService';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface EntityAttributeJoinProps {
  elementName: string;
  index: number;
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
}

const EntityAttributeJoin = ({ elementName, index, additionalFilters = [], formik }: EntityAttributeJoinProps) => {

  const entityId = formik.values.joinEntityAttributeDefinitions[index]?.entity?.id;
  const attributeDefinitionId = formik.values.joinEntityAttributeDefinitions[index]?.attributeDefinition?.id;

  const [joinField] = useField<JoinEntityAttributeDefinition[]>(BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD);

  const attributes = joinField.value
    .filter(item => !!item && !!item.entity && !!item.attributeDefinition)
    .map(row => row?.entity?.name + '.' + row?.attributeDefinition?.name)
    .join(';');

  const entityFilters = [
    ...additionalFilters,
    createReferenceFieldFilter('attributeDefinition.attributeDefinitionType', 'REFERENCE')
  ];

  const attributeDefinitionFilters = [
    ...additionalFilters,
    createReferenceFieldFilter('attributeDefinition.attributeDefinitionType', 'REFERENCE'),
    excludeFieldValueFilter('name', attributes)
  ];

  const targetEntityFilters = [
    ...additionalFilters
  ];

  if (entityId) {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('entity.id', entityId)
    );
  }

  if (attributeDefinitionId) {
    entityFilters.push(
      createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
    );
    targetEntityFilters.push(
      createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
    );
  }

  const coloring = useVersionBrowserColoring(elementName);

  const targetEntityReadOnly = () => {
    return !(entityId && attributeDefinitionId);
  };

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
        coloring={coloring}
      >
        <ReferenceFieldWithFormik
          name={elementName + '.entity'}
          label={ENTITY_LABEL}
          objectType={ENTITY_TYPE}
          customEndpoint={ENTITY_TYPE + '/business-model-mapping-entity-join'}
          additionalFilters={entityFilters}
          icon={ENTITY_ICON}
          disableColoring={true}
          filterProperty={'entity.name'}
          dragDropRowProperty={'name'}
        />
        <ReferenceFieldWithFormik
          name={elementName + '.attributeDefinition'}
          label={ATTRIBUTE_LABEL}
          objectType={ATTRIBUTE_DEFINITION_TYPE}
          customEndpoint={ATTRIBUTE_DEFINITION_TYPE + '/business-model-mapping-attribute-definition-join'}
          additionalFilters={attributeDefinitionFilters}
          icon={ATTRIBUTE_ICON}
          setValue={(attributeDefinition) => {
            if (attributeDefinition) {
              formik.setFieldValue(elementName + '.attributeDefinition', attributeDefinition);
              const searchUrl = getCustomSearchEndpoint('entity/business-model-mapping-target-entity') + '/' + attributeDefinition?.id;
              doFetch(searchUrl, 'GET')
                .then((targetEntity: AccurityReference) => formik.setFieldValue(elementName + '.targetEntity', targetEntity))
            } else {
              formik.setFieldValue(elementName + '.attributeDefinition', null);
              formik.setFieldValue(elementName + '.targetEntity', null);
            }
          }}
          disableColoring={true}
          filterProperty={'attributeDefinition.name'}
          dragDropRowProperty={'name'}
        />
      </DetailInlineGroup>
      <ReferenceFieldWithFormik
        name={elementName + '.targetEntity'}
        label={BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL}
        objectType={ENTITY_TYPE}
        icon={ENTITY_ICON}
        customEndpoint={ENTITY_TYPE + '/business-model-mapping-target-entities'}
        additionalFilters={targetEntityFilters}
        readOnly={targetEntityReadOnly()}
        coloringOverwrite={elementName}
      />
    </>
  )
};

export default EntityAttributeJoin;
