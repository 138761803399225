import { AccurityFetchError, CONTENT_DISPOSITION_HEADER, FILENAME_PREFIX, WEBSOCKET_MESSAGING_SESSION_ID } from './types';
import { getWebsocketSessionIdForCurrentWindow } from '../websocket/websocketConnectionHandler';
import { KeycloakClient } from '../keycloak/KeycloakClient';

type DoFetchOptions = {
  doNotFillContentType?: boolean;
  unauthorizedRequest?: boolean;
  downloadResponse?: boolean;
  abortSignal?: AbortSignal;
};

export const doFetch = async (url: string,
                              method: 'GET' | 'POST' | 'PUT' | 'DELETE',
                              body?: string | FormData,
                              options?: DoFetchOptions) => {

  const headers = createHeaders(options);

  const response = await fetch(url, {
    method: method,
    body: body,
    headers: headers,
    credentials: 'include',
    signal: options?.abortSignal
  });

  return options?.downloadResponse
    ? downloadResponse(response)
    : parseResponse(response);
};

const parseResponse = async (response: Response) => {
  let responseBody;
  if (response.headers.get('Content-Type') === 'application/json') {
    try {
      responseBody = await response.json();
    } catch (e) {
      console.error('Could not parse response body! ', e);
      return Promise.reject({ errorCode: response.status, errorBody: undefined } as AccurityFetchError);
    }
  }

  if (response.ok) {
    return responseBody;
  } else {
    return Promise.reject({ errorCode: response.status, errorBody: responseBody } as AccurityFetchError);
  }
};

const downloadResponse = async (response: Response) => {
  return response.blob()
    .then((blob) => {
      const contentDispositionHeader = response.headers.get(CONTENT_DISPOSITION_HEADER);
      const filename = contentDispositionHeader?.substring(contentDispositionHeader.indexOf(FILENAME_PREFIX) + FILENAME_PREFIX.length);
      if (filename) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
};

const createHeaders = (options?: DoFetchOptions) => {
  const headers = new Headers();

  if (!headers.has('Content-Type') && !options?.doNotFillContentType) {
    headers.append('Content-Type', 'application/json');
  }

  if (!options?.unauthorizedRequest) {
    headers.append('Authorization', `Bearer ${KeycloakClient.getToken()}`);
  }

  headers.append(WEBSOCKET_MESSAGING_SESSION_ID, getWebsocketSessionIdForCurrentWindow());

  return headers;
};
