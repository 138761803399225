import { Message } from '@stomp/stompjs';
import { AccurityMessage } from './types';

export const extractSessionIdFromSocket = (socket: any): string => {
  const url = socket._transport.url;
  const partUrl = url.substring(0, url.lastIndexOf(`/${socket._transport.transportName}`));
  return partUrl.substring(partUrl.lastIndexOf('/') + 1);
};

export const parseWebsocketMessage = (message: Message): AccurityMessage => {
  let accurityMessage = null;

  try {
    accurityMessage = JSON.parse(message.body);
  } catch (err) {
    console.error('Could not parse body of websocket message! ', message);
  }

  return accurityMessage;
};
