import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CoreEntityState } from '../../../common/redux/types';
import { EnumOption, Tag } from 'ts-components';
import { DATA_STRUCTURE_LABEL } from '../../dataStructure/types/dataStructureTypes';
import { DATA_FIELD_LABEL, DataFieldReference } from '../../dataField/types/dataFieldTypes';
import { ENTITY_LABEL } from '../../entity/types/entityTypes';
import { ATTRIBUTE_LABEL, HasEntityAttributeDefinition } from '../../attribute/types/attributeTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { DataSetReference } from '../../dataSet/types/dataSetTypes';

export const BUSINESS_MODEL_MAPPING_TYPE = 'business-model-mapping';
export const BUSINESS_MODEL_MAPPING_LABEL = 'Business Model Mapping';
export const BUSINESS_MODEL_MAPPING_PLURAL_LABEL = 'Business Model Mappings';
export const BUSINESS_MODEL_MAPPING_ICON = 'businessModelMapping';
export const BUSINESS_MODEL_MAPPING_BASED_ON_LABEL = 'Based on';
export const BUSINESS_MODEL_MAPPING_BUSINESS_MODEL_MAPPING_FIELD = 'businessModelMappingType';
export const MAPPING_ITEM_ENTITIES_FIELD_NAME = 'mappingItemEntities';
export const MAPPING_ITEM_DATA_STRUCTURES_FIELD_NAME = 'mappingItemDataStructures';
export const BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITIONS_FIELD_NAME = 'businessModelMappingTargetEntityAttributeDefinitions';
export const BUSINESS_MODEL_MAPPING_DATA_STRUCTURES_FIELD_NAME = 'businessModelMappingDataStructures';
// BASE
export const BUSINESS_MODEL_MAPPING_BASE_LABEL = 'Base';
export const BUSINESS_MODEL_MAPPING_BASE_FIELD = 'base';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_SET_TYPE_LABEL = 'Data Set Type (Base)';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_SET_LABEL = 'Data Set (Base)';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD = 'baseDataSet';
export const BUSINESS_MODEL_MAPPING_BASE_ENTITY_FIELD = 'baseEntity';
export const BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_DEFINITION_FIELD = 'baseAttributeDefinition';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD = 'baseDataStructure';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_FIELD = 'baseDataField';
// TARGET
export const BUSINESS_MODEL_MAPPING_TARGET_LABEL = 'Target';
export const BUSINESS_MODEL_MAPPING_TARGET_FIELD = 'target';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_TYPE_LABEL = 'Data Set Type (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_LABEL = 'Data Set (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD = 'targetDataSet';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD = 'targetDataStructureDataFields';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_LABEL = 'Target Data Structure';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_PLURAL_LABEL = 'Target Data Structures';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_LABEL = 'Target Data Field';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_PLURAL_LABEL = 'Target Data Fields';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_OPERATION_FIELD = 'operation';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_OPERATION_LABEL = 'Operation';
export const BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITION_FIELD = 'businessModelMappingTargetEntityAttributeDefinitions';
export const BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTE_LABEL = 'Target Attribute';
export const BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTE_PLURAL_LABEL = 'Target Attributes';
// SELECTIONS
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD = 'selectionItems';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL = 'Target Selection';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL = 'Target Selections';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON = 'filter';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTION_CRITERIA_FIELD = 'criteria';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTION_CRITERIA_LABEL = 'Criteria';
// JOIN
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL = 'Target Join';
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL = 'Target Joins';
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON = 'merged';
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD = 'joinDataStructureDataField';
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_LEFT_DATA_STRUCTURE_FIELD = 'joinDataStructureDataField.leftDataStructure';
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_RIGHT_DATA_STRUCTURE_FIELD = 'joinDataStructureDataField.rightDataStructure';
export const BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS = 'joinDataStructureDataField.joinDataFields';

export const BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD = 'joinEntityAttributeDefinitions';
export const BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL = 'Target Entity';
export const BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_PLURAL_LABEL = 'Target Entities';

// LIST LABELS
export const BUSINESS_MODEL_MAPPING_BASE_ENTITY_LABEL = 'Entity (Base)';
export const BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_LABEL = 'Attribute (Base)';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_LABEL = 'Data Structure (Base)';
export const BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_LABEL = 'Data Field (Base)';
export const BUSINESS_MODEL_MAPPING_TARGET_ENTITIES_LABEL = 'Entities (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTES_LABEL = 'Attributes (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURES_LABEL = 'Data Structures (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELDS_LABEL = 'Data Fields (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_OPERATIONS_LABEL = 'Operations (Target)';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_ENTITIES_LABEL = 'Entities (Target Selections)';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_ATTRIBUTES_LABEL = 'Attributes (Target Selections)';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_DATA_STRUCTURES_LABEL = 'Data Structures (Target Selections)';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_DATA_FIELDS_LABEL = 'Data Fields (Target Selections)';
export const BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_CRITERIA_LABEL = 'Criteria (Target Selections)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_ENTITY_LABEL = 'Entity (Target Joins)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_REFERENCE_ATTRIBUTE_LABEL = 'Reference Attribute (Target Joins)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_TARGET_ENTITY_LABEL = 'Target Entity (Target Joins)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_LEFT_DATA_STRUCTURE_LABEL = 'Left Data Structure (Target Joins)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_RIGHT_DATA_STRUCTURE_LABEL = 'Right Data Structure (Target Joins)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_LEFT_DATA_FIELDS_LABEL = 'Left Data Fields (Target Joins)';
export const BUSINESS_MODEL_MAPPING_TARGET_JOINS_RIGHT_DATA_FIELDS_LABEL = 'Right Data Fields (Target Joins)';

export interface BusinessModelMappingState extends CoreEntityState<BusinessModelMapping> {
}

export enum BusinessModelMappingType {
  ENTITY = 'ENTITY',
  ATTRIBUTE = 'ATTRIBUTE',
  DATA_STRUCTURE = 'DATA_STRUCTURE',
  DATA_FIELD = 'DATA_FIELD'
}

export const BusinessModelMappingTypeOptions: EnumOption[] = [
  { value: BusinessModelMappingType.ENTITY, label: ENTITY_LABEL },
  { value: BusinessModelMappingType.ATTRIBUTE, label: ATTRIBUTE_LABEL },
  { value: BusinessModelMappingType.DATA_STRUCTURE, label: DATA_STRUCTURE_LABEL },
  { value: BusinessModelMappingType.DATA_FIELD, label: DATA_FIELD_LABEL },
];

export interface BusinessModelMapping extends AccurityCoreEntity {
  status: AccurityReference;
  tags: Tag[];
  baseDataSet?: DataSetReference;
  businessModelMappingType: BusinessModelMappingType;
  baseEntity?: AccurityReference;
  baseAttributeDefinition?: AccurityReference;
  baseAttribute?: AccurityReference;
  baseDataStructure?: AccurityReference;
  baseDataField?: DataFieldReference;
  targetDataSet?: DataSetReference;
  targetDataStructureDataFields: TargetDataStructureDataField[];
  businessModelMappingTargetEntityAttributeDefinitions: BusinessModelMappingTargetEntityAttributeDefinition[];
  selectionItems: SelectionItem[];
  joinDataStructureDataField?: JoinDataStructureDataField;
  joinEntityAttributeDefinitions: JoinEntityAttributeDefinition[];
  customPropertyValues: CustomPropertyValues;
}

export interface TargetDataStructureDataField {
  id: number;
  dataStructure: AccurityReference;
  dataField?: DataFieldReference;
  operation?: string;
}

export interface BusinessModelMappingTargetEntityAttributeDefinition extends HasEntityAttributeDefinition {
  id: number;
}

export interface SelectionItem extends HasEntityAttributeDefinition {
  id: number;
  dataStructure?: AccurityReference;
  dataField?: DataFieldReference;
  criteria?: string;
}

export interface JoinDataStructureDataField {
  id: number;
  leftDataStructure: AccurityReference;
  rightDataStructure: AccurityReference;
  joinDataFields?: JoinDataField[];
}

export interface JoinDataField {
  id: number;
  leftDataField: AccurityReference;
  rightDataField: AccurityReference;
}

export interface JoinEntityAttributeDefinition extends HasEntityAttributeDefinition {
  id: number;
  targetEntity?: AccurityReference;
}
