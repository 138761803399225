import { DATA_STRUCTURE_TYPE, DataStructureState } from '../types/dataStructureTypes';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';

const initialState: DataStructureState = getInitialCoreEntityState(DATA_STRUCTURE_TYPE);

const dataStructureSlice = createSlice({
  name: DATA_STRUCTURE_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default dataStructureSlice.reducer;
