import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption, Tag } from 'ts-components';

export const ENTITY_TYPE = 'entity';
export const ENTITY_LABEL = 'Entity';
export const ENTITY_PLURAL_LABEL = 'Entities';
export const ENTITY_ICON = 'entity';
export const ENTITY_DESCRIPTION = 'Entity Description';
export const BASED_ON_LABEL = 'Based on';
export const BASED_ON_DESCRIPTION_LABEL = 'Based on - Description';
export const BUSINESS_TERM_DESCRIPTION = 'Business Term Description';
export const ENTITY_TYPE_LABEL = 'Entity Type';
export const PARENT_ENTITY_LABEL = 'Parent Entity';
export const CHILD_ENTITY_LABEL = 'Child Entity';
export const CHILD_ENTITY_PLURAL_LABEL = 'Child Entities';

export const CONFIRM_ENTITY_PARENT_CHANGE_SETTINGS_DETAIL = 'CONFIRM_ENTITY_PARENT_CHANGE';

export interface EntityState extends CoreEntityState<Entity> {
}

export enum EntityType {
  MAIN = 'MAIN',
  RELATIONSHIP = 'RELATIONSHIP'
}

export const EntityTypeOptions: EnumOption[] = [
  { value: EntityType.MAIN, label: 'Main' },
  { value: EntityType.RELATIONSHIP, label: 'Relationship' },
];

export interface Entity extends AccurityCoreEntity, WithChildrenCounts {
  basedOnBusinessTerm: AccurityReference;
  status?: AccurityReference;
  entityType?: EntityType;
  parent?: AccurityReference;
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
  keepInheritedAttributes?: boolean;
}

export interface HasEntity {
  entity?: AccurityReference;
}