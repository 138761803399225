import React from 'react';
import { DetailHeader, StaticField } from 'ts-components';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useTranslation } from 'react-i18next';

interface SettingsDetailProps {
  objectName: string;
  iconName: string;
  confirmDescription: string;
  bottomBar: React.ReactNode;
  children?: React.ReactNode;
}

const SettingsDetail = ({ objectName, iconName, confirmDescription, bottomBar, children }: SettingsDetailProps) => {
  const formik = useFormikContext<AccurityCoreEntity>();
  const { t } = useTranslation();
  const objectTypeLabel = t(`common.${formik.values.objectType}`);

  return (
    <>
      <DetailHeader iconName={iconName} inverted={true}>
        <StaticField
          name={'name'}
          label={`${objectTypeLabel}`}
          value={objectName}
          inverted={true}
        />
      </DetailHeader>
      <StaticField
        name={'confirmDescription'}
        value={confirmDescription}
        inverted={true}
      />
      {children}
      {bottomBar}
    </>
  );
};

export default SettingsDetail;
