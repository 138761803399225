import React from 'react';
import { AccurityDivider, ActionField } from 'ts-components';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';
import { BUSINESS_TERM_DIAGRAM_TYPE } from '../../dataLineageDiagram/types/types';

interface BusinessTermDiagramButtonProps {
  id: string;
  disabled?: boolean;
}

const BusinessTermDiagramButton = ({ id, disabled }: BusinessTermDiagramButtonProps) => {
  const { openDiagram } = useAccurityNavigation();
  const formik = useFormikContext<AccurityCoreEntity>();

  if (formik.status.isVersionBrowser || !formik.status.isUpdateDetail) {
    return null;
  }

  return (
    <>
      <AccurityDivider/>
      <ActionField
        label={'View Business Term Lineage'}
        iconName={'dataLineage'}
        onClick={() => openDiagram(BUSINESS_TERM_DIAGRAM_TYPE, id)}
        disabled={disabled}
      />
    </>
  );
};

export default BusinessTermDiagramButton;
