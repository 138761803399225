import { useEffect } from 'react';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';
import { useSelector } from 'react-redux';
import { CoreEntityState } from '../../../common/redux/types';
import { doBackgroundFetchByObjectTypeIdAndAddToStore } from '../../../common/redux/commonActions';

export const useReferencingListLogic = <T extends AccurityCoreEntity>(type: string, id: string): T | undefined => {
  const referencedEntity = useSelector((state: { [type: string]: CoreEntityState<T> }) => state[type]?.byId[id]?.data)

  useEffect(() => {
    if (!referencedEntity) {
      doBackgroundFetchByObjectTypeIdAndAddToStore(type, id);
    }
  }, [referencedEntity, type, id])

  return referencedEntity;
}
