import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { BUSINESS_RULE_TYPE, BusinessRuleState } from '../types/businessRuleTypes';

const initialState: BusinessRuleState = getInitialCoreEntityState(BUSINESS_RULE_TYPE);

const businessRuleSlice = createSlice({
  name: BUSINESS_RULE_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default businessRuleSlice.reducer;
