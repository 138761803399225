import { CollapsingGroup, DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE } from '../../../dataStructure/types/dataStructureTypes';
import {
  TECHNICAL_DATA_MAPPING_JOIN_ICON,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_LEFT_DATA_STRUCTURE_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_RIGHT_DATA_STRUCTURE_FIELD,
  TechnicalDataMapping
} from '../../types/technicalDataMappingTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps } from 'formik';
import { createReferenceFieldFilter } from '../../../../common/referenceField/utils/filters';
import { clearTargetDataFieldJoinsOnDataStructureRemove, getTechnicalDataMappingDataStructureDataFieldJoinCountLabel } from '../technicalDataMappingDetailUtils';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import TechnicalDataMappingTargetDataFieldsJoin from './TechnicalDataMappingTargetDataFieldsJoin';

interface DataStructureDataFieldJoinProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<TechnicalDataMapping>;
}

const TechnicalDataMappingTargetDataStructureDataFieldJoin = ({ additionalFilters = [], formik }: DataStructureDataFieldJoinProps) => {

  const dataStructuresFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.targetDataSet?.id),
    ...additionalFilters,
  ];

  const leftDataStructureId = formik.values.technicalDataMappingTargetJoinDataStructureDataField?.leftDataStructure?.id;
  const rightDataStructureId = formik.values.technicalDataMappingTargetJoinDataStructureDataField?.rightDataStructure?.id;
  const joins = formik.getFieldProps('technicalDataMappingTargetJoinDataStructureDataField.technicalDataMappingTargetJoinDataFields').value;
  const isStructuresSelected = leftDataStructureId && rightDataStructureId;
  const isVersionBrowser = formik.status.isVersionBrowser;

  return (
    <>
      <CollapsingGroup
        name={'joins'}
        title={
          getTechnicalDataMappingDataStructureDataFieldJoinCountLabel(
            joins,
            TECHNICAL_DATA_MAPPING_TARGET_JOIN_LABEL,
            TECHNICAL_DATA_MAPPING_TARGET_JOIN_PLURAL_LABEL
          )
        }
        icon={TECHNICAL_DATA_MAPPING_JOIN_ICON}
        expandedByDefault={false}
        expandable={true}
      >
        <DetailInlineGroup indentRight={!isVersionBrowser}>
          <ReferenceFieldWithFormik
            name={TECHNICAL_DATA_MAPPING_TARGET_JOIN_LEFT_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            objectType={DATA_STRUCTURE_TYPE}
            icon={DATA_STRUCTURE_ICON}
            additionalFilters={dataStructuresFilter}
            setValue={(leftDataStructure) => {
              clearTargetDataFieldJoinsOnDataStructureRemove(formik, TECHNICAL_DATA_MAPPING_TARGET_JOIN_LEFT_DATA_STRUCTURE_FIELD, leftDataStructure, rightDataStructureId);
            }}
            coloringOverwrite={TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD}
          />
          <ReferenceFieldWithFormik
            name={TECHNICAL_DATA_MAPPING_TARGET_JOIN_RIGHT_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            objectType={DATA_STRUCTURE_TYPE}
            icon={DATA_STRUCTURE_ICON}
            additionalFilters={dataStructuresFilter}
            setValue={(rightDataStructure) => {
              clearTargetDataFieldJoinsOnDataStructureRemove(formik, TECHNICAL_DATA_MAPPING_TARGET_JOIN_RIGHT_DATA_STRUCTURE_FIELD, rightDataStructure, leftDataStructureId);
            }}
            coloringOverwrite={TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD}
          />
        </DetailInlineGroup>
        {isStructuresSelected &&
        <CollectionFieldWithFormik
          name={TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD}
          insideCollapsingGroup={false}
          rowValidation={(value) => value && value.leftDataField && value.leftDataField.id && value.rightDataField && value.rightDataField.id}
          renderRow={(elementName, index) =>
            <TechnicalDataMappingTargetDataFieldsJoin
              elementName={elementName}
              index={index}
              additionalFilters={additionalFilters}
              formik={formik}
            />
          }
        />}
      </CollapsingGroup>
    </>
  )
};

export default TechnicalDataMappingTargetDataStructureDataFieldJoin;
