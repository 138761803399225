import React, { useMemo } from 'react';
import { InteractiveDiagram } from 'ts-components';
import { BUSINESS_MODEL_DIAGRAM_TYPE, BusinessModelDiagram, GraphContent } from '../types/businessModelDiagramTypes';
import BusinessModelDiagramTopBarControls from './BusinessModelDiagramTopBarControls';
import { requestDisplayNeighboursOfNode, requestGenerateGraph, requestHideNode, requestLoadGraph, requestSaveGraph, requestUpdateAttributesOfNode } from '../rest/rest';
import { AccurityReference } from '../../../common/types/accurityTypes';
import BusinessModelDiagramNode, { BusinessModelDiagramNodeProps } from './node/BusinessModelDiagramNode';
import BusinessModelDiagramLink, { BusinessModelDiagramLinkProps } from './link/BusinessModelDiagramLink';
import { ENTITY_TYPE } from '../../entity/types/entityTypes';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { useInteractiveDiagramsLogic } from './useInteractiveDiagramsLogic';

interface BusinessModelDiagramProps {
  diagramId: string;
}

const BusinessModelDiagramComponent = ({ diagramId }: BusinessModelDiagramProps) => {
  const hasUpdatePermission = usePermissionsForObjectType(BUSINESS_MODEL_DIAGRAM_TYPE).hasUpdatePermission;
  const commonDiagramsLogic = useInteractiveDiagramsLogic<BusinessModelDiagram, BusinessModelDiagramNodeProps, BusinessModelDiagramLinkProps>(
    BUSINESS_MODEL_DIAGRAM_TYPE,
    diagramId,
    requestLoadGraph,
    requestHideNode,
    requestSaveGraph,
  );

  const {
    diagramBag,
    setDiagramState,
    diagramInfo,
    selectedTags,
    setSelectedTags,
    handleHideNode,
    onSave,
    onReload,
    onOpenDetail,
    onCloseDiagram,
  } = commonDiagramsLogic;

  const handleGenerate = (selectedItems: AccurityReference[]) => {
    requestGenerateGraph(selectedItems)
      .then((graphContent: GraphContent) => {
        setDiagramState({
          nodes: graphContent?.nodes,
          links: graphContent?.links,
          secondaryLinks: graphContent?.secondaryLinks,
          transform: undefined,
        }, false);
      });
  };

  const handleDisplayNeighboursOfNode = (nodeId: string) => {
    requestDisplayNeighboursOfNode(diagramBag.state, nodeId)
      .then((graphContent: GraphContent) => {
        setDiagramState({
          ...diagramBag.state,
          ...graphContent,
        });
      });
  };

  const handleUpdateAttributesOfNode = (nodeId: string, attributeIds: string[]) => {
    requestUpdateAttributesOfNode(diagramBag.state, nodeId, attributeIds)
      .then((graphContent: GraphContent) => {
        setDiagramState({
          ...diagramBag.state,
          ...graphContent,
        });
      });
  };

  const renderNode = (node: BusinessModelDiagramNodeProps) => (
    <BusinessModelDiagramNode
      key={node.id}
      {...node}
      hideNode={handleHideNode}
      displayNeighboursOfNode={handleDisplayNeighboursOfNode}
      updateAttributes={handleUpdateAttributesOfNode}
    />
  );

  const renderLink = (link: BusinessModelDiagramLinkProps) => (
    <BusinessModelDiagramLink
      key={link.id}
      {...link}
    />
  );

  const selectedItems: AccurityReference[] = useMemo(() => (
    Object.values(diagramBag.state.nodes)
      .filter((node: BusinessModelDiagramNodeProps) => node.objectType === ENTITY_TYPE)
      .map((node: BusinessModelDiagramNodeProps) => ({
          id: node.objectId,
          objectType: node.objectType,
          name: node.label,
        })
      )
  ), [diagramBag.state.nodes]);

  if (!diagramInfo) {
    return null;
  }

  return (
    <InteractiveDiagram<BusinessModelDiagramNodeProps, BusinessModelDiagramLinkProps>
      diagramContext={diagramBag}
      renderNode={renderNode}
      renderLink={renderLink}
      onSave={onSave}
      onReload={onReload}
      onOpenDetail={onOpenDetail}
      onCloseDiagram={onCloseDiagram}
      readOnly={!hasUpdatePermission}
      name={diagramInfo?.name}
      topBarControls={
        <BusinessModelDiagramTopBarControls
          tags={selectedTags}
          setTags={setSelectedTags}
          initialSelectedItems={selectedItems}
          onGenerate={handleGenerate}
          hasUpdatePermission={hasUpdatePermission}
        />
      }
    />
  );
};

export default BusinessModelDiagramComponent;
