import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../redux/types';
import { commonReducers } from '../../../redux/commonReducers';
import { CUSTOM_PROPERTY_GROUP_TYPE, CustomPropertyGroupState } from '../types/customPropertyGroupTypes';

const initialState: CustomPropertyGroupState = getInitialCoreEntityState(CUSTOM_PROPERTY_GROUP_TYPE);

const customPropertyGroupSlice = createSlice({
  name: CUSTOM_PROPERTY_GROUP_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default customPropertyGroupSlice.reducer;
