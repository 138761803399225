import React from 'react';
import { FormikProps } from 'formik';
import { CUSTOM_PROPERTY_DEFAULT_LABEL, CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, CustomProperty } from '../../types/customPropertyTypes';
import { AccurityFilter } from '../../../../types/accurityTypes';
import { createReferenceFieldFilter } from '../../../../referenceField/utils/filters';
import { DetailInlineGroup, EnumOption } from 'ts-components';
import ReferenceFieldWithFormik from '../../../../detail/formik/ReferenceFieldWithFormik';
import { getSupportiveCustomPropertyDefaultValueFieldLabel, targetObjectTypeIcon, targetObjectTypeTypeEndpoint } from '../../../customPropertyUtils';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../../../businessGlossary/dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../detail/formik/EnumerationFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE, DataStructureReference } from '../../../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DATA_FIELD_LABEL, DataFieldReference } from '../../../../../businessGlossary/dataField/types/dataFieldTypes';

interface DataFieldDefaultValueFieldProps {
  formik: FormikProps<CustomProperty>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const DataFieldDefaultValueField = ({ formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions }: DataFieldDefaultValueFieldProps) => {

  const targetObjectType = formik.values.targetObjectType;
  const objectType = targetObjectTypeTypeEndpoint(targetObjectType);
  const icon = targetObjectTypeIcon(targetObjectType);
  const readOnly = targetObjectType === null;

  const dataSetReferenceFieldProps = formik.getFieldProps(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference').value;
  const dataStructureReferenceFieldProps = formik.getFieldProps(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataStructureReference').value;

  let filters: AccurityFilter[] = [];

  const dataSetId = dataSetReferenceFieldProps?.id;
  const dataStructureId = dataStructureReferenceFieldProps?.id;

  const dataStructureFilters: AccurityFilter[] = [
    createReferenceFieldFilter('dataSet.id', dataSetId),
    createReferenceFieldFilter('childrenCounts.dataFieldsCount', '>=1')
  ];
  filters.push(
    createReferenceFieldFilter('dataStructure.dataSet.id', dataSetId),
    createReferenceFieldFilter('dataStructure.id', dataStructureId)
  );


  return (
    <>

      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_SET_LABEL)}
          objectType={DATA_SET_TYPE}
          icon={DATA_SET_ICON}
          setValue={(dataSet) => {
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference', dataSet);
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataStructureReference', null);
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', null);
          }
          }
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
        {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference.dataSetType'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_SET_TYPE_LABEL)}
          options={dataSetTypeEnumOptions}
          readOnly={true}
        />}
      </DetailInlineGroup>

      <DetailInlineGroup
        childGridSizes={[6, 6]}
      >
        <ReferenceFieldWithFormik<DataStructureReference>
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataStructureReference'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_STRUCTURE_LABEL)}
          objectType={DATA_STRUCTURE_TYPE}
          additionalFilters={dataStructureFilters}
          icon={DATA_STRUCTURE_ICON}
          setValue={(dataStructure) => {
            if (!dataSetId) {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference', dataStructure?.dataSet);
            }
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataStructureReference', dataStructure);
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', null);
          }}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
        <ReferenceFieldWithFormik<DataFieldReference>
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference'}
          icon={icon}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, DATA_FIELD_LABEL)}
          additionalFilters={filters}
          setValue={(dataField) => {
            if (!dataSetId) {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataSetReference', dataField?.dataStructure?.dataSet)
            }
            if (!dataStructureId) {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.dataStructureReference', dataField?.dataStructure);
            }
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', dataField);
          }}
          objectType={objectType}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
          readOnly={readOnly}
        />
      </DetailInlineGroup>
    </>
  )
};

export default DataFieldDefaultValueField;