import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BULK_OPERATION_STORE_NAME, BulkOperationsState, CoreEntityWithTags } from '../types';
import { CoreEntityState } from '../../redux/types';
import { AccurityCoreEntity } from '../../types/accurityTypes';

const initialState: BulkOperationsState = {
  isInProgress: false,
  selectedRows: [],
};

const bulkOperationSlice = createSlice({
  name: BULK_OPERATION_STORE_NAME,
  initialState: initialState,
  reducers: {
    selectRows: (state: BulkOperationsState, action: PayloadAction<string[]>) => {
      state.selectedRows = action.payload;
    },
    unselectRows: (state: BulkOperationsState, action: PayloadAction<string[]>) => {
      state.selectedRows = state.selectedRows.filter(selected => !action.payload.includes(selected));
    },
    resetState: (state: BulkOperationsState) => {
      state.selectedRows = [];
      state.isInProgress = false;
    },
    setIsInProgress: (state: BulkOperationsState, action: PayloadAction<boolean>) => {
      state.isInProgress = action.payload;
    },
  }
});

export default bulkOperationSlice;

/**
 * Actions
 */
export const {
  resetState,
  selectRows,
  unselectRows,
  setIsInProgress
} = bulkOperationSlice.actions;

/**
 * Selectors
 */
export const selectSelectedEntities = (listType: string) => (state: any): CoreEntityWithTags[] => {
  const bulkOperationStore = state[BULK_OPERATION_STORE_NAME] as BulkOperationsState;
  const entitiesStore = state[listType] as (CoreEntityState<AccurityCoreEntity> | undefined);

  return bulkOperationStore.selectedRows
    // map ids to objects
    .map(id => entitiesStore?.byId[id]?.data)
    // filter out potential null/undefined objects
    .filter(entity => !!entity) as CoreEntityWithTags[];
};

export const selectIsInProgress = (state: any) =>
  (state[BULK_OPERATION_STORE_NAME] as BulkOperationsState).isInProgress;

