import { getApplicationSettingsEndpoint, getCustomLogoSettingEndpoint, getCustomLogoUpdateEndpoint, getPublicSettingsEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { ApplicationSettingsResponse, CustomLogoRequest, PublicSettingsResponse } from '../types/types';

export const requestPublicSetting = (): Promise<PublicSettingsResponse> => {

  return doFetch(getPublicSettingsEndpoint(), 'GET', undefined, { unauthorizedRequest: true })
    .catch(showErrorMessageFromResponse);
};

export const requestApplicationSettings = (): Promise<ApplicationSettingsResponse> => {
  return doFetch(getApplicationSettingsEndpoint(), 'GET')
    .catch(showErrorMessageFromResponse);
};

export const requestUpdateCustomLogo = (customLogo: CustomLogoRequest) => {
  const customLogoUpdateUrl = getCustomLogoUpdateEndpoint();
  const body = new FormData();
  if (customLogo.customLogoLink) {
    body.append('customLogoLink', String(customLogo.customLogoLink));
  }
  if (customLogo.customLogoFile) {
    body.append('customLogoFile', customLogo.customLogoFile);
  }

  return doFetch(customLogoUpdateUrl, 'POST', body, { doNotFillContentType: true });
};

export const requestGetCustomLogo = () => {
  const customLogoFetchUrl = getCustomLogoSettingEndpoint();

  return doFetch(customLogoFetchUrl, 'GET');
};
