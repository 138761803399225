import { doFetch } from '../../../common/rest/FetchService';
import { getDisableEmailNotificationsForUser, getRestoreDefaultUiSettings, getUserLockUnlockEndpoint } from '../../../common/rest/endpoints';
import { showErrorMessageFromResponse } from '../../../common/userMessages/actions';
import { User } from '../types/userTypes';

export const lockUnlockUserRequest = (user: User): Promise<User> => {
  return doFetch(getUserLockUnlockEndpoint(), 'PUT', JSON.stringify({ userInfoId: user.id }))
    .catch(showErrorMessageFromResponse);
};

export const restoreDefaultSettingsRequest = (userInfoId: string) => {
  return doFetch(getRestoreDefaultUiSettings(), 'PUT', JSON.stringify({ userInfoId: userInfoId }))
    .catch(showErrorMessageFromResponse)
};

export const disableEmailNotificationsForUserRequest = (userInfoId: string) => {
  return doFetch(getDisableEmailNotificationsForUser(), 'PUT', JSON.stringify({ userInfoId: userInfoId }))
    .catch(showErrorMessageFromResponse)
};