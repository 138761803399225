import {
  AccurityFilterType,
  Align,
  BooleanColumn,
  COLUMN_DEFAULT_WIDTH_POINTS,
  columnTypes,
  DateColumn,
  DownloadColumn,
  EnumColumn,
  IconColumn,
  NumberColumn,
  NumericFormat,
  PercentageColumn,
  Tag,
  TagColumn,
  TextColumn,
  TimeColumn
} from 'ts-components';
import { EmailNotificationActionType } from '../../types/accurityTypes';
import { requestTagFieldSearch } from '../../tagField/rest';
import { useAccurityNavigation } from '../../navigation/hooks';
import Analytics from '../../analytics';
import { COMMENTS_COUNT } from '../../comments/types/types';

const commonColumnProps = {
  hidden: false,
  width: COLUMN_DEFAULT_WIDTH_POINTS,
  index: 0,
  filterable: true,
};

export const columnFactory = {
  createTextColumn: (name: string, label: string, overwrites?: Partial<TextColumn>): TextColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.TEXT_TYPE,
  }),

  createEnumColumn: (name: string, label: string, options: any[], overwrites?: Partial<EnumColumn>): EnumColumn => ({
    ...commonColumnProps,
    align: Align.LEFT,
    ...overwrites,
    options,
    name,
    label,
    type: columnTypes.ENUM_TYPE,
  }),

  createBooleanColumn: (name: string, label: string, overwrites?: Partial<BooleanColumn>): BooleanColumn => ({
    ...commonColumnProps,
    align: Align.LEFT,
    ...overwrites,
    name,
    label,
    type: columnTypes.BOOL_TYPE,
  }),

  createNumberColumn: (name: string, label: string, overwrites?: Partial<NumberColumn>, numericFormat?: NumericFormat): NumberColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.NUMBER_TYPE,
    numericFormat: numericFormat,
  }),

  createDateColumn: (name: string, label: string, overwrites?: Partial<DateColumn>): DateColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.DATE_TYPE,
  }),

  createPercentageColumn: (name: string, label: string, overwrites?: Partial<PercentageColumn>): PercentageColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.PERCENTAGE_TYPE,
  }),

  createTimeColumn: (name: string, label: string, overwrites?: Partial<TimeColumn>): TimeColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.TIME_TYPE,
  }),

  createDownloadColumn: (name: string, label: string, overwrites?: Partial<DownloadColumn>): DownloadColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.DOWNLOAD_LINK_TYPE,
  }),

  createIconColumn: (name: string, label: string, overwrites?: Partial<IconColumn>): IconColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.ICON_TYPE,
  }),

  createTagsColumn: (
    name: string,
    label: string,
    searchProperty: string,
    navigation: ReturnType<typeof useAccurityNavigation>,
    overwrites?: Partial<TagColumn>
  ): TagColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    searchProperty,
    filterValueProperty: 'id',
    doSearch: search => requestTagFieldSearch(search).then(response => response.rows),
    onTagClick: (tag: Tag) => {
      navigation.openListForTag(tag.name);
      Analytics.event(`List: ${navigation.getCurrentNavigation().listType}`, 'Tag click', tag.name);
    },
    type: columnTypes.TAG_TYPE,
    sortable: false
  }),

  createHyperlinkColumn: (name: string, label: string, overwrites?: Partial<TextColumn>): TextColumn => ({ // TODO add HyperlinkColumn type in ts-components
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.HYPERLINK_TYPE,
  }),

  createEmailNotificationColumn: (notificationType: EmailNotificationActionType, label: string): BooleanColumn => ({
    ...commonColumnProps,
    align: Align.CENTER,
    name: `activeNotifications.${notificationType}`,
    label,
    hidden: true,
    filterType: AccurityFilterType.ACTIVE_NOTIFICATION,
    sortable: false,
    type: columnTypes.BOOL_TYPE
  }),

  createCollectionColumn: (name: string, label: string, overwrites?: Partial<TextColumn>): TextColumn =>
    columnFactory.createTextColumn(name, label, { ...overwrites, sortable: false }),
};

type ColumnParams<T = unknown> = {
  name?: string;
  label?: string;
} & Partial<T>;

export const commonColumns = {
  id: ({ name = 'id', label = 'ID' }: ColumnParams = {}) =>
    columnFactory.createNumberColumn(name, label, { hidden: true, align: Align.LEFT, hideComparisonSelector: true }, NumericFormat.POSITIVE_NUMERIC),

  name: ({ name = 'name', label = 'Name' }: ColumnParams = {}) =>
    columnFactory.createTextColumn(name, label),

  tags: (navigation: ReturnType<typeof useAccurityNavigation>, { name = 'tags', label = 'Tags', ...overwrites }: ColumnParams<TagColumn> = {}) =>
    columnFactory.createTagsColumn(name, label, 'tags.id', navigation, overwrites),

  description: ({ name = 'description.plainTextValue', label = 'Description', sortable, hidden }: ColumnParams<{ sortable?: boolean, hidden?: boolean }> = {}) =>
    columnFactory.createTextColumn(name, label, { sortable, hidden }),

  createdTime: ({ name = 'createdDate', label = 'Created at' }: ColumnParams = {}) =>
    columnFactory.createDateColumn(name, label, { hidden: true }),

  createdBy: ({ name = 'createdBy.name', label = 'Created by' }: ColumnParams = {}) =>
    columnFactory.createTextColumn(name, label, { hidden: true }),

  lastChangedTime: ({ name = 'changedDate', label = 'Updated at' }: ColumnParams = {}) =>
    columnFactory.createDateColumn(name, label, { hidden: true }),

  lastChangedBy: ({ name = 'changedBy.name', label = 'Updated by' }: ColumnParams = {}) =>
    columnFactory.createTextColumn(name, label, { hidden: true }),

  status: ({ name = 'status.name', label = 'Status' }: ColumnParams = {}) =>
    columnFactory.createTextColumn(name, label, { hidden: true }),

  collection: ({ name, label }: Required<ColumnParams>) =>
    columnFactory.createTextColumn(name, label, { sortable: false }),

  commentsNotification: ({ label = 'Notifications - Comments' }: ColumnParams = {}) =>
    columnFactory.createEmailNotificationColumn(EmailNotificationActionType.COMMENT_UPDATE, label),

  objectUpdatesNotification: ({ label = 'Notifications - Updates' }: ColumnParams = {}) =>
    columnFactory.createEmailNotificationColumn(EmailNotificationActionType.OBJECT_UPDATE, label),

  commentsCount: ({ label = 'Comments' }: ColumnParams = {}) =>
    columnFactory.createNumberColumn(COMMENTS_COUNT, label, { hidden: true }, NumericFormat.POSITIVE_NUMERIC),

};
