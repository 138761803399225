import React from 'react';
import { DetailHeader, DetailInlineGroup, EnumOption, StaticField } from 'ts-components';
import { DATA_FIELD_ICON, DATA_FIELD_TYPE, DataField, DataFieldConstraintOptions, } from '../types/dataFieldTypes';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { getDataSetFilters, getDataStructureFilters, getDataStructureValueHandler } from './dataFieldUtils';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL, DataSetReference, DataSetType, useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import {
  DATA_STRUCTURE_ICON,
  DATA_STRUCTURE_LABEL,
  DATA_STRUCTURE_TYPE,
  DATA_STRUCTURE_TYPE_FIELD_LABEL,
  dataStructureTypeOptions,
} from '../../dataStructure/types/dataStructureTypes';
import SingleLineFieldWithFormik from '../../../common/detail/formik/SinglelineFieldWithFormik';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import DataLineageDiagramButton from '../../../common/detail/commonFields/DataLineageDiagramButton';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import { Feature } from '../../../common/userSettings/features/features';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import RelatedBusinessTermsWithSuggestionsForDataField from './RelatedBusinessTermsWithSuggestionsForDataField';
import {
  TECHNICAL_DATA_MAPPING_ICON,
  TECHNICAL_DATA_MAPPING_LABEL,
  TECHNICAL_DATA_MAPPING_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_TYPE
} from '../../technicalModelMapping/types/technicalDataMappingTypes';
import FeatureCheckerOr from '../../../common/userSettings/components/FeatureCheckerOr';
import ColumnProfilingResultButton from '../../../common/columnProfiling/components/ColumnProfilingResultButton';
import DoColumnProfilingButton from '../../../common/columnProfiling/components/DoColumnProfilingButton';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { COLUMN_PROFILING_TYPE } from '../../../common/columnProfiling/types/types';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import { DataSyncResultField } from '../../../common/dataCatalogSync/components/DataSyncResultField';
import { DataCatalogSyncStatus } from '../../../common/dataCatalogSync/types';
import ChildrenCountsContainer from '../../../common/detail/components/ChildrenCountsContainer';
import BusinessModelMappingDiagramButton from '../../../common/detail/commonFields/BusinessModelMappingDiagramButton';
import TechnicalDataMappingDiagramButton from '../../../common/detail/commonFields/TechnicalDataMappingDiagramButton';

interface DataFieldDetailProps {
  id?: string,
}

const DataFieldDetail = ({ id }: DataFieldDetailProps) => {

  return (
    <AccurityDetailContainer<DataField>
      objectType={DATA_FIELD_TYPE}
      id={id}
    >
      {(formik, detailBag) => (
        <DataFieldDetailWrapper
          formik={formik}
          detailBag={detailBag}
        />
      )}
    </AccurityDetailContainer>
  );
};

const isDataFieldRemovable = (dataField: DataField) => {
  if (dataField.dataSet.dataSetType === DataSetType.LOGICAL) {
    return true;
  }

  return dataField.syncResult?.status === DataCatalogSyncStatus.OBSOLETED;
};

const DataFieldDetailWrapper = ({ formik, detailBag }: { formik: FormikProps<DataField>; detailBag: DetailBag<DataField> }) => {

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();
  const isLogical = formik.values?.dataSet?.dataSetType === DataSetType.LOGICAL;
  const isUpdateDetail = formik.status.isUpdateDetail;
  const { hasDeletePermission, hasCreatePermission } = formik.status.permissions;

  const columnProfilingPermissions = usePermissionsForObjectType(COLUMN_PROFILING_TYPE);
  const isDataFieldProfileable = formik.values.isProfileable && hasFeature(Feature.COLUMN_PROFILING);
  const canSeeProfilingResults = isDataFieldProfileable && columnProfilingPermissions.hasReadPermission;

  const customActionButtons = isDataFieldProfileable && columnProfilingPermissions.hasLaunchPermission
    ? [<DoColumnProfilingButton dataField={formik.values} key={'do-column-profiling'}/>]
    : [];

  if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
    return getConfirmRemovalDetail(formik, detailBag);
  } else {
    return (
      <>
        {getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions, canSeeProfilingResults)}
        <DefaultDetailBottomBar
          objectType={DATA_FIELD_TYPE}
          detailBag={{
            ...detailBag,
            showRemoveAction: isUpdateDetail && hasDeletePermission && isDataFieldRemovable(formik.values),
            showCopyAction: isUpdateDetail && hasCreatePermission && isLogical,
          }}
          customActionButtons={customActionButtons}
        />
      </>
    );
  }
};

const getConfirmRemovalDetail = (formik: FormikProps<DataField>, detailBag: DetailBag<DataField>) => (
  <ConfirmRemovalDetail
    iconName={DATA_FIELD_ICON}
    detailBag={detailBag}
  >
    <FeatureCheckerOr featureIds={[Feature.BUSINESS_MODEL_MAPPINGS, Feature.TECHNICAL_DATA_MAPPINGS]}>
      <StaticField
        name={'removalDescription1'}
        value={IT_IS_CURRENTLY_LABEL}
        inverted={true}
      />
      <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
          pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessModelMappingsCount}
          iconName={BUSINESS_MODEL_MAPPING_ICON}
          pinIconName={DATA_FIELD_ICON}
          childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.TECHNICAL_DATA_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={TECHNICAL_DATA_MAPPING_LABEL}
          pluralLabel={TECHNICAL_DATA_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.technicalDataMappingsCount}
          iconName={TECHNICAL_DATA_MAPPING_ICON}
          pinIconName={DATA_FIELD_ICON}
          childFieldType={TECHNICAL_DATA_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
    </FeatureCheckerOr>
  </ConfirmRemovalDetail>

);

const getDetailFields = (formik: FormikProps<DataField>,
                         multipleDataSetTypesAllowedFeature: boolean,
                         dataSetTypeEnumOptions: EnumOption[],
                         canSeeProfilingResults: boolean) => {
  const dataSet = formik.values?.dataSet as DataSetReference | undefined;
  const isPhysicalDataSet = dataSet?.dataSetType === DataSetType.PHYSICAL;
  const isVersionBrowser = formik.status.isVersionBrowser;
  return (
    <>
      <DetailHeader iconName={DATA_FIELD_ICON}>
        <NameFieldWithFormik
          label={'Data Field Name'}
          readOnly={isPhysicalDataSet}
        />
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions, canSeeProfilingResults)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={DATA_FIELD_TYPE}/>
      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik
          name={'dataSet'}
          label={DATA_SET_LABEL}
          setValue={(newValue) => {
            formik.setFieldValue('dataSet', newValue);
            formik.setFieldValue('dataStructure', null);
          }}
          objectType={DATA_SET_TYPE}
          icon={DATA_SET_ICON}
          additionalFilters={getDataSetFilters(formik)}
          readOnly={formik.status.isUpdateDetail}
        />
        {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
          name={'dataSet.dataSetType'}
          label={DATA_SET_TYPE_LABEL}
          options={dataSetTypeEnumOptions}
          readOnly={true}
        />}
      </DetailInlineGroup>
      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik
          name={'dataStructure'}
          setValue={getDataStructureValueHandler(formik)}
          label={DATA_STRUCTURE_LABEL}
          icon={DATA_STRUCTURE_ICON}
          objectType={DATA_STRUCTURE_TYPE}
          additionalFilters={getDataStructureFilters(formik)}
          readOnly={formik.status.isUpdateDetail}
        />
        <EnumerationFieldWithFormik
          name={'dataStructure.dataStructureType'}
          label={DATA_STRUCTURE_TYPE_FIELD_LABEL}
          options={dataStructureTypeOptions}
          readOnly={true}
        />
      </DetailInlineGroup>
      {/* todo DS type */}
      {/* todo uncomment when comments are implemented #26083 */}
      {/*<MultiLineFieldWithFormik*/}
      {/*  name={DATA_FIELD_COMMENT_FIELD}*/}
      {/*  label={DATA_FIELD_COMMENT_FIELD_LABEL}*/}
      {/*  readOnly={dataSet?.dataSetType === DataSetType.PHYSICAL}*/}
      {/*/>*/}
      <SingleLineFieldWithFormik
        name={'dataType'}
        label={'Data Type'}
        readOnly={formik.status.isUpdateDetail && isPhysicalDataSet}
      />
      <EnumerationFieldWithFormik
        name={`notNull`}
        label={'Not Null'}
        options={DataFieldConstraintOptions}
        readOnly={formik.status.isUpdateDetail && isPhysicalDataSet}
      />
      <RelatedBusinessTermsWithSuggestionsForDataField
        hideSuggestions={isVersionBrowser || !formik.status.isUpdateDetail}
      />
      <DataSyncResultField/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.DATA_FIELD}/>
      <ChildrenCountsContainer>
        {childrenCounts => <>
          <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
            <ChildrenCountFieldWithFormik
              singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
              pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
              value={childrenCounts.businessModelMappingsCount}
              iconName={BUSINESS_MODEL_MAPPING_ICON}
              pinIconName={DATA_FIELD_ICON}
              childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.TECHNICAL_DATA_MAPPINGS}>
            <ChildrenCountFieldWithFormik
              singularLabel={TECHNICAL_DATA_MAPPING_LABEL}
              pluralLabel={TECHNICAL_DATA_MAPPING_PLURAL_LABEL}
              value={childrenCounts.technicalDataMappingsCount}
              iconName={TECHNICAL_DATA_MAPPING_ICON}
              pinIconName={DATA_FIELD_ICON}
              childFieldType={TECHNICAL_DATA_MAPPING_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
        </>}
      </ChildrenCountsContainer>
      <DataLineageDiagramButton
        objectType={DATA_FIELD_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
      <BusinessModelMappingDiagramButton
        objectType={DATA_FIELD_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
      <TechnicalDataMappingDiagramButton
        objectType={DATA_FIELD_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
      {canSeeProfilingResults && <ColumnProfilingResultButton dataField={formik.values}/>}
    </>
  )
};

export default DataFieldDetail;
