import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

const CLEAN_STORE_ACTION = 'CLEAN_STORE_ACTION';

const reducer = (state: any, action: PayloadAction<boolean>) => {
  if (action.type === CLEAN_STORE_ACTION) {
    // Preserve 'public' appSettings state to keep environment info
    // Preserve userMessages because Logout Notifications are kept in store and must be shown after logout+clean
    // Preserve userSettings based on passed flag
    const preserveUserSettings = action.payload;
    state = {
      appSettings: state.appSettings,
      userMessages: state.userMessages,
      userSettings: preserveUserSettings ? state.userSettings : undefined,
    }
  }
  return rootReducer(state, action);
};

const rootStore = configureStore({
  reducer: reducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    rootStore.replaceReducer(newRootReducer);
  })
}

export const cleanStore = (preserveUserSettings?: boolean) => {
  rootStore.dispatch({ type: CLEAN_STORE_ACTION, payload: preserveUserSettings });
};

export default rootStore;
