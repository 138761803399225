import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BOTTOM_BANNER_DISMISSED_KEY,
  DETAIL_WIDTH_SETTING_KEY,
  GLOBAL_PIN_FILTER_KEY,
  LIST_SETTINGS_KEY_PREFIX,
  UserSettings,
  UserSettingsState
} from '../types/types';
import { ListSettings } from '../../list/types/types';
import { User } from '../../../businessGlossary/user/types/userTypes';
import { getFormattedTimezone } from '../types/timezones';
import { AccurityPinFilter } from '../../types/accurityTypes';

const initialUserSettingsState: UserSettingsState = {
  id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  timezone: undefined,
  roles: [],
  globalPinFilters: [],
  listSettings: {},
  detailWidth: undefined,
  // TODO move to app settings
  maxCustomPropertyValueElements: undefined,
  features: [],
};

const setUserSettings = (state: UserSettingsState, action: PayloadAction<UserSettings>) => {
  state.id = action.payload.user.id;
  state.email = action.payload.user.email;
  state.firstName = action.payload.user.firstName;
  state.lastName = action.payload.user.lastName;
  state.timezone = getFormattedTimezone(action.payload.user.timezone);
  state.roles = action.payload.user.roles;

  const globalPinFilterPreference = action.payload.preferences
    .find(preference => preference.keyValue === GLOBAL_PIN_FILTER_KEY);
  state.globalPinFilters = globalPinFilterPreference ? JSON.parse(globalPinFilterPreference.value) : [];

  const listSettings = action.payload.preferences
    .filter(preference => preference.keyValue.startsWith(LIST_SETTINGS_KEY_PREFIX))
    .map(preference => JSON.parse(preference.value));

  listSettings.forEach((listSettings: ListSettings) => {
    state.listSettings[listSettings.type] = listSettings;
  });

  const detailWidthSetting = action.payload.preferences
    .find(preference => preference.keyValue === DETAIL_WIDTH_SETTING_KEY);
  state.detailWidth = detailWidthSetting ? JSON.parse(detailWidthSetting.value) : undefined;

  const bottomBannerDismissedSetting = action.payload.preferences
    .find(preference => preference.keyValue === BOTTOM_BANNER_DISMISSED_KEY);
  state.bottomBannerDismissedAt = bottomBannerDismissedSetting ? JSON.parse(bottomBannerDismissedSetting.value) : undefined;

  state.maxCustomPropertyValueElements =
    action.payload.maxCustomPropertyValueElements === -1 ? undefined : action.payload.maxCustomPropertyValueElements;
  state.features = action.payload.features;
};

const clearUserSettings = (state: UserSettingsState, action: Action) => {
  state = initialUserSettingsState;
};

const setGlobalPinFilters = (state: UserSettingsState, action: PayloadAction<AccurityPinFilter[]>) => {
  state.globalPinFilters = action.payload;
};

const updateUserProfile = (state: UserSettingsState, action: PayloadAction<User>) => {
  if (state.id !== action.payload.id) {
    return; // Not the current User
  }
  state.firstName = action.payload.firstName;
  state.lastName = action.payload.lastName;
  state.timezone = getFormattedTimezone(action.payload.timezone);
};

const setDetailWidth = (state: UserSettingsState, action: PayloadAction<number>) => {
  state.detailWidth = action.payload;
};

const setBottomBannerDismissed = (state: UserSettingsState, action: PayloadAction<number>) => {
  state.bottomBannerDismissedAt = action.payload;
}

const setListSettings = (state: UserSettingsState, action: PayloadAction<ListSettings>) => {
  state.listSettings[action.payload.type] = action.payload;
};

const clearAllUISettings = (state: UserSettingsState, action: PayloadAction<void>) => {
  const { listSettings, detailWidth, globalPinFilters } = initialUserSettingsState;
  state.listSettings = listSettings;
  state.detailWidth = detailWidth;
  state.globalPinFilters = globalPinFilters;
};

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: initialUserSettingsState,
  reducers: {
    setUserSettingsInStore: setUserSettings,
    clearUserSettingsInStore: clearUserSettings,
    setGlobalPinFiltersInStore: setGlobalPinFilters,
    updateUserProfileInStore: updateUserProfile,
    setListSettingsInStore: setListSettings,
    setDetailWidthInStore: setDetailWidth,
    setBottomBannerDismissedInStore: setBottomBannerDismissed,
    clearAllUISettingsInStore: clearAllUISettings,
  },
});

export const {
  setUserSettingsInStore,
  clearUserSettingsInStore,
  setGlobalPinFiltersInStore,
  updateUserProfileInStore,
  setListSettingsInStore,
  setDetailWidthInStore,
  setBottomBannerDismissedInStore,
  clearAllUISettingsInStore
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
