import React from 'react';
import { RichTextEditor, RichTextEditorValue } from 'ts-components';
import { useField, useFormikContext } from 'formik';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

type DescriptionFieldWithFormikProps = {
  label?: string;
  inherited?: boolean;
}

const DescriptionFieldWithFormik = ({ label, inherited }: DescriptionFieldWithFormikProps) => {
  const [field, , helper] = useField<RichTextEditorValue>('description');
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring('description');

  return (
    <RichTextEditor
      label={label ? label : 'Description'}
      value={field.value}
      setValue={helper.setValue}
      disabled={formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default DescriptionFieldWithFormik;
