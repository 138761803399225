import React from 'react';
import { DataCatalogSyncNotificationCenterNotification } from './types';
import { NotificationCenterCard, NotificationCenterCardProps, ProgressIndicator } from 'ts-components';
import { useDispatch } from 'react-redux';
import { dismissNotification } from './notificationCenterSlice';
import { useTranslation } from 'react-i18next';
import { SyncRunStatus } from '../dataCatalogSync/types';

export interface DataCatalogSyncNotificationCardProps {
  notification: DataCatalogSyncNotificationCenterNotification;
}

const DataCatalogSyncNotificationCard = ({ notification }: DataCatalogSyncNotificationCardProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let title;
    let titleIcon;
    let titleIconColor: NotificationCenterCardProps['titleIconColor'];
    let content;
    if (SyncRunStatus.FAILED === notification.runStatus) {
      title = t('dataSyncNotification.dataSyncFailed');
      titleIcon = 'mui:Cancel';
      titleIconColor = 'error';
    } else if (SyncRunStatus.FINISHED === notification.runStatus) {
      title = t('dataSyncNotification.dataSyncFinished');
      titleIcon = 'mui:Check';
      titleIconColor = 'success';
    } else { // SyncRunStatus.RUNNING
      title = t('dataSyncNotification.dataSyncRunning');
      titleIcon = 'mui:InfoOutlined';
      content = <span style={{ width: '100%' }}><ProgressIndicator/></span>;
    }

    const date = notification.date;
    const unread = !notification.wasRead;
    const onDismiss = notification.cannotDismiss ? undefined : () => dispatch(dismissNotification(notification.id));

    return (
      <NotificationCenterCard
        title={title}
        titleIcon={titleIcon}
        titleIconColor={titleIconColor}
        date={date}
        unread={unread}
        onDismiss={onDismiss}
      >
        {content}
      </NotificationCenterCard>
    );
  }
;

export default DataCatalogSyncNotificationCard;
