import React from 'react';

const AccurityListOverlay = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="accurityListOverlay">
      {children}
    </div>
  );
};

export default AccurityListOverlay;
