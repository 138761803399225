import { ColumnProfilingCommand, ColumnProfilingFinishedCommand, ColumnProfilingStartedCommand } from '../../types';
import rootStore from '../../../redux/rootStore';
import { dismissNotification, pushNotification } from '../../../notificationCenter/notificationCenterSlice';
import { ColumnProfilingNotificationCenterNotification, NotificationCenterNotificationType } from '../../../notificationCenter/types';
import { ProfileStatus } from '../../../columnProfiling/types/types';
import { markDataFieldAsProfiledInStore, setColumnProfilingResultInStore } from '../../../columnProfiling/redux/columnProfilingSlice';
import {
  getDataFieldProfilingFailedMessage,
  getDataFieldProfilingFinishedMessage,
  getDataFieldProfilingInProgressMessage,
  getDataFieldProfilingUpdatedByAnotherUserMessage
} from '../../../userMessages/commonMessages';
import { doLoadProfilingResult } from '../../../columnProfiling/rest/doLoadProfilingResult';
import { showSnackbarMessage } from '../../../userMessages/actions';

export const handleColumnProfilingStartedCommand = (command: ColumnProfilingStartedCommand) => {
  // we want to show notification only to user which triggered profiling
  const userId = Number(rootStore.getState().userSettings.id);
  if (userId === command.initiatedBy) {
    const msg = getDataFieldProfilingInProgressMessage(command.objectName);
    pushNotificationToStore(command, msg, ProfileStatus.RUNNING);
  }
};

export const handleColumnProfilingFinishedCommand = (command: ColumnProfilingFinishedCommand) => {
  // we want to show notification only to user which triggered profiling
  const userId = Number(rootStore.getState().userSettings.id);
  if (userId === command.initiatedBy) {

    const msg = command.isSuccessful
      ? getDataFieldProfilingFinishedMessage(command.objectName)
      : getDataFieldProfilingFailedMessage(command.objectName);
    const profileStatus = command.isSuccessful ? ProfileStatus.FINISHED : ProfileStatus.FAILED;

    pushNotificationToStore(command, msg, profileStatus);
    rootStore.dispatch(markDataFieldAsProfiledInStore({ dataFieldId: command.objectId.toString(), isSuccessful: command.isSuccessful }));
  }

  // if profiling was successful, we want to reload result no matter who triggered profiling
  if (command.isSuccessful && rootStore.getState().columnProfiling.result?.dataFieldId === command.objectId) {
    doLoadProfilingResult(command.objectId.toString())
      .then(response => {
        if (response) {
          rootStore.dispatch(setColumnProfilingResultInStore(response));

          // if the user did not trigger the profiling, notify him about change via snackbar message
          if (userId !== command.initiatedBy) {
            showSnackbarMessage(getDataFieldProfilingUpdatedByAnotherUserMessage());
          }
        }
      });
  }
};

export const dismissColumnProfilingNotification = (objectId: string | number) => {
  const notificationId = getNotificationId(objectId);
  rootStore.dispatch(dismissNotification(notificationId));
};

const pushNotificationToStore = (command: ColumnProfilingCommand, content: {header: string, body: string}, status: ProfileStatus) => {
  const notificationId = getNotificationId(command.objectId);
  const notification: ColumnProfilingNotificationCenterNotification = {
    type: NotificationCenterNotificationType.COLUMN_PROFILING,
    id: notificationId,
    date: Date.now(),
    wasRead: false,
    body: content.body,
    title: content.header,
    profileStatus: status,
    dataFieldId: command.objectId,
    cannotDismiss: status === ProfileStatus.RUNNING
  };

  // dismiss old notification
  rootStore.dispatch(dismissNotification(notificationId));
  rootStore.dispatch(pushNotification(notification));
};

const getNotificationId = (objectId: string | number) => {
  return `column-profiling-data-field-${objectId}`;
};
