import { useDispatch } from 'react-redux';
import { Attribute, ATTRIBUTE_TYPE, CONFIRM_ENTITY_OR_ATT_DEF_CHANGE_SETTINGS_DETAIL } from '../types/attributeTypes';
import { doBackgroundFetchByObjectTypeAndId } from '../../../common/redux/commonActions';
import { isEqual } from 'lodash';
import { addCoreEntityToStore, setDetailOpenedAt, setIsDetailLoading } from '../../../common/redux/commonReducers';
import { doFetch } from '../../../common/rest/FetchService';
import { getResetEndpoint } from '../../../common/rest/endpoints';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { FormikProps } from 'formik';
import { DetailBag } from '../../../common/detail/types/types';
import { CustomSaveSteps } from '../../../common/detail/actions/detailSave';
import rootStore from '../../../common/redux/rootStore';
import { showSnackbarMessage } from '../../../common/userMessages/actions';
import { useCallback } from 'react';

export const useAttributeDetailLogic = (id?: string) => {
  const navigationController = useAccurityNavigation();
  const dispatch = useDispatch();

  const detailBagSaveAction = (
    formik: FormikProps<Attribute>,
    detailBag: DetailBag<Attribute>
  ) => (fieldOverwrites?: Partial<Attribute>) => {
    const isConfirmSaveDetail =
      formik.status.settingsDetail === CONFIRM_ENTITY_OR_ATT_DEF_CHANGE_SETTINGS_DETAIL;

    const hasNoInheritedChildren = formik.values.childrenCounts.attributesCount === 0;

    const didEntityChange =
      formik.initialValues?.entity?.id !== undefined &&
      formik.initialValues?.entity?.id !== formik.values?.entity?.id;

    const didAttDefChange =
      formik.initialValues?.attributeDefinition?.id !== undefined &&
      formik.initialValues?.attributeDefinition?.id !== formik.values?.attributeDefinition?.id;

    const didEntityOrAttDefChange = didEntityChange || didAttDefChange;
    const didNeitherEntityOrAddDefChange = !didEntityChange && !didAttDefChange;

    if (isConfirmSaveDetail || hasNoInheritedChildren || didNeitherEntityOrAddDefChange) {
      detailBag.saveAction(fieldOverwrites);
    } else if (didEntityOrAttDefChange) {
      detailBag.setSettingsDetail(CONFIRM_ENTITY_OR_ATT_DEF_CHANGE_SETTINGS_DETAIL);
    }
  };

  const addAttributeToStore = (attribute: Attribute) => {
    const fetchedAt = Date.now();

    rootStore.dispatch(addCoreEntityToStore({
      coreEntity: attribute,
      fetchedAt: fetchedAt
    }));

    if (attribute.entity) {
      rootStore.dispatch(addCoreEntityToStore({
        coreEntity: attribute.entity,
        fetchedAt: fetchedAt
      }));
    }

    if (attribute.attributeDefinition) {
      rootStore.dispatch(addCoreEntityToStore({
        coreEntity: attribute.attributeDefinition,
        fetchedAt: fetchedAt
      }));
    }

  };

  const detailBagResetAction = () => {
    if (id) {
      const resetEndpoint = getResetEndpoint(ATTRIBUTE_TYPE, id);
      rootStore.dispatch(setIsDetailLoading(true));
      doFetch(resetEndpoint, 'PUT')
        .then((resetAttribute: Attribute) => {
          addAttributeToStore(resetAttribute);
          navigationController.openDetailWithObject(ATTRIBUTE_TYPE, id, true);
          showSnackbarMessage('The Inherited Child Attribute has been restored to default.')
        })
        .finally(() => {
          rootStore.dispatch(setIsDetailLoading(false));
        });
    }
  };

  const customSaveSteps: CustomSaveSteps<Attribute> = {
    addSavedEntityToStore: addAttributeToStore,
  };

  const customHandleExternalUpdate = useCallback((defaultHandleExternalUpdate: () => void, attributeInDetail: Attribute) => {
    if (id) {
      doBackgroundFetchByObjectTypeAndId(ATTRIBUTE_TYPE, id)
        .then((attributeFromBackend: Attribute) => {

          const updateWasOnlyToEntityOrAD = isEqual(
            {
              ...attributeInDetail,
              name: undefined,
              entity: undefined,
              attributeDefinition: undefined,
              changedBy: undefined,
              changedDate: undefined,
              version: undefined,
            },
            {
              ...attributeFromBackend,
              name: undefined,
              entity: undefined,
              attributeDefinition: undefined,
              changedBy: undefined,
              changedDate: undefined,
              version: undefined,
            }
          );

          if (updateWasOnlyToEntityOrAD) {
            dispatch(setDetailOpenedAt({ objectType: ATTRIBUTE_TYPE, openedAt: Date.now() }));
          } else {
            defaultHandleExternalUpdate();
          }
        })
    }
  }, [dispatch, id]);

  return {
    detailBagSaveAction,
    detailBagResetAction,
    customSaveSteps,
    customHandleExternalUpdate,
  }
};
