import React from 'react';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { Process, PROCESS_ICON, PROCESS_TYPE } from '../types/processTypes';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { PROCESS_STEP_ICON, PROCESS_STEP_LABEL, PROCESS_STEP_PLURAL_LABEL, PROCESS_STEP_TYPE } from '../step/types/processStepTypes';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../mapping/types/processMappingTypes';
import { DetailHeader, StaticField } from 'ts-components';
import GenerateProcessDiagramButton from '../../processDiagram/detail/GenerateProcessDiagramButton';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramBasedOnType } from '../../processDiagram/types/processDiagramTypes';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { useHasFeature } from '../../../common/userSettings/hooks/features';

interface ProcessDetailProps {
  id?: string,
}

const ProcessDetail = ({ id }: ProcessDetailProps) => {
  const hasFeature = useHasFeature();

  const hasProcessDiagramCreatePermission = usePermissionsForObjectType(PROCESS_DIAGRAM_TYPE).hasCreatePermission;
  const hasProcessDiagramFeature = hasFeature(Feature.PROCESS_DIAGRAMS);

  const generateProcessDiagramButton = hasProcessDiagramCreatePermission && hasProcessDiagramFeature &&
    <GenerateProcessDiagramButton
      key="processDiagramButton"
      basedOnType={ProcessDiagramBasedOnType.PROCESSES}
      objectType={PROCESS_TYPE}
      id={id}
    />;

  return (
    <AccurityDetailContainer<Process>
      objectType={PROCESS_TYPE}
      id={id}>
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar
                objectType={PROCESS_TYPE}
                detailBag={detailBag}
                customMenuItems={generateProcessDiagramButton}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<Process>, detailBag: DetailBag<Process>) => (
  <ConfirmRemovalDetail
    iconName={PROCESS_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={IT_IS_CURRENTLY_LABEL}
      inverted={true}
    />
    <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
      <ChildrenCountFieldWithFormik
        singularLabel={PROCESS_MAPPING_LABEL}
        pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
        value={formik.values.childrenCounts.processMappingsCount}
        iconName={PROCESS_MAPPING_ICON}
        pinIconName={PROCESS_ICON}
        childFieldType={PROCESS_MAPPING_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
    <StaticField
      name={'removalDescription1'}
      value={'It is also:'}
      inverted={true}
    />
    <FeatureChecker featureId={Feature.PROCESS_STEPS}>
      <ChildrenCountFieldWithFormik
        singularLabel={PROCESS_STEP_LABEL}
        pluralLabel={PROCESS_STEP_PLURAL_LABEL}
        value={formik.values.childrenCounts.processStepsCount}
        iconName={PROCESS_STEP_ICON}
        pinIconName={PROCESS_ICON}
        childFieldType={PROCESS_STEP_TYPE}
        childrenCountWording={ChildrenCountWording.HAS}
        inverted={true}
      />
    </FeatureChecker>
    <StaticField
      name={'removalDescription2'}
      value={'These Process Steps will be removed together with the Process.'}
      inverted={true}
    />
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<Process>) => {
  return (
    <FeatureChecker featureId={Feature.PROCESSES}>
      <DetailHeader iconName={PROCESS_ICON}>
        <NameFieldWithFormik label={'Process Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={PROCESS_TYPE}/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.PROCESS}/>
      <FeatureChecker featureId={Feature.PROCESS_STEPS}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_STEP_LABEL}
          pluralLabel={PROCESS_STEP_PLURAL_LABEL}
          value={formik.values.childrenCounts.processStepsCount}
          iconName={PROCESS_STEP_ICON}
          pinIconName={PROCESS_ICON}
          childFieldType={PROCESS_STEP_TYPE}
          childrenCountWording={ChildrenCountWording.HAS}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={PROCESS_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.BASE_FOR}
        />
      </FeatureChecker>
    </FeatureChecker>
  );
};

export default ProcessDetail;
