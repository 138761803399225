import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption, Tag } from 'ts-components';

export const COMPONENT_REFERENCE_OPTION_LABEL = 'Component (Reference)';
export const COMPONENT_VALUE_OPTION_LABEL = 'Component (Value)';
export const COMPOSITE_OPTION_LABEL = 'Composite';
export const REFERENCE_OPTION_LABEL = 'Reference';
export const VALUE_OPTION_LABEL = 'Value';

export const ATTRIBUTE_DEFINITION_TYPE = 'attribute-definition';
export const ATTRIBUTE_DEFINITION_LABEL = 'Attribute Definition';
export const ATTRIBUTE_DEFINITION_PLURAL_LABEL = 'Attribute Definitions';
export const ATTRIBUTE_DEFINITION_ICON = 'attributeDefinition';

export const ATTRIBUTE_DEFINITION_TYPE_LABEL = 'Attribute Definition Type';
export const ATTRIBUTE_DEFINITION_DESCRIPTION_LABEL = 'Attribute Definition Description';
export const COMPONENT_ATTRIBUTE_DEFINITION_LABEL = 'Component Attribute Definition';
export const COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL = 'Component Attribute Definitions';
export const COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL_SHORT = 'Components';

export const COMPONENT_OF_LABEL = 'Component of';
export const COMPONENT_OF_DESCRIPTION_LABEL = 'Component of - Description';

export const PARENT_ATTRIBUTE_LABEL = 'Parent Attribute';

export interface AttributeDefinitionState extends CoreEntityState<AttributeDefinition> {
}

export enum AttributeDefinitionType {
  COMPOSITE = 'COMPOSITE',
  COMPONENT_REFERENCE = 'COMPONENT_REFERENCE',
  COMPONENT_VALUE = 'COMPONENT_VALUE',
  REFERENCE = 'REFERENCE',
  VALUE = 'VALUE'
}

// Maintain alphabetical order of enum elements
export const AttributeDefinitionTypeOptions: EnumOption[] = [
  { value: AttributeDefinitionType.COMPONENT_REFERENCE, label: COMPONENT_REFERENCE_OPTION_LABEL },
  { value: AttributeDefinitionType.COMPONENT_VALUE, label: COMPONENT_VALUE_OPTION_LABEL },
  { value: AttributeDefinitionType.COMPOSITE, label: COMPOSITE_OPTION_LABEL },
  { value: AttributeDefinitionType.REFERENCE, label: REFERENCE_OPTION_LABEL },
  { value: AttributeDefinitionType.VALUE, label: VALUE_OPTION_LABEL },
];

// Maintain alphabetical order of enum elements
export const AttributeDefinitionTypeOptionsForCreate: EnumOption[] = [
  { value: AttributeDefinitionType.COMPOSITE, label: COMPOSITE_OPTION_LABEL },
  { value: AttributeDefinitionType.REFERENCE, label: REFERENCE_OPTION_LABEL },
  { value: AttributeDefinitionType.VALUE, label: VALUE_OPTION_LABEL },
];

export const isReference = (attributeDefinitionType?: AttributeDefinitionType) => {
  return attributeDefinitionType !== undefined ?
    [AttributeDefinitionType.REFERENCE,
      AttributeDefinitionType.COMPONENT_REFERENCE].includes(attributeDefinitionType) :
    false;
};

export const isComponent = (attributeDefinitionType?: AttributeDefinitionType) => {
  return attributeDefinitionType !== undefined ?
    [AttributeDefinitionType.COMPONENT_VALUE,
      AttributeDefinitionType.COMPONENT_REFERENCE
    ].includes(attributeDefinitionType) :
    false;
};

export const isValue = (attributeDefinitionType?: AttributeDefinitionType) => {
  return attributeDefinitionType !== undefined ?
    [AttributeDefinitionType.VALUE,
      AttributeDefinitionType.COMPONENT_VALUE].includes(attributeDefinitionType) :
    false;
};

export const isComposite = (attributeDefinitionType?: AttributeDefinitionType) => {
  return attributeDefinitionType !== undefined ?
    [AttributeDefinitionType.COMPOSITE,
      AttributeDefinitionType.COMPONENT_VALUE,
      AttributeDefinitionType.COMPONENT_REFERENCE].includes(attributeDefinitionType) :
    false;
};

export interface AttributeDefinition extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  attributeDefinitionType?: AttributeDefinitionType;
  compositeType?: AccurityReference;
  targetEntity?: AccurityReference;
  valueType?: AccurityReference;
  tags: Tag[];
  basedOnBusinessTerm?: AccurityReference;
  parent?: AccurityReference;
  customPropertyValues: CustomPropertyValues;
}
