import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import rootStore from './common/redux/rootStore';
import AccurityApp from './businessGlossary/AccurityApp';

ReactDOM.render(
  <Provider store={rootStore}>
    <AccurityApp/>
  </Provider>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
