import React from 'react';
import { useFormikContext } from 'formik';
import { DetailBag } from '../../../common/detail/types/types';
import SettingsDetail from '../../../common/detail/commonFields/SettingsDetail';
import { ENTITY_ICON } from '../types/entityTypes';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';
import KeepDiscardButtonBar, { KeepDiscardAction } from '../../attribute/detail/KeepDiscardButtonBar';

interface EntityConfirmParentChangeDetailProps {
  detailBag: DetailBag<AccurityCoreEntity>
}

const CONFIRM_TEXT = 'Changing the parent of this Entity will leave existing inherited Attributes without a parent. ' +
  'You can either keep these inherited Attributes (turning them into non-inherited), or discard them.';

const EntityConfirmParentChangeDetail = ({ detailBag }: EntityConfirmParentChangeDetailProps) => {
  const formik = useFormikContext<AccurityCoreEntity>();

  return (
    <SettingsDetail
      objectName={formik.values.name}
      iconName={ENTITY_ICON}
      confirmDescription={CONFIRM_TEXT}
      bottomBar={
        <KeepDiscardButtonBar
          detailBag={detailBag}
          keepDiscardAction={KeepDiscardAction.SAVE}
        />
      }
    />
  );

};

export default EntityConfirmParentChangeDetail;
