import { Column } from 'ts-components';
import { commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useBusinessModelDiagramColumns = () => {

  const isOracle = useIsOracleDb();

  const businessModelDiagramColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle }),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(businessModelDiagramColumns, GlossaryCustomPropertyObjectType.BUSINESS_MODEL_DIAGRAM);

}
