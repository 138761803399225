import { AccurityCoreEntity } from '../../types/accurityTypes';
import { BulkNotificationsUpdate, Tag } from 'ts-components';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { getBulkDeleteEndpoint, getBulkOperationLogEndpoint, getBulkUpdateNotificationsEndpoint, getBulkUpdateTagsEndpoint } from '../../rest/endpoints';

export const requestBulkRemove = (objects: AccurityCoreEntity[], objectType: string) => {
  const request: RemoveRequest = {
    idNamePairs: getIdNamePairs(objects)
  };
  const url = getBulkDeleteEndpoint(objectType);

  return doFetch(url, 'POST', JSON.stringify(request))
    .catch(showErrorMessageFromResponse);
};

export const requestUpdateTags = (objects: AccurityCoreEntity[], existingTags: Tag[], objectType: string) => {
  const request: BulkTagsUpdateRequest = {
    idNamePairs: getIdNamePairs(objects),
    tags: existingTags
  };
  const url = getBulkUpdateTagsEndpoint(objectType);

  return doFetch(url, 'POST', JSON.stringify(request))
    .catch(showErrorMessageFromResponse);
};

export const requestBulkNotificationUpdate = (objects: AccurityCoreEntity[], notificationsUpdate: BulkNotificationsUpdate, objectType: string) => {
  const request: BulkNotificationsUpdateRequest = {
    idNamePairs: getIdNamePairs(objects),
    ...notificationsUpdate,
  };
  const url = getBulkUpdateNotificationsEndpoint(objectType);

  return doFetch(url, 'POST', JSON.stringify(request))
    .catch(showErrorMessageFromResponse);
};

const getIdNamePairs = (objects: AccurityCoreEntity[]) => objects.reduce((pairs, current) => {
  return { ...pairs, [current.id]: current.name };
}, {});

type BulkOperationsRequest = {
  // id: name
  idNamePairs: { [id: string]: string };
}

type RemoveRequest = BulkOperationsRequest;

type BulkNotificationsUpdateRequest = {

} & BulkOperationsRequest & BulkNotificationsUpdate;

type BulkTagsUpdateRequest = {
  tags: any[];
} & BulkOperationsRequest;

export const requestDownloadBulkOperationLog = (objectType: string) => {
  const logUrl = getBulkOperationLogEndpoint(objectType);
  return doFetch(logUrl, 'GET', undefined, { downloadResponse: true });
};
