import reduxStore from '../redux/rootStore';
import { showSnackbar, showToast } from './redux/userMessagesSlice';
import { AccurityFetchError } from '../rest/types';
import { AccurityCoreEntity } from '../types/accurityTypes';
import { getFinishedReloadMessage, getFinishedRemovalMessage, getFinishedSavingMessage } from './commonMessages';

export const showErrorMessageFromResponse = (error: AccurityFetchError) => {

  if (Array.isArray(error.errorBody)) {
    error.errorBody.forEach(responseElement =>
      reduxStore.dispatch(showToast({ title: responseElement.message.header, message: responseElement.message.body }))
    );
  }

  return Promise.reject(error); // FIXME think about this somehow
};

export const showSuccessSavingMessage = <T extends AccurityCoreEntity>(coreEntity: T): Promise<T> => {
  reduxStore.dispatch(showSnackbar(getFinishedSavingMessage(coreEntity)));
  return Promise.resolve(coreEntity);
};

export const showSuccessRemovalMessage = <T extends AccurityCoreEntity>(coreEntity: T) => {
  reduxStore.dispatch(showSnackbar(getFinishedRemovalMessage(coreEntity)));
};

export const showSuccessReloadMessage = <T extends AccurityCoreEntity>(coreEntity: T) => {
  reduxStore.dispatch(showSnackbar(getFinishedReloadMessage(coreEntity)));
};

export const showSnackbarMessage = (message: string) => {
  reduxStore.dispatch(showSnackbar(message));
};
