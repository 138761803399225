import { ColumnFilterData } from '../list/types/types';
import { AccurityFilterType, RichTextEditorValue, UserInfo } from 'ts-components';

export interface AccurityCoreEntityIdentifier {
  objectType: string;
  id: string;
}

export interface AccurityReference extends AccurityCoreEntityIdentifier {
  name: string;
  description?: RichTextEditorValue;
}

export interface AccurityReferenceWithChips extends AccurityReference {
  chips: AccurityReference[];
}

export interface AccurityCoreEntity extends AccurityCoreEntityIdentifier {
  name: string;
  description?: RichTextEditorValue;
  createdDate: number;
  changedDate: number;
  createdBy: UserInfo;
  changedBy: UserInfo;
  version: number;
  activeNotifications: ActiveNotifications;
}

export interface AccuritySearch {
  startFrom: number;
  maxResults: number;
  filters: AccurityFilter[];
  sort: AccuritySort;
}

export interface AccurityFilter {
  type: AccurityFilterType;
  property: string;
  value: string;
  timezone?: string;
  relationType?: string;
}

export interface AccurityPinFilter extends AccurityFilter {
  icon: string;
  label?: string;
  disabled?: boolean;
}

export interface AccuritySort {
  type: AccuritySortType;
  property: string;
}

export enum AccuritySortType {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export interface AccurityListLocationState {
  openedAt: number;
  pinFilters?: AccurityPinFilter[];
  referenceFilters?: AccurityFilter[];
  columnFilters?: ColumnFilterData[];
  customEndpoint?: string;
}

export interface AccurityDetailLocationState {
  openedAt: number;
  prePopulateData?: Record<string, any>;
}

export interface AccurityDiagramLocationState {
  openedAt: number;
}

export interface AccurityLocationState {
  list: AccurityListLocationState;
  detail: AccurityDetailLocationState;
  diagram: AccurityDiagramLocationState;
}

export type EmailNotificationState = {
  actionType: EmailNotificationActionType;
  referencedObjectType: string;
  referencedObjectName: string;
  referencedObjectId: string;
}

export enum EmailNotificationActionType {
  OBJECT_UPDATE = 'OBJECT_UPDATE',
  COMMENT_UPDATE = 'COMMENT_UPDATE'
}

export type ActiveNotifications = {
  [notificationType in keyof typeof EmailNotificationActionType]: boolean;
};

export type ObjectSuggestionSearch = {
  excludedSuggestionsNames: string[];
  objectName: string;
  maxResults: number;
}
