import { FormikProps, useFormik } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { usePermissionsForObject } from '../../userSettings/hooks/permissions';
import { useSelectCurrentCustomPropertiesFromStore } from '../../customProperties/customPropertyHooks';

export const useAccurityFormik = <T extends AccurityCoreEntity>(coreEntity: T,
                                                                onSubmit: (entity: T, setFieldError: (field: string, errorMessage: string) => void) => void,
                                                                initiallyDisabled?: boolean,
): FormikProps<T> => {
  const permissions = usePermissionsForObject(coreEntity?.objectType, coreEntity?.id);
  const customProperties = useSelectCurrentCustomPropertiesFromStore();
  return useFormik<T>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: coreEntity,
    initialStatus: {
      permissions: permissions,
      customProperties: customProperties,
      isUpdateDetail: coreEntity?.id !== null && coreEntity?.id !== undefined,
      isEntityInDetailDeleted: false,
      isEntityInDetailOutdated: false,
      activeEmbeddedDetail: undefined,
      settingsDetail: undefined,
      isDisabled: initiallyDisabled,
      isVersionBrowser: false
    },
    onSubmit: (values, formikHelpers) => {
      onSubmit(values, formikHelpers.setFieldError)
    },
  });
};
