import { CollapsingGroup, DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE } from '../../../dataStructure/types/dataStructureTypes';
import {
  TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_FIELDS_FIELD,
  TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD,
  TECHNICAL_DATA_MAPPING_BASE_JOIN_LABEL,
  TECHNICAL_DATA_MAPPING_BASE_JOIN_LEFT_DATA_STRUCTURE_FIELD,
  TECHNICAL_DATA_MAPPING_BASE_JOIN_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_BASE_JOIN_RIGHT_DATA_STRUCTURE_FIELD,
  TECHNICAL_DATA_MAPPING_JOIN_ICON,
  TechnicalDataMapping
} from '../../types/technicalDataMappingTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps } from 'formik';
import { createReferenceFieldFilter } from '../../../../common/referenceField/utils/filters';
import { clearBaseDataFieldJoinsOnDataStructureRemove, getTechnicalDataMappingDataStructureDataFieldJoinCountLabel } from '../technicalDataMappingDetailUtils';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import TechnicalDataMappingBaseDataFieldsJoin from './TechnicalDataMappingBaseDataFieldsJoin';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface DataStructureDataFieldJoinProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<TechnicalDataMapping>;
}

const TechnicalDataMappingBaseDataStructureDataFieldJoin = ({ additionalFilters = [], formik }: DataStructureDataFieldJoinProps) => {

  const dataStructuresFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.baseDataSet?.id),
    ...additionalFilters,
  ];

  const leftDataStructureId = formik.values.technicalDataMappingBaseJoinDataStructureDataField?.leftDataStructure?.id;
  const rightDataStructureId = formik.values.technicalDataMappingBaseJoinDataStructureDataField?.rightDataStructure?.id;
  const joins = formik.getFieldProps('technicalDataMappingBaseJoinDataStructureDataField.technicalDataMappingBaseJoinDataFields').value;
  const isStructuresSelected = leftDataStructureId && rightDataStructureId;
  const isVersionBrowser = formik.status.isVersionBrowser;

  const dataStructureSelectionColoring = useVersionBrowserColoring(TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD);

  return (
    <>
      <CollapsingGroup
        name={'joins'}
        title={
          getTechnicalDataMappingDataStructureDataFieldJoinCountLabel(
            joins,
            TECHNICAL_DATA_MAPPING_BASE_JOIN_LABEL,
            TECHNICAL_DATA_MAPPING_BASE_JOIN_PLURAL_LABEL
          )
        }
        icon={TECHNICAL_DATA_MAPPING_JOIN_ICON}
        expandedByDefault={false}
        expandable={true}
      >
        <DetailInlineGroup
          indentRight={!isVersionBrowser}
          coloring={dataStructureSelectionColoring}
          className={'technicalDataMapping__joinInlineGroup'}
        >
          <ReferenceFieldWithFormik
            name={TECHNICAL_DATA_MAPPING_BASE_JOIN_LEFT_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            objectType={DATA_STRUCTURE_TYPE}
            icon={DATA_STRUCTURE_ICON}
            additionalFilters={dataStructuresFilter}
            setValue={(leftDataStructure) => {
              clearBaseDataFieldJoinsOnDataStructureRemove(formik, TECHNICAL_DATA_MAPPING_BASE_JOIN_LEFT_DATA_STRUCTURE_FIELD, leftDataStructure, rightDataStructureId);
            }}
            disableColoring={true}
          />
          <ReferenceFieldWithFormik
            name={TECHNICAL_DATA_MAPPING_BASE_JOIN_RIGHT_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            objectType={DATA_STRUCTURE_TYPE}
            icon={DATA_STRUCTURE_ICON}
            additionalFilters={dataStructuresFilter}
            setValue={(rightDataStructure) => {
              clearBaseDataFieldJoinsOnDataStructureRemove(formik, TECHNICAL_DATA_MAPPING_BASE_JOIN_RIGHT_DATA_STRUCTURE_FIELD, rightDataStructure, leftDataStructureId);
            }}
            disableColoring={true}
          />
        </DetailInlineGroup>
        {isStructuresSelected &&
        <CollectionFieldWithFormik
          name={TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_FIELDS_FIELD}
          insideCollapsingGroup={false}
          rowValidation={(value) => value && value.leftDataField && value.leftDataField.id && value.rightDataField && value.rightDataField.id}
          renderRow={(elementName, index) =>
            <TechnicalDataMappingBaseDataFieldsJoin
              elementName={elementName}
              index={index}
              additionalFilters={additionalFilters}
              formik={formik}
            />
          }
        />}
      </CollapsingGroup>
    </>
  )
};

export default TechnicalDataMappingBaseDataStructureDataFieldJoin;
