import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Tag } from 'ts-components';
import AccurityTagField from '../../tagField/components/AccurityTagField';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';
import { useAccurityNavigation } from '../../navigation/hooks';
import Analytics from '../../analytics';

interface TagsFieldWithFormikProps {
  name?: string;
  label?: string;
  objectType: string;
  inherited?: boolean
}

const TagsFieldWithFormik = ({ name = 'tags', label = 'Tags', objectType, inherited }: TagsFieldWithFormikProps) => {
  const [field, meta, helper] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(name, true);
  const { openListForTag } = useAccurityNavigation();

  const onTagClickHandler = (tag: Tag) => {
    if (tag && tag.id) {
      openListForTag(tag.name);
      Analytics.event('Detail: ' + objectType, 'Click on tag', tag.name);
    }
  };

  const isUsedWithinVersionBrowser = !!coloring;

  return (
    <AccurityTagField
      readOnly={formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      errorMessage={meta.error}
      setValue={helper.setValue}
      value={field.value}
      name={name}
      label={label}
      onTagClick={isUsedWithinVersionBrowser ? undefined : onTagClickHandler} // if no handler is passed, the readonly styles are applied to tag components
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default TagsFieldWithFormik;
