import React from 'react';
import { ConfirmCancelDialog, DataCatalogSyncLoadingDialog, DataStructureChanges, DataStructureSyncSelectionDialog } from 'ts-components';
import { useDataSetChanges } from '../hooks/useDataSetChanges';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { DATA_SET_TYPE } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import { useDataSetSync } from '../hooks/useDataSetSync';
import { hasDataStructures } from '../utils';
import { setIsSyncInProgress } from '../../../businessGlossary/dataSet/redux/dataSetSlice';

type DataSetSyncContainerProps = {
  dataSetId: string;
  onClose: () => void;
}

export const DataSetSyncContainer = ({
                                       dataSetId,
                                       onClose
                                     }: DataSetSyncContainerProps) => {

  const [changes, isLoading, error, abortLoading] = useDataSetChanges(dataSetId);
  const [doSync, savingError] = useDataSetSync();

  const [existingObjects, maximumObjects] = useSelector((state: RootState) => {
    const { existingObjects, maximumObjects } = state[DATA_SET_TYPE].currentListSearchResult;
    return [existingObjects, maximumObjects == -1 ? undefined : maximumObjects] as const;
  });

  const dispatch = useDispatch();
  const setSyncInProgress = () => {
    dispatch(setIsSyncInProgress({ id: dataSetId, }));
  };

  const abortLoadingAndClose = () => {
    abortLoading();
    onClose();
  };

  const handleSelectionClose = (selection: DataStructureChanges[]) => {
    const dataStructuresToUpdate = selection.map(dataStructure => dataStructure.id);
    if (changes && dataStructuresToUpdate.length > 0) {
      doSync(changes.dataSet.id, dataStructuresToUpdate).then(() => {
        setSyncInProgress();
        onClose();
      });
    } else {
      onClose();
    }
  };

  if (isLoading) {
    return <DataCatalogSyncLoadingDialog
      handleClose={abortLoadingAndClose}
      title={'Loading'}
    />
  }

  if (error) {
    return <ConfirmCancelDialog
      onConfirm={onClose}
      title={'Data Source cannot be reached'}
      children={error.errorBody?.map(q => q.message.body).join(' ')}
    />
  }

  if (savingError) {
    const title = savingError.errorBody?.map(q => q.message.header)[0] || 'Data synchronization failed.';
    return <ConfirmCancelDialog
      onConfirm={onClose}
      title={title}
      children={savingError.errorBody?.map(q => q.message.body).join(' ')}
    />
  }

  if (!hasDataStructures(changes)) {
    return <ConfirmCancelDialog
      onConfirm={onClose}
      title={'Data Source is Empty'}
      children={'There is nothing to synchronize.'}
    />
  }

  return !!changes
    ? <DataStructureSyncSelectionDialog
      triggeredFrom={[changes.dataSet.dataSource, changes.dataSet]}
      data={changes.dataStructures}
      handleClose={handleSelectionClose}
      existingObjects={existingObjects}
      maximumObjects={maximumObjects}
    />
    : null;

};