import React from 'react';
import { FormikProps } from 'formik';
import { DetailInlineGroup, Tooltip } from 'ts-components';
import { AccurityCoreEntity, AccurityFilter } from '../../types/accurityTypes';
import { CustomProperty } from '../customProperty/types/customPropertyTypes';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL, useDataSetTypeEnumOptions } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import { getSupportiveCustomPropertyValueFieldLabel } from '../customPropertyUtils';
import { createReferenceFieldFilter } from '../../referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../detail/formik/ReferenceFieldWithFormik';
import EnumerationFieldWithFormik from '../../detail/formik/EnumerationFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE, DataStructureReference } from '../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_TYPE, DataFieldReference } from '../../../businessGlossary/dataField/types/dataFieldTypes';

interface DataFieldCustomPropertyValueFieldProps {
  formik: FormikProps<AccurityCoreEntity>;
  fieldName: string;
  customProperty: CustomProperty;
  additionalFilters?: AccurityFilter[];
  inherited?: boolean;
  tooltip?: Tooltip;
}

const DataFieldCustomPropertyValueField = ({ formik, fieldName, customProperty, additionalFilters = [], inherited, tooltip }: DataFieldCustomPropertyValueFieldProps) => {

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  const referenceFieldProps = formik.getFieldProps(fieldName + '.reference').value;
  const dataSetReferenceFieldProps = formik.getFieldProps(fieldName + '.dataSetReference').value;
  const dataStructureReferenceFieldProps = formik.getFieldProps(fieldName + '.dataStructureReference').value;

  const showDefaultTooltip = !!referenceFieldProps;
  const showDataSetTooltip = !!dataSetReferenceFieldProps;
  const showDataStructureTooltip = !!dataStructureReferenceFieldProps;

  let dataStructureFilters;

  const dataSetId = dataSetReferenceFieldProps?.id;
  const dataStructureId = dataStructureReferenceFieldProps?.id;

  dataStructureFilters = [
    createReferenceFieldFilter('dataSet.id', dataSetId),
    createReferenceFieldFilter('childrenCounts.dataFieldsCount', '>=1')
  ];
  additionalFilters.push(
    createReferenceFieldFilter('dataStructure.dataSet.id', dataSetId),
    createReferenceFieldFilter('dataStructure.id', dataStructureId)
  );

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik
          name={fieldName + '.dataSetReference'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_SET_LABEL)}
          objectType={DATA_SET_TYPE}
          icon={DATA_SET_ICON}
          tooltip={tooltip}
          setValue={(dataSet) => {
            formik.setFieldValue(fieldName + '.dataSetReference', dataSet);
            formik.setFieldValue(fieldName + '.dataStructureReference', null);
            formik.setFieldValue(fieldName + '.reference', null);
          }
          }
          showDefaultTooltip={showDataSetTooltip}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
        {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
          name={fieldName + '.dataSetReference.dataSetType'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_SET_TYPE_LABEL)}
          options={dataSetTypeEnumOptions}
          readOnly={true}
        />}
      </DetailInlineGroup>
      <DetailInlineGroup
        childGridSizes={[6, 6]}>
        <ReferenceFieldWithFormik<DataStructureReference>
          name={fieldName + '.dataStructureReference'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_STRUCTURE_LABEL)}
          objectType={DATA_STRUCTURE_TYPE}
          icon={DATA_STRUCTURE_ICON}
          tooltip={tooltip}
          additionalFilters={dataStructureFilters}
          setValue={(dataStructure) => {
            if (!dataSetId) {
              formik.setFieldValue(fieldName + '.dataSetReference', dataStructure?.dataSet);
            }
            formik.setFieldValue(fieldName + '.dataStructureReference', dataStructure);
            formik.setFieldValue(fieldName + '.reference', null);
          }}
          showDefaultTooltip={showDataStructureTooltip}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
        <ReferenceFieldWithFormik<DataFieldReference>
          name={fieldName + '.reference'}
          icon={DATA_FIELD_ICON}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_FIELD_LABEL)}
          objectType={DATA_FIELD_TYPE}
          additionalFilters={additionalFilters}
          setValue={(dataField) => {
            if (!dataSetId) {
              formik.setFieldValue(fieldName + '.dataSetReference', dataField?.dataStructure?.dataSet)
            }
            if (!dataStructureId) {
              formik.setFieldValue(fieldName + '.dataStructureReference', dataField?.dataStructure);
            }
            formik.setFieldValue(fieldName + '.reference', dataField);
          }}
          tooltip={tooltip}
          showDefaultTooltip={showDefaultTooltip}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
      </DetailInlineGroup>
    </>
  )
};


export default DataFieldCustomPropertyValueField;