import React from 'react';
import { useField, useFormikContext } from 'formik';
import { HyperlinkField, Tooltip } from 'ts-components';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface HyperlinkFieldWithFormikProps {
  name: string;
  label: string;
  readOnly?: boolean;
  tooltip?: Tooltip;
  inherited?: boolean;
  coloringOverwrite?: string;
}

const HyperlinkFieldWithFormik = ({ name, label, readOnly, tooltip, inherited, coloringOverwrite }: HyperlinkFieldWithFormikProps) => {
  const [field, meta, ] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(coloringOverwrite ? coloringOverwrite : name);

  return (
    <HyperlinkField
      value={field.value}
      onChange={(value: string) => formikContext.setFieldValue(name, value)}
      errorText={meta.error}
      name={name}
      label={label}
      disabled={readOnly || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      tooltip={tooltip}
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default HyperlinkFieldWithFormik;
