import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { Tag } from 'ts-components';
import { CoreEntityState } from '../../../common/redux/types';

export const PROCESS_TYPE = 'process';
export const PROCESS_LABEL = 'Process';
export const PROCESS_PLURAL_LABEL = 'Processes';
export const PROCESS_ICON = 'process';

export const PROCESS_FIELD = 'process';

export interface ProcessState extends CoreEntityState<Process> {
}

export interface Process extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}