import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';

import { Tooltip } from 'ts-components';

import AccurityReferenceField from '../../referenceField/components/AccurityReferenceField';
import { AccurityFilter, AccurityReference } from '../../types/accurityTypes';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface ReferenceFieldWithFormikProps<T extends AccurityReference> {
  name: string;
  label: string;
  icon?: string;
  setValue?: (newValue: T | null) => void;
  objectType: string;
  customEndpoint?: string;
  readOnly?: boolean;
  additionalFilters?: AccurityFilter[];
  hideRemoveButton?: boolean;
  showDefaultTooltip?: boolean;
  tooltip?: Tooltip;
  maxResult?: number;
  coloringOverwrite?: string;
  disableColoring?: boolean;
  defaultValue?: AccurityReference;
  inherited?: boolean;
  filterProperty?: string;
  dragDropRowProperty?: string;
}

const ReferenceFieldWithFormik = <T extends AccurityReference = AccurityReference>({
                                                                                     name,
                                                                                     label,
                                                                                     icon,
                                                                                     objectType,
                                                                                     customEndpoint,
                                                                                     readOnly,
                                                                                     additionalFilters = [],
                                                                                     setValue,
                                                                                     hideRemoveButton,
                                                                                     tooltip,
                                                                                     showDefaultTooltip,
                                                                                     maxResult,
                                                                                     coloringOverwrite,
                                                                                     defaultValue,
                                                                                     inherited,
                                                                                     filterProperty,
                                                                                     dragDropRowProperty,
                                                                                     disableColoring
                                                                                   }: ReferenceFieldWithFormikProps<T>) => {
  const [field, meta, helper] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(coloringOverwrite ? coloringOverwrite : name);

  // See #21304
  const setValueSettingNullIfUndefined = (newValue?: T) => {
    const nullIfUndefinedNewValue = newValue === undefined ? null : newValue;
    if (setValue) {
      setValue(nullIfUndefinedNewValue);
    } else {
      helper.setValue(nullIfUndefinedNewValue);
    }
  };

  useEffect(() => {
    if (defaultValue && !field.value) {
      helper.setValue(defaultValue);
    }
  });

  return (
    <AccurityReferenceField<T>
      value={field.value}
      errorMessage={meta.error}
      setValue={setValueSettingNullIfUndefined}
      name={name}
      label={label}
      icon={icon}
      readOnly={readOnly || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      objectType={objectType}
      customEndpoint={customEndpoint}
      additionalFilters={additionalFilters}
      hideRemoveButton={hideRemoveButton}
      coloring={disableColoring ? undefined : coloring}
      maxResult={maxResult}
      inherited={inherited}
      showDefaultTooltip={showDefaultTooltip}
      tooltip={tooltip}
      filterProperty={filterProperty}
      dragDropRowProperty={dragDropRowProperty}
    />
  );
};

export default ReferenceFieldWithFormik;
