import { BulkOperationActionType } from '../bulkOperations/types';
import { DataManagementMessageType } from '../dataTransfer/types';
import { SyncRunStatus } from '../dataCatalogSync/types';

export enum WebsocketMessageType {
  CreateCommand = 'createCommand',
  UpdateCommand = 'updateCommand',
  DeleteCommand = 'deleteCommand',
  AggregatedNotificationsCommand = 'aggregatedNotificationsCommand',
  ImportJobResultCommand = 'importJobResultCommand',
  ImportExceptionCommand = 'importExceptionCommand',
  ExportJobResultCommand = 'exportJobResultCommand',
  ErrorNotification = 'errorNotification',
  LogoutNotification = 'logoutNotification',
  BulkOperationLogCommand = 'bulkOperationLogCommand',
  ColumnProfilingStartedCommand = 'profilingStartedCommand',
  ColumnProfilingFinishedCommand = 'profilingFinishedCommand',
  DataManagementCommand = 'dataManagementCommand',
  DataSyncJobCommand = 'dataSyncJobCommand',
}

export type WebsocketCrudCommandType =
  WebsocketMessageType.CreateCommand |
  WebsocketMessageType.UpdateCommand |
  WebsocketMessageType.DeleteCommand;

export type WebsocketNotificationType =
  WebsocketMessageType.ErrorNotification |
  WebsocketMessageType.LogoutNotification;

export interface CrudCommand {
  command: WebsocketCrudCommandType;
  id: string;
  objectType: string;
  modifiedDate?: number;
}

export interface ImportJobResultCommand {
  command: WebsocketMessageType.ImportJobResultCommand;
  importJobId: string;
  objectType: string;
  partialImport: boolean;
  successfulImport: boolean;
  numberOfAllRecords: number;
  numberOfValidRecords: number;
  numberOfInvalidRecords: number;
  numberOfCreatedRecords: number;
  numberOfUpdatedRecords: number;
}

export interface ImportExceptionCommand {
  command: WebsocketMessageType.ImportExceptionCommand;
  importJobId: string;
  objectType: string;
}

export interface ExportJobResultCommand {
  command: WebsocketMessageType.ExportJobResultCommand;
  exportJobId: string;
  objectType: string;
  numberOfExportedRows: number;
  exportSuccess: boolean;
}

export interface BulkOperationLogCommand {
  command: WebsocketMessageType.BulkOperationLogCommand;
  jobId: string;
  objectType: string;
  successfulOperation: boolean;
  failedItems: number;
  successfulItems: number;
  action: BulkOperationActionType;
}

export interface DataManagementCommand {
  command: WebsocketMessageType.DataManagementCommand;
  dataManagementId: string;
  dataManagementMessageType: DataManagementMessageType;
}

export interface Notification {
  command: WebsocketNotificationType;
  header: string;
  body: string;
}

export interface AggregatedNotificationsCommand {
  command: WebsocketMessageType.AggregatedNotificationsCommand;
  commands: CrudCommand[];
}

export interface ErrorNotification extends Notification {
  command: WebsocketMessageType.ErrorNotification;
}

export interface LogoutNotification extends Notification {
  command: WebsocketMessageType.LogoutNotification;
}

export interface ColumnProfilingCommand {
  jobId: number;
  objectId: number;
  objectName: string;
  initiatedBy: number;
}

export interface ColumnProfilingStartedCommand extends ColumnProfilingCommand {
  command: WebsocketMessageType.ColumnProfilingStartedCommand;
}

export interface ColumnProfilingFinishedCommand extends ColumnProfilingCommand {
  command: WebsocketMessageType.ColumnProfilingFinishedCommand;
  finishTime: number;
  isSuccessful: boolean;
}

export interface DataSyncJobCommand {
  command: WebsocketMessageType.DataSyncJobCommand;
  runId: number;
  runStatus: SyncRunStatus;
}

export type AccurityNotification = ErrorNotification | LogoutNotification;
export type AccurityImportCommand = ImportJobResultCommand | ImportExceptionCommand;
export type AccurityProfilingCommand = ColumnProfilingStartedCommand | ColumnProfilingFinishedCommand;

export type AccurityMessage = CrudCommand
  | AggregatedNotificationsCommand
  | AccurityNotification
  | AccurityImportCommand
  | ExportJobResultCommand
  | BulkOperationLogCommand
  | AccurityProfilingCommand
  | DataManagementCommand
  | DataSyncJobCommand;
