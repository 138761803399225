import { removeCoreEntityFromStore, setIsDetailLoading } from '../../redux/commonReducers';
import { getDeleteEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse, showSuccessRemovalMessage } from '../../userMessages/actions';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { AccurityNavigationController, useAccurityNavigation } from '../../navigation/hooks';
import rootStore from '../../redux/rootStore';

export type RemoveSteps<T extends AccurityCoreEntity> = {
  setLoadingTrue: () => void,
  getRemoveEndpoint: (coreEntity: T) => string,
  doRemoveRequest: (endpoint: string, coreEntity: T) => Promise<T>,
  removeCoreEntityFromStore: (coreEntity: T) => void,
  closeDetail: (navigationController: AccurityNavigationController) => void,
  showRemoveSuccessSnackbar: (initialCoreEntity: T, coreEntity: T) => void,
  setLoadingFalse: () => void,
};

export type CustomRemoveSteps<T extends AccurityCoreEntity> = Partial<RemoveSteps<T>>;

export type RemoveAction<T extends AccurityCoreEntity> = (initialCoreEntity: T, coreEntity: T) => void;

const getDefaultRemoveSteps = <T extends AccurityCoreEntity>(): RemoveSteps<T> => {
  return {
    setLoadingTrue: () => {
      rootStore.dispatch(setIsDetailLoading(true));
    },

    getRemoveEndpoint: (coreEntity: T) => {
      return getDeleteEndpoint(coreEntity.objectType, coreEntity.id);
    },

    doRemoveRequest: (removeUrl: string, coreEntity: T) => {
      const deleteBody = JSON.stringify(coreEntity);

      return doFetch(removeUrl, 'DELETE', deleteBody)
        .catch(showErrorMessageFromResponse);
    },

    removeCoreEntityFromStore: (coreEntity: T) => {
      rootStore.dispatch(removeCoreEntityFromStore({ objectType: coreEntity.objectType, id: coreEntity.id }));
    },

    closeDetail: (navigationController: AccurityNavigationController) => {
      navigationController.closeDetail();
    },

    showRemoveSuccessSnackbar: (initialCoreEntity: T, coreEntity: T) => {
      showSuccessRemovalMessage(initialCoreEntity);
    },

    setLoadingFalse: () => {
      rootStore.dispatch(setIsDetailLoading(false));
    },
  }
};

export const useRemoveAction = <T extends AccurityCoreEntity>(customRemoveSteps?: CustomRemoveSteps<T>
): RemoveAction<T> => {
  const navigationController = useAccurityNavigation();

  return (initialCoreEntity: T, coreEntity: T) => {
    const removeSteps: RemoveSteps<T> = {
      ...getDefaultRemoveSteps(),
      ...customRemoveSteps,
    }

    removeSteps.setLoadingTrue();
    const removeEndpoint = removeSteps.getRemoveEndpoint(coreEntity);
    removeSteps.doRemoveRequest(removeEndpoint, coreEntity)
      .then(() => {
        removeSteps.removeCoreEntityFromStore(coreEntity);
        removeSteps.closeDetail(navigationController);
        removeSteps.showRemoveSuccessSnackbar(initialCoreEntity, coreEntity);
      })
      .finally(removeSteps.setLoadingFalse);
  };
}
