import React from 'react';
import { ObjectCounter, ObjectCounterSegment } from 'ts-components';
import { ListSearchResult } from '../types/types';
import { USER_TYPE } from '../../../businessGlossary/user/types/userTypes';

export type AccurityListObjectCounterProps = { listType: string; } &
  Pick<ListSearchResult<any>, 'existingObjects' | 'maximumObjects' | 'additionalExistingObjects' | 'additionalMaximumObjects'>;

const AccurityListObjectCounter = ({
                                     listType,
                                     existingObjects,
                                     maximumObjects,
                                     additionalExistingObjects,
                                     additionalMaximumObjects
                                   }: AccurityListObjectCounterProps) => {
  const segments: ObjectCounterSegment[] = [];

  if (listType === USER_TYPE) {
    if (maximumObjects > 0) {
      segments.push({
        // user - user_maintainer = viewers
        limit: maximumObjects - additionalMaximumObjects,
        // existingObjects - additionalExistingObjects = viewers
        count: existingObjects - additionalExistingObjects,
        label: 'Viewers'
      });
    }

    if (additionalMaximumObjects > 0) {
      segments.push({
        limit: additionalMaximumObjects,
        count: additionalExistingObjects,
        label: 'Editors'
      });
    }
  } else {
    if (maximumObjects !== -1) {
      segments.push({ count: existingObjects, limit: maximumObjects });
    }
  }

  return segments.length ? <ObjectCounter segments={segments}/> : null;
};

export default AccurityListObjectCounter;
