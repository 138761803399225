import { DATA_SET_TYPE, DataSet, DataSetState } from '../types/dataSetTypes';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreEntityState, getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';

const initialState: DataSetState = getInitialCoreEntityState('data-set');

export const setIsSyncInProgress = createAction<{ id: string }>('SET_IS_SYNC_IN_PROGRESS');
export const setIsSyncInProgressReducer = (state: CoreEntityState<DataSet>, action: PayloadAction<{ id: string }>) => {
  const id = action.payload.id;

  if (!state.byId[id]) {
    return;
  }

  console.log('hmmm : ', state.byId[id].data.name, state.byId[id].data.isSyncInProgress);

  state.byId[id].data = {
    ...state.byId[id].data,
    isSyncInProgress: true,
  };
};

const dataSetSlice = createSlice({
  name: DATA_SET_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
    [setIsSyncInProgress.type]: setIsSyncInProgressReducer
  }
});

export default dataSetSlice.reducer;
