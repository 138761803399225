import { AccurityPinFilter } from '../../types/accurityTypes';
import { ListSettings } from '../../list/types/types';
import { CustomProperty } from '../../customProperties/customProperty/types/customPropertyTypes';
import { CustomPropertyGroup } from '../../customProperties/customPropertyGroup/types/customPropertyGroupTypes';

export const LIST_SETTINGS_KEY_PREFIX = 'listSettings';
export const GLOBAL_PIN_FILTER_KEY = 'globalPinFilters';
export const DETAIL_WIDTH_SETTING_KEY = 'detailWidth';
export const BOTTOM_BANNER_DISMISSED_KEY = 'bottomBannerDismissedAt';

export interface UserSettingsState {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  timezone?: string;
  roles?: Role[];
  globalPinFilters: AccurityPinFilter[];
  listSettings: {
    [key: string]: ListSettings;
  }
  detailWidth?: number;
  bottomBannerDismissedAt?: number;
  maxCustomPropertyValueElements?: number;
  features: Feature[];
}

interface BackendUiPreference {
  keyValue: string;
  value: any;
}

export interface Feature {
  type: string;
}

export interface UserSettings {
  user: {
    id: string,
    firstName: string,
    lastName: string,
    timezone: string,
    roles: Role[],
    email: string;
  }
  preferences: BackendUiPreference[];
  customProperties: CustomProperty[];
  customPropertyGroups: CustomPropertyGroup[];
  maxCustomPropertyValueElements: number;
  features: Feature[];
}

export enum AccessRight {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  LAUNCH = 'LAUNCH',
}

export interface Permission {
  objectType: string;
  accessRight: AccessRight;
}

export interface Role {
  name: string;
  admin: boolean;
  permissions: Permission[];
}

export type CrudPermissions = {
  hasCreatePermission: boolean;
  hasReadPermission: boolean;
  hasUpdatePermission: boolean;
  hasDeletePermission: boolean;
  hasLaunchPermission: boolean;
}

export type InstanceInfoRequest = {
  subject: string;
}

export enum ApplicationStatus {
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
}

export type InstanceInfoResponse = {
  statusResponse: {
    state: 'STARTING' | 'RUNNING' | 'FAILED',
    available: boolean
  }
}

