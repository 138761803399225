import { AccountStatus, User } from '../types/userTypes';
import rootStore from '../../../common/redux/rootStore';
import { disableEmailNotificationsForUserRequest, lockUnlockUserRequest, restoreDefaultSettingsRequest } from '../rest/userApi';
import { showSnackbarMessage } from '../../../common/userMessages/actions';
import {
  getAccountHasBeenLockedMessage,
  getAccountHasBeenUnlockedMessage,
  getEmailNotificationsDisabledMessage,
  getSettingsRestoredToDefaultMessage
} from '../../../common/userMessages/commonMessages';
import { addCoreEntityToStore, setIsDetailLoading } from '../../../common/redux/commonReducers';

export const lockUser = (user: User, postReloadAction?: () => void) => {
  if (user.accountStatus === AccountStatus.ACTIVE) {
    toggleAccountStatus(user, postReloadAction)
      .then(() => showSnackbarMessage(getAccountHasBeenLockedMessage(user)));
  }
};

export const unlockUser = (user: User, postReloadAction?: () => void) => {
  if (user.accountStatus === AccountStatus.LOCKED_MANUALLY || user.accountStatus === AccountStatus.LOCKED_AUTOMATICALLY) {
    toggleAccountStatus(user, postReloadAction)
      .then(() => showSnackbarMessage(getAccountHasBeenUnlockedMessage(user)));
  }
};

const toggleAccountStatus = (user: User, postReloadAction?: () => void) => {
  rootStore.dispatch(setIsDetailLoading(true));
  return lockUnlockUserRequest(user).then((savedUser) => {
    rootStore.dispatch(addCoreEntityToStore({ coreEntity: savedUser, fetchedAt: Date.now() }));
    if (postReloadAction) {
      postReloadAction();
    }
  }).finally(() => rootStore.dispatch(setIsDetailLoading(false)));
};

export const restoreDefaultSettings = (userId: string) => {
  return restoreDefaultSettingsRequest(userId)
    .then(() => showSnackbarMessage(getSettingsRestoredToDefaultMessage()));
};

export const disableEmailNotificationsForUser = (userId: string) => {
  return disableEmailNotificationsForUserRequest(userId)
    .then(() => showSnackbarMessage(getEmailNotificationsDisabledMessage()))
};
