import React from 'react';
import {camelCase} from 'lodash';
import { BulkOperationNotificationCenterNotification } from './types';
import { NotificationCenterCard, ProgressIndicator } from 'ts-components';
import { useDispatch } from 'react-redux';
import { dismissNotification } from './notificationCenterSlice';
import Analytics from '../analytics';
import { requestDownloadBulkOperationLog } from '../bulkOperations/rest/rest';
import { useTranslation } from 'react-i18next';

export interface BulkOperationNotificationCardProps {
  notification: BulkOperationNotificationCenterNotification;
}

const BulkOperationNotificationCard = ({ notification }: BulkOperationNotificationCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDismiss = notification.cannotDismiss ? undefined : () => dispatch(dismissNotification(notification.id));

  const downloadReport = notification.showDownloadReport ? () => {
    requestDownloadBulkOperationLog(notification.objectType);
    Analytics.event('Notification Center', 'Download Bulk Operation Report');
  } : undefined;

  return (
    <NotificationCenterCard
      title={notification.header}
      titleIcon={camelCase(notification.objectType)}
      date={notification.date}
      unread={!notification.wasRead}
      onDismiss={onDismiss}
      actionText={ notification.showDownloadReport ? t('importNotification.downloadReport') : undefined}
      actionClick={downloadReport}
    >
      {notification.inProgress && <ProgressBar/>}
      {notification.body}
    </NotificationCenterCard>
  );
};

export default BulkOperationNotificationCard;

const ProgressBar = () => (
  <span style={{ width: '100%' }}><ProgressIndicator/></span>
);
