import React from 'react';
import { useFormikContext } from 'formik';
import RelatedBusinessTermsWithSuggestions from '../../../common/detail/formik/RelatedBusinessTermsWithSuggestions';
import { DataStructure } from '../types/dataStructureTypes';
import { createFetchFunctionForBusinessTermsSuggestions } from '../../businessTerm/rest/fetchBusinessTermsSuggestions';
import { getBusinessTermsSuggestionsByDataStructureEndpoint } from '../../../common/rest/endpoints';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';

type RelatedBusinessTermsWithSuggestionsForDataStructureProps = {
  hideSuggestions?: boolean
};

const RelatedBusinessTermsWithSuggestionsForDataStructure = ({ hideSuggestions }: RelatedBusinessTermsWithSuggestionsForDataStructureProps) => {
  const formikContext = useFormikContext<DataStructure>();

  return (
    <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
      <RelatedBusinessTermsWithSuggestions
        objectName={formikContext.values.name}
        fetchFn={createFetchFunctionForBusinessTermsSuggestions(getBusinessTermsSuggestionsByDataStructureEndpoint())}
        hideSuggestions={hideSuggestions}
      />
    </FeatureChecker>
  )
};

export default RelatedBusinessTermsWithSuggestionsForDataStructure;
