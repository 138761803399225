import React from 'react';
import CloseDetailWithFormik from '../formik/CloseDetailWithFormik';
import CommentsToggleWithFormik from '../formik/CommentsToggleWithFormik';
import EmailNotificationToggleWithFormik from '../formik/EmailNotificationToggleWithFormik';
import FeatureChecker from '../../userSettings/components/FeatureChecker';
import { Feature } from '../../userSettings/features/features';

export interface DetailTopBarProps {
  allowComments?: boolean;
  allowEmailNotification?: boolean;
}

const DetailTopBar = ({ allowComments = true, allowEmailNotification = true }: DetailTopBarProps) => {
  return (
    <div className="detailTopBar">
      {allowComments && <CommentsToggleWithFormik/>}
      {allowEmailNotification &&
      <FeatureChecker featureId={Feature.EMAIL_NOTIFICATIONS}>
        <EmailNotificationToggleWithFormik/>
      </FeatureChecker>}
      <CloseDetailWithFormik/>
    </div>
  );
};

export default DetailTopBar;