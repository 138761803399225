import { useEffect, useState } from 'react';
import { getDataSetChanges } from '../api/dataSetSyncApi';
import { DataSetChanges } from '../types';
import { AccurityFetchError } from '../../rest/types';

export const useDataSetChanges = (dataSetId: string) => {
  const [changes, setChanges] = useState<DataSetChanges>();
  const [error, setError] = useState<AccurityFetchError>();
  const [isLoading, setIsLoading] = useState(false);
  let abortController: AbortController;

  useEffect(() => {
    setIsLoading(true);
    setChanges(undefined);
    setError(undefined);
    abortController = new AbortController();
    getDataSetChanges(dataSetId, abortController.signal)
      .then(response => setChanges(response))
      .catch(error => {
        if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
          // the request was aborted, we do not want to handle this error
          return;
        }

        setError(error);
      })
      .finally(() => setIsLoading(false));

    return () => {
      // abort previous request when data set is changed
      abortController.abort();
    }
  }, [dataSetId]);

  const abortLoading = () => abortController && isLoading
    ? abortController.abort()
    : {};

  return [
    changes,
    isLoading,
    error,
    abortLoading
  ] as const;
};