import { useEffect, useState } from 'react';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useSelectLastProfiledDataFieldId } from '../redux/selectors';

export const useOpenCanvasOnProfilingFinish = (dataFieldId?: string) => {

  // determines whether the profiling was triggered for current dataFieldId
  const [triggered, setTriggered] = useState(false);
  const navigation = useAccurityNavigation();
  // get last profiled DF id from redux store
  const lastProfiledDataFieldId = useSelectLastProfiledDataFieldId();
  const [lastProfiledDataSetInitial, setLastProfiledDataSetInitial] = useState(lastProfiledDataFieldId);

  // reset `triggered` flag when we change data field detail
  useEffect(() => {
    setTriggered(false);
    setLastProfiledDataSetInitial(lastProfiledDataFieldId);
  }, [dataFieldId]);

  // open canvas if
  //      we are on the same data field detail as on which the profiling was triggered
  //      and the lastProfiledDataFieldId has changed (we received websocket message that profiling has finished)
  useEffect(() => {
    if (
      dataFieldId &&
      triggered &&
      lastProfiledDataFieldId !== lastProfiledDataSetInitial &&
      String(lastProfiledDataFieldId) === String(dataFieldId)
    ) {
      navigation.openDataFieldProfilingCanvas(dataFieldId);
    }
  }, [lastProfiledDataFieldId, dataFieldId]);

  return {
    profilingTriggered: () => setTriggered(true)
  }
};
