import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { BusinessRuleDimensionOptions } from '../types/businessRuleTypes';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { Column } from 'ts-components';

export const useBusinessRuleListColumns = () => {
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const businessRuleListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    columnFactory.createTextColumn('definition.plainTextValue', 'Definition', { sortable: !isOracle, hidden: true }),
    columnFactory.createEnumColumn('dimension', 'Dimension', BusinessRuleDimensionOptions),
    columnFactory.createTextColumn('threshold', 'Threshold'),
    commonColumns.tags(navigationController),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(businessRuleListColumns, GlossaryCustomPropertyObjectType.BUSINESS_RULE);

};
