import React from 'react';
import { AccurityFilterType, useInteractiveDiagramContext } from 'ts-components';

import AccurityReferenceMultiSelector from '../../../../common/referenceField/components/AccurityReferenceMultiSelector';
import { AccurityFilter, AccurityReference } from '../../../../common/types/accurityTypes';
import { ATTRIBUTE_TYPE } from '../../../attribute/types/attributeTypes';
import { BusinessModelDiagramNodeProps } from './BusinessModelDiagramNode';
import { BusinessModelDiagramLinkProps } from '../link/BusinessModelDiagramLink';

interface DisplayAttributesSelectorProps {
  entityId: string;
  nodeId: string;
  updateAttributes: (attributeIds: string[]) => void;
}

const DisplayAttributesSelector = ({ entityId, nodeId, updateAttributes }: DisplayAttributesSelectorProps) => {
  const diagramBag = useInteractiveDiagramContext<BusinessModelDiagramNodeProps, BusinessModelDiagramLinkProps>();
  const displayedAttributes = Object.values(diagramBag.state.links)
    .filter(link => link.relationType === 'ASSOCIATION')
    .filter(link => link.target === nodeId)
    .map(link => diagramBag.state.nodes[link.source])
    .filter(node => !!node)
    .map(node => ({
      id: node.objectId,
      objectType: node.objectType,
      name: node.label,
    }));

  const onSelectAttribute = (newSelectedAttributes: AccurityReference[]) => {
    updateAttributes(newSelectedAttributes.map(att => att.id));
  }

  const nonReferenceAttributeOfEntityFilters: AccurityFilter[] = [
    {
      type: AccurityFilterType.SIMPLE_QUERY,
      property: 'entity.id',
      value: entityId,
    },
    {
      type: AccurityFilterType.SIMPLE_QUERY,
      property: 'attributeDefinition.attributeDefinitionType',
      value: '-=REFERENCE',
    },
    {
      type: AccurityFilterType.SIMPLE_QUERY,
      property: 'attributeDefinition.attributeDefinitionType',
      value: '-=COMPONENT_REFERENCE',
    },
  ];

  return (
    <AccurityReferenceMultiSelector
      name={'attributesSelector'}
      label={'Attributes'}
      value={displayedAttributes}
      setValue={onSelectAttribute}
      objectType={ATTRIBUTE_TYPE}
      additionalFilters={nonReferenceAttributeOfEntityFilters}
      focusOnMount={true}
      allowSelectAll={true}
    />
  );
}

export default DisplayAttributesSelector;
