import React, { useState } from 'react';
import ApplicationSettingsDetail from './ApplicationSettingsDetail';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../analytics';
import { customLogoUpdate } from '../actions/applicationSettingsActions';
import { AppSettingsState, CustomLogoRequest } from '../types/types';
import { doFetch } from '../../rest/FetchService';
import { getClearAllDataEndpoint, getCreateSampleDataEndpoint } from '../../rest/endpoints';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { useAccurityNavigation } from '../../navigation/hooks';
import { cleanStore } from '../../redux/rootStore';

const ApplicationSettingsDetailContainer = () => {
  const navigationController = useAccurityNavigation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const updateCustomLogo = (customLogo: CustomLogoRequest) => {
    Analytics.event('Detail: Application Settings', 'Custom Logo Change ');
    dispatch(customLogoUpdate(customLogo, setIsLoading));
  };

  const clearAllData = () => {
    // see #24978
    // doFetch(getConfirmPasswordEndpoint(), 'POST', JSON.stringify({ password: confirmPassword }))
    navigationController.openWelcomeScreen();
    cleanStore(true);
    doFetch(getClearAllDataEndpoint(), 'POST')
      .catch(showErrorMessageFromResponse);
  };

  const useSampleData = () => {
    // see #24978
    // doFetch(getConfirmPasswordEndpoint(), 'POST', JSON.stringify({ password: confirmPassword }))
    navigationController.openWelcomeScreen();
    cleanStore(true);
    doFetch(getCreateSampleDataEndpoint(), 'POST')
      .catch(showErrorMessageFromResponse);
  };

  const appSettings = useSelector((state: { appSettings: AppSettingsState }) => state.appSettings);

  return (
    <ApplicationSettingsDetail
      appSettings={appSettings}
      updateCustomLogo={updateCustomLogo}
      clearAllData={clearAllData}
      useSampleData={useSampleData}
      isLoading={isLoading}
    />
  );
};

export default ApplicationSettingsDetailContainer;
