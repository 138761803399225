import { Action, ThunkAction } from '@reduxjs/toolkit';

export type RootState = {
  [objectType: string]: CoreEntityState<any>
}

// export type RootState = ReturnType<typeof rootReducer>

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export interface CoreEntityMeta {
  versionInStore: number;
  versionInBackend: number;
  wasDeleted: boolean;
  fetchedAt: number;
}

export interface CoreEntityState<T> {
  type: string;
  byId: {
    [id: string]: {
      data: T,
      meta: CoreEntityMeta,
    }
  },
  allIds: string[],
  currentListSearchResult: {
    ids: string[],
    totalRows: number,
    existingObjects: number,
    maximumObjects: number,
    additionalExistingObjects: number,
    additionalMaximumObjects: number,
    fetchedAt: number,
  },
  invalidatedAt: number,
  isDetailLoading: boolean,
  isListLoading: boolean,
  detailOpenedAt?: number,
}

export const getInitialCoreEntityState = (type: string) => (
  {
    type: type,
    byId: {},
    allIds: [],
    currentListSearchResult: {
      ids: [],
      totalRows: 0,
      existingObjects: 0,
      maximumObjects: -1,
      additionalExistingObjects: -1,
      additionalMaximumObjects: -1,
      fetchedAt: -1,
    },
    invalidatedAt: Date.now(),
    isDetailLoading: false,
    isListLoading: false,
    detailOpenedAt: Date.now(),
  }
);

export const getInitialCoreEntityMeta = (): CoreEntityMeta => ({
  versionInStore: -1,
  versionInBackend: -1,
  wasDeleted: false,
  fetchedAt: -1,
});


export const mockedCPData = {
  customPropertyGroups: [
    {
      objectType: 'customPropertyGroup',
      id: 14,
      name: 'Additional Information',
      groupOrder: 0,
    },
    {
      objectType: 'customPropertyGroup',
      id: 77,
      name: 'Empty Group',
      groupOrder: 4,
    },
    {
      objectType: 'customPropertyGroup',
      id: 107,
      name: 'Another Group',
      groupOrder: 2,
    },
  ],
  customProperties: [
    {
      objectType: 'customProperty',
      id: 22,
      customPropertyGroup: { objectType: 'customPropertyGroup', id: 14, name: '' },
      name: 'Links',
      forObjectType: 'DATA_SET',
      propertyType: 'HYPERLINK',
      multiselection: true,
      order: 7,
    },
    {
      objectType: 'customProperty',
      id: 12,
      customPropertyGroup: { objectType: 'customPropertyGroup', id: 14, name: '' },
      name: 'Notes',
      forObjectType: 'DATA_SET',
      propertyType: 'TEXT',
      multiselection: false,
      order: 3,
    },
    {
      objectType: 'customProperty',
      id: 88,
      customPropertyGroup: { objectType: 'customPropertyGroup', id: 107, name: '' },
      name: 'Color',
      forObjectType: 'DATA_SET',
      propertyType: 'ENUMERATION',
      options: [
        { text: 'Red', value: 'RED' },
        { text: 'Green', value: 'GREEN' },
        { text: 'Blue', value: 'BLUE' },
      ],
      multiselection: false,
      order: 5,
    },
  ],
};
