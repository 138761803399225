import React from 'react';
import { DetailHeader } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_TYPE, BusinessRule, BusinessRuleDimensionOptions } from '../types/businessRuleTypes';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import MultiLineFieldWithFormik from '../../../common/detail/formik/MultiLineFieldWithFormik';
import { BUSINESS_TERM_TYPE } from '../../businessTerm/types/businessTermTypes';
import RichTextEditorFieldWithFormik from '../../../common/detail/formik/RichTextEditorFieldWithFormik';
import { ATTRIBUTE_TYPE } from '../../attribute/types/attributeTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface BusinessRuleDetailProps {
  id?: string,
}

const BusinessRuleDetail = ({ id }: BusinessRuleDetailProps) => {
  const hasFeature = useHasFeature();
  const hasAttributesFeature = hasFeature(Feature.ATTRIBUTES);
  const hasBusinessTermFeature = hasFeature(Feature.BUSINESS_TERMS);

  return (
    <AccurityDetailContainer<BusinessRule>
      objectType={BUSINESS_RULE_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, hasBusinessTermFeature, hasAttributesFeature)}
              <DefaultDetailBottomBar objectType={BUSINESS_RULE_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (detailBag: DetailBag<BusinessRule>) => (
  <ConfirmRemovalDetail
    iconName={BUSINESS_RULE_ICON}
    detailBag={detailBag}
  />
);

const getDetailFields = (formik: FormikProps<BusinessRule>,
                         hasBusinessTermFeature: boolean,
                         hasAttributesFeature: boolean) => {
  return (
    <FeatureChecker featureId={Feature.BUSINESS_RULES}>
      <DetailHeader iconName={BUSINESS_RULE_ICON}>
        <NameFieldWithFormik label={'Business Rule Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={(formik) => getDetailFields(formik, hasBusinessTermFeature, hasAttributesFeature)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={BUSINESS_RULE_TYPE}/>
      <RichTextEditorFieldWithFormik
        name={'definition'}
        label={'Definition'}
        allowedChipTypes={[...(hasBusinessTermFeature ? [BUSINESS_TERM_TYPE] : []), ...(hasAttributesFeature ? [ATTRIBUTE_TYPE] : [])]}
      />
      <EnumerationFieldWithFormik
        name={'dimension'}
        label={'Dimension'}
        options={BusinessRuleDimensionOptions}
      />
      <MultiLineFieldWithFormik name={'threshold'} label={'Threshold'}/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.BUSINESS_RULE}/>
    </FeatureChecker>
  );
};

export default BusinessRuleDetail;
