import React from 'react';
import { AccurityButton, AccurityMenuItem, DetailBottomBar, DetailMenu } from 'ts-components';
import { FormikProps } from 'formik';
import { BUSINESS_TERM_TYPE, BusinessTerm } from '../types/businessTermTypes';
import { DetailBag } from '../../../common/detail/types/types';
import Analytics from '../../../common/analytics';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { ATTRIBUTE_DEFINITION_TYPE, AttributeDefinition } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { Entity, ENTITY_TYPE } from '../../entity/types/entityTypes';
import { AccurityReferenceWithChips } from '../../../common/types/accurityTypes';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import GenerateProcessDiagramButton from '../../processDiagram/detail/GenerateProcessDiagramButton';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramBasedOnType } from '../../processDiagram/types/processDiagramTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';

interface BusinessTermDetailBottomBarProps {
  detailBag: DetailBag<BusinessTerm>;
  formik: FormikProps<BusinessTerm>;
}

const BusinessTermDetailBottomBar = ({ detailBag, formik }: BusinessTermDetailBottomBarProps) => {
  const trackAction = Analytics.createActionTrackerWithCallback('Detail: ' + BUSINESS_TERM_TYPE);
  const navigation = useAccurityNavigation();
  const hasFeature = useHasFeature();

  const businessTermReference: AccurityReferenceWithChips = {
    id: formik.values.id,
    objectType: formik.values.objectType,
    name: formik.values.name,
    description: formik.values.description,
    chips: formik.values.chips,
  };

  const openNewEntityDetail = () => {
    const prePopulatedData: Partial<Entity> = {
      basedOnBusinessTerm: businessTermReference,
    };
    navigation.openDetail(ENTITY_TYPE, prePopulatedData);
  };

  const openNewAttributeDefinitionDetail = () => {
    const prePopulatedData: Partial<AttributeDefinition> = {
      basedOnBusinessTerm: businessTermReference,
    };
    navigation.openDetail(ATTRIBUTE_DEFINITION_TYPE, prePopulatedData);
  };

  const hasEntityCreatePermission = usePermissionsForObjectType(ENTITY_TYPE).hasCreatePermission;
  const hasAttributeDefinitionCreatePermission = usePermissionsForObjectType(ATTRIBUTE_DEFINITION_TYPE).hasCreatePermission;
  const hasProcessDiagramCreatePermission = usePermissionsForObjectType(PROCESS_DIAGRAM_TYPE).hasCreatePermission;
  const hasProcessDiagramFeature = hasFeature(Feature.PROCESS_DIAGRAMS);

  return (
    <DetailBottomBar
      menu={(
        <DetailMenu>
          {detailBag.showCopyAction && (
            <AccurityMenuItem onClick={trackAction(detailBag.copyAction, 'Copy Object')}>
              Copy
            </AccurityMenuItem>
          )}
          {formik.status.isUpdateDetail && hasEntityCreatePermission && (
            <FeatureChecker featureId={Feature.ENTITIES}>
              <AccurityMenuItem onClick={openNewEntityDetail}>
                Base Entity on this Business Term
              </AccurityMenuItem>
            </FeatureChecker>
          )}
          {formik.status.isUpdateDetail && hasAttributeDefinitionCreatePermission && (
            <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
              <AccurityMenuItem onClick={openNewAttributeDefinitionDetail}>
                Base Attribute Definition on this Business Term
              </AccurityMenuItem>
            </FeatureChecker>
          )}
          {hasProcessDiagramCreatePermission && hasProcessDiagramFeature &&
          <GenerateProcessDiagramButton
            basedOnType={ProcessDiagramBasedOnType.BUSINESS_TERMS}
            objectType={BUSINESS_TERM_TYPE}
            id={formik.values.id}
          />}
        </DetailMenu>
      )}
    >
      {detailBag.showRemoveAction && (
        <AccurityButton
          onClick={trackAction(() => detailBag.removeAction(), 'Remove Object')}
          disabled={detailBag.isRemoveDisabled}
          color="secondary"
        >
          Remove
        </AccurityButton>
      )}
      {detailBag.showReloadAction && (
        <AccurityButton
          onClick={trackAction(detailBag.reloadAction, 'Reload Object')}
          disabled={detailBag.isReloadDisabled}
          color="secondary"
        >
          Reload
        </AccurityButton>
      )}
      {detailBag.showSaveAction && (
        <AccurityButton
          onClick={trackAction(() => detailBag.saveAction(), 'Save Object')}
          disabled={detailBag.isSaveDisabled}
          color="primary"
        >
          Save
        </AccurityButton>
      )}
    </DetailBottomBar>
  );
};

export default BusinessTermDetailBottomBar;
