import React from 'react';
import { CommentsContainer } from 'ts-components';
import { useCommentNotification } from '../hooks/commentNotificationHook';
import { useSelector } from 'react-redux';
import { UserSettingsState } from '../../userSettings/types/types';
import { useCommentData } from '../hooks/commentDataHook';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';

type CommentsDrawerProps = {
  entity: AccurityCoreEntity;
  onCloseDrawer: () => void;
};

export const CommentsDrawer = ({ entity, onCloseDrawer }: CommentsDrawerProps) => {

  const {
    comments,
    isLoading,
    totalComments,
    handleCreate,
    handleDelete,
    handleUpdate,
    loadMore
  } = useCommentData(entity.objectType, +entity.id);

  const hasFeature = useHasFeature();
  const displayNotificationToggle = hasFeature(Feature.EMAIL_NOTIFICATIONS);
  const { isNotificationActive, isNotificationLoading, toggleNotification } = useCommentNotification(entity);
  const user = useSelector((state: { userSettings: UserSettingsState }) => {
    const { id, firstName, lastName } = state.userSettings;
    return {
      id: parseInt(id ? id : '', 10), firstName: firstName || '', lastName: lastName || ''
    }
  });

  return (
    <div className="commentsDrawerWrapper">
      <div className="commentsDrawer">
        <CommentsContainer
          comments={comments}
          totalCount={totalComments}
          onSave={handleUpdate}
          onLoadMore={loadMore}
          onDelete={handleDelete}
          onCreate={handleCreate}
          isLoading={isLoading}
          onClose={onCloseDrawer}
          displayNotificationToggle={displayNotificationToggle}
          isNotificationActive={isNotificationActive}
          isNotificationLoading={isNotificationLoading}
          onNotificationClick={() => toggleNotification()}
          user={user}
        />
      </div>
    </div>
  )
};
