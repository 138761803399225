import React, { useState } from 'react';
import { useAccurityNavigation } from '../../common/navigation/hooks';
import Analytics from '../../common/analytics';
import {
  AttributeDefinitionMenuItem,
  AttributeMenuItem,
  BusinessModelMappingMenuItem,
  BusinessRuleMenuItem,
  BusinessTermMenuItem,
  CompositeTypeMenuItem,
  CustomPropertyGroupMenuItem,
  CustomPropertyMenuItem,
  DataAssetMenuItem,
  DataFieldMenuItem,
  DataSetMenuItem,
  DataSourceMenuItem,
  DataStructureMenuItem,
  EntityMenuItem,
  ProcessMappingMenuItem,
  ProcessMenuItem,
  ProcessStepMenuItem,
  RequirementMenuItem,
  StatusTypeMenuItem,
  TechnicalDataMappingMenuItem,
  UserMenuItem,
  ValueTypeMenuItem, BusinessModelDiagramMenuItem, ProcessDiagramMenuItem
} from './businessGlossaryMenuItems';
import { MainMenu, MainMenuGroup } from 'ts-components';
import FeatureCheckerOr from '../../common/userSettings/components/FeatureCheckerOr';
import { Feature } from '../../common/userSettings/features/features';

interface BusinessGlossaryMainMenuProps {
  expanded?: boolean;
}

const BusinessGlossaryMainMenu = ({ expanded }: BusinessGlossaryMainMenuProps) => {
  const trackAction = Analytics.createActionTracker('Menu');
  const navigationController = useAccurityNavigation();
  const selectedMenuItem = navigationController.getCurrentNavigation().listType || navigationController.getCurrentNavigation().diagramType;
  const [expandedGroups, setExpandedGroups] = useState<string[]>(['businessModel', 'dataCatalog', 'processManagement']);

  const toggleExpandGroup = (groupName: string) => {
    if (expandedGroups.includes(groupName)) {
      setExpandedGroups(expandedGroups.filter(gn => gn !== groupName));
    } else {
      setExpandedGroups([...expandedGroups, groupName]);
    }
  };

  const isGroupExpanded = (groupName: string) => expandedGroups.includes(groupName);

  const menuItemClickHandler = (itemType: string) => {
    navigationController.openListAndCloseDetail(itemType);
    trackAction('Click', itemType);
  };

  const collapsedMenuItems = (
    <>
      {BusinessTermMenuItem}
      {EntityMenuItem}
      {AttributeDefinitionMenuItem}
      {AttributeMenuItem}
      {BusinessModelMappingMenuItem}
      {BusinessModelDiagramMenuItem}
      {BusinessRuleMenuItem}
      {DataAssetMenuItem}
      {DataSetMenuItem}
      {DataStructureMenuItem}
      {DataFieldMenuItem}
    </>
  );

  const expandedMenuItems = (
    <>
      <FeatureCheckerOr featureIds={[
        Feature.REQUIREMENTS,
        Feature.BUSINESS_TERMS,
        Feature.ENTITIES,
        Feature.ATTRIBUTE_DEFINITIONS,
        Feature.ATTRIBUTES,
        Feature.BUSINESS_MODEL_MAPPINGS,
        Feature.BUSINESS_RULES,
        Feature.COMPOSITE_TYPES,
        Feature.VALUE_TYPES
      ]}>
        <MainMenuGroup name={'businessModel'} label={'Business Model'}>
          {RequirementMenuItem}
          {BusinessTermMenuItem}
          {EntityMenuItem}
          {AttributeDefinitionMenuItem}
          {AttributeMenuItem}
          {BusinessModelDiagramMenuItem}
          {BusinessModelMappingMenuItem}
          {BusinessRuleMenuItem}
          {CompositeTypeMenuItem}
          {ValueTypeMenuItem}
        </MainMenuGroup>
      </FeatureCheckerOr>
      <FeatureCheckerOr featureIds={[
        Feature.DATA_ASSETS,
        Feature.LOGICAL_DATA_SETS,
        Feature.PHYSICAL_DATA_SETS,
        Feature.DATA_STRUCTURES,
        Feature.DATA_FIELDS,
        Feature.TECHNICAL_DATA_MAPPINGS
      ]}>
        <MainMenuGroup name={'dataCatalog'} label={'Data Catalog'}>
          {DataAssetMenuItem}
          {DataSetMenuItem}
          {DataStructureMenuItem}
          {DataFieldMenuItem}
          {TechnicalDataMappingMenuItem}
        </MainMenuGroup>
      </FeatureCheckerOr>
      <FeatureCheckerOr featureIds={[
        Feature.PROCESSES,
        Feature.PROCESS_STEPS,
        Feature.PROCESS_MAPPINGS,
        Feature.PROCESS_DIAGRAMS,
      ]}>
        <MainMenuGroup name={'processManagement'} label={'Process Management'}>
          {ProcessMenuItem}
          {ProcessStepMenuItem}
          {ProcessMappingMenuItem}
          {ProcessDiagramMenuItem}
        </MainMenuGroup>
      </FeatureCheckerOr>
      <MainMenuGroup name={'administration'} label={'Administration'}>
        {CustomPropertyGroupMenuItem}
        {CustomPropertyMenuItem}
        {DataSourceMenuItem}
        {StatusTypeMenuItem}
        {UserMenuItem}
      </MainMenuGroup>
    </>
  );

  return (
    <MainMenu
      expanded={expanded}
      selectedType={selectedMenuItem}
      onItemClick={menuItemClickHandler}
      isGroupExpanded={isGroupExpanded}
      onGroupClick={toggleExpandGroup}
    >
      {expanded ? expandedMenuItems : collapsedMenuItems}
    </MainMenu>
  );
};

export default BusinessGlossaryMainMenu;
