import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_PLURAL_LABEL, BUSINESS_RULE_TYPE } from '../types/businessRuleTypes';
import { useBusinessRuleListColumns } from './businessRuleListColumns';

const BusinessRuleList = () => {
  const businessRuleColumns = useBusinessRuleListColumns();
  const businessRuleListSettings = getDefaultListSettings(BUSINESS_RULE_TYPE, businessRuleColumns);

  return (
    <AccurityListContainer
      objectType={BUSINESS_RULE_TYPE}
      title={BUSINESS_RULE_PLURAL_LABEL}
      icon={BUSINESS_RULE_ICON}
      columns={businessRuleColumns}
      defaultListSettings={businessRuleListSettings}
    />
  );
};

export default BusinessRuleList;
