import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { Column } from 'ts-components';
import { useValidColumnsForFeatures } from '../../../common/list/listUtils';

export const useStatusListColumns = () => {

  const isOracle = useIsOracleDb();

  const statusListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    columnFactory.createBooleanColumn('defaultIndicator', 'Default'),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumnsForFeatures(statusListColumns);
};
