import { isEqual } from 'lodash';
import { Tag } from 'ts-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { requestBulkNotificationUpdate, requestBulkRemove, requestUpdateTags } from '../rest/rest';
import { pushBulkOperationInProgressNotification } from '../notifications/actions';
import { BulkOperationActionType, BulkOperationsConfig, CoreEntityWithTags } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, selectIsInProgress, selectRows, selectSelectedEntities, setIsInProgress, unselectRows } from '../redux/bulkOperationsSlice';
import { AccurityCoreEntity, EmailNotificationActionType } from '../../types/accurityTypes';
import { CrudPermissions } from '../../userSettings/types/types';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';
import { BulkNotificationsUpdate } from 'ts-components/src/modules/list/components/ListBulkNotificationsDialog';
import { getBulkNotificationsUpdatedMessage } from '../messages';
import { showSnackbarMessage } from '../../userMessages/actions';
import { setNotificationForCoreEntity } from '../../redux/commonReducers';

export const useBulkOperations = (listType: string,
                                  visibleRows: AccurityCoreEntity[],
                                  permissions: CrudPermissions,
                                  bulkOperationsConfig: BulkOperationsConfig = {
                                    removeEnabled: true,
                                    updateTagsEnabled: true,
                                    toggleEmailNotificationsEnabled: true,
                                  }): BulkOperationsManager => {

  const dispatch = useDispatch();
  const hasFeature = useHasFeature();
  const selectedEntities = useSelector(selectSelectedEntities(listType), isEqual);
  const isInProgress = useSelector(selectIsInProgress);

  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    const entitiesToUnselect = selectedEntities
      .filter(selected => !visibleRows.find(visible => visible.id === selected.id))
      .map(q => q.id);
    if (entitiesToUnselect.length > 0) {
      dispatch(unselectRows(entitiesToUnselect));
    }
  }, [visibleRows, selectedEntities, dispatch])

  // reset values when the list is changed
  useEffect(() => {
    dispatch(resetState());
    setTags([]);
  }, [listType, dispatch]);

  // update the collection of common tags when row selection changes
  useEffect(() => {
    setTags(getCommonTags(selectedEntities));
  }, [selectedEntities]);

  const handleRowSelectionChanged = useCallback((ids: string[]) => {
    dispatch(selectRows(ids))
  }, [dispatch, selectRows]);

  const handleBulkRemove = () => {
    dispatch(setIsInProgress(true));
    pushBulkOperationInProgressNotification(listType, BulkOperationActionType.DELETE);
    requestBulkRemove(selectedEntities, listType)
      .catch(() => dispatch(setIsInProgress(false)));
  };

  const handleBulkTagsUpdate = () => {
    dispatch(setIsInProgress(true));
    pushBulkOperationInProgressNotification(listType, BulkOperationActionType.UPDATE_TAGS);
    requestUpdateTags(selectedEntities, tags, listType)
      .catch(() => dispatch(setIsInProgress(false)));
  };

  const handleBulkNotificationUpdate = (notificationsUpdate: BulkNotificationsUpdate) => {
    dispatch(setIsInProgress(true));
    requestBulkNotificationUpdate(selectedEntities, notificationsUpdate, listType)
      .then(() => {
        const storePayload = []
        if (notificationsUpdate.updates !== undefined) {
          storePayload.push(...selectedEntities.map(entity => ({
            notificationType: EmailNotificationActionType.OBJECT_UPDATE,
            enabled: notificationsUpdate.updates!,
            id: entity.id,
            objectType: entity.objectType
          })));
        }
        if (notificationsUpdate.comments !== undefined) {
          storePayload.push(...selectedEntities.map(entity => ({
            notificationType: EmailNotificationActionType.COMMENT_UPDATE,
            enabled: notificationsUpdate.comments!,
            id: entity.id,
            objectType: entity.objectType
          })));
        }
        if (storePayload.length > 0 ){
          showSnackbarMessage(getBulkNotificationsUpdatedMessage(selectedEntities.length, listType));
          dispatch(setNotificationForCoreEntity(storePayload));
        }
      })
      .finally(() => dispatch(setIsInProgress(false)));
  };

  const resetTagsSelection = () => {
    setTags(getCommonTags(selectedEntities));
  };

  const selectedRowIds = useMemo(() => selectedEntities.map(entity => entity.id), [selectedEntities]);

  const objectHasTags = hasObjectTags(selectedEntities)
  const removeOperationEnabled = bulkOperationsConfig.removeEnabled && permissions.hasDeletePermission;
  const updateTagsOperationEnabled = bulkOperationsConfig.updateTagsEnabled && permissions.hasUpdatePermission && objectHasTags;
  const toggleEmailNotificationsOperationEnabled = bulkOperationsConfig.toggleEmailNotificationsEnabled && hasFeature(Feature.EMAIL_NOTIFICATIONS);

  const isEnabled = (removeOperationEnabled || updateTagsOperationEnabled || toggleEmailNotificationsOperationEnabled);

  return {
    isEnabled,
    removeOperationEnabled,
    updateTagsOperationEnabled,
    toggleEmailNotificationsOperationEnabled,
    isBulkActionsVisible: selectedEntities.length > 0,
    selectedRowsIds: selectedRowIds,
    tags: tags,
    isInProgress,
    objectHasTags,
    handleRowSelectionChanged: handleRowSelectionChanged,
    handleTagsChange: (tags: Tag[]) => setTags(tags),
    toggleNotification: handleBulkNotificationUpdate,
    removeItems: handleBulkRemove,
    saveTags: handleBulkTagsUpdate,
    resetTagsSelection: resetTagsSelection
  };
};

export type BulkOperationsManager = {
  isEnabled: boolean;
  removeOperationEnabled: boolean;
  updateTagsOperationEnabled: boolean;
  toggleEmailNotificationsOperationEnabled: boolean;

  isBulkActionsVisible: boolean;
  isInProgress: boolean;
  selectedRowsIds: string[];
  objectHasTags: boolean;
  tags: Tag[];
  handleTagsChange: (tags: Tag[]) => void;
  removeItems: () => void;
  toggleNotification: (notificationsUpdate: BulkNotificationsUpdate) => void;
  saveTags: () => void;
  handleRowSelectionChanged: (rowIds: string[]) => void;
  resetTagsSelection: () => void;
}

const hasObjectTags = (entities: CoreEntityWithTags[]) => {
  return entities.length > 0 && 'tags' in entities[0];
};

const getCommonTags = (entities: CoreEntityWithTags[]) => {
  return entities[0]?.tags?.filter(tag => {
    return entities.every(row => row.tags?.find(q => String(q.id) === String(tag.id)));
  }) || [];
};
