import React from 'react';
import {AccurityDetail as AccurityDetailComponent, DetailHeader, StaticField} from 'ts-components';
import {MAX_DETAIL_WIDTH, MIN_DETAIL_WIDTH} from '../../detail/components/AccurityDetail';
import {useIsCloudEnvironment, useIsSwaggerEnabled} from '../../appSettings/hooks/environmentInfoHooks';
import {useProductTermsSettings} from '../../productTerms/hooks/productTermsHook';
import {useHasFeature} from '../../userSettings/hooks/features';
import {Feature} from '../../userSettings/features/features';
import DetailTopBar from '../../detail/components/DetailTopBar';

const ACCURITY_LINK = '<a href=\'https://www.accurity.ai\' target=\'_blank\'>Accurity Home Page</a>';
const createProductTermsLink = (link: string) => `<a href="${link}" target="_blank">Product Terms</a>`;
const PRIVACY_POLICY = '<a href=\'https://www.accurity.ai/marketing-consent.pdf\' target=\'_blank\'>Privacy Policy</a>';
const COOKIE_POLICY = '<a href=\'https://www.accurity.ai/cookie-policy.pdf\' target=\'_blank\'>Cookie Policy</a>';
const SECURITY_POLICY = '<a href=\'https://www.accurity.ai/security-policy.pdf\' target=\'_blank\'>Security Policy</a>';
const BROWSER_EXTENSION = '<a href=\'https://chrome.google.com/webstore/detail/browser-dictionary/lphoojilnnpafhhglindjjpohkjffmbh\' target=\'_blank\'>Browser Extension (for Chrome/Edge)</a>';
const SWAGGER_LINK = '<a href="/swagger-ui/index.html" target="_blank">REST API Documentation</a>'

interface AboutDetailProps {
  applicationName: string;
  applicationVersion: string;
}

const AboutDetail = ({ applicationName, applicationVersion }: AboutDetailProps) => {
  const isCloudEnvironment = useIsCloudEnvironment();
  const hasFeature = useHasFeature();
  const { productTermsLink } = useProductTermsSettings();
  const installationType = isCloudEnvironment ? 'SaaS' : 'On-premise';
  const isSwaggerEnabled = useIsSwaggerEnabled();

  return (
    <AccurityDetailComponent
      minWidth={MIN_DETAIL_WIDTH}
      maxWidth={MAX_DETAIL_WIDTH}
    >
      <DetailHeader iconName={'accurityLogo'}>
        <StaticField
          name={'aboutAppTitle'}
          label={'About'}
          value={applicationName}
        />
        <DetailTopBar allowComments={false} allowEmailNotification={false}/> 
      </DetailHeader>
      <StaticField
        name={'version'}
        label={'Application Version'}
        value={`${applicationVersion} (${installationType})`}
      />
      {hasFeature(Feature.BROWSER_EXTENSION) && <StaticField
        name={'browser-extension'}
        format={'HTML'}
        value={BROWSER_EXTENSION}
      />}
      {isSwaggerEnabled && <StaticField
          name={'swagger-link'}
          format={'HTML'}
          value={SWAGGER_LINK}
      />}
      <StaticField
        name={'applicationInformation'}
        label={'Application Information'}
        format={'HTML'}
        value={ACCURITY_LINK}
      />
      <StaticField
        name={'productTerms'}
        label={''}
        format={'HTML'}
        value={createProductTermsLink(productTermsLink)}
      />
      {isCloudEnvironment && <StaticField
        name={'privacyPolicy'}
        label={''}
        format={'HTML'}
        value={PRIVACY_POLICY}
      />}
      {isCloudEnvironment && <StaticField
        name={'cookiePolicy'}
        label={''}
        format={'HTML'}
        value={COOKIE_POLICY}
      />}
      {isCloudEnvironment && <StaticField
        name={'securityPolicy'}
        label={''}
        format={'HTML'}
        value={SECURITY_POLICY}
      />}
      <StaticField
        name={'copyright'}
        label={`Copyright © ${new Date().getFullYear()} Simplity. All Rights Reserved.`}
        value={''}
      />
    </AccurityDetailComponent>
  );
};

export default AboutDetail;
