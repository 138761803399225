import React from 'react';
import { FormikProps } from 'formik';
import { DetailInlineGroup, Tooltip } from 'ts-components';
import { AccurityCoreEntity, AccurityFilter } from '../../types/accurityTypes';
import { CustomProperty } from '../customProperty/types/customPropertyTypes';
import { getSupportiveCustomPropertyValueFieldLabel } from '../customPropertyUtils';
import { createReferenceFieldFilter } from '../../referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../detail/formik/ReferenceFieldWithFormik';
import { PROCESS_ICON, PROCESS_LABEL, PROCESS_TYPE } from '../../../businessGlossary/process/types/processTypes';
import { PROCESS_STEP_ICON, PROCESS_STEP_LABEL, PROCESS_STEP_TYPE, ProcessStepReference } from '../../../businessGlossary/process/step/types/processStepTypes';

interface ProcessStepCustomPropertyValueFieldProps {
  formik: FormikProps<AccurityCoreEntity>;
  fieldName: string;
  customProperty: CustomProperty;
  additionalFilters?: AccurityFilter[];
  inherited?: boolean;
  tooltip?: Tooltip;
}

const ProcessStepCustomPropertyValueField = ({ formik, fieldName, customProperty, additionalFilters = [], inherited, tooltip }: ProcessStepCustomPropertyValueFieldProps) => {

  const referenceFieldProps = formik.getFieldProps(fieldName + '.reference').value;
  const processReferenceFieldProps = formik.getFieldProps(fieldName + '.processReference').value;

  const showProcessTooltip = !!processReferenceFieldProps;
  const showDefaultTooltip = !!referenceFieldProps;

  const processId = processReferenceFieldProps?.id;

  additionalFilters.push(
    createReferenceFieldFilter('process.id', processId),
  );

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
      >
        <ReferenceFieldWithFormik
          name={fieldName + '.processReference'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, PROCESS_LABEL)}
          objectType={PROCESS_TYPE}
          icon={PROCESS_ICON}
          tooltip={tooltip}
          setValue={(process) => {
            formik.setFieldValue(fieldName + '.processReference', process);
            formik.setFieldValue(fieldName + '.reference', null);
          }}
          showDefaultTooltip={showProcessTooltip}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
        <ReferenceFieldWithFormik<ProcessStepReference>
          name={fieldName + '.reference'}
          icon={PROCESS_STEP_ICON}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, PROCESS_STEP_LABEL)}
          objectType={PROCESS_STEP_TYPE}
          additionalFilters={additionalFilters}
          setValue={(processStep) => {
            if (!processId) {
              formik.setFieldValue(fieldName + '.processReference', processStep?.process);
            }
            formik.setFieldValue(fieldName + '.reference', processStep);
          }}
          tooltip={tooltip}
          showDefaultTooltip={showDefaultTooltip}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
      </DetailInlineGroup>
    </>
  )
};

export default ProcessStepCustomPropertyValueField;