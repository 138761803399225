import React from 'react';
import { useFormikContext } from 'formik';
import { AccurityIconButton } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { AccurityCoreEntity } from '../../types/accurityTypes';

const CloseDetailWithFormik = () => {
  const accurityNavigation = useAccurityNavigation();
  const formik = useFormikContext<AccurityCoreEntity>();

  // TODO: formik should be undefined feature/26001
  if (formik?.status.isVersionBrowser) {
    return null;
  }

  return (
    <AccurityIconButton
        iconName="mui:Close"
        size="small"
        onClick={() => accurityNavigation.closeDetail()}
    />
  );
};

export default CloseDetailWithFormik;
