import React from 'react';
import ConnectionGroup from './ConnectionGroup';
import UsernamePasswordGroup from './UsernamePasswordGroup';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';

const MongoDbDataSourceFields = () => (
  <>
    <ConnectionGroup/>
    <SingleLineFieldWithFormik
      name={'authenticationDatabase'}
      label={'Authentication Database'}
    />
    <UsernamePasswordGroup/>
  </>
);

export default MongoDbDataSourceFields;
