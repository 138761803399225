import React from 'react';
import { BulkOperationsManager } from '../hooks/bulkOperationsHook';
import AccurityTagField from '../../tagField/components/AccurityTagField';
import { useEffectOnMountOnly } from '../../hooks/useEffectOnMountOnly';

interface TagsEditDialogContentProps {
  bulkOperationsManager: BulkOperationsManager;
}

export const TagsEditDialogContent = ({ bulkOperationsManager }: TagsEditDialogContentProps) => {

  useEffectOnMountOnly(() => {
    // resets tags selection on unmount
    return () => bulkOperationsManager.resetTagsSelection();
  });

  return (
    <AccurityTagField
      value={bulkOperationsManager.tags}
      name={'tags'}
      label={'Tags'}
      setValue={bulkOperationsManager.handleTagsChange}
    />
  );
};
