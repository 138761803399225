import { AccurityCoreEntity } from '../../types/accurityTypes';
import { AccurityFetchError } from '../../rest/types';
import { AppThunk } from '../../redux/types';
import { requestSaveUserSetting } from '../../userSettings/rest/userSettingsApi';
import { setDetailWidthInStore } from '../../userSettings/redux/userSettingsSlice';
import { DETAIL_WIDTH_SETTING_KEY } from '../../userSettings/types/types';
import { get } from 'lodash';
import { ERROR_FIELD_PATH_SEPARATOR } from '../types/types';

export const detailSaveWidth = (detailWidth: number): AppThunk => async dispatch => {
  requestSaveUserSetting(DETAIL_WIDTH_SETTING_KEY, detailWidth)
    .then(() => {
      dispatch(setDetailWidthInStore(detailWidth));
    })
};

export const setRedUnderlinesOnDetail = (saveError: AccurityFetchError,
                                         setFieldError: (field: string, errorMessage: string) => void,
                                         alsoSetErrorMessage?: boolean) => {

  if (Array.isArray(saveError.errorBody)) {
    saveError.errorBody.forEach(responseElement =>
      responseElement.path?.split(ERROR_FIELD_PATH_SEPARATOR).forEach(path =>
        setFieldError(path, alsoSetErrorMessage ? responseElement.message.body : ' ')
      )
    );
  }
};

export const coreEntityAsFormData = (coreEntity: AccurityCoreEntity): FormData => {
  const formDataBody = new FormData();
  Object.keys(coreEntity).forEach(key => {
    const value = get(coreEntity, key);
    if (value instanceof File) {
      formDataBody.append('file', value)
    }
  });
  const blob = new Blob(
    [JSON.stringify(coreEntity)],
    { type: 'application/json' }
  );
  formDataBody.append('dto', blob);

  return formDataBody;
}
