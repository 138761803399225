import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { Column, NumericFormat } from 'ts-components';
import { ATTRIBUTE_LABEL } from '../types/attributeTypes';
import { ENTITY_LABEL, EntityTypeOptions } from '../../entity/types/entityTypes';
import { ATTRIBUTE_DEFINITION_LABEL, AttributeDefinitionTypeOptions } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useEmbeddedEntityColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();

  const embeddedEntityColumns: Column[] = [
    commonColumns.id({ name: 'entity.id', label: 'ID (E)' }),
    columnFactory.createTextColumn('entity.name', 'Name (E)'),
    columnFactory.createTextColumn('entity.basedOnBusinessTerm.name', 'Based on (E)', { hidden: true }),
    columnFactory.createEnumColumn('entity.entityType', 'Type (E)', EntityTypeOptions, { hidden: true }),
    columnFactory.createTagsColumn('entity.tags', 'Tags (E)', 'entity.tags.id', navigationController, { hidden: true }),
    columnFactory.createTextColumn('entity.parent.name', 'Parent (E)', { hidden: true }),
    columnFactory.createNumberColumn('entity.childrenCounts.entitiesCount', 'Children (E)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('entity.childrenCounts.attributesCount', 'Attributes (E)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('entity.childrenCounts.referenceAttributeTargetEntitiesCount', 'Reference Attributes (E)',
      { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [columnFactory.createNumberColumn('entity.childrenCounts.businessModelMappingsCount', 'Business Model Mappings (E)',
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ENTITIES) ?
      [columnFactory.createNumberColumn('entity.childrenCounts.processMappingsCount', 'Process Mappings (E)',
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime({ name: 'entity.createdDate', label: 'Created at (E)' }),
    commonColumns.createdBy({ name: 'entity.createdBy.name', label: 'Created by (E)' }),
    commonColumns.lastChangedTime({ name: 'entity.changedDate', label: 'Updated at (E)' }),
    commonColumns.lastChangedBy({ name: 'entity.changedBy.name', label: 'Updated by (E)' }),
    commonColumns.status({ name: 'entity.status.name', label: 'Status (E)' }),
  ].map(column => {
    return { ...column, group: ENTITY_LABEL };
  });

  return useValidColumns(embeddedEntityColumns, GlossaryCustomPropertyObjectType.ENTITY, ENTITY_LABEL, 'entity', ' (E)');
};

export const useEmbeddedAttributeDefinitionColumns = () => {
  const navigationController = useAccurityNavigation();

  const embeddedAttributeDefinitionColumns: Column[] = [
    commonColumns.id({ name: 'attributeDefinition.id', label: 'ID (AD)' }),
    columnFactory.createTextColumn('attributeDefinition.name', 'Name (AD)'),
    columnFactory.createTextColumn('attributeDefinition.basedOnBusinessTerm.name', 'Based on (AD)', { hidden: true }),
    columnFactory.createTextColumn('attributeDefinition.parent.name', 'Component of (AD)', { hidden: true }),
    columnFactory.createEnumColumn('attributeDefinition.attributeDefinitionType', 'Type (AD)', AttributeDefinitionTypeOptions),
    columnFactory.createTextColumn('attributeDefinition.compositeType.name', 'Composite Type (AD)'),
    columnFactory.createTextColumn('attributeDefinition.targetEntity.name', 'Target Entity (AD)'),
    columnFactory.createTextColumn('attributeDefinition.valueType.name', 'Value Type (AD)'),
    columnFactory.createTagsColumn('attributeDefinition.tags', 'Tags (AD)', 'attributeDefinition.tags.id', navigationController, { hidden: true }),
    columnFactory.createNumberColumn('attributeDefinition.childrenCounts.attributesCount', 'Attributes (AD)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('attributeDefinition.childrenCounts.attributeDefinitionsCount', 'Components (AD)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC),

    commonColumns.createdTime({ name: 'attributeDefinition.createdDate', label: 'Created at (AD)' }),
    commonColumns.createdBy({ name: 'attributeDefinition.createdBy.name', label: 'Created by (AD)' }),
    commonColumns.lastChangedTime({ name: 'attributeDefinition.changedDate', label: 'Updated at (AD)' }),
    commonColumns.lastChangedBy({ name: 'attributeDefinition.changedBy.name', label: 'Updated by (AD)' }),
    commonColumns.status({ name: 'attributeDefinition.status.name', label: 'Status (AD)' }),
  ].map(column => {
    return { ...column, group: ATTRIBUTE_DEFINITION_LABEL };
  });

  return useValidColumns(embeddedAttributeDefinitionColumns, GlossaryCustomPropertyObjectType.ATTRIBUTE_DEFINITION, ATTRIBUTE_DEFINITION_LABEL, 'attributeDefinition', ' (AD)');
};

export const useAttributeListColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const attributeListColumns: Column[] = [
    commonColumns.id({ label: 'ID (A)' }),
    columnFactory.createBooleanColumn('inherited', 'Inherited (A)'),
    columnFactory.createTextColumn('name', 'Name (A)', { hidden: true }),
    columnFactory.createTextColumn('description.plainTextValue', 'Description (A)', { hidden: true, sortable: !isOracle }),
    ...(hasFeature(Feature.CALCULATION_RULES) ? [columnFactory.createTextColumn('calculationRule.plainTextValue', 'Calculation Rule (A)', {
      hidden: true,
      sortable: !isOracle
    })] : []),
    columnFactory.createCollectionColumn('requirements.name', 'Requirements (A)', { hidden: true }),
    commonColumns.tags(navigationController, { hidden: true, label: 'Tags (A)' }),
    columnFactory.createTextColumn('parent.name', 'Parent (A)', { hidden: true }),
    columnFactory.createNumberColumn('childrenCounts.attributesCount', 'Children (A)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    ...(hasFeature(Feature.BUSINESS_RULES) ? [columnFactory.createNumberColumn('childrenCounts.businessRulesCount', 'Business Rules (A)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.CALCULATION_RULES) ? [columnFactory.createNumberColumn('childrenCounts.calculationRulesCount', 'Calculation Rules (A)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ? [columnFactory.createNumberColumn('childrenCounts.businessModelMappingsCount', 'Business Model Mappings (A)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ATTRIBUTES) ? [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', 'Process Mappings (A)', { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime({ label: 'Created at (A)' }),
    commonColumns.createdBy({ label: 'Created by (A)' }),
    commonColumns.lastChangedTime({ label: 'Updated at (A)' }),
    commonColumns.lastChangedBy({ label: 'Updated by (A)' }),
    commonColumns.status({ label: 'Status (A)' }),
    commonColumns.objectUpdatesNotification({ label: 'Notifications - Updates (A)' }),
    commonColumns.commentsNotification({ label: 'Notifications - Comments (A)' }),
    commonColumns.commentsCount({ label: 'Comments (A)' }),
  ].map(column => {
    return { ...column, group: ATTRIBUTE_LABEL };
  });

  return useValidColumns(attributeListColumns, GlossaryCustomPropertyObjectType.ATTRIBUTE, ATTRIBUTE_LABEL, undefined, ' (A)');

};
