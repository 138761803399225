import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { DiagramObject, DiagramObjectType } from 'ts-components';

import { useAccurityNavigation } from '../../common/navigation/hooks';
import { BackendDiagramEntity, BackendDiagramLink, BackendDiagramResponse, DiagramLineageType } from './types/types';
import { requestFetchDiagramEntities } from './rest/diagramApi';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_TYPE } from '../businessTerm/types/businessTermTypes';
import { DATA_SET_ICON, DATA_SET_TYPE } from '../dataSet/types/dataSetTypes';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_TYPE } from '../dataStructure/types/dataStructureTypes';
import { DATA_FIELD_ICON, DATA_FIELD_TYPE } from '../dataField/types/dataFieldTypes';
import { TECHNICAL_DATA_MAPPING_ICON, TECHNICAL_DATA_MAPPING_TYPE } from '../technicalModelMapping/types/technicalDataMappingTypes';
import { ENTITY_ICON, ENTITY_TYPE } from '../entity/types/entityTypes';
import { ATTRIBUTE_ICON, ATTRIBUTE_TYPE } from '../attribute/types/attributeTypes';
import { BUSINESS_MODEL_MAPPING_ICON, BUSINESS_MODEL_MAPPING_TYPE } from '../businessModelMapping/types/businessModelMappingTypes';

const createIdFromDiagramEntity = (diagramEntity: BackendDiagramEntity) => `${diagramEntity.depth}-${diagramEntity.objectType}-${diagramEntity.id}`;

const createLinkById = (diagramLink: BackendDiagramLink) => (
  {
    nodeId: createIdFromDiagramEntity(diagramLink.linkedEntity),
    label: diagramLink.linkLabel,
  }
);

const createNodeMetaFromBusinessGlossaryEntity = (diagramEntity: BackendDiagramEntity): { label: string, icon: string, type: DiagramObjectType } => {
  switch (diagramEntity.objectType) {
    case BUSINESS_TERM_TYPE:
      return {
        label: diagramEntity.name,
        icon: BUSINESS_TERM_ICON,
        type: DiagramObjectType.NODE,
      };
    case DATA_SET_TYPE:
      return {
        label: diagramEntity.name,
        icon: DATA_SET_ICON,
        type: DiagramObjectType.GROUP,
      };
    case DATA_STRUCTURE_TYPE:
      return {
        label: diagramEntity.name,
        icon: DATA_STRUCTURE_ICON,
        type: DiagramObjectType.NODE,
      };
    case DATA_FIELD_TYPE:
      return {
        label: diagramEntity.name,
        icon: DATA_FIELD_ICON,
        type: DiagramObjectType.NODE,
      };
    case TECHNICAL_DATA_MAPPING_TYPE:
      return {
        label: diagramEntity.id,
        icon: TECHNICAL_DATA_MAPPING_ICON,
        type: DiagramObjectType.MINI_NODE,
      };
    case BUSINESS_MODEL_MAPPING_TYPE:
      return {
        label: diagramEntity.id,
        icon: BUSINESS_MODEL_MAPPING_ICON,
        type: DiagramObjectType.MINI_NODE,
      };
    case ENTITY_TYPE:
      return {
        label: diagramEntity.name,
        icon: ENTITY_ICON,
        type: DiagramObjectType.NODE,
      };
    case ATTRIBUTE_TYPE:
      return {
        label: diagramEntity.name.substr(diagramEntity.name.indexOf('.') + 1),
        icon: ATTRIBUTE_ICON,
        type: DiagramObjectType.NODE,
      };
  }
  throw new Error('Could not create Node Meta for Entity with type ' + diagramEntity.objectType);
};

export const useBackendDiagramDataFor = (objectType: string, id: string, lineageType: DiagramLineageType, diagramOpenedAt: number) => {
  const { openDetailWithObject } = useAccurityNavigation();
  const [diagramResponse, setDiagramResponse] = useState<BackendDiagramResponse | null>(null);

  // Fetch Entities and Diagram info from backend
  useEffect(() => {
    setDiagramResponse(null);
    requestFetchDiagramEntities(objectType, id, lineageType)
      .then(setDiagramResponse);
  }, [id, objectType, diagramOpenedAt]);

  if (diagramResponse === null) {
    return null;
  }

  // transform BG entities into DiagramObjects
  const unsortedNodes: DiagramObject[] = diagramResponse.diagramEntities.map(diagramEntity => ({
    id: createIdFromDiagramEntity(diagramEntity),
    depth: diagramEntity.depth,
    groupParentNode: diagramEntity.groupParent ? createIdFromDiagramEntity(diagramEntity.groupParent) : undefined,
    links: diagramEntity.linkedEntities.map(createLinkById),
    hasCyclicLink: diagramEntity.hasCyclicLink,
    onClick: () => openDetailWithObject(diagramEntity.objectType, diagramEntity.id),
    ...createNodeMetaFromBusinessGlossaryEntity(diagramEntity),
  }));
  const nodes = sortBy(unsortedNodes, 'label');
  const startNodeId = createIdFromDiagramEntity(diagramResponse.startEntity);
  const columnCount = diagramResponse.columnCount;
  const filterableLinkTypes = diagramResponse.filterableLinkTypes;

  // Return everything in shape ready to be used by Diagram
  return {
    nodes,
    startNodeId,
    columnCount,
    filterableLinkTypes,
  };
};
