import React from 'react';
import { ActionField } from 'ts-components';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';

interface OpenDiagramButtonProps {
  icon: string;
  diagramType: string;
  diagramId: string;
}

const OpenDiagramButton = ({ icon, diagramType, diagramId }: OpenDiagramButtonProps) => {
  const { openDiagram } = useAccurityNavigation();
  const formikContext = useFormikContext<AccurityCoreEntity>();

  if (formikContext.status.isVersionBrowser || !formikContext.status.isUpdateDetail) {
    return null;
  }

  return (
    <ActionField
      label={'Open Diagram'}
      iconName={icon}
      onClick={() => openDiagram(diagramType, diagramId)}
      disabled={formikContext.status.isEntityInDetailDeleted}
    />
  );
};

export default OpenDiagramButton;
