import { cleanStore } from '../../redux/rootStore';
import { BrowserExtensionUtils } from '../../browserExtension/utils';
import { KeycloakClient } from '../../keycloak/KeycloakClient';
import { disconnectWebsocketClient } from '../../websocket/websocketConnectionHandler';

export const logout = () => {
  KeycloakClient.logout()
    .then(() => {
      cleanStore();
      disconnectWebsocketClient();
      BrowserExtensionUtils.notifyUserHasBeenLoggedOut();
    });
};
