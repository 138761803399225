import React from 'react';
import { CustomProperty, CustomPropertyInheritanceObject } from './customProperty/types/customPropertyTypes';
import { CustomPropertyGroup } from './customPropertyGroup/types/customPropertyGroupTypes';
import { CollapsingGroup, Tooltip } from 'ts-components';
import { useCustomPropertiesFromFormikContextForObjectType } from './customPropertyHooks';
import CustomPropertyValueField from './customPropertyValue/CustomPropertyValueField';

export type CustomPropertyFieldsContainerProps = {
  forObjectType: string;
  expandedByDefaultOverwrite?: boolean;
  inheritance?: CustomPropertyInheritanceObject;
}

const CustomPropertyFieldsContainer = ({ forObjectType, expandedByDefaultOverwrite, inheritance }: CustomPropertyFieldsContainerProps) => {
  const [customProperties, customPropertyGroups] = useCustomPropertiesFromFormikContextForObjectType(forObjectType);

  const createTooltip = (cpg: CustomPropertyGroup): Tooltip => ({
    value: cpg.name,
    description: cpg.description?.plainTextValue
  });

  return (
    <>
      {customPropertyGroups.map((cpg: CustomPropertyGroup) => (
        <CollapsingGroup
          key={`custom-property-group-${cpg.id}`}
          name={cpg.id}
          title={cpg.name}
          expandable={true}
          expandedByDefault={typeof expandedByDefaultOverwrite !== 'undefined' ? expandedByDefaultOverwrite : !cpg.collapsedByDefault}
          tooltip={cpg.description?.plainTextValue ? createTooltip(cpg) : undefined}
        >
          {customProperties
            .filter((cp: CustomProperty) => cp.customPropertyGroup.id === cpg.id)
            .map((cp: CustomProperty) =>
              <CustomPropertyValueField
                key={`custom-property-${cp.id}`}
                customProperty={cp}
                inherited={inheritance && inheritance[cp.id]}
                expandedByDefault={typeof expandedByDefaultOverwrite !== 'undefined' ? expandedByDefaultOverwrite : false}
              />
            )}
        </CollapsingGroup>
      ))}
    </>
  );
};


export default CustomPropertyFieldsContainer;
