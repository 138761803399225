import { AccurityFilter, AccurityReference, AccuritySort } from '../types/accurityTypes';

export const DEFAULT_REFERENCE_FILTER_PROPERTY = 'name';

export interface AccurityReferenceFieldSearch {
  filters: AccurityFilter[];
  sort: AccuritySort;
  maxResults: number;
}

export interface AccurityReferenceFieldSearchResult<T = AccurityReference> {
  rows: T[];
  size: number;
}
