import React from 'react';
import { useBusinessGlossaryCustomPropertyTargetObjectTypes, useBusinessGlossaryCustomPropertyTypes } from './types';
import CustomPropertyList from '../../common/customProperties/customProperty/list/CustomPropertyList';
import { useHasFeature } from '../../common/userSettings/hooks/features';
import { Feature } from '../../common/userSettings/features/features';
import { AdvancedCustomPropertyPropertyTypeOptions, CustomPropertyPropertyTypeOptions } from '../../common/customProperties/customProperty/types/customPropertyTypes';

const GlossaryCustomPropertyList = () => {
  const glossaryCustomPropertyObjectTypes = useBusinessGlossaryCustomPropertyTypes();
  const hasFeature = useHasFeature();

  const customPropertyPropertyTypeOptions = hasFeature(Feature.ADVANCED_CUSTOM_PROPERTIES) ? AdvancedCustomPropertyPropertyTypeOptions : CustomPropertyPropertyTypeOptions;
  const customPropertyTargetObjectTypeOptions = useBusinessGlossaryCustomPropertyTargetObjectTypes(true);

  return (
    <CustomPropertyList
      customPropertyObjectTypeOptions={glossaryCustomPropertyObjectTypes}
      customPropertyPropertyTypeOptions={customPropertyPropertyTypeOptions}
      customPropertyTargetObjectTypeOptions={customPropertyTargetObjectTypeOptions}
    />
  );
};

export default GlossaryCustomPropertyList;
