import React from 'react';
import { ColumnProfilingNotificationCenterNotification } from './types';
import { NotificationCenterCard, NotificationCenterCardProps, ProgressIndicator } from 'ts-components';
import { useDispatch } from 'react-redux';
import { dismissNotification } from './notificationCenterSlice';
import { COLUMN_PROFILING_TYPE, ProfileStatus } from '../columnProfiling/types/types';
import { usePermissionsForObjectType } from '../userSettings/hooks/permissions';
import { useAccurityNavigation } from '../navigation/hooks';

export interface ColumnProfilingNotificationCardProps {
  notification: ColumnProfilingNotificationCenterNotification;
  closeNotificationCenter: () => void;
}

const ColumnProfilingNotificationCard = ({ notification, closeNotificationCenter }: ColumnProfilingNotificationCardProps) => {
  const dispatch = useDispatch();
  const permission = usePermissionsForObjectType(COLUMN_PROFILING_TYPE);
  const navigation = useAccurityNavigation();

  const canSeeResults = permission.hasLaunchPermission;
  const isRunning = notification.profileStatus === ProfileStatus.RUNNING;

  let titleIconColor: NotificationCenterCardProps['titleIconColor'];
  if (notification.profileStatus === ProfileStatus.FAILED) {
    titleIconColor = 'error';
  } else if (notification.profileStatus === ProfileStatus.FINISHED) {
    titleIconColor = 'success';
  }

  const onDismiss = notification.cannotDismiss ? undefined : () => dispatch(dismissNotification(notification.id));

  const actionButtonTitle = canSeeResults && notification.profileStatus === ProfileStatus.FINISHED
    ? 'See Results'
    : undefined;

  const handleActionClick = canSeeResults && notification.profileStatus === ProfileStatus.FINISHED ? () => {
    navigation.openDataFieldProfilingCanvas(notification.dataFieldId.toString());
    dispatch(dismissNotification(notification.id));
    closeNotificationCenter();
  } : undefined;

  return (
    <NotificationCenterCard
      title={notification.title}
      titleIcon={'dataProfile'}
      titleIconColor={titleIconColor}
      date={notification.date}
      unread={!notification.wasRead}
      onDismiss={onDismiss}
      actionText={actionButtonTitle}
      actionClick={handleActionClick}
    >
      <div style={{ width: '100%' }}>
        {isRunning && <div style={{ margin: '4px 0' }}><ProgressIndicator/></div>}
        {notification.body}
      </div>
    </NotificationCenterCard>
  );
};

export default ColumnProfilingNotificationCard;
