import { useDispatch, useSelector } from 'react-redux';
import { requestUserSettingsFetch } from '../rest/userSettingsApi';
import { UserSettings } from '../types/types';
import { addCoreEntityToStore } from '../../redux/commonReducers';
import { setUserSettingsInStore } from '../redux/userSettingsSlice';
import { connectWebsocketClient } from '../../websocket/websocketConnectionHandler';

export const useLoadUserSettings = () => {
  const dispatch = useDispatch();

  return () => requestUserSettingsFetch()
    .then((userSettings: UserSettings) => {
      const fetchedAt = Date.now();
      userSettings.customPropertyGroups?.forEach(cpg => {
        dispatch(addCoreEntityToStore({ coreEntity: cpg, fetchedAt: fetchedAt }));
      });
      userSettings.customProperties?.forEach(cp => {
        dispatch(addCoreEntityToStore({ coreEntity: cp, fetchedAt: fetchedAt }));
      });
      dispatch(setUserSettingsInStore(userSettings));
      connectWebsocketClient(userSettings.user.email);
      return userSettings;
    });
};

export const useAreUserSettingsLoaded = () => {
  return useSelector((state: any) => !!state.userSettings.id)
};

export const useUserTimezone = () => {
  return useSelector((state: any) => state.userSettings.timezone);
};
