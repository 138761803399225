import React from 'react';
import { AccurityDivider, ActionField } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { BUSINESS_MODEL_MAPPING_DIAGRAM_TYPE } from '../../../businessGlossary/dataLineageDiagram/types/types';

interface BusinessModelMappingDiagramButtonProps {
  objectType?: string;
  id?: string;
  disabled?: boolean;
}

const BusinessModelMappingDiagramButton = ({ objectType, id, disabled }: BusinessModelMappingDiagramButtonProps) => {
  const { openDiagram } = useAccurityNavigation();
  const hasFeature = useHasFeature();
  const formik = useFormikContext<AccurityCoreEntity>();

  if (!hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) || formik.status.isVersionBrowser || !formik.status.isUpdateDetail) {
    return null;
  }

  return (
    <>
      <AccurityDivider/>
      <ActionField
        label={'View Business Model Lineage'}
        iconName={'dataLineage'}
        onClick={id && objectType ? () => openDiagram(BUSINESS_MODEL_MAPPING_DIAGRAM_TYPE, id, objectType) : undefined}
        disabled={disabled}
      />
    </>
  );
};

export default BusinessModelMappingDiagramButton;
