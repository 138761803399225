import React from 'react';
import { CardList, TaggedEntity } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { CardListFetchFunction, useCardListFetchData } from '../hooks/useCardListFetchData';
import { AccurityReference } from '../../types/accurityTypes';

type AccurityCardListProps = {
  fetchFn: CardListFetchFunction;
  searchValue: string;
}

const AccurityCardList = ({ fetchFn, searchValue }: AccurityCardListProps) => {
  const navigation = useAccurityNavigation();
  const listData = useCardListFetchData(fetchFn, searchValue);

  const handleCardClick = (card: TaggedEntity) => {
    if (card.relatedObjectType && card.relatedObjectId) {
      navigation.openDetailWithObject(card.relatedObjectType, card.relatedObjectId.toString())
    } else {
      navigation.openDetailWithObject(card.objectType, card.id.toString())
    }
  };

  const openChip = (reference?: AccurityReference) => {
    if (reference) {
      navigation.openDetailWithObject(reference.objectType, reference.id);
    }
  };

  return (
    <CardList
      cards={listData.rows}
      totalItems={listData.existingObjects}
      loadMoreFunction={listData.loadMore}
      onCardClick={handleCardClick}
      onTagClick={tag => navigation.openListForTag(tag.name)}
      onRelatedObjectClick={relObject => navigation.openDetailWithObject(relObject.objectType, relObject.id.toString())}
      onChipClick={openChip}
    />
  )
};

export default AccurityCardList;
