import React from 'react';
import { useAccurityNavigation } from './hooks';
import { AccurityAppBar, DemoScheduler } from 'ts-components';
import UserPopup from '../userPopup/UserPopup';
import { useSelector } from 'react-redux';
import Analytics from '../analytics';
import GlobalPinFilters from './GlobalPinFilters';
import { AppSettingsState } from '../appSettings/types/types';
import { UserSettingsState } from '../userSettings/types/types';
import AccurityGlobalSearch from '../globalSearch/components/AccurityGlobalSearch';
import { matchPath } from 'react-router-dom';
import { routes } from '../../businessGlossary/navigation/routes';
import { useHasFeature } from '../userSettings/hooks/features';
import { Feature } from '../userSettings/features/features';
import FeatureChecker from '../userSettings/components/FeatureChecker';

const CALENDLY_EMBED_URL = 'https://calendly.com/accurity/accurity-platform-discovery-call?text_color=061220&primary_color=6D8CE2';

interface AccurityNavigationProps {
  headerLogo: string;
  appBarItems: React.ReactNode;
  mainMenu: React.ReactNode;
  toggleMainMenuExpanded: () => void;
  isMenuExpanded: boolean;
  children: React.ReactNode;
}

const AccurityNavigation = ({
                              headerLogo,
                              appBarItems,
                              mainMenu,
                              toggleMainMenuExpanded,
                              isMenuExpanded,
                              children
                            }: AccurityNavigationProps) => {

  const hasFeature = useHasFeature();
  const isCustomLogoEnabled = hasFeature(Feature.CUSTOM_LOGO);

  const navigationController = useAccurityNavigation();
  const isWelcomeScreen = matchPath(navigationController.getCurrentPath(), { path: routes.welcomeScreenPath, exact: true });
  const currentUserFirstName = useSelector((state: any) => state.userSettings.firstName);
  const currentUserLastName = useSelector((state: any) => state.userSettings.lastName);
  const currentUserEmail = useSelector((state: any) => state.userSettings.email);
  const customLogoBase64Image = useSelector((state: any) => state.appSettings.base64image);
  const customLogoLink = useSelector((state: { appSettings: AppSettingsState }) => state.appSettings.customLogoLink);
  const globalPinFilters = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.globalPinFilters);
  const trackAction = Analytics.createActionTracker('Menu');

  const appLogoClickHandler = () => {
    navigationController.openWelcomeScreen();
    trackAction('Click', 'App logo');
  };

  const additionalLeftContent = (
    <>
      <span key='demo'>
        <FeatureChecker featureId={Feature.SCHEDULE_DEMO}>
          <DemoScheduler
            buttonLabel={'GET YOUR DEMO'}
            calendlyEmbedUrl={CALENDLY_EMBED_URL}
            prefillName={`${currentUserFirstName} ${currentUserLastName}`}
            prefillEmail={currentUserEmail}
          />
        </FeatureChecker>
      </span>
      <span key='filters'>
        <GlobalPinFilters pinFilters={globalPinFilters}/>
      </span>
    </>
  )

  return (
    <>
      <AccurityAppBar
        onMenuClick={toggleMainMenuExpanded}
        isMenuExpanded={isMenuExpanded}
        appLogoSrc={headerLogo}
        onAppLogoClick={appLogoClickHandler}
        customLogoSrc={isCustomLogoEnabled ? customLogoBase64Image : undefined}
        customLogoLink={isCustomLogoEnabled ? customLogoLink : undefined}
        additionalLeftContent={additionalLeftContent}
      >
        {!isWelcomeScreen && <AccurityGlobalSearch variant='right'/>}
        {appBarItems}
        <UserPopup userFirstName={currentUserFirstName} userLastName={currentUserLastName}/>
      </AccurityAppBar>
      <div className={'applicationContent__container'}>
        <div className={'mainMenu__container'}>
          {mainMenu}
        </div>
        {children}
      </div>
    </>
  );
};

export default AccurityNavigation;
