import React from 'react';
import { ActionField } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { DataField } from '../../../businessGlossary/dataField/types/dataFieldTypes';
import { useHasProfilingData } from '../hooks/hasProfilingData';
import { useHasFeature } from '../../userSettings/hooks/features';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { Feature } from '../../userSettings/features/features';
import { usePermissionsForObjectType } from '../../userSettings/hooks/permissions';
import { COLUMN_PROFILING_TYPE } from '../types/types';

interface ShowColumnProfilingButtonProps {
  dataField?: DataField,
}

const ColumnProfilingResultButton = ({ dataField }: ShowColumnProfilingButtonProps) => {

  const navigation = useAccurityNavigation();
  const hasProfilingData = useHasProfilingData(dataField);
  const hasFeature = useHasFeature();
  const permissions = usePermissionsForObjectType(COLUMN_PROFILING_TYPE);
  const formik = useFormikContext<AccurityCoreEntity>();

  if (
    !hasFeature(Feature.COLUMN_PROFILING) ||
    !permissions.hasReadPermission ||
    formik.status.isVersionBrowser ||
    !formik.status.isUpdateDetail ||
    !dataField
  ) {
    return null;
  }

  return (
    <ActionField
      label={'View Latest Profiling Results'}
      iconName={'dataProfile'}
      onClick={() => navigation.openDataFieldProfilingCanvas(dataField.id)}
      disabled={!hasProfilingData}
    />
  );
};

export default ColumnProfilingResultButton;
