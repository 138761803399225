import { CoreEntityState } from '../../../redux/types';
import { AccurityCoreEntity } from '../../../types/accurityTypes';
import { WithChildrenCounts } from '../../../childrenCountField/types';

export const CUSTOM_PROPERTY_GROUP_TYPE = 'custom-property-group';
export const CUSTOM_PROPERTY_GROUP_LABEL = 'Custom Property Group';
export const CUSTOM_PROPERTY_GROUP_PLURAL_LABEL = 'Custom Property Groups';
export const CUSTOM_PROPERTY_GROUP_ICON = 'customPropertyGroup';
export const COLLAPSED_BY_DEFAULT_LABEL = 'Collapsed By Default';

export interface CustomPropertyGroupState extends CoreEntityState<CustomPropertyGroup> {
}

export interface CustomPropertyGroup extends AccurityCoreEntity, WithChildrenCounts {
  collapsedByDefault: boolean;
  groupOrder: number;
}
