import React from 'react';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useFormikContext } from 'formik';
import { ChildrenCounts, WithChildrenCounts } from '../../childrenCountField/types';
import { useSelectCoreEntity } from '../../redux/commonSelectors';

type ChildrenCountsContainerProps = {
  children?: (childrenCounts: ChildrenCounts) => React.ReactNode;
}

const hasChildrenCounts = (entity: unknown): entity is WithChildrenCounts => {
  return typeof entity === 'object' && entity != null && 'childrenCounts' in entity;
};

const ChildrenCountsContainer = ({ children }: ChildrenCountsContainerProps) => {
  const formik = useFormikContext<AccurityCoreEntity>();
  const storeEntity = useSelectCoreEntity(formik.values.objectType, formik.values.id);

  return (
    <>
      {!formik.status.isVersionBrowser && formik.status.isUpdateDetail && hasChildrenCounts(storeEntity) && children
        ? children(storeEntity.childrenCounts)
        : null}
    </>
  )
};

export default ChildrenCountsContainer;
