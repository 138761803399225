import { Column, NumericFormat } from 'ts-components';

import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { DataSourceTypeOptions } from '../types/dataSourceTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { useValidColumnsForFeatures } from '../../../common/list/listUtils';


export const useDataSourceListColumns = () => {

  const isOracle = useIsOracleDb();

  const dataSourceListColumns: Column[] = [
    commonColumns.id(),
    columnFactory.createEnumColumn('dataSourceType', 'Data Source Type', DataSourceTypeOptions),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    columnFactory.createNumberColumn('childrenCounts.dataSetsCount', 'Data Sets', undefined, NumericFormat.POSITIVE_NUMERIC),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumnsForFeatures(dataSourceListColumns);
};
