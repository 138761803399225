import * as React from 'react';
import { AccurityButton } from 'ts-components';
import accurityLogo from '../../images/accurity-logo.svg';
import { useAccurityNavigation } from '../../../common/navigation/hooks';

export const NotFoundPage = () => {

  const { openWelcomeScreen } = useAccurityNavigation();

  return (
    <div className="notFoundPage">
      <div className="notFoundPage__paper">
        <img className="notFoundPage__logo" src={accurityLogo}/>
        <div className="notFoundPage__404">404</div>
        <div className="notFoundPage__text">Page not found</div>
        <div className="notFoundPage__goHomeButton">
          <AccurityButton
            onClick={() => openWelcomeScreen()}
            color={'secondary'}
          >Go Home
          </AccurityButton>
        </div>
      </div>
    </div>
  );
};
