import React from 'react';
import { InteractiveNode, NodeColor, NodeMenuItem, NodeVariant } from 'ts-components';
import { useAccurityNavigation } from '../../../../common/navigation/hooks';

export interface ProcessDiagramNodeProps {
  id: string;
  objectId: string;
  objectType: string;
  label: string;
  nodeType: 'DATA_ASSET' | 'PROCESS' | 'PROCESS_STEP';
  hideNode: (nodeId: string) => void;
}

const ProcessDiagramNode = ({ id, objectId, objectType, label, nodeType, hideNode }: ProcessDiagramNodeProps) => {
  const { openDetailWithObject, getCurrentNavigation } = useAccurityNavigation();
  const { detailType, detailId } = getCurrentNavigation();
  const isDetailOpen = detailType === objectType && String(detailId) === String(objectId);

  const commonProps = {
    key: id,
    id: id,
    label: label,
    withShadow: isDetailOpen,
  }

  const commonMenuItems = [
    <NodeMenuItem
      key={'open-detail'}
      label={'Display Detail'}
      onClick={() => {
        openDetailWithObject(objectType, objectId);
      }}
    />,
    <NodeMenuItem
      key={'hide-node'}
      label={'Hide Node'}
      onClick={() => {
        hideNode(id);
      }}
    />,
  ];

  switch (nodeType) {
    case 'DATA_ASSET':
      return (
        <InteractiveNode
          {...commonProps}
          variant={NodeVariant.RECT}
          fillColor={NodeColor.GREY}
          borderColor={NodeColor.GREY}
          textColor={NodeColor.TEXT_DARK}
          menuItems={commonMenuItems}
        />
      );
    case 'PROCESS':
      return (
        <InteractiveNode
          {...commonProps}
          variant={NodeVariant.ROUND}
          height={60}
          fillColor={NodeColor.PURPLE}
          borderColor={NodeColor.PURPLE}
          textColor={NodeColor.TEXT_LIGHT}
          menuItems={commonMenuItems}
        />
      );
    case 'PROCESS_STEP':
      return (
        <InteractiveNode
          {...commonProps}
          variant={NodeVariant.ROUND}
          height={60}
          fillColor={NodeColor.LIGHT_BLUE}
          borderColor={NodeColor.LIGHT_BLUE}
          textColor={NodeColor.TEXT_LIGHT}
          menuItems={commonMenuItems}
        />
      );
    default:
      throw new Error('No Node defined for Node type: ' + String(nodeType));
  }
}

export default ProcessDiagramNode;
