import React from 'react';
import { SingleLineField, VersionBrowserColoring } from 'ts-components';

interface NameFieldProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  readOnly?: boolean;
  inverted?: boolean;
  coloring?: VersionBrowserColoring;
}

const NameField = ({ label, value, onChange, errorMessage, readOnly, inverted, coloring }: NameFieldProps) => (
  <SingleLineField
    name={'name'}
    label={label}
    type={'text'}
    value={value}
    onChange={onChange}
    errorMessage={errorMessage}
    disabled={readOnly}
    inverted={inverted}
    coloring={coloring}
  />
);

export default NameField;
