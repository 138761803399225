import { useEffect, useLayoutEffect } from 'react';
import { removeFixedHeightsOnAllCollapsingGroups, setFixedHeightsOnAllCollapsingGroups, } from '../utils/versionBrowserUtils';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { FormikProps } from 'formik';

const isEventTriggeredByUser = (e: Event) => {
  return e.isTrusted;
};

const onClickListener = (e: Event) => {
  // check if we react to user click or click fired by calling .click() below
  if (!isEventTriggeredByUser(e)) {
    return;
  }
  const headerElm = e.currentTarget as HTMLElement;

  // find field name identifier
  const fieldName = headerElm.closest('.collapsingGroup')?.getAttribute('data-field-name');

  // figure out opposite side selector. Either '.versionBrowserField-new' or '.versionBrowserField-old'
  const versionBrowserWrapper = headerElm.closest('[class*="versionBrowserField"]');
  const targetSelector = versionBrowserWrapper
    ? (versionBrowserWrapper.classList.contains('versionBrowserField-old') ? '.versionBrowserField-new' : '.versionBrowserField-old')
    : null;

  // fire click event on the collapsing group on the other side of the comparision
  const controlOnOtherSide = document.querySelector<HTMLElement>(
    `${targetSelector} .collapsingGroup[data-field-name="${fieldName}"] ${Array.from(headerElm.classList).map(classname => `.${classname}`).join('')}`
  );
  if (controlOnOtherSide) {
    controlOnOtherSide.click();
  }
  // Recalculation heights of collapsing groups. This must be within setTimeout(0), because it must be done in next JS tick
  // in order to recalculate heights after collapsing group reacted to click event
  setTimeout(() => {
    removeFixedHeightsOnAllCollapsingGroups();
    setFixedHeightsOnAllCollapsingGroups();
  }, 0);
};

export const useSyncCollapsingGroups = (formikOldObject?: FormikProps<AccurityCoreEntity>, formikNewObject?: FormikProps<AccurityCoreEntity>) => {

  // synchronize collapsing/expanding
  useEffect(() => {
    const collapsingGroupControls = [
      ...Array.from(document.querySelectorAll(`.versionBrowserComparison .collapsingGroup__header:not(.collapsingGroup__header--customHeader)`)),
      ...Array.from(document.querySelectorAll(`.versionBrowserComparison .collapsingGroup__header--customHeader .collapsingGroup__expandIcon`)),
    ];
    collapsingGroupControls.forEach((cgElm, key) => {
      cgElm.addEventListener('click', onClickListener);
    });

    // cleaning => remove attached listeners;
    return () => collapsingGroupControls.forEach((cgElm, key) => {
      cgElm.removeEventListener('click', onClickListener);
    });
  }, [formikOldObject, formikNewObject]);

  // synchronize height of collapsing groups
  useLayoutEffect(() => {
    setFixedHeightsOnAllCollapsingGroups();
    return () => removeFixedHeightsOnAllCollapsingGroups();
  }, [formikOldObject, formikNewObject]);
};
