// TODO inject port from Business Glossary

import { ExportFormat } from '../dataTransfer/types';
import { DATA_SOURCE_TYPE } from '../../businessGlossary/dataSource/types/dataSourceTypes';
import { BUSINESS_MODEL_DIAGRAM_TYPE } from '../../businessGlossary/businessModelDiagram/types/businessModelDiagramTypes';
import { DATA_FIELD_TYPE } from '../../businessGlossary/dataField/types/dataFieldTypes';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramBasedOnType } from '../../businessGlossary/processDiagram/types/processDiagramTypes';
import { DiagramLineageType } from '../../businessGlossary/dataLineageDiagram/types/types';

const url = '/api';

export const getEnvironmentInfoEndpoint = () => `${url}/environment-info`;
export const getInstanceInfoEndpoint = () => `${url}/instance-info`;
export const getUserSettingsEndpoint = () => `${url}/user-settings`;
export const getPublicSettingsEndpoint = () => `${url}/public-settings`;
export const getApplicationSettingsEndpoint = () => `${url}/application-setting`;

export const getUserLockUnlockEndpoint = () => `${url}/user/lock-unlock`;
export const getRestoreDefaultUiSettings = () => `${url}/user/restore-default-ui-settings`;
export const getDisableEmailNotificationsForUser = () => `${url}/user/disable-email-notifications`;

export const getUserPreferencesEndpoint = (key: string) => `${url}/ui-preferences/${key}`;

export const getGetEndpoint = (objectType: string, id: string) => `${url}/${objectType}/${id}`;
export const getSaveEndpoint = (objectType: string) => `${url}/${objectType}`;
export const getDeleteEndpoint = (objectType: string, id: string) => `${url}/${objectType}/${id}`;

export const getResetEndpoint = (objectType: string, id: string) => `${url}/${objectType}/${id}/reset`;

export const getSearchEndpoint = (objectType: string) => `${url}/${objectType}/search`;
export const getListSearchReferencingEndpoint = (objectType: string, id: string) => `${url}/${objectType}/search/${id}`;
export const getReferenceSearchEndpoint = (objectType: string) => `${url}/${objectType}/reference`;
export const getCustomSearchEndpoint = (customEndpoint: string) => `${url}/${customEndpoint}`;
export const getTextSearchFieldSearchEndpoint = (searchEndpoint: string, searchText?: string) => `${url}/${searchEndpoint}/${searchText ? searchText : ''}`;

export const getCommentByIdEndpoint = (objectType: string, commentId: number) => `${url}/${objectType}/comment/${commentId}`;
export const getCommentsSearchEndpoint = (objectType: string, objectId: number) => `${url}/${objectType}/${objectId}/comment/search`;
export const getCommentsCreateEndpoint = (objectType: string, objectId: number) => `${url}/${objectType}/${objectId}/comment`;
export const getCommentsUpdateEndpoint = (objectType: string, objectId: number) => `${url}/${objectType}/${objectId}/comment`;
export const getCommentsDeleteEndpoint = (objectType: string, commentId: number) => `${url}/${objectType}/comment/${commentId}`;

export const getFindRevisionsEndpoint = (objectType: string, id: string) => `${url}/${objectType}/findRevisionReferences/${id}`;
export const getFindObjectChangesEndpoint = (objectType: string) => `${url}/${objectType}/findObjectChanges`;

export const getWebsocketEndpoint = (email: string) => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const host = window.location.host;
  return `${protocol}//${host}${url}/socket?username=${email}`;
};

export const getExportLinkEndpoint = (objectType: string, format: ExportFormat) => `${url}/${objectType}/export/${format}`;
export const getExportEndpoint = (objectType: string, format: ExportFormat, documentId: string) => `${url}/${objectType}/export/${format}/${documentId}`;

export const getImportFileEndpoint = (objectType: string) => `${url}/${objectType}/import`;
export const getImportStartEndpoint = (objectType: string, documentId: string) => `${url}/${objectType}/import/${documentId}`;
export const getImportLogEndpoint = (objectType: string) => `${url}/${objectType}/import-log`;

export const getExportFileEndpoint = (objectType: string) => `${url}/${objectType}/export-file`;

export const getCreateSampleDataEndpoint = () => getApplicationSettingsEndpoint() + `/createSampleData`;
export const getClearAllDataEndpoint = () => getApplicationSettingsEndpoint() + `/clearAllData`;

export const getCustomLogoUpdateEndpoint = () => getApplicationSettingsEndpoint() + `/updateLogo`;
export const getCustomLogoSettingEndpoint = () => getApplicationSettingsEndpoint() + `/custom-logo-settings`;
export const getAcceptProductTermsEndpoint = () => getApplicationSettingsEndpoint() + '/acceptProductTerms';

export const getNotificationToggleEndpoint = (enabled: boolean) => `${url}/email-notification/${enabled ? 'disable' : 'enable'}`;

export const getGlobalSearchEndpoint = () => `${url}/global-search/findByNameOrDescription`;
export const getEntitiesWithTagEndpoint = () => `${url}/global-search/findAllByTagName`;

export const getDiagramEndpoint = (objectType: string, id: string, lineageType: DiagramLineageType) => `${url}/diagram/${objectType}/${id}/${lineageType}`;

export const getTestConnectionEndpoint = () => `${url}/${DATA_SOURCE_TYPE}/test-connection`;

export const getGenerateBmdGraphEndpoint = () => `${url}/${BUSINESS_MODEL_DIAGRAM_TYPE}/generate`;
export const getCreateBmdBasedOnEntityEndpoint = (entityId: string) => `${url}/${BUSINESS_MODEL_DIAGRAM_TYPE}/generate-from-entity/${entityId}`;

export const getGenerateProcessGraphEndpoint = (basedOnType?: ProcessDiagramBasedOnType) => `${url}/${PROCESS_DIAGRAM_TYPE}/generate/${basedOnType}`;
export const getCreateProcessDiagramBasedOnEndpoint = (basedOnType: ProcessDiagramBasedOnType, objectType: string, objectId: string) =>
  `${url}/${PROCESS_DIAGRAM_TYPE}/generate-from-entity/${basedOnType}/${objectType}/${objectId}`;

export const getGraphSaveEndpoint = (diagramType: string) => `${url}/${diagramType}/graph`;
export const getGraphLoadEndpoint = (diagramType: string, diagramId: string) => `${url}/${diagramType}/graph/${diagramId}`;
export const getHideNodeEndpoint = (objectType: string, nodeId: string) => `${url}/${objectType}/remove-node/${nodeId}`;
export const getDisplayNodeNeighboursEndpoint = (objectType: string, nodeId: string) => `${url}/${objectType}/display-neighbours/${nodeId}`;
export const getUpdateAttributesEndpoint =
  (objectType: string, nodeId: string, attributesIdString: string) => `${url}/${objectType}/update-attributes/${nodeId}/${attributesIdString}`;

export const getBusinessTermsSuggestionsByDataFieldEndpoint = () => `${url}/object-suggestion/business-terms-by-data-field-name`;
export const getBusinessTermsSuggestionsByDataStructureEndpoint = () => `${url}/object-suggestion/business-terms-by-data-structure-name`;

export const getBulkDeleteEndpoint = (objectType: string) => `${url}/${objectType}/bulk-delete`;
export const getBulkUpdateTagsEndpoint = (objectType: string) => `${url}/${objectType}/bulk-update-tags`;
export const getBulkUpdateNotificationsEndpoint = (objectType: string) => `${url}/${objectType}/bulk-update-notifications`;
export const getBulkOperationLogEndpoint = (objectType: string) => `${url}/${objectType}/bulk-operation-log`;

export const getDataFieldDoProfilingEndpoint = (dataFieldId: string) => `${getGetEndpoint(DATA_FIELD_TYPE, dataFieldId)}/profile`;
export const getDataFieldProfilingResultEndpoint = (dataFieldId: string) => `${getGetEndpoint(DATA_FIELD_TYPE, dataFieldId)}/profiling-result`;
export const getDataFieldHasProfilingResultEndpoint = (dataFieldId: string) => `${getGetEndpoint(DATA_FIELD_TYPE, dataFieldId)}/has-result`;

export const getDataSetSyncScanEndpoint = (dataSetId: string) => `${url}/data-set/sync/scan/${dataSetId}`;
export const getDataSetSyncUpdateEndpoint = (dataSetId: string) => `${url}/data-set/sync/update/${dataSetId}`;

export const getFindAttributeByEntityAttributeDefinitionEndpoint =
  (entityId: string, attributeDefinitionId: string) => `${url}/attribute/find-by-entity-attribute-definition/${entityId}/${attributeDefinitionId}`;