import { ErrorNotification } from '../../types';
import { showToast } from '../../../userMessages/redux/userMessagesSlice';
import rootStore from '../../../redux/rootStore';

export const handleErrorNotification = (errorNotification: ErrorNotification) => {
  rootStore.dispatch(showToast({
    title: errorNotification.header,
    message: errorNotification.body
  }));
};
