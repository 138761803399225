import { DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_DEFINITION_FIELD, BUSINESS_MODEL_MAPPING_BASE_ENTITY_FIELD, BusinessModelMapping } from '../../types/businessModelMappingTypes';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../entity/types/entityTypes';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL } from '../../../attribute/types/attributeTypes';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../../attributeDefinition/types/attributeDefinitionTypes';
import { AccurityFilter, AccurityReference } from '../../../../common/types/accurityTypes';
import { createReferenceFieldFilter } from '../../../../common/referenceField/utils/filters';
import { FormikProps } from 'formik';
import { doFetch } from '../../../../common/rest/FetchService';
import { getFindAttributeByEntityAttributeDefinitionEndpoint } from '../../../../common/rest/endpoints';

interface EntityAttributeProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
}

const EntityAttribute = ({ additionalFilters = [], formik }: EntityAttributeProps) => {

  const entityId = formik.values.baseEntity?.id;
  const attributeDefinitionId = formik.values.baseAttributeDefinition?.id;

  const entityFilters = [
    ...additionalFilters,
  ];

  const attributeDefinitionFilters = [
    ...additionalFilters,
  ];

  if (!attributeDefinitionId) {
    entityFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    entityFilters.push(
      createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
    );
  }

  if (!entityId) {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('entity.id', entityId)
    );
  }

  const setBaseAttribute = (entityId?: string, attributeDefinitionId?: string): Promise<void> => {
    if (entityId && attributeDefinitionId) {
      return doFetch(getFindAttributeByEntityAttributeDefinitionEndpoint(entityId, attributeDefinitionId), 'GET')
        .then(attribute => {
          formik.setFieldValue('baseAttribute', attribute);
        })
    } else {
      formik.setFieldValue('baseAttribute', null);
      return Promise.resolve();
    }
  }

  const setEntityValue = (newValue: AccurityReference | null) => {
    setBaseAttribute(newValue?.id, formik.values.baseAttributeDefinition?.id)
      .then(() => {
        formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_ENTITY_FIELD, newValue);
      });
  }

  const setAttributeDefinitionValue = (newValue: AccurityReference | null) => {
    setBaseAttribute(formik.values.baseEntity?.id, newValue?.id)
      .then(() => {
        formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_DEFINITION_FIELD, newValue);
      });
  }

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}>
        <ReferenceFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_BASE_ENTITY_FIELD}
          label={ENTITY_LABEL}
          objectType={ENTITY_TYPE}
          customEndpoint={ENTITY_TYPE + '/business-model-mapping-entity-selection'}
          additionalFilters={entityFilters}
          setValue={setEntityValue}
          icon={ENTITY_ICON}
        />
        <ReferenceFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_DEFINITION_FIELD}
          label={ATTRIBUTE_LABEL}
          objectType={ATTRIBUTE_DEFINITION_TYPE}
          customEndpoint={ATTRIBUTE_DEFINITION_TYPE + '/business-model-mapping-attribute-definition-selection'}
          additionalFilters={attributeDefinitionFilters}
          setValue={setAttributeDefinitionValue}
          icon={ATTRIBUTE_ICON}
        />
      </DetailInlineGroup>
    </>
  )
};

export default EntityAttribute;