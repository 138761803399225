import { useFormikContext } from 'formik';
import { VersionBrowserColoring } from 'ts-components';
import { get } from 'lodash';

export function useVersionBrowserColoring(fieldName: string, returnCollection?: false): VersionBrowserColoring | undefined;
export function useVersionBrowserColoring(fieldName: string, returnCollection?: true): VersionBrowserColoring[] | undefined;
export function useVersionBrowserColoring(fieldName: string, returnCollection?: boolean): VersionBrowserColoring[] | VersionBrowserColoring | undefined {
  const context = useFormikContext();

  // the component where this hook is used is not withing VersionBrowser
  if (!context?.status?.revisionColoring) {
    return undefined;
  }

  const coloringObject = (context.status.revisionColoring || []) as { [key: string]: VersionBrowserColoring | VersionBrowserColoring[] };

  // each field expects coloring information.
  // When there isn't change in field value, backend doesn't send 'TRANSPARENT' change.
  // So we send array with 'TRANSPARENT' values for collection fields and 'TRANSPARENT' value for single-value fields
  let defaultValue: VersionBrowserColoring | VersionBrowserColoring[] = VersionBrowserColoring.TRANSPARENT;
  if (returnCollection) {
    const fieldValue = get(context.values, fieldName);
    const collectionFieldValue = Array.isArray(fieldValue) ? fieldValue : [];
    defaultValue = collectionFieldValue.map(() => VersionBrowserColoring.TRANSPARENT);
  }
  return get(coloringObject, fieldName, defaultValue);
}
