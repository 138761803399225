import React from 'react';
import { useField, useFormikContext } from 'formik';
import { MultiLineField, Tooltip } from 'ts-components';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface MultiLineFieldWithFormikProps {
  name: string;
  label: string;
  readOnly?: boolean;
  tooltip?: Tooltip;
  coloringOverwrite?: string;
  disableColoring?: boolean;
  inherited?: boolean;
  className?: string
}

const MultiLineFieldWithFormik = ({
                                    name,
                                    label,
                                    readOnly,
                                    tooltip,
                                    coloringOverwrite,
                                    disableColoring,
                                    inherited,
                                    className
                                  }: MultiLineFieldWithFormikProps) => {
  const [field, meta, ] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(coloringOverwrite ? coloringOverwrite : name);

  return (
    <MultiLineField
      value={field.value}
      onChange={(value: string) => formikContext.setFieldValue(name, value)}
      errorMessage={meta.error}
      name={name}
      label={label}
      disabled={readOnly || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      tooltip={tooltip}
      coloring={disableColoring ? undefined : coloring}
      inherited={inherited}
      className={className}
    />
  );
};

export default MultiLineFieldWithFormik;
