import React from 'react';
import { FormikProps, useField } from 'formik';

import { DetailInlineGroup } from 'ts-components';

import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { createReferenceFieldFilter, excludeFieldValueFilter } from '../../../../common/referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_TYPE } from '../../../dataField/types/dataFieldTypes';
import {
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD,
  TechnicalDataMapping,
  TechnicalDataMappingTargetJoinDataField
} from '../../types/technicalDataMappingTypes';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface DataFieldsJoinProps {
  elementName: string;
  index: number;
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<TechnicalDataMapping>;
}

const TechnicalDataMappingTargetDataFieldsJoin = ({ elementName, index, additionalFilters = [], formik }: DataFieldsJoinProps) => {

  const leftDataFieldFilter = [
    createReferenceFieldFilter('dataStructure.id', formik.values.technicalDataMappingTargetJoinDataStructureDataField?.leftDataStructure?.id),
    ...additionalFilters,
  ];

  const rightDataFieldFilter = [
    createReferenceFieldFilter('dataStructure.id', formik.values.technicalDataMappingTargetJoinDataStructureDataField?.rightDataStructure?.id),
    ...additionalFilters,
  ];

  const [joinDataFields] = useField<TechnicalDataMappingTargetJoinDataField[]>(TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD);

  joinDataFields.value.map(row => row?.leftDataField)
    .filter(value => value !== null)
    .forEach(value => {
      if (!!value && !!value.id) {
        leftDataFieldFilter.push(
          excludeFieldValueFilter('id', value.id)
        );
      }
    });

  joinDataFields.value.map(row => row?.rightDataField)
    .filter(value => value !== null)
    .forEach(value => {
      if (!!value && !!value.id) {
        rightDataFieldFilter.push(
          excludeFieldValueFilter('id', value.id)
        );
      }
    });

  const coloring = useVersionBrowserColoring(TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD);

  return (
    <DetailInlineGroup
      childGridSizes={[6, 6]}
      coloring={coloring}
    >
      <ReferenceFieldWithFormik
        name={elementName + '.leftDataField'}
        label={DATA_FIELD_LABEL}
        objectType={DATA_FIELD_TYPE}
        icon={DATA_FIELD_ICON}
        additionalFilters={leftDataFieldFilter}
        disableColoring={true}
      />
      <ReferenceFieldWithFormik
        name={elementName + '.rightDataField'}
        label={DATA_FIELD_LABEL}
        objectType={DATA_FIELD_TYPE}
        icon={DATA_FIELD_ICON}
        additionalFilters={rightDataFieldFilter}
        disableColoring={true}
      />
    </DetailInlineGroup>
  )
};

export default TechnicalDataMappingTargetDataFieldsJoin;
