import { getNotificationToggleEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../userMessages/actions';
import { AccurityCoreEntity, EmailNotificationActionType, EmailNotificationState } from '../../types/accurityTypes';
import { getEmailNotificationDisabledMessage, getEmailNotificationEnabledMessage } from '../../userMessages/commonMessages';

export const toggleNotificationsRequest = (entity: AccurityCoreEntity, isActive: boolean) => {
  const notificationType = EmailNotificationActionType.COMMENT_UPDATE;
  const payload: EmailNotificationState = {
    actionType: notificationType,
    referencedObjectType: entity.objectType,
    referencedObjectName: entity.name,
    referencedObjectId: entity.id
  };

  return doFetch(getNotificationToggleEndpoint(isActive), 'PUT', JSON.stringify(payload))
    .then(() => {
      showSnackbarMessage(isActive ? getEmailNotificationDisabledMessage(notificationType) : getEmailNotificationEnabledMessage(notificationType));
    })
    .catch(showErrorMessageFromResponse);
};
