import { useCallback, useEffect, useState } from 'react';
import { AccurityReferenceFieldSearchResult } from '../types';

export const useAutocompleteBackendSearchLogic = <T>(
  textValue: string = '',
  setFieldValue: (newFieldValue: T) => void,
  doBackendSearch: (searchText: string) => Promise<AccurityReferenceFieldSearchResult<T>>,
  searchDisabled?: boolean,
) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>(textValue);
  const [items, setItems] = useState<T[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const resetFilterText = useCallback(() => {
    setFilterText(textValue);
  }, [textValue]);

  useEffect(() => resetFilterText(), [resetFilterText]);

  const onChange = (value: string) => {
    setFilterText(value);
    if (!value) {
      setItems([]);
      setTotalItems(0)
    }
    doSearch(value);
  };

  // TODO remove redundant onSelect<->setFieldValue when we migrate ReferenceField to MUI AutoComplete
  const onSelect = useCallback((item: any) => {
    setFieldValue(item);
  }, [setFieldValue]);

  const doSearch = (searchText: string = filterText || '') => {
    if (searchDisabled) {
      setItems([]);
      setTotalItems(0);
    } else {
      setIsLoading(true);
      return doBackendSearch(searchText)
        .then((searchResult) => {
          setItems(searchResult.rows);
          setTotalItems(searchResult.size);
          return searchResult;
        })
        .finally(() => setIsLoading(false));
    }
  };

  return {
    onChange,
    onSelect,
    filterText,
    resetFilterText,
    doSearch,
    isLoading,
    items,
    totalItems,
  };
};
