import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { DATA_FIELD_TYPE, DataFieldState } from '../types/dataFieldTypes';

const initialState: DataFieldState = getInitialCoreEntityState(DATA_FIELD_TYPE);

const dataFieldSlice = createSlice({
  name: DATA_FIELD_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default dataFieldSlice.reducer;
