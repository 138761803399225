import { Align, Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import {
  BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_LABEL,
  BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_LABEL,
  BUSINESS_MODEL_MAPPING_BASE_DATA_SET_LABEL,
  BUSINESS_MODEL_MAPPING_BASE_DATA_SET_TYPE_LABEL,
  BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_LABEL,
  BUSINESS_MODEL_MAPPING_BASE_ENTITY_LABEL,
  BUSINESS_MODEL_MAPPING_BASE_LABEL,
  BUSINESS_MODEL_MAPPING_BASED_ON_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTES_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELDS_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_TYPE_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURES_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_ENTITIES_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_ENTITY_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_LEFT_DATA_FIELDS_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_LEFT_DATA_STRUCTURE_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_REFERENCE_ATTRIBUTE_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_RIGHT_DATA_FIELDS_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_RIGHT_DATA_STRUCTURE_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOINS_TARGET_ENTITY_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_OPERATIONS_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_ATTRIBUTES_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_CRITERIA_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_DATA_FIELDS_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_DATA_STRUCTURES_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_ENTITIES_LABEL,
  BusinessModelMappingTypeOptions
} from '../types/businessModelMappingTypes';
import { Feature } from '../../../common/userSettings/features/features';
import { useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useBusinessModelMappingStartListColumns = (): Column[] => {
  const isOracle = useIsOracleDb();

  return [
    columnFactory.createNumberColumn('id', 'ID', {
      align: Align.LEFT,
      hideComparisonSelector: true
    }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createTextColumn('description.plainTextValue', 'Description', { hidden: true, sortable: !isOracle }),
    columnFactory.createEnumColumn('businessModelMappingType', BUSINESS_MODEL_MAPPING_BASED_ON_LABEL, BusinessModelMappingTypeOptions),
  ];
};

export const useBusinessModelMappingBaseListColumns = (): Column[] => {
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  return [
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('baseDataSet.dataSetType', BUSINESS_MODEL_MAPPING_BASE_DATA_SET_TYPE_LABEL, dataSetTypeEnumOptions, { hidden: true })] : []),
    columnFactory.createTextColumn('baseDataSet.name', BUSINESS_MODEL_MAPPING_BASE_DATA_SET_LABEL),
    commonColumns.collection({ name: 'baseDataStructure.name', label: BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_LABEL }),
    commonColumns.collection({ name: 'baseDataField.name', label: BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_LABEL }),
    commonColumns.collection({ name: 'baseEntity.name', label: BUSINESS_MODEL_MAPPING_BASE_ENTITY_LABEL }),
    commonColumns.collection({ name: 'baseAttributeDefinition.name', label: BUSINESS_MODEL_MAPPING_BASE_ATTRIBUTE_LABEL }),
  ].map(column => {
    return { ...column, group: BUSINESS_MODEL_MAPPING_BASE_LABEL };
  });
};

export const useBusinessModelMappingTargetListColumns = (): Column[] => {
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  return [
    columnFactory.createTextColumn('businessModelMappingTargetEntityAttributeDefinitions.entity.name', BUSINESS_MODEL_MAPPING_TARGET_ENTITIES_LABEL, {
      hidden: true,
      sortable: false
    }),
    columnFactory.createTextColumn('businessModelMappingTargetEntityAttributeDefinitions.attributeDefinition.name', BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTES_LABEL, {
      hidden: true,
      sortable: false
    }),
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('targetDataSet.dataSetType', BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_TYPE_LABEL, dataSetTypeEnumOptions, { hidden: true })] : []),
    columnFactory.createTextColumn('targetDataSet.name', BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_LABEL, { hidden: true }),
    columnFactory.createTextColumn('targetDataStructureDataFields.dataStructure.name', BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURES_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('targetDataStructureDataFields.dataField.name', BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELDS_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('targetDataStructureDataFields.operation', BUSINESS_MODEL_MAPPING_TARGET_OPERATIONS_LABEL, { hidden: true, sortable: false }),
  ].map(column => {
    return { ...column, group: BUSINESS_MODEL_MAPPING_TARGET_LABEL };
  });
};

export const useBusinessModelMappingSelectionsListColumns = (): Column[] => {

  return [
    columnFactory.createTextColumn('selectionItems.entity.name', BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_ENTITIES_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('selectionItems.attributeDefinition.name', BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_ATTRIBUTES_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('selectionItems.dataStructure.name', BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_DATA_STRUCTURES_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('selectionItems.dataField.name', BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_DATA_FIELDS_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('selectionItems.criteria', BUSINESS_MODEL_MAPPING_TARGET_SELECTIONS_CRITERIA_LABEL, { hidden: true, sortable: false }),
  ].map(column => {
    return { ...column, group: BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL };
  });
};

export const useBusinessModelMappingJoinsListColumns = (): Column[] => {

  return [
    columnFactory.createTextColumn('joinEntityAttributeDefinitions.entity.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_ENTITY_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('joinEntityAttributeDefinitions.attributeDefinition.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_REFERENCE_ATTRIBUTE_LABEL, {
      hidden: true,
      sortable: false
    }),
    columnFactory.createTextColumn('joinEntityAttributeDefinitions.targetEntity.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_TARGET_ENTITY_LABEL, { hidden: true, sortable: false }),
    columnFactory.createTextColumn('joinDataStructureDataField.leftDataStructure.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_LEFT_DATA_STRUCTURE_LABEL, {
      hidden: true,
      sortable: false
    }),
    columnFactory.createTextColumn('joinDataStructureDataField.rightDataStructure.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_RIGHT_DATA_STRUCTURE_LABEL, {
      hidden: true,
      sortable: false
    }),
    columnFactory.createTextColumn('joinDataStructureDataField.joinDataFields.leftDataField.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_LEFT_DATA_FIELDS_LABEL, {
      hidden: true,
      sortable: false
    }),
    columnFactory.createTextColumn('joinDataStructureDataField.joinDataFields.rightDataField.name', BUSINESS_MODEL_MAPPING_TARGET_JOINS_RIGHT_DATA_FIELDS_LABEL, {
      hidden: true,
      sortable: false
    }),
  ].map(column => {
    return { ...column, group: BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL };
  });
};

export const useBusinessModelMappingEndListColumns = () => {
  const navigationController = useAccurityNavigation();

  const businessModelMappingListColumns: Column[] = [
    commonColumns.tags(navigationController),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(businessModelMappingListColumns, GlossaryCustomPropertyObjectType.BUSINESS_MODEL_MAPPING);
};
