import { DataTransferLink, ExportFormat, ExportSearch } from './types';
import { getExportEndpoint, getExportFileEndpoint, getExportLinkEndpoint, getImportFileEndpoint, getImportLogEndpoint, getImportStartEndpoint } from '../rest/endpoints';
import { doFetch } from '../rest/FetchService';

export const requestExportLink = (format: ExportFormat, exportSearch: ExportSearch) => {
  const exportUrl = getExportLinkEndpoint(exportSearch.objectType, format);
  const body = JSON.stringify(exportSearch);

  return doFetch(exportUrl, 'POST', body);
};

export const requestExport = (format: ExportFormat, exportSearch: ExportSearch, downloadLink: DataTransferLink) => {
  const downloadUrl = getExportEndpoint(exportSearch.objectType, format, downloadLink.id);
  return doFetch(downloadUrl, 'GET');
};

export const requestSendImportFile = (objectType: string, file: File, partialImport: boolean) => {
  const importFileUrl = getImportFileEndpoint(objectType);

  const body = new FormData();
  body.append('file', file);
  body.append('partialImport', `${partialImport}`);

  return doFetch(importFileUrl, 'POST', body, { doNotFillContentType: true });
};

export const requestStartImportJob = (objectType: string, importLink: DataTransferLink) => {
  const importStartUrl = getImportStartEndpoint(objectType, importLink.id);

  return doFetch(importStartUrl, 'GET');
};

export const requestDownloadImportLog = (objectType: string) => {
  const importLogUrl = getImportLogEndpoint(objectType);
  return doFetch(importLogUrl, 'GET', undefined, { downloadResponse: true });
};

export const requestDownloadExportFile = (objectType: string) => {
  const exportFileUrl = getExportFileEndpoint(objectType);
  return doFetch(exportFileUrl, 'GET', undefined, { downloadResponse: true });
};
