import React, { useEffect, useState } from 'react';
import AccurityReferenceMultiSelector from '../../../common/referenceField/components/AccurityReferenceMultiSelector';
import { AccurityButton, AccurityFilterType, DiagramControlField } from 'ts-components';
import { AccurityReference } from '../../../common/types/accurityTypes';
import { isEqual } from 'lodash';
import { BUSINESS_TERM_LABEL, BUSINESS_TERM_PLURAL_LABEL, BUSINESS_TERM_TYPE } from '../../businessTerm/types/businessTermTypes';
import { ProcessDiagramBasedOnConfig, ProcessDiagramBasedOnType } from '../types/processDiagramTypes';
import { ATTRIBUTE_LABEL, ATTRIBUTE_PLURAL_LABEL, ATTRIBUTE_TYPE } from '../../attribute/types/attributeTypes';
import { DATA_ASSET_LABEL, DATA_ASSET_PLURAL_LABEL, DATA_ASSET_TYPE } from '../../dataAsset/types/dataAssetTypes';
import { ENTITY_LABEL, ENTITY_PLURAL_LABEL, ENTITY_TYPE } from '../../entity/types/entityTypes';
import { PROCESS_LABEL, PROCESS_PLURAL_LABEL, PROCESS_TYPE } from '../../process/types/processTypes';
import { PROCESS_STEP_LABEL, PROCESS_STEP_PLURAL_LABEL, PROCESS_STEP_TYPE, ProcessStepReference } from '../../process/step/types/processStepTypes';

interface ProcessDiagramTopBarControlsProps {
  basedOnType: ProcessDiagramBasedOnType;
  tags: AccurityReference[];
  setTags: (newTags: AccurityReference[]) => void;
  initialSelectedItems: AccurityReference[];
  onGenerate: (selectedItems: AccurityReference[]) => void;
  hasUpdatePermission: boolean;
}

const basedOnTypeConfig: ProcessDiagramBasedOnConfig = {
  [ProcessDiagramBasedOnType.ATTRIBUTES]: {
    objectType: ATTRIBUTE_TYPE,
    label: ATTRIBUTE_PLURAL_LABEL,
    tagsLabel: ATTRIBUTE_LABEL + ' Tags',
    groupBy: (option: AccurityReference) => option?.name.substr(0, option.name.indexOf('.')),
  },
  [ProcessDiagramBasedOnType.BUSINESS_TERMS]: {
    objectType: BUSINESS_TERM_TYPE,
    label: BUSINESS_TERM_PLURAL_LABEL,
    tagsLabel: BUSINESS_TERM_LABEL + ' Tags',
  },
  [ProcessDiagramBasedOnType.DATA_ASSETS]: {
    objectType: DATA_ASSET_TYPE,
    label: DATA_ASSET_PLURAL_LABEL,
    tagsLabel: DATA_ASSET_LABEL + ' Tags',
  },
  [ProcessDiagramBasedOnType.ENTITIES]: {
    objectType: ENTITY_TYPE,
    label: ENTITY_PLURAL_LABEL,
    tagsLabel: ENTITY_LABEL + ' Tags',
  },
  [ProcessDiagramBasedOnType.PROCESSES]: {
    objectType: PROCESS_TYPE,
    label: PROCESS_PLURAL_LABEL,
    tagsLabel: PROCESS_LABEL + ' Tags',
  },
  [ProcessDiagramBasedOnType.PROCESS_STEPS]: {
    objectType: PROCESS_STEP_TYPE,
    label: PROCESS_STEP_PLURAL_LABEL,
    tagsLabel: PROCESS_STEP_LABEL + ' Tags',
    groupBy: (option: ProcessStepReference) => option?.process?.name,
  },
}

const ProcessDiagramTopBarControls = ({ basedOnType, tags, setTags, initialSelectedItems = [], onGenerate, hasUpdatePermission }: ProcessDiagramTopBarControlsProps) => {
  const [selectedItems, setSelectedItems] = useState<AccurityReference[]>(initialSelectedItems);

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  const dirty = !isEqual(initialSelectedItems, selectedItems);

  const onGenerateClick = () => {
    onGenerate(selectedItems);
  };

  const tagIds = tags.map(tag => tag.id).join(';');
  const filterByTags = tagIds ? [{
    type: AccurityFilterType.SIMPLE_QUERY,
    property: 'tags.id',
    value: `=${tagIds}`,
  }] : undefined;

  const { objectType, label, tagsLabel, groupBy } = basedOnTypeConfig[basedOnType];

  return (
    <>
      <DiagramControlField>
        <AccurityReferenceMultiSelector
          name={'tag'}
          label={tagsLabel}
          value={tags}
          setValue={newValue => setTags(newValue)}
          objectType={'tag'}
          disabled={!hasUpdatePermission}
          customEndpoint={`global-search/findAllTagsByObjectTypeAndName/${objectType}`}
        />
      </DiagramControlField>
      <DiagramControlField>
        <AccurityReferenceMultiSelector
          name={'sourcesSelector'}
          label={label}
          value={selectedItems}
          setValue={setSelectedItems}
          objectType={objectType}
          additionalFilters={filterByTags}
          disabled={!hasUpdatePermission}
          allowSelectAll={true}
          groupBy={groupBy}
        />
      </DiagramControlField>
      <AccurityButton
        onClick={onGenerateClick}
        disabled={!hasUpdatePermission || selectedItems.length === 0 || !dirty}
      >
        Generate
      </AccurityButton>
    </>
  );
};

export default ProcessDiagramTopBarControls;
