import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FileChooser } from 'ts-components';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';
import { createFile } from '../../appSettings/detail/CustomLogoSettingGroup';

interface FileChooserFieldWithFormikProps {
  name: string;
  fileNameField: string;
  label: string;
  accept: string;
  sizeRestriction?: number;
  disabled?: boolean;
}

const FileChooserFieldWithFormik = ({ name, label, accept, sizeRestriction, fileNameField, disabled }: FileChooserFieldWithFormikProps) => {
  const [fileData] = useField(name);
  const [fileName] = useField(fileNameField);
  const formikContext = useFormikContext();

  let coloring = useVersionBrowserColoring(fileNameField);

  const fileChooserValue = fileData.value
    ? [fileData.value]
    : (fileName.value ? [createFile(fileName.value)] : []);

  return (
    <FileChooser
      name={name}
      label={label}
      accept={accept}
      sizeRestriction={sizeRestriction}
      files={fileChooserValue}
      onFileSelection={(fileList) => formikContext.setFieldValue(name, fileList[0])}
      coloring={coloring}
      disabled={coloring !== undefined ? !!coloring : disabled}
    />
  );
};

export default FileChooserFieldWithFormik;
