import { getEnvironmentInfoEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { EnvironmentInfoResponse } from '../types/types';

export const requestEnvironmentInfo = (): Promise<EnvironmentInfoResponse> => {

  return doFetch(getEnvironmentInfoEndpoint(), 'GET', undefined, { unauthorizedRequest: true })
    .catch(showErrorMessageFromResponse);
};
