import React from 'react';
import { useHasReadPermission } from '../../common/userSettings/hooks/permissions';
import { MainMenuItem } from 'ts-components';
import { useHasSomeOfTheFeatures } from '../../common/userSettings/hooks/features';

interface BusinessGlossaryMenuItemProps {
  type: string;
  label: string;
  icon?: string;
  features?: string[];
}

const BusinessGlossaryMenuItem = ({ type, label, icon, features, ...menuItemProps }: BusinessGlossaryMenuItemProps) => {
  const hasReadPermission = useHasReadPermission();
  const hasSomeOfTheFeatures = useHasSomeOfTheFeatures();

  if (!hasReadPermission(type) || (features !== undefined && !hasSomeOfTheFeatures(features))) {
    return null;
  }

  return (
    <MainMenuItem
      type={type}
      label={label}
      icon={icon}
      {...menuItemProps}
    />
  );
};

export default BusinessGlossaryMenuItem;
