import { AccurityFilter } from '../../types/accurityTypes';
import { AccurityFilterType } from 'ts-components';

export const createReferenceFieldFilter = (property: string, value?: string): AccurityFilter => {
  return {
    type: AccurityFilterType.SIMPLE_QUERY,
    property: property,
    value: value || '',
  };
};

export const createExactReferenceFieldFilter = (property: string, value: string): AccurityFilter => {
  return {
    type: AccurityFilterType.SIMPLE_QUERY,
    property: property,
    value: `=${value}`,
  };
};

export const createUniqueWithinCollectionFilters = (filterProperty: string, collection?: any[]): AccurityFilter[] => {
  const alreadySelectedProperties = collection
    ?.filter(item => !!item && !!item[filterProperty])
    .map(item => item[filterProperty])
    .join(';');

  if (!alreadySelectedProperties) {
    return [];
  }

  return [
    excludeFieldValueFilter(filterProperty, alreadySelectedProperties)
  ];
};

export const excludeFieldValueFilter = (property: string, value: string): AccurityFilter => {
  return {
    type: AccurityFilterType.SIMPLE_QUERY,
    property: property,
    value: '-=' + value
  };
};
