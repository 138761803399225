import { useSelectProfilingData } from '../redux/selectors';
import { useEffect } from 'react';
import { clearColumnProfilingDataInStore, setColumnProfilingResultInStore } from '../redux/columnProfilingSlice';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useDispatch } from 'react-redux';
import { doLoadProfilingResult } from '../rest/doLoadProfilingResult';
import { useEffectOnMountOnly } from '../../hooks/useEffectOnMountOnly';

export const useProfilingData = (dataFieldId?: string) => {

  const data = useSelectProfilingData(dataFieldId);
  const { closeDataFieldProfilingCanvas } = useAccurityNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataFieldId) {
      doLoadProfilingResult(dataFieldId)
        .then(response => {
          if (response) {
            dispatch(setColumnProfilingResultInStore(response));
          } else {
            closeDataFieldProfilingCanvas();
          }
        });
    }
  }, [dataFieldId, dispatch]);

  useEffectOnMountOnly(() => {
    // clear store on unmount
    return () => {
      dispatch(clearColumnProfilingDataInStore());
    }
  });

  return data;
};
