import React from 'react';
import { AccurityFilterType, ActionField } from 'ts-components';
import { getLabelForValue } from '../utils';
import { useAccurityNavigation } from '../../navigation/hooks';
import { ChildrenCountWording } from '../types';
import { AccurityPinFilter } from '../../types/accurityTypes';

interface ChildrenCountFieldProps {
  singularLabel: string;
  pluralLabel: string;
  value?: number;
  iconName?: string;
  pinIconName?: string;
  pinLabel?: string;
  childFieldType: string;
  parentFieldType: string;
  relationType?: string;
  parentId: string;
  inverted?: boolean;
  disabled?: boolean;
  customPinFilters?: AccurityPinFilter[];
  onClick?: () => void;
  childrenCountWording?: ChildrenCountWording
}

const ChildrenCountField = ({
                              singularLabel,
                              pluralLabel,
                              value,
                              iconName,
                              pinIconName,
                              pinLabel,
                              childFieldType,
                              parentFieldType,
                              relationType = 'RELATED_TO',
                              parentId,
                              inverted,
                              disabled,
                              customPinFilters,
                              onClick,
                              childrenCountWording
                            }: ChildrenCountFieldProps) => {

  const label = getLabelForValue(singularLabel, pluralLabel, value, childrenCountWording);
  const navigationController = useAccurityNavigation();

  const handleOnClick = onClick
    ? onClick
    : () => {
      const filters = customPinFilters
        ?
        customPinFilters
        :
        [{
          type: AccurityFilterType.CHILDREN_COUNT,
          property: parentFieldType,
          value: parentId,
          relationType: relationType,
          timezone: '',
          icon: pinIconName || '',
          label: pinLabel,
          disabled: false,
        }];

      navigationController.openListWithPinFilters(childFieldType, filters)
    }

  return (
    <ActionField
      label={label}
      iconName={iconName}
      inverted={inverted}
      disabled={disabled}
      onClick={handleOnClick}
    />
  );
};

export default ChildrenCountField;
