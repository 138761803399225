import React, { useCallback, useMemo, useState } from 'react';
import { Tag, TagField, VersionBrowserColoring } from 'ts-components';
import { requestTagFieldSearch } from '../rest';
import { debounce } from 'lodash';

interface AccurityTagFieldProps {
  name: string
  label: string
  value: Tag[]
  setValue: (newValue: Tag[]) => void;
  onTagClick?: (tag: Tag) => void;
  disableNewTagCreation?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
  coloring?: VersionBrowserColoring[];
  inherited?: boolean;
}

const TAG_SEARCH_DELAY = 300;

const AccurityTagField = ({ name, label, value = [], setValue, readOnly, errorMessage, onTagClick, disableNewTagCreation, coloring, inherited }: AccurityTagFieldProps) => {
  const [items, setItems] = useState<Tag[]>([]);

  const doSearch = useCallback((searchText: string) => {
    if (searchText) {
      requestTagFieldSearch(searchText, value)
        .then(response => setItems(response.rows))
        .catch(error => console.error('Tag Search Error! ', error))
    } else {
      // reset items when search text is empty in order to not show non-relevant tags after typing first letter
      setItems([]);
    }
  }, [value]);

  const debouncedSearch = useMemo(() => {
    return debounce(doSearch, TAG_SEARCH_DELAY)
  }, [doSearch]);

  return (
    <TagField
      value={value}
      onChange={(tags: Tag[]) => setValue(tags)}
      errorMessage={errorMessage}
      name={name}
      label={label}
      options={items}
      disableNewTagCreation={disableNewTagCreation}
      disabled={readOnly}
      searchHandler={debouncedSearch}
      onTagClick={onTagClick}
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default AccurityTagField;
