import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { ATTRIBUTE_PLURAL_LABEL } from '../../attribute/types/attributeTypes';
import { BUSINESS_TERM_PLURAL_LABEL } from '../../businessTerm/types/businessTermTypes';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import React from 'react';
import { getDefaultListSettings, useValidColumns } from '../../../common/list/listUtils';
import { REQUIREMENT_ICON, REQUIREMENT_PLURAL_LABEL, REQUIREMENT_TYPE } from '../types/requirementTypes';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

const RequirementList = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const requirementListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    commonColumns.tags(navigationController),
    ...(hasFeature(Feature.ATTRIBUTES) ?
      [columnFactory.createNumberColumn('childrenCounts.attributesCount', ATTRIBUTE_PLURAL_LABEL, { hidden: false }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [columnFactory.createNumberColumn('childrenCounts.businessTermsCount', BUSINESS_TERM_PLURAL_LABEL, { hidden: false }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  const requirementColumns = useValidColumns(requirementListColumns, GlossaryCustomPropertyObjectType.REQUIREMENT);
  const requirementListSettings = getDefaultListSettings(REQUIREMENT_TYPE, requirementColumns);

  return (
    <AccurityListContainer
      objectType={REQUIREMENT_TYPE}
      title={REQUIREMENT_PLURAL_LABEL}
      icon={REQUIREMENT_ICON}
      columns={requirementColumns}
      defaultListSettings={requirementListSettings}
    />
  );
};

export default RequirementList;
