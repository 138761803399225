import { useCallback, useState } from 'react';
import { updateDataSet } from '../api/dataSetSyncApi';
import { AccurityFetchError } from '../../rest/types';

export const useDataSetSync = () => {
  const [error, setError] = useState<AccurityFetchError>();

  const doSync = useCallback((dataSetId: string, dataStructureIds: (string | number)[]) => {
    setError(undefined);
    return updateDataSet(dataSetId, dataStructureIds)
      .catch(error => setError(error))
  }, [setError]);

  return [doSync, error] as const;
};