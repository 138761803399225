import React from 'react';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps, useField } from 'formik';
import { createReferenceFieldFilter, excludeFieldValueFilter } from '../../../../common/referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_TYPE } from '../../../dataField/types/dataFieldTypes';
import { BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS, BusinessModelMapping, JoinDataField } from '../../types/businessModelMappingTypes';
import { DetailInlineGroup, VersionBrowserColoring } from 'ts-components';

interface DataFieldsJoinProps {
  elementName: string;
  index: number;
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
  coloring?: VersionBrowserColoring
}

const DataFieldsJoin = ({ elementName, index, additionalFilters = [], coloring, formik }: DataFieldsJoinProps) => {

  const leftDataFieldFilter = [
    createReferenceFieldFilter('dataStructure.id', formik.values.joinDataStructureDataField?.leftDataStructure?.id),
    ...additionalFilters,
  ];

  const rightDataFieldFilter = [
    createReferenceFieldFilter('dataStructure.id', formik.values.joinDataStructureDataField?.rightDataStructure?.id),
    ...additionalFilters,
  ];

  const [joinDataFields] = useField<JoinDataField[]>(BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS);

  joinDataFields.value.map(row => row?.leftDataField)
    .filter(value => value !== null)
    .forEach(value => {
      if (!!value && !!value.id) {
        leftDataFieldFilter.push(
          excludeFieldValueFilter('id', value.id)
        );
      }
    });

  joinDataFields.value.map(row => row?.rightDataField)
    .filter(value => value !== null)
    .forEach(value => {
      if (!!value && !!value.id) {
        rightDataFieldFilter.push(
          excludeFieldValueFilter('id', value.id)
        );
      }
    });

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
        coloring={coloring}
      >
        <ReferenceFieldWithFormik
          name={elementName + '.leftDataField'}
          label={DATA_FIELD_LABEL}
          objectType={DATA_FIELD_TYPE}
          icon={DATA_FIELD_ICON}
          additionalFilters={leftDataFieldFilter}
          disableColoring={true}
        />
        <ReferenceFieldWithFormik
          name={elementName + '.rightDataField'}
          label={DATA_FIELD_LABEL}
          objectType={DATA_FIELD_TYPE}
          icon={DATA_FIELD_ICON}
          additionalFilters={rightDataFieldFilter}
          disableColoring={true}
        />
      </DetailInlineGroup>
    </>
  )
};

export default DataFieldsJoin;
