import { ApplicationStatus } from '../../userSettings/types/types';

export const APPLICATION_SETTING = 'application-setting';

export enum AppEnvironment {
  CLOUD = 'cloud',
  ON_PREMISE = 'on-premise',
}

export interface AppSettingsState {
  applicationVersion: string;
  activeProfiles: string;
  customLogoLink?: string;
  customLogoFilename?: string;
  base64image?: string;
  gaCode: string;
  gaAppId: string;
  hotjarId: number;
  hotjarSnippetVersion: number;
  environment?: AppEnvironment;
  keycloakUrl?: string;
  applicationStatus?: ApplicationStatus;
  productTermsLink: string;
  productTermsCheckEnabled: boolean;
  productTermsAccepted: boolean;
  tierName: string;
}

export interface EnvironmentInfoResponse {
  environment: AppEnvironment;
  keycloakUrl: string;
}

export interface PublicSettingsResponse {
  gaCode: string;
  gaAppId: string;
  applicationVersion: string;
  activeProfiles: string;
  hotjarId: number;
  hotjarSnippetVersion: number;
  tierName: string;
}

export type CustomLogo = {
  link?: string;
  filename?: string;
  base64image?: string;
}

export type CustomLogoRequest = {
  customLogoLink?: string;
  customLogoFile?: File;
}

export type ApplicationSettingsResponse = {
  customLogo: CustomLogo;
  productTermsLink: string;
  productTermsCheckEnabled: boolean;
  productTermsAccepted: boolean;
}
