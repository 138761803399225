import * as React from 'react';
import { ReactNode } from 'react';
import { useLoadApplicationSettings, useLoadPublicSettings } from '../appSettings/hooks/appSettingsHooks';
import { useLoadEnvironmentInfo } from '../appSettings/hooks/environmentInfoHooks';
import { useSelector } from 'react-redux';
import { AppSettingsState } from '../appSettings/types/types';
import { useEffectOnMountOnly } from '../hooks/useEffectOnMountOnly';
import { KeycloakClient } from './KeycloakClient';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { loadInstanceInfoRepeatedly } from '../userSettings/rest/userSettingsApi';
import { useLoadUserSettings } from '../userSettings/hooks/userSettingsHooks';

type KeycloakProviderProps = {
  children: ReactNode;
}

export const KeycloakProvider = ({ children }: KeycloakProviderProps) => {
  const loadPublicSettings = useLoadPublicSettings();
  const loadEnvironmentInfo = useLoadEnvironmentInfo();
  const loadUserSettings = useLoadUserSettings();
  const loadApplicationSettings = useLoadApplicationSettings();
  const keycloakUrl = useSelector((state: { appSettings: AppSettingsState }) => state.appSettings.keycloakUrl);

  useEffectOnMountOnly(() => {
    loadEnvironmentInfo();
    loadPublicSettings();
  });

  if (!keycloakUrl) {
    return null;
  }

  const keycloakClient = KeycloakClient.create(keycloakUrl);

  const onEventHandler = (event: string) => {
    if (event === 'onAuthSuccess') {
      keycloakClient
        .loadUserProfile()
        .then(keycloakProfile => loadInstanceInfoRepeatedly(keycloakProfile.email))
        .then(() => Promise.all([
          // reload public settings to reinitialize analytics tools
          loadPublicSettings(),
          loadApplicationSettings(),
          loadUserSettings(),
        ]));
    }
  };

  return <ReactKeycloakProvider
    authClient={keycloakClient}
    autoRefreshToken={true}
    initOptions={{ onLoad: 'check-sso' }}
    onEvent={onEventHandler}
  >
    {children}
  </ReactKeycloakProvider>;
};
