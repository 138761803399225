import React from 'react';
import { useField, useFormikContext } from 'formik';
import { SwitchField } from 'ts-components';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface SwitchFieldWithFormikProps {
  name: string;
  label: string | React.ReactElement;
  readOnly?: boolean
  displayAsCheckbox?: boolean;
  inverted?: boolean;
}

const SwitchFieldWithFormik = ({ name, label, readOnly, displayAsCheckbox, inverted }: SwitchFieldWithFormikProps) => {
  const [field, meta, helper] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(name);

  const noUpdatePermission = formikContext?.status?.permissions?.hasUpdatePermission === false;

  return (
    <SwitchField
      value={field.value}
      onChange={e => helper.setValue(e.currentTarget.checked)}
      errorMessage={meta.error}
      name={name}
      label={label}
      displayAsCheckbox={displayAsCheckbox}
      disabled={readOnly || formikContext?.status?.isDisabled || noUpdatePermission}
      coloring={coloring}
      inverted={inverted}
    />
  );
};

export default SwitchFieldWithFormik;
