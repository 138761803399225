import { doFetch } from '../rest/FetchService';
import { getCustomSearchEndpoint, getReferenceSearchEndpoint, getTextSearchFieldSearchEndpoint } from '../rest/endpoints';
import { AccurityFilter } from '../types/accurityTypes';
import { showErrorMessageFromResponse } from '../userMessages/actions';
import { createReferenceFieldSearch } from './utils/search';
import { CustomEndpointRequestType } from '../rest/types';
import { AccurityReferenceFieldSearchResult } from './types';

export const requestReferenceFieldSearch = (searchEndpoint: string, sortProperty: string, filters: AccurityFilter[] = [], maxResult?: number, customEndpoint?: string) => {
  const searchUrl = customEndpoint
    ? getCustomSearchEndpoint(customEndpoint) + '/' + CustomEndpointRequestType.REFERENCE
    : getReferenceSearchEndpoint(searchEndpoint);
  const referenceSearch = createReferenceFieldSearch(sortProperty, filters, maxResult);
  const requestBody = JSON.stringify(referenceSearch);

  return doFetch(searchUrl, 'POST', requestBody)
    .catch(showErrorMessageFromResponse);
};

export const requestTextSearchFieldSearch = async (searchEndpoint: string, textFilter: string): Promise<AccurityReferenceFieldSearchResult<string>> => {
  const textSearchEndpoint = getTextSearchFieldSearchEndpoint(searchEndpoint, textFilter);

  return doFetch(textSearchEndpoint, 'GET')
    .then((response: string[]) => {
      const searchResponse: AccurityReferenceFieldSearchResult<string> = {
        size: response.length,
        rows: response,
      };
      return searchResponse;
    })
    .catch(showErrorMessageFromResponse);
};
