import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { NumericFormat } from 'ts-components';
import { ATTRIBUTE_DEFINITION_PLURAL_LABEL } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { COMPOSITE_TYPE_PLURAL_LABEL } from '../../compositeType/types/compositeTypeTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useValueTypeListColumns = () => {

  const isOracle = useIsOracleDb();

  return [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    columnFactory.createNumberColumn('childrenCounts.attributeDefinitionsCount', ATTRIBUTE_DEFINITION_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('childrenCounts.compositeTypesCount', COMPOSITE_TYPE_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ]

};