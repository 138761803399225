import { AccurityFilter, AccurityReference } from '../../types/accurityTypes';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useHasReadPermission } from '../../userSettings/hooks/permissions';

export const useReferenceFieldIconAction = <T extends AccurityReference>(objectType: string,
                                                                         value?: T,
                                                                         readOnly?: boolean,
                                                                         customEndpoint?: string,
                                                                         additionalFilters: AccurityFilter[] = [],
): ((objectType: string) => void) | undefined => {

  const navigationController = useAccurityNavigation();
  const hasReadPermission = useHasReadPermission();
  const hasValue = !!value?.id;
  const readOnlyAndEmpty = readOnly && !hasValue;

  if (!hasReadPermission(objectType) || readOnlyAndEmpty) {
    return undefined;
  }

  return hasValue
    ? () => navigationController.openDetailWithObject(objectType, value.id)
    : () => navigationController.openListWithReferenceFilters(objectType, additionalFilters, customEndpoint);

}
