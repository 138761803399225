import React from 'react';

import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import { DetailHeader, StaticField } from 'ts-components';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import { DATA_SOURCE_ICON, DATA_SOURCE_TYPE, DataSource, DataSourceType, DataSourceTypeOptions } from '../types/dataSourceTypes';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import DataSourceDetailBottomBar from './components/DataSourceDetailBottomBar';
import { getDataSourceSaveSuccessMessage } from '../../../common/userMessages/commonMessages';
import { showSnackbarMessage } from '../../../common/userMessages/actions';
import { coreEntityAsFormData } from '../../../common/detail/actions/detailActions';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_PLURAL_LABEL, DATA_SET_TYPE } from '../../dataSet/types/dataSetTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureCheckerOr from '../../../common/userSettings/components/FeatureCheckerOr';
import BigQueryDataSourceFields from './components/BigQueryDataSourceFields';
import ExcelDataSourceFields from './components/ExcelDataSourceFields';
import MongoDbDataSourceFields from './components/MongoDbDataSourceFields';
import JdbcDataSourceFields from './components/JdbcDataSourceFields';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import SnowflakeDataSourceFields from './components/SnowflakeDataSourceFields';

interface DataSourceDetailProps {
  id?: string
}

export const FILE_SIZE_LIMIT = 1024 * 1024 * 10;

const DataSourceDetail = ({ id }: DataSourceDetailProps) => {

  const customSaveSteps = {
    getSaveBody: coreEntityAsFormData,
    showSaveSuccessSnackbar: () => showSnackbarMessage(getDataSourceSaveSuccessMessage()),
  };

  return (
    <AccurityDetailContainer<DataSource>
      objectType={DATA_SOURCE_TYPE}
      id={id}
      customSaveSteps={customSaveSteps}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DataSourceDetailBottomBar
                detailBag={{
                  ...detailBag,
                  showCopyAction: false,
                }}
                formik={formik}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<DataSource>, detailBag: DetailBag<DataSource>) => (
  <ConfirmRemovalDetail
    iconName={DATA_SOURCE_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={'It is currently used by:'}
      inverted={true}
    />
    <FeatureCheckerOr featureIds={[Feature.LOGICAL_DATA_SETS, Feature.PHYSICAL_DATA_SETS]}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_SET_LABEL}
        pluralLabel={DATA_SET_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataSetsCount}
        iconName={DATA_SET_ICON}
        pinIconName={DATA_SOURCE_ICON}
        childFieldType={DATA_SET_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.USED_BY}
      />
    </FeatureCheckerOr>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<DataSource>) => {
  const isBigQuerySource = formik.values.dataSourceType === DataSourceType.BIGQUERY;
  const isExcelSource = formik.values.dataSourceType === DataSourceType.EXCEL;
  const isMongoDbSource =
    formik.values.dataSourceType === DataSourceType.MONGODB_ATLAS ||
    formik.values.dataSourceType === DataSourceType.MONGODB;
  const isJdbcSource =
    formik.values.dataSourceType === DataSourceType.MSSQL ||
    formik.values.dataSourceType === DataSourceType.ORACLE ||
    formik.values.dataSourceType === DataSourceType.POSTGRESQL ||
    formik.values.dataSourceType === DataSourceType.TERADATA ||
    formik.values.dataSourceType === DataSourceType.VERTICA;
  const isSnowflakeSource = formik.values.dataSourceType === DataSourceType.SNOWFLAKE;

  return (
    <>
      <DetailHeader iconName={DATA_SOURCE_ICON}>
        <NameFieldWithFormik label={'Data Source Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik)}
        />}
      />
      <EnumerationFieldWithFormik
        name={'dataSourceType'}
        label={'Data Source Type'}
        options={DataSourceTypeOptions}
        readOnly={formik.status.isUpdateDetail}
      />
      {isBigQuerySource ? <BigQueryDataSourceFields/>
        : isExcelSource ? <ExcelDataSourceFields/>
          : isMongoDbSource ? <MongoDbDataSourceFields/>
            : isSnowflakeSource ? <SnowflakeDataSourceFields/>
              : isJdbcSource ? <JdbcDataSourceFields/>
                : undefined}
      <FeatureCheckerOr featureIds={[Feature.LOGICAL_DATA_SETS, Feature.PHYSICAL_DATA_SETS]}>
        <ChildrenCountFieldWithFormik
          singularLabel={DATA_SET_LABEL}
          pluralLabel={DATA_SET_PLURAL_LABEL}
          value={formik.values.childrenCounts.dataSetsCount}
          iconName={DATA_SET_ICON}
          pinIconName={DATA_SOURCE_ICON}
          childFieldType={DATA_SET_TYPE}
          childrenCountWording={ChildrenCountWording.USED_BY}
        />
      </FeatureCheckerOr>
    </>
  );
};

export default DataSourceDetail;
