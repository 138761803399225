import { AccurityReference } from '../../../common/types/accurityTypes';
import {
  getDisplayNodeNeighboursEndpoint,
  getGenerateBmdGraphEndpoint,
  getGraphLoadEndpoint,
  getGraphSaveEndpoint,
  getHideNodeEndpoint,
  getUpdateAttributesEndpoint
} from '../../../common/rest/endpoints';
import { BUSINESS_MODEL_DIAGRAM_TYPE, Graph, GraphContent } from '../types/businessModelDiagramTypes';
import { doFetch } from '../../../common/rest/FetchService';
import { showErrorMessageFromResponse } from '../../../common/userMessages/actions';

export const requestGenerateGraph = (items: AccurityReference[]): Promise<GraphContent> => {
  const generateEndpoint = getGenerateBmdGraphEndpoint();
  const requestBody = JSON.stringify(items);

  return doFetch(generateEndpoint, 'POST', requestBody)
    .catch(showErrorMessageFromResponse);
}

export const requestSaveGraph = (graph: Graph): Promise<Graph> => {
  const graphEndpoint = getGraphSaveEndpoint(BUSINESS_MODEL_DIAGRAM_TYPE);
  const requestBody = JSON.stringify(graph);

  return doFetch(graphEndpoint, 'PUT', requestBody)
    .catch(showErrorMessageFromResponse);
}

export const requestLoadGraph = (businessModelDiagramId: string): Promise<Graph> => {
  const graphEndpoint = getGraphLoadEndpoint(BUSINESS_MODEL_DIAGRAM_TYPE, businessModelDiagramId);

  return doFetch(graphEndpoint, 'GET')
    .catch(showErrorMessageFromResponse);
}

export const requestHideNode = (graphContent: GraphContent, nodeId: string): Promise<GraphContent> => {
  const hideNodeEndpoint = getHideNodeEndpoint(BUSINESS_MODEL_DIAGRAM_TYPE, nodeId);
  const payload = JSON.stringify(graphContent);

  return doFetch(hideNodeEndpoint, 'POST', payload)
    .catch(showErrorMessageFromResponse);
};

export const requestDisplayNeighboursOfNode = (graphContent: GraphContent, nodeId: string): Promise<GraphContent> => {
  const displayNeighboursEndpoint = getDisplayNodeNeighboursEndpoint(BUSINESS_MODEL_DIAGRAM_TYPE, nodeId);
  const payload = JSON.stringify(graphContent);

  return doFetch(displayNeighboursEndpoint, 'POST', payload)
    .catch(showErrorMessageFromResponse);
}

export const requestUpdateAttributesOfNode = (graphContent: GraphContent, nodeId: string, attributeIds: string[]): Promise<GraphContent> => {
  const attributeIdsString = attributeIds.join(',');
  const updateAttributesEndpoint = getUpdateAttributesEndpoint(BUSINESS_MODEL_DIAGRAM_TYPE, nodeId, attributeIdsString);
  const payload = JSON.stringify(graphContent);

  return doFetch(updateAttributesEndpoint, 'POST', payload)
    .catch(showErrorMessageFromResponse);
}
