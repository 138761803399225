import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnProfilingReduxState, ProfileResult } from '../types/types';
import { uniq } from 'lodash';

const initialState: ColumnProfilingReduxState = {
  result: undefined,
  inProgressProfiling: [],
  lastProfiledDataFieldId: undefined
};

const setColumnProfilingResult = (state: ColumnProfilingReduxState, action: PayloadAction<ProfileResult>) => {
  state.result = {
    ...action.payload,
    data: typeof action.payload.data === 'string' ? JSON.parse(action.payload.data) : action.payload.data
  };
};

const clearColumnProfilingData = (state: ColumnProfilingReduxState, action: PayloadAction<void>) => {
  state.result = undefined;
};

const markDataFieldIsBeingProfiled = (state: ColumnProfilingReduxState, action: PayloadAction<{dataFieldId: string}>) => {
  state.inProgressProfiling = uniq([...state.inProgressProfiling, action.payload.dataFieldId]);
};

const markDataFieldAsProfiled = (state: ColumnProfilingReduxState, action: PayloadAction<{dataFieldId: string, isSuccessful: boolean}>) => {
  state.inProgressProfiling = state.inProgressProfiling.filter(dataFieldId => String(dataFieldId) !== String(action.payload.dataFieldId));
  if (action.payload.isSuccessful) {
    state.lastProfiledDataFieldId = action.payload.dataFieldId;
  }
};

const columnProfilingSlice = createSlice({
  name: 'columnProfiling',
  initialState: initialState,
  reducers: {
    setColumnProfilingResultInStore: setColumnProfilingResult,
    clearColumnProfilingDataInStore: clearColumnProfilingData,
    markDataFieldIsBeingProfiledInStore: markDataFieldIsBeingProfiled,
    markDataFieldAsProfiledInStore: markDataFieldAsProfiled
  },
});

export const {
  clearColumnProfilingDataInStore,
  setColumnProfilingResultInStore,
  markDataFieldAsProfiledInStore,
  markDataFieldIsBeingProfiledInStore
} = columnProfilingSlice.actions;

export default columnProfilingSlice.reducer;
