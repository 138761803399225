import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../../common/redux/types';
import { commonReducers } from '../../../../common/redux/commonReducers';
import { PROCESS_MAPPING_TYPE, ProcessMappingState } from '../types/processMappingTypes';

const initialState: ProcessMappingState = getInitialCoreEntityState(PROCESS_MAPPING_TYPE);

const processMappingSlice = createSlice({
  name: PROCESS_MAPPING_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default processMappingSlice.reducer;
