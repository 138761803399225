import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccuritySnackbar, UserMessageContainer } from 'ts-components';

import { dismissAllToasts, dismissSnackbar, dismissToast } from '../redux/userMessagesSlice';
import { UserMessagesState } from '../types/userMessagesTypes';

const UserMessages = () => {

  const dispatch = useDispatch();
  const toasts = useSelector(({ userMessages }: { userMessages: UserMessagesState }) => userMessages.toasts);
  const snackbarMessage = useSelector(({ userMessages }: { userMessages: UserMessagesState }) => userMessages.snackbarMessage);

  return (
    <>
      <UserMessageContainer
        messages={toasts}
        onDismiss={message => dispatch(dismissToast(message))}
        onDismissAll={() => dispatch(dismissAllToasts())}
      />
      <AccuritySnackbar
        message={snackbarMessage}
        onClose={() => dispatch(dismissSnackbar())}
      />
    </>
  );
};

export default UserMessages;
