import React from 'react';
import FileChooserFieldWithFormik from '../../../../common/detail/formik/FileChooserFieldWithFormik';
import { useFormikContext } from 'formik';
import { FILE_SIZE_LIMIT } from '../DataSourceDetail';

const ACCEPTED_EXCEL_FILE_TYPES = '.xls,.xlsx';

const ExcelDataSourceFields = () => {
  const formik = useFormikContext();
  return (
    <>
      {!formik.status.isVersionBrowser &&
      <FileChooserFieldWithFormik
        name={'fileData'}
        label={'Select File'}
        fileNameField={'fileName'}
        accept={ACCEPTED_EXCEL_FILE_TYPES}
        sizeRestriction={FILE_SIZE_LIMIT}
        disabled={!formik.status.permissions.hasCreatePermission}
      />}
    </>
  );
};

export default ExcelDataSourceFields;
