import React from 'react';
import CollectionFieldWithFormik from './CollectionFieldWithFormik';
import SingleLineFieldWithFormik from './SinglelineFieldWithFormik';
import EnumerationFieldWithFormik from './EnumerationFieldWithFormik';
import { DetailInlineGroup } from 'ts-components';
import ReferenceFieldWithFormik from './ReferenceFieldWithFormik';
import { VALUE_TYPE_ICON, VALUE_TYPE_LABEL, VALUE_TYPE_TYPE } from '../../../businessGlossary/valueType/types/valueTypeTypes';
import { ENTITY_ICON, ENTITY_TYPE } from '../../../businessGlossary/entity/types/entityTypes';
import { FormikProps, useFormikContext } from 'formik';
import {
  ComponentType,
  ComponentTypeOptions,
  COMPOSITE_TYPE_COMPONENT_TYPE_ICON,
  COMPOSITE_TYPE_COMPONENT_TYPE_LABEL,
  CompositeType,
  TARGET_ENTITY_LABEL
} from '../../../businessGlossary/compositeType/types/compositeTypeTypes';
import { get } from 'lodash';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

const COMPONENTS_FIELD_NAME = 'components';

interface ComponentsFieldWithFormikProps {
  formik: FormikProps<CompositeType>;
}

const ComponentsFieldWithFormik = ({ formik }: ComponentsFieldWithFormikProps) => {
  return (
    <CollectionFieldWithFormik
      name={COMPONENTS_FIELD_NAME}
      title={getComponentFieldTitle(formik.values.components.length)}
      icon={COMPOSITE_TYPE_COMPONENT_TYPE_ICON}
      rowValidation={(value) => value && value.name && (value.valueType || value.targetEntity)}
      renderRow={(elementName, index, collectionValues) =>
        <ComponentValueRow
          elementName={elementName}
          index={index}
          collectionValues={collectionValues}
        />}
      initialRowValue={{ componentType: ComponentType.VALUE }}
    />
  );
};

const ComponentValueRow = ({ elementName, index, collectionValues }: { elementName: string, index: number, collectionValues: any[] }) => {
  const formikContext = useFormikContext();
  const isAlreadySaved = get(formikContext?.values, elementName).id !== undefined;
  const coloring = useVersionBrowserColoring(elementName);

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
        coloring={coloring}
      >
        <EnumerationFieldWithFormik
          name={elementName + '.componentType'}
          label={COMPOSITE_TYPE_COMPONENT_TYPE_LABEL}
          options={ComponentTypeOptions}
          disableColoring={true}
          readOnly={isAlreadySaved}
        />
        <SingleLineFieldWithFormik
          name={elementName + '.name'}
          label={'Component Name'}
          disableColoring={true}
        />
      </DetailInlineGroup>
      {collectionValues[index]?.componentType === ComponentType.VALUE &&
      <ReferenceFieldWithFormik
        name={elementName + '.valueType'}
        label={VALUE_TYPE_LABEL}
        objectType={VALUE_TYPE_TYPE}
        icon={VALUE_TYPE_ICON}
        coloringOverwrite={elementName}
      />
      }
      {collectionValues[index]?.componentType === ComponentType.REFERENCE &&
      <ReferenceFieldWithFormik
        name={elementName + '.targetEntity'}
        label={TARGET_ENTITY_LABEL}
        objectType={ENTITY_TYPE}
        icon={ENTITY_ICON}
        coloringOverwrite={elementName}
      />
      }
    </>
  );
};

const getComponentFieldTitle = (numberOfComponents: number) => {
  const components = numberOfComponents === null || numberOfComponents === undefined ? 0 : numberOfComponents;
  if (numberOfComponents === 1) {
    return `Composed of ${components} Component`;
  } else {
    return `Composed of ${components} Components`;
  }
};

export default ComponentsFieldWithFormik;
