import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { EnumOption } from 'ts-components';

export const PROCESS_DIAGRAM_TYPE = 'process-diagram';
export const PROCESS_DIAGRAM_LABEL = 'Process Diagram';
export const PROCESS_DIAGRAM_PLURAL_LABEL = 'Process Diagrams';
export const PROCESS_DIAGRAM_ICON = 'processDiagram';

export const PROCESS_DIAGRAM_BASED_ON_TYPE_LABEL = 'Based On';

export interface ProcessDiagramState extends CoreEntityState<ProcessDiagram> {
}

export interface ProcessDiagram extends AccurityCoreEntity {
  status?: AccurityReference;
  processDiagramBasedOnType: ProcessDiagramBasedOnType;
  customPropertyValues: CustomPropertyValues;
}

export enum ProcessDiagramBasedOnType {
  ATTRIBUTES = 'ATTRIBUTES',
  BUSINESS_TERMS = 'BUSINESS_TERMS',
  DATA_ASSETS = 'DATA_ASSETS',
  ENTITIES = 'ENTITIES',
  PROCESSES = 'PROCESSES',
  PROCESS_STEPS = 'PROCESS_STEPS',
}

export const ProcessDiagramBasedOnTypeOptions: EnumOption[] = [
  { value: ProcessDiagramBasedOnType.ATTRIBUTES, label: 'Attributes' },
  { value: ProcessDiagramBasedOnType.BUSINESS_TERMS, label: 'Business Terms' },
  { value: ProcessDiagramBasedOnType.DATA_ASSETS, label: 'Data Assets' },
  { value: ProcessDiagramBasedOnType.ENTITIES, label: 'Entities' },
  { value: ProcessDiagramBasedOnType.PROCESSES, label: 'Processes' },
  { value: ProcessDiagramBasedOnType.PROCESS_STEPS, label: 'Process Steps' },
];

export interface ProcessDiagramBasedOnConfig {
  [basedOnType: string]: {
    objectType: string;
    label: string;
    tagsLabel: string;
    groupBy?: (option: any) => string;
  }
}
