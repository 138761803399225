import { addCoreEntityToStore, setIsDetailLoading } from '../../redux/commonReducers';
import { getGetEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../userMessages/actions';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { AccurityFetchError } from '../../rest/types';
import { AccurityNavigationController, useAccurityNavigation } from '../../navigation/hooks';
import rootStore from '../../redux/rootStore';

export type ReloadSteps<T extends AccurityCoreEntity> = {
  setLoadingTrue: () => void,
  getReloadEndpoint: (objectType: string, id: string) => string,
  doReloadRequest: (endpoint: string) => Promise<T>,
  addReloadedEntityToStore: (reloadedCoreEntity: T) => void,
  refreshDetail: (reloadedCoreEntity: T, navigationController: AccurityNavigationController) => void,
  showReloadSuccessSnackbar: (reloadedCoreEntity: T, successSnackbar?: (entity: T) => string) => void,
  handleReloadError: (reloadError: AccurityFetchError, navigationController: AccurityNavigationController) => void,
  setLoadingFalse: () => void,
};

export type CustomReloadSteps<T extends AccurityCoreEntity> = Partial<ReloadSteps<T>>;

export type ReloadAction<T extends AccurityCoreEntity> = (successSnackbar?: (entity: T) => string) => void;

const getDefaultReloadSteps = <T extends AccurityCoreEntity>(): ReloadSteps<T> => {
  return {
    setLoadingTrue: () => {
      rootStore.dispatch(setIsDetailLoading(true));
    },

    getReloadEndpoint: (objectType: string, id: string) => {
      return getGetEndpoint(objectType, id);
    },

    doReloadRequest: (reloadUrl: string) => {
      return doFetch(reloadUrl, 'GET')
        .catch(showErrorMessageFromResponse);
    },

    addReloadedEntityToStore: (reloadedCoreEntity: T) => {
      const fetchedAt = Date.now();
      rootStore.dispatch(addCoreEntityToStore({ coreEntity: reloadedCoreEntity, fetchedAt: fetchedAt }));
    },

    refreshDetail: (reloadedCoreEntity: T, navigationController: AccurityNavigationController) => {
      navigationController.openDetailWithObject(reloadedCoreEntity.objectType, reloadedCoreEntity.id, true, true);
    },

    showReloadSuccessSnackbar: (reloadedCoreEntity: T, successSnackbar?: (entity: T) => string) => {
      if (successSnackbar) {
        showSnackbarMessage(successSnackbar(reloadedCoreEntity));
      }
    },

    handleReloadError: (reloadError: AccurityFetchError, navigationController: AccurityNavigationController) => {
      navigationController.closeDetail()
    },

    setLoadingFalse: () => {
      rootStore.dispatch(setIsDetailLoading(false));
    },
  }
};

export const useReloadAction = <T extends AccurityCoreEntity>(objectType: string,
                                                              id: string,
                                                              customReloadSteps?: CustomReloadSteps<T>
): ReloadAction<T> => {
  const navigationController = useAccurityNavigation();

  return (successSnackbar?: (entity: T) => string) => {
    const reloadSteps: ReloadSteps<T> = {
      ...getDefaultReloadSteps(),
      ...customReloadSteps
    }

    reloadSteps.setLoadingTrue();
    const reloadEndpoint = reloadSteps.getReloadEndpoint(objectType, id);
    reloadSteps.doReloadRequest(reloadEndpoint)
      .then(reloadedCoreEntity => {
        reloadSteps.addReloadedEntityToStore(reloadedCoreEntity);
        reloadSteps.refreshDetail(reloadedCoreEntity, navigationController);
        reloadSteps.showReloadSuccessSnackbar(reloadedCoreEntity, successSnackbar);
      })
      .catch((reloadError: AccurityFetchError) => reloadSteps.handleReloadError(reloadError, navigationController))
      .finally(reloadSteps.setLoadingFalse);
  };
}
