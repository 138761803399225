import React from 'react';
import { Feature } from '../../../common/userSettings/features/features';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { DetailHeader, objectTypeToIconName } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import {
  BUSINESS_MODEL_DIAGRAM_ICON,
  BUSINESS_MODEL_DIAGRAM_TYPE,
  BusinessModelDiagram
} from '../types/businessModelDiagramTypes';
import OpenDiagramButton from './OpenDiagramButton';
import { CustomRemoveSteps } from '../../../common/detail/actions/detailRemove';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface BusinessModelDiagramDetailProps {
  id?: string,
}

const BusinessModelDiagramDetail = ({ id }: BusinessModelDiagramDetailProps) => {

  const customRemoveSteps: CustomRemoveSteps<BusinessModelDiagram> = {
    closeDetail: (navigationController => {
      const { diagramType, diagramId } = navigationController.getCurrentNavigation();
      const isCanvasOpen = diagramType === BUSINESS_MODEL_DIAGRAM_TYPE && diagramId === id;
      if (isCanvasOpen) {
        navigationController.openListAndCloseDetail(BUSINESS_MODEL_DIAGRAM_TYPE);
      } else {
        navigationController.closeDetail();
      }
    }),
  }

  return (
    <AccurityDetailContainer<BusinessModelDiagram>
      objectType={BUSINESS_MODEL_DIAGRAM_TYPE}
      id={id}
      customRemoveSteps={customRemoveSteps}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar objectType={BUSINESS_MODEL_DIAGRAM_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (detailBag: DetailBag<BusinessModelDiagram>) => (
  <ConfirmRemovalDetail
    iconName={objectTypeToIconName(BUSINESS_MODEL_DIAGRAM_TYPE)}
    detailBag={detailBag}
  />
);

const getDetailFields = (formik: FormikProps<BusinessModelDiagram>) => {
  return (
    <FeatureChecker featureId={Feature.BUSINESS_MODEL_DIAGRAMS}>
      <DetailHeader iconName={BUSINESS_MODEL_DIAGRAM_ICON}>
        <NameFieldWithFormik label={'Business Model Diagram Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton getDetailFields={getDetailFields}/>}
      />
      <StatusFieldWithFormik/>
      <CustomPropertyFieldsContainer
        forObjectType={GlossaryCustomPropertyObjectType.BUSINESS_MODEL_DIAGRAM}
      />
      <OpenDiagramButton
        icon={BUSINESS_MODEL_DIAGRAM_ICON}
        diagramType={BUSINESS_MODEL_DIAGRAM_TYPE}
        diagramId={formik.values.id}
      />
    </FeatureChecker>
  );
};

export default BusinessModelDiagramDetail;
