import { CustomPropertyTargetObjectType, customPropertyTargetObjectTypesWithSupportiveColumns } from '../../businessGlossary/customProperties/types';
import { ATTRIBUTE_ICON, ATTRIBUTE_TYPE } from '../../businessGlossary/attribute/types/attributeTypes';
import { ATTRIBUTE_DEFINITION_ICON, ATTRIBUTE_DEFINITION_TYPE } from '../../businessGlossary/attributeDefinition/types/attributeDefinitionTypes';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_TYPE } from '../../businessGlossary/businessRule/types/businessRuleTypes';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_TYPE } from '../../businessGlossary/businessTerm/types/businessTermTypes';
import { DATA_ASSET_ICON, DATA_ASSET_TYPE } from '../../businessGlossary/dataAsset/types/dataAssetTypes';
import { DATA_SET_ICON, DATA_SET_TYPE } from '../../businessGlossary/dataSet/types/dataSetTypes';
import { ENTITY_ICON, ENTITY_TYPE } from '../../businessGlossary/entity/types/entityTypes';
import { REQUIREMENT_ICON, REQUIREMENT_TYPE } from '../../businessGlossary/requirement/types/requirementTypes';
import { USER_ICON, USER_TYPE } from '../../businessGlossary/user/types/userTypes';
import { PROCESS_ICON, PROCESS_TYPE } from '../../businessGlossary/process/types/processTypes';
import { CustomProperty } from './customProperty/types/customPropertyTypes';
import { Tooltip } from 'ts-components';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_TYPE } from '../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DATA_FIELD_ICON, DATA_FIELD_TYPE } from '../../businessGlossary/dataField/types/dataFieldTypes';
import { PROCESS_STEP_ICON, PROCESS_STEP_TYPE } from '../../businessGlossary/process/step/types/processStepTypes';
import { BUSINESS_MODEL_DIAGRAM_ICON, BUSINESS_MODEL_DIAGRAM_TYPE } from '../../businessGlossary/businessModelDiagram/types/businessModelDiagramTypes';
import { PROCESS_DIAGRAM_ICON, PROCESS_DIAGRAM_TYPE } from '../../businessGlossary/processDiagram/types/processDiagramTypes';

export const targetObjectTypeTypeEndpoint = (targetObjectType?: string) => {

  if (CustomPropertyTargetObjectType.ATTRIBUTE === targetObjectType) {
    return ATTRIBUTE_TYPE;
  } else if (CustomPropertyTargetObjectType.ATTRIBUTE_DEFINITION === targetObjectType) {
    return ATTRIBUTE_DEFINITION_TYPE;
  } else if (CustomPropertyTargetObjectType.BUSINESS_MODEL_DIAGRAM === targetObjectType) {
    return BUSINESS_MODEL_DIAGRAM_TYPE;
  } else if (CustomPropertyTargetObjectType.BUSINESS_RULE === targetObjectType) {
    return BUSINESS_RULE_TYPE;
  } else if (CustomPropertyTargetObjectType.BUSINESS_TERM === targetObjectType) {
    return BUSINESS_TERM_TYPE;
  } else if (CustomPropertyTargetObjectType.DATA_ASSET === targetObjectType) {
    return DATA_ASSET_TYPE;
  } else if (CustomPropertyTargetObjectType.DATA_SET === targetObjectType) {
    return DATA_SET_TYPE;
  } else if (CustomPropertyTargetObjectType.DATA_STRUCTURE === targetObjectType) {
    return DATA_STRUCTURE_TYPE;
  } else if (CustomPropertyTargetObjectType.DATA_FIELD === targetObjectType) {
    return DATA_FIELD_TYPE;
  } else if (CustomPropertyTargetObjectType.ENTITY === targetObjectType) {
    return ENTITY_TYPE;
  } else if (CustomPropertyTargetObjectType.PROCESS === targetObjectType) {
    return PROCESS_TYPE;
  } else if (CustomPropertyTargetObjectType.PROCESS_STEP === targetObjectType) {
    return PROCESS_STEP_TYPE;
  } else if (CustomPropertyTargetObjectType.PROCESS_DIAGRAM === targetObjectType) {
    return PROCESS_DIAGRAM_TYPE;
  } else if (CustomPropertyTargetObjectType.REQUIREMENT === targetObjectType) {
    return REQUIREMENT_TYPE;
  } else if (CustomPropertyTargetObjectType.USER === targetObjectType) {
    return USER_TYPE;
  } else {
    console.error('No endpoint found for Reference Type: ', targetObjectType);
    return USER_TYPE; //TODO: revisit
  }
};

export const targetObjectTypeIcon = (targetObjectType?: string) => {

  if (CustomPropertyTargetObjectType.ATTRIBUTE === targetObjectType) {
    return ATTRIBUTE_ICON;
  } else if (CustomPropertyTargetObjectType.ATTRIBUTE_DEFINITION === targetObjectType) {
    return ATTRIBUTE_DEFINITION_ICON;
  } else if (CustomPropertyTargetObjectType.BUSINESS_MODEL_DIAGRAM === targetObjectType) {
    return BUSINESS_MODEL_DIAGRAM_ICON;
  } else if (CustomPropertyTargetObjectType.BUSINESS_RULE === targetObjectType) {
    return BUSINESS_RULE_ICON;
  } else if (CustomPropertyTargetObjectType.BUSINESS_TERM === targetObjectType) {
    return BUSINESS_TERM_ICON;
  } else if (CustomPropertyTargetObjectType.DATA_ASSET === targetObjectType) {
    return DATA_ASSET_ICON;
  } else if (CustomPropertyTargetObjectType.DATA_SET === targetObjectType) {
    return DATA_SET_ICON;
  } else if (CustomPropertyTargetObjectType.DATA_STRUCTURE === targetObjectType) {
    return DATA_STRUCTURE_ICON;
  } else if (CustomPropertyTargetObjectType.DATA_FIELD === targetObjectType) {
    return DATA_FIELD_ICON;
  } else if (CustomPropertyTargetObjectType.ENTITY === targetObjectType) {
    return ENTITY_ICON;
  } else if (CustomPropertyTargetObjectType.PROCESS === targetObjectType) {
    return PROCESS_ICON;
  } else if (CustomPropertyTargetObjectType.PROCESS_STEP === targetObjectType) {
    return PROCESS_STEP_ICON;
  } else if (CustomPropertyTargetObjectType.PROCESS_DIAGRAM === targetObjectType) {
    return PROCESS_DIAGRAM_ICON;
  } else if (CustomPropertyTargetObjectType.REQUIREMENT === targetObjectType) {
    return REQUIREMENT_ICON;
  } else if (CustomPropertyTargetObjectType.USER === targetObjectType) {
    return USER_ICON;
  } else {
    console.error('No endpoint found for Reference Type: ', targetObjectType);
    return USER_ICON; //TODO: revisit
  }
};

export const generateTooltipForCP = (customProperty: CustomProperty): Tooltip | undefined => {
  return customProperty.description?.plainTextValue ? {
    value: customProperty.name,
    description: customProperty.description?.plainTextValue
  } : undefined;
};

export const assembleAttributeNames = (values?: any[]) => {
  const attributeNames = values ? values?.filter(value => value?.entityReference?.name !== undefined && value?.attributeDefinitionReference?.name !== undefined)
    .map(row => row?.entityReference?.name + '.' + row?.attributeDefinitionReference?.name) : [];

  let attributes = [];

  for (let i = 0; i < attributeNames.length; i++) {
    attributes[i] = { name: attributeNames[i] }
  }

  return attributes;
};

export const getDisplayCustomPropertyValueField = (targetObjectType: CustomPropertyTargetObjectType) => {
  return !customPropertyTargetObjectTypesWithSupportiveColumns.includes(targetObjectType)
};

export const getSupportiveCustomPropertyValueFieldLabel = (label: string, additionalLabel: string) => {
  return label + ' - ' + additionalLabel;
};

export const getSupportiveCustomPropertyDefaultValueFieldLabel = (label: string, additionalLabel: string) => {
  return label + ' ' + additionalLabel;
};