import React from 'react';
import StatusField from '../commonFields/StatusField';
import { useField, useFormikContext } from 'formik';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

type StatusFieldWithFormikProps = {
  inherited?: boolean;
}

const StatusFieldWithFormik = ({ inherited }: StatusFieldWithFormikProps) => {
  const [field, meta, helper] = useField('status');
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring('status');

  return (
    <StatusField
      value={field.value}
      errorMessage={meta.error}
      setValue={helper.setValue}
      readOnly={formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default StatusFieldWithFormik;
