import { CollapsingGroup, DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE } from '../../../dataStructure/types/dataStructureTypes';
import {
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_LEFT_DATA_STRUCTURE_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_RIGHT_DATA_STRUCTURE_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL,
  BusinessModelMapping
} from '../../types/businessModelMappingTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps } from 'formik';
import { createReferenceFieldFilter } from '../../../../common/referenceField/utils/filters';
import { clearDataFieldJoinsOnDataStructureRemove, getBusinessModelMappingDataStructureDataFieldJoinCountLabel } from '../businessModelMappingDetailUtils';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import DataFieldsJoin from './DataFieldsJoin';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface DataStructureDataFieldJoinProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
}

const DataStructureDataFieldJoin = ({ additionalFilters = [], formik }: DataStructureDataFieldJoinProps) => {

  const dataStructuresFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.targetDataSet?.id),
    ...additionalFilters,
  ];

  const leftDataStructureId = formik.values.joinDataStructureDataField?.leftDataStructure?.id;
  const rightDataStructureId = formik.values.joinDataStructureDataField?.rightDataStructure?.id;
  const joins = formik.getFieldProps('joinDataStructureDataField.joinDataFields').value;
  const isStructuresSelected = leftDataStructureId && rightDataStructureId;
  const isVersionBrowser = formik.status.isVersionBrowser;
  const coloring = useVersionBrowserColoring(BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD);

  return (
    <>
      <CollapsingGroup
        name={'targetJoins'}
        title={getBusinessModelMappingDataStructureDataFieldJoinCountLabel(joins, BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL, BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL)}
        icon={BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON}
        expandedByDefault={isVersionBrowser}
        expandable={true}
      >
        <DetailInlineGroup
          indentRight={!isVersionBrowser}
          coloring={coloring}
        >
          <ReferenceFieldWithFormik
            name={BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_LEFT_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            objectType={DATA_STRUCTURE_TYPE}
            icon={DATA_STRUCTURE_ICON}
            additionalFilters={dataStructuresFilter}
            setValue={(leftDataStructure) => {
              clearDataFieldJoinsOnDataStructureRemove(formik, BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_LEFT_DATA_STRUCTURE_FIELD, leftDataStructure, rightDataStructureId);
            }}
            disableColoring={true}
          />
          <ReferenceFieldWithFormik
            name={BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_RIGHT_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            objectType={DATA_STRUCTURE_TYPE}
            icon={DATA_STRUCTURE_ICON}
            additionalFilters={dataStructuresFilter}
            setValue={(rightDataStructure) => {
              clearDataFieldJoinsOnDataStructureRemove(formik, BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_RIGHT_DATA_STRUCTURE_FIELD, rightDataStructure, leftDataStructureId);
            }}
            disableColoring={true}
          />
        </DetailInlineGroup>
        {isStructuresSelected &&
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS}
          insideCollapsingGroup={false}
          rowValidation={(value) => value && value.leftDataField && value.leftDataField.id && value.rightDataField && value.rightDataField.id}
          renderRow={(elementName, index) =>
            <DataFieldsJoin
              elementName={elementName}
              index={index}
              additionalFilters={additionalFilters}
              coloring={coloring}
              formik={formik}
            />
          }
        />}
      </CollapsingGroup>
    </>
  )
};

export default DataStructureDataFieldJoin;
