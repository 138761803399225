import React from 'react';
import DataSetList from '../dataSet/list/DataSetList';
import { DATA_SET_TYPE } from '../dataSet/types/dataSetTypes';
import DataStructureList from '../dataStructure/list/DataStructureList';
import { DATA_STRUCTURE_TYPE } from '../dataStructure/types/dataStructureTypes';
import StatusList from '../status/list/StatusList';
import { STATUS_TYPE } from '../status/types/statusTypes';
import BusinessTermList from '../businessTerm/list/BusinessTermList';
import { BUSINESS_TERM_TYPE } from '../businessTerm/types/businessTermTypes';
import DataFieldList from '../dataField/list/DataFieldList';
import { DATA_FIELD_TYPE } from '../dataField/types/dataFieldTypes';
import { USER_TYPE } from '../user/types/userTypes';
import UserList from '../user/list/UserList';
import BusinessTermDetail from '../businessTerm/detail/BusinessTermDetail';
import DataSetDetail from '../dataSet/detail/DataSetDetail';
import DataStructureDetail from '../dataStructure/detail/DataStructureDetail';
import StatusDetail from '../status/detail/StatusDetail';
import UserDetail from '../user/detail/UserDetail';
import DataFieldDetail from '../dataField/detail/DataFieldDetail';
import { USER_PROFILE } from '../../common/userPopup/types';
import UserProfileDetail from '../../common/userPopup/UserProfileDetail';
import { ABOUT_PAGE } from '../../common/about/types/types';
import AboutDetailContainer from '../../common/about/detail/AboutDetailContainer';
import { useAccurityNavigation } from '../../common/navigation/hooks';
import Analytics from '../../common/analytics';
import { CUSTOM_PROPERTY_GROUP_TYPE } from '../../common/customProperties/customPropertyGroup/types/customPropertyGroupTypes';
import CustomPropertyGroupList from '../../common/customProperties/customPropertyGroup/list/CustomPropertyGroupList';
import CustomPropertyGroupDetail from '../../common/customProperties/customPropertyGroup/detail/CustomPropertyGroupDetail';
import { CUSTOM_PROPERTY_TYPE } from '../../common/customProperties/customProperty/types/customPropertyTypes';
import GlossaryCustomPropertyDetail from '../customProperties/GlossaryCustomPropertyDetail';
import GlossaryCustomPropertyList from '../customProperties/GlossaryCustomPropertyList';
import DataLineageDiagram from '../dataLineageDiagram/components/DataLineageDiagram';
import FeatureChecker from '../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../common/userSettings/features/features';
import { APPLICATION_SETTING } from '../../common/appSettings/types/types';
import ApplicationSettingsDetailContainer from '../../common/appSettings/detail/ApplicationSettingsDetailContainer';
import { AccurityMainPanel } from 'ts-components';
import { DATA_SOURCE_TYPE } from '../dataSource/types/dataSourceTypes';
import DataSourceDetail from '../dataSource/detail/DataSourceDetail';
import DataSourceList from '../dataSource/list/DataSourceList';
import { ENTITY_TYPE } from '../entity/types/entityTypes';
import EntityDetail from '../entity/detail/EntityDetail';
import EntityList from '../entity/list/EntityList';
import { COMPOSITE_TYPE_TYPE } from '../compositeType/types/compositeTypeTypes';
import CompositeTypeList from '../compositeType/list/CompositeTypeList';
import CompositeTypeDetail from '../compositeType/detail/CompositeTypeDetail';
import { ATTRIBUTE_DEFINITION_TYPE } from '../attributeDefinition/types/attributeDefinitionTypes';
import AttributeDefinitionList from '../attributeDefinition/list/AttributeDefinitionList';
import AttributeDefinitionDetail from '../attributeDefinition/detail/AttributeDefinitionDetail';
import { ATTRIBUTE_TYPE } from '../attribute/types/attributeTypes';
import AttributeList from '../attribute/list/AttributeList';
import AttributeDetail from '../attribute/detail/AttributeDetail';
import { VALUE_TYPE_TYPE } from '../valueType/types/valueTypeTypes';
import ValueTypeDetail from '../valueType/detail/ValueTypeDetail';
import ValueTypeList from '../valueType/list/ValueTypeList';
import { BUSINESS_RULE_TYPE } from '../businessRule/types/businessRuleTypes';
import BusinessRuleDetail from '../businessRule/detail/BusinessRuleDetail';
import BusinessRuleList from '../businessRule/list/BusinessRuleList';
import RequirementList from '../requirement/list/RequirementList';
import RequirementDetail from '../requirement/detail/RequirementDetail';
import ReferencingBusinessTermList from '../businessTerm/referencingList/ReferencingBusinessTermList';
import { DATA_ASSET_TYPE } from '../dataAsset/types/dataAssetTypes';
import DataAssetList from '../dataAsset/list/DataAssetList';
import DataAssetDetail from '../dataAsset/detail/DataAssetDetail';
import { BUSINESS_MODEL_MAPPING_TYPE } from '../businessModelMapping/types/businessModelMappingTypes';
import BusinessModelMappingList from '../businessModelMapping/list/BusinessModelMappingList';
import { REQUIREMENT_TYPE } from '../requirement/types/requirementTypes';
import BusinessModelMappingDetail from '../businessModelMapping/detail/BusinessModelMappingDetail';
import { PROCESS_TYPE } from '../process/types/processTypes';
import ProcessList from '../process/list/ProcessList';
import ProcessDetail from '../process/detail/ProcessDetail';
import { PROCESS_STEP_TYPE } from '../process/step/types/processStepTypes';
import ProcessStepList from '../process/step/list/ProcessStepList';
import ProcessStepDetail from '../process/step/detail/ProcessStepDetail';
import { PROCESS_MAPPING_TYPE } from '../process/mapping/types/processMappingTypes';
import ProcessMappingList from '../process/mapping/list/ProcessMappingList';
import ProcessMappingDetail from '../process/mapping/detail/ProcessMappingDetail';
import TaggedEntityList from '../taggedEntity/list/TaggedEntityList';
import GlobalSearchList from '../../common/globalSearch/components/GlobalSearchList';
import { TECHNICAL_DATA_MAPPING_TYPE } from '../technicalModelMapping/types/technicalDataMappingTypes';
import TechnicalDataMappingList from '../technicalModelMapping/list/TechnicalDataMappingList';
import TechnicalDataMappingDetail from '../technicalModelMapping/detail/TechnicalDataMappingDetail';
import { BUSINESS_MODEL_DIAGRAM_TYPE } from '../businessModelDiagram/types/businessModelDiagramTypes';
import BusinessModelDiagramDetail from '../businessModelDiagram/detail/BusinessModelDiagramDetail';
import BusinessModelDiagramList from '../businessModelDiagram/list/BusinessModelDiagramList';
import BusinessModelDiagramComponent from '../businessModelDiagram/diagram/BusinessModelDiagramComponent';
import FeatureCheckerOr from '../../common/userSettings/components/FeatureCheckerOr';
import AccurityColumnProfilingCanvas from '../../common/columnProfiling/components/ColumnProfilingCanvas';
import AccurityListOverlay from '../../common/list/components/AccurityListOverlay';
import { PROCESS_DIAGRAM_TYPE } from '../processDiagram/types/processDiagramTypes';
import ProcessDiagramList from '../processDiagram/list/ProcessDiagramList';
import ProcessDiagramDetail from '../processDiagram/detail/ProcessDiagramDetail';
import ProcessDiagramComponent from '../processDiagram/diagram/ProcessDiagramComponent';
import {
  BUSINESS_MODEL_MAPPING_DIAGRAM_TYPE,
  BUSINESS_TERM_DIAGRAM_TYPE,
  DATA_LINEAGE_DIAGRAM_TYPE,
  DiagramLineageType,
  TECHNICAL_DATA_MAPPING_DIAGRAM_TYPE
} from '../dataLineageDiagram/types/types';

const getListComponent = (entityType?: string) => {
  switch (entityType) {
    case BUSINESS_TERM_TYPE:
      return <BusinessTermList/>;
    case BUSINESS_RULE_TYPE:
      return (
        <FeatureChecker featureId={Feature.BUSINESS_RULES}>
          <BusinessRuleList/>
        </FeatureChecker>
      );
    case ENTITY_TYPE:
      return <EntityList/>;
    case COMPOSITE_TYPE_TYPE:
      return <CompositeTypeList/>;
    case ATTRIBUTE_DEFINITION_TYPE:
      return <AttributeDefinitionList/>;
    case ATTRIBUTE_TYPE:
      return <AttributeList/>;
    case BUSINESS_MODEL_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.BUSINESS_MODEL_DIAGRAMS}>
          <BusinessModelDiagramList/>
        </FeatureChecker>
      );
    case DATA_ASSET_TYPE:
      return <DataAssetList/>;
    case DATA_SET_TYPE:
      return (
        <FeatureCheckerOr featureIds={[Feature.LOGICAL_DATA_SETS, Feature.PHYSICAL_DATA_SETS]}>
          <DataSetList/>
        </FeatureCheckerOr>
      );
    case DATA_STRUCTURE_TYPE:
      return <DataStructureList/>;
    case DATA_FIELD_TYPE:
      return <DataFieldList/>;
    case TECHNICAL_DATA_MAPPING_TYPE:
      return <TechnicalDataMappingList/>;
    case CUSTOM_PROPERTY_GROUP_TYPE:
      return <CustomPropertyGroupList/>;
    case CUSTOM_PROPERTY_TYPE:
      return <GlossaryCustomPropertyList/>;
    case DATA_SOURCE_TYPE:
      return (
        <FeatureChecker featureId={Feature.DATA_SOURCES}>
          <DataSourceList/>
        </FeatureChecker>
      );
    case VALUE_TYPE_TYPE:
      return <ValueTypeList/>;
    case REQUIREMENT_TYPE:
      return <RequirementList/>;
    case BUSINESS_MODEL_MAPPING_TYPE:
      return <BusinessModelMappingList/>;
    case PROCESS_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESSES}>
          <ProcessList/>
        </FeatureChecker>
      );
    case PROCESS_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_DIAGRAMS}>
          <ProcessDiagramList/>
        </FeatureChecker>
      );
    case PROCESS_STEP_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_STEPS}>
          <ProcessStepList/>
        </FeatureChecker>
      );
    case PROCESS_MAPPING_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
          <ProcessMappingList/>
        </FeatureChecker>
      );
    case STATUS_TYPE:
      return <StatusList/>;
    case USER_TYPE:
      return (
        <FeatureChecker featureId={Feature.USERS_MANAGEMENT}>
          <UserList/>
        </FeatureChecker>
      );
    default:
      return null;
  }
};

const getReferencingListComponent = (listType: string | undefined, listId: string) => {
  switch (listType) {
    case BUSINESS_TERM_TYPE:
      return <ReferencingBusinessTermList id={listId}/>;
    default:
      return null;
  }
};

const getDiagramComponent = (diagramType: string, diagramId: string, diagramEntityType?: string) => {
  switch (diagramType) {
    case BUSINESS_MODEL_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.BUSINESS_MODEL_DIAGRAMS}>
          <BusinessModelDiagramComponent diagramId={diagramId}/>
        </FeatureChecker>
      );
    case PROCESS_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_DIAGRAMS}>
          <ProcessDiagramComponent diagramId={diagramId}/>
        </FeatureChecker>
      );
    case BUSINESS_TERM_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
          <DataLineageDiagram
            id={diagramId}
            objectType={BUSINESS_TERM_TYPE}
            lineageType={DiagramLineageType.BUSINESS_TERM}
          />
        </FeatureChecker>
      );
    case DATA_LINEAGE_DIAGRAM_TYPE:
      return diagramEntityType && (
        <FeatureChecker featureId={Feature.DATA_LINEAGE_DIAGRAMS}>
          <DataLineageDiagram
            id={diagramId}
            objectType={diagramEntityType}
            lineageType={DiagramLineageType.DATA_CATALOG}
          />
        </FeatureChecker>
      );
    case BUSINESS_MODEL_MAPPING_DIAGRAM_TYPE:
      return diagramEntityType && (
        <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
          <DataLineageDiagram
            id={diagramId}
            objectType={diagramEntityType}
            lineageType={DiagramLineageType.BUSINESS_MODEL_MAPPING}
            nodesCollapsibleFromColumn={1}
            nodesExpandedFromColumn={2}
          />
        </FeatureChecker>
      );
    case TECHNICAL_DATA_MAPPING_DIAGRAM_TYPE:
      return diagramEntityType && (
        <FeatureChecker featureId={Feature.TECHNICAL_DATA_MAPPINGS}>
          <DataLineageDiagram
            id={diagramId}
            objectType={diagramEntityType}
            lineageType={DiagramLineageType.TECHNICAL_DATA_MAPPING}
            nodesCollapsibleFromColumn={1}
            nodesExpandedFromColumn={2}
          />
        </FeatureChecker>
      );
    default:
      return null;
  }
};

const getDetailComponent = (detailType?: string, entityId?: string) => {
  switch (detailType) {
    case BUSINESS_TERM_TYPE:
      return <BusinessTermDetail id={entityId}/>;
    case BUSINESS_RULE_TYPE:
      return (
        <FeatureChecker featureId={Feature.BUSINESS_RULES}>
          <BusinessRuleDetail id={entityId}/>
        </FeatureChecker>
      );
    case ENTITY_TYPE:
      return <EntityDetail id={entityId}/>;
    case COMPOSITE_TYPE_TYPE:
      return <CompositeTypeDetail id={entityId}/>;
    case ATTRIBUTE_DEFINITION_TYPE:
      return <AttributeDefinitionDetail id={entityId}/>;
    case ATTRIBUTE_TYPE:
      return <AttributeDetail id={entityId}/>;
    case BUSINESS_MODEL_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.BUSINESS_MODEL_DIAGRAMS}>
          <BusinessModelDiagramDetail id={entityId}/>
        </FeatureChecker>
      );
    case DATA_ASSET_TYPE:
      return <DataAssetDetail id={entityId}/>;
    case DATA_SET_TYPE:
      return (
        <FeatureCheckerOr featureIds={[Feature.LOGICAL_DATA_SETS, Feature.PHYSICAL_DATA_SETS]}>
          <DataSetDetail id={entityId}/>
        </FeatureCheckerOr>
      );
    case DATA_STRUCTURE_TYPE:
      return <DataStructureDetail id={entityId}/>;
    case DATA_FIELD_TYPE:
      return <DataFieldDetail id={entityId}/>;
    case TECHNICAL_DATA_MAPPING_TYPE:
      return <TechnicalDataMappingDetail id={entityId}/>;
    case CUSTOM_PROPERTY_GROUP_TYPE:
      return <CustomPropertyGroupDetail id={entityId}/>;
    case CUSTOM_PROPERTY_TYPE:
      return <GlossaryCustomPropertyDetail id={entityId}/>;
    case DATA_SOURCE_TYPE:
      return (
        <FeatureChecker featureId={Feature.DATA_SOURCES}>
          <DataSourceDetail id={entityId}/>
        </FeatureChecker>
      );
    case VALUE_TYPE_TYPE:
      return <ValueTypeDetail id={entityId}/>;
    case REQUIREMENT_TYPE:
      return <RequirementDetail id={entityId}/>;
    case BUSINESS_MODEL_MAPPING_TYPE:
      return <BusinessModelMappingDetail id={entityId}/>;
    case PROCESS_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESSES}>
          <ProcessDetail id={entityId}/>
        </FeatureChecker>
      );
    case PROCESS_DIAGRAM_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_DIAGRAMS}>
          <ProcessDiagramDetail id={entityId}/>
        </FeatureChecker>
      );
    case PROCESS_STEP_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_STEPS}>
          <ProcessStepDetail id={entityId}/>
        </FeatureChecker>
      );
    case PROCESS_MAPPING_TYPE:
      return (
        <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
          <ProcessMappingDetail id={entityId}/>
        </FeatureChecker>
      );
    case STATUS_TYPE:
      return <StatusDetail id={entityId}/>;
    case USER_TYPE:
      return (
        <FeatureChecker featureId={Feature.USERS_MANAGEMENT}>
          <UserDetail id={entityId}/>
        </FeatureChecker>
      );
    case USER_PROFILE:
      return <UserProfileDetail/>;
    case APPLICATION_SETTING:
      return <ApplicationSettingsDetailContainer/>;
    case ABOUT_PAGE:
      return <AboutDetailContainer applicationName={'Accurity'}/>;
    default:
      return null;
  }
};

const useGetMainPanelComponent = () => {
  const {
    diagramType,
    diagramId,
    diagramEntityType,
    listId,
    listType,
    tagName,
    searchTerm,
    dataFieldProfilingId
  } = useAccurityNavigation().getCurrentNavigation();

  let listComponent = null;
  let overlayingComponent = null;

  if (listType && listId) {
    listComponent = getReferencingListComponent(listType, listId);
  } else if (listType) {
    listComponent = getListComponent(listType);
  } else if (tagName) {
    listComponent = <TaggedEntityList tagName={tagName}/>;
  } else if (searchTerm) {
    listComponent = <GlobalSearchList searchTerm={searchTerm}/>;
  }

  if (diagramType && diagramId) {
    overlayingComponent = getDiagramComponent(diagramType, diagramId, diagramEntityType);
  }

  if (!!dataFieldProfilingId) {
    overlayingComponent = <AccurityColumnProfilingCanvas dataFieldId={dataFieldProfilingId}/>;
  }

  return (
    <>
      {listComponent}
      {overlayingComponent && <AccurityListOverlay>
        {overlayingComponent}
      </AccurityListOverlay>}
    </>
  );
};

const BusinessGlossaryMainScreen = () => {
  const {
    listType,
    detailType,
    detailId,
    diagramType,
  } = useAccurityNavigation().getCurrentNavigation();
  Analytics.useScreenAnalytics(Analytics.createScreenIdentifier(listType, detailType, detailId, diagramType));

  const mainPanelComponent = useGetMainPanelComponent();

  return (
    <>
      {getDetailComponent(detailType, detailId)}
      <AccurityMainPanel>
        {mainPanelComponent}
      </AccurityMainPanel>
    </>
  );
};

export default BusinessGlossaryMainScreen;
