import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { Tag } from 'ts-components';
import { DataSetReference } from '../../dataSet/types/dataSetTypes';

export const DATA_ASSET_TYPE = 'data-asset';
export const DATA_ASSET_LABEL = 'Data Asset';
export const DATA_ASSET_PLURAL_LABEL = 'Data Assets';
export const DATA_ASSET_ICON = 'dataAsset';
export const PARENT_DATA_ASSET_LABEL = 'Parent Data Asset';
export const DATA_ASSET_DATA_SETS = 'Data Sets';
export const CHILD_DATA_ASSET_LABEL = 'Child Data Asset';
export const CHILD_DATA_ASSETS_LABEL = 'Child Data Assets';

export interface DataAssetState extends CoreEntityState<DataAsset> {
}

export interface DataAsset extends AccurityCoreEntity, WithChildrenCounts {
  status: AccurityReference;
  parent?: AccurityReference;
  dataSets: DataSetReference[];
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}
