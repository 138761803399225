import React from 'react';
import '../main.less';
import AccurityRoutes from './AccurityRoutes';
import { useUserTimezone } from '../common/userSettings/hooks/userSettingsHooks';
import { AccurityDnDProvider, AccurityI18nProvider, AccurityMuiThemeProvider, AccurityTimezoneProvider } from 'ts-components';
import resources from '../common/localization/resources';
import { i18nInitOptions } from '../common/localization/setup';
import { KeycloakProvider } from '../common/keycloak/KeycloakProvider';
import UserMessages from '../common/userMessages/components/UserMessages';
import { LoadingPage } from '../common/keycloak/LoadingPage';
import { ApplicationStatus } from '../common/userSettings/types/types';
import { useSelector } from 'react-redux';
import { AppSettingsState } from '../common/appSettings/types/types';
import { GuidedToursInit } from '../common/analytics/GuidedToursInit';

const AccurityApp = () => {
  const timezone = useUserTimezone();
  const applicationStatus = useSelector(({ appSettings }: { appSettings: AppSettingsState }) => {
    return appSettings.applicationStatus
  });

  return (
    <>
      {applicationStatus !== ApplicationStatus.RUNNING && <LoadingPage status={applicationStatus}/>}
      <GuidedToursInit/>
      <AccurityDnDProvider>
        <AccurityTimezoneProvider value={timezone}>
          <AccurityI18nProvider resources={resources} options={i18nInitOptions}>
            {() => (
              <AccurityMuiThemeProvider primaryColor={'#6D8CE2'}>
                <div id="appContainer" className="Accurity">
                  <KeycloakProvider>
                    <AccurityRoutes/>
                  </KeycloakProvider>
                  <UserMessages/>
                </div>
              </AccurityMuiThemeProvider>
            )}
          </AccurityI18nProvider>
        </AccurityTimezoneProvider>
      </AccurityDnDProvider>
    </>
  );
};

export default AccurityApp;
