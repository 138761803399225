import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AccurityNavigationParams } from './types';
import { createPathFromNavigationParams, decodeNavParams } from './utils';
import { AccurityFilter, AccurityListLocationState, AccurityLocationState, AccurityPinFilter } from '../types/accurityTypes';
import { useDispatch } from 'react-redux';
import { setDetailOpenedAt } from '../redux/commonReducers';
import { DATA_FIELD_TYPE } from '../../businessGlossary/dataField/types/dataFieldTypes';

/*
 * Hook that provides simple navigation commands to component, eg. to detail, without it needing
 * to be aware of other parts of navigation, eg. current list or diagram.
 */

// TODO ids from URL are strings, ids from entities are numbers
export const useAccurityNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentParams = decodeNavParams(useParams<AccurityNavigationParams>());
  const currentLocation = useLocation<AccurityLocationState>();
  const currentLocationState = currentLocation?.state || {};

  const doNavigate = (newParams: AccurityNavigationParams, locationState?: AccurityLocationState) => {
    if (newParams.detailType && locationState?.detail?.openedAt) {
      dispatch(setDetailOpenedAt({ objectType: newParams.detailType, openedAt: locationState?.detail?.openedAt }));
    }
    const newPath = createPathFromNavigationParams({
      ...currentParams,
      ...newParams,
    });
    history.push(
      newPath,
      locationState
    );
  };

  const navigationController = {
    openList: (listType: string,
               listId?: string,
               listLocationStateOverwrites?: Partial<AccurityListLocationState>) => {
      doNavigate({
        listType: listType,
        listId: listId,
        diagramType: undefined,
        diagramId: undefined,
        diagramEntityType: undefined,
        comments: undefined,
        searchTerm: undefined,
        tagName: undefined,
        dataFieldProfilingId: undefined
      }, {
        ...currentLocationState,
        list: {
          openedAt: Date.now(),
          ...listLocationStateOverwrites
        }
      })
    }
    ,

    openListAndCloseDetail: (listType: string, listLocationStateOverwrites?: Partial<AccurityListLocationState>) =>
      doNavigate({
        listType: listType,
        listId: undefined,
        diagramType: undefined,
        diagramId: undefined,
        diagramEntityType: undefined,
        detailType: undefined,
        detailId: undefined,
        tagName: undefined,
        searchTerm: undefined,
        dataFieldProfilingId: undefined,
        comments: undefined,
      }, {
        ...currentLocationState,
        list: {
          openedAt: Date.now(),
          ...listLocationStateOverwrites,
        }
      }),

    openListWithPinFilters: (listType: string, pinFilters: AccurityPinFilter[]) =>
      navigationController.openList(listType, undefined,
        {
          pinFilters: pinFilters,
        }
      ),

    openListWithReferenceFilters: (listType: string, referenceFilters: AccurityFilter[], customEndpoint?: string) =>
      navigationController.openList(listType, undefined,
        {
          referenceFilters: referenceFilters,
          customEndpoint: customEndpoint,
        }
      ),

    reopenCurrentList: () => {
      if (currentParams.listType) {
        navigationController.openList(
          currentParams.listType,
          currentParams.listId
        );
      }
    },

    openDiagram: (diagramType: string, diagramId: string, diagramEntityType?: string) =>
      doNavigate({
          diagramType: diagramType,
          diagramId: diagramId,
          diagramEntityType: diagramEntityType,
          comments: undefined,
          dataFieldProfilingId: undefined
        },
        {
          ...currentLocationState,
          diagram: {
            openedAt: Date.now(),
          }
        }
      ),

    closeDiagram: () => doNavigate({
      diagramType: undefined,
      diagramId: undefined,
      diagramEntityType: undefined,
    }, {
      ...currentLocationState,
    }),

    openDetail: (detailType: string, prePopulateData?: Record<string, any>) =>
      doNavigate({
          detailType: detailType,
          detailId: undefined,
          comments: undefined,
        },
        {
          ...currentLocationState,
          detail: {
            prePopulateData: prePopulateData,
            openedAt: Date.now(),
          }
        }
      ),

    openDetailWithObject: (detailType: string, objectId: string, preserveCommentsDrawerState?: boolean, preservePrePopulatedData?: boolean) =>
      doNavigate({
          detailType: detailType,
          detailId: objectId,
          comments: preserveCommentsDrawerState ? currentParams.comments : undefined,
        },
        {
          ...currentLocationState,
          detail: {
            prePopulateData: preservePrePopulatedData ? currentLocationState?.detail?.prePopulateData : undefined,
            openedAt: Date.now(),
          }
        }
      ),

    openDetailAndDiagram: (objectType: string, objectId: string, diagramId: string, diagramEntityType?: string) =>
      doNavigate({
          detailType: objectType,
          detailId: objectId,
          diagramType: objectType,
          diagramEntityType: diagramEntityType,
          diagramId: diagramId,
          comments: undefined,
          dataFieldProfilingId: undefined
        },
        {
          ...currentLocationState,
          diagram: {
            openedAt: Date.now(),
          },
          detail: {
            openedAt: Date.now(),
          }
        }
      ),

    openDetailAndList: (detailType: string, objectId: string) =>
      doNavigate({
          detailType: detailType,
          detailId: objectId,
          listType: detailType,
          listId: undefined,
          tagName: undefined,
          searchTerm: undefined,
          dataFieldProfilingId: undefined,
          diagramType: undefined,
          diagramId: undefined,
          diagramEntityType: undefined,
        },
        {
          ...currentLocationState,
          detail: {
            openedAt: Date.now(),
          },
          list: {
            openedAt: Date.now(),
          }
        }
      ),

    openListForTag: (tagName: string) =>
      doNavigate({
          tagName: tagName,
          listType: undefined,
          listId: undefined,
          searchTerm: undefined,
          dataFieldProfilingId: undefined,
          diagramId: undefined,
          diagramType: undefined,
          diagramEntityType: undefined,
        }
      ),

    openSearch: (searchTerm: string) =>
      doNavigate({
          searchTerm: searchTerm,
          listType: undefined,
          listId: undefined,
          tagName: undefined,
          dataFieldProfilingId: undefined,
          diagramId: undefined,
          diagramType: undefined,
          diagramEntityType: undefined,
        }
      ),

    removeDetailIdWithoutReloading: () => {
      const newPath = createPathFromNavigationParams({
        ...currentParams,
        detailId: undefined,
        comments: undefined,
      });
      window.history.replaceState(null, document.title, newPath);
    },

    closeDetail: () =>
      doNavigate({
        detailType: undefined,
        detailId: undefined,
        comments: undefined,
      }, {
        ...currentLocationState,
      }),

    openCommentsDrawer: () =>
      doNavigate({
        comments: 'comments',
      }, {
        ...currentLocationState,
      }),

    closeCommentsDrawer: () =>
      doNavigate({
        comments: undefined,
      }, {
        ...currentLocationState,
      }),

    openDataFieldProfilingCanvas: (dataFieldId: string) =>
      doNavigate({
        detailType: DATA_FIELD_TYPE,
        detailId: dataFieldId,
        dataFieldProfilingId: dataFieldId,
        diagramType: undefined,
        diagramId: undefined,
        diagramEntityType: undefined,
        comments: undefined,
      }, {
        ...currentLocationState,
      }),

    closeDataFieldProfilingCanvas: () =>
      doNavigate({
        dataFieldProfilingId: undefined
      }, {
        ...currentLocationState,
      }),

    openWelcomeScreen: () =>
      doNavigate({
        listType: undefined,
        listId: undefined,
        diagramType: undefined,
        diagramId: undefined,
        diagramEntityType: undefined,
        detailType: undefined,
        detailId: undefined,
        comments: undefined,
        tagName: undefined,
        searchTerm: undefined,
        dataFieldProfilingId: undefined
      }),

    getCurrentNavigation: () =>
      ({
        ...currentParams
      }),

    getCurrentPath: () =>
      currentLocation.pathname,

  };

  return navigationController;
};

export type AccurityNavigationController = ReturnType<typeof useAccurityNavigation>
