import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { DATA_SOURCE_ICON, DATA_SOURCE_PLURAL_LABEL, DATA_SOURCE_TYPE } from '../types/dataSourceTypes';
import { useDataSourceListColumns } from './dataSourceListColumns';

const DataSourceList = () => {

  const dataSourceColumns = useDataSourceListColumns();
  const dataSourceListSettings = getDefaultListSettings(DATA_SOURCE_TYPE, useDataSourceListColumns());

  return (
    <AccurityListContainer
      objectType={DATA_SOURCE_TYPE}
      title={DATA_SOURCE_PLURAL_LABEL}
      icon={DATA_SOURCE_ICON}
      columns={dataSourceColumns}
      defaultListSettings={dataSourceListSettings}
      allowImport={false}
      allowExport={false}
    />
  );
};

export default DataSourceList;
