import { CrudCommand } from '../../types';

type CrudCommandHandler = (command: CrudCommand) => void;

export class WebSocketCrudObserver {

  private static instance: WebSocketCrudObserver;
  private observers: { [objectType: string]: CrudCommandHandler[] } = {};

  private constructor() {
  };

  /**
   * Method to control
   * access to singleton object
   */
  public static getInstance(): WebSocketCrudObserver {
    if (!WebSocketCrudObserver.instance) {
      WebSocketCrudObserver.instance = new WebSocketCrudObserver();
    }
    return WebSocketCrudObserver.instance;
  }

  /**
   * Subscribe to WebSocket commands for specific object type
   * @param handler Function to handle WebSocket command
   */
  public subscribe(handler: CrudCommandHandler): () => void {
    return this.subscribeForObjectType('ALL', handler);
  }

  /**
   * Subscribe to WebSocket commands for specific object type
   * @param objectType Object type we want to listen for changes
   * @param handler Function to handle WebSocket command
   */
  public subscribeForObjectType(objectType: string, handler: CrudCommandHandler): () => void {
    this.observers[objectType] = this.observers[objectType] || [];
    if (!this.observers[objectType].includes(handler)) {
      this.observers[objectType].push(handler);
    }

    // return function to unsubscribe
    return () => {
      const idx = this.observers[objectType].indexOf(handler);
      if (idx !== -1) {
        this.observers[objectType].splice(idx, 1);
      }
    };
  }

  /**
   * Trigger an handler in each subscriber
   *
   * @param command Command that is passed to handler
   */
  public notify(command: CrudCommand) {
    // notify listeners for specific object type
    (this.observers[command.objectType] || []).forEach(handler => handler(command));

    // notify all listeners
    (this.observers['ALL'] || []).forEach(handler => handler(command));
  }
}
