import { DataManagementMessageType, ExportResult, ImportResult } from '../dataTransfer/types';
import { ProfileStatus } from '../columnProfiling/types/types';
import { SyncRunStatus } from '../dataCatalogSync/types';

export interface NotificationCenterState {
  notifications: NotificationCenterNotification[];
}

export enum NotificationCenterNotificationType {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  BULK_OPERATION = 'BULK_OPERATION',
  COLUMN_PROFILING = 'COLUMN_PROFILING',
  DATA_MANAGEMENT = 'DATA_MANAGEMENT',
  DATA_CATALOG_SYNC = 'DATA_CATALOG_SYNC',
}

export interface NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType;
  id: string;
  date: number;
  wasRead?: boolean;
  cannotDismiss?: boolean;
}

export interface ImportNotificationCenterNotification extends NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType.IMPORT;
  objectType: string;
  showDownloadReport?: boolean;
  importResult?: ImportResult;
  failedWithException?: boolean;
}

export interface ExportNotificationCenterNotification extends NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType.EXPORT;
  objectType: string;
  showDownloadReport?: boolean;
  exportResult?: ExportResult;
}

export interface BulkOperationNotificationCenterNotification extends NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType.BULK_OPERATION;
  objectType: string;
  header: string;
  body?: string;
  inProgress?: boolean;
  showDownloadReport?: boolean;
}

export interface ColumnProfilingNotificationCenterNotification extends NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType.COLUMN_PROFILING;
  title: string;
  body: string;
  dataFieldId: number;
  profileStatus: ProfileStatus;
}

export interface DataManagementNotificationCenterNotification extends NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType.DATA_MANAGEMENT;
  dataManagementMessageType: DataManagementMessageType;
}

export interface DataCatalogSyncNotificationCenterNotification extends NotificationCenterNotificationCommon {
  type: NotificationCenterNotificationType.DATA_CATALOG_SYNC;
  runStatus: SyncRunStatus;
}

export type NotificationCenterNotification = ImportNotificationCenterNotification
  | ExportNotificationCenterNotification
  | BulkOperationNotificationCenterNotification
  | ColumnProfilingNotificationCenterNotification
  | DataManagementNotificationCenterNotification
  | DataCatalogSyncNotificationCenterNotification;
