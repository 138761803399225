import React from 'react';
import { ExportNotificationCenterNotification } from './types';
import { NotificationCenterCard, NotificationCenterCardProps, objectTypeToIconName, ProgressIndicator } from 'ts-components';
import { useDispatch } from 'react-redux';
import { dismissNotification } from './notificationCenterSlice';
import { useTranslation } from 'react-i18next';
import { requestDownloadExportFile } from '../dataTransfer/api';
import Analytics from '../analytics';

export interface ExportNotificationCardProps {
  notification: ExportNotificationCenterNotification;
}

const ExportNotificationCard = ({ notification }: ExportNotificationCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inProgress = !notification.exportResult;
  const success = notification.exportResult?.exportSuccess;

  let title;
  let titleIconColor: NotificationCenterCardProps['titleIconColor'];
  if (inProgress) {
    title = t('exportNotification.inProgress', { objectType: notification.objectType });
  } else if (success) {
    title = t('exportNotification.finishSuccess', { objectType: notification.objectType });
    titleIconColor = 'success';
  } else {
    title = t('exportNotification.failed', { objectType: notification.objectType });
    titleIconColor = 'error';
  }

  const date = notification.date;
  const unread = !notification.wasRead;
  const onDismiss = notification.cannotDismiss ? undefined : () => dispatch(dismissNotification(notification.id));

  const downloadReport = notification.showDownloadReport && success ? () => {
    requestDownloadExportFile(notification.objectType);
    Analytics.event('Notification Center', 'Download Report');
  } : undefined;

  let content;
  if (inProgress) {
    content = <span style={{ width: '100%' }}><ProgressIndicator/></span>;
  } else if (success && notification.exportResult?.numberOfExportedRows !== 0) {
    content =
      <span>
      {
        t('exportNotification.exported') + ': ' + notification.exportResult?.numberOfExportedRows
      }
    </span>;
  }

  return (
    <NotificationCenterCard
      title={title}
      titleIcon={objectTypeToIconName(notification.objectType)}
      titleIconColor={titleIconColor}
      date={date}
      unread={unread}
      onDismiss={onDismiss}
      actionText={t('importNotification.downloadReport')}
      actionClick={downloadReport}
    >
      {content}
    </NotificationCenterCard>
  );
};

export default ExportNotificationCard;
