import { DataSyncJobCommand } from '../../types';
import rootStore from '../../../redux/rootStore';
import { pushNotification } from '../../../notificationCenter/notificationCenterSlice';
import { DataCatalogSyncNotificationCenterNotification, NotificationCenterNotificationType } from '../../../notificationCenter/types';
import { SyncRunStatus } from '../../../dataCatalogSync/types';

export const handleDataSyncJobCommand = (dataSyncJobCommand: DataSyncJobCommand) => {
  const { runId, runStatus } = dataSyncJobCommand;

  const notification: DataCatalogSyncNotificationCenterNotification = {
    type: NotificationCenterNotificationType.DATA_CATALOG_SYNC,
    id: `data-catalog-sync-run-${runId}`,
    date: Date.now(),
    runStatus: runStatus,
    cannotDismiss: runStatus === SyncRunStatus.PREPARING || runStatus === SyncRunStatus.RUNNING,
  }

  rootStore.dispatch(pushNotification(notification));
};
