import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { REQUIREMENT_TYPE, RequirementState } from '../types/requirementTypes';

const initialState: RequirementState = getInitialCoreEntityState(REQUIREMENT_TYPE);

const requirementSlice = createSlice({
  name: REQUIREMENT_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default requirementSlice.reducer;
