import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { PublicSettingsResponse } from '../appSettings/types/types';
import { hotjar } from 'react-hotjar';
import smartlookClient from 'smartlook-client'

interface GoogleInitParams {
  gaCode: string;
  gaAppId: string;
  applicationVersion: string;
}

interface HotjarInitParams {
  hotjarId: number;
  hotjarSnippetVersion: number;
}

const SMARTLOOK_ID = 'e144cd4e5fb98e8002bbb809282ee1e3f2bcdb48';
const CLARITY_ID = '3xlb4a971s';
const GTM_ID  = 'GTM-KZCVXN7';

const IS_SAAS_ENV  = window?.location?.origin?.includes('app.accurity.ai');

// turn on/off logging in this module
const debugMode = false;

let isGaInitialized = false;
let isHotjarInitialized = false;
let isGtmInitialized = false;
let isSmartlookInitialized = false;
let isClarityInitialized = false;

// cache to store fn calls before GA is initialized
type GAUnsuccessfulRequestItem = { fnName: 'screen' | 'event'; args: any[]; };
const unsuccessfulRequests: GAUnsuccessfulRequestItem[] = [];

// Log function for debugging purpose
function log(message: string, obj?: any) {
  if (debugMode) {
    if (!isGaInitialized) {
      console.warn('[ga] Is not initialized');
    }
    console.log('[ga]', message, obj);
    if (!isHotjarInitialized) {
      console.warn('[hotjar] Is not initialized');
    }
  }
}

const trackGtmEvent = (eventName: string) => {
  if (isGtmInitialized) {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
      }
    })
  }
};

const trackScreenHandler = (screenName: string, pathName?: string) => {
  // Get default pathname from Location object and remove ids to track /list/status/detail/1 and /list/status/detail/123 as one screen
  pathName = pathName ? pathName : window.location.pathname.replace(/\/\d/gi, '');

  log('screen', screenName);
  if (isGaInitialized) {
    ReactGA.ga('send', 'screenview', { screenName });
    ReactGA.pageview(pathName);
  } else {
    unsuccessfulRequests.push({ fnName: 'screen', args: [screenName, pathName] });
  }
};

const trackEventHandler = (category: string, action: string, label?: string) => {
  let event = { category, action, label };
  log('event', event);
  if (isGaInitialized) {
    ReactGA.event(event);
  } else {
    unsuccessfulRequests.push({ fnName: 'event', args: [category, action, label] });
  }
};

const processUnsuccessfulRequests = () => {
  let item: GAUnsuccessfulRequestItem | undefined = unsuccessfulRequests.pop();

  while (item) {
    if (item.fnName === 'screen') {
      trackScreenHandler(item.args[0], item.args[1]);
    } else if (item.fnName === 'event') {
      trackEventHandler(item.args[0], item.args[1], item.args[2]);
    }
    item = unsuccessfulRequests.pop();
  }
};

const initGA = (options: GoogleInitParams) => {
  if (!isGaInitialized) {
    try {
      ReactGA.initialize(options.gaCode);
      ReactGA.set({
        appId: options.gaAppId,
        appName: options.gaAppId,
        appVersion: options.applicationVersion
      });
      isGaInitialized = true;
      processUnsuccessfulRequests()
    } catch (e) {
      console.log('[ga] Google analytics not initialized', e);
    }
  }
};

const initHotjar = (options: HotjarInitParams) => {
  if (!isHotjarInitialized) {
    hotjar.initialize(options.hotjarId, options.hotjarSnippetVersion);
    isHotjarInitialized = true;
  }
};

const initGoogleTagManager = () => {
  if (IS_SAAS_ENV  && !isGtmInitialized) {
    TagManager.initialize({
      gtmId: GTM_ID ,
    });
    isGtmInitialized = true;
  }
};

const initSmartlook = () => {
  if (IS_SAAS_ENV  && !isSmartlookInitialized) {
    smartlookClient.init(SMARTLOOK_ID);
    isSmartlookInitialized = true;
  }
};

const initClarity = () => {
  if (IS_SAAS_ENV  && !isClarityInitialized) {

    const w = window as any;
    w.clarity = w.clarity || function () {
      (w.clarity.q = w.clarity.q || []).push(arguments)
    };
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.clarity.ms/tag/' + CLARITY_ID;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(script, firstScript);

    isClarityInitialized = true;
  }
};

const Analytics = {
  gtmEvent: trackGtmEvent,

  screen: trackScreenHandler,

  event: trackEventHandler,

  createScreenIdentifier: (listType?: string, detailType?: string, detailId?: string, diagramType?: string) => {
    const identifierParts = [
      listType ? 'list:' + listType : null,
      diagramType ? 'diagram:' + diagramType : null,
      detailType ? 'detail:' + detailType : null,
      detailId ? 'detailId:' + detailId : null,
    ].filter(q => q !== null);
    return identifierParts.join(' ');
  },

  createActionTracker: (category: string) => {
    return (action: string, label?: string) => {
      Analytics.event(category, action, label);
    }
  },

  createActionTrackerWithCallback: (category: string) => {
    return <T extends Array<any>, U>(callback: (...args: T) => U, actionName: string, label?: string) => {
      return (...args: T): U => {
        Analytics.event(category, actionName, label);
        return callback(...args);
      };
    }
  },

  initAnalytics: (appSettings: PublicSettingsResponse) => {
    // todo we are not reinitializing GA/Hotjar when it is once initialized and the config for GA/hotjar changed
    // this is not a problem for now, because we have only one tracking account
    if (appSettings && appSettings.gaCode && !isGaInitialized) {
      initGA(appSettings);
    }

    if (appSettings && appSettings.hotjarId !== 0 && !isHotjarInitialized) {
      initHotjar(appSettings);
    }

    initSmartlook();
    initClarity();
  },

  useScreenAnalytics: (screenName: string) => {
    useEffect(() => {
      Analytics.screen(screenName);
    }, [screenName]);
  }
};

export default Analytics;
