import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';

import { AccurityFilterType, Column, DEFAULT_DATE_FORMAT } from 'ts-components';

import { columnFactory } from '../list/commonColumns/columnDefinitions';
import { CUSTOM_PROPERTY_TYPE, CustomProperty, CustomPropertyPropertyType } from './customProperty/types/customPropertyTypes';
import { CUSTOM_PROPERTY_GROUP_TYPE, CustomPropertyGroup } from './customPropertyGroup/types/customPropertyGroupTypes';
import { CustomPropertyTargetObjectType } from '../../businessGlossary/customProperties/types';
import { DATA_SET_LABEL } from '../../businessGlossary/dataSet/types/dataSetTypes';
import { DATA_STRUCTURE_LABEL } from '../../businessGlossary/dataStructure/types/dataStructureTypes';
import { PROCESS_LABEL } from '../../businessGlossary/process/types/processTypes';
import { getDisplayCustomPropertyValueField, getSupportiveCustomPropertyValueFieldLabel } from './customPropertyUtils';
import { DATA_FIELD_LABEL } from '../../businessGlossary/dataField/types/dataFieldTypes';
import { PROCESS_STEP_LABEL } from '../../businessGlossary/process/step/types/processStepTypes';

export const useCustomPropertiesFromFormikContextForObjectType = (forObjectType: string): [CustomProperty[], CustomPropertyGroup[]] => {
  const [customProperties, customPropertyGroups] = useFormikContext()?.status?.customProperties;
  return filterAndOrderCustomProperties(forObjectType, customProperties, customPropertyGroups);
};

export const useCustomPropertiesFromStoreForObjectType = (forObjectType: string): [CustomProperty[], CustomPropertyGroup[]] => {
  const [customProperties, customPropertyGroups] = useSelectCurrentCustomPropertiesFromStore();
  return filterAndOrderCustomProperties(forObjectType, customProperties, customPropertyGroups);
};

export const useSelectCurrentCustomPropertiesFromStore = () => {
  const customPropertyState = useSelector((state: RootState) => state[CUSTOM_PROPERTY_TYPE]);
  const customProperties = useMemo(() => customPropertyState.allIds
      .filter(id => !customPropertyState.byId[id].meta.wasDeleted)
      .map(id => customPropertyState.byId[id].data)
    , [customPropertyState]);

  const customPropertyGroupState = useSelector((state: RootState) => state[CUSTOM_PROPERTY_GROUP_TYPE]);
  const customPropertyGroups = useMemo(() => customPropertyGroupState.allIds
      .filter(id => !customPropertyGroupState.byId[id].meta.wasDeleted)
      .map(id => customPropertyGroupState.byId[id].data)
    , [customPropertyGroupState]);

  return [customProperties, customPropertyGroups];
};

export const useCustomPropertyColumns = (forObjectType: string,  group?: string, prefix?: string, labelSuffix?: string): Column[] => {
  const [customProperties] = useCustomPropertiesFromStoreForObjectType(forObjectType);

  const customPropertyValuePrefix = prefix ? prefix + '.customPropertyValues' : 'customPropertyValues';
  const suffix = labelSuffix ?? '';

  let customPropertyValueColumns: Column[] = [];

  for (let cp of customProperties) {

    const displayCustomPropertyValueField = getDisplayCustomPropertyValueField(cp.targetObjectType);
    const sortable = false;

    switch (cp.propertyType) {
      case CustomPropertyPropertyType.HYPERLINK:
        customPropertyValueColumns.push(columnFactory.createHyperlinkColumn(`${customPropertyValuePrefix}.${cp.id}.value`, cp.name + suffix,
          {
            hidden: true,
            filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
            sortable: sortable,
            group: group,
          }));
        break;
      case CustomPropertyPropertyType.ENUMERATION:
        customPropertyValueColumns.push(columnFactory.createEnumColumn(`${customPropertyValuePrefix}.${cp.id}.value`, cp.name + suffix, [{
            id: 0,
            value: '--objects-without-value',
            label: '<EMPTY>',
          }, ...cp.options],
          {
            hidden: true,
            filterType: AccurityFilterType.CUSTOM_PROPERTY_ENUM,
            sortable: sortable,
            group: group,
          }));
        break;
      case CustomPropertyPropertyType.TEXT:
        customPropertyValueColumns.push(columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.value`, cp.name + suffix,
          {
            hidden: true,
            filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
            sortable: sortable,
            group: group,
          }));
        break;
      case CustomPropertyPropertyType.NUMBER:
        customPropertyValueColumns.push(columnFactory.createNumberColumn(`${customPropertyValuePrefix}.${cp.id}.value`, cp.name + suffix,
          {
            hidden: true,
            filterType: AccurityFilterType.CUSTOM_PROPERTY_NUMERIC,
            sortable: sortable,
            group: group,
          }));
        break;
      case CustomPropertyPropertyType.RELATION:

        if (CustomPropertyTargetObjectType.PROCESS_STEP === cp.targetObjectType) {
          customPropertyValueColumns.push(
            columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.processReference.name`,
              getSupportiveCustomPropertyValueFieldLabel(cp.name, PROCESS_LABEL) + suffix,
              {
                hidden: true,
                filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
                sortable: false,
                filterable: false,
                group: group,
              }));
          customPropertyValueColumns.push(columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.reference.name`,
            getSupportiveCustomPropertyValueFieldLabel(cp.name, PROCESS_STEP_LABEL) + suffix,
            {
              hidden: true,
              filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
              sortable: sortable,
              group: group,
            }));
        }

        if (CustomPropertyTargetObjectType.DATA_STRUCTURE === cp.targetObjectType) {
          customPropertyValueColumns.push(
            columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.dataSetReference.name`,
              getSupportiveCustomPropertyValueFieldLabel(cp.name, DATA_SET_LABEL) + suffix,
              {
                hidden: true,
                filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
                sortable: false,
                filterable: false,
                group: group,
              }));
          customPropertyValueColumns.push(columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.reference.name`,
            getSupportiveCustomPropertyValueFieldLabel(cp.name, DATA_STRUCTURE_LABEL) + suffix,
            {
              hidden: true,
              filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
              sortable: sortable,
              group: group,
            }));
        }

        if (CustomPropertyTargetObjectType.DATA_FIELD === cp.targetObjectType) {
          customPropertyValueColumns.push(
            columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.dataSetReference.name`,
              getSupportiveCustomPropertyValueFieldLabel(cp.name, DATA_SET_LABEL) + suffix,
              {
                hidden: true,
                filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
                sortable: false,
                filterable: false,
                group: group,
              }));
          customPropertyValueColumns.push(
            columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.dataStructureReference.name`,
              getSupportiveCustomPropertyValueFieldLabel(cp.name, DATA_STRUCTURE_LABEL) + suffix,
              {
                hidden: true,
                filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
                sortable: false,
                filterable: false,
                group: group,
              }));
          customPropertyValueColumns.push(columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.reference.name`,
            getSupportiveCustomPropertyValueFieldLabel(cp.name, DATA_FIELD_LABEL) + suffix,
            {
              hidden: true,
              filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
              sortable: sortable,
              group: group,
            }));
        }

        if (CustomPropertyTargetObjectType.ATTRIBUTE === cp.targetObjectType) {
          customPropertyValueColumns.push(columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.reference.name`, cp.name + suffix,
            {
              hidden: true,
              filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
              sortable: sortable,
              group: group,
            }));
        }

        if (displayCustomPropertyValueField) {
          customPropertyValueColumns.push(columnFactory.createTextColumn(`${customPropertyValuePrefix}.${cp.id}.reference.name`, cp.name + suffix,
            {
              hidden: true,
              filterType: AccurityFilterType.CUSTOM_PROPERTY_TEXT,
              sortable: sortable,
              group: group,
            }));
        }
        break;
      case CustomPropertyPropertyType.DATE:
        customPropertyValueColumns.push(columnFactory.createDateColumn(`${customPropertyValuePrefix}.${cp.id}.value`, cp.name + suffix,
          {
            hidden: true,
            filterType: AccurityFilterType.CUSTOM_PROPERTY_NUMERIC,
            sortable: sortable,
            group: group,
            format: DEFAULT_DATE_FORMAT,
          }));
        break;
      default:
        throw new Error('Unsupported CustomProperty Property Type: ' + cp.propertyType);
    }
  }

  return customPropertyValueColumns;
};

const filterAndOrderCustomProperties = (forObjectType: string, allCustomProperties: CustomProperty[], allCustomPropertyGroups: CustomPropertyGroup[]): [CustomProperty[], CustomPropertyGroup[]] => {
  const customPropertiesForObjectType = allCustomProperties
    .filter((cp: CustomProperty) => cp.forObjectType === forObjectType);

  // Extract IDs of all CustomPropertyGroups from the filtered CustomProperties
  const customPropertyGroupIds = customPropertiesForObjectType.map((cp: CustomProperty) => cp.customPropertyGroup.id);

  const customPropertyGroupsForObjectType = allCustomPropertyGroups
    .filter((cpg: CustomPropertyGroup) => customPropertyGroupIds.includes(cpg.id));

  // Sort CPs and CPGs by their respective order properties
  const orderedCustomPropertiesForObjectType = sortBy(customPropertiesForObjectType, ['valueOrder', 'name']);
  const orderedCustomPropertyGroupsForObjectType = sortBy(customPropertyGroupsForObjectType, ['groupOrder', 'name']);

  // Return ordered CP collection and CPG collection for given object type
  return [
    orderedCustomPropertiesForObjectType,
    orderedCustomPropertyGroupsForObjectType,
  ];
};
