import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { BUSINESS_MODEL_DIAGRAM_TYPE, BusinessModelDiagramState } from '../types/businessModelDiagramTypes';

const initialState: BusinessModelDiagramState = getInitialCoreEntityState(BUSINESS_MODEL_DIAGRAM_TYPE);

const businessModelDiagramSlice = createSlice({
  name: BUSINESS_MODEL_DIAGRAM_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default businessModelDiagramSlice.reducer;
