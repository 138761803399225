import { DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../entity/types/entityTypes';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL } from '../../../attribute/types/attributeTypes';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../../attributeDefinition/types/attributeDefinitionTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { useField } from 'formik';
import { createReferenceFieldFilter, createUniqueWithinCollectionFilters } from '../../../../common/referenceField/utils/filters';
import {
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_CRITERIA_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_CRITERIA_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD,
  SelectionItem
} from '../../types/businessModelMappingTypes';
import { assembleAttributeNames } from '../businessModelMappingDetailUtils';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface EntityAttributeSelectionProps {
  elementName: string;
  index: number;
  additionalFilters?: AccurityFilter[];
}

const EntityAttributeSelection = ({ elementName, index, additionalFilters = [] }: EntityAttributeSelectionProps) => {

  const [field] = useField<SelectionItem[]>(BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD);
  const entities = field.value.map(row => row?.entity);
  const attributeDefinitions = field.value.map(row => row?.attributeDefinition);

  const attributes = assembleAttributeNames(field);

  const currentEntity = entities[index];
  const currentAttributeDefinition = attributeDefinitions[index];

  const entityFilters = createUniqueWithinCollectionFilters('name', attributes);
  entityFilters.push(...additionalFilters);

  const attributeDefinitionFilters = createUniqueWithinCollectionFilters('name', attributes);
  attributeDefinitionFilters.push(...additionalFilters);

  if (!currentAttributeDefinition) {
    entityFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    entityFilters.push(
      createReferenceFieldFilter('attributeDefinition.id', currentAttributeDefinition.id),
    );
  }

  if (!currentEntity) {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('entity.id', currentEntity.id)
    );
  }

  const coloring = useVersionBrowserColoring(elementName);

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
        coloring={coloring}
      >
        <ReferenceFieldWithFormik
          name={elementName + '.entity'}
          label={ENTITY_LABEL}
          objectType={ENTITY_TYPE}
          customEndpoint={ENTITY_TYPE + '/business-model-mapping-entity-selection'}
          icon={ENTITY_ICON}
          additionalFilters={entityFilters}
          disableColoring={true}
        />
        <ReferenceFieldWithFormik
          name={elementName + '.attributeDefinition'}
          label={ATTRIBUTE_LABEL}
          objectType={ATTRIBUTE_DEFINITION_TYPE}
          customEndpoint={ATTRIBUTE_DEFINITION_TYPE + '/business-model-mapping-attribute-definition-selection'}
          icon={ATTRIBUTE_ICON}
          additionalFilters={attributeDefinitionFilters}
          disableColoring={true}
        />
      </DetailInlineGroup>
      <DetailInlineGroup
        indentLeft={true}
        coloring={coloring}
      >
        <SingleLineFieldWithFormik
          name={elementName + '.' + BUSINESS_MODEL_MAPPING_TARGET_SELECTION_CRITERIA_FIELD}
          label={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_CRITERIA_LABEL}
          disableColoring={true}
        />
      </DetailInlineGroup>
    </>
  )
};

export default EntityAttributeSelection;
