import { usePermissionsForObjectType } from '../../userSettings/hooks/permissions';
import { DATA_STRUCTURE_TYPE } from '../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DATA_FIELD_TYPE } from '../../../businessGlossary/dataField/types/dataFieldTypes';

export const useCatalogSyncPermission = () => {
  const dataStructurePermissions = usePermissionsForObjectType(DATA_STRUCTURE_TYPE);
  const dataFieldPermissions = usePermissionsForObjectType(DATA_FIELD_TYPE);

  const canPerformSync = dataStructurePermissions.hasCreatePermission
    && dataStructurePermissions.hasUpdatePermission
    && dataFieldPermissions.hasCreatePermission
    && dataFieldPermissions.hasUpdatePermission;

  return {
    canPerformSync
  }
};