import { DATA_ASSET_TYPE, DataAssetState } from '../types/dataAssetTypes';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';

const initialState: DataAssetState = getInitialCoreEntityState('data-asset');

const dataAssetSlice = createSlice({
  name: DATA_ASSET_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default dataAssetSlice.reducer;
