import React from 'react';
import { ImportNotificationCenterNotification } from './types';
import { NotificationCenterCard, NotificationCenterCardProps, objectTypeToIconName, ProgressIndicator } from 'ts-components';
import { useDispatch } from 'react-redux';
import { dismissNotification } from './notificationCenterSlice';
import { useTranslation } from 'react-i18next';
import { requestDownloadImportLog } from '../dataTransfer/api';
import Analytics from '../analytics';

export interface ImportNotificationCardProps {
  notification: ImportNotificationCenterNotification;
}

const ImportNotificationCard = ({ notification }: ImportNotificationCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inProgress = !notification.importResult && !notification.failedWithException;
  const success = notification.importResult && notification.importResult.successfulImport;

  let title;
  let titleIconColor: NotificationCenterCardProps['titleIconColor'];
  if (inProgress) {
    title = t('importNotification.inProgress', { objectType: notification.objectType });
  } else if (notification.failedWithException) {
    title = t('importNotification.failedUnexpectedly', { objectType: notification.objectType });
    titleIconColor = 'error';
  } else if (success) {
    title = t('importNotification.finishSuccess', { objectType: notification.objectType });
    titleIconColor = 'success';
  } else if (notification.importResult?.partialImport) {
    title = t('importNotification.finishError', { objectType: notification.objectType });
    titleIconColor = 'error';
  } else {
    title = t('importNotification.failed', { objectType: notification.objectType });
    titleIconColor = 'error';
  }

  const date = notification.date;
  const unread = !notification.wasRead;
  const onDismiss = notification.cannotDismiss ? undefined : () => dispatch(dismissNotification(notification.id));

  const downloadReport = notification.showDownloadReport ? () => {
    requestDownloadImportLog(notification.objectType);
    Analytics.event('Notification Center', 'Download Report');
  } : undefined;

  let content;
  if (inProgress) { // In Progress Message
    content = <span style={{ width: '100%' }}><ProgressIndicator/></span>;
  } else if (notification.failedWithException) {
    content =
      <span>
      {
        t('importNotification.created') + ': 0 | ' +
        t('importNotification.updated') + ': 0'
      }
      </span>;
  } else if (success) {
    content =
      <span>
      {
        t('importNotification.created') + ': ' + notification.importResult?.numberOfCreatedRecords + ' | ' +
        t('importNotification.updated') + ': ' + notification.importResult?.numberOfUpdatedRecords + ' | ' +
        t('importNotification.invalid') + ': 0'
      }
    </span>;
  } else if (notification.importResult?.partialImport) {
    content = // Error with Partial Import ON
      <span>
      {
        t('importNotification.created') + ': ' + notification.importResult?.numberOfCreatedRecords + ' | ' +
        t('importNotification.updated') + ': ' + notification.importResult?.numberOfUpdatedRecords + ' | ' +
        t('importNotification.invalid') + ': ' + notification.importResult?.numberOfInvalidRecords
      }
    </span>;
  } else {
    content = // Error with Partial Import OFF
      <span>
      {
        t('importNotification.valid') + ': ' + notification.importResult?.numberOfValidRecords + ' | ' +
        t('importNotification.invalid') + ': ' + notification.importResult?.numberOfInvalidRecords
      }
    </span>;
  }

  return (
    <NotificationCenterCard
      title={title}
      titleIcon={objectTypeToIconName(notification.objectType)}
      titleIconColor={titleIconColor}
      date={date}
      unread={unread}
      onDismiss={onDismiss}
      actionText={t('importNotification.downloadReport')}
      actionClick={downloadReport}
    >
      {content}
    </NotificationCenterCard>
  );
};

export default ImportNotificationCard;
