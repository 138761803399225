import { DataField } from '../../../businessGlossary/dataField/types/dataFieldTypes';
import { useEffect, useState } from 'react';
import { doFetch } from '../../rest/FetchService';
import { getDataFieldHasProfilingResultEndpoint } from '../../rest/endpoints';
import { useSelectLastProfiledDataFieldId } from '../redux/selectors';

export const useHasProfilingData = (dataField?: DataField) => {
  const lastProfiledDataFieldId = useSelectLastProfiledDataFieldId();
  const [hasResult, setHasResult] = useState(true);

  // check if there is a profiling result for given data field
  useEffect(() => {
    if (dataField?.id) {
      doFetch(getDataFieldHasProfilingResultEndpoint(dataField.id), 'GET')
        .then((hasData: boolean) => setHasResult(!!hasData));
    } else {
      setHasResult(false);
    }
  }, [dataField?.id]);

  // enable button when we receive websocket message and we set the lastProfiledDataFieldId in redux store
  useEffect(() => {
    if (dataField?.id && String(lastProfiledDataFieldId) === String(dataField?.id)) {
      setHasResult(true);
    }
  }, [lastProfiledDataFieldId, dataField?.id]);

  return hasResult;
};
