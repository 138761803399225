import React, { useEffect } from 'react';
import { ColumnProfilingCanvas } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { DATA_FIELD_TYPE } from '../../../businessGlossary/dataField/types/dataFieldTypes';
import { handleGeneralData } from './handleGeneralData';
import { handleStatisticData } from './handleStatisticData';
import { handleFrequencyData } from './handleFrequencyData';
import { handleDateHistogramData } from './handleDateHistogramData';
import { handleCountByLengthHistogram } from './handleCountByLengthHistogram';
import { dismissColumnProfilingNotification } from '../../websocket/handlers/columnProfiling/columnProfilingCommandHandler';
import { useProfilingData } from '../hooks/profilingDataHook';

export type DataFieldProfilingProps = {
  dataFieldId: string;
}

const AccurityColumnProfilingCanvas = ({ dataFieldId }: DataFieldProfilingProps) => {

  const data = useProfilingData(dataFieldId);
  const navigation = useAccurityNavigation();
  const navigationParams = navigation.getCurrentNavigation();
  const isVisibleDetail = navigationParams.detailType === DATA_FIELD_TYPE && navigationParams.detailId === dataFieldId;

  // dismiss notification if exists
  useEffect(() => {
    dismissColumnProfilingNotification(dataFieldId);
  }, [dataFieldId]);

  if (!data) {
    return null;
  }

  return (
    <ColumnProfilingCanvas
      columnName={data.data.name}
      showOpenDetailButton={!isVisibleDetail}
      openDetail={() => navigation.openDetailWithObject(DATA_FIELD_TYPE, dataFieldId)}
      closeCanvas={() => navigation.closeDataFieldProfilingCanvas()}
      profiledAt={new Date(data.profiledAt).getTime()}
      profiledBy={data.profiledBy}
    >
      {data.data && handleGeneralData(data.data)}
      {data.data && handleStatisticData(data.data)}
      {data.data && handleCountByLengthHistogram(data.data)}
      {data.data && handleDateHistogramData(data.data)}
      {data.data && handleFrequencyData(data.data)}
    </ColumnProfilingCanvas>
  );
};

export default AccurityColumnProfilingCanvas;
