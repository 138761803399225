import { AccurityFilter, AccurityReference } from '../../../common/types/accurityTypes';
import { requestReferenceFieldSearch } from '../../../common/referenceField/rest';
import { STATUS_TYPE } from '../types/statusTypes';
import { AccurityFilterType } from 'ts-components';
import { AccurityReferenceFieldSearchResult } from '../../../common/referenceField/types';

export const fetchDefaultStatusReference = (): Promise<AccurityReference> => {
  const defaultStatusFilter: AccurityFilter = {
    type: AccurityFilterType.SIMPLE_QUERY,
    property: 'defaultIndicator',
    value: 'true',
  }

  return requestReferenceFieldSearch(STATUS_TYPE, 'id', [defaultStatusFilter])
    .then((searchResult: AccurityReferenceFieldSearchResult) => searchResult.rows[0]);
};
