import React from 'react';
import { InteractiveLink, LinkArrowVariant, LinkLineVariant } from 'ts-components';
import { useAccurityNavigation } from '../../../../common/navigation/hooks';

export interface ProcessDiagramLinkProps {
  id: string;
  objectId?: string;
  objectType?: string;
  source: string;
  target: string;
  relationType: 'ASSOCIATION' | 'DIRECTED_ASSOCIATION' | 'INHERITANCE';
}

const ProcessDiagramLink = ({ id, objectId, objectType, source, target, relationType }: ProcessDiagramLinkProps) => {
  const { openDetailWithObject, getCurrentNavigation } = useAccurityNavigation();
  const { detailType, detailId } = getCurrentNavigation();

  const arrowVariant =
    'DIRECTED_ASSOCIATION' === relationType ? LinkArrowVariant.ARROW_HEAD
      : 'INHERITANCE' === relationType ? LinkArrowVariant.CLOSED_ARROW_HEAD
      : LinkArrowVariant.NONE;

  const onClick = objectId && objectType
    ? () => openDetailWithObject(objectType, objectId)
    : undefined

  const isDetailOpen =
    objectType !== undefined && objectId !== undefined &&
    detailType === objectType &&
    String(detailId) === String(objectId);

  return (
    <InteractiveLink
      key={id}
      id={id}
      source={source}
      target={target}
      lineVariant={LinkLineVariant.LINE}
      arrowVariant={arrowVariant}
      onClick={onClick}
      withShadow={isDetailOpen}
    />
  );
}

export default ProcessDiagramLink;
