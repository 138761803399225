import * as React from 'react';
import { AccurityButton, AccurityMuiThemeProvider, ProgressIndicator } from 'ts-components';
import welcomeScreenLogo from '../../businessGlossary/images/accurity-logo-vert.svg';
import { ApplicationStatus } from '../userSettings/types/types';
import { logout } from '../userSettings/hooks/logout';

type LoadingPageProps = {
  status?: ApplicationStatus;
}

export const LoadingPage = ({ status }: LoadingPageProps) => {
  let message = '';
  const isStarting = status === ApplicationStatus.STARTING;

  if (isStarting) {
    message = 'We are preparing an instance for you. It should be ready in a few minutes.';
  }

  // the primary color differs from the one we use in app, so we need to wrap whole loading page to custom theme provider
  return (
    <AccurityMuiThemeProvider primaryColor={'#e35205'}>
      <div className="loadingPage">
        <img src={welcomeScreenLogo} className="loadingPage__logo" alt="Accurity"/>
        <ProgressIndicator className="loadingPage__progressIndicator"/>
        <span className="loadingPage__message">{message}</span>
        {isStarting && <AccurityButton
          onClick={logout}
          variant={'text'}
          color={'secondary'}
          className="loadingPage__goBackLink"
        >
          Logout
        </AccurityButton>}
      </div>
    </AccurityMuiThemeProvider>
  );
};


