import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { ATTRIBUTE_ICON, ATTRIBUTE_PLURAL_LABEL, ATTRIBUTE_TYPE } from '../types/attributeTypes';
import { useAttributeListColumns, useEmbeddedAttributeDefinitionColumns, useEmbeddedEntityColumns } from './attributeListColumns';

const AttributeList = () => {
  const attributeColumns = [
    ...useAttributeListColumns(),
    ...useEmbeddedEntityColumns(),
    ...useEmbeddedAttributeDefinitionColumns(),
  ];

  const attributeListSettings = getDefaultListSettings(ATTRIBUTE_TYPE, attributeColumns);

  return (
    <AccurityListContainer
      objectType={ATTRIBUTE_TYPE}
      title={ATTRIBUTE_PLURAL_LABEL}
      icon={ATTRIBUTE_ICON}
      columns={attributeColumns}
      defaultListSettings={attributeListSettings}
      partialImportOnly={true}
      bulkOperationsConfig={{
        removeEnabled: false,
        updateTagsEnabled: false,
        toggleEmailNotificationsEnabled: true,
      }}
    />
  );
};

export default AttributeList;
