import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { EnumOption, Tag } from 'ts-components';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { Requirement } from '../../requirement/types/requirementTypes';

export const BUSINESS_TERM_TYPE = 'business-term';
export const BUSINESS_TERM_LABEL = 'Business Term';
export const BUSINESS_TERM_PLURAL_LABEL = 'Business Terms';
export const TARGET_BUSINESS_TERM_LABEL = 'Target Business Term';
export const TARGET_BUSINESS_TERM_PLURAL_LABEL = 'Target Business Terms';
export const BUSINESS_TERM_ICON = 'businessTerm';
export const BUSINESS_TERM_REFERENCING_ICON = 'businessTermReferencing';
export const RELATED_BUSINESS_TERMS_LABEL = 'Business Terms';
export const RELATED_BUSINESS_TERM_REFERENCES_LABEL = 'References';
export const RELATED_BUSINESS_TERM_REFERENCED_BY_LABEL = 'Referenced by';
export const RELATED_BUSINESS_TERM_REFERENCED_BY_ICON = 'businessTermReferenced';
export const RELATED_BUSINESS_TERM_RELATION_TYPE_LABEL = 'Reference Type';
export const RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL = 'Reference Types';
export const RELATED_VALUE_LABEL = 'Related Value';
export const RELATED_VALUE_PLURAL_LABEL = 'Related Values';
export const RELATED_VALUE_NAME_LABEL = 'Value Name';
export const RELATED_VALUE_NAMES_LABEL = 'Value Names';
export const RELATED_VALUE_DESCRIPTION_LABEL = 'Value Description';
export const RELATED_VALUE_DESCRIPTIONS_LABEL = 'Value Descriptions';
export const BUSINESS_TERM_RELATED_VALUE_ICON = 'businessTermRelatedValue';

export const BUSINESS_TERMS_REFERENCING_TYPE = 'business-terms-referencing';
export const BUSINESS_TERMS_REFERENCING_LABEL = 'Business Terms Referencing';
export const BUSINESS_TERM_ALSO_REFERENCES_LABEL = 'Also References';

export interface BusinessTermState extends CoreEntityState<BusinessTerm> {
}

export enum BusinessTermRelatedBusinessTermRelationType {
  RELATES_TO = 'RELATES_TO',
  REPLACES = 'REPLACES',
  CALCULATED_FROM = 'CALCULATED_FROM',
  EQUIVALENT_TO = 'EQUIVALENT_TO',
  PARENT_OF = 'PARENT_OF',
  CONTAINS = 'CONTAINS'
}

// Maintain alphabetical order of enum elements
export const BusinessTermRelatedBusinessTermRelationTypeOptions: EnumOption[] = [
  { value: BusinessTermRelatedBusinessTermRelationType.CALCULATED_FROM, label: 'Calculated from' },
  { value: BusinessTermRelatedBusinessTermRelationType.CONTAINS, label: 'Contains' },
  { value: BusinessTermRelatedBusinessTermRelationType.EQUIVALENT_TO, label: 'Equivalent to' },
  { value: BusinessTermRelatedBusinessTermRelationType.PARENT_OF, label: 'Parent of' },
  { value: BusinessTermRelatedBusinessTermRelationType.RELATES_TO, label: 'Relates to' },
  { value: BusinessTermRelatedBusinessTermRelationType.REPLACES, label: 'Replaces' },
];

export const ReverseRelationTypeOptions: EnumOption[] = [
  { value: BusinessTermRelatedBusinessTermRelationType.RELATES_TO, label: 'Related to' },
  { value: BusinessTermRelatedBusinessTermRelationType.REPLACES, label: 'Replaced by' },
  { value: BusinessTermRelatedBusinessTermRelationType.CALCULATED_FROM, label: 'Used in calculation of' },
  { value: BusinessTermRelatedBusinessTermRelationType.EQUIVALENT_TO, label: 'Equivalent to' },
  { value: BusinessTermRelatedBusinessTermRelationType.PARENT_OF, label: 'Child of' },
  { value: BusinessTermRelatedBusinessTermRelationType.CONTAINS, label: 'Contained in' },
];

export enum DataCatalogRelatedBusinessTermRelationType {
  CAPTURES = 'CAPTURES',
  DEPENDS_ON = 'DEPENDS_ON',
  RELATES_TO = 'RELATES_TO',
}

// Maintain alphabetical order of enum elements
export const DataCatalogRelatedBusinessTermRelationTypeOptions: EnumOption[] = [
  { value: DataCatalogRelatedBusinessTermRelationType.CAPTURES, label: 'Captures' },
  { value: DataCatalogRelatedBusinessTermRelationType.DEPENDS_ON, label: 'Depends on' },
  { value: DataCatalogRelatedBusinessTermRelationType.RELATES_TO, label: 'Relates to' },
];

export type RelatedBusinessTermRelationType = BusinessTermRelatedBusinessTermRelationType | DataCatalogRelatedBusinessTermRelationType;

export interface RelatedBusinessTerm {
  relationType: RelatedBusinessTermRelationType,
  relatedBusinessTerm: AccurityReference
}

export interface ReferencedBy {
  name: string
}

export interface BusinessTerm extends AccurityCoreEntity, WithChildrenCounts {
  synonym?: string;
  status?: AccurityReference;
  dataSet?: AccurityReference;
  relatedBusinessTerms: RelatedBusinessTerm[];
  referencedBy: ReferencedBy[];
  relatedValues: BusinessTermRelatedValue[];
  requirements: Requirement[];
  tags: Tag[];
  chips: AccurityReference[];
  customPropertyValues: CustomPropertyValues;
}

export interface BusinessTermRelatedValue extends AccurityCoreEntity {

}
