import React from 'react';
import { DataField } from '../types/dataFieldTypes';
import { useFormikContext } from 'formik';
import RelatedBusinessTermsWithSuggestions from '../../../common/detail/formik/RelatedBusinessTermsWithSuggestions';
import { createFetchFunctionForBusinessTermsSuggestions } from '../../businessTerm/rest/fetchBusinessTermsSuggestions';
import { getBusinessTermsSuggestionsByDataFieldEndpoint } from '../../../common/rest/endpoints';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';

type RelatedBusinessTermsWithSuggestionsForDataFieldProps = {
  hideSuggestions?: boolean;
}

const RelatedBusinessTermsWithSuggestionsForDataField = ({ hideSuggestions }: RelatedBusinessTermsWithSuggestionsForDataFieldProps) => {
  const formikContext = useFormikContext<DataField>();
  const searchString = formikContext.values.dataStructure?.name + '.' + formikContext.values.name;

  return (
    <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
      <RelatedBusinessTermsWithSuggestions
        objectName={searchString}
        fetchFn={createFetchFunctionForBusinessTermsSuggestions(getBusinessTermsSuggestionsByDataFieldEndpoint())}
        hideSuggestions={hideSuggestions}
      />
    </FeatureChecker>
  )
};

export default RelatedBusinessTermsWithSuggestionsForDataField;
