import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CoreEntityState } from '../../../common/redux/types';
import { Tag } from 'ts-components';
import { DataStructure } from '../../dataStructure/types/dataStructureTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { DataSetReference } from '../../dataSet/types/dataSetTypes';
import { DataFieldReference } from '../../dataField/types/dataFieldTypes';

export const TECHNICAL_DATA_MAPPING_TYPE = 'technical-data-mapping';
export const TECHNICAL_DATA_MAPPING_LABEL = 'Technical Data Mapping';
export const TECHNICAL_DATA_MAPPING_PLURAL_LABEL = 'Technical Data Mappings';
export const TECHNICAL_DATA_MAPPING_ICON = 'technicalDataMapping';
export const TECHNICAL_DATA_MAPPING_JOIN_ICON = 'merged';
export const TECHNICAL_DATA_MAPPING_SELECTION_ICON = 'filter';
export const TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_FIELD = 'operation';
export const TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_LABEL = 'Operation';
export const TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_PLURAL_LABEL = 'Operations';
export const TECHNICAL_DATA_MAPPING_SELECTION_CRITERIA_FIELD = 'criteria';
export const TECHNICAL_DATA_MAPPING_SELECTION_CRITERIA_LABEL = 'Criteria';

// BASE
export const TECHNICAL_DATA_MAPPING_BASE_LABEL = 'Base';
export const TECHNICAL_DATA_MAPPING_BASE_FIELD = 'base';
export const TECHNICAL_DATA_MAPPING_BASE_DATA_SET_FIELD_NAME = 'baseDataSet';
// BASE DATA FIELD
export const TECHNICAL_DATA_MAPPING_BASE_DATA_SET_LABEL = 'Base Data Set';
export const TECHNICAL_DATA_MAPPING_BASE_DATA_FIELD_LABEL = 'Base Data Field';
export const TECHNICAL_DATA_MAPPING_BASE_DATA_FIELD_PLURAL_LABEL = 'Base Data Fields';
export const TECHNICAL_DATA_MAPPING_BASE_DATA_STRUCTURE_DATA_FIELDS_FIELD = 'technicalDataMappingBaseDataStructureDataFields';
// BASE SELECTIONS
export const TECHNICAL_DATA_MAPPING_BASE_SELECTION_ITEMS_FIELD = 'technicalDataMappingBaseSelectionItems';
export const TECHNICAL_DATA_MAPPING_BASE_SELECTION_LABEL = 'Base Selection';
export const TECHNICAL_DATA_MAPPING_BASE_SELECTION_PLURAL_LABEL = 'Base Selections';
// BASE JOIN
export const TECHNICAL_DATA_MAPPING_BASE_JOIN_LABEL = 'Base Join';
export const TECHNICAL_DATA_MAPPING_BASE_JOIN_PLURAL_LABEL = 'Base Joins';
export const TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD = 'technicalDataMappingBaseJoinDataStructureDataField';
export const TECHNICAL_DATA_MAPPING_BASE_JOIN_LEFT_DATA_STRUCTURE_FIELD = 'technicalDataMappingBaseJoinDataStructureDataField.leftDataStructure';
export const TECHNICAL_DATA_MAPPING_BASE_JOIN_RIGHT_DATA_STRUCTURE_FIELD = 'technicalDataMappingBaseJoinDataStructureDataField.rightDataStructure';
export const TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_FIELDS_FIELD = 'technicalDataMappingBaseJoinDataStructureDataField.technicalDataMappingBaseJoinDataFields';

// TARGET
export const TECHNICAL_DATA_MAPPING_TARGET_LABEL = 'Target';
export const TECHNICAL_DATA_MAPPING_TARGET_FIELD = 'target';
export const TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_FIELD_NAME = 'targetDataSet';
export const TECHNICAL_DATA_MAPPING_TARGET_DATA_SET_LABEL = 'Target Data Set';
// TARGET DATA FIELD
export const TECHNICAL_DATA_MAPPING_TARGET_DATA_FIELD_LABEL = 'Target Data Field';
export const TECHNICAL_DATA_MAPPING_TARGET_DATA_FIELD_PLURAL_LABEL = 'Target Data Fields';
export const TECHNICAL_DATA_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD = 'technicalDataMappingTargetDataStructureDataFields';
// TARGET SELECTIONS
export const TECHNICAL_DATA_MAPPING_TARGET_SELECTION_ITEMS_FIELD = 'technicalDataMappingTargetSelectionItems';
export const TECHNICAL_DATA_MAPPING_TARGET_SELECTION_LABEL = 'Target Selection';
export const TECHNICAL_DATA_MAPPING_TARGET_SELECTION_PLURAL_LABEL = 'Target Selections';
// TARGET JOIN
export const TECHNICAL_DATA_MAPPING_TARGET_JOIN_LABEL = 'Target Join';
export const TECHNICAL_DATA_MAPPING_TARGET_JOIN_PLURAL_LABEL = 'Target Joins';
export const TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_FIELD = 'technicalDataMappingTargetJoinDataStructureDataField';
export const TECHNICAL_DATA_MAPPING_TARGET_JOIN_LEFT_DATA_STRUCTURE_FIELD = 'technicalDataMappingTargetJoinDataStructureDataField.leftDataStructure';
export const TECHNICAL_DATA_MAPPING_TARGET_JOIN_RIGHT_DATA_STRUCTURE_FIELD = 'technicalDataMappingTargetJoinDataStructureDataField.rightDataStructure';
export const TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD = 'technicalDataMappingTargetJoinDataStructureDataField.technicalDataMappingTargetJoinDataFields';

// BASE LIST LABELS
export const TECHNICAL_DATA_MAPPING_DATA_SET_BASE_LABEL = 'Data Set (Base)';
export const TECHNICAL_DATA_MAPPING_DATA_SET_TYPE_BASE_LABEL = 'Data Set Type (Base)';
export const TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_BASE_LABEL = 'Data Structures (Base)';
export const TECHNICAL_DATA_MAPPING_DATA_FIELDS_BASE_LABEL = 'Data Fields (Base)';
export const TECHNICAL_DATA_MAPPING_OPERATIONS_BASE_LABEL = 'Operations (Base)';
export const TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_BASE_SELECTIONS_LABEL = 'Data Structures (Base Selections)';
export const TECHNICAL_DATA_MAPPING_DATA_FIELDS_BASE_SELECTIONS_LABEL = 'Data Fields (Base Selections)';
export const TECHNICAL_DATA_MAPPING_CRITERIA_BASE_SELECTIONS_LABEL = 'Criteria (Base Selections)';
export const TECHNICAL_DATA_MAPPING_LEFT_DATA_STRUCTURE_BASE_JOINS_LABEL = 'Left Data Structure (Base Joins)';
export const TECHNICAL_DATA_MAPPING_RIGHT_DATA_STRUCTURE_BASE_JOINS_LABEL = 'Right Data Structure (Base Joins)';
export const TECHNICAL_DATA_MAPPING_LEFT_DATA_FIELDS_BASE_JOINS_LABEL = 'Left Data Fields (Base Joins)';
export const TECHNICAL_DATA_MAPPING_RIGHT_DATA_FIELDS_BASE_JOINS_LABEL = 'Right Data Fields (Base Joins)';

// TARGET LIST LABELS
export const TECHNICAL_DATA_MAPPING_DATA_SET_TARGET_LABEL = 'Data Set (Target)';
export const TECHNICAL_DATA_MAPPING_DATA_SET_TYPE_TARGET_LABEL = 'Data Set Type (Target)';
export const TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_TARGET_LABEL = 'Data Structures (Target)';
export const TECHNICAL_DATA_MAPPING_DATA_FIELDS_TARGET_LABEL = 'Data Fields (Target)';
export const TECHNICAL_DATA_MAPPING_OPERATIONS_TARGET_LABEL = 'Operations (Target)';
export const TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_TARGET_SELECTIONS_LABEL = 'Data Structures (Target Selections)';
export const TECHNICAL_DATA_MAPPING_DATA_FIELDS_TARGET_SELECTIONS_LABEL = 'Data Fields (Target Selections)';
export const TECHNICAL_DATA_MAPPING_CRITERIA_TARGET_SELECTIONS_LABEL = 'Criteria (Target Selections)';
export const TECHNICAL_DATA_MAPPING_LEFT_DATA_STRUCTURE_TARGET_JOINS_LABEL = 'Left Data Structure (Target Joins)';
export const TECHNICAL_DATA_MAPPING_RIGHT_DATA_STRUCTURE_TARGET_JOINS_LABEL = 'Right Data Structure (Target Joins)';
export const TECHNICAL_DATA_MAPPING_LEFT_DATA_FIELDS_TARGET_JOINS_LABEL = 'Left Data Fields (Target Joins)';
export const TECHNICAL_DATA_MAPPING_RIGHT_DATA_FIELDS_TARGET_JOINS_LABEL = 'Right Data Fields (Target Joins)';

export interface TechnicalDataMappingState extends CoreEntityState<TechnicalDataMapping> {
}

export interface TechnicalDataMapping extends AccurityCoreEntity {
  status: AccurityReference;
  tags: Tag[];
  baseDataSet?: DataSetReference;
  technicalDataMappingBaseDataStructureDataFields: TechnicalDataMappingBaseDataStructureDataField[];
  technicalDataMappingBaseSelectionItems: TechnicalDataMappingBaseSelectionItem[];
  technicalDataMappingBaseJoinDataStructureDataField?: TechnicalDataMappingBaseJoinDataStructureDataField;
  targetDataSet?: DataSetReference;
  technicalDataMappingTargetDataStructureDataFields: TechnicalDataMappingTargetDataStructureDataField[];
  technicalDataMappingTargetSelectionItems: TechnicalDataMappingTargetSelectionItem[];
  technicalDataMappingTargetJoinDataStructureDataField?: TechnicalDataMappingTargetJoinDataStructureDataField;
  customPropertyValues: CustomPropertyValues;
}

export interface TechnicalDataMappingBaseDataStructureDataField {
  id: number;
  dataStructure: AccurityReference;
  dataField?: DataFieldReference;
  operation?: string;
}

export interface TechnicalDataMappingBaseSelectionItem {
  id: number;
  dataStructure?: AccurityReference;
  dataField?: DataFieldReference;
  criteria?: string;
}

export interface TechnicalDataMappingBaseJoinDataStructureDataField {
  id: number;
  leftDataStructure: DataStructure;
  rightDataStructure: DataStructure;
  technicalDataMappingBaseJoinDataFields?: TechnicalDataMappingBaseJoinDataField[];
}

export interface TechnicalDataMappingBaseJoinDataField {
  id: number;
  leftDataField: AccurityReference;
  rightDataField: AccurityReference;
}

export interface TechnicalDataMappingTargetDataStructureDataField {
  id: number;
  dataStructure: AccurityReference;
  dataField?: DataFieldReference;
  operation?: string;
}

export interface TechnicalDataMappingTargetSelectionItem {
  id: number;
  dataStructure?: AccurityReference;
  dataField?: DataFieldReference;
  criteria?: string;
}

export interface TechnicalDataMappingTargetJoinDataStructureDataField {
  id: number;
  leftDataStructure: AccurityReference;
  rightDataStructure: AccurityReference;
  technicalDataMappingTargetJoinDataFields?: TechnicalDataMappingTargetJoinDataField[];
}

export interface TechnicalDataMappingTargetJoinDataField {
  id: number;
  leftDataField: AccurityReference;
  rightDataField: AccurityReference;
}