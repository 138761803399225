import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../../common/redux/types';
import { commonReducers } from '../../../../common/redux/commonReducers';
import { PROCESS_STEP_TYPE, ProcessStepState } from '../types/processStepTypes';

const initialState: ProcessStepState = getInitialCoreEntityState(PROCESS_STEP_TYPE);

const processStepSlice = createSlice({
  name: PROCESS_STEP_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default processStepSlice.reducer;
