export interface ChildrenCounts {
  id: number,
  type: string,
  dataStructuresCount: number,
  dataAssetsCount: number,
  dataSetsCount: number,
  dataFieldsCount: number,
  businessModelMappingsCount: number,
  businessTermsCount: number,
  businessRulesCount: number,
  entitiesCount: number,
  referenceAttributeTargetEntitiesCount: number,
  attributesCount: number,
  calculationRulesCount: number;
  attributeDefinitionsCount: number,
  componentAttributeDefinitionCount: number,
  compositeTypesCount: number,
  customPropertiesCount: number,
  processesCount: number,
  processStepsCount: number,
  processMappingsCount: number;
  technicalDataMappingsCount: number;
}

export interface WithChildrenCounts {
  childrenCounts: ChildrenCounts;
}

export enum ChildrenCountWording {
  SHOW = 'Show',
  HAS = 'Has',
  REFERENCED_BY = 'Referenced by',
  USED_BY = 'Used by',
  RELATED_TO = 'Related to',
  COMPOSED_OF = 'Composed of',
  REFERENCES = 'References',
  TARGET_OF = 'Target of',
  BASE_FOR = 'Base for',
  NONE = '',
}
