import { AccurityReference } from '../../../common/types/accurityTypes';
import { getGenerateProcessGraphEndpoint, getGraphLoadEndpoint, getGraphSaveEndpoint, getHideNodeEndpoint } from '../../../common/rest/endpoints';
import { doFetch } from '../../../common/rest/FetchService';
import { showErrorMessageFromResponse } from '../../../common/userMessages/actions';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramBasedOnType } from '../types/processDiagramTypes';
import { Graph, GraphContent } from '../../businessModelDiagram/types/businessModelDiagramTypes';

export const requestGenerateGraph = (items: AccurityReference[], basedOnType: ProcessDiagramBasedOnType): Promise<GraphContent> => {
  const generateEndpoint = getGenerateProcessGraphEndpoint(basedOnType);
  const requestBody = JSON.stringify(items);

  return doFetch(generateEndpoint, 'POST', requestBody)
    .catch(showErrorMessageFromResponse);
}

export const requestSaveGraph = (graph: Graph): Promise<Graph> => {
  const graphEndpoint = getGraphSaveEndpoint(PROCESS_DIAGRAM_TYPE);
  const requestBody = JSON.stringify(graph);

  return doFetch(graphEndpoint, 'PUT', requestBody)
    .catch(showErrorMessageFromResponse);
}

export const requestLoadGraph = (diagramId: string): Promise<Graph> => {
  const graphEndpoint = getGraphLoadEndpoint(PROCESS_DIAGRAM_TYPE, diagramId);

  return doFetch(graphEndpoint, 'GET')
    .catch(showErrorMessageFromResponse);
}

export const requestHideNode = (graphContent: GraphContent, nodeId: string): Promise<GraphContent> => {
  const hideNodeEndpoint = getHideNodeEndpoint(PROCESS_DIAGRAM_TYPE, nodeId);
  const payload = JSON.stringify(graphContent);

  return doFetch(hideNodeEndpoint, 'POST', payload)
    .catch(showErrorMessageFromResponse);
};

