import React from 'react';
import { Attribute, ATTRIBUTE_ICON, ATTRIBUTE_TYPE, CALCULATION_RULE_LABEL, CALCULATION_RULE_PLURAL_LABEL } from '../types/attributeTypes';
import { FormikProps } from 'formik';
import { DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { StaticField } from 'ts-components';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_LABEL, BUSINESS_RULE_PLURAL_LABEL, BUSINESS_RULE_TYPE } from '../../businessRule/types/businessRuleTypes';
import KeepDiscardButtonBar, { KeepDiscardAction } from './KeepDiscardButtonBar';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../process/mapping/types/processMappingTypes';

interface AttributeConfirmRemovalDetailProps {
  formik: FormikProps<Attribute>
  detailBag: DetailBag<Attribute>
}

const AttributeConfirmRemovalDetail = ({ formik, detailBag }: AttributeConfirmRemovalDetailProps) => {
  const hasInheritedChildAttributes = formik.values.childrenCounts.attributesCount > 0;

  return (
    <ConfirmRemovalDetail
      iconName={ATTRIBUTE_ICON}
      detailBag={detailBag}
      bottomBar={
        hasInheritedChildAttributes
          ?
          <KeepDiscardButtonBar
            detailBag={detailBag}
            keepDiscardAction={KeepDiscardAction.REMOVE}
          />
          :
          undefined
      }
    >
      <StaticField
        name={'removalDescription1'}
        value={IT_IS_CURRENTLY_LABEL}
        inverted={true}
      />
      <FeatureChecker featureId={Feature.BUSINESS_RULES}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_RULE_LABEL}
          pluralLabel={BUSINESS_RULE_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessRulesCount}
          iconName={BUSINESS_RULE_ICON}
          pinIconName={ATTRIBUTE_ICON}
          childFieldType={BUSINESS_RULE_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.CALCULATION_RULES}>
        <ChildrenCountFieldWithFormik
          singularLabel={CALCULATION_RULE_LABEL}
          pluralLabel={CALCULATION_RULE_PLURAL_LABEL}
          value={formik.values.childrenCounts.calculationRulesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ATTRIBUTE_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          relationType={'CALCULATION_RULE'}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
          pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessModelMappingsCount}
          iconName={BUSINESS_MODEL_MAPPING_ICON}
          pinIconName={ATTRIBUTE_ICON}
          childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={ATTRIBUTE_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      {formik.values.childrenCounts.attributesCount > 0 &&
      <StaticField
        name={'removalDescription'}
        value={
          'Removing this Attribute will leave existing inherited Attributes without a parent. ' +
          'You can either keep these inherited Attributes (turning them into non-inherited), or discard them.'
        }
        inverted={true}
      />}
    </ConfirmRemovalDetail>
  );
};

export default AttributeConfirmRemovalDetail;

