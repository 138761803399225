import { ProfilingDataType, UserInfo } from 'ts-components';

export const COLUMN_PROFILING_TYPE = 'column-profiling';

type OccurringValues = {
  value: any;
  count: number;
}

export type GeneralData = {
  totalCount: number;
  distinctCount: number;
  uniqueCount: number;
  nullCount: number;
  nullPercent: number;
  occuringValues?: OccurringValues[];
}

export type DataPair = {
  value: string;
  count: number;
  percent: number;
}

export type FrequencyData = {
  mostOccurring: DataPair[];
  leastOccurring: DataPair[];
  countByLength: DataPair[];
}

export type DateFrequencyData = {
  year?: DataPair[];
  month?: DataPair[];
  dayOfTheWeek?: DataPair[];
}

type StatisticData = {
  min: number;
  max: number;
  mean: number;
}

export type NumericStatisticData = {
  standardDeviation: number;
  median: number;
  variance: number;
  sum: number;
} & StatisticData;

export type DateStatisticData = {
} & StatisticData;

export type ResultData = {
  type: ProfilingDataType;
  name: string;
  dataSourceType: string;
  generalData: GeneralData;
  frequencyData: FrequencyData;
  dateFrequencyData?: DateFrequencyData;
  numericStatisticData?:  NumericStatisticData;
  dateStatisticsData?:  DateStatisticData;
}

export enum ProfileStatus {
  PREPARING = 'PREPARING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

export type ProfileResult  = {
  id: number;
  dataFieldId: number;
  data: ResultData;
  profileStatus: ProfileStatus;
  runTime: number;
  message: string;
  profiledAt: string;
  profiledBy: UserInfo
}

export interface ColumnProfilingReduxState {
  result?: ProfileResult;
  inProgressProfiling: string[]; // data fields ids
  lastProfiledDataFieldId?: string;
}
