import React from 'react';
import CollectionFieldWithFormik from './CollectionFieldWithFormik';
import ReferenceFieldWithFormik from './ReferenceFieldWithFormik';
import {
  BUSINESS_TERM_ICON,
  BUSINESS_TERM_LABEL,
  BUSINESS_TERM_TYPE,
  TARGET_BUSINESS_TERM_LABEL,
  TARGET_BUSINESS_TERM_PLURAL_LABEL
} from '../../../businessGlossary/businessTerm/types/businessTermTypes';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity, AccurityFilter } from '../../types/accurityTypes';
import { createUniqueWithinCollectionFilters } from '../../referenceField/utils/filters';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../referenceField/utils/dragAndDrop';
import { getProcessMappingCountLabel } from '../../../businessGlossary/process/mapping/detail/processMappingDetailUtils';

const TARGET_BUSINESS_TERMS_FIELD_NAME = 'targetBusinessTerms';

interface TargetBusinessTermsFieldWithFormikProps {
  additionalFilters?: AccurityFilter[];
}

const TargetBusinessTermsFieldWithFormik = ({ additionalFilters = [] }: TargetBusinessTermsFieldWithFormikProps) => {
  const formikContext = useFormikContext<AccurityCoreEntity>();
  const targetBusinessTerms = formikContext.getFieldProps(TARGET_BUSINESS_TERMS_FIELD_NAME).value;

  const collectionTitle =
    getProcessMappingCountLabel(targetBusinessTerms, TARGET_BUSINESS_TERM_LABEL, TARGET_BUSINESS_TERM_PLURAL_LABEL);

  const targetBusinessTermsFilters = [
    ...additionalFilters,
    ...createUniqueWithinCollectionFilters('id', targetBusinessTerms),
  ];

  return (
    <CollectionFieldWithFormik
      name={TARGET_BUSINESS_TERMS_FIELD_NAME}
      title={collectionTitle}
      icon={BUSINESS_TERM_ICON}
      rowValidation={(value) => value && value.id}
      testDraggedItem={defaultTestDraggedItem(BUSINESS_TERM_TYPE)}
      testDroppedItem={defaultTestDroppedItem({ objectType: BUSINESS_TERM_TYPE, additionalFilters: targetBusinessTermsFilters })}
      renderRow={(elementName) =>
        <ReferenceFieldWithFormik
          name={elementName}
          label={BUSINESS_TERM_LABEL}
          icon={BUSINESS_TERM_ICON}
          objectType={BUSINESS_TERM_TYPE}
          additionalFilters={targetBusinessTermsFilters}
          hideRemoveButton={true}
        />
      }
    />
  );
};

export default TargetBusinessTermsFieldWithFormik;
