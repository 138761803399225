import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationCenterNotification, NotificationCenterNotificationType, NotificationCenterState } from './types';

const initialState: NotificationCenterState = {
  notifications: [],
};

const notificationCenterSlice = createSlice({
  name: 'notificationCenter',
  initialState: initialState,
  reducers: {
    pushNotification: (state: NotificationCenterState, action: PayloadAction<NotificationCenterNotification>) => {
      const existingNotification = state.notifications.find(notification => notification.id === action.payload.id);
      if (existingNotification && existingNotification.date >= action.payload.date) {
        return; // We have more recent notification for this ID, do nothing
      }

      // Merge new Notification onto Existing if it is present
      const newNotification: NotificationCenterNotification = {
        ...existingNotification,
        ...action.payload,
        wasRead: false,
      };

      // Remove existing Notification for that ID if it is present
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload.id);

      // Add new notification to state
      state.notifications.push(newNotification);

      // Sort all notifications by date
      state.notifications.sort((a, b) => b.date - a.date);
    },

    clearImportDownloadReportsFromOtherNotifications: (state: NotificationCenterState, action: PayloadAction<{ id: string, objectType: string }>) => {
      state.notifications = state.notifications.map(notification => {
        if (notification.type === NotificationCenterNotificationType.IMPORT && notification.objectType === action.payload.objectType && notification.id !== action.payload.id) {
          return {
            ...notification,
            showDownloadReport: false,
          }
        } else {
          return notification;
        }
      })
    },

    clearExportDownloadReportsFromOtherNotifications: (state: NotificationCenterState, action: PayloadAction<{ id: string, objectType: string }>) => {
      state.notifications = state.notifications.map(notification => {
        if (notification.type === NotificationCenterNotificationType.EXPORT && notification.objectType === action.payload.objectType && notification.id !== action.payload.id) {
          return {
            ...notification,
            showDownloadReport: false,
          }
        } else {
          return notification;
        }
      })
    },


    markAllNotificationsAsRead: (state: NotificationCenterState) => {
      state.notifications = state.notifications.map(notification => ({ ...notification, wasRead: true }))
    },

    dismissNotification: (state: NotificationCenterState, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
    },

    dismissAllNotifications: (state: NotificationCenterState) => {
      state.notifications = state.notifications.filter(notification => notification.cannotDismiss);
    },
  },
});

export const {
  pushNotification,
  clearImportDownloadReportsFromOtherNotifications,
  clearExportDownloadReportsFromOtherNotifications,
  markAllNotificationsAsRead,
  dismissNotification,
  dismissAllNotifications,
} = notificationCenterSlice.actions;

export default notificationCenterSlice.reducer;
