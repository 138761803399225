import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { CHILD_DATA_ASSETS_LABEL, DATA_ASSET_DATA_SETS, PARENT_DATA_ASSET_LABEL } from '../types/dataAssetTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { PROCESS_MAPPING_PLURAL_LABEL } from '../../process/mapping/types/processMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useDataAssetListColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const dataAssetListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    columnFactory.createTextColumn('parent.name', PARENT_DATA_ASSET_LABEL),
    commonColumns.collection({ name: 'dataSets.name', label: DATA_ASSET_DATA_SETS }),
    commonColumns.tags(navigationController),
    ...(hasFeature(Feature.DATA_ASSETS) ?
      [columnFactory.createNumberColumn('childrenCounts.dataAssetsCount', CHILD_DATA_ASSETS_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL,
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(dataAssetListColumns, GlossaryCustomPropertyObjectType.DATA_ASSET);
};
