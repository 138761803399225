import { getDataSetSyncScanEndpoint, getDataSetSyncUpdateEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';

export const getDataSetChanges = (dataSetId: string, abortSignal?: AbortSignal) => {
  const url = getDataSetSyncScanEndpoint(dataSetId);
  return doFetch(url, 'GET', undefined, { abortSignal });
};

export const updateDataSet = (dataSetId: string, dataStructureIds: (string | number)[]) => {
  const url = getDataSetSyncUpdateEndpoint(dataSetId);
  return doFetch(url, 'POST', JSON.stringify(dataStructureIds));
};
