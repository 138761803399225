import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { AccurityGlobalSearchResponse, requestGlobalSearch } from './rest';

const SEARCH_DEBOUNCE_RATE = 200;

export const useGlobalSearchFetchData = () => {

  const [response, setResponse] = useState<AccurityGlobalSearchResponse | null>(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCurrentRender = true;

    if (searchText.length === 0) {
      // clear data when user empties the search string
      setResponse({ rows: [], existingObjects: 0, size: 0, maximumObjects: 0, additionalExistingObjects: -1, additionalMaximumObjects: -1 });
    } else {
      setIsLoading(true);
      requestGlobalSearch(searchText).then((response) => {
        if (isCurrentRender) {
          setIsLoading(false);
          setResponse(response);
        }
      });
    }

    return () => {
      isCurrentRender = false;
    }
  }, [searchText, setResponse]);

  const size = response?.size || 0;
  const existingObjects = response?.existingObjects || 0;
  const rows = response?.rows || [];

  return {
    rows,
    existingObjects,
    size,
    isLoading,
    setSearchText: debounce(setSearchText, SEARCH_DEBOUNCE_RATE)
  };
};
