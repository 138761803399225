import React from 'react';
import FileChooserFieldWithFormik from '../../../../common/detail/formik/FileChooserFieldWithFormik';
import { useFormikContext } from 'formik';
import { FILE_SIZE_LIMIT } from '../DataSourceDetail';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';

const ACCEPTED_BIGQUERY_FILE_TYPES = '.json';

const BigQueryDataSourceFields = () => {
  const formik = useFormikContext();
  return (
    <>
      {!formik.status.isVersionBrowser &&
      <FileChooserFieldWithFormik
        name={'fileData'}
        label={'Select Service Account Key File'}
        fileNameField={'fileName'}
        accept={ACCEPTED_BIGQUERY_FILE_TYPES}
        sizeRestriction={FILE_SIZE_LIMIT}
        disabled={!formik.status.permissions.hasCreatePermission}
      />}
      <SingleLineFieldWithFormik
        name={'database'}
        label={'Dataset'}
      />
    </>
  );
};

export default BigQueryDataSourceFields;
