import React from 'react';
import { useHasFeature } from '../hooks/features';

interface FeatureCheckerOrProps {
  featureIds: string[],
}

const FeatureCheckerOr = ({ featureIds, children }: React.PropsWithChildren<FeatureCheckerOrProps>): JSX.Element | null => {
  const hasFeature = useHasFeature();

  let featuresEnabled = false;

  featureIds.forEach(featureId => {
    featuresEnabled = featuresEnabled || hasFeature(featureId);
  });

  if (featuresEnabled) {
    return (
      <>
        {children}
      </>
    )
  }
  return null;
};

export default FeatureCheckerOr;
