import { Entity, ENTITY_ICON } from '../types/entityTypes';
import { StaticField } from 'ts-components';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import {
  ATTRIBUTE_ICON,
  ATTRIBUTE_LABEL,
  ATTRIBUTE_PLURAL_LABEL,
  ATTRIBUTE_TYPE,
  REFERENCE_ATTRIBUTE_LABEL,
  REFERENCE_ATTRIBUTE_PLURAL_LABEL
} from '../../attribute/types/attributeTypes';
import {
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_LABEL,
  ATTRIBUTE_DEFINITION_PLURAL_LABEL,
  ATTRIBUTE_DEFINITION_TYPE
} from '../../attributeDefinition/types/attributeDefinitionTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { COMPOSITE_TYPE_ICON, COMPOSITE_TYPE_LABEL, COMPOSITE_TYPE_PLURAL_LABEL, COMPOSITE_TYPE_TYPE } from '../../compositeType/types/compositeTypeTypes';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import React from 'react';
import { FormikProps } from 'formik';
import { DetailBag, IT_ALSO_LABEL, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import KeepDiscardButtonBar, { KeepDiscardAction } from '../../attribute/detail/KeepDiscardButtonBar';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../process/mapping/types/processMappingTypes';

interface EntityConfirmRemovalDetailProps {
  formik: FormikProps<Entity>
  detailBag: DetailBag<Entity>
}

const EntityConfirmRemovalDetail = ({ formik, detailBag }: EntityConfirmRemovalDetailProps) => {
  const usedInInheritedAttributes = formik.values.childrenCounts.attributesCount > 0 && formik.values.childrenCounts.entitiesCount > 0;
  return (
    <ConfirmRemovalDetail
      iconName={ENTITY_ICON}
      detailBag={detailBag}
      bottomBar={
        usedInInheritedAttributes
          ?
          <KeepDiscardButtonBar
            detailBag={detailBag}
            keepDiscardAction={KeepDiscardAction.REMOVE}
          />
          :
          undefined
      }
    >
      <StaticField
        name={'removalDescriptionGroup1'}
        value={IT_IS_CURRENTLY_LABEL}
        inverted={true}
      />
      <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
          pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessModelMappingsCount}
          iconName={BUSINESS_MODEL_MAPPING_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        <ChildrenCountFieldWithFormik
          singularLabel={REFERENCE_ATTRIBUTE_LABEL}
          pluralLabel={REFERENCE_ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.referenceAttributeTargetEntitiesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          relationType={'REFERENCE_ATTRIBUTE_TARGET_ENTITY'}
          childrenCountWording={ChildrenCountWording.TARGET_OF}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_DEFINITION_LABEL}
          pluralLabel={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributeDefinitionsCount}
          iconName={ATTRIBUTE_DEFINITION_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ATTRIBUTE_DEFINITION_TYPE}
          childrenCountWording={ChildrenCountWording.USED_BY}
          inverted={true}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.COMPOSITE_TYPES}>
        <ChildrenCountFieldWithFormik
          singularLabel={COMPOSITE_TYPE_LABEL}
          pluralLabel={COMPOSITE_TYPE_PLURAL_LABEL}
          value={formik.values.childrenCounts.compositeTypesCount}
          iconName={COMPOSITE_TYPE_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={COMPOSITE_TYPE_TYPE}
          childrenCountWording={ChildrenCountWording.USED_BY}
          inverted={true}
        />
      </FeatureChecker>

      {formik.values.childrenCounts.attributesCount > 0 &&
      <StaticField
        name={'removalDescriptionGroup2'}
        value={IT_ALSO_LABEL}
        inverted={true}
      />}
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        {formik.values.childrenCounts.attributesCount > 0 &&
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_LABEL}
          pluralLabel={ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          childrenCountWording={ChildrenCountWording.HAS}
          inverted={true}
        />}
      </FeatureChecker>
      {formik.values.childrenCounts.attributesCount > 0 &&
      <StaticField
        name={'removalDescriptionGroup3'}
        value={'These Attributes will be removed together with the Entity.'}
        inverted={true}
      />
      }
      {formik.values.childrenCounts.attributesCount > 0 && formik.values.childrenCounts.entitiesCount > 0 &&
      <StaticField
        name={'removalDescriptionGroup4'}
        value={'Removing these Attributes will leave existing inherited Attributes without a parent. You can either keep those inherited Attributes (turning them into non-inherited), or discard them.'}
        inverted={true}
      />}
    </ConfirmRemovalDetail>
  );
};

export default EntityConfirmRemovalDetail;
