import React from 'react';
import { ListPinFilter } from 'ts-components';
import { AccurityPinFilter } from '../types/accurityTypes';
import { useAccurityNavigation } from './hooks';
import { globalPinFiltersUpdate } from '../userSettings/actions/userSettingsActions';
import { useDispatch } from 'react-redux';
import { isValidGlobalPinFilterForListType } from '../list/listUtils';

interface GlobalPinFiltersProps {
  pinFilters?: AccurityPinFilter[]
}

const GlobalPinFilters = ({ pinFilters = [] }: GlobalPinFiltersProps) => {
  const dispatch = useDispatch();
  const { listType } = useAccurityNavigation().getCurrentNavigation();

  const handleRemoveGlobalPinFilter = (index: number) => {
    dispatch(globalPinFiltersUpdate(
      pinFilters.filter((pin, i) => index !== i)
    ));
  };

  const handleDisableGlobalPinFilter = (index: number) => {
    dispatch(globalPinFiltersUpdate(
      pinFilters.map((pin, i) => index === i
        ? { ...pin, disabled: !pin.disabled }
        : pin
      )));
  };

  return (
    <>
      {pinFilters.map((pinFilter, index) => {
        const isValid = isValidGlobalPinFilterForListType(pinFilter, listType);
        return (
          <ListPinFilter
            key={`${pinFilter.label}-${pinFilter.property}`}
            icon={pinFilter.icon}
            text={pinFilter.label || ''}
            disabled={pinFilter.disabled || !isValid}
            onRemove={() => handleRemoveGlobalPinFilter(index)}
            onDisable={isValid ? () => handleDisableGlobalPinFilter(index) : undefined}
          />
        )
      })}
    </>
  );
};

export default GlobalPinFilters;
