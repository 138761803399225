import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { ATTRIBUTE_DEFINITION_TYPE, AttributeDefinitionState } from '../types/attributeDefinitionTypes';

const initialState: AttributeDefinitionState = getInitialCoreEntityState(ATTRIBUTE_DEFINITION_TYPE);

const attributeDefinitionSlice = createSlice({
  name: ATTRIBUTE_DEFINITION_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default attributeDefinitionSlice.reducer;
