import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { BUSINESS_TERM_TYPE, BusinessTermState } from '../types/businessTermTypes';

const initialState: BusinessTermState = getInitialCoreEntityState(BUSINESS_TERM_TYPE);

const businessTermSlice = createSlice({
  name: BUSINESS_TERM_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default businessTermSlice.reducer;
