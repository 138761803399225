import React from 'react';
import { useHasFeature } from '../hooks/features';

interface FeatureOffCheckerProps {
  featureId: string,
}

const FeatureOffChecker = ({ featureId, children }: React.PropsWithChildren<FeatureOffCheckerProps>): JSX.Element | null => {
  const hasFeature = useHasFeature();

  if (hasFeature(featureId)) {
    return null;
  }
  return (
    <>
      {children}
    </>
  )
};

export default FeatureOffChecker;