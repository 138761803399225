import React from 'react';
import { AccurityButton, DetailBottomBar } from 'ts-components';
import { Attribute } from '../types/attributeTypes';
import { DetailBag } from '../../../common/detail/types/types';

export enum KeepDiscardAction {
  SAVE,
  REMOVE,
}

interface KeepDiscardButtonBarProps {
  detailBag: DetailBag<Attribute>;
  keepDiscardAction: KeepDiscardAction;
}

const KeepDiscardButtonBar = ({ detailBag, keepDiscardAction }: KeepDiscardButtonBarProps) => {

  const handleDiscard = () => {
    if (keepDiscardAction === KeepDiscardAction.SAVE) {
      detailBag.saveAction({
        keepInheritedAttributes: false,
      });
    } else {
      detailBag.removeAction({
        keepInheritedAttributes: false,
      });
    }
  }

  const handleKeep = () => {
    if (keepDiscardAction === KeepDiscardAction.SAVE) {
      detailBag.saveAction({
        keepInheritedAttributes: true,
      });
    } else {
      detailBag.removeAction({
        keepInheritedAttributes: true,
      });
    }
  }

  return (
    <DetailBottomBar>
      <AccurityButton
        onClick={() => detailBag.setSettingsDetail(undefined)}
        disabled={detailBag.isLoading}
        color="light"
      >
        Cancel
      </AccurityButton>
      <AccurityButton
        onClick={handleDiscard}
        disabled={
          detailBag.isLoading ||
          (keepDiscardAction === KeepDiscardAction.REMOVE && detailBag.isRemoveDisabled)
        }
        color="primary"
      >
        Discard
      </AccurityButton>
      <AccurityButton
        onClick={handleKeep}
        disabled={
          detailBag.isLoading ||
          (keepDiscardAction === KeepDiscardAction.REMOVE && detailBag.isRemoveDisabled)
        }
        color="primary"
      >
        Keep
      </AccurityButton>
    </DetailBottomBar>
  );
};

export default KeepDiscardButtonBar;
