import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { STATUS_TYPE, StatusState } from '../types/statusTypes';

const initialState: StatusState = getInitialCoreEntityState(STATUS_TYPE);

const statusSlice = createSlice({
  name: STATUS_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default statusSlice.reducer;
