import { DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_TYPE, DataFieldReference } from '../../../dataField/types/dataFieldTypes';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE } from '../../../dataStructure/types/dataStructureTypes';
import { createReferenceFieldFilter, excludeFieldValueFilter } from '../../../../common/referenceField/utils/filters';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps, useField } from 'formik';
import {
  TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_FIELD,
  TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD,
  TechnicalDataMapping,
  TechnicalDataMappingTargetDataStructureDataField
} from '../../types/technicalDataMappingTypes';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface TargetDataFieldProps {
  elementName: string;
  index: number;
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<TechnicalDataMapping>;
}

const TechnicalDataMappingTargetDataField = ({ elementName, index, additionalFilters = [], formik }: TargetDataFieldProps) => {

  const dataStructureId = formik.values.technicalDataMappingTargetDataStructureDataFields[index]?.dataStructure?.id;

  const dataStructuresFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.targetDataSet?.id),
    createReferenceFieldFilter('childrenCounts.dataFieldsCount', '>=1'),
    ...additionalFilters,
  ];

  const dataFieldsFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.targetDataSet?.id),
    ...additionalFilters
  ];

  const [targetDataStructureDataFields] = useField<TechnicalDataMappingTargetDataStructureDataField[]>(TECHNICAL_DATA_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD);
  targetDataStructureDataFields.value.map(row => row?.dataField)
    .filter(value => value !== null)
    .forEach(value => {
      if (!!value && !!value.id) {
        dataFieldsFilter.push(
          excludeFieldValueFilter('id', value.id)
        );
      }
    });

  if (dataStructureId) {
    dataFieldsFilter.push(
      createReferenceFieldFilter('dataStructure.id', dataStructureId)
    );
  }

  const coloring = useVersionBrowserColoring(elementName);

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
        coloring={coloring}
      >
        <ReferenceFieldWithFormik
          name={elementName + '.dataStructure'}
          label={DATA_STRUCTURE_LABEL}
          objectType={DATA_STRUCTURE_TYPE}
          icon={DATA_STRUCTURE_ICON}
          additionalFilters={dataStructuresFilter}
          setValue={(dataStructure) => {
            formik.setFieldValue(elementName + '.dataStructure', dataStructure);
            formik.setFieldValue(elementName + '.dataField', null);
          }}
          disableColoring={true}
        />
        <ReferenceFieldWithFormik<DataFieldReference>
          name={elementName + '.dataField'}
          label={DATA_FIELD_LABEL}
          objectType={DATA_FIELD_TYPE}
          icon={DATA_FIELD_ICON}
          additionalFilters={dataFieldsFilter}
          setValue={(dataField) => {
            if (!dataStructureId) {
              formik.setFieldValue(elementName + '.dataStructure', dataField?.dataStructure);
            }
            formik.setFieldValue(elementName + '.dataField', dataField);
          }}
          disableColoring={true}
        />
      </DetailInlineGroup>
      <DetailInlineGroup
        indentLeft={true}
        coloring={coloring}
      >
        <SingleLineFieldWithFormik
          name={elementName + '.' + TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_FIELD}
          label={TECHNICAL_DATA_MAPPING_DATA_FIELD_OPERATION_LABEL}
          disableColoring={true}
        />
      </DetailInlineGroup>
    </>
  )
};

export default TechnicalDataMappingTargetDataField;
