import React, { useEffect } from 'react';
import { AccurityButton, CollapsingGroup, FileChooser, HyperlinkField } from 'ts-components';
import { AppSettingsState } from '../types/types';
import mime from 'mime-types';

// 10MB is frontend file size limit to prevent user from uploading file bigger than this value
// (Standard backend validation will be triggered for files with size between 1MB and 10MB)
const LOGO_SIZE_LIMIT = 1024 * 1024 * 10;

export const createFile = (filename: string) => {
  return new File([], filename, { type: mime.lookup(filename) || undefined });
};

interface CustomLogoSettingGroupProps {
  appSettings: AppSettingsState,
  trackAction: (category: string) => void;
  customLogoFile?: File,
  setCustomLogoFile: (customLogoFile?: File) => void;
  customLogoLink?: string,
  setCustomLogoLink: (customLogoLink?: string) => void;
}

const CustomLogoSettingGroup = ({ appSettings, trackAction, customLogoFile, setCustomLogoFile, customLogoLink, setCustomLogoLink }: CustomLogoSettingGroupProps) => {
  // update local file and link when new properties are passed as a result of save action
  useEffect(() => {
    setCustomLogoFile(appSettings.customLogoFilename ? createFile(appSettings.customLogoFilename) : undefined);
    setCustomLogoLink(appSettings.customLogoLink);
  }, [appSettings.customLogoFilename, setCustomLogoFile, appSettings.customLogoLink, setCustomLogoLink]);


  const handleSetCustomLogo = (customLogoFile: File) => {
    setCustomLogoFile(customLogoFile);
    trackAction('Set Custom Logo');
  };

  const handleRemoveCustomLogo = () => {
    setCustomLogoFile(undefined);
    setCustomLogoLink(undefined);
    trackAction('Remove Custom Logo');
  };

  return (
    <CollapsingGroup
      name={'customLogo'}
      title={'Custom Logo'}
      icon={'mui:SettingsOutlined'}
      expandedByDefault={false}
      expandable={true}
    >
      <FileChooser
        name={'customLogoFile'}
        label={'Select New Custom Logo File'}
        accept={'image/*'}
        sizeRestriction={LOGO_SIZE_LIMIT}
        multiple={false}
        files={customLogoFile ? [customLogoFile] : []}
        onFileSelection={fileList => handleSetCustomLogo(fileList[0] as File)}
      />
      {customLogoFile && (
        <HyperlinkField
          name={'customLogoLink'}
          label={'Custom Logo Link'}
          value={customLogoLink || ''}
          onChange={setCustomLogoLink}
        />
      )}
      {customLogoFile && (
        <AccurityButton
          onClick={handleRemoveCustomLogo}
          variant="outlined"
          fullWidth={true}
          aria-label="removeCustomLogo"
          className="customLogoSettings__remove"
        >
          Remove Current Custom Logo File
        </AccurityButton>
      )}

    </CollapsingGroup>
  )
};

export default CustomLogoSettingGroup;
