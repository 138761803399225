import { AccurityCoreEntity, AccurityReference } from '../../../../common/types/accurityTypes';
import { WithChildrenCounts } from '../../../../common/childrenCountField/types';
import { CustomPropertyValues } from '../../../../common/customProperties/customProperty/types/customPropertyTypes';
import { EnumOption, Tag } from 'ts-components';
import { CoreEntityState } from '../../../../common/redux/types';
import { ProcessStepReference } from '../../step/types/processStepTypes';
import { HasEntity } from '../../../entity/types/entityTypes';
import { HasEntityAttributeDefinition } from '../../../attribute/types/attributeTypes';


export const PROCESS_MAPPING_TYPE = 'process-mapping';
export const PROCESS_MAPPING_LABEL = 'Process Mapping';
export const PROCESS_MAPPING_PLURAL_LABEL = 'Process Mappings';
export const PROCESS_MAPPING_ICON = 'processMapping';

export const PROCESS_MAPPING_PROCESS_MAPPING_TYPE_FIELD = 'processMappingType';
export const PROCESS_MAPPING_PROCESS_MAPPING_TYPE_LABEL = 'Mapping Type';
export const PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_FIELD = 'processMappingBasedOnType';
export const PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_LABEL = 'Based on';

export const PROCESS_MAPPING_BASE_LABEL = 'Base';
export const PROCESS_MAPPING_BASE_FIELD = 'base';

export const PROCESS_MAPPING_TARGET_LABEL = 'Target';
export const PROCESS_MAPPING_TARGET_FIELD = 'target';

export const PROCESS_MAPPING_TARGET_ENTITIES_FIELD = 'processMappingTargetEntities';
export const PROCESS_MAPPING_TARGET_ENTITIES_LABEL = 'Target Entity';
export const PROCESS_MAPPING_TARGET_ENTITIES_PLURAL_LABEL = 'Target Entities';

export const PROCESS_MAPPING_TARGET_ATTRIBUTES_FIELD = 'processMappingTargetAttributes';
export const PROCESS_MAPPING_TARGET_ATTRIBUTES_LABEL = 'Target Attribute';
export const PROCESS_MAPPING_TARGET_ATTRIBUTES_PLURAL_LABEL = 'Target Attributes';

export interface ProcessMappingState extends CoreEntityState<ProcessMapping> {
}

export enum ProcessMappingType {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export const ProcessMappingTypeOptions: EnumOption[] = [
  { value: ProcessMappingType.INPUT, label: 'Input' },
  { value: ProcessMappingType.OUTPUT, label: 'Output' },
];

export enum ProcessMappingBasedOnType {
  PROCESS = 'PROCESS',
  PROCESS_STEP = 'PROCESS_STEP'
}

export const ProcessMappingBasedOnTypeOptions: EnumOption[] = [
  { value: ProcessMappingBasedOnType.PROCESS, label: 'Process' },
  { value: ProcessMappingBasedOnType.PROCESS_STEP, label: 'Process Step' },
];

export interface ProcessMapping extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  processMappingType?: ProcessMappingType;
  processMappingBasedOnType?: ProcessMappingBasedOnType;
  process?: AccurityReference;
  processStep?: ProcessStepReference;
  processMappingTargetEntities: ProcessMappingTargetEntity[];
  processMappingTargetAttributes: ProcessMappingTargetAttribute[];
  targetBusinessTerms: AccurityReference[];
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}

export interface ProcessMappingTargetEntity extends HasEntity {
  id: number;
}

export interface ProcessMappingTargetAttribute extends HasEntityAttributeDefinition {
  id: number;
}