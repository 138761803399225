import { TECHNICAL_DATA_MAPPING_TYPE, TechnicalDataMappingState } from '../types/technicalDataMappingTypes';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { createSlice } from '@reduxjs/toolkit';
import { commonReducers } from '../../../common/redux/commonReducers';

const initialState: TechnicalDataMappingState = getInitialCoreEntityState(TECHNICAL_DATA_MAPPING_TYPE);

const technicalDataMappingSlice = createSlice({
  name: TECHNICAL_DATA_MAPPING_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default technicalDataMappingSlice.reducer;
