import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Calls the callback function every time the dependency changes, however skips (first) initial render
 * @param callback Imperative function that can return a cleanup function
 * @param deps effect will activate when the values in the list change.
 */
export const useDidUpdateEffect = (callback: EffectCallback, deps: DependencyList) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (!didMountRef.current) {
      // initial render
      didMountRef.current = true;
    } else {
      // subsequent render
      return callback();
    }
  }, deps);
};
