import { doFetch } from '../rest/FetchService';
import { getGlobalSearchEndpoint } from '../rest/endpoints';
import { showErrorMessageFromResponse } from '../userMessages/actions';
import { ListSearchResult } from '../list/types/types';
import { TaggedEntity } from 'ts-components';

export type  AccurityGlobalSearchResponse = ListSearchResult<TaggedEntity>;

export const requestGlobalSearch = (searchTerm: string, startFrom = 0, maxResults = 20): Promise<AccurityGlobalSearchResponse> => {
  const requestBody = { searchTerm, startFrom, maxResults };
  return doFetch(getGlobalSearchEndpoint(), 'POST', JSON.stringify(requestBody))
    .catch(showErrorMessageFromResponse);
};
