import { dismissNotification, pushNotification } from '../notificationCenter/notificationCenterSlice';
import { DataManagementNotificationCenterNotification, NotificationCenterNotificationType } from '../notificationCenter/types';
import rootStore from '../redux/rootStore';
import { DataManagementMessageType } from '../dataTransfer/types';


export const pushDataManagementCenterNotification = (dataManagementData: { id: string, date: number, dataManagementMessageType: DataManagementMessageType }) => {
  const cannotDismiss = dataManagementData.dataManagementMessageType === DataManagementMessageType.IMPORT_SAMPLE_DATA_STARTED
    || dataManagementData.dataManagementMessageType === DataManagementMessageType.REMOVE_ALL_DATA_STARTED;

  const dataManagementNotification: DataManagementNotificationCenterNotification = {
    type: NotificationCenterNotificationType.DATA_MANAGEMENT,
    ...dataManagementData,
    cannotDismiss
  };
  rootStore.dispatch(dismissNotification(dataManagementData.id));
  rootStore.dispatch(pushNotification(dataManagementNotification));
};
