import { addCoreEntityToStore, setIsListLoading, setListSearchResultInStore } from '../../redux/commonReducers';
import { getCustomSearchEndpoint, getSearchEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { AccuritySearch } from '../../types/accurityTypes';
import rootStore from '../../redux/rootStore';
import { ListSearchResult } from '../types/types';
import { useCallback } from 'react';
import { CustomEndpointRequestType } from '../../rest/types';

export type ListSearchSteps = {
  setLoadingTrue: () => void,
  getListSearchEndpoint: (objectType: string, customEndpoint?: string) => string,
  doListSearchRequest: (searchEndpoint: string, search: AccuritySearch) => Promise<ListSearchResult>,
  addCoreEntitiesToStore: (searchResult: ListSearchResult, fetchedAt: number) => void,
  addListSearchResultToStore: (objectType: string, searchResult: ListSearchResult, fetchedAt: number) => void,
  setLoadingFalse: () => void,
};

export type CustomListSearchSteps = Partial<ListSearchSteps>;

export type ListSearchAction = (search: AccuritySearch) => void;

const getDefaultListSearchSteps = (): ListSearchSteps => {
  return {
    setLoadingTrue: () => {
      rootStore.dispatch(setIsListLoading(true));
    },

    getListSearchEndpoint: (objectType: string, customEndpoint?: string) => {
      if (customEndpoint) {
        return getCustomSearchEndpoint(customEndpoint) + '/' + CustomEndpointRequestType.LIST;
      } else {
        return getSearchEndpoint(objectType);
      }
    },

    doListSearchRequest: (searchEndpoint: string, search: AccuritySearch) => {
      const method = 'POST';
      const searchBody = JSON.stringify(search);

      return doFetch(searchEndpoint, method, searchBody);
    },

    addCoreEntitiesToStore: (searchResult: ListSearchResult, fetchedAt: number) => {
      rootStore.dispatch(addCoreEntityToStore({ coreEntity: searchResult.rows, fetchedAt: fetchedAt }));
    },

    addListSearchResultToStore: (objectType: string, searchResult: ListSearchResult, fetchedAt: number) => {
      const ids = searchResult.rows.map(row => row.id);
      rootStore.dispatch(setListSearchResultInStore({
        objectType: objectType,
        ids: ids,
        size: searchResult.size,
        existingObjects: searchResult.existingObjects,
        maximumObjects: searchResult.maximumObjects,
        additionalMaximumObjects: searchResult.additionalMaximumObjects,
        additionalExistingObjects: searchResult.additionalExistingObjects,
        fetchedAt: fetchedAt,
      }));
    },

    setLoadingFalse: () => {
      rootStore.dispatch(setIsListLoading(false));
    },
  }
};

export const useListSearchAction = (objectType: string,
                                    customEndpoint?: string,
                                    customListSearchSteps?: CustomListSearchSteps
): ListSearchAction => {
  return useCallback((search: AccuritySearch) => {
    const listSearchSteps: ListSearchSteps = {
      ...getDefaultListSearchSteps(),
      ...customListSearchSteps
    };

    listSearchSteps.setLoadingTrue();
    const listSearchEndpoint = listSearchSteps.getListSearchEndpoint(objectType, customEndpoint);
    listSearchSteps.doListSearchRequest(listSearchEndpoint, search)
      .then(listSearchResult => {
        const fetchedAt = Date.now();
        listSearchSteps.addCoreEntitiesToStore(listSearchResult, fetchedAt);
        listSearchSteps.addListSearchResultToStore(objectType, listSearchResult, fetchedAt);
      })
      .finally(listSearchSteps.setLoadingFalse);
  }, [objectType, customEndpoint, customListSearchSteps]);
};
