import { Column, NumericFormat } from 'ts-components';

import { columnFactory, commonColumns } from '../../../list/commonColumns/columnDefinitions';
import { CUSTOM_PROPERTY_PLURAL_LABEL } from '../../customProperty/types/customPropertyTypes';
import { useIsOracleDb } from '../../../appSettings/hooks/environmentInfoHooks';
import { COLLAPSED_BY_DEFAULT_LABEL } from '../types/customPropertyGroupTypes';
import { useValidColumnsForFeatures } from '../../../list/listUtils';

export const useCustomPropertyGroupListColumns = () => {

  const isOracle = useIsOracleDb();

  const customPropertyGroupListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ hidden: true, sortable: !isOracle }),
    columnFactory.createBooleanColumn('collapsedByDefault', COLLAPSED_BY_DEFAULT_LABEL),
    columnFactory.createNumberColumn('groupOrder', 'Order', { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('childrenCounts.customPropertiesCount', CUSTOM_PROPERTY_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumnsForFeatures(customPropertyGroupListColumns);
};
