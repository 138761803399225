import * as React from 'react';
import { ProfileCard, ProfilingDataType, Thermometer, ThermometerValueLabelPair } from 'ts-components';
import { GeneralData, ResultData } from '../types/types';

export const handleGeneralData = ({ generalData, type }: ResultData) => {

  return (
    <ProfileCard title={'Count'} key={'count'}>
      {generalData.distinctCount < 3
        ? handleSimpleGeneralData(generalData, type)
        : handleAdvancedGeneralData(generalData)}
    </ProfileCard>
  );
};

const createValueLabelPair = (value: number, label: string, severity?: 'primary' | 'secondary'): ThermometerValueLabelPair => {
  return {
    value,
    label,
    severity
  };
};

const handleSimpleGeneralData = ({ distinctCount, nullCount, totalCount, occuringValues }: GeneralData, type: ProfilingDataType) => {

  // the simple count chart is displayed when distinct count is < 3, so we expecting that there are occuringValues
  if (!occuringValues) {
    return null;
  }

  const valueLabelPairs: ThermometerValueLabelPair[] = occuringValues.map((segmentData, idx) =>
    createValueLabelPair(segmentData.count, segmentData.value, idx === 0 ? 'primary' : 'secondary')
  );

  valueLabelPairs.push(createValueLabelPair(nullCount, 'Null'));

  return (
    <Thermometer
      valueLabelPairs={valueLabelPairs}
      dataType={type}
    />
  )
};

const handleAdvancedGeneralData = ({ totalCount, nullCount, distinctCount, uniqueCount }: GeneralData) => {

  const nonNullCount = totalCount - nullCount;

  return (
    <>
      <Thermometer
        valueLabelPairs={[
          createValueLabelPair(nonNullCount, 'Non-null', 'primary'),
          createValueLabelPair(nullCount, 'Null', 'secondary')
        ]}
      />
      <Thermometer
        valueLabelPairs={[
          createValueLabelPair(distinctCount, 'Distinct', 'primary'),
          createValueLabelPair(nonNullCount - distinctCount, 'Duplicate', 'secondary')
        ]}
      />
      <Thermometer
        valueLabelPairs={[
          createValueLabelPair(uniqueCount, 'Unique', 'primary'),
          createValueLabelPair(distinctCount - uniqueCount, 'Non-unique', 'secondary')
        ]}
      />
    </>
  );
};
