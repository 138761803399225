import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramState } from '../types/processDiagramTypes';

const initialState: ProcessDiagramState = getInitialCoreEntityState(PROCESS_DIAGRAM_TYPE);

const processDiagramSlice = createSlice({
  name: PROCESS_DIAGRAM_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default processDiagramSlice.reducer;
