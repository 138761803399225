import { addCoreEntityToStore, setIsDetailLoading } from '../../redux/commonReducers';
import { getSaveEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../userMessages/actions';
import { setRedUnderlinesOnDetail } from './detailActions';
import { getFinishedSavingMessage } from '../../userMessages/commonMessages';
import { AccurityFetchError } from '../../rest/types';
import { AccurityNavigationController, useAccurityNavigation } from '../../navigation/hooks';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import rootStore from '../../redux/rootStore';
import { SetFieldError } from '../types/types';

export type SaveSteps<T extends AccurityCoreEntity> = {
  setLoadingTrue: () => void,
  getSaveEndpoint: (coreEntity: T) => string,
  getSaveBody: (coreEntity: T) => string | FormData,
  doSaveRequest: (coreEntity: T, endpoint: string, saveBody: string | FormData) => Promise<T>,
  addSavedEntityToStore: (savedCoreEntity: T) => void,
  refreshDetail: (savedCoreEntity: T, navigationController: AccurityNavigationController) => void,
  showSaveSuccessSnackbar: (savedCoreEntity: T, previousCoreEntity: T) => void,
  handleSaveError: (saveError: AccurityFetchError, setFieldError: SetFieldError) => void,
  setLoadingFalse: () => void,
};

export type CustomSaveSteps<T extends AccurityCoreEntity> = Partial<SaveSteps<T>>;

export type SaveAction<T extends AccurityCoreEntity> = (entity: T, setFieldError: (field: string, errorMessage: string) => void) => void;

export const getDefaultSaveSteps = <T extends AccurityCoreEntity>(): SaveSteps<T> => ({

  setLoadingTrue: () => {
    rootStore.dispatch(setIsDetailLoading(true));
  },

  getSaveEndpoint: (coreEntity: T) => {
    return getSaveEndpoint(coreEntity.objectType);
  },

  getSaveBody: (coreEntity: T) => {
    return JSON.stringify(coreEntity);
  },

  doSaveRequest: (coreEntity: T, saveUrl: string, saveBody: string | FormData) => {
    const method = coreEntity.id === undefined ? 'POST' : 'PUT';

    return doFetch(saveUrl, method, saveBody, {doNotFillContentType: saveBody instanceof FormData})
      .catch(showErrorMessageFromResponse);
  },

  addSavedEntityToStore: (savedCoreEntity: T) => {
    const fetchedAt = Date.now();
    rootStore.dispatch(addCoreEntityToStore({ coreEntity: savedCoreEntity, fetchedAt: fetchedAt }));
  },

  refreshDetail: (savedCoreEntity: T, navigationController: AccurityNavigationController) => {
    navigationController.openDetailWithObject(savedCoreEntity.objectType, savedCoreEntity.id, true);
  },

  showSaveSuccessSnackbar: (savedCoreEntity: T, previousCoreEntity: T) => {
    showSnackbarMessage(getFinishedSavingMessage(savedCoreEntity));
  },

  handleSaveError: (saveError: AccurityFetchError, setFieldError: SetFieldError) => {
    setRedUnderlinesOnDetail(saveError, setFieldError);
  },

  setLoadingFalse: () => {
    rootStore.dispatch(setIsDetailLoading(false));
  },
});

export const useSaveAction = <T extends AccurityCoreEntity>(customSaveSteps?: CustomSaveSteps<T>): SaveAction<T> => {
  const navigationController = useAccurityNavigation();

  return (coreEntity: T, setFieldError: SetFieldError) => {
    const saveSteps: SaveSteps<T> = {
      ...getDefaultSaveSteps(),
      ...customSaveSteps,
    };

    saveSteps.setLoadingTrue();
    const saveEndpoint = saveSteps.getSaveEndpoint(coreEntity);
    const saveBody = saveSteps.getSaveBody(coreEntity);
    saveSteps.doSaveRequest(coreEntity, saveEndpoint, saveBody)
      .then(savedCoreEntity => {
        saveSteps.addSavedEntityToStore(savedCoreEntity);
        saveSteps.refreshDetail(savedCoreEntity, navigationController);
        saveSteps.showSaveSuccessSnackbar(savedCoreEntity, coreEntity);
      })
      .catch((saveError: AccurityFetchError) => saveSteps.handleSaveError(saveError, setFieldError))
      .finally(saveSteps.setLoadingFalse);
  };
};
