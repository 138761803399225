import React from 'react';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity, EmailNotificationActionType, EmailNotificationState } from '../../types/accurityTypes';
import EmailNotificationToggle from '../commonFields/EmailNotificationToggle';
import { useSelectCoreEntity } from '../../redux/commonSelectors';
import { useDispatch } from 'react-redux';
import { setNotificationForCoreEntity } from '../../redux/commonReducers';

const EmailNotificationToggleWithFormik = () => {
  const dispatch = useDispatch();
  const formikContext = useFormikContext<AccurityCoreEntity>();
  const { objectType, name, id } = { ...formikContext?.values };
  const isActive = useSelectCoreEntity(objectType, id)?.activeNotifications.OBJECT_UPDATE || false;

    // TODO: formik should be undefined feature/26001
  if (formikContext?.status.isVersionBrowser || !formikContext?.status.isUpdateDetail) {
    return null;
  }

  if (!id) {
    return null;
  }

  const setNotificationEnabled = (newValue: boolean) => {
    dispatch(setNotificationForCoreEntity({
      notificationType: EmailNotificationActionType.OBJECT_UPDATE,
      enabled: newValue,
      objectType,
      id,
    }));
  };

  const payload: EmailNotificationState = {
    actionType: EmailNotificationActionType.OBJECT_UPDATE,
    referencedObjectType: objectType,
    referencedObjectName: name,
    referencedObjectId: id
  };

  return (
    <EmailNotificationToggle
      value={isActive}
      payload={payload}
      onToggleSuccess={setNotificationEnabled}
    />
  )
};

export default EmailNotificationToggleWithFormik;
