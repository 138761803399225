import { ExportColumnDefinition, ExportSearch, ListExportData } from './types';
import { ExportColumnsType, ExportRowsType, ListExportSettings } from 'ts-components';
import { AccurityFilter, AccurityPinFilter } from '../types/accurityTypes';
import { AccurityFilterType, Column } from 'ts-components';
import { columnFilterDataToAccurityFilter } from '../list/listUtils';


export const createExportSearch = (listExportSettings: ListExportSettings, listExportData: ListExportData, timeZone?: string): ExportSearch => {

  const exportStartFrom = listExportSettings.rows === ExportRowsType.VISIBLE_ONLY ? listExportData.offset : 0;
  const exportMaxResults = getExportMaxResults(listExportSettings, listExportData);
  const exportFilters = getExportFilters(listExportData.columns, listExportData.pinFilters);
  const exportTemplate = listExportSettings.columns === ExportColumnsType.REQUIRED_FOR_IMPORT;
  const exportColumns = getExportColumns(listExportData.columns, listExportSettings.columns === ExportColumnsType.VISIBLE_ONLY);

  return {
    delimiter: listExportSettings.delimiter,
    objectType: listExportData.objectType,
    filename: listExportData.filename,
    sort: listExportData.sort,
    startFrom: exportStartFrom,
    maxResults: exportMaxResults,
    filters: exportFilters,
    columns: exportColumns,
    exportTemplate: exportTemplate,
    timeZone: timeZone
  };

};

const getExportFilters = (columns: Column[], pinFilters: AccurityPinFilter[]): AccurityFilter[] => {
  const exportFilters: AccurityFilter[] = [];

  // Pin Filters from list
  pinFilters.forEach(pinFilter => {
      if (!pinFilter.disabled) {
        exportFilters.push({
          type: pinFilter.type === AccurityFilterType.CHILDREN_COUNT ? AccurityFilterType.CHILDREN_COUNT : AccurityFilterType.REFERENCE,
          value: pinFilter.value,
          property: pinFilter.property,
          relationType: pinFilter.relationType,
        });
      }
    }
  );

  // Column Filters from list
  columns.forEach(column => {
    if (column.filter) {
      exportFilters.push(columnFilterDataToAccurityFilter({
        filter: column.filter,
        filterType: column.filterType,
        searchProperty: column.searchProperty,
        filterValueProperty: column.filterValueProperty,
        columnName: column.name
      }))
    }
  });

  return exportFilters;
};

const columnToExportColumnDefinition = (column: Column): ExportColumnDefinition => ({
  type: column.type,
  label: column.label,
  property: column.name,
});

const getExportColumns = (columns: Column[], onlyVisible: boolean): ExportColumnDefinition[] => {
  if (onlyVisible) {
    columns = columns.filter(column => !column.hidden);
  }
  return columns.map(columnToExportColumnDefinition);
};

const getExportMaxResults = (listExportSettings: ListExportSettings, listExportData: ListExportData) => {
  if (listExportSettings.rows === ExportRowsType.HEADERS_ONLY) {
    return 0;
  } else if (listExportSettings.rows === ExportRowsType.VISIBLE_ONLY) {
    return listExportData.limit;
  } else {
    return listExportData.size;
  }
};
