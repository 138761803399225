import { FormikProps } from 'formik';
import { TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_FIELDS_FIELD, TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD, TechnicalDataMapping } from '../types/technicalDataMappingTypes';
import { AccurityReference } from '../../../common/types/accurityTypes';

export const getTechnicalDataMappingCountLabel = (collection: any[], label: string, pluralLabel: string) => {

  if (collection && collection.length) {
    const length = collection.length;

    if (length !== 1) {
      return length + ' ' + pluralLabel;
    } else {
      return length + ' ' + label;
    }
  } else {
    return '0 ' + pluralLabel;
  }
};

export const getTechnicalDataMappingDataStructureDataFieldJoinCountLabel = (collection: any[], label: string, pluralLabel: string) => {

  if (collection && collection.length) {
    return '1 ' + label;
  } else {
    return '0 ' + pluralLabel;
  }
};

export const resetValuesOnBaseDataSetChange = (formik: FormikProps<TechnicalDataMapping>, isDataSetToBeCleared: boolean) => {
  formik.setValues({
    ...formik.values,
    baseDataSet: isDataSetToBeCleared ? undefined : formik.values.baseDataSet,
    technicalDataMappingBaseDataStructureDataFields: [],
    technicalDataMappingBaseSelectionItems: [],
    technicalDataMappingBaseJoinDataStructureDataField: undefined,
  });

  formik.setErrors({
    ...formik.errors,
    baseDataSet: isDataSetToBeCleared ? undefined : formik.errors.baseDataSet,
    technicalDataMappingBaseDataStructureDataFields: [],
    technicalDataMappingBaseSelectionItems: [],
    technicalDataMappingBaseJoinDataStructureDataField: undefined,
  });
};

export const resetValuesOnTargetDataSetChange = (formik: FormikProps<TechnicalDataMapping>, isDataSetToBeCleared: boolean) => {
  formik.setValues({
    ...formik.values,
    targetDataSet: isDataSetToBeCleared ? undefined : formik.values.targetDataSet,
    technicalDataMappingTargetDataStructureDataFields: [],
    technicalDataMappingTargetSelectionItems: [],
    technicalDataMappingTargetJoinDataStructureDataField: undefined
  });

  formik.setErrors({
    ...formik.errors,
    targetDataSet: isDataSetToBeCleared ? undefined : formik.errors.targetDataSet,
    technicalDataMappingTargetDataStructureDataFields: [],
    technicalDataMappingTargetSelectionItems: [],
    technicalDataMappingTargetJoinDataStructureDataField: undefined
  });
};


export const clearBaseDataFieldJoinsOnDataStructureRemove = (formik: FormikProps<TechnicalDataMapping>,
                                                             fieldName: string,
                                                             dataStructure?: AccurityReference | null,
                                                             oppositeDataStructureId?: string) => {
  if (dataStructure) {
    formik.setFieldValue(fieldName, dataStructure);
  } else {
    formik.setFieldValue(fieldName, null);
  }

  if (!dataStructure || !oppositeDataStructureId) {
    formik.setFieldValue(TECHNICAL_DATA_MAPPING_BASE_JOIN_DATA_FIELDS_FIELD, []);
  }
};

export const clearTargetDataFieldJoinsOnDataStructureRemove = (formik: FormikProps<TechnicalDataMapping>,
                                                               fieldName: string,
                                                               dataStructure?: AccurityReference | null,
                                                               oppositeDataStructureId?: string) => {
  if (dataStructure) {
    formik.setFieldValue(fieldName, dataStructure);
  } else {
    formik.setFieldValue(fieldName, null);
  }

  if (!dataStructure || !oppositeDataStructureId) {
    formik.setFieldValue(TECHNICAL_DATA_MAPPING_TARGET_JOIN_DATA_FIELDS_FIELD, []);
  }
};
