import React, { useCallback, useState } from 'react';
import { AccurityButton } from 'ts-components';
import { FormikProps, useFormikContext, } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { AccurityVersionBrowserModal } from './VersionBrowserModal';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';

interface VersionBrowserFieldProps<T> {
  getDetailFields: (formik: FormikProps<T>) => JSX.Element;
}

export const VersionBrowserButton = ({ getDetailFields }: VersionBrowserFieldProps<any>) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const formik = useFormikContext<AccurityCoreEntity>();
  const entity = formik.values;
  const hasFeature = useHasFeature();

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  // do not show Version Browser Button when there is only one available revision or user has not access to this feature
  if (entity.version <= 1 || !hasFeature(Feature.VERSION_BROWSER)) {
    return null;
  }

  return (
    <>
      <AccurityButton
        className="versionBrowserField__versionBrowserButton"
        onClick={() => setIsModalOpen(true)}
        disabled={formik.status.isEntityInDetailDeleted}
        variant="outlined"
        fullWidth
      >
        Compare versions
      </AccurityButton>
      {isModalOpen && <AccurityVersionBrowserModal
        getDetailFields={getDetailFields}
        closeModal={handleCloseModal}
      />}
    </>
  )
};
