import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption, Tag } from 'ts-components';

export const COMPOSITE_TYPE_TYPE = 'composite-type';
export const COMPOSITE_TYPE_LABEL = 'Composite Type';
export const COMPOSITE_TYPE_PLURAL_LABEL = 'Composite Types';
export const COMPOSITE_TYPE_ICON = 'compositeType';

export const COMPOSITE_TYPE_COMPONENTS_LABEL = 'Components';
export const COMPOSITE_TYPE_COMPONENT_TYPE_ICON = 'compositeTypeComponent';
export const COMPOSITE_TYPE_COMPONENT_TYPE_LABEL = 'Component Type';
export const COMPOSITE_TYPE_COMPONENT_TYPE_PLURAL_LABEL = 'Component Types';
export const COMPOSITE_TYPE_COMPONENT_NAME_LABEL = 'Component Name';
export const COMPOSITE_TYPE_COMPONENT_NAME_PLURAL_LABEL = 'Component Names';

export const TARGET_ENTITY_LABEL = 'Target Entity';
export const TARGET_ENTITY_PLURAL_LABEL = 'Target Entities';

export interface CompositeTypeState extends CoreEntityState<CompositeType> {
}

export interface CompositeType extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  components: CompositeTypeComponent[];
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}

export enum ComponentType {
  VALUE = 'VALUE',
  REFERENCE = 'REFERENCE'
}

export const ComponentTypeOptions: EnumOption[] = [
  { value: ComponentType.VALUE, label: 'Value' },
  { value: ComponentType.REFERENCE, label: 'Reference' },
];

export interface CompositeTypeComponent extends AccurityCoreEntity {
  componentType?: ComponentType;
  valueType?: AccurityReference;
  targetEntity?: AccurityReference;
}

