import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity } from '../../../common/types/accurityTypes';

export const STATUS_TYPE = 'status';
export const STATUS_LABEL = 'Status Value';
export const STATUS_PLURAL_LABEL = 'Status Values';
export const STATUS_ICON = 'status';

export interface StatusState extends CoreEntityState<Status> {
}

export interface Status extends AccurityCoreEntity {
  defaultIndicator: boolean;
}
