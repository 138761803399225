import { useDispatch, useSelector } from 'react-redux';
import { setProductTermsAcceptedInStore } from '../../appSettings/redux/appSettingsSlice';
import { AppSettingsState } from '../../appSettings/types/types';
import { acceptProductTerms } from '../rest/rest';
import { showSnackbarMessage } from '../../userMessages/actions';

export const useProductTermsSettings = () => {
  return useSelector((state: { appSettings: AppSettingsState }) => {
    const { productTermsAccepted, productTermsCheckEnabled, productTermsLink } = state.appSettings;
    return { productTermsAccepted, productTermsCheckEnabled, productTermsLink };
  });
};

export const useAcceptProductTerms = () => {
  const dispatch = useDispatch();
  return () => {
    acceptProductTerms()
      .then(() => {
        dispatch(setProductTermsAcceptedInStore());
      })
      .catch(() => showSnackbarMessage('An error occurred during accepting Product Terms'));
  }
};