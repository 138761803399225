import i18n from 'i18next';
import { DATA_STRUCTURE_TYPE } from '../../businessGlossary/dataStructure/types/dataStructureTypes';
import { PROCESS_TYPE } from '../../businessGlossary/process/types/processTypes';
import { CUSTOM_PROPERTY_TYPE } from '../customProperties/customProperty/types/customPropertyTypes';

export const getBulkNotificationsUpdatedMessage = (updatedObjectsCnt: number, objectType: string) =>
  i18n.t('0030', { count: updatedObjectsCnt, objectType });

export const getBulkRemovalInProgressMessage = (objectType: string) =>
  i18n.t('0120', { objectType });

export const getBulkRemovalFinishedMessage = (objectType: string) =>
  i18n.t('0121', { objectType });

export const getBulkUpdateInProgressMessage = (objectType: string) =>
  i18n.t('0122', { objectType });

export const getBulkUpdateFinishedMessage = (objectType: string) =>
  i18n.t('0123', { objectType });

export const getRemovalDialogMessage = (listType: string, selectedRowsCnt: number) => {
  const genericRemovalMsg = i18n.t('bulkOperations.genericRemoval', { count: selectedRowsCnt, listType });
  switch (listType) {
    case DATA_STRUCTURE_TYPE:
      return genericRemovalMsg + '\n\n' +
        i18n.t('bulkOperations.dependencyRemoval', { listType, count: selectedRowsCnt, dependencies: 'Data Fields' });
    case PROCESS_TYPE:
      return genericRemovalMsg + '\n\n' +
        i18n.t('bulkOperations.dependencyRemoval', { listType, count: selectedRowsCnt, dependencies: 'Process Steps' });
    case CUSTOM_PROPERTY_TYPE:
      return genericRemovalMsg + '\n\n' +
        i18n.t('bulkOperations.dependencyRemoval', { listType, count: selectedRowsCnt, dependencies: 'Custom Properties', ending: ' from existing objects' });
    default:
      return genericRemovalMsg;
  }
};
