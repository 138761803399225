import React, { useCallback } from 'react';
import { ReferenceField, Tooltip, VersionBrowserColoring } from 'ts-components';
import { AccurityFilter, AccurityReference } from '../../types/accurityTypes';
import { defaultTestDraggedItem, defaultTestDroppedItem, DropFilterParams } from '../utils/dragAndDrop';
import { useAutocompleteBackendSearchLogic } from '../utils/autocompleteBackendSearchLogic';
import { doReferenceFieldSearch } from '../utils/search';
import { useReferenceFieldIconAction } from './useIconAction';
import { DEFAULT_REFERENCE_FILTER_PROPERTY } from '../types';
import { useEffectOnMountOnly } from '../../hooks/useEffectOnMountOnly';

interface ReferenceFieldProps<T extends AccurityReference> {
  name: string
  label: string
  objectType: string;
  customEndpoint?: string;
  filterProperty?: string;
  dragDropRowProperty?: string;
  value?: T
  setValue: (newValue: T | undefined) => void;
  icon?: string
  readOnly?: boolean;
  errorMessage?: string;
  additionalFilters?: AccurityFilter[];
  hideRemoveButton?: boolean;
  coloring?: VersionBrowserColoring;
  maxResult?: number;
  initialFilterText?: string;
  inherited?: boolean;
  inverted?: boolean;
  tooltip?: Tooltip;
  showDefaultTooltip?: boolean;
}

const AccurityReferenceField = <T extends AccurityReference = AccurityReference>({
                                                                                   name,
                                                                                   label,
                                                                                   objectType,
                                                                                   customEndpoint,
                                                                                   filterProperty = DEFAULT_REFERENCE_FILTER_PROPERTY,
                                                                                   dragDropRowProperty,
                                                                                   value,
                                                                                   setValue,
                                                                                   icon,
                                                                                   readOnly,
                                                                                   errorMessage,
                                                                                   additionalFilters = [],
                                                                                   hideRemoveButton,
                                                                                   coloring,
                                                                                   maxResult,
                                                                                   initialFilterText,
                                                                                   inherited,
                                                                                   inverted,
                                                                                   showDefaultTooltip,
                                                                                   tooltip
                                                                                 }: ReferenceFieldProps<T>) => {
  const doBackendSearch = useCallback((searchText: string) =>
      doReferenceFieldSearch<T>(objectType, filterProperty, searchText, additionalFilters, maxResult, customEndpoint)
    , [objectType, filterProperty, additionalFilters, maxResult, customEndpoint]);

  const textValue = value ? value.name : initialFilterText;

  const {
    onChange,
    onSelect,
    filterText,
    resetFilterText,
    doSearch,
    isLoading,
    items,
  } = useAutocompleteBackendSearchLogic<T>(textValue, setValue, doBackendSearch);

  useEffectOnMountOnly(() => {
    // If some initialFilterText already do search and if there is exact match of this on name, auto-select it
    if (initialFilterText) {
      doSearch(initialFilterText)?.then((response) => {
        const exactMatch = response.rows.find(reference => reference.name === initialFilterText);
        if (exactMatch) {
          onSelect(exactMatch);
        }
      })
    }
  });

  const iconAction = useReferenceFieldIconAction<T>(objectType, value, readOnly, customEndpoint, additionalFilters);

  const dropFilterParams: DropFilterParams = {
    objectType,
    filterProperty,
    dragDropRowProperty,
    additionalFilters,
    customEndpoint
  }

  return (
    <ReferenceField
      value={filterText}
      menuItems={items}
      menuItemRenderer={(item: any) => item.name}
      menuItemIsLoading={isLoading}
      onChange={onChange}
      onSelect={onSelect}
      onFocus={doSearch}
      iconAction={iconAction}
      onOutsideClick={resetFilterText}
      name={name}
      description={value?.description}
      label={label}
      icon={icon}
      disabled={readOnly}
      errorMessage={errorMessage}
      testDraggedItem={defaultTestDraggedItem(objectType)}
      testDroppedItem={defaultTestDroppedItem(dropFilterParams)}
      hideRemoveButton={hideRemoveButton}
      coloring={coloring}
      inherited={inherited}
      inverted={inverted}
      tooltip={tooltip}
      showDefaultTooltip={showDefaultTooltip}
    />
  );
};

export default AccurityReferenceField;
