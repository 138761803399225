import React from 'react';
import { InteractiveNode, NodeColor, NodeMenuItem, NodeMenuItemPopover, NodeVariant } from 'ts-components';
import { useAccurityNavigation } from '../../../../common/navigation/hooks';
import DisplayAttributesSelector from './DisplayAttributesSelector';

export interface BusinessModelDiagramNodeProps {
  id: string;
  objectId: string;
  objectType: string;
  label: string;
  nodeType: 'ATTRIBUTE' | 'ENTITY' | 'RELATIONSHIP_ENTITY';
  hideNode: (nodeId: string) => void;
  displayNeighboursOfNode: (nodeId: string) => void;
  updateAttributes: (nodeId: string, attributeIds: string[]) => void;
}

const BusinessModelDiagramNode = ({ id, objectId, objectType, label, nodeType, hideNode, displayNeighboursOfNode, updateAttributes }: BusinessModelDiagramNodeProps) => {
  const { openDetailWithObject, getCurrentNavigation } = useAccurityNavigation();
  const { detailType, detailId } = getCurrentNavigation();
  const isDetailOpen = detailType === objectType && String(detailId) === String(objectId);

  const commonProps = {
    key: id,
    id: id,
    label: label,
    withShadow: isDetailOpen,
  }

  const commonMenuItems = [
    <NodeMenuItem
      key={'open-detail'}
      label={'Display Detail'}
      onClick={() => {
        openDetailWithObject(objectType, objectId);
      }}
    />,
    <NodeMenuItem
      key={'hide-node'}
      label={'Hide Node'}
      onClick={() => {
        hideNode(id);
      }}
    />,
  ];

  const displayNeighboursMenuItem = (
    <NodeMenuItem
      key={'display-neighbours'}
      label={'Display Neighbors'}
      onClick={() => {
        displayNeighboursOfNode(id);
      }}
    />
  );

  const displayAttributesMenuItem = (
    <NodeMenuItemPopover
      key={'displayAttributes'}
      label={'Display Attributes'}
    >
      <DisplayAttributesSelector
        entityId={objectId}
        nodeId={id}
        updateAttributes={(attributeIds: string[]) => updateAttributes(id, attributeIds)}
      />
    </NodeMenuItemPopover>
  );

  switch (nodeType) {
    case 'ENTITY':
      return (
        <InteractiveNode
          {...commonProps}
          variant={NodeVariant.RECT}
          fillColor={NodeColor.LIGHT_BLUE}
          borderColor={NodeColor.LIGHT_BLUE}
          textColor={NodeColor.TEXT_LIGHT}
          menuItems={[...commonMenuItems, displayNeighboursMenuItem, displayAttributesMenuItem]}
        />
      );
    case 'RELATIONSHIP_ENTITY':
      return (
        <InteractiveNode
          {...commonProps}
          variant={NodeVariant.DIAMOND}
          fillColor={NodeColor.GREY}
          borderColor={NodeColor.GREY}
          textColor={NodeColor.TEXT_DARK}
          menuItems={[...commonMenuItems, displayNeighboursMenuItem, displayAttributesMenuItem]}
        />
      );
    case 'ATTRIBUTE':
      return (
        <InteractiveNode
          {...commonProps}
          variant={NodeVariant.ROUND}
          fillColor={NodeColor.WHITE_BLUE}
          borderColor={NodeColor.WHITE_BLUE}
          textColor={NodeColor.TEXT_DARK}
          menuItems={commonMenuItems}
        />
      );
    default:
      throw new Error('No Node defined for Node type: ' + String(nodeType));
  }
}

export default BusinessModelDiagramNode;
