import { useState } from 'react';
import { AccurityCoreEntity, EmailNotificationActionType } from '../../types/accurityTypes';
import { toggleNotificationsRequest } from '../rest/commentNotificationApi';
import { useSelectCoreEntity } from '../../redux/commonSelectors';
import { useDispatch } from 'react-redux';
import { setNotificationForCoreEntity } from '../../redux/commonReducers';

export const useCommentNotification = (entity: AccurityCoreEntity) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isActive = useSelectCoreEntity(entity.objectType, entity.id)?.activeNotifications?.COMMENT_UPDATE || false;

  const doRequest = () => {
    setIsLoading(true);

    toggleNotificationsRequest(entity, isActive)
      .then(() => {
        dispatch(setNotificationForCoreEntity({
          enabled: !isActive,
          objectType: entity.objectType,
          id: entity.id,
          notificationType: EmailNotificationActionType.COMMENT_UPDATE
        }));
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isNotificationActive: isActive,
    isNotificationLoading: isLoading,
    toggleNotification: doRequest,
  };
};
