import { CrudCommand, WebsocketMessageType } from '../../types';
import { evictAllTemplatesFromStore, invalidateCoreEntityInStore, } from '../../../redux/commonReducers';
import rootStore from '../../../redux/rootStore';
import { STATUS_TYPE } from '../../../../businessGlossary/status/types/statusTypes';
import { CUSTOM_PROPERTY_TYPE } from '../../../customProperties/customProperty/types/customPropertyTypes';
import { CUSTOM_PROPERTY_GROUP_TYPE } from '../../../customProperties/customPropertyGroup/types/customPropertyGroupTypes';
import { doBackgroundFetchByObjectTypeIdAndAddToStore } from '../../../redux/commonActions';
import { COMMENT_TYPE } from '../../../comments/types/types';
import { WebSocketCrudObserver } from './websocketCrudObserver';
import { reloadCustomLogo } from '../../../appSettings/actions/applicationSettingsActions';
import { APPLICATION_SETTING } from '../../../appSettings/types/types';

// objects which are not stored in redux store but we want to react to notifications via WebSocketCrudObserver
const NON_REDUX_OBJECTS = [COMMENT_TYPE];

export const handleCrudCommand = (crudCommand: CrudCommand) => {
  let shouldRefreshList = false;
  const objectTypesInStore = Object.keys(rootStore.getState());

  if (NON_REDUX_OBJECTS.includes(crudCommand.objectType)) {
    WebSocketCrudObserver.getInstance().notify(crudCommand);
  } else if(objectTypesInStore.includes(crudCommand.objectType)) {
    handleReduxStoreForAllObjectTypes(crudCommand);
    shouldRefreshList = true;
  }

  if (crudCommand.objectType === CUSTOM_PROPERTY_TYPE) {
    handleForCustomProperty(crudCommand);
  } else if (crudCommand.objectType === CUSTOM_PROPERTY_GROUP_TYPE) {
    handleForCustomPropertyGroup(crudCommand);
  } else if (crudCommand.objectType === STATUS_TYPE) {
    handleForStatus();
  } else if (crudCommand.objectType === APPLICATION_SETTING) {
    handleForApplicationSettings();
  }
  return shouldRefreshList;
};

const handleReduxStoreForAllObjectTypes = (crudCommand: CrudCommand) => {
  rootStore.dispatch(invalidateCoreEntityInStore({
    objectType: crudCommand.objectType,
    id: crudCommand.id,
    wasDeleted: crudCommand.command === WebsocketMessageType.DeleteCommand,
    versionInBackend: crudCommand.modifiedDate,
  }));
};

const handleForApplicationSettings = () => {
  rootStore.dispatch(reloadCustomLogo() as any);
}

const handleForStatus = () => {
  rootStore.dispatch(evictAllTemplatesFromStore());
};

const handleForCustomProperty = (crudCommand: CrudCommand) => {
  fetchCoreEntityFromCommand(crudCommand);
};

const handleForCustomPropertyGroup = (crudCommand: CrudCommand) => {
  fetchCoreEntityFromCommand(crudCommand);
};

const fetchCoreEntityFromCommand = (crudCommand: CrudCommand) => {
  if (crudCommand.command !== WebsocketMessageType.DeleteCommand) {
    doBackgroundFetchByObjectTypeIdAndAddToStore(crudCommand.objectType, crudCommand.id);
  }
};
