import * as React from 'react';
import { FrequencyTable, FrequencyTableValueLabelPair, ProfileCard, ProfilingDataType } from 'ts-components';
import { DataPair, ResultData } from '../types/types';

export const handleFrequencyData = ({ frequencyData, dateFrequencyData, generalData, type }: ResultData) => {

  // don't show Frequency Table for boolean column or there is not enough distinct values
  if (type === ProfilingDataType.BOOLEAN_TYPE || generalData.totalCount == generalData.distinctCount) {
    return null;
  }

  let mostOccurringPairs: FrequencyTableValueLabelPair[] = [];
  let leastOccurringPairs: FrequencyTableValueLabelPair[] = [];

  if (frequencyData.mostOccurring && frequencyData.leastOccurring) {
    mostOccurringPairs = frequencyData.mostOccurring.map(createValueLabelPair);
    leastOccurringPairs = frequencyData.leastOccurring.map(createValueLabelPair);
  }

  if (mostOccurringPairs.length === 0 && leastOccurringPairs.length === 0) {
    return null;
  }

  return (
    <ProfileCard title={'Frequency'} key={'frequency'}>
      <FrequencyTable
        totalCount={generalData.totalCount}
        distinctCount={generalData.distinctCount}
        mostOccurring={mostOccurringPairs}
        leastOccurring={leastOccurringPairs}
        dataType={type}
      />
    </ProfileCard>
  );
};

const createValueLabelPair = (pair: DataPair): FrequencyTableValueLabelPair => {
  return {
    value: parseInt(pair.count as any),
    label: pair.value
  };
};
