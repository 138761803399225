import React, { useCallback } from 'react';
import { AutocompleteSelector } from 'ts-components';
import { AccurityFilter, AccurityReference } from '../../types/accurityTypes';
import { doReferenceFieldSearch } from '../utils/search';
import { useAutocompleteBackendSearchLogic } from '../utils/autocompleteBackendSearchLogic';

interface AccurityReferenceMultiSelectorProps {
  name: string;
  label: string;
  value: AccurityReference[];
  setValue: (newValue: AccurityReference[]) => void;
  groupBy?: (value: AccurityReference) => string;
  objectType: string;
  additionalFilters?: AccurityFilter[];
  focusOnMount?: boolean;
  disabled?: boolean;
  allowSelectAll?: boolean;
  customEndpoint?: string;
}

const AccurityReferenceMultiSelector = ({
                                          name,
                                          label,
                                          value,
                                          setValue,
                                          groupBy,
                                          objectType,
                                          additionalFilters,
                                          focusOnMount,
                                          disabled,
                                          allowSelectAll,
                                          customEndpoint
                                        }: AccurityReferenceMultiSelectorProps) => {

  const doBackendSearch = useCallback((searchText: string) => {
    return doReferenceFieldSearch(objectType, 'name', searchText, additionalFilters, allowSelectAll ? 500 : 20, customEndpoint);
  }, [objectType, additionalFilters, allowSelectAll, customEndpoint]);

  const {
    filterText,
    onChange,
    items,
    isLoading,
    totalItems
  } = useAutocompleteBackendSearchLogic<AccurityReference>('', () => null, doBackendSearch);

  const isOptionEqualToValue = (option: AccurityReference, value: AccurityReference) => {
    return option.objectType === value.objectType && option.id === value.id;
  };

  return (
    <AutocompleteSelector<AccurityReference>
      label={label}
      filterText={filterText}
      setFilterText={onChange}
      options={items}
      selectedOptions={value}
      setSelectedOptions={setValue}
      isOptionEqualToValue={isOptionEqualToValue}
      focusOnMount={focusOnMount}
      useTagChips={objectType === 'tag'}
      isLoading={isLoading}
      disabled={disabled}
      totalOptions={totalItems}
      allowSelectAll={allowSelectAll}
      groupBy={groupBy}
    />
  );
};

export default AccurityReferenceMultiSelector;
