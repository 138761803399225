import { FormikProps } from 'formik';
import React from 'react';
import CustomPropertyFieldsContainer, { CustomPropertyFieldsContainerProps } from '../../customProperties/CustomPropertyFieldsContainer';

export const createFields = (
  formik: FormikProps<any>,
  identifier: 'old' | 'new',
  getDetailFields: (formik: FormikProps<any>, ...rest: any[]) => JSX.Element
) => {
  return React.Children.map(getDetailFields(formik).props.children, (child, index) => {
    if (!child) {
      return null;
    }

    const additionalProps = {};
    if (CustomPropertyFieldsContainer === child.type) {
      (additionalProps as CustomPropertyFieldsContainerProps).expandedByDefaultOverwrite = true;
    }

    return (
      <div className={`versionBrowserField-${identifier}`} data-index={index}>
        <child.type {...child.props} {...additionalProps}/>
      </div>
    );
  })
};

const COLLAPSING_GROUP_IN_VERSION_BROWSER = '.versionBrowserComparison .collapsingGroup';

const findCollapsingGroupFieldNames = () => {
  const collapsingGroups = document.querySelectorAll(COLLAPSING_GROUP_IN_VERSION_BROWSER);
  const fieldNames: string[] = [];
  collapsingGroups.forEach(cpg => {
    const selector = cpg.getAttribute('data-field-name');
    if (!!selector && !fieldNames.includes(selector)) {
      fieldNames.push(selector)
    }
  });
  return fieldNames;
};

export const setFixedHeightsOnAllCollapsingGroups = () => {
  findCollapsingGroupFieldNames().forEach(fieldName => setFixedHeightsOnCollapsingGroups(fieldName));
};

const setFixedHeightsOnCollapsingGroups = (fieldName: string) => {

  let maxHeight = 0;
  const selector = `${COLLAPSING_GROUP_IN_VERSION_BROWSER}[data-field-name="${fieldName}"]`;
  const collapsingGroups = document.querySelectorAll<HTMLElement>(selector);
  collapsingGroups.forEach(cpg => {
    maxHeight = Math.max(maxHeight, cpg.getBoundingClientRect().height);
  });

  collapsingGroups.forEach(cpg => {
    cpg.style.height = `${maxHeight}px`;
    cpg.style.boxSizing = 'border-box';
  });
};

export const removeFixedHeightsOnAllCollapsingGroups = () => {
  findCollapsingGroupFieldNames().forEach(fieldName => removeFixedHeightsOnCollapsingGroups(fieldName));
};

const removeFixedHeightsOnCollapsingGroups = (fieldName: string) => {
  const selector = `${COLLAPSING_GROUP_IN_VERSION_BROWSER}[data-field-name="${fieldName}"]`;
  const collapsingGroups = document.querySelectorAll<HTMLElement>(selector);
  collapsingGroups.forEach(cpg => {
    cpg.style.height = ``;
  });
};
