import React from 'react';
import { FormikProps } from 'formik';
import { DetailInlineGroup } from 'ts-components';
import { AccurityCoreEntity, AccurityFilter, AccuritySearch, AccuritySortType } from '../../types/accurityTypes';
import { CustomProperty } from '../customProperty/types/customPropertyTypes';
import { assembleAttributeNames, getSupportiveCustomPropertyValueFieldLabel } from '../customPropertyUtils';
import { createReferenceFieldFilter, createUniqueWithinCollectionFilters } from '../../referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../detail/formik/ReferenceFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../businessGlossary/entity/types/entityTypes';
import { doFetch } from '../../rest/FetchService';
import { getSearchEndpoint } from '../../rest/endpoints';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL, ATTRIBUTE_TYPE } from '../../../businessGlossary/attribute/types/attributeTypes';
import { ListSearchResult } from '../../list/types/types';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../../businessGlossary/attributeDefinition/types/attributeDefinitionTypes';

interface AttributeCustomPropertyValueFieldProps {
  formik: FormikProps<AccurityCoreEntity>;
  fieldName: string;
  customProperty: CustomProperty;
  collectionValues?: any[]
  inherited?: boolean;
}

const AttributeCustomPropertyValueField = ({ formik, fieldName, customProperty, collectionValues, inherited }: AttributeCustomPropertyValueFieldProps) => {

  const entityReferenceFieldProps = formik.getFieldProps(fieldName + '.entityReference').value;
  const attributeDefinitionReferenceFieldProps = formik.getFieldProps(fieldName + '.attributeDefinitionReference').value;

  const showEntityTooltip = !!entityReferenceFieldProps;
  const showAttributeDefinitionTooltip = !!attributeDefinitionReferenceFieldProps;

  const entityId = entityReferenceFieldProps?.id;
  const attributeDefinitionId = attributeDefinitionReferenceFieldProps?.id;

  const attributes = assembleAttributeNames(collectionValues);

  let entityFilters = createUniqueWithinCollectionFilters('name', attributes);
  let attributeDefinitionFilters = createUniqueWithinCollectionFilters('name', attributes);

  if (!attributeDefinitionId) {
    entityFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    entityFilters.push(
      createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
    );
  }

  if (!entityId) {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('entity.id', entityId)
    );
  }

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}>
        <ReferenceFieldWithFormik
          name={fieldName + '.entityReference'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, ENTITY_LABEL)}
          objectType={ENTITY_TYPE}
          customEndpoint={ENTITY_TYPE + '/business-model-mapping-entity-selection'}
          additionalFilters={entityFilters}
          icon={ENTITY_ICON}
          showDefaultTooltip={showEntityTooltip}
          setValue={(entity) => {
            if (entity) {
              formik.setFieldValue(fieldName + '.entityReference', entity);
              if (attributeDefinitionReferenceFieldProps) {
                let attributeFilter: AccurityFilter[] = [];
                attributeFilter.push(
                  createReferenceFieldFilter('entity.id', entity?.id),
                  createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
                );

                const search: AccuritySearch = {
                  filters: attributeFilter,
                  sort: { property: 'name', type: AccuritySortType.ASCENDING },
                  maxResults: 20,
                  startFrom: 0
                };

                doFetch(getSearchEndpoint(ATTRIBUTE_TYPE), 'POST', JSON.stringify(search))
                  .then((result: ListSearchResult) => formik.setFieldValue(fieldName + '.reference', result?.rows[0]))
              }
            } else {
              formik.setFieldValue(fieldName + '.entityReference', null);
              formik.setFieldValue(fieldName + '.reference', null);
            }
          }}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
        <ReferenceFieldWithFormik
          name={fieldName + '.attributeDefinitionReference'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, ATTRIBUTE_LABEL)}
          objectType={ATTRIBUTE_DEFINITION_TYPE}
          customEndpoint={ATTRIBUTE_DEFINITION_TYPE + '/business-model-mapping-attribute-definition-selection'}
          additionalFilters={attributeDefinitionFilters}
          icon={ATTRIBUTE_ICON}
          showDefaultTooltip={showAttributeDefinitionTooltip}
          setValue={(attributeDefinition) => {
            if (attributeDefinition) {
              formik.setFieldValue(fieldName + '.attributeDefinitionReference', attributeDefinition);
              if (entityReferenceFieldProps) {
                let attributeFilter: AccurityFilter[] = [];
                attributeFilter.push(
                  createReferenceFieldFilter('entity.id', entityId),
                  createReferenceFieldFilter('attributeDefinition.id', attributeDefinition?.id)
                );

                const search: AccuritySearch = {
                  filters: attributeFilter,
                  sort: { property: 'name', type: AccuritySortType.ASCENDING },
                  maxResults: 20,
                  startFrom: 0
                };

                doFetch(getSearchEndpoint(ATTRIBUTE_TYPE), 'POST', JSON.stringify(search))
                  .then((result: ListSearchResult) => formik.setFieldValue(fieldName + '.reference', result?.rows[0]))
              }
            } else {
              formik.setFieldValue(fieldName + '.attributeDefinitionReference', null);
              formik.setFieldValue(fieldName + '.reference', null);
            }
          }}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
      </DetailInlineGroup>
    </>
  )
};


export default AttributeCustomPropertyValueField;