import { AccurityCoreEntity, AccurityReference } from '../../../../common/types/accurityTypes';
import { WithChildrenCounts } from '../../../../common/childrenCountField/types';
import { CustomPropertyValues } from '../../../../common/customProperties/customProperty/types/customPropertyTypes';
import { Tag } from 'ts-components';
import { CoreEntityState } from '../../../../common/redux/types';

export const PROCESS_STEP_TYPE = 'process-step';
export const PROCESS_STEP_LABEL = 'Process Step';
export const PROCESS_STEP_PLURAL_LABEL = 'Process Steps';
export const PROCESS_STEP_ICON = 'processStep';

export const PROCESS_STEP_FIELD = 'processStep';

export interface ProcessStepState extends CoreEntityState<ProcessStep> {
}

export interface ProcessStep extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  process?: AccurityReference;
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}

export interface ProcessStepReference extends AccurityReference {
  process: AccurityReference;
}