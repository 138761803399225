import { AccurityCoreEntity } from '../../types/accurityTypes';
import { VersionBrowserColoring, VersionBrowserRevision } from 'ts-components';
import { useFormik, } from 'formik';
import { useEffect, useState } from 'react';
import { getChanges } from '../rest/versionBrowserApi';
import { VersionBrowserChangesResponse } from '../types/types';
import { CustomProperty } from '../../customProperties/customProperty/types/customPropertyTypes';
import { CustomPropertyGroup } from '../../customProperties/customPropertyGroup/types/customPropertyGroupTypes';

export const useLoadChanges = (entity: AccurityCoreEntity, olderRevision?: VersionBrowserRevision, newerRevision?: VersionBrowserRevision, newerRevisionIsCurrent?: boolean) => {

  const [isLoadingChanges, setIsLoadingChanges] = useState(true);
  const [response, setResponse] = useState<VersionBrowserChangesResponse>();

  useEffect(() => {
    if (!!olderRevision && !!newerRevision) {
      setIsLoadingChanges(true);
      getChanges(entity.objectType, entity.id, olderRevision.revision, newerRevision.revision, newerRevisionIsCurrent).then(result => {
        setResponse(result);
        setIsLoadingChanges(false);
      })
    }
    // eslint-disable-next-line
  }, [entity, olderRevision, newerRevision]);

  return {
    isLoadingChanges,
    formikOldObject: useVersionBrowserFormik(response?.oldObject, response?.oldObjectCustomProperties, response?.oldObjectCustomPropertyGroups, response?.oldObjectColoring),
    formikNewObject: useVersionBrowserFormik(response?.newObject, response?.newObjectCustomProperties, response?.newObjectCustomPropertyGroups, response?.newObjectColoring),
  }

};

export const useVersionBrowserFormik = (entity?: any, customProperties?: CustomProperty[], customPropertyGroups?: CustomPropertyGroup[], coloring?: { [key: string]: VersionBrowserColoring | VersionBrowserColoring[] }) => {
  const formik = useFormik({
    ...formikConfig,
    initialValues: entity,
    initialStatus: {
      ...formikConfig.initialStatus,
      customProperties: [customProperties, customPropertyGroups],
      revisionColoring: coloring,
    }
  });

  // return only formik which has object set
  return formik.values ? formik : undefined;
}

// fake permission object that simulates read-only access rights
const permissions = {
  hasCreatePermission: false,
  hasReadPermission: true,
  hasUpdatePermission: false,
  hasDeletePermission: false,
};

const formikConfig = {
  enableReinitialize: true,
  initialValues: undefined as any,
  initialStatus: {
    permissions: permissions,
    isUpdateDetail: true,
    isEntityInDetailDeleted: false,
    isEntityInDetailOutdated: false,
    revisionColoring: undefined,
    isVersionBrowser: true
  },
  onSubmit: () => {
  },
};
