import { Tag } from 'ts-components';
import { doFetch } from '../rest/FetchService';
import { getReferenceSearchEndpoint } from '../rest/endpoints';
import { showErrorMessageFromResponse } from '../userMessages/actions';
import { createReferenceFieldFilter } from '../referenceField/utils/filters';
import { createReferenceFieldSearch } from '../referenceField/utils/search';

export interface AccurityTagSearchResult {
  rows: Tag[];
  size: number;
}

export const requestTagFieldSearch = (searchText: string, usedTags: Tag[] = []): Promise<AccurityTagSearchResult> => {

  const textFilter = createReferenceFieldFilter('name', searchText);
  const excludeUsedTagsFilters = usedTags.map(tag =>
    createReferenceFieldFilter('name', `-=${tag.name}`)
  );
  const referenceSearch = createReferenceFieldSearch('name', [textFilter, ...excludeUsedTagsFilters]);
  const requestBody = JSON.stringify(referenceSearch);

  return doFetch(getReferenceSearchEndpoint('tag'), 'POST', requestBody)
    .catch(showErrorMessageFromResponse);
};
