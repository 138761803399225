import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';

export const BUSINESS_MODEL_DIAGRAM_TYPE = 'business-model-diagram';
export const BUSINESS_MODEL_DIAGRAM_LABEL = 'Business Model Diagram';
export const BUSINESS_MODEL_DIAGRAM_PLURAL_LABEL = 'Business Model Diagrams';
export const BUSINESS_MODEL_DIAGRAM_ICON = 'businessModelDiagram';

export interface BusinessModelDiagramState extends CoreEntityState<BusinessModelDiagram> {
}

export interface BusinessModelDiagram extends AccurityCoreEntity {
  status?: AccurityReference;
  customPropertyValues: CustomPropertyValues;
}

export interface Graph {
  id?: string;
  diagramType: string;
  diagramId: string;
  content: string;
  x: number;
  y: number;
  zoom: number;
  source: {
    tags: AccurityReference[];
    sourceReferences?: AccurityReference[];
  }
}

export interface GraphContent {
  nodes: any;
  hiddenNodes: any;
  links: any;
  secondaryLinks: any;
}
