import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../main.less';
import BusinessGlossaryMainScreen from './mainScreen/BusinessGlossaryMainScreen';
import BusinessGlossaryWelcomeScreen from './welcomeScreen/BusinessGlossaryWelcomeScreen';
import PostAuthenticationRoute from '../common/userSettings/PostAuthenticationRoute';
import { routes } from './navigation/routes';
import BusinessGlossaryNavigation from './navigation/BusinessGlossaryNavigation';
import { NotFoundPage } from './404/compoennts/NotFoundPage';

const AccurityRoutes = () => {

  return (
    <BrowserRouter>
      <Switch>
        <PostAuthenticationRoute
          path={[routes.welcomeScreenPath, ...routes.mainScreenPaths]}
          exact={true}
        >
          <BusinessGlossaryNavigation>
            <Route
              path={routes.welcomeScreenPath}
              exact={true}
              component={BusinessGlossaryWelcomeScreen}
            />
            <Route
              path={routes.mainScreenPaths}
              exact={true}
              component={BusinessGlossaryMainScreen}
            />
          </BusinessGlossaryNavigation>
        </PostAuthenticationRoute>
        <PostAuthenticationRoute
          component={NotFoundPage}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default AccurityRoutes;
