import React from 'react';
import NameField from '../commonFields/NameField';
import { useField, useFormikContext } from 'formik';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface NameFieldWithFormikProps {
  label: string;
  readOnly?: boolean;
  dontShowError?: boolean;
}

const NameFieldWithFormik = ({ label, readOnly, dontShowError }: NameFieldWithFormikProps) => {
  const [field, meta] = useField('name');
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring('name');

  const isReadOnly =
    readOnly ||
    formikContext?.status?.settingsDetail !== undefined ||
    formikContext?.status?.isDisabled ||
    !formikContext.status.permissions.hasUpdatePermission;

  return (
    <NameField
      label={label}
      value={field.value}
      onChange={(value: string) => formikContext.setFieldValue('name', value)}
      errorMessage={dontShowError ? undefined : meta.error}
      readOnly={isReadOnly}
      inverted={formikContext?.status?.settingsDetail !== undefined}
      coloring={coloring}
    />
  );
};

export default NameFieldWithFormik;
