import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import {
  ATTRIBUTE_DEFINITION_TYPE_LABEL,
  AttributeDefinitionTypeOptions,
  COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL_SHORT,
  COMPONENT_OF_LABEL
} from '../types/attributeDefinitionTypes';
import { BASED_ON_LABEL } from '../../entity/types/entityTypes';
import { COMPOSITE_TYPE_LABEL, TARGET_ENTITY_LABEL } from '../../compositeType/types/compositeTypeTypes';
import { VALUE_TYPE_LABEL } from '../../valueType/types/valueTypeTypes';
import { ATTRIBUTE_PLURAL_LABEL } from '../../attribute/types/attributeTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { Feature } from '../../../common/userSettings/features/features';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useAttributeDefinitionListColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const attributeDefinitionListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    columnFactory.createTextColumn('description.plainTextValue', 'Description', { sortable: !isOracle, hidden: true }),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [columnFactory.createTextColumn('basedOnBusinessTerm.name', BASED_ON_LABEL, { hidden: true })] : []),
    columnFactory.createTextColumn('parent.name', COMPONENT_OF_LABEL, { hidden: true }),
    columnFactory.createEnumColumn('attributeDefinitionType', ATTRIBUTE_DEFINITION_TYPE_LABEL, AttributeDefinitionTypeOptions),
    columnFactory.createTextColumn('compositeType.name', COMPOSITE_TYPE_LABEL),
    columnFactory.createTextColumn('targetEntity.name', TARGET_ENTITY_LABEL),
    columnFactory.createTextColumn('valueType.name', VALUE_TYPE_LABEL),
    commonColumns.tags(navigationController),
    ...(hasFeature(Feature.ATTRIBUTES) ?
      [columnFactory.createNumberColumn('childrenCounts.attributesCount', ATTRIBUTE_PLURAL_LABEL, {}, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ATTRIBUTE_DEFINITIONS) ?
      [columnFactory.createNumberColumn('childrenCounts.attributeDefinitionsCount', COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL_SHORT, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(attributeDefinitionListColumns, GlossaryCustomPropertyObjectType.ATTRIBUTE_DEFINITION);

};
