import React, { useEffect, useRef } from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import { CollectionField, RowItem, Tooltip } from 'ts-components';

interface CollectionFieldWithFormikProps {
  name: string;
  title?: string;
  icon?: string;
  renderRow: (elementName: string, index: number, collectionValues: any[]) => React.ReactNode;
  initialRowValue?: any;
  rowValidation?: (value: any) => boolean;
  rowDisableRemove?: (value: any) => boolean;
  testDraggedItem?: (item?: RowItem) => boolean;
  testDroppedItem?: (item?: RowItem) => Promise<RowItem>;
  onValidDrop?: (addRow: (newValue: any) => void, item?: RowItem) => void;
  onChange?: (newValues: any[]) => void;
  disabled?: boolean;
  maxRows?: number;
  expandedByDefault?: boolean;
  inherited?: boolean;
  insideCollapsingGroup?: boolean;
  tooltip?: Tooltip;
  enableSuggestions?: boolean;
  suggestions?: React.ReactNode[];
  findSuggestions?: () => void;
  virtualizeListFromCount?: number;
}

const CollectionFieldWithFormik = ({
                                     name,
                                     title,
                                     icon,
                                     renderRow,
                                     initialRowValue,
                                     rowValidation,
                                     rowDisableRemove,
                                     testDraggedItem,
                                     testDroppedItem,
                                     onValidDrop,
                                     disabled,
                                     maxRows,
                                     expandedByDefault,
                                     inherited,
                                     insideCollapsingGroup,
                                     tooltip,
                                     enableSuggestions,
                                     findSuggestions,
                                     suggestions,
                                     onChange,
                                     virtualizeListFromCount,
                                   }: CollectionFieldWithFormikProps) => {
  const [field] = useField<any[]>(name);
  const formikContext = useFormikContext();

  const collectionLength = useRef(field.value?.length);
  useEffect(() => {
    if (field.value?.length !== collectionLength.current) {
      collectionLength.current = field.value?.length;
      onChange?.(field.value);
    }
  }, [field.value, onChange])

  return (
    <FieldArray
      name={name}
      render={arrayHelpers =>
        <CollectionField
          name={name}
          title={title}
          icon={icon}
          values={field.value}
          addRow={arrayHelpers.push}
          removeRowAtIndex={arrayHelpers.remove}
          renderRow={renderRow}
          initialRowValue={initialRowValue}
          rowValidation={rowValidation}
          rowDisableRemove={rowDisableRemove}
          testDraggedItem={testDraggedItem}
          testDroppedItem={testDroppedItem}
          onValidDrop={onValidDrop}
          disabled={disabled || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
          maxRows={maxRows}
          expandedByDefault={expandedByDefault}
          inherited={inherited}
          insideCollapsingGroup={insideCollapsingGroup}
          tooltip={tooltip}
          suggestions={suggestions}
          findSuggestions={findSuggestions}
          enableSuggestions={enableSuggestions}
          virtualizeListFromCount={virtualizeListFromCount}
        />
      }
    />
  );
};

export default CollectionFieldWithFormik;
