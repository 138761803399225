export const BrowserExtensionUtils = {
  notifyExtension: (messageType: string, payload?: any) => {
    // browser extension listens to messages in src/content/appTunnel.ts
    // bgAppTunnel is automatically loaded on BG pages if browser extension is installed
    window.postMessage(JSON.stringify({ type: messageType, payload: payload }), window.origin);
  },

  notifyUserHasBeenLoggedOut: () => {
    BrowserExtensionUtils.notifyExtension('ACCURITY_USER_HAS_BEEN_LOGGED_OUT_IN_APP');
  }
};
