import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption, Tag } from 'ts-components';
import { DataSetReference } from '../../dataSet/types/dataSetTypes';
import { DataCatalogSyncResult, HasSyncResult } from '../../../common/dataCatalogSync/types';
import { RelatedBusinessTerm } from '../../businessTerm/types/businessTermTypes';

export const DATA_STRUCTURE_TYPE = 'data-structure';
export const DATA_STRUCTURE_LABEL = 'Data Structure';
export const DATA_STRUCTURE_PLURAL_LABEL = 'Data Structures';
export const DATA_STRUCTURE_ICON = 'dataStructure';
export const DATA_STRUCTURE_FIELD = 'dataStructure';
export const DATA_STRUCTURE_TYPE_FIELD = 'dataStructureType';
export const DATA_STRUCTURE_TYPE_FIELD_LABEL = 'Data Structure Type';
export const DATA_STRUCTURE_COMMENT_FIELD = 'dataStructureComment';
export const DATA_STRUCTURE_COMMENT_FIELD_LABEL = 'Database Comment';

export enum DataStructureType {
  LOGICAL = 'LOGICAL',
  TABLE = 'TABLE',
  VIEW = 'VIEW',
  COLLECTION = 'COLLECTION',
  SHEET = 'SHEET'
}

export const dataStructureTypeOptions: EnumOption[] = [
  { value: DataStructureType.LOGICAL, label: 'Logical' },
  { value: DataStructureType.TABLE, label: 'Table' },
  { value: DataStructureType.VIEW, label: 'View' },
  { value: DataStructureType.COLLECTION, label: 'Collection' },
  { value: DataStructureType.SHEET, label: 'Sheet' },
];

export interface DataStructureState extends CoreEntityState<DataStructure> {
}

export interface DataStructure extends AccurityCoreEntity, WithChildrenCounts, HasSyncResult {
  physicalName?: string;
  status?: AccurityReference;
  dataSet: DataSetReference;
  dataStructureType: DataStructureType;
  relatedBusinessTerms: RelatedBusinessTerm[];
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
  syncResult?: DataCatalogSyncResult
}

export interface DataStructureReference extends AccurityReference {
  dataSet: DataSetReference;
}

export const isDataStructure = (object: unknown): object is DataStructure => {
  return (object as DataStructure).objectType === DATA_STRUCTURE_TYPE;
};