import { AccurityCoreEntity } from '../../types/accurityTypes';
import { VersionBrowserRevision } from 'ts-components';
import { useEffect, useState } from 'react';
import { getRevisions } from '../rest/versionBrowserApi';

export const useLoadRevisions = (entity: AccurityCoreEntity, closeModal: () => void) => {

  const [allRevisions, setAllRevisions] = useState<VersionBrowserRevision[]>([]);
  const [olderRevision, setOlderRevision] = useState<VersionBrowserRevision>();
  const [newerRevision, setNewerRevision] = useState<VersionBrowserRevision>();
  const [isLoadingRevisions, setIsLoadingRevisions] = useState(true);

  // load all revisions for version selector
  useEffect(() => {
    setIsLoadingRevisions(true);
    getRevisions(entity.objectType, entity.id).then(response => {
      const revisions = response.rows;
      setAllRevisions(revisions);
      if (revisions.length >= 2) {
        // initially compare newest revision and revision before
        setOlderRevision(revisions[revisions.length - 2]);
        setNewerRevision(revisions[revisions.length - 1]);
      } else {
        // close modal if we don't have at least 2 revision to compare
        closeModal();
      }
    }).finally(() => setIsLoadingRevisions(false));
  }, [entity.id, entity.objectType, closeModal]);

  return {
    allRevisions,
    olderRevision,
    newerRevision,
    isLoadingRevisions,
    setRevisions: (oldRevision: VersionBrowserRevision, newRevision: VersionBrowserRevision) => {
      setOlderRevision(oldRevision);
      setNewerRevision(newRevision);
    }
  };
};
