import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyInheritanceObject, CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { AttributeDefinition, AttributeDefinitionType } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { Entity } from '../../entity/types/entityTypes';
import { Requirement } from '../../requirement/types/requirementTypes';
import { EnumOption, Tag } from 'ts-components';
import { RichTextEditorValue } from 'ts-components';

export const ATTRIBUTE_TYPE = 'attribute';
export const ATTRIBUTE_LABEL = 'Attribute';
export const ATTRIBUTE_PLURAL_LABEL = 'Attributes';
export const ATTRIBUTE_ICON = 'attribute';

export const REFERENCE_ATTRIBUTE_LABEL = 'Reference Attribute';
export const REFERENCE_ATTRIBUTE_PLURAL_LABEL = 'Reference Attributes';

export const ATTRIBUTE_DESCRIPTION_LABEL = 'Attribute Description';

export const CALCULATION_RULE_LABEL = 'Calculation Rule';
export const CALCULATION_RULE_PLURAL_LABEL = 'Calculation Rules';

export const INHERITED_ATTRIBUTE_LABEL = 'Inherited Attribute';

export const INHERITED_CHILD_ATTRIBUTE_LABEL = 'Inherited Child Attribute';
export const INHERITED_CHILD_ATTRIBUTE_PLURAL_LABEL = 'Inherited Child Attributes';
export const CHILD_ATTRIBUTE_PLURAL_LABEL = 'Child Attributes';

export const CONFIRM_ENTITY_OR_ATT_DEF_CHANGE_SETTINGS_DETAIL = 'CONFIRM_ENTITY_OR_ATT_DEF_CHANGE';

export interface AttributeState extends CoreEntityState<Attribute> {
}

export enum AttributePropertyInheritanceType {
  NONE = 'NONE',
  FULLY = 'FULLY',
  PARTIALLY = 'PARTIALLY'
}

export const AttributePropertyInheritanceTypeOptions: EnumOption[] = [
  { value: AttributePropertyInheritanceType.NONE, label: 'None' },
  { value: AttributePropertyInheritanceType.FULLY, label: 'Fully' },
  { value: AttributePropertyInheritanceType.PARTIALLY, label: 'Partially' },
];

export interface Attribute extends AccurityCoreEntity, WithChildrenCounts {
  calculationRule?: RichTextEditorValue;
  calculationRuleInherited?: boolean;
  descriptionInherited?: boolean;
  entity?: Entity;
  attributeDefinition?: AttributeDefinition;
  attributeDefinitionType?: AttributeDefinitionType;
  status?: AccurityReference;
  statusInherited?: boolean;
  propertyInheritanceType?: AttributePropertyInheritanceType;
  parent?: AccurityReference;
  requirements?: Requirement[];
  requirementsInherited?: boolean;
  tags: Tag[];
  tagsInherited?: boolean;
  inherited?: boolean;
  customPropertyValues: CustomPropertyValues;
  customPropertyValuesInheritance: CustomPropertyInheritanceObject;
  keepInheritedAttributes?: boolean;
}

export interface HasEntityAttributeDefinition {
  entity?: AccurityReference;
  attributeDefinition?: AccurityReference;
  attribute?: AccurityReference;
}