import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import { TECHNICAL_DATA_MAPPING_ICON, TECHNICAL_DATA_MAPPING_PLURAL_LABEL, TECHNICAL_DATA_MAPPING_TYPE } from '../types/technicalDataMappingTypes';
import {
  useTechnicalDataMappingBaseJoinsListColumns,
  useTechnicalDataMappingBaseListColumns,
  useTechnicalDataMappingBaseSelectionsListColumns,
  useTechnicalDataMappingEndListColumns,
  useTechnicalDataMappingStartListColumns,
  useTechnicalDataMappingTargetJoinsListColumns,
  useTechnicalDataMappingTargetListColumns,
  useTechnicalDataMappingTargetSelectionsListColumns
} from './technicalDataMappingListColumns';
import { AccuritySortType } from '../../../common/types/accurityTypes';

const TechnicalDataMappingList = () => {
  const technicalDataMappingColumns = [
    ...useTechnicalDataMappingStartListColumns(),
    ...useTechnicalDataMappingBaseListColumns(),
    ...useTechnicalDataMappingBaseSelectionsListColumns(),
    ...useTechnicalDataMappingBaseJoinsListColumns(),
    ...useTechnicalDataMappingTargetListColumns(),
    ...useTechnicalDataMappingTargetSelectionsListColumns(),
    ...useTechnicalDataMappingTargetJoinsListColumns(),
    ...useTechnicalDataMappingEndListColumns()
  ];

  const technicalDataMappingListSettings = getDefaultListSettings(
    TECHNICAL_DATA_MAPPING_TYPE,
    technicalDataMappingColumns,
    { property: 'id', type: AccuritySortType.ASCENDING }
  );

  return (
    <AccurityListContainer
      objectType={TECHNICAL_DATA_MAPPING_TYPE}
      title={TECHNICAL_DATA_MAPPING_PLURAL_LABEL}
      icon={TECHNICAL_DATA_MAPPING_ICON}
      columns={technicalDataMappingColumns}
      defaultListSettings={technicalDataMappingListSettings}
      partialImportOnly={false}
    />
  );
};

export default TechnicalDataMappingList;
