import { useSelector } from 'react-redux';
import { CoreEntityState, RootState } from '../../redux/types';
import { DATA_SET_TYPE, DataSet, DataSetType } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import { useCallback, useRef, useState } from 'react';
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect';

export const NEW_OBJECT_TIME_THRESHOLD = 10_000; // 10s

export const useDataSetSyncDialogState = (dataSetId?: string) => {
  const dataSetsById = useSelector((state: RootState) => state[DATA_SET_TYPE].byId as CoreEntityState<DataSet>['byId']);
  const dataSet = dataSetId ? dataSetsById[dataSetId] : undefined;
  const [isOpen, setOpenInternal] = useState(false);
  const wasAutoOpenChecked = useRef(false);

  const setOpen = useCallback((newState: boolean) => {
    // we allow to open dialog only for physical Data Sets
    if (newState && dataSet && dataSet.data.dataSetType === DataSetType.PHYSICAL) {
      setOpenInternal(newState);
    } else {
      setOpenInternal(false);
    }
  }, [setOpenInternal, dataSet]);

  // reset autoOpenChecked lock and close dialog when data set has changed
  useDidUpdateEffect(() => {
    wasAutoOpenChecked.current = false;
    setOpen(false)
  }, [dataSetId]);

  if (!isOpen && !wasAutoOpenChecked.current && dataSet) {
    const { fetchedAt, versionInBackend } = dataSet.meta;
    const { version } = dataSet.data;
    const isNewlyCreated = version === 1 && Math.abs(versionInBackend - fetchedAt) <= NEW_OBJECT_TIME_THRESHOLD;
    if (isNewlyCreated) {
      setOpen(true);
    }
    wasAutoOpenChecked.current = true;
  }

  return {
    isOpen,
    setOpen
  };
};