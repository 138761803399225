import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { VALUE_TYPE_TYPE, ValueTypeState } from '../types/valueTypeTypes';

const initialState: ValueTypeState = getInitialCoreEntityState(VALUE_TYPE_TYPE);

const valueTypeSlice = createSlice({
  name: VALUE_TYPE_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default valueTypeSlice.reducer;
