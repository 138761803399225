import React from 'react';
import { AccurityMenuItem, CollapsingGroup, DetailHeader, DetailInlineGroup, StaticField } from 'ts-components';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import {
  Attribute,
  ATTRIBUTE_DESCRIPTION_LABEL,
  ATTRIBUTE_ICON,
  ATTRIBUTE_TYPE,
  AttributePropertyInheritanceType,
  AttributePropertyInheritanceTypeOptions,
  CALCULATION_RULE_LABEL,
  CALCULATION_RULE_PLURAL_LABEL,
  CONFIRM_ENTITY_OR_ATT_DEF_CHANGE_SETTINGS_DETAIL,
  INHERITED_ATTRIBUTE_LABEL,
  INHERITED_CHILD_ATTRIBUTE_LABEL,
  INHERITED_CHILD_ATTRIBUTE_PLURAL_LABEL
} from '../types/attributeTypes';
import {
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_LABEL,
  ATTRIBUTE_DEFINITION_TYPE,
  AttributeDefinitionType,
  isComponent,
  PARENT_ATTRIBUTE_LABEL
} from '../../attributeDefinition/types/attributeDefinitionTypes';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../entity/types/entityTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { excludeFieldValueFilter } from '../../../common/referenceField/utils/filters';
import EmbeddedDetail from '../../../common/detail/components/EmbeddedDetail';
import { getAttributeDefinitionDetailFields } from '../../attributeDefinition/detail/AttributeDefinitionDetail';
import { getEntityDetailFields } from '../../entity/detail/EntityDetail';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import { useAttributeDetailLogic } from './attributeDetailLogic';
import AttributeConfirmRemovalDetail from './AttributeConfirmRemovalDetail';
import AttributeConfirmEntityOrAttDefChangeDetail from './AttributeConfirmEntityOrAttDefChangeDetail';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import RequirementsFieldWithFormik from '../../../common/detail/formik/RequirementsFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import RichTextEditorFieldWithFormik from '../../../common/detail/formik/RichTextEditorFieldWithFormik';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_LABEL, BUSINESS_RULE_PLURAL_LABEL, BUSINESS_RULE_TYPE } from '../../businessRule/types/businessRuleTypes';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../process/mapping/types/processMappingTypes';
import GenerateProcessDiagramButton from '../../processDiagram/detail/GenerateProcessDiagramButton';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagramBasedOnType } from '../../processDiagram/types/processDiagramTypes';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import BusinessModelMappingDiagramButton from '../../../common/detail/commonFields/BusinessModelMappingDiagramButton';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { useHasFeature } from '../../../common/userSettings/hooks/features';


interface AttributeDetailProps {
  id?: string,
}

const AttributeDetail = ({ id }: AttributeDetailProps) => {
  const {
    detailBagSaveAction,
    detailBagResetAction,
    customSaveSteps,
    customHandleExternalUpdate,
  } = useAttributeDetailLogic(id);
  const hasFeature = useHasFeature();

  const hasProcessDiagramCreatePermission = usePermissionsForObjectType(PROCESS_DIAGRAM_TYPE).hasCreatePermission;
  const hasProcessDiagramFeature = hasFeature(Feature.PROCESS_DIAGRAMS);

  const generateProcessDiagramButton = hasProcessDiagramCreatePermission && hasProcessDiagramFeature &&
    <GenerateProcessDiagramButton
      key="processDiagramButton"
      basedOnType={ProcessDiagramBasedOnType.ATTRIBUTES}
      objectType={ATTRIBUTE_TYPE}
      id={id}
    />;

  return (
    <AccurityDetailContainer<Attribute>
      objectType={ATTRIBUTE_TYPE}
      id={id}
      customSaveSteps={customSaveSteps}
      customHandleExternalUpdate={customHandleExternalUpdate}
    >
      {(formik, defaultDetailBag) => {
        const detailBag = {
          ...defaultDetailBag,
          saveAction: detailBagSaveAction(formik, defaultDetailBag)
        };

        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return <AttributeConfirmRemovalDetail formik={formik} detailBag={detailBag}/>;
        } else if (formik.status.settingsDetail === CONFIRM_ENTITY_OR_ATT_DEF_CHANGE_SETTINGS_DETAIL) {
          return <AttributeConfirmEntityOrAttDefChangeDetail detailBag={detailBag}/>;
        } else {
          return (
            <>
              {getDetailFields(formik, detailBag)}
              <DefaultDetailBottomBar
                objectType={ATTRIBUTE_TYPE}
                detailBag={{
                  ...detailBag,
                  showCopyAction: false,
                  showRemoveAction: formik.status.permissions.hasDeletePermission && !formik.initialValues.inherited &&
                    !isComponent(formik.initialValues.attributeDefinitionType) && formik.status.isUpdateDetail,
                }}
                customMenuItems={[
                  ...formik.values.propertyInheritanceType !== AttributePropertyInheritanceType.FULLY &&
                  formik.values.inherited === true
                    ? [<AccurityMenuItem key="reset" onClick={detailBagResetAction}>Reset</AccurityMenuItem>]
                    : [],
                  generateProcessDiagramButton
                ]}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getDetailFields = (formik: FormikProps<Attribute>,
                         detailBag: DetailBag<Attribute>) => {

  const setNameValue = (entity?: { name: string } | null, attributeDefinition?: { name: string } | null) => {
    const newValue = entity && attributeDefinition
      ? entity.name + '.' + attributeDefinition.name
      : '';

    formik.setFieldValue('name', newValue);
  };

  const isVersionBrowser = formik.status.isVersionBrowser;

  return (
    <>
      {!isVersionBrowser && <DetailHeader iconName={ATTRIBUTE_ICON}>
        <NameFieldWithFormik
          label={'Attribute Name'}
          readOnly={true}
        />
        <DetailTopBar/>
      </DetailHeader>}
      <EmbeddedDetail
        name={'entity'}
        id={formik.values.entity?.id}
        objectType={ENTITY_TYPE}
        referenceField={
          <ReferenceFieldWithFormik
            name={'entity'}
            label={ENTITY_LABEL}
            objectType={ENTITY_TYPE}
            icon={ENTITY_ICON}
            readOnly={formik.initialValues.inherited || isComponent(formik.initialValues.attributeDefinitionType)}
            setValue={entity => {
              formik.setFieldValue('entity', entity);
              setNameValue(entity, formik.values.attributeDefinition);
            }}
          />
        }
        embeddedFields={getEntityDetailFields}
        containingDetailBag={detailBag}
      />
      <EmbeddedDetail
        name={'attributeDefinition'}
        objectTypeLabel={ATTRIBUTE_DEFINITION_LABEL}
        id={formik.values.attributeDefinition?.id}
        objectType={ATTRIBUTE_DEFINITION_TYPE}
        referenceField={
          <ReferenceFieldWithFormik
            name={'attributeDefinition'}
            label={ATTRIBUTE_DEFINITION_LABEL}
            objectType={ATTRIBUTE_DEFINITION_TYPE}
            icon={ATTRIBUTE_DEFINITION_ICON}
            additionalFilters={[
              excludeFieldValueFilter('attributeDefinitionType', AttributeDefinitionType.COMPONENT_VALUE),
              excludeFieldValueFilter('attributeDefinitionType', AttributeDefinitionType.COMPONENT_REFERENCE)
            ]}
            readOnly={formik.initialValues.inherited || isComponent(formik.initialValues.attributeDefinitionType)}
            setValue={attributeDefinition => {
              formik.setFieldValue('attributeDefinition', attributeDefinition);
              setNameValue(formik.values.entity, attributeDefinition);
            }}
          />
        }
        embeddedFields={getAttributeDefinitionDetailFields}
        editable={formik.status.permissions.hasUpdatePermission && !formik.values.inherited && !isComponent(formik.initialValues.attributeDefinitionType)}
        containingDetailBag={detailBag}
      />
      <CollapsingGroup
        name={'attributeProperties'}
        title={'Attribute Properties'}
        expandedByDefault={true}
        expandable={true}
      >
        <DescriptionFieldWithFormik
          label={ATTRIBUTE_DESCRIPTION_LABEL}
          inherited={formik.values.descriptionInherited}
        />
        <FeatureChecker featureId={Feature.CALCULATION_RULES}>
          <RichTextEditorFieldWithFormik
            name={'calculationRule'}
            label={CALCULATION_RULE_LABEL}
            inherited={formik.values.calculationRuleInherited}
            allowedChipTypes={[ATTRIBUTE_TYPE]}
          />
        </FeatureChecker>
        <HistoryGroupWithFormik
          VersionBrowserButton={<VersionBrowserButton
            getDetailFields={formik => getDetailFields(formik, detailBag)}
          />}
        />
        <StatusFieldWithFormik
          inherited={formik.values.statusInherited}
        />
        <TagsFieldWithFormik
          objectType={ATTRIBUTE_TYPE}
          inherited={formik.values.tagsInherited}
        />
        <DetailInlineGroup>
          {formik.status.isUpdateDetail && <StaticField
            label={INHERITED_ATTRIBUTE_LABEL}
            name={'inherited'}
            value={formik.values.inherited ? 'Yes' : 'No'}
          />}
          {formik.values.inherited &&
          <StaticField
            name={'propertyInheritanceType'}
            label={'Inherited Properties'}
            value={AttributePropertyInheritanceTypeOptions.find(option => formik.values.propertyInheritanceType === option.value)?.label || ''}
          />}
        </DetailInlineGroup>
        {formik.values.inherited && <ReferenceFieldWithFormik
          name={'parent'}
          label={PARENT_ATTRIBUTE_LABEL}
          objectType={ATTRIBUTE_TYPE}
          icon={ATTRIBUTE_ICON}
          readOnly={true}
        />}
        <FeatureChecker featureId={Feature.REQUIREMENTS}>
          <RequirementsFieldWithFormik
            inherited={formik.values.requirementsInherited}/>
        </FeatureChecker>
        <CustomPropertyFieldsContainer
          forObjectType={GlossaryCustomPropertyObjectType.ATTRIBUTE}
          inheritance={formik.values.customPropertyValuesInheritance}
        />
        <FeatureChecker featureId={Feature.ATTRIBUTES}>
          <ChildrenCountFieldWithFormik
            singularLabel={INHERITED_CHILD_ATTRIBUTE_LABEL}
            pluralLabel={INHERITED_CHILD_ATTRIBUTE_PLURAL_LABEL}
            value={formik.values.childrenCounts.attributesCount}
            iconName={ATTRIBUTE_ICON}
            pinIconName={ATTRIBUTE_ICON}
            childFieldType={ATTRIBUTE_TYPE}
            childrenCountWording={ChildrenCountWording.HAS}
          />
        </FeatureChecker>
        <FeatureChecker featureId={Feature.CALCULATION_RULES}>
          <ChildrenCountFieldWithFormik
            singularLabel={CALCULATION_RULE_LABEL}
            pluralLabel={CALCULATION_RULE_PLURAL_LABEL}
            value={formik.values.childrenCounts.calculationRulesCount}
            iconName={ATTRIBUTE_ICON}
            pinIconName={ATTRIBUTE_ICON}
            childFieldType={ATTRIBUTE_TYPE}
            relationType={'CALCULATION_RULE'}
            childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          />
        </FeatureChecker>
        <FeatureChecker featureId={Feature.BUSINESS_RULES}>
          <ChildrenCountFieldWithFormik
            singularLabel={BUSINESS_RULE_LABEL}
            pluralLabel={BUSINESS_RULE_PLURAL_LABEL}
            value={formik.values.childrenCounts.businessRulesCount}
            iconName={BUSINESS_RULE_ICON}
            pinIconName={ATTRIBUTE_ICON}
            childFieldType={BUSINESS_RULE_TYPE}
            childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          />
        </FeatureChecker>
        <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
          <ChildrenCountFieldWithFormik
            singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
            pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
            value={formik.values.childrenCounts.businessModelMappingsCount}
            iconName={BUSINESS_MODEL_MAPPING_ICON}
            pinIconName={ATTRIBUTE_ICON}
            childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
            childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          />
        </FeatureChecker>
        <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
          <ChildrenCountFieldWithFormik
            singularLabel={PROCESS_MAPPING_LABEL}
            pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
            value={formik.values.childrenCounts.processMappingsCount}
            iconName={PROCESS_MAPPING_ICON}
            pinIconName={ATTRIBUTE_ICON}
            childFieldType={PROCESS_MAPPING_TYPE}
            childrenCountWording={ChildrenCountWording.TARGET_OF}
          />
        </FeatureChecker>
        <BusinessModelMappingDiagramButton
          objectType={ATTRIBUTE_TYPE}
          id={formik.values.id}
          disabled={formik.status.isEntityInDetailDeleted}
        />
      </CollapsingGroup>
    </>
  );
};

export default AttributeDetail;
