export interface AccurityFetchError {
  errorCode: number;
  errorBody?: AccurityFetchErrorBody[];
}

export interface AccurityFetchErrorBody {
  message: AccurityFetchErrorMessage;
  path?: string;
}

export interface AccurityFetchErrorMessage {
  code: string;
  header: string;
  body: string;
  httpStatus: number;
}

export enum CustomEndpointRequestType {
  REFERENCE = 'REFERENCE',
  LIST = 'LIST'
}

export const WEBSOCKET_MESSAGING_SESSION_ID = 'Accurity-Messaging-Session-Id';
export const CONTENT_DISPOSITION_HEADER = 'Content-disposition';
export const FILENAME_PREFIX = 'filename=';