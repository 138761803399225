import React from 'react';
import { DetailHeader } from 'ts-components';

import { ROLE_FIELD_NAME, ROLE_ICON, ROLE_LABEL, ROLE_PLURAL_LABEL, ROLE_TYPE, User, USER_ICON, USER_TYPE } from '../types/userTypes';
import SingleLineFieldWithFormik from '../../../common/detail/formik/SinglelineFieldWithFormik';
import CollectionFieldWithFormik from '../../../common/detail/formik/CollectionFieldWithFormik';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import { createUniqueWithinCollectionFilters } from '../../../common/referenceField/utils/filters';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { updateUserProfileInStore } from '../../../common/userSettings/redux/userSettingsSlice';
import { useDispatch } from 'react-redux';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { getFinishedSavingMessage, getInvitationHasBeenSentMessage } from '../../../common/userMessages/commonMessages';
import { lockUser, unlockUser } from '../redux/userActions';
import { UserDetailBottomBar } from './UserDetailBottomBar';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { CustomSaveSteps, getDefaultSaveSteps } from '../../../common/detail/actions/detailSave';
import { showSnackbarMessage } from '../../../common/userMessages/actions';
import { FormikProps } from 'formik';
import { getLabelForValue } from '../../../common/childrenCountField/utils';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface UserDetailProps {
  id?: string;
}

const UserDetail = ({ id }: UserDetailProps) => {
  const dispatch = useDispatch();
  const navigationController = useAccurityNavigation();
  const hasFeature = useHasFeature();

  const successSavingSnackbar = (savedEntity: User, previousEntity: User) => {
    if (!previousEntity.id && savedEntity.id) {
      // creating new user
      showSnackbarMessage(getInvitationHasBeenSentMessage(savedEntity));
    } else {
      // updating existing user
      showSnackbarMessage(getFinishedSavingMessage(savedEntity));
    }

    return undefined;
  };

  const customSaveSteps: CustomSaveSteps<User> = {
    addSavedEntityToStore: (savedUser: User) => {
      getDefaultSaveSteps<User>().addSavedEntityToStore(savedUser);
      dispatch(updateUserProfileInStore(savedUser));
    },
    showSaveSuccessSnackbar: successSavingSnackbar,
  };

  const postLockUnlockReload = id ? () => {
    navigationController.openDetailWithObject(USER_TYPE, id);
  } : undefined;

  return (
    <AccurityDetailContainer<User>
      objectType={USER_TYPE}
      id={id}
      customSaveSteps={customSaveSteps}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          const rolesUniqueFilter = createUniqueWithinCollectionFilters('id', formik.values.roles);
          const lockAccount = () => lockUser(formik.values, postLockUnlockReload);
          const unlockAccount = () => unlockUser(formik.values, postLockUnlockReload);
          const isEmailEditable = hasFeature(Feature.ALWAYS_EDITABLE_EMAIL)
            ? true
            : !formik.status.isUpdateDetail;
          return (
            <>
              <DetailHeader iconName={USER_ICON}>
                <SingleLineFieldWithFormik
                  name={'email'}
                  label={'Email'}
                  type={'email'}
                  readOnly={!isEmailEditable}
                />
                <DetailTopBar allowComments={false} allowEmailNotification={false} />
              </DetailHeader>
              <SingleLineFieldWithFormik name={'firstName'} label={'First Name'}/>
              <SingleLineFieldWithFormik name={'lastName'} label={'Last Name'}/>
              {/* see #24978 */}
              {/*{formik.status.isUpdateDetail && <StaticField*/}
              {/*name={'accountStatus'}*/}
              {/*label={'Account Status'}*/}
              {/*value={accountStatuses.find(q => q.value === formik.values.accountStatus)?.label || 'Unknown'}*/}
              {/*style={{ marginBottom: '16px' }}*/}
              {/*/>}*/}
              <CollectionFieldWithFormik
                name={ROLE_FIELD_NAME}
                title={getRolesLabel(formik)}
                icon={ROLE_ICON}
                rowValidation={(value) => value && value.id}
                renderRow={(elementName) =>
                  <ReferenceFieldWithFormik
                    name={elementName}
                    label={ROLE_LABEL}
                    objectType={ROLE_TYPE}
                    additionalFilters={rolesUniqueFilter}
                    hideRemoveButton={true}
                    showDefaultTooltip={false}
                  />
                }
              />
              <UserDetailBottomBar
                formik={formik}
                detailBag={detailBag}
                lockAccount={lockAccount}
                unlockAccount={unlockAccount}
              />
            </>
          )
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<User>, detailBag: DetailBag<User>) => (
  <ConfirmRemovalDetail
    iconName={USER_ICON}
    detailBag={detailBag}
    objectName={formik.values.email}
  />
);

export const getRolesLabel = (formik: FormikProps<User>): string => {
  const roles: [] = formik.getFieldProps(ROLE_FIELD_NAME).value;
  let length = 0;
  if (roles) {
    length = roles.length;
  }

  return getLabelForValue(ROLE_LABEL, ROLE_PLURAL_LABEL, length, ChildrenCountWording.NONE);
};

export default UserDetail;
