import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';

export const USER_TYPE = 'user';
export const USER_LABEL = 'User';
export const USER_PLURAL_LABEL = 'Users';
export const USER_ICON = 'user';

export const ROLE_FIELD_NAME = 'roles';
export const ROLE_TYPE = 'role';
export const ROLE_LABEL = 'Role';
export const ROLE_PLURAL_LABEL = 'Roles';
export const ROLE_ICON = 'role';

export enum AccountStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  LOCKED_MANUALLY = 'LOCKED_MANUALLY',
  LOCKED_AUTOMATICALLY = 'LOCKED_AUTOMATICALLY'
}

export type AccountStatusWithLabel = {
  value: AccountStatus,
  label: string
}

export const accountStatuses: AccountStatusWithLabel[] = [
  { value: AccountStatus.INVITED, label: 'Invited' },
  { value: AccountStatus.ACTIVE, label: 'Active' },
  { value: AccountStatus.LOCKED_MANUALLY, label: 'Locked Manually' },
  { value: AccountStatus.LOCKED_AUTOMATICALLY, label: 'Locked Automatically' }
];

export interface UserState extends CoreEntityState<User> {
}

export interface User extends AccurityCoreEntity {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  timezone: string;
  roles: AccurityReference[];
  accountStatus: AccountStatus;
}
