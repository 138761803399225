import * as React from 'react';
import { mapValues } from 'lodash';
import { BoxAndWhisker, ProfileCard, ProfilingDataType } from 'ts-components';
import { DateStatisticData, NumericStatisticData, ResultData } from '../types/types';

export const handleStatisticData = ({ numericStatisticData, dateStatisticsData, generalData }: ResultData) => {

  // we display statistics only if there is at least 3 distinct values
  if (generalData.distinctCount < 3) {
    return null;
  }

  if (numericStatisticData) {
    return handleNumericStatisticData(numericStatisticData);
  } else if (dateStatisticsData) {
    return handleDateStatisticData(dateStatisticsData);
  } else {
    return null;
  }
};

const handleNumericStatisticData = (numericStatisticData: NumericStatisticData) => {

  numericStatisticData = mapValues(numericStatisticData, value => parseFloat(value as any));

  return (
    <ProfileCard title={'Statistics'} key={'statistics'}>
      <BoxAndWhisker
        min={numericStatisticData.min}
        max={numericStatisticData.max}
        mean={numericStatisticData.mean}
        median={numericStatisticData.median}
        standardDeviation={numericStatisticData.standardDeviation}
        dataType={ProfilingDataType.NUMBER_TYPE}
      />
    </ProfileCard>
  );
};

const handleDateStatisticData = (dateStatisticData: DateStatisticData) => {

  dateStatisticData = mapValues(dateStatisticData, value => parseFloat(value as any));

  return (
    <ProfileCard title={'Statistics'} key={'statistics'}>
      <BoxAndWhisker
        min={dateStatisticData.min}
        max={dateStatisticData.max}
        mean={dateStatisticData.mean}
        dataType={ProfilingDataType.TIME_TYPE}
      />
    </ProfileCard>
  );
};

