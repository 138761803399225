import React from 'react';
import { FormikProps } from 'formik';
import { CUSTOM_PROPERTY_DEFAULT_LABEL, CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, CustomProperty } from '../../types/customPropertyTypes';
import { getSupportiveCustomPropertyDefaultValueFieldLabel } from '../../../customPropertyUtils';
import { DetailInlineGroup } from 'ts-components';
import ReferenceFieldWithFormik from '../../../../detail/formik/ReferenceFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../../../businessGlossary/entity/types/entityTypes';
import { AccurityFilter, AccuritySearch, AccuritySortType } from '../../../../types/accurityTypes';
import { createReferenceFieldFilter } from '../../../../referenceField/utils/filters';
import { doFetch } from '../../../../rest/FetchService';
import { getSearchEndpoint } from '../../../../rest/endpoints';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL, ATTRIBUTE_TYPE } from '../../../../../businessGlossary/attribute/types/attributeTypes';
import { ListSearchResult } from '../../../../list/types/types';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../../../../businessGlossary/attributeDefinition/types/attributeDefinitionTypes';

interface AttributeDefaultValueFieldProps {
  formik: FormikProps<CustomProperty>;
}

const AttributeDefaultValueField = ({ formik }: AttributeDefaultValueFieldProps) => {

  const targetObjectType = formik.values.targetObjectType;
  const readOnly = targetObjectType === null;

  const entityReferenceFieldProps = formik.getFieldProps(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.entityReference').value;
  const attributeDefinitionReferenceFieldProps = formik.getFieldProps(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.attributeDefinitionReference').value;

  const entityId = entityReferenceFieldProps?.id;
  const attributeDefinitionId = attributeDefinitionReferenceFieldProps?.id;

  let entityFilters = [];
  let attributeDefinitionFilters = [];


  if (!attributeDefinitionId) {
    entityFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    entityFilters.push(
      createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
    );
  }

  if (!entityId) {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('childrenCounts.attributesCount', '>=1')
    );
  } else {
    attributeDefinitionFilters.push(
      createReferenceFieldFilter('entity.id', entityId)
    );
  }

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}>
        <ReferenceFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.entityReference'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, ENTITY_LABEL)}
          objectType={ENTITY_TYPE}
          customEndpoint={ENTITY_TYPE + '/business-model-mapping-entity-selection'}
          additionalFilters={entityFilters}
          icon={ENTITY_ICON}
          readOnly={readOnly}
          setValue={(entity) => {
            if (entity) {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.entityReference', entity);
              if (attributeDefinitionReferenceFieldProps) {
                let attributeFilter: AccurityFilter[] = [];
                attributeFilter.push(
                  createReferenceFieldFilter('entity.id', entity?.id),
                  createReferenceFieldFilter('attributeDefinition.id', attributeDefinitionId)
                );

                const search: AccuritySearch = {
                  filters: attributeFilter,
                  sort: { property: 'name', type: AccuritySortType.ASCENDING },
                  maxResults: 20,
                  startFrom: 0
                };

                doFetch(getSearchEndpoint(ATTRIBUTE_TYPE), 'POST', JSON.stringify(search))
                  .then((result: ListSearchResult) => formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', result?.rows[0]))
              }
            } else {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.entityReference', null);
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', null);
            }
          }}
        />
        <ReferenceFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.attributeDefinitionReference'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, ATTRIBUTE_LABEL)}
          objectType={ATTRIBUTE_DEFINITION_TYPE}
          customEndpoint={ATTRIBUTE_DEFINITION_TYPE + '/business-model-mapping-attribute-definition-selection'}
          additionalFilters={attributeDefinitionFilters}
          icon={ATTRIBUTE_ICON}
          readOnly={readOnly}
          setValue={(attributeDefinition) => {
            if (attributeDefinition) {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.attributeDefinitionReference', attributeDefinition);
              if (entityReferenceFieldProps) {
                let attributeFilter: AccurityFilter[] = [];
                attributeFilter.push(
                  createReferenceFieldFilter('entity.id', entityId),
                  createReferenceFieldFilter('attributeDefinition.id', attributeDefinition?.id)
                );

                const search: AccuritySearch = {
                  filters: attributeFilter,
                  sort: { property: 'name', type: AccuritySortType.ASCENDING },
                  maxResults: 20,
                  startFrom: 0
                };

                doFetch(getSearchEndpoint(ATTRIBUTE_TYPE), 'POST', JSON.stringify(search))
                  .then((result: ListSearchResult) => formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', result?.rows[0]))
              }
            } else {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.attributeDefinitionReference', null);
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', null);
            }
          }}
        />
      </DetailInlineGroup>
    </>
  )
};

export default AttributeDefaultValueField;