import React from 'react';
import CollectionFieldWithFormik from './CollectionFieldWithFormik';
import ReferenceFieldWithFormik from './ReferenceFieldWithFormik';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity, AccurityFilter } from '../../types/accurityTypes';
import { createUniqueWithinCollectionFilters } from '../../referenceField/utils/filters';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../referenceField/utils/dragAndDrop';
import { REQUIREMENT_ICON, REQUIREMENT_LABEL, REQUIREMENT_PLURAL_LABEL, REQUIREMENT_TYPE } from '../../../businessGlossary/requirement/types/requirementTypes';
import { getLabelForValue } from '../../childrenCountField/utils';
import { ChildrenCountWording } from '../../childrenCountField/types';

const REQUIREMENTS_FIELD_NAME = 'requirements';

interface requirementsFieldWithFormikProps {
  additionalFilters?: AccurityFilter[];
  inherited?: boolean;
}

const RequirementsFieldWithFormik = ({ additionalFilters = [], inherited }: requirementsFieldWithFormikProps) => {
  const formikContext = useFormikContext<AccurityCoreEntity>();
  const requirements = formikContext.getFieldProps(REQUIREMENTS_FIELD_NAME).value;
  const collectionTitle = getLabelForValue(REQUIREMENT_LABEL, REQUIREMENT_PLURAL_LABEL, requirements.length, ChildrenCountWording.REFERENCES);

  const requirementsFilters = [
    ...additionalFilters,
    ...createUniqueWithinCollectionFilters('id', requirements),
  ];

  return (
    <CollectionFieldWithFormik
      name={REQUIREMENTS_FIELD_NAME}
      title={collectionTitle}
      icon={REQUIREMENT_ICON}
      rowValidation={(value) => value && value.id}
      testDraggedItem={defaultTestDraggedItem(REQUIREMENT_TYPE)}
      testDroppedItem={defaultTestDroppedItem({ objectType: REQUIREMENT_TYPE, additionalFilters: requirementsFilters })}
      inherited={inherited}
      renderRow={(elementName) =>
        <ReferenceFieldWithFormik
          name={elementName}
          label={REQUIREMENT_LABEL}
          icon={REQUIREMENT_ICON}
          objectType={REQUIREMENT_TYPE}
          additionalFilters={requirementsFilters}
          hideRemoveButton={true}
        />
      }
    />
  );
};

export default RequirementsFieldWithFormik;
