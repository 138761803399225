import React from 'react';
import { AccurityButton, DetailBottomBar } from 'ts-components';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { DetailBag } from '../types/types';

interface ConfirmRemovalDetailBottomBarProps {
  detailBag: DetailBag<AccurityCoreEntity>;
}

const ConfirmRemovalDetailBottomBar = ({ detailBag }: ConfirmRemovalDetailBottomBarProps) => {
  return (
    <DetailBottomBar>
      <AccurityButton
        onClick={() => detailBag.setSettingsDetail(undefined)}
        disabled={detailBag.isLoading}
        color="light"
      >
        Cancel
      </AccurityButton>
      <AccurityButton
        onClick={() => detailBag.removeAction()}
        disabled={detailBag.isLoading || detailBag.isRemoveDisabled}
        color="primary"
      >
        Confirm
      </AccurityButton>
    </DetailBottomBar>
  );
};

export default ConfirmRemovalDetailBottomBar;
