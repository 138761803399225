import { AccurityCoreEntity } from "../../types/accurityTypes";

export type DetailBag<T extends AccurityCoreEntity> = {
  isLoading: boolean | undefined;
  isReloadDisabled: boolean;
  isRemoveDisabled: boolean;
  isSaveDisabled: boolean;
  showCopyAction: boolean;
  showRemoveAction: boolean;
  showReloadAction: boolean;
  showSaveAction: boolean;
  saveAction: (fieldOverwrites?: Partial<T>) => void;
  reloadAction: () => void;
  copyAction: (copyFieldOverwrites?: Partial<T>) => void;
  removeAction: (fieldOverwrites?: Partial<T>) => void;
  setActiveEmbeddedDetail: (activeEmbeddedDetail: string | undefined) => void;
  setSettingsDetail: (settingsDetail: string | undefined) => void;
  setIsDetailLoading: (isLoading: boolean) => void;
}

export const CONFIRM_REMOVE_SETTINGS_DETAIL = 'CONFIRM_REMOVE';

export const TEMPLATE_OBJECT_ID = 'template';

export type SetFieldError = (field: string, errorMessage: string) => void;

export const ERROR_FIELD_PATH_SEPARATOR = ',';

export const IT_IS_CURRENTLY_LABEL = 'It is currently:';
export const IT_ALSO_LABEL = 'It also:';
export const IT_CURRENTLY_LABEL = 'It currently:';