import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption } from 'ts-components';

export const DATA_SOURCE_TYPE = 'data-source';
export const DATA_SOURCE_LABEL = 'Data Source';
export const DATA_SOURCE_PLURAL_LABEL = 'Data Sources';
export const DATA_SOURCE_ICON = 'dataSource';

export interface DataSourceState extends CoreEntityState<DataSource> {
}

export enum DataSourceType {
  ORACLE = 'ORACLE',
  MSSQL = 'MSSQL',
  POSTGRESQL = 'POSTGRESQL',
  TERADATA = 'TERADATA',
  EXCEL = 'EXCEL',
  MONGODB = 'MONGODB',
  MONGODB_ATLAS = 'MONGODB_ATLAS',
  BIGQUERY = 'BIGQUERY',
  SNOWFLAKE = 'SNOWFLAKE',
  VERTICA = 'VERTICA',
}

export const DataSourceTypeOptions: EnumOption[] = [
  { value: DataSourceType.BIGQUERY, label: 'BigQuery' },
  { value: DataSourceType.EXCEL, label: 'Microsoft Excel' },
  { value: DataSourceType.MONGODB, label: 'MongoDB' },
  { value: DataSourceType.MONGODB_ATLAS, label: 'MongoDB Atlas' },
  { value: DataSourceType.ORACLE, label: 'Oracle' },
  { value: DataSourceType.POSTGRESQL, label: 'PostgreSQL' },
  { value: DataSourceType.MSSQL, label: 'SQL Server' },
  { value: DataSourceType.TERADATA, label: 'Teradata' },
  { value: DataSourceType.SNOWFLAKE, label: 'Snowflake' },
  { value: DataSourceType.VERTICA, label: 'Vertica' },
];

export enum OracleConnectionType {
  SID = 'SID',
  SERVICE = 'SERVICE'
}

export const OracleConnectionTypeOptions: EnumOption[] = [
  { value: OracleConnectionType.SID, label: 'SID' },
  { value: OracleConnectionType.SERVICE, label: 'Service' }
];

export interface DataSource extends AccurityCoreEntity, WithChildrenCounts {
  dataSourceType?: DataSourceType;
  useJdbcUrl: boolean;
  host?: string;
  port?: number;
  database?: string;
  authenticationDatabase?: string;
  jdbcUrl?: string;
  dataSourceUsername: string;
  dataSourcePassword: string;
  fileData: File;
  fileName: string;
}

export interface DataSourceReference extends AccurityReference {
  dataSourceType?: DataSourceType;
}
