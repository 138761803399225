import { doFetch } from '../../../common/rest/FetchService';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../../common/userMessages/actions';
import { getBusinessTermsSuggestionHasBeenFoundMessage } from '../../../common/userMessages/commonMessages';
import { ObjectSuggestionType } from 'ts-components';
import { SuggestionFetchFn } from '../../../common/detail/formik/RelatedBusinessTermsWithSuggestions';
import { ObjectSuggestionSearch } from '../../../common/types/accurityTypes';
import { AccurityFetchError } from '../../../common/rest/types';

export const createFetchFunctionForBusinessTermsSuggestions = (endpoint: string): SuggestionFetchFn => (
  objectName: string,
  excludedSuggestionsNames: string[] = [],
  maxResults: number = 5
): Promise<ObjectSuggestionType[]> => {

  const requestBody: ObjectSuggestionSearch = {
    excludedSuggestionsNames,
    maxResults,
    objectName
  };

  return doFetch(endpoint, 'POST', JSON.stringify(requestBody))
    .then((response: ObjectSuggestionType[]) => {
      showSnackbarMessage(getBusinessTermsSuggestionHasBeenFoundMessage(response.length));
      return response;
    })
    .catch((error: AccurityFetchError) => {
      if (error.errorBody?.some(value => value?.message?.httpStatus === 404)) {
        showSnackbarMessage(getBusinessTermsSuggestionHasBeenFoundMessage(0));
      } else {
        showErrorMessageFromResponse(error);
      }
      return [];
    });
};
