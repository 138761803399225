import { Client, StompSubscription } from '@stomp/stompjs';
import { getWebsocketEndpoint } from '../rest/endpoints';
import { handleWebsocket } from './websocketMessageHandler';

const isDevMode = process.env.NODE_ENV === 'development';
const verboseLogging = false;

let _client: Client;
let _userSubscription: StompSubscription;
let _broadcastSubscription: StompSubscription;
let _windowSubscription: StompSubscription;
let _websocketSessionIdForCurrentWindow: string;

export const connectWebsocketClient = (email: string) => {
  if (!!_client) {
    console.log('Attempting to establish websocket connection but connection should already be established.');
    return;
  }

  const websocketUrl = getWebsocketEndpoint(email);
  _client = new Client({
    brokerURL: websocketUrl,
    onConnect: () => {
      _windowSubscription = _client.subscribe(`/notifications/${getWebsocketSessionIdForCurrentWindow()}`, handleWebsocket)
      _userSubscription = _client.subscribe('/user/notifications', handleWebsocket);
      _broadcastSubscription = _client.subscribe('/notifications', handleWebsocket);
    },
    debug: logWebsocketMessage,
    logRawCommunication: true,
    reconnectDelay: 5000,
    // NOTE: Any heartbeat configuration here is completely ignored. Heartbeat is instead configured in backend WebSocketConfig.java
  });

  _client.activate();
}

export const disconnectWebsocketClient = () => {
  if (_userSubscription) {
    _userSubscription.unsubscribe();
  }
  if (_broadcastSubscription) {
    _broadcastSubscription.unsubscribe();
  }
  if (_windowSubscription) {
    _windowSubscription.unsubscribe();
  }
  if (_client) {
    _client.deactivate()
      .catch((err: string) => {
        console.log('Stomp client disconnect error: ', err)
      });
  }
};

export const getWebsocketSessionIdForCurrentWindow = () => {
  if (!_websocketSessionIdForCurrentWindow) {
    _websocketSessionIdForCurrentWindow = generateWebsocketSessionId();
  }
  return _websocketSessionIdForCurrentWindow;
}

const generateWebsocketSessionId = () => window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);

const logWebsocketMessage = (msg: string) => {
  if (!isDevMode) {
    return;
  }

  if (verboseLogging || msg.includes('MESSAGE')) {
    console.log(msg);
  }
}
