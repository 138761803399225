import React from 'react';
import { DetailHeader, StaticField } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import { COMPOSITE_TYPE_ICON, COMPOSITE_TYPE_TYPE, CompositeType } from '../types/compositeTypeTypes';
import {
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_LABEL,
  ATTRIBUTE_DEFINITION_PLURAL_LABEL,
  ATTRIBUTE_DEFINITION_TYPE
} from '../../attributeDefinition/types/attributeDefinitionTypes';
import ComponentsFieldWithFormik from '../../../common/detail/formik/ComponentsFieldWithFormik';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface CompositeTypeDetailProps {
  id?: string,
}

const CompositeTypeDetail = ({ id }: CompositeTypeDetailProps) => {
  return (
    <AccurityDetailContainer<CompositeType>
      objectType={COMPOSITE_TYPE_TYPE}
      id={id}>
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar objectType={COMPOSITE_TYPE_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<CompositeType>, detailBag: DetailBag<CompositeType>) => (
  <ConfirmRemovalDetail
    iconName={COMPOSITE_TYPE_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={IT_IS_CURRENTLY_LABEL}
      inverted={true}
    />
    <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
      <ChildrenCountFieldWithFormik
        singularLabel={ATTRIBUTE_DEFINITION_LABEL}
        pluralLabel={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
        value={formik.values.childrenCounts.attributeDefinitionsCount}
        iconName={ATTRIBUTE_DEFINITION_ICON}
        pinIconName={COMPOSITE_TYPE_ICON}
        childFieldType={ATTRIBUTE_DEFINITION_TYPE}
        childrenCountWording={ChildrenCountWording.USED_BY}
        inverted={true}
      />
    </FeatureChecker>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<CompositeType>) => {
  return (
    <>
      <DetailHeader iconName={COMPOSITE_TYPE_ICON}>
        <NameFieldWithFormik label={'Composite Type Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={getDetailFields}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={COMPOSITE_TYPE_TYPE}/>
      <ComponentsFieldWithFormik formik={formik}/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.COMPOSITE_TYPE}/>
      <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_DEFINITION_LABEL}
          pluralLabel={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributeDefinitionsCount}
          iconName={ATTRIBUTE_DEFINITION_ICON}
          pinIconName={COMPOSITE_TYPE_ICON}
          childFieldType={ATTRIBUTE_DEFINITION_TYPE}
          childrenCountWording={ChildrenCountWording.USED_BY}
        />
      </FeatureChecker>
    </>
  );
};

export default CompositeTypeDetail;
