import React, { useCallback } from 'react';
import CollectionFieldWithFormik from './CollectionFieldWithFormik';
import {
  BUSINESS_TERM_RELATED_VALUE_ICON,
  BusinessTermRelatedValue,
  RELATED_VALUE_DESCRIPTION_LABEL,
  RELATED_VALUE_LABEL,
  RELATED_VALUE_NAME_LABEL,
  RELATED_VALUE_PLURAL_LABEL,
} from '../../../businessGlossary/businessTerm/types/businessTermTypes';
import SingleLineFieldWithFormik from './SinglelineFieldWithFormik';
import MultiLineFieldWithFormik from './MultiLineFieldWithFormik';
import { DetailInlineGroup, getVersionBrowserColoringClass } from 'ts-components';
import { getLabelForValue } from '../../childrenCountField/utils';
import { ChildrenCountWording } from '../../childrenCountField/types';
import { useField } from 'formik';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

const RELATED_VALUES_FIELD_NAME = 'relatedValues';

const RelatedValuesFieldWithFormik = () => {
  const [field] = useField<BusinessTermRelatedValue[]>(RELATED_VALUES_FIELD_NAME);
  const collectionTitle = getLabelForValue(RELATED_VALUE_LABEL, RELATED_VALUE_PLURAL_LABEL, field.value.length, ChildrenCountWording.NONE);

  const renderRow = useCallback((elementName) => (
    <RelatedValueRow
      elementName={elementName}
    />
  ), []);

  return (
    <CollectionFieldWithFormik
      name={RELATED_VALUES_FIELD_NAME}
      title={collectionTitle}
      icon={BUSINESS_TERM_RELATED_VALUE_ICON}
      rowValidation={(value) => value && value.name}
      virtualizeListFromCount={20}
      renderRow={renderRow}
    />
  );
};

const RelatedValueRow = ({ elementName }: { elementName: string }) => {

  const coloring = useVersionBrowserColoring(elementName);

  return (
    <div className={getVersionBrowserColoringClass(coloring)}>
      <SingleLineFieldWithFormik
        name={elementName + '.name'}
        label={RELATED_VALUE_NAME_LABEL}
        disableColoring={true}
      />
      <DetailInlineGroup indentLeft={true}>
        <MultiLineFieldWithFormik
          name={elementName + '.description'}
          label={RELATED_VALUE_DESCRIPTION_LABEL}
          disableColoring={true}
        />
      </DetailInlineGroup>
    </div>
  );
};

export default RelatedValuesFieldWithFormik;
