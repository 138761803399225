import React from 'react';

import { FormikProps } from 'formik';

import { NumericFormat } from 'ts-components';

import { CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL, CustomProperty, CustomPropertyPropertyType } from '../../types/customPropertyTypes';
import EnumerationFieldWithFormik from '../../../../detail/formik/EnumerationFieldWithFormik';
import ReferenceFieldWithFormik from '../../../../detail/formik/ReferenceFieldWithFormik';
import DateFieldWithFormik from '../../../../detail/formik/DateFieldWithFormik';
import HyperlinkFieldWithFormik from '../../../../detail/formik/HyperlinkFieldWithFormik';
import SingleLineFieldWithFormik from '../../../../detail/formik/SinglelineFieldWithFormik';
import { getDisplayCustomPropertyValueField, targetObjectTypeIcon, targetObjectTypeTypeEndpoint } from '../../../customPropertyUtils';
import MultiLineFieldWithFormik from '../../../../detail/formik/MultiLineFieldWithFormik';
import { CustomPropertyTargetObjectType } from '../../../../../businessGlossary/customProperties/types';
import { useDataSetTypeEnumOptions } from '../../../../../businessGlossary/dataSet/types/dataSetTypes';
import { useHasFeature } from '../../../../userSettings/hooks/features';
import { Feature } from '../../../../userSettings/features/features';
import ProcessStepDefaultValueField from './ProcessStepDefaultValueField';
import DataStructureDefaultValueField from './DataStructureDefaultValueField';
import DataFieldDefaultValueField from './DataFieldDefaultValueField';
import AttributeDefaultValueField from './AttributeDefaultValueField';


interface DefaultValueProps {
  formik: FormikProps<CustomProperty>
}

const DefaultValue = ({ formik }: DefaultValueProps) => {

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  switch (formik.values.propertyType) {
    case CustomPropertyPropertyType.DATE:
      return (
        <DateFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.value'}
          label={CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
      );
    case CustomPropertyPropertyType.ENUMERATION:
      const isReadOnly = formik.values.options.filter(value => value !== undefined).length < 2;
      return (
        <EnumerationFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.value'}
          label={CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL}
          canBeEmpty={true}
          readOnly={isReadOnly}
          options={isReadOnly ? [] : formik.values.options.filter(option => option)}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
      );
    case CustomPropertyPropertyType.HYPERLINK:
      return (
        <HyperlinkFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.value'}
          label={CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
      );
    case CustomPropertyPropertyType.NUMBER:
      return (
        <SingleLineFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.value'}
          label={CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL}
          format={NumericFormat.DECIMAL}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
      );
    case CustomPropertyPropertyType.RELATION:
      const targetObjectType = formik.values.targetObjectType;

      const objectType = targetObjectTypeTypeEndpoint(targetObjectType);
      const icon = targetObjectTypeIcon(targetObjectType);
      const readOnly = targetObjectType === null;
      const displayCustomPropertyValueField = getDisplayCustomPropertyValueField(targetObjectType);

      return (
        <>
          {CustomPropertyTargetObjectType.PROCESS_STEP === targetObjectType &&
          <ProcessStepDefaultValueField
            formik={formik}
          />
          }

          {CustomPropertyTargetObjectType.DATA_STRUCTURE === targetObjectType &&
          <DataStructureDefaultValueField
            formik={formik}
            multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
            dataSetTypeEnumOptions={dataSetTypeEnumOptions}/>
          }

          {CustomPropertyTargetObjectType.DATA_FIELD === targetObjectType &&
          <DataFieldDefaultValueField
            formik={formik}
            multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
            dataSetTypeEnumOptions={dataSetTypeEnumOptions}/>
          }

          {CustomPropertyTargetObjectType.ATTRIBUTE === targetObjectType &&
          <AttributeDefaultValueField
            formik={formik}
          />
          }

          {displayCustomPropertyValueField && <ReferenceFieldWithFormik
            name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference'}
            icon={icon}
            label={CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL}
            setValue={(newValue) => {
              if (newValue) {
                formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', newValue);
              } else {
                formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, null);
              }
            }}
            objectType={objectType}
            coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
            readOnly={readOnly}
          />}
        </>
      );
    case CustomPropertyPropertyType.TEXT:
      return (
        <MultiLineFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.value'}
          label={CUSTOM_PROPERTY_DEFAULT_VALUE_LABEL}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
        />
      );

  }
};

export default DefaultValue;