import { requestApplicationSettings, requestPublicSetting } from '../rest/appSettingsApi';
import { setApplicationSettingsInStore, setPublicSettingsInStore } from '../redux/appSettingsSlice';
import { useDispatch } from 'react-redux';
import Analytics from '../../analytics';

export const useLoadPublicSettings = () => {
  const dispatch = useDispatch();
  return () => requestPublicSetting()
    .then(settings => {
      if (settings) {
        dispatch(setPublicSettingsInStore(settings));
        Analytics.initAnalytics(settings);
      }
    });
};

export const useLoadApplicationSettings = () => {
  const dispatch = useDispatch();
  return () => requestApplicationSettings()
    .then(settings => dispatch(setApplicationSettingsInStore(settings)));
};
