import { ResultData } from '../types/types';

export const handleCountByLengthHistogram = ({ frequencyData }: ResultData) => {

  return null;

  // this is not supported by backend yet
  //
  // if (!frequencyData.countByLength || frequencyData.countByLength.length < 3) {
  //   return null;
  // }
  //
  // frequencyData.countByLength.sort((a, b) => parseInt(a.value) - parseInt(b.value));
  //
  // const labels = frequencyData.countByLength.map(q => q.value);
  // const values = frequencyData.countByLength.map(q => q.count);
  //
  // return (
  //   <ProfileCard title={'Value Distribution - String Length'} key={'value-distribution'}>
  //     <Histogram
  //       labels={labels}
  //       values={values}
  //     />
  //   </ProfileCard>
  // );
};
