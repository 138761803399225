import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { COMPOSITE_TYPE_TYPE, CompositeTypeState } from '../types/compositeTypeTypes';

const initialState: CompositeTypeState = getInitialCoreEntityState(COMPOSITE_TYPE_TYPE);

const compositeTypeSlice = createSlice({
  name: COMPOSITE_TYPE_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default compositeTypeSlice.reducer;
