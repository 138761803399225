import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { DATA_SOURCE_TYPE, DataSourceState } from '../types/dataSourceTypes';

const initialState: DataSourceState = getInitialCoreEntityState(DATA_SOURCE_TYPE);

const dataSourceSlice = createSlice({
  name: DATA_SOURCE_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default dataSourceSlice.reducer;
