import { AccurityCoreEntity, AccurityCoreEntityIdentifier, EmailNotificationActionType } from '../types/accurityTypes';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { CoreEntityState } from './types';
import { getLastModifiedDate } from './utils';
import { ListSearchResult } from '../list/types/types';

type AddCoreEntityPayload = { coreEntity: AccurityCoreEntity | AccurityCoreEntity[], fetchedAt: number };
export const addCoreEntityToStore = createAction<AddCoreEntityPayload>('ADD_ENTITY_TO_STORE');
export const addCoreEntityToStoreReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<AddCoreEntityPayload>) => {
  const coreEntities = Array.isArray(action.payload.coreEntity) ? action.payload.coreEntity : [action.payload.coreEntity];
  coreEntities.forEach(coreEntity => {
    if (state.type !== coreEntity.objectType) {
      return;
    }

    const id = coreEntity.id || 'template';

    const candidateVersion = getLastModifiedDate(coreEntity) || Date.now();
    const versionInStore = state.byId[id] && state.byId[id].meta.versionInStore;
    if (versionInStore && candidateVersion && versionInStore > candidateVersion) {
      return; // overwrite if changed version is more recent OR if changed version is the same
      // We need to overwrite when changed version is the same because updating some Collection items on
      // Entity or Reference inside Entity does not update 'changedDate' so we would otherwise miss those changes
    }

    state.byId[id] = {
      data: coreEntity,
      meta: {
        versionInStore: candidateVersion,
        versionInBackend: candidateVersion,
        wasDeleted: false,
        fetchedAt: action.payload.fetchedAt,
      }
    };

    if (!state.allIds.includes(id)) {
      state.allIds.push(id);
    }
  })
};

export const removeCoreEntityFromStore = createAction<AccurityCoreEntityIdentifier>('REMOVE_ENTITY_FROM_STORE');
export const removeCoreEntityFromStoreReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<AccurityCoreEntityIdentifier>) => {
  if (state.type !== action.payload.objectType) {
    return;
  }

  state.allIds = state.allIds.filter(id => String(id) !== String(action.payload.id));
  state.currentListSearchResult.ids = state.currentListSearchResult.ids.filter(id => String(id) !== String(action.payload.id));
  delete state.byId[action.payload.id];
};

type SetNotificationPayload = {
  notificationType: EmailNotificationActionType;
  enabled: boolean;
} & AccurityCoreEntityIdentifier;
export const setNotificationForCoreEntity = createAction<SetNotificationPayload | SetNotificationPayload[]>('SET_NOTIFICATION_FOR_CORE_ENTITY');
export const setNotificationForCoreEntityReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<SetNotificationPayload | SetNotificationPayload[]>) => {
  const changes = Array.isArray(action.payload) ? action.payload : [action.payload];

  changes
    .filter(change => change.objectType === state.type && !!state.byId[change.id])
    .forEach(({ id, enabled, notificationType }) => {
      state.byId[id].data.activeNotifications = {
        ...state.byId[id].data.activeNotifications,
        [notificationType]: enabled
      }
    });
};

type SetListSearchResultInStorePayload =
  { objectType: string, ids: string[], fetchedAt: number }
  & Pick<ListSearchResult, 'size' | 'existingObjects' | 'maximumObjects' | 'additionalExistingObjects' | 'additionalMaximumObjects'>;
export const setListSearchResultInStore = createAction<SetListSearchResultInStorePayload>('SET_LIST_SEARCH_RESULT_IN_STORE');
export const setListSearchResultInStoreReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<SetListSearchResultInStorePayload>) => {
  if (state.type !== action.payload.objectType) {
    return;
  }

  state.currentListSearchResult = {
    ...state.currentListSearchResult,
    ids: action.payload.ids,
    totalRows: action.payload.size,
    existingObjects: action.payload.existingObjects,
    maximumObjects: action.payload.maximumObjects,
    additionalExistingObjects: action.payload.additionalExistingObjects,
    additionalMaximumObjects: action.payload.additionalMaximumObjects,
    fetchedAt: action.payload.fetchedAt,
  }
};

type InvalidateAllEntityTypesPayload = { invalidatedAt: number };
export const invalidateAllEntityTypesInStore = createAction<InvalidateAllEntityTypesPayload>('INVALIDATE_ALL_ENTITY_TYPES_IN_STORE');
export const invalidateAllEntityTypesInStoreReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<InvalidateAllEntityTypesPayload>) => {
  state.invalidatedAt = action.payload.invalidatedAt;
};

export const evictAllTemplatesFromStore = createAction('EVICT_TEMPLATES_FROM_STORE');
export const evictAllTemplatesFromStoreReducer = (state: CoreEntityState<AccurityCoreEntity>) => {
  state.allIds = state.allIds.filter(id => String(id) !== 'template');
  delete state.byId['template'];
};

export const invalidateCoreEntityInStore = createAction<{ objectType: string, id: string, wasDeleted: boolean, versionInBackend?: number }>('INVALIDATE_CORE_ENTITY_IN_STORE');
export const invalidateCoreEntityInStoreReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<{ objectType: string, id: string, wasDeleted: boolean, versionInBackend?: number }>) => {
  if (state.type !== action.payload.objectType) {
    return;
  }

  const id = action.payload.id;

  if (!state.byId[id] || !action.payload.versionInBackend) {
    return;
  }

  const candidateVersion = action.payload.versionInBackend;
  const currentVersion = state.byId[id].meta.versionInBackend;
  if (currentVersion >= candidateVersion) {
    return; // only take more recent
  }

  state.byId[id].meta = {
    ...state.byId[id].meta,
    versionInBackend: candidateVersion,
    wasDeleted: action.payload.wasDeleted,
  };

};

export const setIsDetailLoading = createAction<boolean>('SET_IS_DETAIL_LOADING');
export const setIsDetailLoadingReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<boolean>) => {
  state.isDetailLoading = action.payload;
};

export const setIsListLoading = createAction<boolean>('SET_IS_LIST_LOADING');
export const setIsListLoadingReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<boolean>) => {
  state.isListLoading = action.payload;
};

export const setDetailOpenedAt = createAction<{ objectType: string, openedAt: number | undefined }>('SET_DETAIL_OPENED_AT');
export const setDetailOpenedAtReducer = (state: CoreEntityState<AccurityCoreEntity>, action: PayloadAction<{ objectType: string, openedAt: number | undefined }>) => {
  if (state.type !== action.payload.objectType) {
    return;
  }

  state.detailOpenedAt = action.payload.openedAt;
};

export const commonReducers = {
  [addCoreEntityToStore.type]: addCoreEntityToStoreReducer,
  [removeCoreEntityFromStore.type]: removeCoreEntityFromStoreReducer,
  [setListSearchResultInStore.type]: setListSearchResultInStoreReducer,
  [invalidateAllEntityTypesInStore.type]: invalidateAllEntityTypesInStoreReducer,
  [evictAllTemplatesFromStore.type]: evictAllTemplatesFromStoreReducer,
  [invalidateCoreEntityInStore.type]: invalidateCoreEntityInStoreReducer,
  [setIsDetailLoading.type]: setIsDetailLoadingReducer,
  [setIsListLoading.type]: setIsListLoadingReducer,
  [setDetailOpenedAt.type]: setDetailOpenedAtReducer,
  [setNotificationForCoreEntity.type]: setNotificationForCoreEntityReducer
};
