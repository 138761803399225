import React from 'react';
import AboutDetail from './AboutDetail';
import {AppSettingsState} from '../../appSettings/types/types';
import {useSelector} from 'react-redux';

interface AboutDetailContainerProps {
  applicationName: string;
}

const AboutDetailContainer = ({ applicationName }: AboutDetailContainerProps) => {
  const appSettings = useSelector((state: { appSettings: AppSettingsState }) => state.appSettings);

  return (
    <AboutDetail
      applicationVersion={appSettings.applicationVersion}
      applicationName={applicationName}
    />
  );
};

export default AboutDetailContainer;
