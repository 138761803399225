import React from 'react';
import { useField, useFormikContext } from 'formik';

import { DateField, Tooltip } from 'ts-components';

import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface DateFieldWithFormikProps {
  name: string;
  label: string;
  readOnly?: boolean;
  tooltip?: Tooltip;
  coloringOverwrite?: string;
  inherited?: boolean;
}

const DateFieldWithFormik = ({ name, label, readOnly, tooltip, coloringOverwrite, inherited }: DateFieldWithFormikProps) => {
  const [field, meta, ] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(coloringOverwrite ? coloringOverwrite : name);

  return (
    <DateField
      value={Number(field.value)}
      onChange={(value?: number) => {
        // Store `NaN` as string `'NaN'`
        // On Frontend the two are equivalent, but on Backend `NaN` is incorrectly converted to `null`, where as `'NaN'` correctly triggers validations
        const newValue = (value !== undefined && isNaN(value)) ? 'NaN' : value;
        formikContext.setFieldValue(name, newValue)
      }}
      errorMessage={meta.error}
      name={name}
      label={label}
      disabled={readOnly || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      tooltip={tooltip}
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default DateFieldWithFormik;
