import { ExportJobResultCommand, ImportExceptionCommand, ImportJobResultCommand } from '../../../types';
import { pushExportNotificationCenterNotification, pushImportNotificationCenterNotification } from '../../../../dataTransfer/actions';
import { requestDownloadExportFile } from '../../../../dataTransfer/api';
import { showErrorMessageFromResponse } from '../../../../userMessages/actions';

export const handleImportJobResultCommand = (importJobResultCommand: ImportJobResultCommand) => {
  const { importJobId, objectType, ...importResult } = importJobResultCommand;
  const importFinishDate = Date.now();

  pushImportNotificationCenterNotification({
    id: importJobId,
    objectType: objectType,
    date: importFinishDate,
    cannotDismiss: false,
    failedWithException: false,
    showDownloadReport: true,
    importResult: importResult
  });
};

export const handleImportExceptionCommand = (importExceptionCommand: ImportExceptionCommand) => {
  const { importJobId, objectType } = importExceptionCommand;
  const importExceptionDate = Date.now();

  pushImportNotificationCenterNotification({
    id: importJobId,
    objectType: objectType,
    date: importExceptionDate,
    cannotDismiss: false,
    failedWithException: true,
  });
};

export const handleExportJobResultCommand = (exportJobResultCommand: ExportJobResultCommand) => {
  const { exportJobId, objectType, ...exportResult } = exportJobResultCommand;
  const exportFinishedDate = Date.now();

  if (exportResult?.exportSuccess) {
    requestDownloadExportFile(objectType)
      .catch(showErrorMessageFromResponse);
  }

  pushExportNotificationCenterNotification({
    id: exportJobId,
    objectType: objectType,
    date: exportFinishedDate,
    cannotDismiss: false,
    showDownloadReport: true,
    exportResult: exportResult
  });
};
