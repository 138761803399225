import React from 'react';
import AccurityReferenceField from '../../referenceField/components/AccurityReferenceField';
import { AccurityReference } from '../../types/accurityTypes';
import { VersionBrowserColoring } from 'ts-components';
import { STATUS_TYPE } from '../../../businessGlossary/status/types/statusTypes';
import FeatureChecker from '../../userSettings/components/FeatureChecker';
import { Feature } from '../../userSettings/features/features';

interface StatusFieldProps {
  value?: AccurityReference;
  setValue: (newValue?: AccurityReference) => void;
  errorMessage?: string;
  readOnly?: boolean;
  coloring?: VersionBrowserColoring;
  inherited?: boolean;
}

const StatusField = ({ value, setValue, errorMessage, readOnly, coloring, inherited }: StatusFieldProps) => (
  <FeatureChecker featureId={Feature.STATUS_VALUES}>
    <AccurityReferenceField
      name={'status'}
      label={'Status'}
      icon={'status'}
      value={value}
      setValue={setValue}
      errorMessage={errorMessage}
      objectType={STATUS_TYPE}
      readOnly={readOnly}
      coloring={coloring}
      inherited={inherited}
    />
  </FeatureChecker>
);

export default StatusField;
