import { BUSINESS_TERM_LABEL } from '../businessTerm/types/businessTermTypes';
import { DATA_FIELD_LABEL } from '../dataField/types/dataFieldTypes';
import { DATA_SET_LABEL } from '../dataSet/types/dataSetTypes';
import { DATA_STRUCTURE_LABEL } from '../dataStructure/types/dataStructureTypes';
import { BUSINESS_RULE_LABEL } from '../businessRule/types/businessRuleTypes';
import { ENTITY_LABEL } from '../entity/types/entityTypes';
import { COMPOSITE_TYPE_LABEL } from '../compositeType/types/compositeTypeTypes';
import { ATTRIBUTE_DEFINITION_LABEL } from '../attributeDefinition/types/attributeDefinitionTypes';
import { ATTRIBUTE_LABEL } from '../attribute/types/attributeTypes';
import { REQUIREMENT_LABEL } from '../requirement/types/requirementTypes';
import { useHasFeature, useHasSomeOfTheFeatures } from '../../common/userSettings/hooks/features';
import { Feature } from '../../common/userSettings/features/features';
import { DATA_ASSET_LABEL } from '../dataAsset/types/dataAssetTypes';
import { BUSINESS_MODEL_MAPPING_LABEL } from '../businessModelMapping/types/businessModelMappingTypes';
import { BUSINESS_MODEL_DIAGRAM_LABEL } from '../businessModelDiagram/types/businessModelDiagramTypes';
import { USER_LABEL } from '../user/types/userTypes';
import { PROCESS_LABEL } from '../process/types/processTypes';
import { PROCESS_STEP_LABEL } from '../process/step/types/processStepTypes';
import { PROCESS_MAPPING_LABEL } from '../process/mapping/types/processMappingTypes';
import { TECHNICAL_DATA_MAPPING_LABEL } from '../technicalModelMapping/types/technicalDataMappingTypes';
import { PROCESS_DIAGRAM_LABEL } from '../processDiagram/types/processDiagramTypes';

export enum GlossaryCustomPropertyObjectType {
  BUSINESS_TERM = 'BUSINESS_TERM',
  BUSINESS_RULE = 'BUSINESS_RULE',
  ENTITY = 'ENTITY',
  COMPOSITE_TYPE = 'COMPOSITE_TYPE',
  ATTRIBUTE_DEFINITION = 'ATTRIBUTE_DEFINITION',
  ATTRIBUTE = 'ATTRIBUTE',
  DATA_SET = 'DATA_SET',
  DATA_FIELD = 'DATA_FIELD',
  TECHNICAL_DATA_MAPPING = 'TECHNICAL_DATA_MAPPING',
  DATA_STRUCTURE = 'DATA_STRUCTURE',
  REQUIREMENT = 'REQUIREMENT',
  DATA_ASSET = 'DATA_ASSET',
  BUSINESS_MODEL_MAPPING = 'BUSINESS_MODEL_MAPPING',
  BUSINESS_MODEL_DIAGRAM = 'BUSINESS_MODEL_DIAGRAM',
  PROCESS = 'PROCESS',
  PROCESS_DIAGRAM = 'PROCESS_DIAGRAM',
  PROCESS_STEP = 'PROCESS_STEP',
  PROCESS_MAPPING = 'PROCESS_MAPPING',
}

export const useBusinessGlossaryCustomPropertyTypes = () => {
  const hasFeature = useHasFeature();
  const hasSomeOfFeature = useHasSomeOfTheFeatures();

  return [
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [{ value: GlossaryCustomPropertyObjectType.BUSINESS_TERM, label: BUSINESS_TERM_LABEL },] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_DIAGRAMS) ?
      [{ value: GlossaryCustomPropertyObjectType.BUSINESS_MODEL_DIAGRAM, label: BUSINESS_MODEL_DIAGRAM_LABEL },] : []),
    ...(hasFeature(Feature.BUSINESS_RULES) ?
      [{ value: GlossaryCustomPropertyObjectType.BUSINESS_RULE, label: BUSINESS_RULE_LABEL },] : []),
    ...(hasFeature(Feature.ENTITIES) ?
      [{ value: GlossaryCustomPropertyObjectType.ENTITY, label: ENTITY_LABEL },] : []),
    ...(hasFeature(Feature.COMPOSITE_TYPES) ?
      [{ value: GlossaryCustomPropertyObjectType.COMPOSITE_TYPE, label: COMPOSITE_TYPE_LABEL },] : []),
    ...(hasFeature(Feature.ATTRIBUTE_DEFINITIONS) ?
      [{ value: GlossaryCustomPropertyObjectType.ATTRIBUTE_DEFINITION, label: ATTRIBUTE_DEFINITION_LABEL },] : []),
    ...(hasFeature(Feature.ATTRIBUTES) ?
      [{ value: GlossaryCustomPropertyObjectType.ATTRIBUTE, label: ATTRIBUTE_LABEL },] : []),
    ...(hasFeature(Feature.DATA_FIELDS) ?
      [{ value: GlossaryCustomPropertyObjectType.DATA_FIELD, label: DATA_FIELD_LABEL },] : []),
    ...(hasFeature(Feature.DATA_ASSETS) ?
      [{ value: GlossaryCustomPropertyObjectType.DATA_ASSET, label: DATA_ASSET_LABEL }] : []),
    ...(hasSomeOfFeature([Feature.LOGICAL_DATA_SETS,Feature.PHYSICAL_DATA_SETS]) ?
      [{ value: GlossaryCustomPropertyObjectType.DATA_SET, label: DATA_SET_LABEL }] :[]),
    ...(hasFeature(Feature.DATA_STRUCTURES) ?
      [{ value: GlossaryCustomPropertyObjectType.DATA_STRUCTURE, label: DATA_STRUCTURE_LABEL }] : []),
    ...(hasFeature(Feature.REQUIREMENTS) ?
      [{ value: GlossaryCustomPropertyObjectType.REQUIREMENT, label: REQUIREMENT_LABEL },] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [{ value: GlossaryCustomPropertyObjectType.BUSINESS_MODEL_MAPPING, label: BUSINESS_MODEL_MAPPING_LABEL },] : []),
    ...(hasFeature(Feature.PROCESSES) ?
      [{ value: GlossaryCustomPropertyObjectType.PROCESS, label: PROCESS_LABEL },] : []),
    ...(hasFeature(Feature.PROCESS_DIAGRAMS) ?
      [{ value: GlossaryCustomPropertyObjectType.PROCESS_DIAGRAM, label: PROCESS_DIAGRAM_LABEL },] : []),
    ...(hasFeature(Feature.PROCESS_STEPS) ?
      [{ value: GlossaryCustomPropertyObjectType.PROCESS_STEP, label: PROCESS_STEP_LABEL },] : []),
    ...(hasFeature(Feature.PROCESS_MAPPINGS) ?
      [{ value: GlossaryCustomPropertyObjectType.PROCESS_MAPPING, label: PROCESS_MAPPING_LABEL },] : []),
    ...(hasFeature(Feature.TECHNICAL_DATA_MAPPINGS) ?
      [{ value: GlossaryCustomPropertyObjectType.TECHNICAL_DATA_MAPPING, label: TECHNICAL_DATA_MAPPING_LABEL },] : [])
  ].sort(function (first, second) {
    const firstLabel = first.label.toUpperCase(); // ignore upper and lowercase
    const secondLabel = second.label.toUpperCase(); // ignore upper and lowercase
    if (firstLabel < secondLabel) {
      return -1;
    }
    if (firstLabel > secondLabel) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

export enum CustomPropertyTargetObjectType {
  ATTRIBUTE = 'ATTRIBUTE',
  ATTRIBUTE_DEFINITION = 'ATTRIBUTE_DEFINITION',
  BUSINESS_MODEL_DIAGRAM = 'BUSINESS_MODEL_DIAGRAM',
  BUSINESS_RULE = 'BUSINESS_RULE',
  BUSINESS_TERM = 'BUSINESS_TERM',
  DATA_ASSET = 'DATA_ASSET',
  DATA_SET = 'DATA_SET',
  DATA_STRUCTURE = 'DATA_STRUCTURE',
  DATA_FIELD = 'DATA_FIELD',
  ENTITY = 'ENTITY',
  PROCESS = 'PROCESS',
  PROCESS_STEP = 'PROCESS_STEP',
  PROCESS_DIAGRAM = 'PROCESS_DIAGRAM',
  REQUIREMENT = 'REQUIREMENT',
  USER = 'USER'
}


export const customPropertyTargetObjectTypesWithSupportiveColumns: CustomPropertyTargetObjectType[] = [
  CustomPropertyTargetObjectType.ATTRIBUTE,
  CustomPropertyTargetObjectType.DATA_FIELD,
  CustomPropertyTargetObjectType.DATA_STRUCTURE,
  CustomPropertyTargetObjectType.PROCESS_STEP
];

export const useBusinessGlossaryCustomPropertyTargetObjectTypes = (isList: boolean) => {
  const hasFeature = useHasFeature();
  const hasSomeOfFeature = useHasSomeOfTheFeatures();

  return [
    ...(isList ?
      [{ value: null, label: '' }] : []),
    ...(hasFeature(Feature.ATTRIBUTES) ?
      [{ value: CustomPropertyTargetObjectType.ATTRIBUTE, label: ATTRIBUTE_LABEL }] : []),
    ...(hasFeature(Feature.ATTRIBUTE_DEFINITIONS) ?
      [{ value: CustomPropertyTargetObjectType.ATTRIBUTE_DEFINITION, label: ATTRIBUTE_DEFINITION_LABEL }] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_DIAGRAMS) ?
      [{ value: CustomPropertyTargetObjectType.BUSINESS_MODEL_DIAGRAM, label: BUSINESS_MODEL_DIAGRAM_LABEL }] : []),
    ...(hasFeature(Feature.BUSINESS_RULES) ?
      [{ value: CustomPropertyTargetObjectType.BUSINESS_RULE, label: BUSINESS_RULE_LABEL }] : []),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [{ value: CustomPropertyTargetObjectType.BUSINESS_TERM, label: BUSINESS_TERM_LABEL }] : []),
    ...(hasFeature(Feature.DATA_ASSETS) ?
      [{ value: CustomPropertyTargetObjectType.DATA_ASSET, label: DATA_ASSET_LABEL }] : []),
    ...(hasSomeOfFeature([Feature.LOGICAL_DATA_SETS,Feature.PHYSICAL_DATA_SETS]) ?
      [{ value: CustomPropertyTargetObjectType.DATA_SET, label: DATA_SET_LABEL },] : []),
    ...(hasFeature(Feature.ENTITIES) ?
      [{ value: CustomPropertyTargetObjectType.ENTITY, label: ENTITY_LABEL },] : []),
    ...(hasFeature(Feature.PROCESSES) ?
      [{ value: CustomPropertyTargetObjectType.PROCESS, label: PROCESS_LABEL },] : []),
    ...(hasFeature(Feature.PROCESS_STEPS) ?
      [{ value: CustomPropertyTargetObjectType.PROCESS_STEP, label: PROCESS_STEP_LABEL },] : []),
    ...(hasFeature(Feature.PROCESS_DIAGRAMS) ?
      [{ value: CustomPropertyTargetObjectType.PROCESS_DIAGRAM, label: PROCESS_DIAGRAM_LABEL },] : []),
    ...(hasFeature(Feature.REQUIREMENTS) ?
      [{ value: CustomPropertyTargetObjectType.REQUIREMENT, label: REQUIREMENT_LABEL },] : []),
    ...(hasFeature(Feature.USERS_MANAGEMENT) ?
      [{ value: CustomPropertyTargetObjectType.USER, label: USER_LABEL },] : []),
    ...(hasFeature(Feature.DATA_STRUCTURES) ?
      [{ value: CustomPropertyTargetObjectType.DATA_STRUCTURE, label: DATA_STRUCTURE_LABEL },] : []),
    ...(hasFeature(Feature.DATA_FIELDS) ?
      [{ value: CustomPropertyTargetObjectType.DATA_FIELD, label: DATA_FIELD_LABEL },] : []),
  ].sort(function (first, second) {
    const firstLabel = first.label.toUpperCase(); // ignore upper and lowercase
    const secondLabel = second.label.toUpperCase(); // ignore upper and lowercase
    if (firstLabel < secondLabel) {
      return -1;
    }
    if (firstLabel > secondLabel) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};
