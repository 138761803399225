import React from 'react';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';
import { useFormikContext } from 'formik';
import { DataSource } from '../../types/dataSourceTypes';

const UsernamePasswordGroup = () => {
  const formik = useFormikContext<DataSource>();
  const isVersionBrowser = formik.status.isVersionBrowser;

  return (
    <>
      <SingleLineFieldWithFormik
        name={'dataSourceUsername'}
        label={'Data Source Username'}
      />
      {!isVersionBrowser &&
      <SingleLineFieldWithFormik
        name={'dataSourcePassword'}
        label={'Data Source Password'}
        type={'password'}
        showPasswordVisibilityButton={true}
      />}
    </>
  )
}

export default UsernamePasswordGroup;
