import { AccurityPinFilter } from '../../types/accurityTypes';
import { AppThunk } from '../../redux/types';
import { requestSaveGlobalPinFilters } from '../rest/userSettingsApi';
import { setGlobalPinFiltersInStore } from '../redux/userSettingsSlice';
import { uniqBy } from 'lodash';

export const globalPinFiltersUpdate = (pinFilters: AccurityPinFilter[] = []): AppThunk => async dispatch => {
  const distinctGlobalPinFilters = uniqBy<AccurityPinFilter>(pinFilters, pin => pin.property);
  requestSaveGlobalPinFilters(distinctGlobalPinFilters)
    .then(response => {
      dispatch(setGlobalPinFiltersInStore(distinctGlobalPinFilters))
    });
};
