import React, { useMemo } from 'react';
import { BUSINESS_TERM_REFERENCING_ICON, BUSINESS_TERM_TYPE, BUSINESS_TERMS_REFERENCING_LABEL, BUSINESS_TERMS_REFERENCING_TYPE, BusinessTerm } from '../types/businessTermTypes';
import { useReferencingListLogic } from './useReferencingListLogic';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import { useReferencingBusinessTermListColumns } from './referencingBusinessTermListColumns';
import { CustomListSearchSteps } from '../../../common/list/actions/listSearch';
import { getListSearchReferencingEndpoint } from '../../../common/rest/endpoints';

interface ReferencingBusinessTermListProps {
  id: string;
}

const ReferencingBusinessTermList = ({ id }: ReferencingBusinessTermListProps) => {
  const businessTerm = useReferencingListLogic<BusinessTerm>(BUSINESS_TERM_TYPE, id);

  const referencingBusinessTermColumns = useReferencingBusinessTermListColumns();
  const referencingBusinessTermListSettings = getDefaultListSettings(BUSINESS_TERMS_REFERENCING_TYPE, referencingBusinessTermColumns);

  const customListSearchSteps: CustomListSearchSteps = useMemo(() => ({
    getListSearchEndpoint: (objectType: string) => {
      return getListSearchReferencingEndpoint(objectType, id);
    }
  }), [id]);

  return (
    <AccurityListContainer
      objectType={BUSINESS_TERM_TYPE}
      title={BUSINESS_TERMS_REFERENCING_LABEL + ' ' + (businessTerm?.name || '')}
      icon={BUSINESS_TERM_REFERENCING_ICON}
      columns={referencingBusinessTermColumns}
      defaultListSettings={referencingBusinessTermListSettings}
      customListSearchSteps={customListSearchSteps}
      hideAddButton={true}
      hideObjectCounter={true}
      allowImport={false}
      allowExport={false}
    />
  );
}

export default ReferencingBusinessTermList;
