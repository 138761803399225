import React, { useEffect, useState } from 'react';

import { FormikContext, FormikProps } from 'formik';
import { AccurityDetail as AccurityDetailComponent } from 'ts-components';
import { CoreEntityMeta } from '../../redux/types';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useAccurityFormik } from '../formik/accurityFormikHook';
import { showSnackbarMessage } from '../../userMessages/actions';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, SetFieldError } from '../types/types';
import { useAccurityNavigation } from '../../navigation/hooks';
import {
  getEntityYouAreEditingWasUpdatedMessage,
  getEntityYouAreViewingWasDeletedMessage,
  getEntityYouAreViewingWasUpdatedMessage,
  getFinishedReloadMessage,
  getNoChangesMadeMessage
} from '../../userMessages/commonMessages';
import { useDispatch } from 'react-redux';
import { setIsDetailLoading } from '../../redux/commonReducers';

export const MIN_DETAIL_WIDTH = 440;
export const MAX_DETAIL_WIDTH = 880;

interface AccurityDetailProps<T extends AccurityCoreEntity> {
  entity: T,
  entityMeta: CoreEntityMeta,
  isLoading?: boolean,
  doSave: (entity: T, setFieldError: SetFieldError) => void;
  doReload: (successSnackbar?: (entity: AccurityCoreEntity) => string) => void;
  doRemove: (initialEntity: T, entity: T) => void;
  savedWidth?: number;
  doSaveWidth: (width: number) => void;
  isEmbedded?: boolean;
  customHandleExternalUpdate?: (defaultHandleExternalUpdate: () => void, detailEntity: T) => void;
  children?: (formik: FormikProps<T>, detailBag: DetailBag<T>) => React.ReactNode;
}

const AccurityDetail = <T extends AccurityCoreEntity>({
                                                        entity,
                                                        entityMeta,
                                                        isLoading,
                                                        doSave,
                                                        doReload,
                                                        doRemove,
                                                        savedWidth,
                                                        doSaveWidth,
                                                        isEmbedded,
                                                        customHandleExternalUpdate,
                                                        children,
                                                      }: AccurityDetailProps<T>) => {
  const [width, setWidth] = useState<number>(savedWidth ?? MIN_DETAIL_WIDTH);
  const saveWidth = () => {
    doSaveWidth(width);
  };

  const dispatch = useDispatch();
  const [versionInDetail] = useState(entityMeta.versionInStore);
  const navigationController = useAccurityNavigation();
  const formik = useAccurityFormik<T>(entity, doSave, isEmbedded);

  // Effect called to handle when Entity in detail is changed by another User
  useEffect(() => {
    if (!formik.status.isUpdateDetail || formik.status.isEntityInDetailDeleted) {
      // If it is a Create or Copy detail we do not care about other Users. If we already flagged Entity as deleted we do not need to do anything else.
      return;
    }

    // Entity deleted by other User
    if (entityMeta.wasDeleted) {
      showSnackbarMessage(getEntityYouAreViewingWasDeletedMessage(formik.initialValues));
      formik.setStatus({
        ...formik.status,
        isEntityInDetailDeleted: true,
      });
      return;
    }

    if (isLoading || formik.status.isEntityInDetailOutdated || versionInDetail >= entityMeta.versionInBackend) {
      // If we are loading or if the entity is not outdated or if we already flagged the entity as outdated, we do not need to do anything else
      return;
    }

    // Entity updated by other User
    const handleOutdatedDetail = () => {
      if (formik.dirty) {
        // If User already started editing form, show snackbar and flag detail as out of date to prevent save
        showSnackbarMessage(getEntityYouAreEditingWasUpdatedMessage(formik.initialValues));
        formik.setStatus({
          ...formik.status,
          isEntityInDetailOutdated: true,
        });
      } else {
        // Else reload newest version from backend
        doReload(getEntityYouAreViewingWasUpdatedMessage);
      }
    }

    customHandleExternalUpdate ? customHandleExternalUpdate(handleOutdatedDetail, formik.values) : handleOutdatedDetail();
  }, [formik, entityMeta.versionInBackend, entityMeta.wasDeleted, isLoading, versionInDetail, doReload, customHandleExternalUpdate]);

  const saveAction = (fieldOverwrites?: { [fieldName: string]: any }) => {
    if (formik.dirty || !formik.initialStatus.isUpdateDetail) {
      formik.setValues({
        ...formik.values,
        ...fieldOverwrites,
      });
      formik.handleSubmit();
    } else {
      showSnackbarMessage(getNoChangesMadeMessage());
    }
  };

  const copyAction = (copyFieldOverwrites?: { [fieldName: string]: any }) => {
    formik.resetForm({
      ...formik,
      values: {
        ...formik.initialValues,
        name: `Copy - ${formik.initialValues.name}`,
        id: undefined,
        ...copyFieldOverwrites,
      },
      status: {
        ...formik.initialStatus,
        isUpdateDetail: false,
      }
    });
    navigationController.removeDetailIdWithoutReloading()
  };

  const removeAction = (fieldOverwrites?: { [fieldName: string]: any }) => {
    if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
      doRemove(
        formik.initialValues,
        {
          ...formik.values,
          ...fieldOverwrites,
        }
      );
    } else {
      setSettingsDetail(CONFIRM_REMOVE_SETTINGS_DETAIL);
    }
  };

  const setActiveEmbeddedDetail = (activeEmbeddedDetail: string | undefined) => {
    formik.setStatus({
      ...formik.status,
      activeEmbeddedDetail: activeEmbeddedDetail,
      isDisabled: activeEmbeddedDetail !== undefined,
    });
  }

  const setSettingsDetail = (settingsDetail: string | undefined) => {
    navigationController.closeCommentsDrawer();
    formik.setStatus({
      ...formik.status,
      settingsDetail: settingsDetail,
    });
  }

  const detailBag: DetailBag<T> = {
    isLoading: isLoading,
    isReloadDisabled: formik.status.isEntityInDetailDeleted || formik.status.isDisabled,
    isRemoveDisabled: formik.status.isEntityInDetailDeleted || formik.status.isEntityInDetailOutdated || formik.status.isDisabled,
    isSaveDisabled: isLoading || formik.status.isEntityInDetailOutdated || formik.status.isEntityInDetailDeleted || formik.status.isDisabled,
    showCopyAction: formik.status.isUpdateDetail && formik.status.permissions.hasCreatePermission,
    showRemoveAction: formik.status.isUpdateDetail && formik.status.permissions.hasDeletePermission,
    showReloadAction: formik.status.isUpdateDetail,
    showSaveAction: formik.status.permissions.hasCreatePermission || (formik.status.isUpdateDetail && formik.status.permissions.hasUpdatePermission),
    saveAction: saveAction,
    copyAction: copyAction,
    reloadAction: () => doReload(getFinishedReloadMessage),
    removeAction: removeAction,
    setActiveEmbeddedDetail: setActiveEmbeddedDetail,
    setSettingsDetail: setSettingsDetail,
    setIsDetailLoading: loading => dispatch(setIsDetailLoading(loading))
  };

  const detailContent = (
    <FormikContext.Provider value={formik}>
      {formik.values && children ? children(formik, detailBag) : null}
    </FormikContext.Provider>
  );

  if (isEmbedded) {
    return (
      <>
        {detailContent}
      </>
    );
  } else {
    return (
      <>
        <AccurityDetailComponent
          key={1}
          width={width}
          setWidth={setWidth}
          onStopResize={saveWidth}
          minWidth={MIN_DETAIL_WIDTH}
          maxWidth={MAX_DETAIL_WIDTH}
          showProgressIndicator={isLoading}
          inverted={formik.status.settingsDetail !== undefined}
        >
          {detailContent}
        </AccurityDetailComponent>
      </>
    );
  }

};

export default AccurityDetail;
