import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserMessagesState } from '../types/userMessagesTypes';
import { UserMessageItem } from 'ts-components';

const initialState: UserMessagesState = {
  toasts: [],
  snackbarMessage: '',
};

const userMessagesSlice = createSlice({
  name: 'user-message',
  initialState: initialState,
  reducers: {

    showSnackbar: (state: UserMessagesState, action: PayloadAction<string>) => {
      state.snackbarMessage = action.payload;
    },

    dismissSnackbar: (state: UserMessagesState) => {
      state.snackbarMessage = '';
    },

    showToast: (state: UserMessagesState, action: PayloadAction<{ title: string, message: string }>) => {
      const newId = Math.max(0, ...state.toasts.map(t => t.id)) + 1;
      state.toasts.push({
        id: newId,
        ...action.payload
      });
    },

    dismissToast: (state: UserMessagesState, action: PayloadAction<UserMessageItem>) => {
      state.toasts = state.toasts.filter(t => t.id !== action.payload.id);
    },

    dismissAllToasts: (state: UserMessagesState) => {
      state.toasts = [];
    },
  },
});

export const {
  showSnackbar,
  dismissSnackbar,
  showToast,
  dismissToast,
  dismissAllToasts
} = userMessagesSlice.actions;

export default userMessagesSlice.reducer;
