import React, { useState } from 'react';
import headerLogo from '../images/accurity-logo.svg';
import AccurityNavigation from '../../common/navigation/AccurityNavigation';
import AccurityNotificationCenter from '../../common/notificationCenter/AccurityNotificationCenter';
import { AccurityIconButton } from 'ts-components';
import BusinessGlossaryMainMenu from './BusinessGlossaryMainMenu';
import { useAreUserSettingsLoaded } from '../../common/userSettings/hooks/userSettingsHooks';

interface BusinessGlossaryNavigationProps {
  menuHiddenWhenCollapsed?: boolean;
  searchHidden?: boolean;
  children: React.ReactNode;
}

const USER_GUIDE_URL = window.location.origin + '/doc/user-guide/index.html';

const appBarItems = (
  <>
    <AccurityNotificationCenter/>
    <AccurityIconButton
      name="userGuide"
      iconName="mui:HelpOutline"
      title="Open user guide"
      onClick={() => window.open(USER_GUIDE_URL, '_blank')}
    />
  </>
);

const BusinessGlossaryNavigation = ({ children }: BusinessGlossaryNavigationProps) => {
  const [isMenuExpanded, setMenuExpanded] = useState<boolean>(false);
  const toggleMenuExpanded = () => setMenuExpanded(!isMenuExpanded);
  const areUserSettingsLoaded = useAreUserSettingsLoaded();

  // we need user settings (roles, etc) everywhere, so wait here until they are loaded
  if (!areUserSettingsLoaded) {
    return null;
  }

  const mainMenu =
    <BusinessGlossaryMainMenu
      expanded={isMenuExpanded}
    />;

  return (
    <AccurityNavigation
      headerLogo={headerLogo}
      appBarItems={appBarItems}
      mainMenu={mainMenu}
      toggleMainMenuExpanded={toggleMenuExpanded}
      isMenuExpanded={isMenuExpanded}
    >
      {children}
    </AccurityNavigation>
  );
};

export default BusinessGlossaryNavigation; 
