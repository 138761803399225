import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { Tag } from 'ts-components';
import { CoreEntityState } from '../../../common/redux/types';

export const REQUIREMENT_TYPE = 'requirement';
export const REQUIREMENT_LABEL = 'Requirement';
export const REQUIREMENT_PLURAL_LABEL = 'Requirements';
export const REQUIREMENT_ICON = 'requirement';
export const REQUIREMENT_DESCRIPTION = 'Requirement Description';

export interface RequirementState extends CoreEntityState<Requirement> {
}

export interface Requirement extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}