import { LogoutNotification } from '../../types';
import { showToast } from '../../../userMessages/redux/userMessagesSlice';
import rootStore from '../../../redux/rootStore';
import { logout } from '../../../userSettings/hooks/logout';

export const handleLogoutNotification = (logoutNotification: LogoutNotification) => {
  rootStore.dispatch(showToast({
    title: logoutNotification.header,
    message: logoutNotification.body
  }));
  logout();
};
