const detailUrlPart = '/detail/:detailType/:detailId?/:comments(comments)?';
const listUrlPart = '/list/:listType';
const searchUrlPart = '/search/:searchTerm';
const tagListUrlPart = '/tagged-entities/:tagName';
const referencingListUrlPart = '/referencing-list/:listType/:listId';
const diagramUrlPart = '/diagram/:diagramType/:diagramId/:diagramEntityType?';
const dataProfilingUrlPart = '/data-field-profiling/:dataFieldProfilingId';

export const routes = {
  welcomeScreenPath: '/',
  mainScreenPaths: [
    detailUrlPart,
  ],
};

const permanentLists = [listUrlPart, searchUrlPart, tagListUrlPart, referencingListUrlPart];

// add paths for list, overlay and detail, and combinations of them
permanentLists.forEach(permanentListUrl => {
  // list
  routes.mainScreenPaths.push(permanentListUrl);

  // list + overlay
  routes.mainScreenPaths.push(permanentListUrl + diagramUrlPart);
  routes.mainScreenPaths.push(permanentListUrl + dataProfilingUrlPart);

  // list + overlay + detail
  routes.mainScreenPaths.push(permanentListUrl + detailUrlPart);
  routes.mainScreenPaths.push(permanentListUrl + diagramUrlPart + detailUrlPart);
  routes.mainScreenPaths.push(permanentListUrl + dataProfilingUrlPart + detailUrlPart);
});
