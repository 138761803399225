import React from 'react';
import { ChangesType, DataCatalogSyncResultField, getVersionBrowserColoringClass } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useFormikContext } from 'formik';
import { DataSet, DataSetType, isDataSet } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import { DataStructure, isDataStructure } from '../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DataField } from '../../../businessGlossary/dataField/types/dataFieldTypes';
import { DataCatalogSyncStatus, dataCatalogSyncStatusOptions } from '../types';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

const getDataSetType = (object: DataSet | DataStructure | DataField) => {
  if (isDataSet(object)) {
    return object.dataSetType;
  } else if (isDataStructure(object)) {
    return object.dataSet.dataSetType;
  } else {
    return object.dataSet.dataSetType;
  }
};

const getResultString = (status: DataCatalogSyncStatus) => {
  return dataCatalogSyncStatusOptions.find(q => q.value === status)?.label ?? '';
};

export const DataSyncResultField = () => {

  const navigation = useAccurityNavigation();
  const formik = useFormikContext<DataSet | DataStructure | DataField>();
  const entity = formik.values;
  const coloring = useVersionBrowserColoring('syncResult');
  const isVersionBrowser = formik.status.isVersionBrowser;

  // Check if we can show results for current object
  if (
    !formik.status.isUpdateDetail ||
    !entity ||
    getDataSetType(entity) !== DataSetType.PHYSICAL ||
    !entity.syncResult ||
    !entity.syncResult.syncRun.triggeredDate
  ) {
    return null;
  }

  const { syncResult } = entity;

  let dataFieldChanges: ChangesType | undefined;
  if (isDataSet(entity) || isDataStructure(entity)) {
    dataFieldChanges = {
      created: syncResult.dataFieldCreatedCount,
      obsoleted: syncResult.dataFieldObsoletedCount,
      outdated: syncResult.dataFieldOutdatedCount,
      updated: syncResult.dataFieldUpdatedCount
    };
  }

  let dataStructureChanges: ChangesType | undefined;
  if (isDataSet(entity)) {
    dataStructureChanges = {
      created: syncResult.dataStructureCreatedCount,
      obsoleted: syncResult.dataStructureObsoletedCount,
      outdated: syncResult.dataStructureOutdatedCount,
      updated: syncResult.dataStructureUpdatedCount
    };
  }

  return (
    <div className={getVersionBrowserColoringClass(coloring)}>
      <DataCatalogSyncResultField
        title={'Last Synchronization Results'}
        lastUpdateDate={syncResult.syncRun.triggeredDate}
        result={getResultString(syncResult.status)}
        triggeredFrom={syncResult.syncRun.triggeredFrom}
        triggeredBy={syncResult.syncRun.triggeredBy}
        triggeredFromOnClick={!isVersionBrowser ? ((triggeredFrom) => navigation.openDetailWithObject(triggeredFrom.objectType, triggeredFrom.id)) : undefined}
        dataFieldsChanges={dataFieldChanges}
        dataStructuresChanges={dataStructureChanges}
      />
    </div>
  );
};
