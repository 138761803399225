import React, { useEffect, useRef } from 'react';
import { TaggedEntity } from 'ts-components';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import { ListSearchResult } from '../../list/types/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';

const mapRelatedObjectName = (entity: TaggedEntity): TaggedEntity => {
  const name = entity.relatedObjectId && entity.relatedObjectType
    ? entity.relatedObjectName + '.' + entity.name
    : entity.name;

  return {
    ...entity,
    name: name
  }
};

export type CardListFetchFunction = (searchValue: string, startFrom: number, maxResults: number) => Promise<ListSearchResult<TaggedEntity>>;

export const useCardListFetchData = (fetchFn: CardListFetchFunction, searchValue: string) => {

  const pageSize = 24;
  const previousSearchValue = useRef(searchValue);
  const [startFrom, setStartsFrom] = React.useState(0);
  const [rows, setRows] = React.useState<TaggedEntity[]>([]);
  const [existingObjects, setExistingObjects] = React.useState(0);
  const [lastDataLoadTime, setLastDataLoadTime] = React.useState<number>();

  const didInvalidateListSearch = useSelector((state: RootState) => {
    // we are only interested in object types which are already loaded on card list
    const loadedObjectTypes = rows.map(q => q.objectType);
    return lastDataLoadTime && Object.keys(state)
      // we go through each store for each object type which is loaded on card list
      .filter(storeKey => loadedObjectTypes.includes(storeKey))
      // just to ensure the store contains needed data
      .filter(storeKey => !!state[storeKey].invalidatedAt)
      .map(storeKey => state[storeKey].invalidatedAt)
      // asking if there is at least one store with newer data
      .some(invalidationDate => invalidationDate > lastDataLoadTime);
  });

  useEffect(() => {
    if (didInvalidateListSearch) {
      fetchFn(searchValue, 0, startFrom + pageSize)
        .then(response => {
          setExistingObjects(response.existingObjects);
          setRows(response.rows);
          setLastDataLoadTime(Date.now());
        })
        .catch(showErrorMessageFromResponse);
    }
  }, [didInvalidateListSearch, fetchFn, searchValue, startFrom]);

  useEffect(() => {

    if (searchValue !== previousSearchValue.current) {
      previousSearchValue.current = searchValue;
      setRows([]);
      setExistingObjects(0);
      setStartsFrom(0);
    }

    fetchFn(searchValue, startFrom, pageSize)
      .then(response => {
        setExistingObjects(response.existingObjects);
        setRows(q => [...q, ...response.rows]);
        setLastDataLoadTime(Date.now());
      })
      .catch(showErrorMessageFromResponse);
  }, [fetchFn, searchValue, startFrom]);

  return {
    rows: rows.map(mapRelatedObjectName),
    existingObjects: existingObjects,
    loadMore: () => setStartsFrom(startFrom + pageSize),
  }
};
