import React from 'react';
import { ChipReference, RichTextEditor, RichTextEditorValue } from 'ts-components';
import { useField, useFormikContext } from 'formik';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';
import { AccurityReference } from '../../types/accurityTypes';
import { useAccurityNavigation } from '../../navigation/hooks';
import OpenChipListControl from '../commonFields/OpenChipListControl';
import AddChipControl from '../commonFields/AddChipControl';

interface RichTextEditorFieldWithFormikProps {
  name: string;
  label: string;
  readOnly?: boolean;
  inherited?: boolean;
  allowedChipTypes?: string[];
  chipsCollectionName?: string;
  onChipCollectionChange?: (previousChipCollection: ChipReference[], newChipCollection: ChipReference[]) => void;
}

const RichTextEditorFieldWithFormik = ({
                                         name,
                                         label,
                                         readOnly,
                                         inherited,
                                         allowedChipTypes,
                                         chipsCollectionName = 'chips',
                                         onChipCollectionChange
                                       }: RichTextEditorFieldWithFormikProps) => {
  const navigationController = useAccurityNavigation();
  const [field, , helper] = useField<RichTextEditorValue>(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(name);

  const [chipsField, , chipsHelper] = useField<AccurityReference[]>(chipsCollectionName);
  const isUsedWithinVersionBrowser = !!coloring;

  const openDetailOfChip = (reference?: AccurityReference) => {
    if (reference) {
      navigationController.openDetailWithObject(reference.objectType, reference.id);
    }
  }

  const chipControls: JSX.Element[] = [];
  if (allowedChipTypes) {
    chipControls.push(<AddChipControl chipObjectTypes={allowedChipTypes}/>)
    allowedChipTypes.forEach(allowedChipType => chipControls.push(
      <OpenChipListControl chipObjectType={allowedChipType}/>)
    );
  }

  const handleReferenceCollectionChange = (newReferenceCollection: ChipReference[]) => {
    onChipCollectionChange?.(chipsField.value, newReferenceCollection);
    chipsHelper.setValue(newReferenceCollection);
  }

  return (
    <RichTextEditor
      label={label}
      value={field.value}
      setValue={helper.setValue}
      referenceCollection={!!allowedChipTypes ? chipsField.value : undefined}
      setReferenceCollection={!!allowedChipTypes ? handleReferenceCollectionChange : undefined}
      chipReferenceTypes={!!allowedChipTypes ? allowedChipTypes : undefined}
      onChipClick={!!allowedChipTypes && !isUsedWithinVersionBrowser ? openDetailOfChip : undefined}
      customControls={chipControls}
      disabled={readOnly || formikContext?.status?.isDisabled || !formikContext.status.permissions.hasUpdatePermission}
      coloring={coloring}
      inherited={inherited}
    />
  );
};

export default RichTextEditorFieldWithFormik;
