import React from 'react';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import EntityAttribute from './EntityAttribute';
import TargetDataField from './TargetDataField';
import {
  BUSINESS_MODEL_MAPPING_BASE_LABEL,
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL,
  BusinessModelMapping
} from '../../types/businessModelMappingTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps } from 'formik';
import { CollapsingGroup, DetailInlineGroup, EnumOption } from 'ts-components';
import { DATA_FIELD_ICON } from '../../../dataField/types/dataFieldTypes';
import { getBusinessModelMappingCountLabel, resetValuesOnTargetDataSetChange } from '../businessModelMappingDetailUtils';
import DataStructureDataFieldSelection from '../misc/DataStructureDataFieldSelection';
import DataStructureDataFieldJoin from '../misc/DataStructureDataFieldJoin';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';

interface BasedOnAttributeProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const BasedOnAttribute = ({
                            additionalFilters = [],
                            formik,
                            multipleDataSetTypesAllowedFeature,
                            dataSetTypeEnumOptions
                          }: BasedOnAttributeProps) => {

  const dataStructureDataFields = formik.getFieldProps(BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD).value;
  const selections = formik.getFieldProps(BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD).value;
  const isVersionBrowser = formik.status.isVersionBrowser;

  return (
    <>
      <CollapsingGroup
        name={'base'}
        title={BUSINESS_MODEL_MAPPING_BASE_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <EntityAttribute
          additionalFilters={additionalFilters}
          formik={formik}
        />
      </CollapsingGroup>
      <CollapsingGroup
        name={BUSINESS_MODEL_MAPPING_TARGET_FIELD}
        title={BUSINESS_MODEL_MAPPING_TARGET_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        {formik.values.businessModelMappingType &&
        <DetailInlineGroup
          childGridSizes={[8, 4]}
        >
          <ReferenceFieldWithFormik
            name={BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD}
            label={DATA_SET_LABEL}
            objectType={DATA_SET_TYPE}
            icon={DATA_SET_ICON}
            setValue={(newValue) => {
              resetValuesOnTargetDataSetChange(formik, false);
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD, newValue);
            }}
          />
          {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
            name={'targetDataSet.dataSetType'}
            label={DATA_SET_TYPE_LABEL}
            options={dataSetTypeEnumOptions}
            readOnly={true}
          />}
        </DetailInlineGroup>}
        {formik.values.targetDataSet && <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD}
          title={getBusinessModelMappingCountLabel(dataStructureDataFields,
            BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_DATA_FIELD_PLURAL_LABEL)}
          icon={DATA_FIELD_ICON}
          rowValidation={(value) => value && value.dataField && value.dataField.id}
          renderRow={(elementName, index) =>
            <TargetDataField
              elementName={elementName}
              index={index}
              additionalFilters={additionalFilters}
              formik={formik}
            />
          }
        />}
        {formik.values.targetDataSet && <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD}
          title={getBusinessModelMappingCountLabel(selections,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL)}
          icon={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON}
          expandedByDefault={isVersionBrowser}
          rowValidation={(value) => value &&
            value.dataStructure && value.dataStructure.id &&
            value.dataField && value.dataField.id &&
            value.criteria
          }
          renderRow={(elementName, index) =>
            <DataStructureDataFieldSelection
              elementName={elementName}
              index={index}
              formik={formik}
            />
          }
        />}
        {formik.values.targetDataSet && <DataStructureDataFieldJoin
          additionalFilters={additionalFilters}
          formik={formik}
        />}
      </CollapsingGroup>
    </>
  )
};

export default BasedOnAttribute;
