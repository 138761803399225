import { useCallback, useEffect, useState } from 'react';
import { AccurityComment } from 'ts-components';
import { uniqBy } from 'lodash';
import { WebSocketCrudObserver } from '../../websocket/handlers/crud/websocketCrudObserver';
import { COMMENT_TYPE } from '../types/types';
import { WebsocketMessageType } from '../../websocket/types';
import { CreateCommentParams, createCommentRequest, deleteCommentRequest, getCommentByIdRequest, getCommentsRequest, updateCommentRequest } from '../rest/commentsApi';

export const useCommentData = (objectType: string, objectId: number) => {

  const [comments, setComments] = useState<AccurityComment[]>([]);
  const [startFrom, setStartFrom] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const isCommentForThisObject = useCallback((comment: AccurityComment) =>
    comment.referencedObjectId === objectId && comment.referencedObjectType === objectType
  , [objectId, objectType]);

  const addComment = useCallback((comment: AccurityComment): AccurityComment => {
    if (comment && isCommentForThisObject(comment) && !comments.find(q => q.id === comment.id)) {
      setComments([...comments, comment]);
    }
    return comment;
  }, [isCommentForThisObject, comments]);

  const updateComment = useCallback((comment: AccurityComment): AccurityComment => {
    if (comment && isCommentForThisObject(comment) && comments.find(q => q.id === comment.id)) {
      const newComments = comments.map(q => q.id === comment.id ? comment : q);
      setComments(newComments);
    }
    return comment;
  }, [isCommentForThisObject, comments]);

  useEffect(() => {
    const unsubscribe = WebSocketCrudObserver.getInstance().subscribeForObjectType(COMMENT_TYPE, (command) => {
      const commentId = parseInt(command.id, 10);
      switch (command.command) {
        case WebsocketMessageType.CreateCommand:
          getCommentByIdRequest(objectType, commentId).then(addComment);
          break;
        case WebsocketMessageType.DeleteCommand:
        case WebsocketMessageType.UpdateCommand:
          getCommentByIdRequest(objectType, commentId).then(updateComment);
          break;
      }
    });
    return () => unsubscribe();
  }, [comments, objectType, addComment, updateComment]);

  useEffect(() => {
    setIsLoading(true);
    getCommentsRequest(objectType, objectId, startFrom).then(response => {
      setComments(prevComments => {
        const newComments = [...response.rows, ...prevComments];
        return uniqBy(newComments, q => q.id);
      });
      setTotalComments(response.existingObjects);
      setIsLoading(false);
    });
  }, [objectType, objectId, startFrom]);

  const handleCreate = (text: string): Promise<AccurityComment> => {
    const createDto: CreateCommentParams = {
      referencedObjectId: objectId,
      referencedObjectType: objectType,
      text
    };

    return createCommentRequest(createDto).then(addComment);
  };

  const handleDelete = (comment: AccurityComment): Promise<AccurityComment> => {
    return deleteCommentRequest(comment).then(updateComment);
  };

  const handleUpdate = (comment: AccurityComment): Promise<AccurityComment> => {
    return updateCommentRequest(comment).then(updateComment);
  };

  const loadMore = () => {
    setStartFrom(startFrom + 10);
  };

  return {
    comments,
    isLoading,
    totalComments,
    handleCreate,
    handleUpdate,
    handleDelete,
    loadMore
  }
};
