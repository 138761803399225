import { AccurityPinFilter } from '../../types/accurityTypes';
import { ListSettings } from '../../list/types/types';
import { getInstanceInfoEndpoint, getUserPreferencesEndpoint, getUserSettingsEndpoint } from '../../rest/endpoints';
import { doFetch } from '../../rest/FetchService';
import { ApplicationStatus, GLOBAL_PIN_FILTER_KEY, InstanceInfoRequest, InstanceInfoResponse, LIST_SETTINGS_KEY_PREFIX } from '../types/types';
import { showErrorMessageFromResponse } from '../../userMessages/actions';
import rootStore from '../../redux/rootStore';
import { setApplicationStatusInStore } from '../../appSettings/redux/appSettingsSlice';

export const requestSaveUserSetting = (key: string, value: any) => {
  const userSettingsEndpoint = getUserPreferencesEndpoint(key);
  const method = 'POST';
  const body = JSON.stringify(value);

  return doFetch(userSettingsEndpoint, method, body)
    .catch(showErrorMessageFromResponse);
};

export const requestSaveListSettings = (listSettings: ListSettings): Promise<ListSettings> => {
  const listSettingsKey = `${LIST_SETTINGS_KEY_PREFIX}-${listSettings.type}`;
  return requestSaveUserSetting(listSettingsKey, listSettings);
};

export const requestSaveGlobalPinFilters = (globalPinFilters: AccurityPinFilter[]) => {
  return requestSaveUserSetting(GLOBAL_PIN_FILTER_KEY, globalPinFilters)
    .catch(showErrorMessageFromResponse);
};

export const requestUserSettingsFetch = () => {
  const userSettingsEndpoint = getUserSettingsEndpoint();
  const method = 'GET';

  return doFetch(userSettingsEndpoint, method, undefined)
    .catch(showErrorMessageFromResponse);
};

export const requestInstanceInfoFetch = (subject?: string): Promise<ApplicationStatus> => {
  if (!subject) {
    return Promise.reject('Email must be set in order to call instance info request');
  }

  const instanceInfoEndpoint = getInstanceInfoEndpoint();
  const method = 'POST';
  const body: InstanceInfoRequest = {
    subject: subject
  };

  return doFetch(instanceInfoEndpoint, method, JSON.stringify(body), { unauthorizedRequest: true })
    .then((response: InstanceInfoResponse) => {
      if (response.statusResponse.available && response.statusResponse.state === 'RUNNING') {
        return ApplicationStatus.RUNNING;
      } else if (response.statusResponse.state === 'FAILED') {
        return ApplicationStatus.FAILED;
      } else {
        return ApplicationStatus.STARTING
      }
    });
};

const loadInstanceInfo = (subject?: string) => {
  return requestInstanceInfoFetch(subject)
    .then(response => {
      rootStore.dispatch(setApplicationStatusInStore(response));
      if (response === ApplicationStatus.RUNNING) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    });
};

const repeatDelay = 5000;

export const loadInstanceInfoRepeatedly = (subject?: string) => {

  let interval: any;
  let maxTries = 60; // 5 minutes of trying

  return new Promise((resolve, reject) => {

    const success = () => {
      clearInterval(interval);
      resolve('');
    };

    const fail = () => {
      rootStore.dispatch(setApplicationStatusInStore(ApplicationStatus.FAILED));
      clearInterval(interval);
      reject();
    };

    interval = setInterval(() => {
      // we reached limit of tries, so let's reject the promise and do not try again
      if (maxTries <= 0) {
        fail();
      } else {
        maxTries--;
        loadInstanceInfo(subject).then(() => success());
      }
    }, repeatDelay);

    loadInstanceInfo(subject).then(() => success());
  });
};
