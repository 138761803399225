import { AppThunk } from '../../redux/types';
import { CustomLogo, CustomLogoRequest } from '../types/types';
import { setCustomLogoInStore } from '../redux/appSettingsSlice';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../userMessages/actions';
import { requestGetCustomLogo, requestUpdateCustomLogo } from '../rest/appSettingsApi';
import { getApplicationSettingsChangeSuccessMessage } from '../../userMessages/commonMessages';

export const customLogoUpdate = (customLogo: CustomLogoRequest,
                                 setIsLoading: (isLoading: boolean) => void
): AppThunk => async dispatch => {

  setIsLoading(true);
  requestUpdateCustomLogo(customLogo)
    .then((updatedCustomLogo: CustomLogo) => {
      dispatch(setCustomLogoInStore(updatedCustomLogo));
      showSnackbarMessage(getApplicationSettingsChangeSuccessMessage());
    })
    .catch(showErrorMessageFromResponse)
    .finally(() =>
      setIsLoading(false)
    );
};

export const reloadCustomLogo = (): AppThunk => async dispatch => {
  requestGetCustomLogo()
    .then((fetchedCustomLogo: CustomLogo) => {
      dispatch(setCustomLogoInStore(fetchedCustomLogo));
    })
    .catch(showErrorMessageFromResponse);
};
