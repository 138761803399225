import { ListSettings } from '../types/types';
import { AppThunk } from '../../redux/types';
import { setListSettingsInStore } from '../../userSettings/redux/userSettingsSlice';
import { requestSaveListSettings } from '../../userSettings/rest/userSettingsApi';
import { showErrorMessageFromResponse, showSnackbarMessage } from '../../userMessages/actions';
import { setIsListLoading } from '../../redux/commonReducers';

export const listSaveSettings = (listSettings: ListSettings,
                                 snackbarMessage: string,
                                 reopenList?: () => void,
): AppThunk => async dispatch => {
  dispatch(setIsListLoading(true));
  requestSaveListSettings(listSettings)
    .then((savedListSetting: ListSettings) => {
      dispatch(setListSettingsInStore(savedListSetting));
      reopenList && reopenList();
      showSnackbarMessage(snackbarMessage);
    })
    .catch(showErrorMessageFromResponse)
    .finally(() =>
      dispatch(setIsListLoading(false))
    );
};
