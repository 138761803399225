import React, { useEffect, useState } from 'react';
import { DetailBag } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { Status, STATUS_ICON, STATUS_TYPE } from '../types/statusTypes';
import { StaticField } from 'ts-components';
import { AccurityReference } from '../../../common/types/accurityTypes';
import { fetchDefaultStatusReference } from '../rest/rest';
import { FormikProps } from 'formik';
import AccurityReferenceField from '../../../common/referenceField/components/AccurityReferenceField';

interface StatusConfirmRemovalDetailProps {
  formik: FormikProps<Status>
  detailBag: DetailBag<Status>
}

const StatusConfirmRemovalDetail = ({ formik, detailBag }: StatusConfirmRemovalDetailProps) => {
  const [defaultStatus, setDefaultStatus] = useState<AccurityReference | undefined>(undefined);

  useEffect(() => {
    fetchDefaultStatusReference()
      .then(setDefaultStatus)
  }, []);

  return (
    <ConfirmRemovalDetail
      iconName={STATUS_ICON}
      detailBag={detailBag}
    >
      {!formik.values.defaultIndicator &&
      <>
        <StaticField
          name={'removalDescription'}
          value={'Doing so will automatically replace it on all objects that are currently using it with:'}
          inverted={true}
        />
        <AccurityReferenceField
          name={'defaultStatus'}
          label={'Default Status'}
          objectType={STATUS_TYPE}
          icon={STATUS_ICON}
          setValue={setDefaultStatus}
          value={defaultStatus}
          readOnly={true}
          inverted={true}
        />
      </>
      }
    </ConfirmRemovalDetail>
  );
};

export default StatusConfirmRemovalDetail;
