import React from 'react';
import { AccurityButton, AccurityDetail as AccurityDetailComponent, DetailBottomBar, DetailHeader, StaticField } from 'ts-components';
import { MAX_DETAIL_WIDTH, MIN_DETAIL_WIDTH } from '../../detail/components/AccurityDetail';

interface ApplicationSettingsConfirmDetailProps {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ApplicationSettingsConfirmDetail = ({ title, description, onCancel, onConfirm }: ApplicationSettingsConfirmDetailProps) => {

  return (
    <AccurityDetailComponent
      minWidth={MIN_DETAIL_WIDTH}
      maxWidth={MAX_DETAIL_WIDTH}
      inverted={true}
    >
      <DetailHeader
        iconName={'mui:SettingsOutlined'}
        title={title}
        inverted={true}
      />
      <StaticField
        name={'operationDescription'}
        value={description}
        inverted={true}
        format={'HTML'}
      />
      {/* see #24978 */}
      {/*<StaticField*/}
      {/*name={'confirmPasswordDescription'}*/}
      {/*value={'To confirm you are authorized to perform this action, please provide your password:'}*/}
      {/*inverted={true}*/}
      {/*/>*/}
      {/*<SingleLineField*/}
      {/*name={'confirmPassword'}*/}
      {/*type={'password'}*/}
      {/*showPasswordVisibilityButton={true}*/}
      {/*value={confirmPassword}*/}
      {/*onChange={setConfirmPassword}*/}
      {/*inverted={true}*/}
      {/*/>*/}
      <DetailBottomBar>
        <AccurityButton
          onClick={onCancel}
          color="light"
        >
          Cancel
        </AccurityButton>
        <AccurityButton
          onClick={onConfirm}
          color="primary"
        >
          Confirm
        </AccurityButton>
      </DetailBottomBar>
    </AccurityDetailComponent>
  )
};

export default ApplicationSettingsConfirmDetail;
