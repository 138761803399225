import { BulkOperationNotificationCenterNotification, NotificationCenterNotificationType } from '../../notificationCenter/types';
import { dismissNotification, pushNotification } from '../../notificationCenter/notificationCenterSlice';
import { BulkOperationLogCommand } from '../../websocket/types';
import rootStore from '../../redux/rootStore';
import { BulkOperationActionType } from '../types';
import { getBulkRemovalFinishedMessage, getBulkRemovalInProgressMessage, getBulkUpdateFinishedMessage, getBulkUpdateInProgressMessage } from '../messages';
import { setIsInProgress } from '../redux/bulkOperationsSlice';

type BulkOperationNotificationProps = {
  id: string;
  objectType: string;
  header: string;
  body?: string;
  inProgress?: boolean;
  showDownloadReport?: boolean;
  cannotDismiss?: boolean;
}

const pushNotificationToStore = (props: BulkOperationNotificationProps) => {
  const notification: BulkOperationNotificationCenterNotification = {
    type: NotificationCenterNotificationType.BULK_OPERATION,
    date: Date.now(),
    ...props
  };

  rootStore.dispatch(dismissNotification(props.id));
  rootStore.dispatch(pushNotification(notification));
};

export const pushBulkOperationFinishedNotification = ({ action, objectType, successfulItems, failedItems, successfulOperation }: BulkOperationLogCommand) => {

  let header = '';
  let body = '';

  if (action === BulkOperationActionType.DELETE) {
    header = getBulkRemovalFinishedMessage(objectType);
    body = `Removed: ${successfulItems} | Failed: ${failedItems}`;
  } else if (action === BulkOperationActionType.UPDATE_TAGS) {
    header = getBulkUpdateFinishedMessage(objectType);
    body = `Updated: ${successfulItems} | Failed: ${failedItems}`;
  }

  rootStore.dispatch(setIsInProgress(false));
  pushNotificationToStore({
    id: getNotificationId(objectType, action),
    inProgress: false,
    objectType: objectType,
    header: header,
    body: body,
    showDownloadReport: !successfulOperation
  });
};

export const pushBulkOperationInProgressNotification = (objectType: string, actionType: BulkOperationActionType) => {

  rootStore.dispatch(setIsInProgress(true));
  pushNotificationToStore({
    id: getNotificationId(objectType, actionType),
    inProgress: true,
    objectType: objectType,
    header: actionType === BulkOperationActionType.DELETE ? getBulkRemovalInProgressMessage(objectType) : getBulkUpdateInProgressMessage(objectType),
    cannotDismiss: true
  });
};

const getNotificationId = (objectType: string, action: BulkOperationActionType) => `bulk-operation-${objectType}-${action}`;
