import { FormikProps } from 'formik';
import { ProcessMapping } from '../types/processMappingTypes';


export const getProcessMappingCountLabel = (collection: any[], label: string, pluralLabel: string) => {

  if (collection && collection.length) {
    const length = collection.length;

    if (length !== 1) {
      return length + ' ' + pluralLabel;
    } else {
      return length + ' ' + label;
    }
  } else {
    return '0 ' + pluralLabel;
  }
};

export const resetValuesOnTypeChange = (formik: FormikProps<ProcessMapping>) => {
  formik.setValues({
    ...formik.values,
    process: undefined,
    processStep: undefined,
    processMappingTargetEntities: [],
    processMappingTargetAttributes: [],
    targetBusinessTerms: []
  });

  formik.setErrors({
    ...formik.errors,
    process: undefined,
    processStep: undefined,
    processMappingTargetEntities: [],
    processMappingTargetAttributes: [],
    targetBusinessTerms: []
  })
};