import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { EnumOption, Tag } from 'ts-components';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';

export const BUSINESS_RULE_TYPE = 'business-rule';
export const BUSINESS_RULE_LABEL = 'Business Rule';
export const BUSINESS_RULE_PLURAL_LABEL = 'Business Rules';
export const BUSINESS_RULE_ICON = 'businessRule';

export interface BusinessRuleState extends CoreEntityState<BusinessRule> {
}

export interface BusinessRule extends AccurityCoreEntity, WithChildrenCounts {
  status?: AccurityReference;
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
}

export enum BusinessRuleDimension {
  ACCURACY = 'ACCURACY',
  COMPLETENESS = 'COMPLETENESS',
  CONSISTENCY = 'CONSISTENCY',
  TIMELINESS = 'TIMELINESS',
  UNIQUENESS = 'UNIQUENESS',
  VALIDITY = 'VALIDITY'
}

export const BusinessRuleDimensionOptions: EnumOption[] = [
  { value: BusinessRuleDimension.ACCURACY, label: 'Accuracy' },
  { value: BusinessRuleDimension.COMPLETENESS, label: 'Completeness' },
  { value: BusinessRuleDimension.CONSISTENCY, label: 'Consistency' },
  { value: BusinessRuleDimension.TIMELINESS, label: 'Timeliness' },
  { value: BusinessRuleDimension.UNIQUENESS, label: 'Uniqueness' },
  { value: BusinessRuleDimension.VALIDITY, label: 'Validity' },
];
