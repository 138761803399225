import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { DATA_FIELD_ICON, DATA_FIELD_PLURAL_LABEL, DATA_FIELD_TYPE } from '../types/dataFieldTypes';
import { useDataFieldListColumns } from './dataFieldListColumns';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';

const DataFieldList = () => {
  const dataFieldColumns = useDataFieldListColumns();
  const dataFieldListSettings = getDefaultListSettings(DATA_FIELD_TYPE, dataFieldColumns);
  const hasFeature = useHasFeature();
  const permissions = usePermissionsForObjectType(DATA_FIELD_TYPE);

  // When DMS are OFF, user can not create Data Field
  permissions.hasCreatePermission = permissions.hasCreatePermission && hasFeature(Feature.LOGICAL_DATA_SETS);

  return (
    <AccurityListContainer
      objectType={DATA_FIELD_TYPE}
      title={DATA_FIELD_PLURAL_LABEL}
      icon={DATA_FIELD_ICON}
      columns={dataFieldColumns}
      defaultListSettings={dataFieldListSettings}
      permissionsOverwrite={permissions}
    />
  );
};

export default DataFieldList;
