import React from 'react';
import { useFormikContext } from 'formik';
import ChildrenCountField from '../../childrenCountField/components/ChildrenCountField';
import { AccurityCoreEntity, AccurityPinFilter } from '../../types/accurityTypes';
import { ChildrenCountWording } from '../../childrenCountField/types';

interface ChildrenCountFieldWithFormikProps {
  singularLabel: string;
  pluralLabel: string;
  value?: number;
  iconName?: string;
  pinIconName?: string;
  pinLabel?: string;
  childFieldType: string;
  relationType?: string;
  inverted?: boolean;
  customPinFilters?: AccurityPinFilter[];
  onClick?: () => void;
  childrenCountWording?: ChildrenCountWording
}

const ChildrenCountFieldWithFormik = ({
                                        singularLabel,
                                        pluralLabel,
                                        value,
                                        iconName,
                                        pinIconName,
                                        pinLabel,
                                        childFieldType,
                                        relationType,
                                        inverted,
                                        customPinFilters,
                                        onClick,
                                        childrenCountWording
                                      }: ChildrenCountFieldWithFormikProps) => {

  const formikContext = useFormikContext<AccurityCoreEntity>();

  if (formikContext.status.isVersionBrowser || !formikContext.status.isUpdateDetail) {
    return null;
  }

  return (
    <ChildrenCountField
      singularLabel={singularLabel}
      pluralLabel={pluralLabel}
      value={value}
      iconName={iconName}
      pinIconName={pinIconName}
      pinLabel={formikContext.initialValues.name}
      childFieldType={childFieldType}
      parentFieldType={formikContext.initialValues.objectType}
      relationType={relationType}
      parentId={formikContext.initialValues.id}
      inverted={inverted}
      disabled={formikContext.status.isEntityInDetailDeleted}
      customPinFilters={customPinFilters}
      onClick={onClick}
      childrenCountWording={childrenCountWording}
    />
  );
};

export default ChildrenCountFieldWithFormik;
