import { InitOptions } from 'i18next';

export const i18nInitOptions: InitOptions = {
  interpolation: {
    format: function (value: any, format?: string) {

      if (typeof value === 'string' && format === 'objectName') {
        return value ?
          '\u00a0"' + (value.length <= 40 ? value : value.substr(0, 37).concat('...')) + '"' :
          '';
      }

      return value;
    }
  }
};
