import React from 'react';
import { requestGlobalSearch } from '../rest';
import AccurityCardList from '../../cardList/components/AccurityCardList';

type GlobalSearchListProps = {
  searchTerm: string;
}

const GlobalSearchList = ({ searchTerm }: GlobalSearchListProps) => {

  return (
    <AccurityCardList fetchFn={requestGlobalSearch} searchValue={searchTerm}/>
  )
};

export default GlobalSearchList;
