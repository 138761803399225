import React from 'react';
import { FormikProps } from 'formik';
import { DetailInlineGroup } from 'ts-components';
import { AccurityFilter } from '../../../../types/accurityTypes';
import { CUSTOM_PROPERTY_DEFAULT_LABEL, CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, CustomProperty } from '../../types/customPropertyTypes';
import { getSupportiveCustomPropertyDefaultValueFieldLabel, targetObjectTypeIcon, targetObjectTypeTypeEndpoint } from '../../../customPropertyUtils';
import ReferenceFieldWithFormik from '../../../../detail/formik/ReferenceFieldWithFormik';
import { PROCESS_ICON, PROCESS_LABEL, PROCESS_TYPE } from '../../../../../businessGlossary/process/types/processTypes';
import { createReferenceFieldFilter } from '../../../../referenceField/utils/filters';
import { PROCESS_STEP_LABEL, ProcessStepReference } from '../../../../../businessGlossary/process/step/types/processStepTypes';

interface ProcessStepDefaultValueFieldProps {
  formik: FormikProps<CustomProperty>;
}

const ProcessStepDefaultValueField = ({ formik }: ProcessStepDefaultValueFieldProps) => {

  const targetObjectType = formik.values.targetObjectType;
  const objectType = targetObjectTypeTypeEndpoint(targetObjectType);
  const icon = targetObjectTypeIcon(targetObjectType);
  const readOnly = targetObjectType === null;
  const processReferenceFieldProps = formik.getFieldProps(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.processReference').value;
  const processId = processReferenceFieldProps?.id;

  let filters: AccurityFilter[] = [];

  filters.push(
    createReferenceFieldFilter('process.id', processId),
  );

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
      >
        <ReferenceFieldWithFormik
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.processReference'}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, PROCESS_LABEL)}
          objectType={PROCESS_TYPE}
          icon={PROCESS_ICON}
          setValue={(process) => {
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.processReference', process);
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', null);
          }}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.processReference'}
        />
        <ReferenceFieldWithFormik<ProcessStepReference>
          name={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference'}
          icon={icon}
          label={getSupportiveCustomPropertyDefaultValueFieldLabel(CUSTOM_PROPERTY_DEFAULT_LABEL, PROCESS_STEP_LABEL)}
          additionalFilters={filters}
          setValue={(processStep) => {
            if (!processId) {
              formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.processReference', processStep?.process);
            }
            formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD + '.reference', processStep);
          }}
          objectType={objectType}
          coloringOverwrite={CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD}
          readOnly={readOnly}
        />
      </DetailInlineGroup>
    </>
  )
};

export default ProcessStepDefaultValueField;