import React from 'react';
import { CustomEditorControl } from 'ts-components';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAccurityNavigation } from '../../navigation/hooks';

interface OpenChipListControlProps {
  chipObjectType?: string;
}

const OpenChipListControl = ({ chipObjectType }: OpenChipListControlProps) => {
  const { t } = useTranslation();
  const navigationController = useAccurityNavigation();

  if (!chipObjectType) {
    return null;
  }

  return (
    <CustomEditorControl
      tooltip={`Open ${t(`common.${chipObjectType}`)} List`}
      icon={camelCase(chipObjectType)}
      onClick={() => navigationController.openList(chipObjectType)}
    />
  );

}

export default OpenChipListControl;
