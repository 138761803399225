import React from 'react';
import AccurityCardList from '../../../common/cardList/components/AccurityCardList';
import { fetchEntitiesWithTag } from '../rest/rest';

type TaggedEntityListProps = {
  tagName: string;
}

const TaggedEntityList = ({ tagName }: TaggedEntityListProps) => {

  return (
    <AccurityCardList fetchFn={fetchEntitiesWithTag} searchValue={tagName}/>
  )
};

export default TaggedEntityList;
