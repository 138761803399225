import React from 'react';
import { HistoryGroupField } from 'ts-components';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useFormikContext } from 'formik';

type HistoryGroupWithFormikProps = {
  VersionBrowserButton?: React.ReactNode;
}

const HistoryGroupWithFormik = ({ VersionBrowserButton }: HistoryGroupWithFormikProps) => {
  const formik = useFormikContext<AccurityCoreEntity>();
  const coreEntity = formik.values;

  if (formik.status.isVersionBrowser || !formik.status.isUpdateDetail) {
    return null;
  }

  return (
    <HistoryGroupField
      {...coreEntity}
      VersionBrowserButton={VersionBrowserButton}
    />
  );
};

export default HistoryGroupWithFormik;
