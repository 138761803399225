import { DataTransferLink, ExportFormat, ExportResult, ExportSearch, ImportResult } from './types';
import { AppThunk } from '../redux/types';
import { requestExport, requestExportLink, requestSendImportFile, requestStartImportJob } from './api';
import {
  clearExportDownloadReportsFromOtherNotifications,
  clearImportDownloadReportsFromOtherNotifications,
  pushNotification
} from '../notificationCenter/notificationCenterSlice';
import { ExportNotificationCenterNotification, ImportNotificationCenterNotification, NotificationCenterNotificationType } from '../notificationCenter/types';
import rootStore from '../redux/rootStore';
import { showErrorMessageFromResponse } from '../userMessages/actions';
import { setIsListLoading } from '../redux/commonReducers';

export const listExport = (format: ExportFormat,
                           exportSearch: ExportSearch,
): AppThunk => async dispatch => {

  dispatch(setIsListLoading(true));
  requestExportLink(format, exportSearch)
    .then((exportLink: DataTransferLink) => {
      const exportStartDate = Date.now();
      pushExportNotificationCenterNotification({
        id: exportLink.id,
        date: exportStartDate,
        cannotDismiss: true,
        showDownloadReport: false,
        objectType: exportSearch.objectType,
      });
      return requestExport(format, exportSearch, exportLink);
    })
    .catch(showErrorMessageFromResponse)
    .finally(() => {
      dispatch(setIsListLoading(false));
    })
};

export const fileImport = (objectType: string,
                           file: File,
                           partialImport: boolean,
): AppThunk => async dispatch => {

  dispatch(setIsListLoading(true));
  requestSendImportFile(objectType, file, partialImport)
    .then((importLink: DataTransferLink) => {
      const importStartDate = Date.now();
      return requestStartImportJob(objectType, importLink)
        .then(() => {
          pushImportNotificationCenterNotification({
            id: importLink.id,
            date: importStartDate,
            cannotDismiss: true,
            showDownloadReport: false,
            failedWithException: false,
            objectType: objectType,
          })
        });
    })
    .catch(showErrorMessageFromResponse)
    .finally(() => {
      dispatch(setIsListLoading(false));
    })
};

export const pushImportNotificationCenterNotification = (importData: { id: string, date: number, cannotDismiss: boolean, showDownloadReport?: boolean, objectType: string, importResult?: ImportResult, failedWithException?: boolean }) => {
  const importNotification: ImportNotificationCenterNotification = {
    type: NotificationCenterNotificationType.IMPORT,
    ...importData,
  };

  rootStore.dispatch(pushNotification(importNotification));
  rootStore.dispatch(clearImportDownloadReportsFromOtherNotifications({ id: importNotification.id, objectType: importNotification.objectType }))
};

export const pushExportNotificationCenterNotification = (exportData: { id: string, date: number, cannotDismiss: boolean, showDownloadReport?: boolean, objectType: string, exportResult?: ExportResult }) => {
  const exportNotification: ExportNotificationCenterNotification = {
    type: NotificationCenterNotificationType.EXPORT,
    ...exportData,
  };

  rootStore.dispatch(pushNotification(exportNotification));
  rootStore.dispatch(clearExportDownloadReportsFromOtherNotifications({ id: exportNotification.id, objectType: exportNotification.objectType }))
};
