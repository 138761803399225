import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { ENTITY_TYPE, EntityState } from '../types/entityTypes';

const initialState: EntityState = getInitialCoreEntityState(ENTITY_TYPE);

const entitySlice = createSlice({
  name: ENTITY_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default entitySlice.reducer;
