import React from 'react';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import { TECHNICAL_DATA_MAPPING_ICON, TECHNICAL_DATA_MAPPING_TYPE, TechnicalDataMapping } from '../types/technicalDataMappingTypes';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import { FormikProps } from 'formik';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import { useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import { DetailHeader, EnumOption } from 'ts-components';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import TechnicalDataMappingBase from './baseSection/TechnicalDataMappingBase';
import TechnicalDataMappingTarget from './targetSection/TechnicalDataMappingTarget';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface TechnicalDataMappingDetailProps {
  id?: string,
}

const TechnicalDataMappingDetail = ({ id }: TechnicalDataMappingDetailProps) => {

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  return (
    <AccurityDetailContainer<TechnicalDataMapping>
      objectType={TECHNICAL_DATA_MAPPING_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        const technicalDataMappingDetailBag: DetailBag<TechnicalDataMapping> = {
          ...detailBag,
          copyAction: () => detailBag.copyAction({ name: undefined })
        };

        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, technicalDataMappingDetailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions)}
              <DefaultDetailBottomBar objectType={TECHNICAL_DATA_MAPPING_TYPE} detailBag={technicalDataMappingDetailBag}/>
            </>
          );
        }
      }}

    </AccurityDetailContainer>
  )
};

const getDetailFields = (formik: FormikProps<TechnicalDataMapping>,
                         multipleDataSetTypesAllowedFeature: boolean,
                         dataSetTypeEnumOptions: EnumOption[]) => {

  return (
    <FeatureChecker featureId={Feature.TECHNICAL_DATA_MAPPINGS}>
      <DetailHeader iconName={TECHNICAL_DATA_MAPPING_ICON}>
        <NameFieldWithFormik
          label={'Technical Data Mapping ID'}
          readOnly={true}
        />
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={TECHNICAL_DATA_MAPPING_TYPE}/>

      <TechnicalDataMappingBase
        formik={formik}
        multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
        dataSetTypeEnumOptions={dataSetTypeEnumOptions}
      />

      <TechnicalDataMappingTarget
        formik={formik}
        multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
        dataSetTypeEnumOptions={dataSetTypeEnumOptions}
      />

      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.TECHNICAL_DATA_MAPPING}/>
    </FeatureChecker>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<TechnicalDataMapping>, detailBag: DetailBag<TechnicalDataMapping>) => (
  <ConfirmRemovalDetail
    iconName={TECHNICAL_DATA_MAPPING_ICON}
    detailBag={detailBag}
  >

  </ConfirmRemovalDetail>
);

export default TechnicalDataMappingDetail;
