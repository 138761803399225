import React from 'react';
import { DetailBag } from '../types/types';
import { useFormikContext } from 'formik';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { useTranslation } from 'react-i18next';
import SettingsDetail from './SettingsDetail';
import ConfirmRemovalDetailBottomBar from './ConfirmRemovalDetailBottomBar';

interface ConfirmRemovalDetailProps {
  iconName: string;
  detailBag: DetailBag<AccurityCoreEntity>;
  objectName?: string;
  bottomBar?: React.ReactNode;
  children?: React.ReactNode;
}

const ConfirmRemovalDetail = ({ iconName, detailBag, objectName, bottomBar, children }: ConfirmRemovalDetailProps) => {
  const formik = useFormikContext<AccurityCoreEntity>();
  const { t } = useTranslation();
  const objectTypeLabel = t(`common.${formik.values.objectType}`);

  return (
    <SettingsDetail
      objectName={objectName || formik.initialValues.name}
      iconName={iconName}
      confirmDescription={`Are you sure you want to remove this ${objectTypeLabel}?`}
      bottomBar={bottomBar ? bottomBar : <ConfirmRemovalDetailBottomBar detailBag={detailBag}/>}
    >
      {children}
    </SettingsDetail>
  );
};

export default ConfirmRemovalDetail;
