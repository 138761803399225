import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { ATTRIBUTE_DEFINITION_PLURAL_LABEL } from '../../attributeDefinition/types/attributeDefinitionTypes';
import {
  ComponentTypeOptions,
  COMPOSITE_TYPE_COMPONENT_NAME_PLURAL_LABEL,
  COMPOSITE_TYPE_COMPONENT_TYPE_PLURAL_LABEL,
  TARGET_ENTITY_PLURAL_LABEL
} from '../types/compositeTypeTypes';
import { VALUE_TYPE_PLURAL_LABEL } from '../../valueType/types/valueTypeTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useCompositeTypeListColumns = () => {
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const compositeTypeListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description({ sortable: !isOracle, hidden: true }),
    columnFactory.createEnumColumn('components.componentType', COMPOSITE_TYPE_COMPONENT_TYPE_PLURAL_LABEL, ComponentTypeOptions, { hidden: true, sortable: false }),
    columnFactory.createCollectionColumn('components.name', COMPOSITE_TYPE_COMPONENT_NAME_PLURAL_LABEL),
    columnFactory.createCollectionColumn('components.valueType.name', VALUE_TYPE_PLURAL_LABEL, { sortable: false }),
    columnFactory.createCollectionColumn('components.targetEntity.name', TARGET_ENTITY_PLURAL_LABEL, { sortable: false }),
    commonColumns.tags(navigationController),
    columnFactory.createNumberColumn('childrenCounts.attributeDefinitionsCount', ATTRIBUTE_DEFINITION_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
    commonColumns.commentsCount(),
  ];

  return useValidColumns(compositeTypeListColumns, GlossaryCustomPropertyObjectType.COMPOSITE_TYPE);
};


