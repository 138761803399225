import i18n from 'i18next';
import { AccurityCoreEntity, EmailNotificationActionType } from '../types/accurityTypes';
import { User } from '../../businessGlossary/user/types/userTypes';

export const getSuccessfulRememberSettingsMessage = (listType: string) =>
  i18n.t(`0023`, { listType });

export const getSuccessfulRestoreSettingsMessage = (listType: string) =>
  i18n.t(`0024`, { listType });

export const getFinishedSavingMessage = (coreEntity: AccurityCoreEntity) =>
  i18n.t(`coreEntity_saved`, { entity: coreEntity });

export const getFinishedReloadMessage = (coreEntity: AccurityCoreEntity) =>
  i18n.t(`coreEntity_reloaded`, { entity: coreEntity });

export const getFinishedRemovalMessage = (coreEntity: AccurityCoreEntity) =>
  i18n.t(`coreEntity_removed`, { entity: coreEntity });

export const getNoChangesMadeMessage = () =>
  i18n.t(`no_changes_to_save`);

export const getEmailNotificationEnabledMessage = (notificationType: EmailNotificationActionType) =>
  i18n.t('email_notification_on', { subject: getSubjectFromNotificationType(notificationType) });

export const getEmailNotificationDisabledMessage = (notificationType: EmailNotificationActionType) =>
  i18n.t('email_notification_off', { subject: getSubjectFromNotificationType(notificationType) });

export const getEntityYouAreEditingWasUpdatedMessage = (coreEntity: AccurityCoreEntity) =>
  i18n.t(`0020`, { entity: coreEntity });

export const getEntityYouAreViewingWasDeletedMessage = (coreEntity: AccurityCoreEntity) =>
  i18n.t(`0022`, { entity: coreEntity });

export const getEntityYouAreViewingWasUpdatedMessage = (coreEntity: AccurityCoreEntity) =>
  i18n.t(`0021`, { entity: coreEntity });

export const getApplicationSettingsChangeSuccessMessage = () =>
  i18n.t(`0031`);

export const getBusinessTermsSuggestionHasBeenFoundMessage = (number: number) =>
  i18n.t(`0032`, { number });

export const getEmailNotificationsDisabledMessage = () =>
  i18n.t(`0204`);

export const getInvitationHasBeenSentMessage = (user: User) =>
  i18n.t(`0227`, { user });

export const getAccountHasBeenLockedMessage = (user: User) =>
  i18n.t(`0228`, { user });

export const getAccountHasBeenUnlockedMessage = (user: User) =>
  i18n.t(`0233`, { user });

export const getSettingsRestoredToDefaultMessage = () =>
  i18n.t(`0226`);

export const getPhysicalDataStructureCreatedMessage = () =>
  i18n.t(`0406`);

export const getDataFieldProfilingInProgressMessage = (objectName: string) => ({
  header: i18n.t('0509.header'),
  body: i18n.t('0509.body', { objectName }),
});

export const getDataFieldProfilingFinishedMessage = (objectName: string) => ({
  header: i18n.t('0510.header'),
  body: i18n.t('0510.body', { objectName }),
});

export const getDataFieldProfilingFailedMessage = (objectName: string) => ({
  header: i18n.t('0511.header'),
  body: i18n.t('0511.body', { objectName }),
});

export const getDataFieldProfilingUpdatedByAnotherUserMessage = () =>
  i18n.t('0512');

export const getDataSourceSaveSuccessMessage = () =>
  i18n.t(`1101`);

export const getDataSourceTestSuccessfulMessage = () =>
  i18n.t(`1102`);

export const getDataSourceTestFailMessage = () =>
  i18n.t(`1107`);

const getSubjectFromNotificationType = (actionType: EmailNotificationActionType) => {
  switch (actionType) {
    case EmailNotificationActionType.COMMENT_UPDATE:
      return 'comments';
    case EmailNotificationActionType.OBJECT_UPDATE:
      return 'updates';
    default:
      console.error('Unknown mapping from action type to subject name: ', actionType)
  }
};
