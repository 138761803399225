import React, { useCallback } from 'react';
import { ReferenceField, VersionBrowserColoring } from 'ts-components';
import { requestTextSearchFieldSearch } from '../rest';
import { useAutocompleteBackendSearchLogic } from '../utils/autocompleteBackendSearchLogic';

interface TextSearchFieldProps {
  name: string
  label: string
  searchEndpoint: string;
  value?: string;
  setValue: (newValue: string | undefined) => void;
  searchDisabled?: boolean;
  searchDisabledText?: string;
  readOnly?: boolean;
  errorMessage?: string;
  coloring?: VersionBrowserColoring;
}

const AccurityTextSearchField = ({
                                   name,
                                   label,
                                   searchEndpoint,
                                   value,
                                   setValue,
                                   searchDisabled,
                                   searchDisabledText,
                                   readOnly,
                                   errorMessage,
                                   coloring
                                 }: TextSearchFieldProps) => {

  const doBackendSearch = useCallback((searchText: string) =>
      requestTextSearchFieldSearch(searchEndpoint, searchText)
    , [searchEndpoint]);

  const {
    onChange,
    onSelect,
    filterText,
    resetFilterText,
    doSearch,
    isLoading,
    items,
  } = useAutocompleteBackendSearchLogic<string>(value, setValue, doBackendSearch, searchDisabled)

  return (
    <ReferenceField
      value={filterText}
      menuItems={items as any[]}
      menuItemRenderer={(item: any) => item}
      emptyMenuItemsLabel={searchDisabled ? searchDisabledText : 'No Results Found'}
      menuItemIsLoading={isLoading}
      onChange={onChange}
      onSelect={onSelect}
      onFocus={doSearch}
      onOutsideClick={resetFilterText}
      name={name}
      label={label}
      disabled={readOnly}
      errorMessage={errorMessage}
      coloring={coloring}
    />
  );
}

export default AccurityTextSearchField;
