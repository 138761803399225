import { BUSINESS_MODEL_MAPPING_TYPE, BusinessModelMappingState } from '../types/businessModelMappingTypes';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { createSlice } from '@reduxjs/toolkit';
import { commonReducers } from '../../../common/redux/commonReducers';

const initialState: BusinessModelMappingState = getInitialCoreEntityState(BUSINESS_MODEL_MAPPING_TYPE);

const businessModelMappingSlice = createSlice({
  name: BUSINESS_MODEL_MAPPING_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default businessModelMappingSlice.reducer;
