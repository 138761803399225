import { getInitialCoreEntityState } from '../../../common/redux/types';
import { createSlice } from '@reduxjs/toolkit';
import { commonReducers } from '../../../common/redux/commonReducers';
import { USER_TYPE, UserState } from '../types/userTypes';

const initialState: UserState = getInitialCoreEntityState(USER_TYPE);

const userSlice = createSlice({
  name: USER_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default userSlice.reducer;
