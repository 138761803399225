export type TimezoneEnumOption = {
  value: string,
  momentFormattedValue?: string,
  label?: string
}

export const getFormattedTimezone = (timezoneValue?: string) => {
  const option = timezoneOptions.find(opt => opt.value === timezoneValue);
  return option ? option.momentFormattedValue : undefined;
};

export const timezoneOptions: TimezoneEnumOption[] = [
  { value: 'AUTOMATIC', momentFormattedValue: undefined, label: 'Automatically Selected' },
  { value: 'ETC_UTC', momentFormattedValue: 'Etc/UTC', label: '(UTC) Co-ordinated Universal Time' },
  { value: 'PACIFIC_HONOLULU', momentFormattedValue: 'Pacific/Honolulu', label: '(UTC-10:00) Hawaii' },
  { value: 'PACIFIC_PITCAIRN', momentFormattedValue: 'Pacific/Pitcairn', label: '(UTC-08:00) Pacific Time (US & Canada)' },
  { value: 'AMERICA_MEXICO_CITY', momentFormattedValue: 'America/Mexico_City', label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey' },
  { value: 'AMERICA_BELIZE', momentFormattedValue: 'America/Belize', label: '(UTC-06:00) Central America Time' },
  { value: 'AMERICA_EDMONTON', momentFormattedValue: 'America/Edmonton', label: '(UTC-05:00) Eastern Time (US & Canada)' },
  { value: 'AMERICA_BOGOTA', momentFormattedValue: 'America/Bogota', label: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco' },
  { value: 'AMERICA_CARACAS', momentFormattedValue: 'America/Caracas', label: '(UTC-04:30) Caracas' },
  { value: 'AMERICA_LA_PAZ', momentFormattedValue: 'America/La_Paz', label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' },
  { value: 'ATLANTIC_BERMUDA', momentFormattedValue: 'Atlantic/Bermuda', label: '(UTC-04:00) Atlantic Time (Canada)' },
  { value: 'AMERICA_ASUNCION', momentFormattedValue: 'America/Asuncion', label: '(UTC-04:00) Asuncion' },
  { value: 'AMERICA_SANTIAGO', momentFormattedValue: 'America/Santiago', label: '(UTC-03:00) Santiago' },
  { value: 'AMERICA_MONTEVIDEO', momentFormattedValue: 'America/Montevideo', label: '(UTC-03:00) Montevideo' },
  { value: 'AMERICA_GODTHAB', momentFormattedValue: 'America/Godthab', label: '(UTC-03:00) Greenland' },
  { value: 'AMERICA_CAYENNE', momentFormattedValue: 'America/Cayenne', label: '(UTC-03:00) Cayenne, Fortaleza' },
  { value: 'AMERICA_BUENOS_AIRES', momentFormattedValue: 'America/Argentina/Buenos_Aires', label: '(UTC-03:00) Buenos Aires' },
  { value: 'AMERICA_FORTALEZA', momentFormattedValue: 'America/Fortaleza', label: '(UTC-03:00) Brasilia' },
  { value: 'ATLANTIC_CAPE_VERDE', momentFormattedValue: 'Atlantic/Cape_Verde', label: '(UTC-01:00) Cabo Verde Is.' },
  { value: 'AFRICA_CASABLANCA', momentFormattedValue: 'Africa/Casablanca', label: '(UTC+00:00) Casablanca' },
  { value: 'EUROPE_LONDON', momentFormattedValue: 'Europe/London', label: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London' },
  { value: 'ATLANTIC_REYKJAVIK', momentFormattedValue: 'Atlantic/Reykjavik', label: '(UTC+00:00) Monrovia, Reykjavik' },
  { value: 'EUROPE_VIENNA', momentFormattedValue: 'Europe/Vienna', label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { value: 'EUROPE_PRAGUE', momentFormattedValue: 'Europe/Prague', label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
  { value: 'EUROPE_PARIS', momentFormattedValue: 'Europe/Paris', label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: 'EUROPE_WARSAW', momentFormattedValue: 'Europe/Warsaw', label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { value: 'AFRICA_TUNIS', momentFormattedValue: 'Africa/Tunis', label: '(UTC+01:00) West Central Africa' },
  { value: 'AFRICA_WINDHOEK', momentFormattedValue: 'Africa/Windhoek', label: '(UTC+01:00) Windhoek' },
  { value: 'ASIA_AMMAN', momentFormattedValue: 'Asia/Amman', label: '(UTC+02:00) Amman' },
  { value: 'EUROPE_ATHENS', momentFormattedValue: 'Europe/Athens', label: '(UTC+02:00) Athens, Bucharest' },
  { value: 'ASIA_BEIRUT', momentFormattedValue: 'Asia/Beirut', label: '(UTC+02:00) Beirut' },
  { value: 'AFRICA_CAIRO', momentFormattedValue: 'Africa/Cairo', label: '(UTC+02:00) Cairo' },
  { value: 'AFRICA_TRIPOLI', momentFormattedValue: 'Africa/Tripoli', label: '(UTC+02:00) Cyprus, Libya' },
  { value: 'AFRICA_DAMASCUS', momentFormattedValue: 'Asia/Damascus', label: '(UTC+02:00) Damascus' },
  { value: 'AFRICA_HARARE', momentFormattedValue: 'Africa/Harare', label: '(UTC+02:00) Harare, Pretoria' },
  { value: 'EUROPE_HELSINKI', momentFormattedValue: 'Europe/Helsinki', label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { value: 'EUROPE_ISTANBUL', momentFormattedValue: 'Europe/Istanbul', label: '(UTC+02:00) Istanbul' },
  { value: 'ASIA_JERUSALEM', momentFormattedValue: 'Asia/Jerusalem', label: '(UTC+02:00) Jerusalem' },
  { value: 'ASIA_BAGHDAD', momentFormattedValue: 'Asia/Baghdad', label: '(UTC+03:00) Baghdad' },
  { value: 'AFRICA_NAIROBI', momentFormattedValue: 'Africa/Nairobi', label: '(UTC+03:00) East Africa' },
  { value: 'ASIA_KUWAIT', momentFormattedValue: 'Asia/Kuwait', label: '(UTC+03:00) Kuwait, Riyadh' },
  { value: 'EUROPE_MINSK', momentFormattedValue: 'Europe/Minsk', label: '(UTC+03:00) Minsk' },
  { value: 'EUROPE_MOSCOW', momentFormattedValue: 'Europe/Moscow', label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd' },
  { value: 'ASIA_TEHRAN', momentFormattedValue: 'Asia/Tehran', label: '(UTC+03:30) Tehran' },
  { value: 'ASIA_MUSCAT', momentFormattedValue: 'Asia/Muscat', label: '(UTC+04:00) Abu Dhabi, Muscat' },
  { value: 'ASIA_BAKU', momentFormattedValue: 'Asia/Baku', label: '(UTC+04:00) Baku' },
  { value: 'INDIAN_MAURITIUS', momentFormattedValue: 'Indian/Mauritius', label: '(UTC+04:00) Port Louis' },
  { value: 'ASIA_TBILISI', momentFormattedValue: 'Asia/Tbilisi', label: '(UTC+04:00) Tbilisi' },
  { value: 'ASIA_YEREVAN', momentFormattedValue: 'Asia/Yerevan', label: '(UTC+04:00) Yerevan' },
  { value: 'ASIA_KABUL', momentFormattedValue: 'Asia/Kabul', label: '(UTC+04:30) Kabul' },
  { value: 'ASIA_ASHGABAT', momentFormattedValue: 'Asia/Ashgabat', label: '(UTC+05:00) Ashgabat, Tashkent' },
  { value: 'ASIA_KARACHI', momentFormattedValue: 'Asia/Karachi', label: '(UTC+05:00) Islamabad, Karachi' },
  { value: 'ASIA_KOLKATA', momentFormattedValue: 'Asia/Kolkata', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
  { value: 'ASIA_COLOMBO', momentFormattedValue: 'Asia/Colombo', label: '(UTC+05:30) Sri Lanka' },
  { value: 'ASIA_KATHMANDU', momentFormattedValue: 'Asia/Kathmandu', label: '(UTC+05:45) Kathmandu' },
  { value: 'ASIA_ALMATY', momentFormattedValue: 'Asia/Almaty', label: '(UTC+06:00) Astana' },
  { value: 'ASIA_DHAKA', momentFormattedValue: 'Asia/Dhaka', label: '(UTC+06:00) Dhaka' },
  { value: 'ASIA_YANGON', momentFormattedValue: 'Asia/Yangon', label: '(UTC+06:30) Yangon (Rangoon)' },
  { value: 'ASIA_BANGKOK', momentFormattedValue: 'Asia/Bangkok', label: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
  { value: 'ASIA_HONG_KONG', momentFormattedValue: 'Asia/Hong_Kong', label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
  { value: 'ASIA_TAIPEI', momentFormattedValue: 'Asia/Taipei', label: '(UTC+08:00) Taipei' },
  { value: 'ASIA_ULAANBAATAR', momentFormattedValue: 'Asia/Ulaanbaatar', label: '(UTC+08:00) Ulaanbaatar' },
  { value: 'ASIA_TOKYO', momentFormattedValue: 'Asia/Tokyo', label: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
  { value: 'ASIA_SEOUL', momentFormattedValue: 'Asia/Seoul', label: '(UTC+09:00) Seoul' },
  { value: 'AUSTRALIA_SYDNEY', momentFormattedValue: 'Australia/Sydney', label: '(UTC+10:00) Canberra, Melbourne, Sydney' },
  { value: 'PACIFIC_GUAM', momentFormattedValue: 'Pacific/Guam', label: '(UTC+10:00) Guam, Port Moresby' },
  { value: 'PACIFIC_GUADALCANAL', momentFormattedValue: 'Pacific/Guadalcanal', label: '(UTC+11:00) Solomon Islands, New Caledonia' },
  { value: 'PACIFIC_AUCKLAND', momentFormattedValue: 'Pacific/Auckland', label: '(UTC+12:00) Auckland, Wellington' },
  { value: 'PACIFIC_FIJI', momentFormattedValue: 'Pacific/Fiji', label: '(UTC+12:00) Fiji' },
  { value: 'PACIFIC_FAKAOFO', momentFormattedValue: 'Pacific/Fakaofo', label: '(UTC+13:00) Nuku\'alofa' },
  { value: 'PACIFIC_APIA', momentFormattedValue: 'Pacific/Apia', label: '(UTC+13:00) Samoa' }
];
