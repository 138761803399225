import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { BUSINESS_MODEL_DIAGRAM_ICON, BUSINESS_MODEL_DIAGRAM_PLURAL_LABEL, BUSINESS_MODEL_DIAGRAM_TYPE, } from '../types/businessModelDiagramTypes';
import { useBusinessModelDiagramColumns } from './businessModelDiagramColumns';

const BusinessModelDiagramList = () => {
  const businessModelDiagramColumns = useBusinessModelDiagramColumns();
  const businessModelDiagramListSettings = getDefaultListSettings(BUSINESS_MODEL_DIAGRAM_TYPE, businessModelDiagramColumns);

  return (
    <AccurityListContainer
      objectType={BUSINESS_MODEL_DIAGRAM_TYPE}
      title={BUSINESS_MODEL_DIAGRAM_PLURAL_LABEL}
      icon={BUSINESS_MODEL_DIAGRAM_ICON}
      columns={businessModelDiagramColumns}
      defaultListSettings={businessModelDiagramListSettings}
      allowExport={false}
      allowImport={false}
    />
  );
};

export default BusinessModelDiagramList;
