import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';
import { PROCESS_TYPE, ProcessState } from '../types/processTypes';

const initialState: ProcessState = getInitialCoreEntityState(PROCESS_TYPE);

const processSlice = createSlice({
  name: PROCESS_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default processSlice.reducer;
