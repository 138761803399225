import React from 'react';
import {
  DATA_STRUCTURE_ICON,
  DATA_STRUCTURE_TYPE,
  DATA_STRUCTURE_TYPE_FIELD,
  DATA_STRUCTURE_TYPE_FIELD_LABEL,
  DataStructure,
  DataStructureType,
  dataStructureTypeOptions
} from '../types/dataStructureTypes';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import { AccurityFilterType, DetailHeader, DetailInlineGroup, EnumOption, StaticField } from 'ts-components';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL, DataSetReference, DataSetType, useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_PLURAL_LABEL, DATA_FIELD_TYPE } from '../../dataField/types/dataFieldTypes';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_ALSO_LABEL, IT_CURRENTLY_LABEL, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import DataLineageDiagramButton from '../../../common/detail/commonFields/DataLineageDiagramButton';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import FeatureOffChecker from '../../../common/userSettings/components/FeatureOffChecker';
import { showSnackbarMessage } from '../../../common/userMessages/actions';
import { getFinishedSavingMessage, getPhysicalDataStructureCreatedMessage } from '../../../common/userMessages/commonMessages';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import { AccurityPinFilter } from '../../../common/types/accurityTypes';
import { CustomSaveSteps } from '../../../common/detail/actions/detailSave';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import RelatedBusinessTermsWithSuggestionsForDataStructure from './RelatedBusinessTermsWithSuggestionsForDataStructure';
import {
  TECHNICAL_DATA_MAPPING_ICON,
  TECHNICAL_DATA_MAPPING_LABEL,
  TECHNICAL_DATA_MAPPING_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_TYPE
} from '../../technicalModelMapping/types/technicalDataMappingTypes';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import { DataSyncResultField } from '../../../common/dataCatalogSync/components/DataSyncResultField';
import ChildrenCountsContainer from '../../../common/detail/components/ChildrenCountsContainer';
import AccurityTextSearchField from '../../../common/referenceField/components/AccurityTextSearchField';
import { DataSourceType } from '../../dataSource/types/dataSourceTypes';
import BusinessModelMappingDiagramButton from '../../../common/detail/commonFields/BusinessModelMappingDiagramButton';

interface DataStructureDetailProps {
  id?: string
}

const DataStructureDetail = ({ id }: DataStructureDetailProps) => {
  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const hasDatabaseStructuresFeature = hasFeature(Feature.PHYSICAL_DATA_SETS);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  const customSaveSteps: CustomSaveSteps<DataStructure> = {
    showSaveSuccessSnackbar: (savedCoreEntity: DataStructure) => {
      const isCreatingPhysicalDataStructure =
        !id && savedCoreEntity?.dataSet?.dataSetType === DataSetType.PHYSICAL;

      showSnackbarMessage(
        isCreatingPhysicalDataStructure
          ? getPhysicalDataStructureCreatedMessage()
          : getFinishedSavingMessage(savedCoreEntity)
      );
    },
  };

  return (
    <AccurityDetailContainer<DataStructure>
      objectType={DATA_STRUCTURE_TYPE}
      id={id}
      customSaveSteps={customSaveSteps}
    >
      {(formik, detailBag) => {
        const dataStructureDetailBag: DetailBag<DataStructure> = DataSetType.PHYSICAL === formik.values?.dataSet?.dataSetType
          ?
          {
            ...detailBag,
            showCopyAction: false
          }
          : detailBag;
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, dataStructureDetailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, multipleDataSetTypesAllowedFeature, hasDatabaseStructuresFeature, dataSetTypeEnumOptions)}
              <DefaultDetailBottomBar objectType={DATA_STRUCTURE_TYPE} detailBag={dataStructureDetailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<DataStructure>, detailBag: DetailBag<DataStructure>) => (
  <ConfirmRemovalDetail
    iconName={DATA_STRUCTURE_ICON}
    detailBag={detailBag}
  >
    <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
      <StaticField
        name={'removalDescription1'}
        value={IT_IS_CURRENTLY_LABEL}
        inverted={true}
      />
      <ChildrenCountFieldWithFormik
        singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
        pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
        value={formik.values.childrenCounts.businessModelMappingsCount}
        iconName={BUSINESS_MODEL_MAPPING_ICON}
        pinIconName={DATA_STRUCTURE_ICON}
        childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
      />
      <StaticField
        name={'removalDescription2'}
        value={IT_ALSO_LABEL}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureOffChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
      <StaticField
        name={'removalDescription3'}
        value={IT_CURRENTLY_LABEL}
        inverted={true}
      />
    </FeatureOffChecker>
    <FeatureChecker featureId={Feature.DATA_FIELDS}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_FIELD_LABEL}
        pluralLabel={DATA_FIELD_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataFieldsCount}
        iconName={DATA_FIELD_ICON}
        pinIconName={DATA_STRUCTURE_ICON}
        childFieldType={DATA_FIELD_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.HAS}
      />
    </FeatureChecker>
    <StaticField
      name={'removalDescription4'}
      value={'These Data Fields will be removed together with the Data Structure.'}
      inverted={true}
    />
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<DataStructure>,
                         multipleDataSetTypesAllowedFeature: boolean,
                         hasDatabaseStructuresFeature: boolean,
                         dataSetTypeEnumOptions: EnumOption[]) => {

  const dataStructure = formik.values;
  const dataSet = dataStructure.dataSet as DataSetReference | null;

  const dataFieldChildrenCountPinFilters: AccurityPinFilter[] = [
    {
      type: AccurityFilterType.SIMPLE_QUERY,
      property: 'dataSet.name',
      value: dataSet ? `"${dataSet.name}"` : '',
      icon: DATA_SET_ICON,
      label: dataSet?.name || '',
    },
    {
      type: AccurityFilterType.SIMPLE_QUERY,
      property: 'dataStructure.name',
      value: `"${dataStructure.name}"`,
      icon: DATA_STRUCTURE_ICON,
      label: dataStructure.name,
    }
  ];

  const handleDataSetChange = (newDataSet: DataSetReference | null) => {
    formik.setFieldValue('dataSet', newDataSet);
    formik.setFieldError('name', undefined);
    formik.setFieldValue('dataStructureType', DataStructureType.LOGICAL);

    // keep the name only if:
    //   1. null => LOGICAL
    //   2. LOGICAL => LOGICAL
    //   3. LOGICAL => null
    const keepNameValue = (dataSet == null && newDataSet?.dataSetType === DataSetType.LOGICAL)
      || (dataSet?.dataSetType === DataSetType.LOGICAL && newDataSet?.dataSetType === DataSetType.LOGICAL)
      || (dataSet?.dataSetType === DataSetType.LOGICAL && newDataSet == null);

    if (!keepNameValue) {
      formik.setFieldValue('name', '');
    }
  };

  const getDefaultDataStructureTypeForDataSet = (dataSet: DataSetReference | null): DataStructureType => {
    if (!dataSet?.dataSource?.dataSourceType) {
      return DataStructureType.LOGICAL;
    } else if (DataSourceType.EXCEL === dataSet.dataSource.dataSourceType) {
      return DataStructureType.SHEET;
    } else if (DataSourceType.MONGODB === dataSet.dataSource.dataSourceType || DataSourceType.MONGODB_ATLAS === dataSet.dataSource.dataSourceType) {
      return DataStructureType.COLLECTION;
    } else {
      return DataStructureType.TABLE;
    }
  }

  const handleDataStructureNameChange = (newValue = '') => {
    formik.setFieldValue('name', newValue);
    formik.setFieldValue('dataStructureType', getDefaultDataStructureTypeForDataSet(formik.values.dataSet));
  }

  const isVersionBrowser = formik.status.isVersionBrowser;
  const isCreated = formik.values.id !== undefined;

  return (
    <>
      <DetailHeader iconName={DATA_STRUCTURE_ICON}>
        {!dataSet && (
          <NameFieldWithFormik
            label="Data Structure Name"
            readOnly
          />
        )}
        {(dataSet && dataSet.dataSetType === DataSetType.LOGICAL) && (
          <NameFieldWithFormik
            label={'Data Structure Name'}
          />
        )}
        {(dataSet && dataSet.dataSetType === DataSetType.PHYSICAL) && (
          <AccurityTextSearchField
            name={'name'}
            label="Data Structure Name"
            searchDisabled={!formik.values.dataSet?.dataSource}
            searchDisabledText={'No Data Source'}
            searchEndpoint={`${DATA_SET_TYPE}/tables/${formik.values?.dataSet?.id ?? ''}`}
            value={formik.values.name}
            setValue={handleDataStructureNameChange}
            errorMessage={formik.errors.physicalName}
            readOnly={isCreated}
          />
        )}
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik, multipleDataSetTypesAllowedFeature, hasDatabaseStructuresFeature, dataSetTypeEnumOptions)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={DATA_STRUCTURE_TYPE}/>
      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik<DataSetReference>
          name={'dataSet'}
          label={DATA_SET_LABEL}
          objectType={DATA_SET_TYPE}
          icon={DATA_SET_ICON}
          readOnly={formik.status.isUpdateDetail}
          setValue={handleDataSetChange}
        />
        {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
          name={'dataSet.dataSetType'}
          label={DATA_SET_TYPE_LABEL}
          options={dataSetTypeEnumOptions}
          readOnly={true}
        />}
      </DetailInlineGroup>
      <EnumerationFieldWithFormik
        name={DATA_STRUCTURE_TYPE_FIELD}
        label={DATA_STRUCTURE_TYPE_FIELD_LABEL}
        options={dataStructureTypeOptions}
        readOnly={true}
      />
      {/* todo uncomment when comments are implemented #26083 */}
      {/*<MultiLineFieldWithFormik*/}
      {/*  name={DATA_STRUCTURE_COMMENT_FIELD}*/}
      {/*  label={DATA_STRUCTURE_COMMENT_FIELD_LABEL}*/}
      {/*  readOnly={dataSet?.dataSetType === DataSetType.PHYSICAL}*/}
      {/*/>*/}
      <RelatedBusinessTermsWithSuggestionsForDataStructure
        hideSuggestions={isVersionBrowser || !formik.status.isUpdateDetail}
      />
      <DataSyncResultField/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.DATA_STRUCTURE}/>
      <ChildrenCountsContainer>
        {childrenCounts => <>
          <FeatureChecker featureId={Feature.DATA_FIELDS}>
            <ChildrenCountFieldWithFormik
              singularLabel={DATA_FIELD_LABEL}
              pluralLabel={DATA_FIELD_PLURAL_LABEL}
              value={childrenCounts.dataFieldsCount}
              iconName={DATA_FIELD_ICON}
              pinIconName={DATA_STRUCTURE_ICON}
              childFieldType={DATA_FIELD_TYPE}
              childrenCountWording={ChildrenCountWording.HAS}
              customPinFilters={dataFieldChildrenCountPinFilters}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
            <ChildrenCountFieldWithFormik
              singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
              pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
              value={childrenCounts.businessModelMappingsCount}
              iconName={BUSINESS_MODEL_MAPPING_ICON}
              pinIconName={DATA_STRUCTURE_ICON}
              childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.TECHNICAL_DATA_MAPPINGS}>
            <ChildrenCountFieldWithFormik
              singularLabel={TECHNICAL_DATA_MAPPING_LABEL}
              pluralLabel={TECHNICAL_DATA_MAPPING_PLURAL_LABEL}
              value={childrenCounts.technicalDataMappingsCount}
              iconName={TECHNICAL_DATA_MAPPING_ICON}
              pinIconName={DATA_STRUCTURE_ICON}
              childFieldType={TECHNICAL_DATA_MAPPING_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
        </>}
      </ChildrenCountsContainer>
      <DataLineageDiagramButton
        objectType={DATA_STRUCTURE_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
      <BusinessModelMappingDiagramButton
        objectType={DATA_STRUCTURE_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
    </>
  );
};

export default DataStructureDetail;
