import React from 'react';
import { useFormikContext } from 'formik';
import { AccurityIconButton } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';

const CommentsToggleWithFormik = () => {
  const formik = useFormikContext<{ id: string}>();
  const accurityNavigation = useAccurityNavigation();
  const { openCommentsDrawer, closeCommentsDrawer } = accurityNavigation;
  const { comments } = accurityNavigation.getCurrentNavigation();
  const onClick = () => comments ? closeCommentsDrawer() : openCommentsDrawer();

  const hasFeature = useHasFeature();
  const isCommentsIconShown = hasFeature(Feature.COMMENTS) && formik.values && formik.values.id && formik.status.settingsDetail === undefined;

  // TODO: formik should be undefined feature/26001
  if (!isCommentsIconShown || formik?.status.isVersionBrowser) {
    return null;
  }

  return (
    (
      <AccurityIconButton
        iconName="mui:QuestionAnswerOutlined"
        title="Comments"
        size="small"
        disabled={!!comments}
        onClick={onClick}
      />
    )
  );
};

export default CommentsToggleWithFormik;
