import React from 'react';
import { useField, useFormikContext } from 'formik';
import { NumericFormat, SingleLineField, Tooltip } from 'ts-components';
import { useVersionBrowserColoring } from '../../versionBrowserField/hooks/versionBrowserColoringHook';

interface SinglelineFieldWithFormik {
  name: string;
  type?: 'text' | 'password' | 'email' | 'number';
  label?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  format?: NumericFormat;
  shouldFocus?: boolean;
  showPasswordVisibilityButton?: boolean;
  coloringOverwrite?: string;
  disableColoring?: boolean;
  tooltip?: Tooltip;
  inherited?: boolean;
}

const SingleLineFieldWithFormik = ({
                                     name,
                                     type = 'text',
                                     label,
                                     onChange,
                                     readOnly,
                                     format,
                                     shouldFocus,
                                     showPasswordVisibilityButton,
                                     coloringOverwrite,
                                     disableColoring,
                                     tooltip,
                                     inherited
                                   }: SinglelineFieldWithFormik) => {
  const [field, meta, ] = useField(name);
  const formikContext = useFormikContext();
  const coloring = useVersionBrowserColoring(coloringOverwrite ? coloringOverwrite : name);

  const noUpdatePermission = formikContext?.status?.permissions?.hasUpdatePermission === false;

  return (
    <SingleLineField
      value={field.value}
      onChange={(value: string) => {
        if (onChange) {
          onChange(value);
        }
        formikContext.setFieldValue(name, value);
      }}
      errorMessage={meta.error}
      name={name}
      type={type}
      label={label}
      disabled={readOnly || formikContext?.status?.isDisabled || noUpdatePermission}
      numericFormat={format}
      shouldFocus={shouldFocus}
      showPasswordVisibilityButton={showPasswordVisibilityButton}
      coloring={disableColoring ? undefined : coloring}
      tooltip={tooltip}
      inherited={inherited}
    />
  );
};

export default SingleLineFieldWithFormik;
