import Keycloak from 'keycloak-js';

export class KeycloakClient {

  private static client: Keycloak.KeycloakInstance;

  static create(keycloakUrl: string) {
    if (!this.client) {
      this.client = Keycloak({
        url: keycloakUrl + '/auth',
        realm: 'accurity',
        clientId: 'accurity',
      });
    }

    return this.client;
  }

  static logout() {
    return this.client?.logout();
  }

  static getToken() {
    if (!this.client) {
      console.log('You can not retrieve token before Keycloak initialization');
      return '';
    }

    return this.client.token;
  }

}
