import { ChildrenCountWording } from './types';

export const getLabelForValue = (singularLabel: string, pluralLabel: string, value?: number, childrenCountWording?: ChildrenCountWording) => {
  const label = childrenCountWording || ChildrenCountWording.NONE === childrenCountWording ? childrenCountWording : ChildrenCountWording.SHOW;
  if (value === 0 || value === undefined) {
    if (label === ChildrenCountWording.SHOW) {
      return `No ${pluralLabel} to show`;
    } else {
      return label + ` 0 ${pluralLabel}`;
    }
  } else if (value === 1) {
    return label + ` 1 ${singularLabel}`;
  } else {
    return label + ` ${value} ${pluralLabel}`;
  }
};