import React from 'react';
import { InteractiveDiagram } from 'ts-components';
import { requestGenerateGraph, requestHideNode, requestLoadGraph, requestSaveGraph } from '../rest/rest';
import { AccurityReference } from '../../../common/types/accurityTypes';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import ProcessDiagramNode, { ProcessDiagramNodeProps } from './node/ProcessDiagramNode';
import ProcessDiagramLink, { ProcessDiagramLinkProps } from './link/ProcessDiagramLink';
import { PROCESS_DIAGRAM_TYPE, ProcessDiagram } from '../types/processDiagramTypes';
import { GraphContent } from '../../businessModelDiagram/types/businessModelDiagramTypes';
import ProcessDiagramTopBarControls from './ProcessDiagramTopBarControls';
import { useInteractiveDiagramsLogic } from '../../businessModelDiagram/diagram/useInteractiveDiagramsLogic';

interface ProcessDiagramProps {
  diagramId: string;
}

const ProcessDiagramComponent = ({ diagramId }: ProcessDiagramProps) => {
  const hasUpdatePermission = usePermissionsForObjectType(PROCESS_DIAGRAM_TYPE).hasUpdatePermission;
  const commonDiagramsLogic = useInteractiveDiagramsLogic<ProcessDiagram, ProcessDiagramNodeProps, ProcessDiagramLinkProps>(
    PROCESS_DIAGRAM_TYPE,
    diagramId,
    requestLoadGraph,
    requestHideNode,
    requestSaveGraph,
  );

  const {
    diagramBag,
    setDiagramState,
    diagramInfo,
    selectedTags,
    setSelectedTags,
    selectedSources,
    setSelectedSources,
    handleHideNode,
    onSave,
    onReload,
    onOpenDetail,
    onCloseDiagram,
  } = commonDiagramsLogic;

  if (!diagramInfo) {
    return null;
  }

  const handleHideProcessDiagramNode = (nodeId: string) => {
    handleHideNode(nodeId).then(() => {
      // If hidden Node object is also a Source in the selector, deselect it
      const { objectType, objectId } = diagramBag.state.nodes[nodeId];
      setSelectedSources(selectedSources.filter(source => !(source.objectType === objectType && source.id === objectId)))
    })
  }

  const handleGenerate = (selectedItems: AccurityReference[]) => {
    requestGenerateGraph(selectedItems, diagramInfo?.processDiagramBasedOnType)
      .then((graphContent: GraphContent) => {
        setDiagramState({
          nodes: graphContent?.nodes,
          links: graphContent?.links,
          secondaryLinks: graphContent?.secondaryLinks,
          transform: undefined,
        }, false);
        setSelectedSources(selectedItems);
      });
  };

  const renderNode = (node: ProcessDiagramNodeProps) => (
    <ProcessDiagramNode
      key={node.id}
      {...node}
      hideNode={handleHideProcessDiagramNode}
    />
  );

  const renderLink = (link: ProcessDiagramLinkProps) => (
    <ProcessDiagramLink
      key={link.id}
      {...link}
    />
  );

  return (
    <InteractiveDiagram<ProcessDiagramNodeProps, ProcessDiagramLinkProps>
      diagramContext={diagramBag}
      renderNode={renderNode}
      renderLink={renderLink}
      onSave={onSave}
      onReload={onReload}
      onOpenDetail={onOpenDetail}
      onCloseDiagram={onCloseDiagram}
      readOnly={!hasUpdatePermission}
      name={diagramInfo.name}
      topBarControls={
        <ProcessDiagramTopBarControls
          basedOnType={diagramInfo.processDiagramBasedOnType}
          tags={selectedTags}
          setTags={setSelectedTags}
          initialSelectedItems={selectedSources}
          onGenerate={handleGenerate}
          hasUpdatePermission={hasUpdatePermission}
        />
      }
    />
  );
};

export default ProcessDiagramComponent;
