import React from 'react';
import { get } from 'lodash';
import { CollapsingGroup, AccurityButton, DetailBottomBar } from 'ts-components';
import { CustomReloadSteps } from '../actions/detailReload';
import AccurityDetailContainer from '../redux/AccurityDetailContainer';
import { AccurityCoreEntity } from '../../types/accurityTypes';
import { FormikContext, FormikProps, useFormikContext } from 'formik';
import { CustomSaveSteps } from '../actions/detailSave';
import { DetailBag } from '../types/types';
import { useDispatch } from 'react-redux';
import { setDetailOpenedAt } from '../../redux/commonReducers';
import { useVersionBrowserFormik } from '../../versionBrowserField/hooks/loadChangesHook';

interface EmbeddedDetailProps<T extends AccurityCoreEntity> {
  name: string;
  objectTypeLabel?: string;
  id?: string;
  objectType: string;
  referenceField: React.ReactNode;
  embeddedFields: (formik: FormikProps<T>, isEmbedded: boolean, editingInEmbedded: boolean) => React.ReactNode;
  editable?: boolean;
  containingDetailBag: DetailBag<T>;
}

const EmbeddedDetail = <T extends AccurityCoreEntity>({
                                                        name,
                                                        objectTypeLabel,
                                                        id,
                                                        objectType,
                                                        referenceField,
                                                        embeddedFields,
                                                        editable,
                                                        containingDetailBag,
                                                      }: EmbeddedDetailProps<T>) => {
  const dispatch = useDispatch();
  const containingDetailFormik = useFormikContext<T>();
  const isEmbeddedDetailActive = containingDetailFormik.status.activeEmbeddedDetail === name;
  const isOtherEmbeddedDetailActive = !isEmbeddedDetailActive && containingDetailFormik.status.activeEmbeddedDetail !== undefined;
  const detailHasEntity = id !== undefined;

  const toggleActive = () => {
    if (isEmbeddedDetailActive) {
      containingDetailBag.setActiveEmbeddedDetail(undefined);
    } else {
      dispatch(setDetailOpenedAt({ objectType: objectType, openedAt: Date.now() }));
      containingDetailBag.setActiveEmbeddedDetail(name);
    }
  };

  const onEditClick = editable && id !== undefined ? toggleActive : undefined;
  const onAddClick = editable && id === undefined ? toggleActive : undefined;

  const customReloadSteps: CustomReloadSteps<T> = {
    refreshDetail: () => {
      dispatch(setDetailOpenedAt({ objectType: objectType, openedAt: Date.now() }));
    },
  };

  const customSaveSteps: CustomSaveSteps<T> = {
    refreshDetail: (coreEntity, navigationController) => {
      containingDetailFormik.setFieldValue(name, coreEntity);
      dispatch(setDetailOpenedAt({ objectType: objectType, openedAt: Date.now() }));
      toggleActive();
    },
  };

  // Support for populating EmbeddedDetail from nested Entity within Containing Detail's Entity.
  // Currently only used to support EmbeddedDetail in VersionBrowser
  const [customProperties, customPropertyGroups] = containingDetailFormik.initialStatus.customProperties;
  const entity = get(containingDetailFormik.initialValues, name);
  const coloring = get(containingDetailFormik.initialStatus.revisionColoring, name);
  const nestedFormik = useVersionBrowserFormik(entity, customProperties, customPropertyGroups, coloring);

  const embeddedDetail =
    containingDetailFormik.status.isVersionBrowser
      ? (nestedFormik &&
      <FormikContext.Provider value={nestedFormik}>
        {embeddedFields(nestedFormik, true, false)}
      </FormikContext.Provider>)
      : (
        <AccurityDetailContainer<T>
          objectType={objectType}
          id={id}
          isEmbedded={true}
          customReloadSteps={customReloadSteps}
          customSaveSteps={customSaveSteps}
        >
          {(formik, detailBag) => {
            formik.status.isDisabled = !isEmbeddedDetailActive;
            return (
              <>
                {embeddedFields(formik, true, isEmbeddedDetailActive)}
                {isEmbeddedDetailActive &&
                <DetailBottomBar>
                  <AccurityButton
                    onClick={toggleActive}
                    color="secondary"
                  >
                    Cancel
                  </AccurityButton>
                  {detailBag.showSaveAction &&
                  <AccurityButton
                    disabled={detailBag.isSaveDisabled}
                    onClick={() => detailBag.saveAction()}
                  >
                    Save
                  </AccurityButton>}
                </DetailBottomBar>}
              </>
            );
          }}
        </AccurityDetailContainer>
      );

  if (!isEmbeddedDetailActive) {
    return (
      <CollapsingGroup
        key={`${name}-${isEmbeddedDetailActive}`}
        name={`embedded-${name}`}
        expandable={true}
        expandedByDefault={isEmbeddedDetailActive}
        expandDisabled={!detailHasEntity}
        onEditClick={onEditClick}
        onAddClick={onAddClick}
        disabled={isOtherEmbeddedDetailActive}
        customHeader={referenceField}
        objectTypeLabel={objectTypeLabel}
      >
        {(detailHasEntity || editable) && embeddedDetail}
      </CollapsingGroup>
    )
  } else {
    return (
      <>
        {embeddedDetail}
      </>
    )
  }

};

export default EmbeddedDetail;
