import React from 'react';
import { FormikProps } from 'formik';
import { DetailInlineGroup, Tooltip } from 'ts-components';

import { AccurityCoreEntity, AccurityFilter } from '../../types/accurityTypes';
import { CustomProperty } from '../customProperty/types/customPropertyTypes';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL, useDataSetTypeEnumOptions } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import { getSupportiveCustomPropertyValueFieldLabel } from '../customPropertyUtils';
import { createReferenceFieldFilter } from '../../referenceField/utils/filters';
import ReferenceFieldWithFormik from '../../detail/formik/ReferenceFieldWithFormik';
import EnumerationFieldWithFormik from '../../detail/formik/EnumerationFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE, DataStructureReference } from '../../../businessGlossary/dataStructure/types/dataStructureTypes';

interface DataStructureCustomPropertyValueFieldProps {
  formik: FormikProps<AccurityCoreEntity>;
  fieldName: string;
  customProperty: CustomProperty;
  additionalFilters?: AccurityFilter[];
  inherited?: boolean;
  tooltip?: Tooltip;
}

const DataStructureCustomPropertyValueField = ({ formik, fieldName, customProperty, additionalFilters = [], inherited, tooltip }: DataStructureCustomPropertyValueFieldProps) => {

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  const referenceFieldProps = formik.getFieldProps(fieldName + '.reference').value;
  const dataSetReferenceFieldProps = formik.getFieldProps(fieldName + '.dataSetReference').value;

  const showDataSetTooltip = !!dataSetReferenceFieldProps;
  const showDefaultTooltip = !!referenceFieldProps;

  const dataSetId = dataSetReferenceFieldProps?.id;

  additionalFilters.push(
    createReferenceFieldFilter('dataSet.id', dataSetId),
  );

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[8, 4]}
      >
        <ReferenceFieldWithFormik
          name={fieldName + '.dataSetReference'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_SET_LABEL)}
          objectType={DATA_SET_TYPE}
          icon={DATA_SET_ICON}
          tooltip={tooltip}
          setValue={(dataSet) => {
            formik.setFieldValue(fieldName + '.dataSetReference', dataSet);
            formik.setFieldValue(fieldName + '.reference', null);
          }}
          showDefaultTooltip={showDataSetTooltip}
          inherited={inherited}
          coloringOverwrite={fieldName}
        />
        {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
          name={fieldName + '.dataSetReference.dataSetType'}
          label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_SET_TYPE_LABEL)}
          options={dataSetTypeEnumOptions}
          readOnly={true}
        />}
      </DetailInlineGroup>
      <ReferenceFieldWithFormik<DataStructureReference>
        name={fieldName + '.reference'}
        icon={DATA_STRUCTURE_ICON}
        label={getSupportiveCustomPropertyValueFieldLabel(customProperty.name, DATA_STRUCTURE_LABEL)}
        objectType={DATA_STRUCTURE_TYPE}
        additionalFilters={additionalFilters}
        setValue={(dataStructure) => {
          if (!dataSetId) {
            formik.setFieldValue(fieldName + '.dataSetReference', dataStructure?.dataSet)
          }
          formik.setFieldValue(fieldName + '.reference', dataStructure);
        }}
        tooltip={tooltip}
        showDefaultTooltip={showDefaultTooltip}
        inherited={inherited}
        coloringOverwrite={fieldName}
      />
    </>
  )
};


export default DataStructureCustomPropertyValueField;