import React from 'react';
import { ENTITY_ICON } from '../../../entity/types/entityTypes';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import {
  BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_LABEL,
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTE_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTE_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITION_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL,
  BusinessModelMapping
} from '../../types/businessModelMappingTypes';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE, DataStructureReference } from '../../../dataStructure/types/dataStructureTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps } from 'formik';
import EntityAttributeSelection from '../misc/EntityAttributeSelection';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { CollapsingGroup, DetailInlineGroup, EnumOption } from 'ts-components';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_TYPE, DataFieldReference } from '../../../dataField/types/dataFieldTypes';
import { createReferenceFieldFilter } from '../../../../common/referenceField/utils/filters';
import { getBusinessModelMappingCountLabel, resetValuesOnBaseDataSetChange } from '../businessModelMappingDetailUtils';
import TargetAttribute from './TargetAttribute';
import EntityAttributeJoin from '../misc/EntityAttributeJoin';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';

interface BasedOnDataFieldProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const BasedOnDataField = ({
                            additionalFilters = [],
                            formik,
                            multipleDataSetTypesAllowedFeature,
                            dataSetTypeEnumOptions
                          }: BasedOnDataFieldProps) => {

  const dataSetId = formik.values.baseDataSet?.id;
  const dataStructureId = formik.values.baseDataStructure?.id;
  const targets = formik.getFieldProps(BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITION_FIELD).value;
  const selections = formik.getFieldProps(BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD).value;
  const joins = formik.getFieldProps(BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD).value;
  const isVersionBrowser = formik.status.isVersionBrowser;

  const dataStructureFilter = [
    ...additionalFilters,
    createReferenceFieldFilter('dataSet.id', formik.values.baseDataSet?.id),
  ];

  const dataFieldFilter = [
    ...additionalFilters,
    createReferenceFieldFilter('dataSet.id', formik.values.baseDataSet?.id),
  ];

  if (dataStructureId) {
    dataFieldFilter.push(
      createReferenceFieldFilter('dataStructure.id', dataStructureId)
    );
  }

  return (
    <>
      <CollapsingGroup
        name={'base'}
        title={BUSINESS_MODEL_MAPPING_BASE_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        {formik.values.businessModelMappingType &&
        <DetailInlineGroup
          childGridSizes={[8, 4]}
        >
          <ReferenceFieldWithFormik
            name={BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD}
            label={DATA_SET_LABEL}
            objectType={DATA_SET_TYPE}
            icon={DATA_SET_ICON}
            setValue={(newValue) => {
              resetValuesOnBaseDataSetChange(formik, false);
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD, newValue);
            }}
          />
          {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
            name={'baseDataSet.dataSetType'}
            label={DATA_SET_TYPE_LABEL}
            options={dataSetTypeEnumOptions}
            readOnly={true}
          />}
        </DetailInlineGroup>}
        <DetailInlineGroup
          childGridSizes={[6, 6]}>
          <ReferenceFieldWithFormik<DataStructureReference>
            name={BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD}
            label={DATA_STRUCTURE_LABEL}
            icon={DATA_STRUCTURE_ICON}
            objectType={DATA_STRUCTURE_TYPE}
            additionalFilters={dataStructureFilter}
            setValue={(dataStructure) => {
              if (!dataSetId) {
                formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD, dataStructure?.dataSet);
              }
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD, dataStructure);
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_FIELD, null);
            }}
          />
          <ReferenceFieldWithFormik<DataFieldReference>
            name={BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_FIELD}
            label={DATA_FIELD_LABEL}
            icon={DATA_FIELD_ICON}
            objectType={DATA_FIELD_TYPE}
            additionalFilters={dataFieldFilter}
            setValue={(dataField) => {
              if (!dataSetId) {
                formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD, dataField?.dataStructure?.dataSet);
              }
              if (!dataStructureId) {
                formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD, dataField?.dataStructure);
              }
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_FIELD_FIELD, dataField);
            }}
          />
        </DetailInlineGroup>
      </CollapsingGroup>
      <CollapsingGroup
        name={BUSINESS_MODEL_MAPPING_TARGET_FIELD}
        title={BUSINESS_MODEL_MAPPING_TARGET_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITION_FIELD}
          title={getBusinessModelMappingCountLabel(targets,
            BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTE_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_ATTRIBUTE_PLURAL_LABEL)}
          icon={ENTITY_ICON}
          rowValidation={(value) => value && value.entity && value.entity.id &&
            value.attributeDefinition && value.attributeDefinition.id}
          renderRow={(elementName, index) =>
            <TargetAttribute
              elementName={elementName}
              index={index}
              additionalFilters={additionalFilters}
            />
          }
        />
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD}
          title={getBusinessModelMappingCountLabel(selections,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL)}
          icon={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON}
          expandedByDefault={isVersionBrowser}
          rowValidation={(value) =>
            value && value.entity && value.entity.id &&
            value.attributeDefinition && value.attributeDefinition.id &&
            value.criteria
          }
          renderRow={(elementName, index) =>
            <EntityAttributeSelection
              elementName={elementName}
              index={index}
            />
          }
        />
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD}
          title={getBusinessModelMappingCountLabel(joins,
            BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL)}
          icon={BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON}
          expandedByDefault={isVersionBrowser}
          rowValidation={(value) => value && value.entity && value.entity.id &&
            value.attributeDefinition && value.attributeDefinition.id &&
            value.targetEntity && value.targetEntity.id}
          renderRow={(elementName, index) =>
            <EntityAttributeJoin
              elementName={elementName}
              index={index}
              formik={formik}
            />
          }/>
      </CollapsingGroup>
    </>
  )
};

export default BasedOnDataField;
