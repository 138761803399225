import React from 'react';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import { Requirement, REQUIREMENT_ICON, REQUIREMENT_TYPE } from '../types/requirementTypes';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_LABEL, BUSINESS_TERM_PLURAL_LABEL, BUSINESS_TERM_TYPE } from '../../businessTerm/types/businessTermTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { ATTRIBUTE_ICON, ATTRIBUTE_LABEL, ATTRIBUTE_PLURAL_LABEL, ATTRIBUTE_TYPE } from '../../attribute/types/attributeTypes';
import { DetailHeader, StaticField } from 'ts-components';
import { ENTITY_ICON } from '../../entity/types/entityTypes';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface RequirementDetailProps {
  id?: string,
}

const RequirementDetail = ({ id }: RequirementDetailProps) => {
  return (
    <AccurityDetailContainer<Requirement>
      objectType={REQUIREMENT_TYPE}
      id={id}>
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar objectType={REQUIREMENT_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<Requirement>, detailBag: DetailBag<Requirement>) => (
  <ConfirmRemovalDetail
    iconName={REQUIREMENT_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={IT_IS_CURRENTLY_LABEL}
      inverted={true}
    />
    <FeatureChecker featureId={Feature.ATTRIBUTES}>
      <ChildrenCountFieldWithFormik
        singularLabel={ATTRIBUTE_LABEL}
        pluralLabel={ATTRIBUTE_PLURAL_LABEL}
        value={formik.values.childrenCounts.attributesCount}
        iconName={ATTRIBUTE_ICON}
        pinIconName={ENTITY_ICON}
        childFieldType={ATTRIBUTE_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
      <ChildrenCountFieldWithFormik
        singularLabel={BUSINESS_TERM_LABEL}
        pluralLabel={BUSINESS_TERM_PLURAL_LABEL}
        value={formik.values.childrenCounts.businessTermsCount}
        iconName={BUSINESS_TERM_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={BUSINESS_TERM_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<Requirement>) => {
  return (
    <FeatureChecker featureId={Feature.REQUIREMENTS}>
      <DetailHeader iconName={REQUIREMENT_ICON}>
        <NameFieldWithFormik label={'Requirement Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={REQUIREMENT_TYPE}/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.REQUIREMENT}/>
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_LABEL}
          pluralLabel={ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={REQUIREMENT_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_TERM_LABEL}
          pluralLabel={BUSINESS_TERM_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessTermsCount}
          iconName={BUSINESS_TERM_ICON}
          pinIconName={REQUIREMENT_ICON}
          childFieldType={BUSINESS_TERM_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
    </FeatureChecker>
  );
};

export default RequirementDetail;
